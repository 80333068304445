import type { CSSProperties } from 'react';

import { classNames } from '@/utils/classNames';

import s from './ActivityItemEvent.module.css';

export interface IActivityItemEventProps {
  content: string;
  className?: string;
  style?: CSSProperties;
}

export const ActivityItemEvent = (props: IActivityItemEventProps) => {
  const { className, content, style } = props;

  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className={classNames(s['comparison'], 'pl-9 text-blueGray-800', className)}
      style={style}
    />
  );
};
ActivityItemEvent.displayName = 'ActivityItemEvent';
