import classNames from 'classnames';

import { Popover } from '@/components/v1/Popover';
import { Show } from '@/components/v1/Show';
import { Tooltip } from '@/components/v1/Tooltip';
import { EllipsisVIcon } from '@/icons/solid';
import { isNonNullable } from '@/utils/isNonNullable';

import s from './TranslationSuggestion.module.css';
import type { ITranslationSuggestionProps } from './types';

export const TranslationSuggestion = ({
  icon,
  cleanSuggestion,
  sourcesComparison,
  reference,
  more,
  tooltip,
  sourceLanguage,
  targetLanguage,
  showShortcut,
  shortcut,
  onClick
}: ITranslationSuggestionProps) => {
  const hasMore = isNonNullable(more);

  return (
    <div className="flex">
      <Tooltip containerClassName="flex-1" placement="left" title={tooltip}>
        <button
          tabIndex={-1}
          className={classNames(
            'flex w-full items-center bg-blue-50 px-2 py-1 text-start text-blue-500 hover:bg-blue-100 hover:text-blue-600',
            hasMore ? 'rounded-l' : 'rounded'
          )}
          onClick={onClick}
        >
          <div className="w-6 text-center">{icon}</div>
          <div className={classNames('ml-2 flex-1', !hasMore && 'mr-6')}>
            <div dir={targetLanguage.direction} className={'break-anywhere whitespace-pre-wrap'}>
              {cleanSuggestion}
            </div>

            <Show when={sourcesComparison}>
              {(sourcesComparison) => (
                <div
                  // todo: remove text-[#9ca3af] for new design system
                  className={classNames(s.comparison, 'mt-2 text-xs font-semibold italic text-[#9ca3af] text-gray-400')}
                  dangerouslySetInnerHTML={{ __html: sourcesComparison }}
                  dir={sourceLanguage.direction}
                />
              )}
            </Show>

            <Show when={reference}>
              <div className="mt-2 text-xs text-gray-400">{reference}</div>
            </Show>
          </div>
        </button>
      </Tooltip>

      {hasMore && (
        <div className="flex">
          <Popover placement="bottom-end" className="w-96" content={more}>
            <Popover.Button
              tabIndex={-1}
              className="h-full w-6 rounded-r bg-blue-50 px-2 py-1 text-blue-500 hover:bg-blue-100 hover:text-blue-600"
            >
              <EllipsisVIcon />
            </Popover.Button>
          </Popover>
        </div>
      )}

      <div className="flex w-12 items-center justify-center">
        {showShortcut && (
          <div className="inline-flex h-4 w-4 items-center justify-center rounded-full bg-gray-300 text-2xs font-bold text-white">
            {shortcut}
          </div>
        )}
      </div>
    </div>
  );
};
