import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import { Navbar } from '@/components/v2/Navbar';
import { StepperGroup } from '@/components/v2/StepperGroup';
import { Button } from '@/components/v2/Button';
import { uploadingDocumentSteps } from '@/routes/UploadDocuments/constants';
import { createContext } from '@/utils/createContext';
import { useUpdateFlagsMutation } from '@/redux/api';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { Show } from '@/components/v2/Show';
import { useRouterParamsQuery } from '@/hooks/useRouterParamsQuery';

interface UploadDocumentProviderProps {
  multiple?: boolean;
  folderManagement?: boolean;
  showSuggestions?: boolean;
  maxFileSize?: number;
  skippableTargetLanguage?: boolean;
  welcome?: boolean;
  children?: ReactNode;
}

type UploadDocumentContextType = Omit<UploadDocumentProviderProps, 'children'>;

export const [Provider, useUploadDocument] = createContext<UploadDocumentContextType>('UploadDocumentContext');

export const UploadDocumentProvider: React.FC<UploadDocumentProviderProps> = (props) => {
  const { multiple, folderManagement, showSuggestions, maxFileSize, skippableTargetLanguage, welcome, children } =
    props;

  const { t } = useTranslation('layout');
  const location = useLocation();
  const [updateFlag] = useUpdateFlagsMutation();
  const { currentAccount } = useCurrentAccount();
  const history = useHistory();
  const query = useRouterParamsQuery();

  const folderId = Number(query.get('folderId'));
  const currentFolderId = !isNaN(Number(folderId)) ? folderId : undefined;

  const isCompleted = currentAccount.onboardingFlags.hasConfirmedAnyDocument;
  const currentPath = location.pathname;

  // ugly workaround to match this route with a regex pattern for the "target-language" step with parameters
  const targetLanguageRegex = /^\/welcome\/documents\/target-language\/(\d+)$/;

  const index = uploadingDocumentSteps.findIndex((step) => {
    if (step.path === '/welcome/documents/target-language' && targetLanguageRegex.test(currentPath)) {
      return true;
    }
    return currentPath.endsWith(step.path);
  });

  const translatedUploadingDocumentSteps = uploadingDocumentSteps.map((step) => ({
    ...step,
    label: t(step.label as unknown as TemplateStringsArray)
  }));

  const handleSkipForNow = () => {
    if (showSuggestions && !isCompleted) {
      updateFlag({ hasHiddenOnboardingWizardWidget: true });
    }
    if (currentFolderId) {
      history.push(`/documents/folder/${currentFolderId}`);
    } else {
      history.push(`/documents`);
    }
  };

  const value = {
    multiple,
    folderManagement,
    showSuggestions,
    maxFileSize,
    skippableTargetLanguage,
    welcome
  };

  return (
    <Provider {...value}>
      <div className="flex min-h-screen flex-col text-sm text-gray-800">
        <Helmet>
          <title>{welcome ? t('uploadNewDocument') : t('redokun')}</title>
        </Helmet>
        <Navbar sticky>
          <Show when={!showSuggestions}>
            <Button variant="surface" color="secondary" onClick={handleSkipForNow} className="ml-auto">
              {t('close')}
            </Button>
          </Show>
        </Navbar>

        <div className="flex-1 bg-gray-50 px-8 pb-28">
          <div className="mx-auto w-full max-w-4xl">
            <div className="mb-20 mt-12 flex items-center justify-center">
              <StepperGroup
                orientation="horizontal"
                items={translatedUploadingDocumentSteps}
                current={index}
                className="w-160"
              />
            </div>
            <div className="flex justify-center">{children}</div>
          </div>
        </div>
      </div>
    </Provider>
  );
};
