import { FileRejection } from 'react-dropzone';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { FileContainer, FileContainerProps } from '@/components/v2/FileContainer';
import FileUploader from '@/components/v2/FileUploader';
import { useToast } from '@/components/v2/Toast';
import { useUploadFile } from '@/redux/api/fileApi';
import { EntityType } from '@/redux/api/types';
import { sizeToMb } from '@/utils/sizeToMb';

import { acceptCreator } from '../acceptCreator';
import { UPLOAD_TM_MAX_FILES, UPLOAD_TM_MAX_SIZE } from '../constants';
import { TranslationMemoryUploadStateType } from '../types';

export enum FileUploadCodesError {
  fileInvalidType = 'file-invalid-type'
}

export type UploadAreaProps = {
  translationMemoryUploadState: TranslationMemoryUploadStateType;
  setTranslationMemoryUploadState: React.Dispatch<React.SetStateAction<TranslationMemoryUploadStateType>>;
};

export const UploadArea = (props: UploadAreaProps) => {
  const { translationMemoryUploadState, setTranslationMemoryUploadState } = props;

  const { t: tTM } = useTranslation('translationMemory');
  const { control } = useFormContext();
  const { toast } = useToast();

  const [uploadFile, { progress: fileProgress, isError: uploadFileError, isSuccess: uploadFileSuccess }] =
    useUploadFile();

  const handleOnDrop = async (field: ControllerRenderProps<FieldValues, 'file'>, files: File[]) => {
    if (files.length) {
      field.onChange(files[0]);
      const { error: err, data: response } = await uploadFile({ entityType: EntityType.translationMemory, files });
      if (response?.successfulUploads.length) {
        setTranslationMemoryUploadState({
          ...translationMemoryUploadState,
          stashedFileId: response.successfulUploads[0].stashedFileId
        });
      } else if (err) {
        return;
      }
    }
  };

  const handleRejectedFiles = (fileRejections: FileRejection[]) => {
    for (let i = 0; i < fileRejections.length; i++) {
      const errorElement = fileRejections[i];
      for (let j = 0; j < errorElement.errors.length; j++) {
        const errorOfFile = errorElement.errors[j];
        if (errorOfFile.code === FileUploadCodesError.fileInvalidType) {
          toast({
            title: `${tTM('fileRejected')}: ${errorElement.file.name} ${tTM('isOfAnUnsupportedFileType')}`,
            kind: 'error'
          });
        }
      }
    }
  };

  let fileContainerStatus: FileContainerProps['status'] = 'active';
  if (uploadFileSuccess) {
    fileContainerStatus = 'completed';
  } else if (uploadFileError) {
    fileContainerStatus = 'error';
  }

  const acceptedFiles = { ...acceptCreator('tmx'), ...acceptCreator('xlsx') };

  return (
    <div className="mt-6 w-full">
      <Controller
        name="file"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="flex flex-row gap-4">
            <div className="w-1/2">
              <FileUploader.Root
                dropzoneOptions={{
                  maxFiles: UPLOAD_TM_MAX_FILES,
                  maxSize: UPLOAD_TM_MAX_SIZE,
                  onDrop: (files, fileRejections) => {
                    if (fileRejections.length) {
                      handleRejectedFiles(fileRejections);
                    } else {
                      handleOnDrop(field, files);
                    }
                  },
                  accept: acceptedFiles
                }}
              >
                <FileUploader.DragArea>
                  <FileUploader.DragAreaContent>
                    <AccessibleIcon
                      icon="ri-upload-2-line"
                      label="ri-upload-2-line"
                      className="text-4xl text-alpha-400"
                    />
                    <FileUploader.DragAreaTitle>{tTM('dragAndDropFiles')}</FileUploader.DragAreaTitle>
                    <FileUploader.DragAreaDescription>{tTM('supportedFileFormat')}</FileUploader.DragAreaDescription>
                  </FileUploader.DragAreaContent>
                  <FileUploader.Divider className="py-4">{tTM('or')}</FileUploader.Divider>
                  <FileUploader.Button>{tTM('browseFiles')}</FileUploader.Button>
                </FileUploader.DragArea>
              </FileUploader.Root>
            </div>
            {field.value ? (
              <div className="flex w-1/2 flex-col gap-2">
                <FileContainer
                  id={field.value.name}
                  fileName={field.value.name}
                  fileSize={`${sizeToMb(field.value.size)} MB`}
                  status={fileContainerStatus}
                  percent={Math.round((fileProgress?.progress ?? 0) * 100)}
                  onClose={() => {
                    field.onChange();
                    setTranslationMemoryUploadState({
                      ...translationMemoryUploadState,
                      stashedFileId: undefined
                    });
                  }}
                  helperMessage=""
                />
              </div>
            ) : null}
          </div>
        )}
      />
    </div>
  );
};
