import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { Box } from '@/components/v2/Box';
import { Button } from '@/components/v2/Button';
import { Checkbox } from '@/components/v2/Checkbox';
import { Label } from '@/components/v2/Label';
import { Select, SelectItem } from '@/components/v2/Select';
import { Text } from '@/components/v2/Text';
import { TextArea } from '@/components/v2/TextArea';
import { useToast } from '@/components/v2/Toast';
import { useDeleteSubscriptionMutation } from '@/redux/api/subscriptionApi';
import { CancelRenewalRequestDtoReason, CurrentAccountDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { standardDateTimeFormat } from '@/utils/date';

type ScheduleSubscriptionCancellationProps = {
  currentAccount: CurrentAccountDto;
  isRenewingState: {
    isRenewing: boolean;
    setIsRenewing: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export const ScheduleSubscriptionCancellation = ({
  currentAccount,
  isRenewingState
}: ScheduleSubscriptionCancellationProps) => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();
  const [deleteSubscriptionTrigger, deleteSubscriptionStatus] = useDeleteSubscriptionMutation();

  const literalErrors = {
    errorMap: (err: z.ZodIssueOptionalMessage, ctx: z.ErrorMapCtx) => {
      switch (err.code) {
        case 'invalid_literal':
          return { message: tSettings('thisFieldIsRequired') };
      }
      return { message: ctx.defaultError };
    }
  };
  const formSchema = z.object({
    reason: z.union([
      z.literal(CancelRenewalRequestDtoReason.iDonTNeedItAnymore, literalErrors),
      z.literal(CancelRenewalRequestDtoReason.myProjectHasEnded, literalErrors),
      z.literal(CancelRenewalRequestDtoReason.iStartedUsingAnotherTool, literalErrors),
      z.literal(CancelRenewalRequestDtoReason.iAmHavingTechnicalIssuesWithTheProduct, literalErrors),
      z.literal(CancelRenewalRequestDtoReason.budgetProblem, literalErrors),
      z.literal(CancelRenewalRequestDtoReason.somethingElse, literalErrors)
    ]),
    reasonDetail: z.string().min(1, tSettings('thisFieldIsRequired')),
    feedback: z.string().nullable(),
    understand: z.boolean()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reason: undefined,
      reasonDetail: '',
      feedback: null,
      understand: false
    }
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { understand, ...rest } = data;

    if (understand) {
      const [err, data] = await to(deleteSubscriptionTrigger({ ...rest }).unwrap());
      if (!err && data.status === 'ok') {
        toast({
          title: tSettings('yourSubscriptionWasCorrectlyCanceled'),
          kind: 'success'
        });
        // Set to false because we expect that the auto-renew is disabled
        isRenewingState.setIsRenewing(false);
      }
    }
  };

  return (
    <form className="mt-6 space-y-7" onSubmit={form.handleSubmit(onSubmit)}>
      <Box>
        <Text weight="bold" color="primary">
          {tSettings('areYouLookingToDisableAutoRenewal')}
        </Text>
        <Text size="sm" color="tertiary">
          {tSettings('weAreSorryGetInTouch')}
        </Text>
        <div className="space-y-7">
          <div className="max-w-xs space-y-3">
            <Label isRequired size="sm" text={tSettings('whyAreYouCancellingYourSubscription')} color="secondary" />
            <Controller
              name={'reason'}
              control={form.control}
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState }) => (
                <div>
                  <Select
                    onChange={onChange}
                    value={value}
                    placeholder={tSettings('selectAnAnswer')}
                    bordered
                    sideOffset={5}
                  >
                    {Object.keys(CancelRenewalRequestDtoReason).map((key, i) => {
                      return (
                        <SelectItem
                          value={CancelRenewalRequestDtoReason[key as keyof typeof CancelRenewalRequestDtoReason]}
                          key={i}
                        >
                          {CancelRenewalRequestDtoReason[key as keyof typeof CancelRenewalRequestDtoReason]}
                        </SelectItem>
                      );
                    })}
                  </Select>
                  {fieldState.error && (
                    <Text size="sm" className="text-red-500">
                      {fieldState.error?.message}
                    </Text>
                  )}
                </div>
              )}
            />
          </div>
          <div className="max-w-xs space-y-3">
            <Label isRequired size="sm" text={tSettings('couldYouTellUsMoreAboutYourExperience')} color="secondary" />
            <Controller
              name={'reasonDetail'}
              control={form.control}
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState }) => (
                <div>
                  <TextArea onChange={onChange} value={value} placeholder={tSettings('itDidntWorkForMeBecause')} />
                  {fieldState.error && (
                    <Text size="sm" className="text-red-500">
                      {fieldState.error?.message}
                    </Text>
                  )}
                </div>
              )}
            />
          </div>
          <div className="max-w-xs space-y-3">
            <Label size="sm" text={tSettings('whatCouldWeImprove')} color="secondary" />
            <Controller
              name={'feedback'}
              control={form.control}
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState }) => (
                <div>
                  <TextArea
                    onChange={onChange}
                    value={value ?? undefined}
                    placeholder={tSettings('esFeatureRequestsFeedback')}
                  />
                  {fieldState.error && (
                    <Text size="sm" className="text-red-500">
                      {fieldState.error?.message}
                    </Text>
                  )}
                </div>
              )}
            />
          </div>

          <Controller
            name={'understand'}
            control={form.control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div className="flex space-x-3 pt-6">
                <Checkbox
                  id="understand"
                  checked={value}
                  onCheckedChange={(value) => {
                    onChange(value);
                  }}
                />
                <Label
                  size="sm"
                  color="primary"
                  htmlFor="understand"
                  text={tSettings('manageAutorenewalIUnderstandThatAllMyData')}
                />
              </div>
            )}
          />
        </div>
      </Box>
      <div className="flex items-center justify-between">
        <Text>
          {tSettings('ifYouCancelYourCurrentSubscriptionWillEndOn', {
            date: standardDateTimeFormat(currentAccount.subscriptionInfo.currentSubEndAt)
          })}
        </Text>
        <Button type="submit" color="negative" disabled={deleteSubscriptionStatus.isLoading}>
          {deleteSubscriptionStatus.isLoading ? <Spinner size="sm" /> : null}
          {tSettings('scheduleSubscriptionCancellation')}
        </Button>
      </div>
    </form>
  );
};
