import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { DocumentListItemDto } from '@/redux/api/types';
import { DeleteDocumentModal } from '@/routes/Document/components/DeleteDocumentModal';
import { DocumentSettingsModal } from '@/routes/Document/components/DocumentSettingsModal';
import { DeleteFolderModal } from '@/routes/DocumentList/components/DocumentListContent/components/DeleteFolderModal';
import { isFolderItem } from '@/routes/DocumentList/components/DocumentListContent/utils';
import { FolderSettingsModal } from '@/routes/Document/components/FolderSettingsModal';

enum ModalKey {
  FOLDER_SETTINGS = 'FOLDER_SETTINGS',
  FOLDER_DELETE = 'FOLDER_DELETE',
  DOCUMENT_SETTINGS = 'DOCUMENT_SETTINGS',
  DOCUMENT_DELETE = 'DOCUMENT_DELETE'
}

export const ActionsCell = () => {
  const { t } = useTranslation('documentList');
  const { currentAccount } = useCurrentAccount();

  const { cell } = DataTable.useCellContext<DocumentListItemDto, DocumentListItemDto>('ActionsCell');
  const { original: data, getIsExpanded, toggleExpanded } = cell.row;
  const isExpanded = getIsExpanded();
  const id = data.item.id;

  // https://github.com/radix-ui/primitives/issues/1836
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState<ModalKey | null>(null);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  // https://github.com/radix-ui/primitives/issues/1836
  const handleModalItemSelect = (key: ModalKey) => (event: Event) => {
    event.preventDefault();
    setMenuOpen(false);
    setModalOpen(key);
  };

  const handleConfirmSettings = () => {
    setModalOpen(null);
    menuTriggerRef.current?.focus();
  };

  // https://github.com/radix-ui/primitives/issues/1836
  const modalProps = (key: ModalKey) => ({
    open: modalOpen === key,
    onOpenChange: (open: boolean) => {
      if (open) {
        setMenuOpen(false);
        setModalOpen(key);
      } else {
        setModalOpen(null);
        menuTriggerRef.current?.focus();
      }
    }
  });

  const renderContent = () => {
    if (isFolderItem(data)) {
      return (
        <>
          <DropdownMenu.Item onSelect={handleModalItemSelect(ModalKey.FOLDER_SETTINGS)}>
            <AccessibleIcon icon="ri-settings-5-line" label={t('openSettings')} />
            {t('openSettings')}
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={handleModalItemSelect(ModalKey.FOLDER_DELETE)}
            className="cursor-pointer text-states-negative-active"
          >
            <AccessibleIcon icon="ri-delete-bin-5-line" label={t('deleteFolder')} />
            {t('deleteFolder')}
          </DropdownMenu.Item>
        </>
      );
    } else {
      return (
        <>
          <DropdownMenu.Item onSelect={handleModalItemSelect(ModalKey.DOCUMENT_SETTINGS)}>
            <AccessibleIcon icon="ri-settings-5-line" label={t('openSettings')} />
            {t('openSettings')}
          </DropdownMenu.Item>
          <DropdownMenu.Item
            className="text-states-negative-active"
            onSelect={handleModalItemSelect(ModalKey.DOCUMENT_DELETE)}
          >
            <AccessibleIcon icon="ri-delete-bin-5-line" label={t('deleteDocument')} />
            {t('deleteDocument')}
          </DropdownMenu.Item>
        </>
      );
    }
  };

  const actionButton = (
    <ButtonLink
      href={
        isFolderItem(data)
          ? `/documents/folder/${cell.row.original.item.id}`
          : `/documents/show/${cell.row.original.item.id}`
      }
      size="sm"
      color="primary"
      variant="soft"
    >
      {isFolderItem(data) ? t('openFolder') : t('seeDetails')}
    </ButtonLink>
  );

  const canManageFileOrFolder = currentAccount.subscriptionInfo.isActive;

  return (
    <div className={'flex flex-row justify-end gap-3'}>
      <Show when={!isFolderItem(data)}>
        <Button variant="soft" color="primary" size="sm" onClick={() => toggleExpanded(!isExpanded)}>
          <Show
            when={isExpanded}
            fallback={<AccessibleIcon label="arrow-down" icon="ri-arrow-down-s-line" className="text-2xl " />}
          >
            <AccessibleIcon label="arrow-up" icon="ri-arrow-up-s-line" className="text-2xl " />
          </Show>
        </Button>
      </Show>
      {actionButton}
      <Show when={canManageFileOrFolder}>
        <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
          <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
            <AccessibleIcon
              ref={menuTriggerRef}
              icon="ri-more-2-fill"
              label="ri-more-2-fill"
              className="cursor-pointer text-lg text-primary-active"
            />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">{renderContent()}</DropdownMenu.Content>
        </DropdownMenu.Root>
      </Show>
      <DocumentSettingsModal
        documentId={id}
        onConfirm={handleConfirmSettings}
        {...modalProps(ModalKey.DOCUMENT_SETTINGS)}
      />
      <DeleteDocumentModal documentId={id} {...modalProps(ModalKey.DOCUMENT_DELETE)} />
      <FolderSettingsModal
        folderId={id}
        // close modal on confirm
        onConfirm={() => setModalOpen(null)}
        {...modalProps(ModalKey.FOLDER_SETTINGS)}
      />
      <DeleteFolderModal folderId={id} {...modalProps(ModalKey.FOLDER_DELETE)} />
    </div>
  );
};
