import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner'; // @todo: v2
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Checkbox } from '@/components/v2/Checkbox';
import { FileContainer } from '@/components/v2/FileContainer';
import FileUploader from '@/components/v2/FileUploader';
import { InlineMessage } from '@/components/v2/InlineMessage';
import { Navbar } from '@/components/v2/Navbar';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { HELP_URL } from '@/config/urls';
import { useDocumentUpload } from '@/hooks/useDocumentUpload';
import { useGetDocumentDetailQuery, useUploadDocumentRevisionMutation } from '@/redux/api';
import { assert } from '@/utils/assert';
import { to } from '@/utils/awaitToJs';
import { standardDateTimeFormat } from '@/utils/date';
import { sizeToMb } from '@/utils/sizeToMb';

import { acceptCreator } from './acceptCreator';
import { ConfirmRevisionRollbackModal } from './components/ConfirmRevisionRollbackModal';
import { SourceDocumentHeader } from './components/SourceDocumentHeader';
import { MAX_ONE_FILES, MAX_SIZE } from './constants';

export const DocumentRevision = () => {
  const { t } = useTranslation('documentRevision');

  const { documentId } = useDocumentParams();
  const { data: documentDetail, isUninitialized, isLoading } = useGetDocumentDetailQuery(documentId);
  const [uploadDocumentRevision] = useUploadDocumentRevisionMutation();
  const { files, onDrop, handleOnClose } = useDocumentUpload({ maxFiles: MAX_ONE_FILES });

  //
  // Form initialization
  const formSchema = z.object({
    skipNotification: z.boolean().optional(),
    file: z.instanceof(File)
  });

  const { control, register, formState, handleSubmit } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      skipNotification: false
    }
  });
  // End form initialization
  //

  //
  // Form submit
  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { stashId } = files[0];

    if (stashId) {
      // Confirm the upload of revision by passing the stashedFileId to uploadDocumentRevision
      const [, response] = await to(
        uploadDocumentRevision({
          documentId,
          stashedFileId: stashId,
          skipNotification: data.skipNotification
        }).unwrap()
      );

      if (response) {
        window.location.href = `/documents/show/${response.newRevisionDocumentId}`;
      }
    }
  };
  // End form submit
  //

  // Page management
  const handleCloseRevisionPage = () => {
    window.location.href = `/documents/show/${documentId}`;
  };

  if (isUninitialized || isLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  assert(documentDetail, 'Document data not found');
  const dividedDocumentName = documentDetail.sourceDocument.filename.split('.');
  const documentExtension = dividedDocumentName[dividedDocumentName.length - 1];

  return (
    <div className="min-h-screen bg-surface-background">
      <Helmet>
        <title>{t('revisionDocument')}</title>
      </Helmet>

      {/* Header */}
      <Navbar sticky>
        <Button variant="surface" color="secondary" onClick={handleCloseRevisionPage} className="ml-auto">
          {t('close')}
        </Button>
      </Navbar>

      <SourceDocumentHeader sourceDocument={documentDetail.sourceDocument} />

      <div className="mx-auto w-full max-w-4xl px-16 pt-20">
        <Text color="secondary">
          {t('youAreUpdatingTheDocument')} “{documentDetail.sourceDocument.filename}“ ({t('revisionNumber')}
          {documentDetail.sourceDocument.revision}) {t('uploadedOn')}{' '}
          {standardDateTimeFormat(documentDetail.sourceDocument.updatedAt)}
        </Text>
        <div className="flex items-center space-x-2 pt-12">
          <Text size="lg" color="primary" className="font-bold">
            {t('uploadTheNewFileRevisionIn')} <span className="uppercase">{documentExtension}</span> {t('format')}
          </Text>
          <div>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <AccessibleIcon
                  className="cursor-help text-xl text-blue-600 hover:text-blue-800"
                  label="ri-information-2-line"
                  icon="ri-information-2-line"
                />
              </Tooltip.Trigger>
              <Tooltip.Content side="right" className="flex max-w-xxs flex-col gap-1">
                {t('tooltipEveryIdenticalTranslation')}
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Root>
          </div>
        </div>
        <div className="mt-3">
          <Text size="md" color="secondary">
            Max. {MAX_ONE_FILES} file
          </Text>
        </div>
      </div>
      {/* End Header */}

      {/* Upload form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-auto w-full max-w-4xl space-y-7 px-16 py-12">
          <div className="w-full max-w-md">
            <Controller
              name="file"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div>
                  <FileUploader.Root
                    dropzoneOptions={{
                      maxFiles: MAX_ONE_FILES,
                      maxSize: MAX_SIZE,
                      onDrop: (files, fileRejection) => {
                        field.onChange(files[0]);
                        onDrop(files, fileRejection);
                      },
                      accept: acceptCreator(documentExtension)
                    }}
                  >
                    <FileUploader.DragArea>
                      <FileUploader.DragAreaContent>
                        <AccessibleIcon
                          icon="ri-upload-2-line"
                          label="ri-upload-2-line"
                          className="text-4xl text-alpha-400"
                        />
                        <FileUploader.DragAreaTitle>{t('dragAndDropFiles')}</FileUploader.DragAreaTitle>
                        <FileUploader.DragAreaDescription>
                          {t('supportedFileFormat')} {documentExtension}
                        </FileUploader.DragAreaDescription>
                      </FileUploader.DragAreaContent>
                      <FileUploader.Divider className="py-4">{t('or')}</FileUploader.Divider>
                      <FileUploader.Button>{t('browseFiles')}</FileUploader.Button>
                    </FileUploader.DragArea>
                  </FileUploader.Root>
                  {files.map((file, i) => (
                    <div className="mt-4" key={i}>
                      <FileContainer
                        id={file.id}
                        fileName={file.name}
                        fileSize={`${sizeToMb(file.size)} MB`}
                        status={file.status}
                        percent={file.percent}
                        helperMessage={file.status === 'error' && file.errorMessage ? file.errorMessage : ''}
                        onClose={(payload) => {
                          field.onChange();
                          handleOnClose(payload);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            />
            <Controller
              name="skipNotification"
              control={control}
              render={({ field }) => (
                <div className="flex items-center space-x-3 pt-6">
                  <Checkbox
                    id="notifications_to_translators"
                    {...register('skipNotification')}
                    onCheckedChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                  <label className="Label" htmlFor="notifications_to_translators">
                    {t('dontSendNotificationsToTheTranslators')}
                  </label>
                </div>
              )}
            />

            <Button variant="text" color="primary" className="mt-7" asChild>
              <a href={`${HELP_URL}/article/16-uploading-revisions`} target="_blank" rel="noreferrer">
                <AccessibleIcon className="text-xl" label="ri-lightbulb-fill" icon="ri-lightbulb-fill" />
                {t('seeHowDocumentRevisionWorks')}
              </a>
            </Button>
          </div>
          <div className="flex justify-end">
            <Button size="md" color="primary" disabled={!formState.isValid} type="submit">
              {t('continue')}
              <AccessibleIcon className="text-xl" label="ri-arrow-right-line" icon="ri-arrow-right-line" />
            </Button>
          </div>
        </div>
      </form>

      {/* Inline message */}
      {documentDetail.sourceDocument.revision > 1 ? (
        <div className="mx-auto w-full max-w-4xl px-16">
          <InlineMessage type="warning" orientation="horizontal">
            <Text size="sm" weight="semibold" className="" color="primary">
              {t('rollbackInlineMessageTitle')}
            </Text>
            <Text size="sm" weight="regular" className="mt-2">
              {t('rollbackInlineMessageText')}
            </Text>
            <div className="mt-6 flex justify-end">
              <ConfirmRevisionRollbackModal
                documentId={documentId}
                documentDetails={{
                  name: documentDetail.previousRevision.filename,
                  uploadDate: documentDetail.previousRevision.createdAt
                    ? standardDateTimeFormat(documentDetail.previousRevision.createdAt)
                    : '',
                  revision: documentDetail.previousRevision.revision
                }}
              >
                <Button variant="surface" color="secondary" size="md">
                  {t('revertToPreviousRevision')}
                </Button>
              </ConfirmRevisionRollbackModal>
            </div>
          </InlineMessage>
        </div>
      ) : null}
    </div>
  );
};

const useDocumentParams = () => {
  const params = useParams<{ documentId: string }>();
  const parsedParams = useMemo(() => ({ documentId: parseInt(params.documentId) }), [params]);
  return parsedParams;
};
