import {
  FileUploader,
  FileUploaderBottomText,
  FileUploaderButton,
  FileUploaderDescription,
  FileUploaderDragArea,
  FileUploaderDragAreaContent,
  FileUploaderDivider,
  FileUploaderTitle
} from './FileUploader';

const FileUploaderDefault = {
  Root: FileUploader,
  DragArea: FileUploaderDragArea,
  DragAreaContent: FileUploaderDragAreaContent,
  DragAreaTitle: FileUploaderTitle,
  DragAreaDescription: FileUploaderDescription,
  Button: FileUploaderButton,
  Divider: FileUploaderDivider,
  BottomText: FileUploaderBottomText
};

export {
  FileUploader,
  FileUploaderButton,
  FileUploaderDivider,
  FileUploaderBottomText,
  FileUploaderDescription,
  FileUploaderDragArea,
  FileUploaderDragAreaContent,
  FileUploaderTitle
} from './FileUploader';

export default FileUploaderDefault;
