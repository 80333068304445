import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { tag } from './styles';
import type { TagElement, TagProps } from './types';

const Tag = forwardRef<TagElement, TagProps>(({ asChild, className, color, size, variant, ...props }, ref) => {
  const Component = asChild ? Slot : 'span';
  return <Component {...props} ref={ref} className={classNames(tag({ color, size, variant }), className)} />;
});
Tag.displayName = 'Tag';

export { Tag };
