import { isFunction } from '@/utils/isFunction';

import type { IShowProps } from './types';

export const Show = <T,>({ when, fallback, children }: IShowProps<T>) => {
  if (when) {
    return <>{isFunction(children) ? children(when) : children}</>;
  }

  return <>{fallback}</>;
};
