import { MachineTranslationVendor } from '@/redux/api/constants';
import { DocumentTranslationDto } from '@/redux/api/types';

export interface LaunchPreTranslateFormProps {
  translations: DocumentTranslationDto[];
  onSubmit?: (data: LaunchPreTranslateFormValues) => void | Promise<void>;
}

export interface LaunchPreTranslateFormValues {
  languages: Record<string, { tm: boolean; mt: boolean; mtVendor: MachineTranslationVendor }>;
  tm: TmOption;
  mt: MtOption;
}

export enum TmOption {
  MARK_AS_CONFIRMED = 'MARK_AS_CONFIRMED',
  MARK_AS_DRAFT = 'MARK_AS_DRAFT',
  DISABLE = 'DISABLE'
}

export enum MtOption {
  MARK_AS_CONFIRMED = 'MARK_AS_CONFIRMED',
  MARK_AS_DRAFT = 'MARK_AS_DRAFT'
}
