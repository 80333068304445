import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Show } from '@/components/v2/Show';

interface SearchProps {
  searchTerm?: string;
  onSearch: (value: string) => void;
}

export const Search = ({ searchTerm, onSearch }: SearchProps) => {
  const { t: tDocumentList } = useTranslation('documentList');
  const [value, setValue] = useState(searchTerm ?? '');

  // if user navigate inside a folder and then go back
  // show the last value
  useEffect(() => {
    setValue(searchTerm ?? '');
  }, [searchTerm]);

  const reset = () => {
    setValue('');
    onSearch('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(value);
    }
  };

  return (
    <div className="flex h-9 flex-row gap-3">
      <div
        className="min-w-select-trigger flex w-full max-w-[284px] gap-2.5 rounded-xl border border-gray-200 bg-white px-4"
        onClick={(e) => e.preventDefault()}
      >
        <AccessibleIcon label="ri-search-line" icon="ri-search-line" className="text-lg text-gray-600" />
        <input
          className="outline-none mr-auto flex-1 py-2 text-base text-typography-primary placeholder-typography-tertiary"
          placeholder={tDocumentList('searchByFileOrByFolder')}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Show when={Boolean(value)}>
          <AccessibleIcon
            label="ri-close-circle-fill"
            icon="ri-close-circle-fill"
            className="cursor-pointer text-lg text-gray-600"
            onClick={reset}
          />
        </Show>
      </div>
      <Button variant="surface" size="sm" onClick={() => onSearch(value ?? '')}>
        {tDocumentList('search')}
      </Button>
    </div>
  );
};
