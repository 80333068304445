import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BillingFrequencyEnum } from '@/redux/api/types';

const getTranslationKey = (billingFrequency: BillingFrequencyEnum) => {
  switch (billingFrequency) {
    case BillingFrequencyEnum.MONTHLY:
      return 'billingFrequency.monthly' as const;
    case BillingFrequencyEnum.YEARLY:
      return 'billingFrequency.yearly' as const;
  }
};

const useBillingFrequencyTranslation = () => {
  const { t: tGlobal, ...translation } = useTranslation('global');

  const t = useCallback(
    (billingFrequency: BillingFrequencyEnum) => {
      const key = getTranslationKey(billingFrequency);
      return tGlobal(key);
    },
    [tGlobal]
  );

  return { ...translation, t };
};

export { useBillingFrequencyTranslation };
