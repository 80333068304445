import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { ManagerDto } from '@/redux/api/types';

import { ConfirmRemoveManagerModal } from './ConfirmRemoveManagerModal';

export const SettingsManagersTableActionsCell = ({
  manager,
  members
}: {
  manager: ManagerDto;
  members?: ManagerDto[];
}) => {
  const { t: tSettings } = useTranslation('settings');

  const [confirmRemoveManagerModalIsOpen, setConfirmRemoveManagerModalIsOpen] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  /* Hidden until API implementation */
  // https://redokun.slack.com/archives/C06NANJENGM/p1717762685956569?thread_ts=1717756034.113759&cid=C06NANJENGM
  // const [inviteManagerTrigger] = useInviteManagerInTeamMutation();
  // const reinviteManagerMenuAction = async () => {
  //   const [err, res] = await to(inviteManagerTrigger({ email: manager.email }).unwrap());
  //
  //   if (!err && res.status === 'ok') {
  //     toast({ title: tSettings('managerInvitedSuccessfullyToastTitle'), kind: 'success' });
  //   }
  // };

  const renderContent = () => {
    return (
      <>
        {/* Hidden until API implementation */}
        {/* {manager.status === ManagerDtoStatus.INVITED ? ( */}
        {/*   <DropdownMenu.Item */}
        {/*     onSelect={() => { */}
        {/*       setMenuOpen(false); */}
        {/*       reinviteManagerMenuAction(); */}
        {/*     }} */}
        {/*   > */}
        {/*     <AccessibleIcon icon="ri-send-plane-line" label={tSettings('resendInvitation')} /> */}
        {/*     {tSettings('resendInvitation')} */}
        {/*   </DropdownMenu.Item> */}
        {/* ) : null} */}
        <DropdownMenu.Item
          className="cursor-pointer text-states-negative-active"
          onSelect={() => {
            setMenuOpen(false);
            setConfirmRemoveManagerModalIsOpen(true);
          }}
        >
          <AccessibleIcon icon="ri-delete-bin-line" label={tSettings('removeManager')} />
          {tSettings('removeManager')}
        </DropdownMenu.Item>
      </>
    );
  };

  return (
    <div className="flex flex-row justify-end gap-3">
      <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
          <AccessibleIcon
            ref={menuTriggerRef}
            icon="ri-more-2-fill"
            label="ri-more-2-fill"
            className="cursor-pointer text-lg text-primary-active"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end">{renderContent()}</DropdownMenu.Content>
      </DropdownMenu.Root>

      <ConfirmRemoveManagerModal
        open={confirmRemoveManagerModalIsOpen}
        onOpenChange={setConfirmRemoveManagerModalIsOpen}
        managerAccountId={manager.id}
        members={members}
      />
    </div>
  );
};
