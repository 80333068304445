import { useTranslation } from 'react-i18next';

import { Text } from '@/components/v2/Text';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

import { Box } from './Box';
import { TeamDetailsActions } from './TeamDetailsActions';

export const TeamDetails = () => {
  const { t: tSettings } = useTranslation('settings');
  const { currentAccount } = useCurrentAccount();
  const { subscriptionInfo } = currentAccount;

  const teamName = (
    <div>
      <Text size="md" color="tertiary" className="font-bold uppercase">
        {tSettings('team')}
      </Text>
      <Text size="md" color="primary" className="mt-3 font-bold">
        {subscriptionInfo.teamName}
      </Text>
      <Text size="md" color="secondary" className="">
        {subscriptionInfo.organization}
      </Text>
    </div>
  );

  const managersCount = (
    <div className="pt-6">
      <Text size="md" color="tertiary" className="">
        {tSettings('managers')}
      </Text>
      <Text size="lg" className="font-semibold text-green-600">
        {subscriptionInfo.teamSize}/{subscriptionInfo.maxTeamSize}
      </Text>
    </div>
  );

  return (
    <Show when={subscriptionInfo.isInATeam}>
      <Box>
        <div className="grid grid-cols-2 gap-3">
          {teamName}
          {managersCount}
        </div>

        <TeamDetailsActions currentAccount={currentAccount} />
      </Box>
    </Show>
  );
};
