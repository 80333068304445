import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@/icons/solid';
import { isNonNullable } from '@/utils/isNonNullable';

import { FIRST_PAGE, MAX_ELEMENTS } from './constants';
import { IPaginationProps } from './types';
import { constrainPage, getPagesRange } from './utils';

export const Pagination: FunctionComponent<IPaginationProps> = ({
  current,
  defaultCurrent = FIRST_PAGE,
  pageSize,
  total,
  onChange,
  className,
  disabled
}) => {
  const [currentPage, setCurrentPage] = useState(isNonNullable(current) ? current : defaultCurrent);
  const [visiblePages, setVisiblePages] = useState<number[]>([]);
  const lastPage = Math.floor((total - 1) / pageSize) + 1;
  const previousPageDisabled = currentPage === FIRST_PAGE || total < 1 || disabled;
  const nextPageDisabled = currentPage === lastPage || total < 1 || disabled;

  useEffect(() => {
    if (isNonNullable(current)) {
      setCurrentPage(current);
    }
  }, [current]);

  useEffect(() => {
    if (isNonNullable(current)) {
      setVisiblePages(getPagesRange(lastPage, MAX_ELEMENTS, current));
    }
  }, [lastPage, current]);

  const handleChange = (page: number) => {
    const newPage = constrainPage(page, FIRST_PAGE, lastPage);
    if (!isNonNullable(current)) {
      setCurrentPage(newPage);
    }
    onChange?.(newPage);
  };

  return (
    <nav className={classNames('inline-flex h-8 text-sm text-gray-800', className)} aria-label="pagination">
      <ul className="inline-flex">
        <li className="flex">
          <button
            className={classNames('inline-flex items-center rounded-l border border-gray-300 px-2', {
              'opacity-50 hover:cursor-not-allowed': previousPageDisabled,
              'hover:bg-gray-100': !previousPageDisabled
            })}
            disabled={previousPageDisabled}
            onClick={() => {
              handleChange(currentPage - 1);
            }}
          >
            <ChevronLeftIcon aria-hidden="true" />
          </button>
        </li>

        {visiblePages.map((page) => {
          const isCurrentPage = page === currentPage;

          return (
            <li key={page} aria-current={isCurrentPage ? 'page' : undefined} className="flex">
              <button
                className={classNames('inline-flex items-center border px-4', {
                  'border-blue-500 font-bold text-blue-500': isCurrentPage,
                  'border-gray-300': !isCurrentPage,
                  'hover:bg-blue-100': isCurrentPage && !disabled,
                  'hover:bg-gray-100': !isCurrentPage && !disabled,
                  'opacity-50 hover:cursor-not-allowed': disabled
                })}
                disabled={isCurrentPage || disabled}
                onClick={() => {
                  handleChange(page);
                }}
              >
                {page}
              </button>
            </li>
          );
        })}

        <li className="flex">
          <button
            className={classNames('inline-flex items-center rounded-r border border-gray-300 px-2', {
              'opacity-50 hover:cursor-not-allowed': nextPageDisabled,
              'hover:bg-gray-100': !nextPageDisabled
            })}
            disabled={nextPageDisabled}
            onClick={() => {
              handleChange(currentPage + 1);
            }}
          >
            <ChevronRightIcon aria-hidden="true" />
          </button>
        </li>
      </ul>
    </nav>
  );
};
