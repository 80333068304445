import { CardNumberElement as StripeCardNumberElement } from '@stripe/react-stripe-js';
import { ComponentPropsWithoutRef } from 'react';

import { defaultClasses, defaultStyle } from '../styles';

const CardNumberElement = (props: CardNumberElementProps) => {
  const { options: optionsProp, ...cardNumberElementProps } = props;
  const options = {
    ...optionsProp,
    style: { ...defaultStyle, ...optionsProp?.style },
    classes: { ...defaultClasses, ...optionsProp?.classes }
  };

  return <StripeCardNumberElement options={options} {...cardNumberElementProps} />;
};
CardNumberElement.displayName = 'CardNumberElement';

type CardNumberElementProps = ComponentPropsWithoutRef<typeof StripeCardNumberElement>;

export { CardNumberElement };
export type { CardNumberElementProps };
