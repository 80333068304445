import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { FileContainer } from '@/components/v2/FileContainer';
import FileUploader from '@/components/v2/FileUploader';
import InlineMessage from '@/components/v2/InlineMessage';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { HELP_URL } from '@/config/urls';
import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { useDocumentUpload } from '@/hooks/useDocumentUpload';
import { useRouterParamsQuery } from '@/hooks/useRouterParamsQuery';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetFoldersQuery } from '@/redux/api';
import { useAppDispatch } from '@/redux/hooks';
import { stashFiles } from '@/redux/slices/uploadDocumentsSlice';
import { useUploadDocument } from '@/routes/UploadDocuments/UploadDocumentProvider';
import { uploadingDocumentSteps } from '@/routes/UploadDocuments/constants';
import { ChangeFolderModal } from '@/routes/UploadDocuments/steps/UploadFile/components/ChangeFolderModal';
import { MAX_ONE_FILES, MAX_SIZE, MAX_TWENTY_FILES } from '@/routes/UploadDocuments/steps/UploadFile/constants';
import { EUploadingDocumentSteps } from '@/routes/UploadDocuments/types';
import { getCurrentFolder, getPathToFolder } from '@/utils/folder';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { sanitizeUrl } from '@/utils/sanitizeUrl';
import Tooltip from '@/components/v2/Tooltip';

export const UploadFile: React.FC = () => {
  const { t: tUploadDocuments } = useTranslation('uploadDocuments');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentAccount, refetch } = useCurrentAccount();
  const { data } = useGetFoldersQuery();
  const [openChangeFolderModal, setOpenChangeFolderModal] = useState(false);

  const query = useRouterParamsQuery();

  // set to undefined since Number of null is 0
  const folderId = Number(query.get('folderId') ?? undefined);

  // check if folderId is not NaN
  const currentFolderId = !isNaN(folderId) ? folderId : undefined;
  const pathToFolder = getPathToFolder(currentFolderId, data?.children);
  const currentFolder = getCurrentFolder(pathToFolder, currentFolderId);

  const {
    multiple = false,
    showSuggestions = false,
    maxFileSize = MAX_SIZE,
    welcome
  } = useUploadDocument('UploadFile');
  const maxFiles = multiple ? MAX_TWENTY_FILES : MAX_ONE_FILES;

  const { files, onDrop, handleOnClose, isUploading } = useDocumentUpload({
    maxFiles,
    folderId: currentFolderId
  });

  useEffect(() => {
    if (!currentAccount.onboardingFlags.hasConfirmedAnyDocument && welcome) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStep = () => {
    if (isNotEmpty(files)) {
      dispatch(
        stashFiles(files.map((f) => ({ fileId: f.stashId, filename: f.name, importNumericData: f.importNumericData })))
      );
      const step = uploadingDocumentSteps.find((s) => s.id === EUploadingDocumentSteps.SET_SOURCE_LANGUAGE);

      if (step) {
        if (welcome) {
          history.replace(sanitizeUrl(`/welcome/${step.path}`));
        } else {
          const path = currentFolderId ? `${step.path}?folderId=${currentFolderId}` : step.path;
          history.push(path);
        }
      }
    }
  };

  const handleOnChangeFolderConfirmed = (folderId?: string) => {
    // close modal
    setOpenChangeFolderModal(false);
    // if folderId is not document root or is a number, update page route
    if (folderId !== DOCUMENT_LIST_MOCK_ID && !isNaN(Number(folderId))) {
      history.push({
        pathname: history.location.pathname,
        search: `?folderId=${folderId}`
      });
    }
    if (folderId === DOCUMENT_LIST_MOCK_ID) {
      history.push({
        pathname: history.location.pathname
      });
    }
  };

  const sizeToMb = (size: number) => {
    return (size / (1024 * 1024)).toFixed(2);
  };

  const disabled = files.length === 0 || !files.every((f) => f.stashId !== undefined);
  const fileSize = sizeToMb(maxFileSize);
  const cannotUploadMoreFiles = !multiple && files.length > 0;
  const disableUploading = cannotUploadMoreFiles || isUploading;

  return (
    <div className="flex w-full flex-col items-center gap-8 pb-8">
      <Show when={showSuggestions}>
        <InlineMessage.Root rootClassName="w-full" orientation="vertical" type="warning">
          <InlineMessage.Title>{tUploadDocuments('youCanOnlyUploadOneDocument')}</InlineMessage.Title>
          <InlineMessage.Text>{tUploadDocuments('weRecommendUploadingFilesWithMaximumWords')}</InlineMessage.Text>
        </InlineMessage.Root>
      </Show>
      <div className="flex w-full flex-col gap-4">
        <div>
          <Show
            when={!welcome}
            fallback={
              <Title size="sm" weight="bold">
                {tUploadDocuments('uploadFiles')}
              </Title>
            }
          >
            <span className="flex flex-row gap-3">
              <Title size="sm" weight="bold">
                {tUploadDocuments('uploadFilesTo')}
              </Title>
              <ChangeFolderModal
                open={openChangeFolderModal}
                onOpenChange={setOpenChangeFolderModal}
                defaultSelectedItem={currentFolderId?.toString()}
                onConfirm={handleOnChangeFolderConfirmed}
              >
                <Button
                  variant="text"
                  color="primary"
                  className="max-w-xs gap-1"
                  onClick={() => setOpenChangeFolderModal(true)}
                >
                  <AccessibleIcon
                    label="ri-folder-3-fill"
                    icon="ri-folder-3-fill"
                    className="text-states-alert-active"
                  />
                  <Show when={currentFolder !== undefined} fallback={'Document list'}>
                    <span className=" overflow-hidden overflow-ellipsis break-words">{currentFolder?.name}</span>
                  </Show>
                </Button>
              </ChangeFolderModal>
            </span>
          </Show>

          <Text size="xs">{`Max. ${maxFiles} files, ${parseInt(fileSize)} MB`}</Text>
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-1/2">
            <FileUploader.Root
              dropzoneOptions={{
                maxFiles,
                maxSize: maxFileSize,
                onDrop,
                disabled: disableUploading
              }}
            >
              <FileUploader.DragArea>
                <FileUploader.DragAreaContent>
                  <AccessibleIcon
                    icon="ri-upload-2-line"
                    label="ri-upload-2-line"
                    className="text-4xl text-alpha-400"
                  />
                  <FileUploader.DragAreaTitle>{tUploadDocuments('dragAndDropFiles')}</FileUploader.DragAreaTitle>
                  <FileUploader.DragAreaDescription>
                    {tUploadDocuments('supportedFileFormats')}
                  </FileUploader.DragAreaDescription>
                </FileUploader.DragAreaContent>
                <FileUploader.Divider className="py-4">{tUploadDocuments('or')}</FileUploader.Divider>

                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <FileUploader.Button disabled={disableUploading}>
                      {tUploadDocuments('browseFiles')}
                    </FileUploader.Button>
                  </Tooltip.Trigger>
                  {/* Show tooltip if user cannot upload more files */}
                  <Show when={cannotUploadMoreFiles}>
                    <Tooltip.Content className="max-w-xxs" side="right">
                      <Text className="text-typography-inverse-primary" size="sm" weight="medium">
                        {tUploadDocuments('youCanUploadMaxFiles', { maxFiles: maxFiles })}
                      </Text>
                      <Tooltip.Arrow />
                    </Tooltip.Content>
                  </Show>
                </Tooltip.Root>
              </FileUploader.DragArea>
            </FileUploader.Root>
            <Button variant="text" color="primary" className="mt-7" asChild>
              <a href={`${HELP_URL}/article/55-supported-file-formats`} target="_blank" rel="noreferrer">
                <AccessibleIcon icon="ri-lightbulb-fill" label="light-bulb" />
                {tUploadDocuments('seeCompleteListOfFileFormats')}
              </a>
            </Button>
          </div>
          <div className="flex w-1/2 flex-col gap-2">
            {files.map((file, index) => (
              <FileContainer
                id={file.id}
                key={index}
                fileName={file.name}
                fileSize={`${sizeToMb(file.size)} MB`}
                status={file.status}
                percent={file.percent}
                helperMessage={file.status === 'error' && file.errorMessage ? file.errorMessage : ''}
                onClose={handleOnClose}
              />
            ))}
          </div>
        </div>
      </div>
      <Button variant="solid" color="primary" size="md" className="ml-auto" disabled={disabled} onClick={nextStep}>
        {tUploadDocuments('continue')}
        <AccessibleIcon label="arrow-right" icon="ri-arrow-right-line" />
      </Button>
    </div>
  );
};
