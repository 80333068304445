import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Text } from '@/components/v2/Text';
import { Label } from '@/components/v2/Label';
import { Input } from '@/components/v2/Input';
import { Button } from '@/components/v2/Button';
import { Spinner } from '@/components/v1/Spinner';
import { to } from '@/utils/awaitToJs';
import { useToast } from '@/components/v2/Toast';
import { useInviteManagerInTeamMutation } from '@/redux/api/teamApi';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

import { Box } from './Box';

export const InviteManager = () => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const { currentAccount } = useCurrentAccount();
  const { subscriptionInfo } = currentAccount;

  const [inviteManagerTrigger, inviteManagerResult] = useInviteManagerInTeamMutation();

  const formSchemaManagerInvitation = z.object({
    email: z.string().email()
  });

  const {
    handleSubmit: inviteManagerHadleSubmit,
    control: inviteManagerControl,
    reset: inviteManagerResetForm
  } = useForm<z.infer<typeof formSchemaManagerInvitation>>({
    resolver: zodResolver(formSchemaManagerInvitation),
    defaultValues: {
      email: ''
    }
  });

  const onSubmitInviteManager = async ({ email }: z.infer<typeof formSchemaManagerInvitation>) => {
    if (email) {
      const [err, res] = await to(inviteManagerTrigger({ email }).unwrap());

      if (!err && res.status === 'ok') {
        inviteManagerResetForm();

        toast({ title: tSettings('managerInvitedSuccessfullyToastTitle'), kind: 'success' });
      }
    }
  };

  // Render only if the user is the owner of the team
  return (
    <Show when={subscriptionInfo.teamOwnerAccountId === currentAccount.id && subscriptionInfo.isInATeam}>
      <Box>
        <div>
          <Text size="md" color="primary" className="font-bold">
            {tSettings('inviteNewManager')}
          </Text>
        </div>
        <div>
          <form onSubmit={inviteManagerHadleSubmit(onSubmitInviteManager)} className="space-y-4">
            <Label htmlFor="email" isRequired text={tSettings('email')}></Label>
            <div className="flex items-center space-x-3">
              <Controller
                name="email"
                control={inviteManagerControl}
                render={({ field }) => {
                  return (
                    <Input
                      type="email"
                      name="email"
                      onChange={field.onChange}
                      value={field.value}
                      placeholder={tSettings('typeEmailAddress')}
                    />
                  );
                }}
              />
              <Button type="submit" disabled={inviteManagerResult.isLoading}>
                {inviteManagerResult.isLoading ? <Spinner size="sm" /> : null}
                {tSettings('sendInvitation')}
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </Show>
  );
};
