import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import { HELP_URL } from '@/config/urls';
import { Title } from '@/components/v2/Title';
import { Text } from '@/components/v2/Text';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';

export const CreateTeam: React.FC = () => {
  const { t: tGlossary } = useTranslation('glossary');
  return (
    <div className="flex flex-col gap-6">
      <Title size="lg">{tGlossary('glossary')}</Title>
      <div className="flex flex-col items-center justify-center gap-10 px-7 py-8">
        <div className="flex flex-col justify-center gap-2 text-center">
          <AccessibleIcon label="ri-team-line" icon="ri-team-line" className="text-3xl text-alpha-400" />
          <Text color="tertiary" weight="bold" size="md">
            {tGlossary('glossariesOnlyForTeams')}
          </Text>
          <Text color="tertiary" weight="regular">
            {tGlossary('createTeamToCreateGlossaries')}
          </Text>
        </div>
        <div className="flex flex-col items-center gap-6">
          <ButtonLink href="/settings/managers" variant="solid" color="primary" size="lg" className="max-w-[250px]">
            <AccessibleIcon label="ri-user-add-line" icon="ri-user-add-line" />
            {tGlossary('createTeam')}
          </ButtonLink>
          <Button variant="text" color="primary" className="mt-7" asChild>
            <a href={`${HELP_URL}/article/126-glossary-what-is-it-how-does-it-work`} target="_blank" rel="noreferrer">
              <AccessibleIcon icon="ri-lightbulb-fill" label="light-bulb" />
              {tGlossary('readMoreAboutHowTheGlossaryWorks')}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};
