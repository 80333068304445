import { redokunApi } from './redokunApi';
import type { GetOperationStatusResponseDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const operationApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get a long-running operation status. If the operation is completed, the response will also contain the operation result.
     */
    getOperationStatus: builder.query<GetOperationStatusResponseDto, number>({
      query: (operationId) => `operation/${operationId}`,
      providesTags: ['operationStatus']
    })
  })
});

export const { useGetOperationStatusQuery, useLazyGetOperationStatusQuery } = operationApi;

export const operationApiUtil = {
  ...operationApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(operationApi.util.updateQueryData)
};
