import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/v1/Modal';
import type { TConfirmModalProps } from '@/components/v1/Modal/types';
import { TextArea } from '@/components/v1/TextArea';
import { useSendFeedbackMutation } from '@/redux/api/feedbackApi';
import type { IFeedbackModalProps } from '@/redux/slices/modalsSlice/types/props';

export const FeedbackModal: FunctionComponent<TConfirmModalProps & { props: IFeedbackModalProps }> = ({
  ...modalProps
}) => {
  const { t } = useTranslation('modals');
  const [feedback, setFeedback] = useState('');
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();

  return (
    <Modal.Confirm
      cancelText={t('close')}
      okText={t('send')}
      okLoading={isLoading}
      onOk={() => {
        sendFeedback({ body: { body: feedback } }).then(() => {
          modalProps.onClose(false);
        });
      }}
      {...modalProps}
    >
      {{
        header: t('sendUsYourFeedback'),
        content: (
          <div className="space-y-4">
            <p>{t('weAlwaysLookingToImproveRedokunTellUs')}</p>

            <div>
              <TextArea
                placeholder={t('whatWouldYouChange')}
                containerClassName="w-full"
                className="max-h-64"
                rows={4}
                value={feedback}
                onChangeValue={setFeedback}
              />
            </div>
          </div>
        )
      }}
    </Modal.Confirm>
  );
};
