import { first } from 'lodash';
import { useTranslation } from 'react-i18next';

import Avatar, { AvatarGroup } from '@/components/v2/Avatar';
import DataTable from '@/components/v2/DataTable';
import { Show } from '@/components/v2/Show';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import type { DocumentTranslationDto } from '@/redux/api/types';
import { getFallbackName } from '@/routes/Document/utils';
import Tooltip from '@/components/v2/Tooltip';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

export const TranslatorsCell = () => {
  const { t: tDocument } = useTranslation('document');
  const { cell } = DataTable.useCellContext<DocumentTranslationDto, DocumentTranslationDto['translators']>(
    'TranslatorsCell'
  );
  const translators = cell.getValue();

  const { currentAccount } = useCurrentAccount();

  const firstTranslator = first(translators);
  const firstTranslatorName = firstTranslator?.id === currentAccount.id ? 'You' : firstTranslator?.fullName;

  const inviteNotAcceptedTranslatorsEmailList = translators.filter((t) => t.isNotAccepted).map((t) => t.email);
  const translatorsEmailListCount = inviteNotAcceptedTranslatorsEmailList.length;

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col">
        <AvatarGroup size="xs">
          {translators.map((translator, index) => (
            <Avatar.Root key={translator.id}>
              <Avatar.Image src={index % 2 === 0 ? translator.avatarUrl : ''} />
              <Avatar.Fallback>{getFallbackName(translator.fullName)}</Avatar.Fallback>
            </Avatar.Root>
          ))}
        </AvatarGroup>
      </div>

      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div>
            <Show when={translators.length > 1} fallback={firstTranslatorName}>
              {firstTranslatorName} and <Tag>{translators.length - 1} others</Tag>
            </Show>
          </div>
        </Tooltip.Trigger>
        <Show when={translators.length > 1}>
          <Tooltip.Content className="max-w-xxs" side="bottom">
            {/* ignore the first translator, since his name has already been shown */}
            {translators.slice(1).map((t) => (
              <Text key={t.id} className="text-typography-inverse-primary" size="sm" weight="medium">
                {t.fullName}
              </Text>
            ))}
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Show>
      </Tooltip.Root>
      <Show when={isNotEmpty(inviteNotAcceptedTranslatorsEmailList)}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <AccessibleIcon
              label="warning-icon"
              icon="ri-alert-line"
              className="text-base text-states-alert-active hover:text-states-alert-hover"
            />
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-xxs" side="bottom">
            <Text className="text-typography-inverse-primary" size="sm" weight="medium">
              {translators.length === 1
                ? tDocument('invitationPending')
                : tDocument('multipleTranslatorIsYetToAcceptYourInvitation', {
                    count: translatorsEmailListCount
                  })}
            </Text>
            {/* if there are more than 1 translator, show which translator did not accept the email invitation */}
            <Show when={translators.length > 1}>
              <Text className="text-typography-inverse-secondary" size="sm" weight="medium">
                {inviteNotAcceptedTranslatorsEmailList.map((t) => t).join('\n')}
              </Text>
            </Show>
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Tooltip.Root>
      </Show>
    </div>
  );
};
