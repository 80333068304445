import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type ParamKeyValuePair = [string, string];

type URLSearchParamsInit = string | ParamKeyValuePair[] | Record<string, string | string[]> | URLSearchParams;

type SetURLSearchParams = <HistoryLocationState = unknown>(
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  state?: HistoryLocationState
) => void;

function createSearchParams(init: URLSearchParamsInit = ''): URLSearchParams {
  return new URLSearchParams(
    typeof init === 'string' || Array.isArray(init) || init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          const value = init[key];
          return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]);
        }, [] as ParamKeyValuePair[])
  );
}

function getSearchParamsForLocation(locationSearch: string, defaultSearchParams: URLSearchParams | null) {
  const searchParams = createSearchParams(locationSearch);

  defaultSearchParams?.forEach((_, key) => {
    if (!searchParams.has(key)) {
      defaultSearchParams.getAll(key).forEach((value) => {
        searchParams.append(key, value);
      });
    }
  });

  return searchParams;
}

function useSearchParams(defaultInit?: URLSearchParamsInit): [URLSearchParams, SetURLSearchParams] {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  const hasSetSearchParamsRef = useRef(false);

  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(
    () =>
      getSearchParamsForLocation(
        location.search,
        hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current
      ),
    [location.search]
  );

  const setSearchParams = useCallback<SetURLSearchParams>(
    (nextInit, state) => {
      const newSearchParams = createSearchParams(typeof nextInit === 'function' ? nextInit(searchParams) : nextInit);
      hasSetSearchParamsRef.current = true;
      history.push({ search: `?${newSearchParams}`, state });
    },
    [history, searchParams]
  );

  return [searchParams, setSearchParams];
}

export { useSearchParams, createSearchParams, getSearchParamsForLocation };
export type { ParamKeyValuePair, URLSearchParamsInit, SetURLSearchParams };
