import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z, ZodType } from 'zod';
import { useHistory } from 'react-router-dom';

import { Layout } from '@/routes/CreateGlossary/components/Layout';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { Button } from '@/components/v2/Button';
import { LanguageCombobox } from '@/routes/CreateGlossary/components/LanguageCombobox';
import { useGetLanguagesQuery } from '@/redux/api';
import { createLanguageGroups } from '@/routes/CreateGlossary/utils';
import { useCreateGlossaryMutation } from '@/redux/api/glossaryApi';
import { to } from '@/utils/awaitToJs';

export const CreateGlossary: React.FC = () => {
  const { t: tGlossary } = useTranslation('glossary');
  const history = useHistory();

  const { data, isLoading } = useGetLanguagesQuery();
  const [triggerCreateGlossary] = useCreateGlossaryMutation();

  const items = data ? createLanguageGroups(data, tGlossary) : [];

  const formSchema: ZodType = z.object({
    sourceLanguageId: z.number().positive(),
    targetLanguageId: z.number().positive()
  });

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { sourceLanguageId, targetLanguageId } = data;

    const [err, res] = await to(triggerCreateGlossary({ sourceLanguageId, targetLanguageId }).unwrap());
    if (!err && res.glossaryId) {
      history.push(`/glossaries/show/${res.glossaryId}`);
    }
  };

  const disabled = !isValid || isLoading;

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-7">
        <Surface className="flex flex-col gap-8 px-7 py-8">
          <Text size="lg" weight="bold" color="primary">
            {tGlossary('createNewGlossaryByPickingSourceAndTargetLanguages')}
          </Text>
          <div className="space-y-2">
            <Text>{tGlossary('sourceLanguage')}</Text>
            <Controller
              control={control}
              name="sourceLanguageId"
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <LanguageCombobox placeholder={tGlossary('selectLanguage')} items={items} onChange={onChange} />
              )}
            />
          </div>
          <div className="space-y-2">
            <Text>{tGlossary('targetLanguage')}</Text>
            <Controller
              control={control}
              name="targetLanguageId"
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <LanguageCombobox placeholder={tGlossary('targetLanguage')} items={items} onChange={onChange} />
              )}
            />
          </div>
        </Surface>
        <Button variant="solid" color="primary" className="self-end" type="submit" disabled={disabled}>
          {tGlossary('createGlossary')}
        </Button>
      </form>
    </Layout>
  );
};
