import { EPlaceholderType } from '../redux/api/constants';
import type { TPlaceholder } from '../redux/api/types';

const TYPE_MAP: Record<string, EPlaceholderType> = {
  'x-tag': EPlaceholderType.TAG,
  'x-marker': EPlaceholderType.MARKER
};

// @ts-expect-error: TS 5 migration error
const CTYPE_MAP: Record<EPlaceholderType, string> = {
  [EPlaceholderType.TAG]: 'x-tag',
  [EPlaceholderType.MARKER]: 'x-marker'
};

export const getPlaceholderFromXmlAttribs = (attribs: Record<string, string>): TPlaceholder | null => {
  const { id, ctype, 'equiv-text': equivText, 'x-desc': xDesc } = attribs;
  const type = TYPE_MAP[ctype];

  if (!id || !type) {
    return null;
  }

  return { id, type, code: equivText, label: xDesc };
};

export const getXmlAttribsFromPlaceholder = (placeholder: TPlaceholder): Record<string, string> | null => {
  const { id, type, code, label } = placeholder;
  const ctype = CTYPE_MAP[type];

  if (!id || !ctype) {
    return null;
  }

  const attribs: Record<string, string> = { id, ctype };

  if (code) {
    attribs['equiv-text'] = code;
  }
  if (label) {
    attribs['x-desc'] = label;
  }

  return attribs;
};
