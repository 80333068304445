import { cva } from 'cva';

export const popoverVariant = cva(['shadow z-50 rounded-lg  bg-surface-elements text-typography-primary'], {
  variants: {
    size: {
      sm: ['px-3 py-2 text-base'],
      md: ['px-5 py-2.5 text-base']
    }
  },
  defaultVariants: {
    size: 'sm'
  }
});
