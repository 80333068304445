import { Show } from '@/components/v1/Show';
import { DeepLIcon, GoogleTranslateIcon } from '@/icons/custom';
import { MachineTranslationVendor } from '@/redux/api/constants';

import type { IMTSuggestionIconProps } from './types';

export const MTSuggestionIcon = ({ vendor }: IMTSuggestionIconProps) => {
  const Icon = Icons[vendor];
  return <Show when={Icon}>{(Icon) => <Icon className="inline-flex w-4" />}</Show>;
};

const Icons = {
  [MachineTranslationVendor.DEEPL]: DeepLIcon,
  [MachineTranslationVendor.GOOGLE_TRANSLATE]: GoogleTranslateIcon
} as const;
