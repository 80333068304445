import { reduce } from 'lodash';
import { z } from 'zod';

import { CountryDto } from '@/redux/api/types';

const configureBillingDetailsFormSchema = (options: { countries: CountryDto[] }) => {
  const { countries } = options;

  // Validation could be triggerd a lot of times, so we want to have a fast lookup
  const countriesMap = reduce(
    countries,
    (map, country) => map.set(country.id, country),
    new Map<CountryDto['id'], CountryDto>()
  );

  const billingDetailsFormSchema = z
    .object({
      companyName: z.string().min(3).max(50),
      addressCountryId: z.coerce.number().positive().finite().safe(),
      addressStreet: z.string().min(3).max(75),
      addressCity: z.string().min(3).max(75),
      addressZipCode: z.string().min(3).max(10),
      addressProvince: z.string().min(2).or(z.literal('')),
      vatNumber: z.string().min(3).or(z.literal('')),
      billingEmail: z.string().email().or(z.literal('')),
      recipientCode: z
        .string()
        .regex(/^[a-zA-Z0-9]{7}$/)
        .length(7)
        .or(z.literal(''))
    })
    .superRefine((data, ctx) => {
      const country = countriesMap.get(data.addressCountryId);

      // Italy specific validation
      if (country?.isItaly) {
        if (!data.addressProvince) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            inclusive: true,
            minimum: 2,
            path: ['addressProvince'],
            type: 'string'
          });
        }
        if (!data.recipientCode) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            exact: true,
            inclusive: true,
            minimum: 7,
            path: ['recipientCode'],
            type: 'string'
          });
        }
      }

      // EU specific validation
      if (country?.isEu) {
        if (!data.vatNumber) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_small,
            inclusive: true,
            minimum: 3,
            path: ['vatNumber'],
            type: 'string'
          });
        }
      }
    });

  return billingDetailsFormSchema;
};

type BillingDetailsFormSchema = z.infer<ReturnType<typeof configureBillingDetailsFormSchema>>;

const defaultValues: BillingDetailsFormSchema = {
  companyName: '',
  addressCountryId: '' as unknown as number,
  addressStreet: '',
  addressCity: '',
  addressZipCode: '',
  addressProvince: '',
  vatNumber: '',
  billingEmail: '',
  recipientCode: ''
};

const provinces = [
  { key: 'AG', value: 'Agrigento' },
  { key: 'AL', value: 'Alessandria' },
  { key: 'AN', value: 'Ancona' },
  { key: 'AO', value: 'Aosta' },
  { key: 'AR', value: 'Arezzo' },
  { key: 'AP', value: 'Ascoli Piceno' },
  { key: 'AT', value: 'Asti' },
  { key: 'AV', value: 'Avellino' },
  { key: 'BA', value: 'Bari' },
  { key: 'BT', value: 'Barletta-Andria-Trani' },
  { key: 'BL', value: 'Belluno' },
  { key: 'BN', value: 'Benevento' },
  { key: 'BG', value: 'Bergamo' },
  { key: 'BI', value: 'Biella' },
  { key: 'BO', value: 'Bologna' },
  { key: 'BZ', value: 'Bolzano' },
  { key: 'BS', value: 'Brescia' },
  { key: 'BR', value: 'Brindisi' },
  { key: 'CA', value: 'Cagliari' },
  { key: 'CL', value: 'Caltanissetta' },
  { key: 'CB', value: 'Campobasso' },
  { key: 'CI', value: 'Carbonia-Iglesias' },
  { key: 'CE', value: 'Caserta' },
  { key: 'CT', value: 'Catania' },
  { key: 'CZ', value: 'Catanzaro' },
  { key: 'CH', value: 'Chieti' },
  { key: 'CO', value: 'Como' },
  { key: 'CS', value: 'Cosenza' },
  { key: 'CR', value: 'Cremona' },
  { key: 'KR', value: 'Crotone' },
  { key: 'CN', value: 'Cuneo' },
  { key: 'EN', value: 'Enna' },
  { key: 'FM', value: 'Fermo' },
  { key: 'FE', value: 'Ferrara' },
  { key: 'FI', value: 'Firenze' },
  { key: 'FG', value: 'Foggia' },
  { key: 'FC', value: 'Forlì-Cesena' },
  { key: 'FR', value: 'Frosinone' },
  { key: 'GE', value: 'Genova' },
  { key: 'GO', value: 'Gorizia' },
  { key: 'GR', value: 'Grosseto' },
  { key: 'IM', value: 'Imperia' },
  { key: 'IS', value: 'Isernia' },
  { key: 'SP', value: 'La Spezia' },
  { key: 'AQ', value: "L'Aquila" },
  { key: 'LT', value: 'Latina' },
  { key: 'LE', value: 'Lecce' },
  { key: 'LC', value: 'Lecco' },
  { key: 'LI', value: 'Livorno' },
  { key: 'LO', value: 'Lodi' },
  { key: 'LU', value: 'Lucca' },
  { key: 'MC', value: 'Macerata' },
  { key: 'MN', value: 'Mantova' },
  { key: 'MS', value: 'Massa-Carrara' },
  { key: 'MT', value: 'Matera' },
  { key: 'ME', value: 'Messina' },
  { key: 'MI', value: 'Milano' },
  { key: 'MO', value: 'Modena' },
  { key: 'MB', value: 'Monza e della Brianza' },
  { key: 'NA', value: 'Napoli' },
  { key: 'NO', value: 'Novara' },
  { key: 'NU', value: 'Nuoro' },
  { key: 'OT', value: 'Olbia-Tempio' },
  { key: 'OR', value: 'Oristano' },
  { key: 'PD', value: 'Padova' },
  { key: 'PA', value: 'Palermo' },
  { key: 'PR', value: 'Parma' },
  { key: 'PV', value: 'Pavia' },
  { key: 'PG', value: 'Perugia' },
  { key: 'PU', value: 'Pesaro e Urbino' },
  { key: 'PE', value: 'Pescara' },
  { key: 'PC', value: 'Piacenza' },
  { key: 'PI', value: 'Pisa' },
  { key: 'PT', value: 'Pistoia' },
  { key: 'PN', value: 'Pordenone' },
  { key: 'PZ', value: 'Potenza' },
  { key: 'PO', value: 'Prato' },
  { key: 'RG', value: 'Ragusa' },
  { key: 'RA', value: 'Ravenna' },
  { key: 'RC', value: 'Reggio Calabria' },
  { key: 'RE', value: 'Reggio Emilia' },
  { key: 'RI', value: 'Rieti' },
  { key: 'RN', value: 'Rimini' },
  { key: 'RM', value: 'Roma' },
  { key: 'RO', value: 'Rovigo' },
  { key: 'SA', value: 'Salerno' },
  { key: 'VS', value: 'Medio Campidano' },
  { key: 'SS', value: 'Sassari' },
  { key: 'SV', value: 'Savona' },
  { key: 'SI', value: 'Siena' },
  { key: 'SR', value: 'Siracusa' },
  { key: 'SO', value: 'Sondrio' },
  { key: 'TA', value: 'Taranto' },
  { key: 'TE', value: 'Teramo' },
  { key: 'TR', value: 'Terni' },
  { key: 'TO', value: 'Torino' },
  { key: 'OG', value: 'Ogliastra' },
  { key: 'TP', value: 'Trapani' },
  { key: 'TN', value: 'Trento' },
  { key: 'TV', value: 'Treviso' },
  { key: 'TS', value: 'Trieste' },
  { key: 'UD', value: 'Udine' },
  { key: 'VA', value: 'Varese' },
  { key: 'VE', value: 'Venezia' },
  { key: 'VB', value: 'Verbano-Cusio-Ossola' },
  { key: 'VC', value: 'Vercelli' },
  { key: 'VR', value: 'Verona' },
  { key: 'VV', value: 'Vibo Valentia' },
  { key: 'VI', value: 'Vicenza' },
  { key: 'VT', value: 'Viterbo' }
];

export { configureBillingDetailsFormSchema, defaultValues, provinces };
export type { BillingDetailsFormSchema };
