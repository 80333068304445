import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useUpdateFlagsMutation } from '@/redux/api';
import {
  OnboardingWizardContent,
  OnboardingWizardId,
  OnboardingWizardItem
} from '@/routes/Document/components/OnboardingWizardContent';
import { OnboardingWizardPortal } from '@/routes/Document/components/OnboardingWizardPortal';
import { createContext } from '@/utils/createContext';

import { OnboardingWizardContextType } from './types';

export const [Provider, useOnboardingWizard] = createContext<OnboardingWizardContextType>('OnboardingWizardContext');

export const OnboardingWizardProvider: FC = ({ children }) => {
  const { t } = useTranslation('uploadDocuments');
  const [updateFlag] = useUpdateFlagsMutation();
  const { currentAccount } = useCurrentAccount();

  const [step, setStep] = useState<OnboardingWizardId>(OnboardingWizardId.SETUP_FILE);
  const [isOnboardingWizardVisibile, setOnboardingVisibile] = useState(
    !currentAccount.onboardingFlags.hasHiddenOnboardingWizardWidget
  );

  const items: OnboardingWizardItem[] = [
    {
      id: OnboardingWizardId.SETUP_FILE,
      title: t('setUpTheFileFileYouWantToBeTranslated'),
      description: t('uploadYourFileSetTheSourceAndTargetLanguage'),
      icon: 'ri-folder-upload-fill'
    },
    {
      id: OnboardingWizardId.PRE_TRANSLATE,
      title: t('preTranslateYourDocumentWithMachine'),
      description: t('chooseWhichAutomaticTranslationApplyToDocument'),
      icon: 'ri-translate'
    },
    {
      id: OnboardingWizardId.EDIT_TRANSLATION,
      title: t('editTheTranslationAndDownload'),
      description: t('checkAndEditAutomaticTranslation'),
      icon: 'ri-file-edit-fill'
    }
  ];

  const hide = () => {
    setOnboardingVisibile(false);
    updateFlag({ hasHiddenOnboardingWizardWidget: true });
  };

  const show = useCallback(() => {
    if (!currentAccount.onboardingFlags.hasHiddenOnboardingWizardWidget) {
      setOnboardingVisibile(true);
    }
  }, [currentAccount.onboardingFlags.hasHiddenOnboardingWizardWidget]);

  // this could be improved somehow
  useEffect(() => {
    if (currentAccount && currentAccount.onboardingFlags) {
      if (
        currentAccount.onboardingFlags.hasConfirmedAnyDocument === false &&
        currentAccount.onboardingFlags.hasPreTranslatedAnyDocument === false &&
        currentAccount.onboardingFlags.hasUploadedAnyDocument === false
      ) {
        setStep(OnboardingWizardId.SETUP_FILE);
        show();
      }
      if (currentAccount.onboardingFlags.hasUploadedAnyDocument) {
        setStep(OnboardingWizardId.PRE_TRANSLATE);
        show();
      }
      if (currentAccount.onboardingFlags.hasPreTranslatedAnyDocument) {
        setStep(OnboardingWizardId.EDIT_TRANSLATION);
        show();
      }
      if (currentAccount.onboardingFlags.hasConfirmedAnyDocument) {
        setStep(OnboardingWizardId.COMPLETED);
        show();
      }
    }
  }, [currentAccount, show]);

  const value: OnboardingWizardContextType = {
    step,
    setStep,
    hide,
    show
  };

  return (
    <Provider {...value}>
      {children}
      {/* TODO: for the future, implement onboarding wizard tooltip */}
      {isOnboardingWizardVisibile && (
        <OnboardingWizardPortal>
          <OnboardingWizardContent items={items} current={step} description={t('completeAllStepsToObtainWords')} />
        </OnboardingWizardPortal>
      )}
    </Provider>
  );
};
