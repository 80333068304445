import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/v1/Tooltip';
import { CircleCheckIcon, CircleExclamationCheckIcon } from '@/icons/solid';
import { EParagraphTranslationStatus } from '@/redux/api/constants';
import { classNames } from '@/utils/classNames';

import type { IDocumentLanguageTranslationIconProps } from './types';

export const DocumentLanguageTranslationIcon: FunctionComponent<IDocumentLanguageTranslationIconProps> = ({
  translationStatus,
  disabled,
  onClick
}) => {
  const { t } = useTranslation('documentLanguageTranslation');

  const handleClick = (status: EParagraphTranslationStatus) => {
    if (!disabled) {
      onClick?.(status);
    }
  };

  switch (translationStatus) {
    case EParagraphTranslationStatus.CONFIRMED:
      return (
        <Tooltip placement="top" title={t('clickToMarkAsDraft')} className="z-20">
          <CircleCheckIcon
            role="button"
            className={classNames('h-4 w-4 text-green-500', {
              'hover:text-green-600': !disabled,
              'cursor-not-allowed opacity-50': disabled
            })}
            onClick={() => {
              handleClick(EParagraphTranslationStatus.DRAFT);
            }}
          />
        </Tooltip>
      );

    case EParagraphTranslationStatus.DRAFT:
      return (
        <Tooltip placement="top" title={t('clickToConfirmTranslation')} className="z-20">
          <CircleExclamationCheckIcon
            role="button"
            className={classNames('h-4 w-4 text-yellow-700', {
              'hover:text-yellow-800': !disabled,
              'cursor-not-allowed opacity-50': disabled
            })}
            onClick={() => {
              handleClick(EParagraphTranslationStatus.CONFIRMED);
            }}
          />
        </Tooltip>
      );

    default:
      return null;
  }
};
