import { useCombobox, useMultipleSelection } from 'downshift';
import { isEmpty } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Checkbox } from '@/components/v2/Checkbox';
import Combobox from '@/components/v2/Combobox';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Text } from '@/components/v2/Text';
import { LanguageGroup, LanguageOption } from '@/routes/Document/types';
import { doesLanguageExist, flattenGroupOptions, getGroupFilter } from '@/routes/Document/utils';

type LanguageSearchBoxProps = {
  languageGroups: LanguageGroup[];
  defaultValues?: LanguageOption[];
  multipleDocuments?: boolean;
  buttonLabel?: string;
  className?: string;
  onAddLanguage: (selectedItems?: LanguageOption[]) => void;
  onAddLanguageToAllDocuments?: (selectedItems?: LanguageOption[]) => void;
};

const SELECTED_GROUP = 'Selected';

export const LanguageSearchBox = forwardRef<HTMLDivElement, LanguageSearchBoxProps>((props, forwardedRef) => {
  const { t } = useTranslation('document');
  const {
    languageGroups,
    defaultValues,
    multipleDocuments = false,
    buttonLabel = t('addLanguages'),
    onAddLanguage,
    onAddLanguageToAllDocuments,
    ...rest
  } = props;

  const [inputSearch, setInputSearch] = useState<string | undefined>();
  const [selectedItems, setSelectedItems] = useState<LanguageOption[]>();

  const options = flattenGroupOptions(languageGroups);

  const filteredLanguageGroups: LanguageGroup[] = inputSearch
    ? languageGroups.map(getGroupFilter(inputSearch))
    : languageGroups;

  const { getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            setSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    }
  });

  const state = useCombobox({
    items: options,
    itemToString(item) {
      return item ? item.label : '';
    },
    selectedItem: null,
    stateReducer(_state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0 // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            if (selectedItems && doesLanguageExist(selectedItems, newSelectedItem.id)) {
              // need to pass the original object to removeSelectedItem otherwise it cannot match the index
              // therefore it doesn't remove the element
              const originalItem = selectedItems.find((item) => item.id === newSelectedItem.id);
              if (originalItem) {
                removeSelectedItem(originalItem);
              }
            } else {
              setSelectedItems([...(selectedItems || []), newSelectedItem]);
            }
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputSearch(newInputValue);
          break;
        // restore default selected items on dropdown open state changed
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          setSelectedItems(defaultValues);
          break;
        default:
          break;
      }
    }
  });

  useEffect(() => {
    if (defaultValues !== undefined) {
      setSelectedItems(defaultValues);
    }
  }, [defaultValues]);

  const handleAddLanguages = () => {
    if (selectedItems) {
      onAddLanguage(selectedItems);
    }
  };

  const handleAddLanguagesToAllDocuments = () => {
    if (selectedItems) {
      onAddLanguageToAllDocuments?.(selectedItems);
    }
  };

  const renderGroups = () => {
    const groupedItems: { [key: string]: LanguageGroup } = {};
    const selectedGroup: LanguageGroup = { name: t('selected'), options: [] };

    filteredLanguageGroups.forEach((section) => {
      const filteredOptions = section.options.filter((option) => {
        const isSelected = selectedItems && doesLanguageExist(selectedItems, option.id);
        if (isSelected) {
          selectedGroup.options.push(option);
          // exclude selected items from the original group
          return false;
        }
        // include non-selected items in the original group
        return true;
      });

      if (!isEmpty(filteredOptions)) {
        groupedItems[section.name] = { ...section, options: filteredOptions };
      }
    });

    // "Selected" group must be the first in the list
    const orderedGroups = [SELECTED_GROUP, ...Object.keys(groupedItems)];

    return orderedGroups.map((groupName, sectionIndex) => {
      const group = groupName === SELECTED_GROUP ? selectedGroup : groupedItems[groupName];

      if (!group || isEmpty(group.options)) {
        // skip rendering if the group is undefined or empty
        return null;
      }

      return (
        <div key={sectionIndex}>
          <Text size="sm" color="tertiary" weight="semibold" className="mx-2 my-2.5">
            {group.name}
          </Text>
          {group.options.map((option, optionIndex) => {
            const index = options.indexOf(option);
            return (
              <Combobox.Item key={optionIndex} item={option} index={index} className="gap-3">
                <Checkbox
                  checked={selectedItems && doesLanguageExist(selectedItems, option.id)}
                  onClick={(e) => e.preventDefault()}
                />
                <FlagIcon label={option.name} code={option.flagCode || 'xx'} size="md" />
                {option.label}
              </Combobox.Item>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div ref={forwardedRef} {...rest}>
      <Combobox.Root state={state} placement="bottom-start">
        <Combobox.Trigger asChild>
          <div
            className="min-w-select-trigger flex w-full gap-1 rounded-md border border-gray-200 bg-white px-5"
            onClick={(e) => e.preventDefault()}
          >
            <input
              {...state.getInputProps({
                ...getDropdownProps({ preventKeyAction: state.isOpen }),
                ...state.getToggleButtonProps(),
                value: inputSearch
              })}
              placeholder={t('selectTargetLanguage')}
              className="outline-none mr-auto flex-1 py-2 text-base text-typography-primary placeholder-typography-tertiary"
            />
            <AccessibleIcon label="ri-search-line" icon="ri-search-line" className="text-lg text-gray-600" />
          </div>
        </Combobox.Trigger>
        <Combobox.Content className="z-50 w-72">
          <Combobox.ItemsContainer>{renderGroups()}</Combobox.ItemsContainer>
          <Button
            variant="solid"
            color="primary"
            className="mt-1 w-full"
            size="sm"
            onClick={handleAddLanguages}
            type="button"
            disabled={!selectedItems}
          >
            {buttonLabel}
          </Button>
          {multipleDocuments && (
            <Button
              variant="solid"
              color="secondary"
              className="mt-1 w-full"
              size="sm"
              onClick={handleAddLanguagesToAllDocuments}
              type="button"
              disabled={!selectedItems}
            >
              {t('addToAllDocuments')}
            </Button>
          )}
        </Combobox.Content>
      </Combobox.Root>
    </div>
  );
});
LanguageSearchBox.displayName = 'LanguageSearchBox';
