import { forEach } from 'lodash';
import { useMemo } from 'react';
import { z } from 'zod';

import { SetURLSearchParams, useSearchParams } from '@/hooks/useSearchParams';
import { DocumentListStatus } from '@/redux/api/types';

const searchParamNumber = () => z.preprocess((arg) => arg || undefined, z.coerce.number());
const searchParamsSchema = z.object({
  filterByOwnerAccountIds: z.array(searchParamNumber()).optional().catch(undefined),
  filterByStatus: z.array(z.nativeEnum(DocumentListStatus)).optional().catch(undefined),
  filterByTargetLanguageIds: z.array(searchParamNumber()).optional().catch(undefined),
  filterByTranslatorAccountIds: z.array(searchParamNumber()).optional().catch(undefined),
  page: searchParamNumber().optional().catch(undefined),
  savedView: searchParamNumber().optional().catch(undefined),
  searchTerm: z.string().optional().catch(undefined)
});

type DocumentListSearchParams = z.infer<typeof searchParamsSchema>;
type DocumentListSearchParamsSetters = {
  [K in keyof DocumentListSearchParams as `set${Capitalize<K>}`]-?: (
    value: DocumentListSearchParams[K] | null | undefined
  ) => void;
} & {
  deleteAll: () => void;
};

const parseDocumentListSearchParams = (searchParams: URLSearchParams): DocumentListSearchParams => {
  const getValue = (name: string) => searchParams.get(name);
  const getValues = (name: string) => (searchParams.has(name) ? searchParams.getAll(name) : null);

  return searchParamsSchema.parse({
    filterByOwnerAccountIds: getValues('filterByOwnerAccountIds[]'),
    filterByStatus: getValues('filterByStatus[]'),
    filterByTargetLanguageIds: getValues('filterByTargetLanguageIds[]'),
    filterByTranslatorAccountIds: getValues('filterByTranslatorAccountIds[]'),
    page: getValue('page'),
    savedView: getValue('savedView'),
    searchTerm: getValue('searchTerm')
  });
};

const createDocumentListSearchParamsSetters = (
  setSearchParams: SetURLSearchParams
): DocumentListSearchParamsSetters => {
  const setValue = <V = unknown>(name: string, value: V | null | undefined) =>
    setSearchParams((searchParams) => {
      if (value) searchParams.set(name, `${value}`);
      else searchParams.delete(name);
      return searchParams;
    });
  const setValues = <V = unknown>(name: string, values: V[] | null | undefined) =>
    setSearchParams((searchParams) => {
      searchParams.delete(name);
      forEach(values, (value) => searchParams.append(name, `${value}`));
      return searchParams;
    });

  const deleteAll = () => {
    setSearchParams((searchParams) => {
      searchParams.delete('filterByOwnerAccountIds[]');
      searchParams.delete('filterByStatus[]');
      searchParams.delete('filterByTargetLanguageIds[]');
      searchParams.delete('filterByTranslatorAccountIds[]');
      searchParams.delete('page');
      searchParams.delete('savedView');
      searchParams.delete('searchTerm');
      return searchParams;
    });
  };

  return {
    setFilterByOwnerAccountIds: (filterByOwnerAccountIds) =>
      setValues('filterByOwnerAccountIds[]', filterByOwnerAccountIds),
    setFilterByStatus: (filterByStatus) => setValues('filterByStatus[]', filterByStatus),
    setFilterByTargetLanguageIds: (filterByTargetLanguageIds) =>
      setValues('filterByTargetLanguageIds[]', filterByTargetLanguageIds),
    setFilterByTranslatorAccountIds: (filterByTranslatorAccountIds) =>
      setValues('filterByTranslatorAccountIds[]', filterByTranslatorAccountIds),
    setPage: (page) => setValue('page', page),
    setSavedView: (savedView) => setValue('savedView', savedView),
    setSearchTerm: (searchTerm) => setValue('searchTerm', searchTerm),
    deleteAll
  };
};

const useDocumentListSearchParams = (): [DocumentListSearchParams, DocumentListSearchParamsSetters] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const documentListSearchParams = useMemo(() => parseDocumentListSearchParams(searchParams), [searchParams]);
  const documentListSearchParamsSetters = useMemo(
    () => createDocumentListSearchParamsSetters(setSearchParams),
    [setSearchParams]
  );

  return [documentListSearchParams, documentListSearchParamsSetters];
};

export { createDocumentListSearchParamsSetters, parseDocumentListSearchParams, useDocumentListSearchParams };
