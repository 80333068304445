import { Redirect, RedirectProps, Route, RouteProps } from 'react-router-dom';

import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

const RequireSubscriptionRoute = (props: RequireSubscriptionRouteProps) => {
  const { redirectTo = '/', ...routeProps } = props;

  const { currentAccount } = useCurrentAccount();
  const { subscriptionInfo } = currentAccount;

  if (subscriptionInfo.isSuspended) {
    return <Redirect to={redirectTo} />;
  }

  return <Route {...routeProps} />;
};
RequireSubscriptionRoute.displayName = 'RequireSubscriptionRoute';

type RequireSubscriptionRouteProps = RouteProps & {
  redirectTo?: RedirectProps['to'];
};

export { RequireSubscriptionRoute };
