import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { useToast } from '@/components/v2/Toast';
import { useExportTranslationMemoriesMutation } from '@/redux/api/translationMemoryApi';
import {
  ExportDocumentLanguagePairDto,
  ExportImportLanguagePairDto,
  ExportItemTypeEnum,
  TmExportFormatEnum
} from '@/redux/api/types';
import { useAppDispatch } from '@/redux/hooks';
import { addOperationToQueue } from '@/redux/slices/operationsSlice/operationsSlice';
import { OperationsKind } from '@/redux/slices/operationsSlice/types';
import { to } from '@/utils/awaitToJs';

import { TranslationMemoriesTableRow } from '../types';
import { DeleteTranslationMemoryModal } from './DeleteTranslationMemoryModal';

enum ModalKey {
  TRANSLATION_MEMORY_DELETE = 'TRANSLATION_MEMORY_DELETE'
}

type ActionsCellProps = TranslationMemoriesTableRow;

export const ActionsCell = ({
  translationMemoryId,
  kind,
  sourceLanguage,
  targetLanguage,
  isDeleting
}: ActionsCellProps) => {
  const { t: tTM } = useTranslation('translationMemory');
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState<ModalKey | null>(null);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  const [exportTranslationMemoryTrigger] = useExportTranslationMemoriesMutation();

  const handleModalItemSelect = (key: ModalKey) => (event: Event) => {
    event.preventDefault();
    setMenuOpen(false);
    setModalOpen(key);
  };

  // https://github.com/radix-ui/primitives/issues/1836
  const modalProps = (key: ModalKey) => ({
    open: modalOpen === key,
    onOpenChange: (open: boolean) => {
      if (open) {
        setMenuOpen(false);
        setModalOpen(key);
      } else {
        setModalOpen(null);
        menuTriggerRef.current?.focus();
      }
    }
  });

  const closeModal = () => {
    setModalOpen(null);
    menuTriggerRef.current?.focus();
  };

  const handleDownload = async () => {
    if (kind) {
      const pairsToExport: (ExportDocumentLanguagePairDto | ExportImportLanguagePairDto)[] = [];

      if (kind === ExportItemTypeEnum.IMPORT && translationMemoryId) {
        pairsToExport.push({ translationMemoryId, type: kind });
      }

      if (
        kind === ExportItemTypeEnum.DOCUMENT &&
        sourceLanguage?.id !== undefined &&
        targetLanguage?.id !== undefined
      ) {
        pairsToExport.push({
          sourceLanguageId: sourceLanguage.id,
          targetLanguageId: targetLanguage.id,
          type: ExportItemTypeEnum.DOCUMENT
        });
      }

      const [err, res] = await to(
        exportTranslationMemoryTrigger({
          exportFormat: TmExportFormatEnum.TMX,
          pairs: pairsToExport
        }).unwrap()
      );
      if (!err) {
        dispatch(
          addOperationToQueue({
            operationId: res.operationId,
            message: tTM('downloadStarted'),
            kind: OperationsKind.DOWNLOAD_TM
          })
        );
        toast({ title: tTM('theDownloadIsBeingPrepared'), kind: 'success' });
      }
    }
  };

  return (
    <div className={'flex flex-row justify-end gap-3'}>
      <Button variant="text" onClick={handleDownload} disabled={isDeleting}>
        <AccessibleIcon label="ri-file-download-line" icon="ri-file-download-line" className="text-lg" />
        {tTM('download')}
      </Button>

      {translationMemoryId !== undefined ? (
        <>
          <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
            <DropdownMenu.Trigger onClick={(e) => e.preventDefault()} disabled={isDeleting}>
              <Button variant="text" ref={menuTriggerRef}>
                <AccessibleIcon icon="ri-more-2-fill" label="ri-more-2-fill" />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Item
                onSelect={handleModalItemSelect(ModalKey.TRANSLATION_MEMORY_DELETE)}
                className="text-states-negative-active"
                disabled={isDeleting}
              >
                <AccessibleIcon icon="ri-delete-bin-5-line" label="ri-delete-bin-5-line" />
                {tTM('delete')}
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
          <DeleteTranslationMemoryModal
            {...modalProps(ModalKey.TRANSLATION_MEMORY_DELETE)}
            translationMemoryId={translationMemoryId}
            onDelete={closeModal}
          />
        </>
      ) : (
        <div className="w-[18px]"></div>
      )}
    </div>
  );
};
