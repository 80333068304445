import type { ITeamMemberColor, ITeamMembersState } from './types';

export const COLORS: ITeamMemberColor[] = [
  // Red
  { text: 'text-red-400', border: 'border-red-400' },
  { text: 'text-red-600', border: 'border-red-600' },
  { text: 'text-red-800', border: 'border-red-800' },

  // Orange
  { text: 'text-orange-400', border: 'border-orange-400' },
  { text: 'text-orange-600', border: 'border-orange-600' },
  { text: 'text-orange-800', border: 'border-orange-800' },

  // Yellow
  { text: 'text-yellow-400', border: 'border-yellow-400' },
  { text: 'text-yellow-600', border: 'border-yellow-600' },
  { text: 'text-yellow-800', border: 'border-yellow-800' },

  // Green
  { text: 'text-green-400', border: 'border-green-400' },
  { text: 'text-green-600', border: 'border-green-600' },
  { text: 'text-green-800', border: 'border-green-800' },

  // Blue
  { text: 'text-blue-400', border: 'border-blue-400' },
  { text: 'text-blue-600', border: 'border-blue-600' },
  { text: 'text-blue-800', border: 'border-blue-800' },

  // Indigo
  { text: 'text-indigo-400', border: 'border-indigo-400' },
  { text: 'text-indigo-600', border: 'border-indigo-600' },
  { text: 'text-indigo-800', border: 'border-indigo-800' },

  // Purple
  { text: 'text-purple-400', border: 'border-purple-400' },
  { text: 'text-purple-600', border: 'border-purple-600' },
  { text: 'text-purple-800', border: 'border-purple-800' },

  // Pink
  { text: 'text-pink-400', border: 'border-pink-400' },
  { text: 'text-pink-600', border: 'border-pink-600' },
  { text: 'text-pink-800', border: 'border-pink-800' }
];

export const DEFAULT_COLOR: ITeamMemberColor = { text: 'text-gray-500', border: 'border-gray-500' };

export const INITIAL_STATE: ITeamMembersState = {
  colors: {}
};
