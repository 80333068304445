import { FocusEvent, KeyboardEvent, useCallback, useState } from 'react';

import { ETranslationUpdateSource } from '@/redux/api/constants';
import type { INextParagraph, IParagraph, ISuggestion } from '@/redux/api/types';
import { EKeyboardEventKey } from '@/utils/keyboardEvent';

import type { TMtSuggestion } from '../../../types';

export interface IUseTranslationInputEventsProps {
  translationContent: string;
  paragraph: IParagraph;
  tmSuggestions?: ISuggestion[];
  mtSuggestions?: TMtSuggestion[];
  onSaveContent?: (content: string, source: ETranslationUpdateSource) => void;
  onGoToNextParagraph?: (nextParagraph: INextParagraph) => void;
  onGoToFirstEmptyParagraph?: () => void;
}

export const useTranslationInputEvents = ({
  translationContent,
  tmSuggestions = [],
  mtSuggestions = [],
  paragraph,
  onSaveContent,
  onGoToNextParagraph,
  onGoToFirstEmptyParagraph
}: IUseTranslationInputEventsProps) => {
  const [showShortcuts, setShowShortcuts] = useState(false);

  const handleFocus = useCallback((_: FocusEvent<HTMLDivElement>) => {
    setShowShortcuts(false);
  }, []);

  const handleBlur = useCallback(
    (_: FocusEvent<HTMLDivElement>) => {
      setShowShortcuts(false);
      onSaveContent?.(translationContent, ETranslationUpdateSource.CAT);
    },
    [onSaveContent, translationContent]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const { ctrlKey, shiftKey, code } = event;

      // Japanese and other asian languages use the composer (eg. Microsoft IME – Input Method Editor)
      // We should ignore RETURN, TAB or other inputs when the user is using the composer, as it
      // will create unexpected issues (see HS #994).
      if (event.nativeEvent.isComposing) {
        return;
      }

      if (code === EKeyboardEventKey.ENTER) {
        event.preventDefault();

        if (paragraph.nextParagraph) {
          onGoToNextParagraph?.(paragraph.nextParagraph);
        }
      } else if (ctrlKey && shiftKey) {
        setShowShortcuts(true);

        const digitMatch = code.match(new RegExp(EKeyboardEventKey.DIGIT_REGEX));
        if (digitMatch) {
          event.preventDefault();
          const suggestionIndex = parseInt(digitMatch[1]) - 1;
          if (suggestionIndex < tmSuggestions.length) {
            const content = tmSuggestions[suggestionIndex]?.contentToPaste;
            onSaveContent?.(content, ETranslationUpdateSource.CAT_SUGGESTION);
          } else if (suggestionIndex < tmSuggestions.length + mtSuggestions.length) {
            const mtSuggestionIndex = suggestionIndex - tmSuggestions.length;
            const content = mtSuggestions[mtSuggestionIndex]?.translation;
            onSaveContent?.(content, ETranslationUpdateSource.CAT_MT);
          }
        } else if (code === EKeyboardEventKey.C) {
          event.preventDefault();
          onSaveContent?.(paragraph.content, ETranslationUpdateSource.CAT_COPY);
        } else if (code === EKeyboardEventKey.N) {
          event.preventDefault();
          onGoToFirstEmptyParagraph?.();
        }
      }
    },
    [mtSuggestions, onGoToFirstEmptyParagraph, onGoToNextParagraph, onSaveContent, paragraph, tmSuggestions]
  );

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (!event.ctrlKey || !event.shiftKey) {
      setShowShortcuts(false);
    }
  }, []);

  return {
    showShortcuts,
    handleFocus,
    handleBlur,
    handleKeyDown,
    handleKeyUp
  };
};
