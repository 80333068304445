import { groupBy } from 'lodash';

import { BillingFrequencyEnum, CurrencyEnum, PlanDto, SubscriptionInfoDto } from '@/redux/api/types';

import { getPriceByFrequencyAndCurrency } from '../utils/getPriceByFrequencyAndCurrency';

export const getCurrentPlanPrice = (
  subscriptionInfo: SubscriptionInfoDto,
  plans: PlanDto[],
  frequency: BillingFrequencyEnum,
  currency: CurrencyEnum
) => {
  const groupByPlanId = groupBy(plans, ({ planCode: planId }) => planId);
  const currentPlanGroup = groupByPlanId[subscriptionInfo.planCode];
  const currentPlanData = currentPlanGroup ? currentPlanGroup[0] : undefined;
  return getPriceByFrequencyAndCurrency(frequency, currency, currentPlanData);
};
