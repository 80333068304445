import { Trans, useTranslation } from 'react-i18next';

import { FAQBlock } from './FAQBlock';

export const FAQSection = () => {
  const { t: tPlans } = useTranslation('plans');

  return (
    <div className="grid grid-cols-2 gap-12">
      <div className="space-y-12">
        <FAQBlock
          title={tPlans('canIRunATestBeforeBuyingTitle')}
          text={
            <Trans
              i18nKey="plans:canIRunATestBeforeBuyingText"
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              components={{ 1: <a className="underline" href="https://redokun.com/contact/demo" /> }}
            />
          }
        />
        <FAQBlock
          title={tPlans('whenDoWordsGetDeductedFromMyBalanceTitle')}
          text={tPlans('whenDoWordsGetDeductedFromMyBalanceText')}
        />
        <FAQBlock
          title={tPlans('howAreWordsDeductionsCountedTitle')}
          text={tPlans('howAreWordsDeductionsCountedText')}
        />
        <FAQBlock
          title={tPlans('howDoesMyWordBalanceWorkWithAnAnnualSubscriptionTitle')}
          text={tPlans('howDoesMyWordBalanceWorkWithAnAnnualSubscriptionText')}
        />
      </div>
      <div className="space-y-12">
        <FAQBlock title={tPlans('canIUpgradeMyPlanTitle')} text={tPlans('canIUpgradeMyPlanText')} />
        <FAQBlock
          title={tPlans('whatIsTheAdvantageOfGettingAnAnnualPlanTitle')}
          text={tPlans('whatIsTheAdvantageOfGettingAnAnnualPlanText')}
        />
        <FAQBlock
          title={tPlans('whatIfThePremiumPlanIsNotEnoughTitle')}
          text={
            <Trans
              i18nKey="plans:whatIfThePremiumPlanIsNotEnoughText"
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              components={{ 1: <a className="underline" href="https://redokun.com/contact-2" /> }}
            />
          }
        />
        <FAQBlock title={tPlans('whatKindOfSupportDoYouOfferTitle')} text={tPlans('whatKindOfSupportDoYouOfferText')} />
      </div>
    </div>
  );
};
