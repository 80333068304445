import { BillingFrequencyEnum, CurrencyEnum, PlanDto } from '@/redux/api/types';

interface GetPlanPriceOptions {
  currency: CurrencyEnum;
  frequency: BillingFrequencyEnum;
}
export const getPlanPrice = (plan: PlanDto, options: GetPlanPriceOptions) => {
  const { currency, frequency } = options;

  let prices;
  switch (currency) {
    case CurrencyEnum.USD:
      prices = { monthlyPrice: plan.usdMonthlyPrice, yearlyPrice: plan.usdYearlyPrice };
      break;

    case CurrencyEnum.EUR:
      prices = { monthlyPrice: plan.eurMonthlyPrice, yearlyPrice: plan.eurYearlyPrice };
      break;

    default:
      throw new Error(`Unsupported currency: ${currency}`);
  }

  switch (frequency) {
    case BillingFrequencyEnum.MONTHLY:
      return prices.monthlyPrice;

    case BillingFrequencyEnum.YEARLY:
      return prices.yearlyPrice;

    default:
      throw new Error(`Unsupported frequency: ${frequency}`);
  }
};
