import { Cell, Column, RowData } from '@tanstack/react-table';
import { isNumber } from 'lodash';

import { COLUMN_SIZE_AUTO, LOADING_DATA_TOKEN } from './constants';

/**
 * Retrieves or generates data based on the input.
 *
 * @param {Data[] | null | undefined | false | number} data - Input data or length for generating empty objects.
 * @returns {Data[]} An array of data or generated empty objects.
 */
const getData = <Data extends RowData = unknown>(data: Data[] | null | undefined | false | number) => {
  if (isNumber(data)) {
    // Loading data
    return Array.from({ length: data }).fill(LOADING_DATA_TOKEN) as Data[];
  }

  // Table data
  return data || [];
};

/**
 * Retrieves the width of a column.
 *
 * @param {Column<Data, Value>} column - The column object containing column definitions.
 * @returns {number | string} The width of the column, which can be a number, 'auto', or the result of column.getSize().
 */
const getColumnWidth = <Data extends RowData, Value>(column: Column<Data, Value>) => {
  const { columnDef } = column;

  const width = columnDef.meta?.width;
  if (width !== undefined) {
    return width;
  }

  const columnDefSize = columnDef.size;
  if (columnDefSize === COLUMN_SIZE_AUTO) {
    return 'auto';
  }

  return column.getSize();
};

/**
 * Retrieves the skeleton width of a cell.
 *
 * @param {Cell<Data, Value>} cell - The cell object containing the row index.
 * @returns {string} The skeleton width of the cell as a percentage.
 */
const getCellSkeletonWidth = <Data extends RowData, Value>(cell: Cell<Data, Value>) => {
  const rowIndex = cell.row.index;
  const offset = (rowIndex % 3) * 10;

  return `${90 - offset}%`;
};

export { getData, getColumnWidth, getCellSkeletonWidth };
