import { forwardRef } from 'react';
import { Separator } from '@radix-ui/react-separator';

import { Text } from '@/components/v2/Text';
import { classNames } from '@/utils/classNames';
import { BaseStep } from '@/components/v2/StepperGroup/BaseStep';

import { BaseStepProps, StepperGroupProps } from './types';
import s from './stepper.module.css';

const LABEL_COLOR = {
  active: 'text-typography-primary',
  checked: 'text-typography-secondary',
  disabled: 'text-typography-tertiary'
} as const;

// TODO: for the future
// implement vertical stepper
export const StepperGroup = forwardRef<HTMLDivElement, StepperGroupProps>((props, ref) => {
  const { className, current, items, orientation = 'horizontal', hideSeparator = false, hideLabel, ...rest } = props;

  const isVertical = orientation === 'vertical';

  const getStatus = (current: number, index: number): BaseStepProps['status'] => {
    return (
      (index === current && 'active') || (index < current && 'checked') || (index > current && 'disabled') || 'disabled'
    );
  };

  return (
    <div
      ref={ref}
      className={classNames('flex items-center justify-between gap-1', isVertical && 'flex-col', className)}
      {...rest}
    >
      {items.map((item, index) => {
        const firstItem = index === 0;
        const status = getStatus(current, index);

        const showSeparator = !firstItem && !hideSeparator;

        const labelColor = LABEL_COLOR[status];
        const separatorColor = status === 'checked' || status === 'active' ? 'bg-primary-active' : ' bg-gray-200';

        return (
          <div key={item.id} className={classNames('relative flex flex-1 items-center justify-center')}>
            <div
              className={classNames(
                'flex min-w-min items-center overflow-hidden whitespace-nowrap',
                isVertical ? 'flex-row' : 'flex-col'
              )}
            >
              <div className="flex h-8 w-8 min-w-8 items-center justify-center">
                <BaseStep status={status} />
              </div>
              {!hideLabel && (
                <Text size="sm" weight="regular" className={classNames(!isVertical && 'mt-3', labelColor)}>
                  {item.label}
                </Text>
              )}
            </div>
            {showSeparator && (
              <Separator
                orientation={orientation}
                className={classNames(!isVertical && s['separator--horizontal'], 'h-0.5 rounded-full', separatorColor)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
});

StepperGroup.displayName = 'StepperGroup';
