import { TrackEventQueryArg } from '@/redux/api/types';

import { redokunApi } from './redokunApi';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const trackingApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    trackEvent: builder.mutation<void, TrackEventQueryArg>({
      query: ({ body }) => ({ url: `events/register-event`, method: 'POST', body })
    })
  })
});

export const { useTrackEventMutation } = trackingApi;

export const trackingApiUtil = {
  ...trackingApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(trackingApi.util.updateQueryData)
};
