import { createSlice } from '@reduxjs/toolkit';

import { EModalType, INITIAL_STATE, ModalVersion } from './constants';
import type {
  AskToUnlockTranslationModalProps,
  EditAssignedLanguagesModalProps,
  IAskUnlockTranslationModalProps,
  IConfirmTranslationModalProps,
  IDeleteDocumentCommentModalProps,
  IDeleteParagraphCommentModalProps,
  IDocumentNotAvailableModalProps,
  IDocumentPreTranslatedModalProps,
  IDocumentTranslationLockedModalProps,
  IErrorModalProps,
  IFeedbackModalProps,
  INewDocumentRevisionAvailableModalProps,
  INewTranslationsImportedModalProps,
  ISessionExpiredModalProps,
  IUnlockTranslationModalProps,
  IWorkInvitationModalProps,
  LowWordBalanceModalProps,
  PaymentIssuesModalProps,
  SubscriptionExpiredModalProps,
  FeatureRequireUpgradeModalProps
} from './types/props';
import { closeModalReducer, destroyModalReducer, openModalReducer } from './utils';

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: INITIAL_STATE,
  reducers: {
    // All modals
    openModal: openModalReducer,
    closeModal: closeModalReducer,
    destroyModal: destroyModalReducer,

    // Ask unlock translation modal
    openAskUnlockTranslationModal: {
      prepare: (props: IAskUnlockTranslationModalProps) => ({
        payload: { type: EModalType.ASK_UNLOCK_TRANSLATION as const, props }
      }),
      reducer: openModalReducer
    },

    // Confirm translation modal
    openConfirmTranslationModal: {
      prepare: (props: IConfirmTranslationModalProps) => ({
        payload: { type: EModalType.CONFIRM_TRANSLATION as const, props }
      }),
      reducer: openModalReducer
    },

    // Delete document comment modal
    openDeleteDocumentCommentModal: {
      prepare: (props: IDeleteDocumentCommentModalProps) => ({
        payload: { type: EModalType.DELETE_DOCUMENT_COMMENT as const, props }
      }),
      reducer: openModalReducer
    },

    // Delete paragraph comment modal
    openDeleteParagraphCommentModal: {
      prepare: (props: IDeleteParagraphCommentModalProps) => ({
        payload: { type: EModalType.DELETE_PARAGRAPH_COMMENT as const, props }
      }),
      reducer: openModalReducer
    },

    // Document not available modal
    openDocumentNotAvailableModal: {
      prepare: (props: IDocumentNotAvailableModalProps = {}) => ({
        payload: { type: EModalType.DOCUMENT_NOT_AVAILABLE as const, props }
      }),
      reducer: openModalReducer
    },

    // Document pre-translated modal
    openDocumentPreTranslatedModal: {
      prepare: (props: IDocumentPreTranslatedModalProps = {}) => ({
        payload: { type: EModalType.DOCUMENT_PRE_TRANSLATED as const, props }
      }),
      reducer: openModalReducer
    },

    // Document translation locked modal
    openDocumentTranslationLockedModal: {
      prepare: (props: IDocumentTranslationLockedModalProps = {}) => ({
        payload: { type: EModalType.DOCUMENT_TRANSLATION_LOCKED as const, props }
      }),
      reducer: openModalReducer
    },

    // Error modal
    openErrorModal: {
      prepare: (props: IErrorModalProps = {}) => ({
        payload: { type: EModalType.ERROR as const, props }
      }),
      reducer: openModalReducer
    },

    // Feedback modal
    openFeedbackModal: {
      prepare: (props: IFeedbackModalProps = {}) => ({
        payload: { type: EModalType.FEEDBACK as const, props }
      }),
      reducer: openModalReducer
    },

    // New document revision available modal
    openNewDocumentRevisionAvailableModal: {
      prepare: (props: INewDocumentRevisionAvailableModalProps) => ({
        payload: { type: EModalType.NEW_DOCUMENT_REVISION_AVAILABLE as const, props }
      }),
      reducer: openModalReducer
    },

    // New translations imported modal
    openNewTranslationsImportedModal: {
      prepare: (props: INewTranslationsImportedModalProps) => ({
        payload: { type: EModalType.NEW_TRANSLATIONS_IMPORTED as const, props }
      }),
      reducer: openModalReducer
    },

    // Session expired modal
    openSessionExpiredModal: {
      prepare: (props: ISessionExpiredModalProps = {}) => ({
        payload: { type: EModalType.SESSION_EXPIRED as const, props }
      }),
      reducer: openModalReducer
    },

    // Unlock translation modal
    openUnlockTranslationModal: {
      prepare: (props: IUnlockTranslationModalProps) => ({
        payload: { type: EModalType.UNLOCK_TRANSLATION as const, props }
      }),
      reducer: openModalReducer
    },

    // Work invitation modal
    openWorkInvitationModal: {
      prepare: (props: IWorkInvitationModalProps) => ({
        payload: { type: EModalType.WORK_INVITATION as const, props }
      }),
      reducer: openModalReducer
    },

    // Not enough words error modal
    openNotEnoughWordsErrorModal: {
      prepare: () => ({
        // version 2 is used to distinguish new modals from legacy's ones
        payload: { type: EModalType.NOT_ENOUGH_WORDS_ERROR as const, version: ModalVersion.V2 }
      }),
      reducer: openModalReducer
    },

    // Edit translator languages modal
    openEditAssignedLanguagesModal: {
      prepare: (props: EditAssignedLanguagesModalProps) => ({
        payload: { type: EModalType.EDIT_TRANSLATOR_ASSIGNED_LANGUAGES as const, props }
      }),
      reducer: openModalReducer
    },

    // Open ask to unlock translation modal
    // new version, it will replace "openAskUnlockTranslationModal" used in WebEditor
    openAskToUnlockTranslationModal: {
      prepare: (props: AskToUnlockTranslationModalProps) => ({
        // version 2 is used to distinguish new modals from legacy's ones
        payload: { type: EModalType.ASK_TO_UNLOCK_TRANSLATION as const, version: ModalVersion.V2, props }
      }),
      reducer: openModalReducer
    },

    // Open ask to unlock translation modal
    // new version, it will replace "openFeedbackModal" used in WebEditor
    openSendFeedbackModal: {
      prepare: () => ({
        payload: { type: EModalType.SEND_FEEDBACK as const, version: ModalVersion.V2 }
      }),
      reducer: openModalReducer
    },

    // Open subscription expired modal
    openSubscriptionExpiredModal: {
      prepare: (props: SubscriptionExpiredModalProps) => ({
        payload: { type: EModalType.SUBSCRIPTION_EXPIRED as const, version: ModalVersion.V2, props }
      }),
      reducer: openModalReducer
    },
    // Open payment issues modal
    openPaymentIssuesModal: {
      prepare: (props: PaymentIssuesModalProps) => ({
        payload: { type: EModalType.PAYMENT_ISSUES as const, version: ModalVersion.V2, props }
      }),
      reducer: openModalReducer
    },
    // Open low word balance modal
    openLowWordBalanceModal: {
      prepare: (props: LowWordBalanceModalProps) => ({
        payload: { type: EModalType.LOW_WORD_BALANCE as const, version: ModalVersion.V2, props }
      }),
      reducer: openModalReducer
    },

    // Open feature not available modal
    openFeatureNotAvailableModal: {
      prepare: (props: FeatureRequireUpgradeModalProps) => ({
        payload: { type: EModalType.FEATURE_NOT_AVAILABLE as const, version: ModalVersion.V2, props }
      }),
      reducer: openModalReducer
    }
  }
});

export const {
  openModal,
  closeModal,
  destroyModal,
  openAskUnlockTranslationModal,
  openConfirmTranslationModal,
  openDeleteDocumentCommentModal,
  openDeleteParagraphCommentModal,
  openDocumentNotAvailableModal,
  openDocumentPreTranslatedModal,
  openDocumentTranslationLockedModal,
  openErrorModal,
  openFeedbackModal,
  openNewDocumentRevisionAvailableModal,
  openNewTranslationsImportedModal,
  openSessionExpiredModal,
  openUnlockTranslationModal,
  openWorkInvitationModal,
  openNotEnoughWordsErrorModal,
  openEditAssignedLanguagesModal,
  openAskToUnlockTranslationModal,
  openSendFeedbackModal,
  openSubscriptionExpiredModal,
  openPaymentIssuesModal,
  openLowWordBalanceModal,
  openFeatureNotAvailableModal
} = modalsSlice.actions;
