import { useState } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { useDeleteSavedViewMutation, usePatchSavedViewMutation } from '@/redux/api/documentListApi';

import { SavedViewForm } from './SavedViewForm';
import { SavedViewMenu } from './SavedViewMenu';
import type { SavedViewItemProps } from './types';
import { SavedViewFormSchema } from './useSavedViewForm';

const SavedViewItem = (props: SavedViewItemProps) => {
  const { id, name, default: isDefault, selected, onSelect } = props;

  const [deleteSavedView] = useDeleteSavedViewMutation();
  const [patchSavedView] = usePatchSavedViewMutation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleClick = () => {
    onSelect(id);
  };

  const handleSubmit = async (data: SavedViewFormSchema) => {
    await patchSavedView({ id, name: data.name });
    setIsEditing(false);
  };

  const handleReset = () => {
    setIsEditing(false);
  };

  const handleStarClick = async () => {
    await patchSavedView({ id, isDefault: !isDefault });
  };

  const handleRenameSelect = () => {
    setIsEditing(true);
  };

  const handleDeleteSelect = async () => {
    await deleteSavedView(id);
  };

  return (
    <li>
      <div
        className="group flex h-12 items-center justify-start gap-2 rounded-lg p-2 transition-colors hover:bg-surface-accent data-selected:bg-surface-accent"
        data-selected={selected || isMenuOpen || undefined}
        onClick={handleClick}
      >
        <AccessibleIcon label="" icon="ri-filter-fill" className="text-lg text-gray-600" />

        <Show
          when={isEditing}
          fallback={
            <Text color="primary" size="sm" className="flex-1">
              {name}
            </Text>
          }
        >
          <SavedViewForm name={name} onSubmit={handleSubmit} onReset={handleReset} />
        </Show>

        <div className="flex items-center group-hover:flex" onClick={(e) => e.stopPropagation()}>
          <Button variant="text" color="primary" onClick={handleStarClick}>
            <Show when={isDefault} fallback={<AccessibleIcon label="ri-star-line" icon="ri-star-line" />}>
              <AccessibleIcon label="ri-star-fill" icon="ri-star-fill" />
            </Show>
          </Button>

          <SavedViewMenu
            open={isMenuOpen}
            onOpenChange={setIsMenuOpen}
            onRenameSelect={handleRenameSelect}
            onDeleteSelect={handleDeleteSelect}
          >
            <Button variant="text" color="primary">
              <AccessibleIcon label="ri-more-2-fill" icon="ri-more-2-fill" />
            </Button>
          </SavedViewMenu>
        </div>
      </div>
    </li>
  );
};
SavedViewItem.displayName = 'SavedViewItem';

export { SavedViewItem };
