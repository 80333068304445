import { useCombobox } from 'downshift';

import Combobox from '@/components/v2/Combobox';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { useMeasure } from '@/hooks/useMeasure';
import { TeamDetailsResponseDto } from '@/redux/api/types';

interface DocumentOwnerSelectProps {
  items: TeamDetailsResponseDto['members'];
  value: number | null;
  onChange?: (value?: number | null) => void;
}

/**
 * DocumentOwnerSelect component for rendering custom select component in Document Settings Modal
 * @function
 * @param {boolean} items - TeamDetails items
 * @param {string} value - value from react hook form
 * @param {Function} onChange - Callback function
 * @returns {React.ReactNode} - The rendered component
 */
export const DocumentOwnerSelect = ({ items, value, onChange }: DocumentOwnerSelectProps) => {
  const BUTTON_PADDING = 20;
  // used to get trigger button width to apply to the dropdown
  const [ref, rect] = useMeasure<HTMLButtonElement>();
  const width = `${rect.width + BUTTON_PADDING}px`;

  const parse = items.find((item) => item.id === value);

  const state = useCombobox({
    items: items,
    selectedItem: parse,
    defaultSelectedItem: parse,
    onSelectedItemChange: ({ selectedItem }) => onChange?.(selectedItem?.id)
  });

  return (
    <Combobox.Root state={state} placement="bottom-start">
      <Combobox.Trigger asChild>
        <button
          ref={ref}
          className="box-border inline-flex h-11 w-full place-content-between items-center gap-2 rounded-md border border-gray-200 bg-white px-5 py-2 text-base font-medium text-typography-primary hover:ring-2 hover:ring-gray-700 focus:border-transparent focus:ring-2 focus:ring-primary-selected"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {state?.selectedItem?.name}
          <span className="flex items-center justify-center">
            {state.isOpen ? (
              <AccessibleIcon label="arrow-up" icon="ri-arrow-up-s-line" className="text-2xl text-alpha-500" />
            ) : (
              <AccessibleIcon label="arrow-down" icon="ri-arrow-down-s-line" className="text-2xl text-alpha-500" />
            )}
          </span>
        </button>
      </Combobox.Trigger>
      <Combobox.Content>
        <Combobox.ItemsContainer style={{ width }}>
          {items.map((item) => (
            <Combobox.Item key={item.id} index={items.indexOf(item) || 0} item={item}>
              {item.name}
            </Combobox.Item>
          ))}
        </Combobox.ItemsContainer>
      </Combobox.Content>
    </Combobox.Root>
  );
};
