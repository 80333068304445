import { Trans, useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import Modal from '@/components/v2/Modal';
import { Show } from '@/components/v2/Show';
import { APP_URL } from '@/config/urls';
import { PaymentIssuesModalProps } from '@/redux/slices/modalsSlice/types/props';

export const PaymentIssuesModal = (props: PaymentIssuesModalProps) => {
  const { canResolveSuspension, ...modalProps } = props;

  const { t: tModals } = useTranslation('modals');

  return (
    <Modal.Root {...modalProps}>
      <Modal.Content
        size="lg"
        // prevent closing modal when click outside
        onInteractOutside={(e) => e.preventDefault()}
        // since there's a case where no inputs are available, this prevents the display of a focus ring around the modal window
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Modal.Header>
          <AccessibleIcon
            icon="ri-alert-line"
            label="warning-icon"
            className="self-start text-4xl text-states-alert-active"
          />
          <Modal.Title size="lg">
            <Show when={canResolveSuspension} fallback={tModals('weCouldntProcessYourPayment')}>
              {tModals('updateYourPaymentMethod')}
            </Show>
          </Modal.Title>
          <Modal.Description size="md">
            {tModals('asWeCouldntProcessYourPaymentYourAccountHasBeenBlocked')}
          </Modal.Description>
          <Modal.Description size="md">
            <Show
              when={canResolveSuspension}
              fallback={
                <Trans
                  i18nKey="modals:toKeepUploadingEditingDownloadingPleaseContactYourManager"
                  components={{ 1: <span className="font-bold"></span> }}
                />
              }
            >
              {tModals('toKeepUploadingEditingDownloadingPleaseUpdateYourCreditCard')}
            </Show>
          </Modal.Description>
        </Modal.Header>

        <Show when={canResolveSuspension}>
          <Modal.Footer className="flex flex-row">
            <Modal.Close>
              <Button color="secondary">{tModals('goBack')}</Button>
            </Modal.Close>

            <ButtonLink color="primary" href={`${APP_URL}/account/subscription`}>
              {tModals('updateNow')}
            </ButtonLink>
          </Modal.Footer>
        </Show>
      </Modal.Content>
    </Modal.Root>
  );
};
