import { useEffect, useState } from 'react';

export const useWindowScrolling = (debounceMs = 100) => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    let scrollingTimeout: NodeJS.Timeout;

    const handleScrollEnd = () => {
      setScrolling(false);
    };

    const handleScroll = () => {
      setScrolling(true);
      clearTimeout(scrollingTimeout);
      scrollingTimeout = setTimeout(handleScrollEnd, debounceMs);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [debounceMs]);

  return scrolling;
};
