import { languageApiUtil } from '../languageApi';
import type { IGetBootstrapDataQueryArg } from '../types';

export const bootstrapSegmentsChanged = (
  args: IGetBootstrapDataQueryArg,
  payload: {
    segmentsDraftCount?: number;
    segmentsTotalCount?: number;
    segmentsTranslatedCount?: number;
    segmentsWithMessagesCount?: number;
    segmentsWithGlossaryWarnings?: number;
  }
) =>
  languageApiUtil.safeUpdateQueryData('getBootstrapData', args, (data) => {
    data.segmentsDraftCount = payload.segmentsDraftCount ?? data.segmentsDraftCount;
    data.segmentsTotalCount = payload.segmentsTotalCount ?? data.segmentsTotalCount;
    data.segmentsTranslatedCount = payload.segmentsTranslatedCount ?? data.segmentsTranslatedCount;
    data.segmentsWithMessagesCount = payload.segmentsWithMessagesCount ?? data.segmentsWithMessagesCount;
    data.segmentsWithGlossaryWarnings = payload.segmentsWithGlossaryWarnings ?? data.segmentsWithGlossaryWarnings;
  });
