import * as DialogPrimitive from '@radix-ui/react-dialog';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';
import { createContext } from '@/utils/createContext';

import { Text } from '../Text';
import { Title } from '../Title';
import { modalContent, modalFooter, modalHeader } from './styles';
import type {
  ModalCloseElement,
  ModalCloseProps,
  ModalContentElement,
  ModalContentProps,
  ModalDescriptionElement,
  ModalDescriptionProps,
  ModalFooterElement,
  ModalFooterProps,
  ModalHeaderElement,
  ModalHeaderProps,
  ModalProps,
  ModalTitleElement,
  ModalTitleProps,
  ModalTriggerElement,
  ModalTriggerProps
} from './types';

const Modal = (props: ModalProps) => <DialogPrimitive.Root {...props} modal />;
Modal.displayName = 'Modal';

const ModalTrigger = forwardRef<ModalTriggerElement, ModalTriggerProps>((props, ref) => (
  <DialogPrimitive.Trigger {...props} asChild ref={ref} />
));
ModalTrigger.displayName = 'ModalTrigger';

const ModalClose = forwardRef<ModalCloseElement, ModalCloseProps>((props, ref) => (
  <DialogPrimitive.Trigger {...props} asChild ref={ref} />
));
ModalClose.displayName = 'ModalClose';

const [ModalContentProvider, useModalContentContext] = createContext<Pick<ModalContentProps, 'size'>>('ModalContent', {
  size: 'md'
});
const ModalContent = forwardRef<ModalContentElement, ModalContentProps>(
  ({ className, container, forceMount, size, ...props }, ref) => (
    <ModalContentProvider size={size}>
      <DialogPrimitive.Portal container={container} forceMount={forceMount}>
        <DialogPrimitive.Overlay className="fixed inset-0 z-50 flex items-center justify-center bg-alpha-400">
          <DialogPrimitive.Content {...props} className={classNames(modalContent({ size }), className)} ref={ref} />
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    </ModalContentProvider>
  )
);
ModalContent.displayName = 'ModalContent';

const ModalHeader = forwardRef<ModalHeaderElement, ModalHeaderProps>(({ className, ...props }, ref) => {
  const modalContent = useModalContentContext('ModalHeader');

  return <div {...props} className={classNames(modalHeader({ size: modalContent.size }), className)} ref={ref} />;
});
ModalHeader.displayName = 'ModalHeader';

const ModalTitle = forwardRef<ModalTitleElement, ModalTitleProps>(({ size, weight, ...props }, ref) => {
  const modalContent = useModalContentContext('ModalTitle');
  const inferredSize = modalContent.size === 'sm' ? 'md' : 'lg';
  const inferredWeight = modalContent.size === 'sm' ? 'semibold' : 'bold';

  return (
    <DialogPrimitive.Title asChild>
      <Title {...props} ref={ref} size={size || inferredSize} weight={weight || inferredWeight} />
    </DialogPrimitive.Title>
  );
});
ModalTitle.displayName = 'ModalTitle';

const ModalDescription = forwardRef<ModalDescriptionElement, ModalDescriptionProps>(
  ({ size, weight, ...props }, ref) => {
    const modalContent = useModalContentContext('ModalDescription');
    const inferredSize = modalContent.size === 'sm' ? 'xs' : 'md';
    const inferredWeight = 'regular';

    return (
      <DialogPrimitive.Description asChild>
        <Text {...props} ref={ref} size={size || inferredSize} weight={weight || inferredWeight} />
      </DialogPrimitive.Description>
    );
  }
);
ModalDescription.displayName = 'ModalDescription';

const ModalFooter = forwardRef<ModalFooterElement, ModalFooterProps>(({ className, orientation, ...props }, ref) => {
  const modalContent = useModalContentContext('ModalFooter');
  const inferredOrientation = modalContent.size === 'lg' ? 'horizontal' : 'vertical';

  return (
    <div
      {...props}
      className={classNames(modalFooter({ orientation: orientation || inferredOrientation }), className)}
      ref={ref}
    />
  );
});
ModalFooter.displayName = 'ModalFooter';

export { Modal, ModalClose, ModalContent, ModalDescription, ModalFooter, ModalHeader, ModalTitle, ModalTrigger };
