import { LOCATION_CHANGE } from 'connected-react-router';
import { includes, toLower } from 'lodash';

import { accountApi, documentListApi } from '@/redux/api';
import { history, listenerMiddleware } from '@/redux/store';
import { to } from '@/utils/awaitToJs';

import { createDocumentListSearchParams } from './utils';

// This is the quickest way to implement the "default view" feature using middleware.
// We can consider a component-based implementation in the future for better maintainability.
listenerMiddleware.startListening({
  predicate: (action) => action.type === LOCATION_CHANGE,
  effect: async (action, { dispatch }) => {
    const getCurrentAccount = async () => {
      const result = dispatch(accountApi.endpoints.getCurrentAccount.initiate());

      try {
        const response = await result.unwrap();
        return response;
      } finally {
        result.unsubscribe();
      }
    };

    const getSavedViews = async () => {
      const result = dispatch(documentListApi.endpoints.getSavedViews.initiate());

      try {
        const response = await result.unwrap();
        return response;
      } finally {
        result.unsubscribe();
      }
    };

    const pathnames = ['/documents', '/documents/'];
    const location = action.payload?.location;
    if (!includes(pathnames, toLower(location?.pathname)) || !!location?.search) {
      return;
    }

    const [currentAccountError, currentAccount] = await to(getCurrentAccount());
    if (!!currentAccountError || !currentAccount.subscriptionInfo.features.savedViews) {
      return;
    }

    const [savedViewsError, savedViewsResponse] = await to(getSavedViews());
    const defaultSavedView = savedViewsResponse?.savedViewList.find((savedView) => savedView.isDefault);
    if (!!savedViewsError || !defaultSavedView) {
      return;
    }

    const searchParams = createDocumentListSearchParams(defaultSavedView);
    const search = searchParams.toString();
    history.replace({ search });
  }
});

export { DocumentList } from './DocumentList';
