import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import type { INewTranslationsImportedModalProps } from '@/redux/slices/modalsSlice/types/props';
import { isNonNullable } from '@/utils/isNonNullable';

export const NewTranslationsImportedModal: FunctionComponent<
  TJumboModalProps & { props: INewTranslationsImportedModalProps }
> = ({ props: { accountName }, ...modalProps }) => {
  const { t } = useTranslation('modals');

  return (
    <Modal.Jumbo {...modalProps}>
      {{
        header: isNonNullable(accountName)
          ? t('newTranslationsImportedBy', { accountName })
          : t('newTranslationsImported'),
        content: isNonNullable(accountName)
          ? t('hasUploadedSomeTranslationsForThisDocumentPleaseViewUpdatedDocument', { accountName })
          : t('someTranslationsUploadedForThisDocumentPleaseViewUpdatedDocument'),
        footer: (
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('reloadThePage')}
          </Button>
        )
      }}
    </Modal.Jumbo>
  );
};
