import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import InlineMessage from '@/components/v2/InlineMessage';
import { Show } from '@/components/v2/Show';
import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetDocumentDetailQuery } from '@/redux/api';
import { TRIAL_WORDCOUNT_LIMIT } from '@/redux/api/constants';
import { SourceDocumentDtoStatus } from '@/redux/api/types';
import { AddLanguageModal } from '@/routes/Document/components/AddLanguageModal';
import { useDownloadEvents } from '@/routes/Document/hooks/useDownload';
import { Beacon } from '@/utils/helpScout';

import { useColumns } from './columns';

const DATA_TABLE_SKELETON_COUNT = 5;

interface DocumentTranslationsProps {
  documentId: number;
}
const DocumentTranslations = (props: DocumentTranslationsProps) => {
  const { documentId } = props;

  const { currentAccount } = useCurrentAccount();

  const { data: documentDetail, isUninitialized, isLoading, isFetching } = useGetDocumentDetailQuery(documentId);
  const canManageDocument = !!documentDetail?.sourceDocument.isOwner || !!documentDetail?.sourceDocument.hasWriteAccess;

  useDownloadEvents(documentId);

  const { t: tDocument } = useTranslation('document');

  const columns = useColumns({
    documentId,
    canManageDocument
  });

  if (isUninitialized || isLoading) {
    return (
      <Surface className="space-y-6 px-7 py-8" radius="sm">
        <div className="flex items-center justify-end">
          <Skeleton>
            <Button variant="text">
              <AccessibleIcon label={tDocument('addLanguagesIcon')} icon="ri-add-line" />
              {tDocument('addLanguages')}
            </Button>
          </Skeleton>
        </div>

        <DataTable.Root columns={columns} data={DATA_TABLE_SKELETON_COUNT} loading>
          <DataTable.Content layout="fixed">
            <DataTable.Header />
            <DataTable.Body />
          </DataTable.Content>
        </DataTable.Root>
      </Surface>
    );
  }

  if (!documentDetail) {
    return null;
  }

  const { sourceDocument, translations } = documentDetail;

  const isTrial = currentAccount.subscriptionInfo.isTrial;
  const isWordCountToobig = sourceDocument.wordCount > TRIAL_WORDCOUNT_LIMIT;

  const canOperateOnDocument = !(isTrial && isWordCountToobig);

  const errorMessage = (
    <InlineMessage.Root
      type="error"
      orientation="horizontal"
      rootClassName="w-full"
      className="flex w-full flex-row justify-between"
    >
      <Text size="sm" weight="semibold" className="flex items-center text-red-700">
        {tDocument('planTrialLimitWordCount')}
      </Text>
      <Button variant="surface" color="secondary" asChild>
        <Link to="/documents/new">{tDocument('uploadNewDocument')}</Link>
      </Button>
    </InlineMessage.Root>
  );

  const renderBasedOnDocumentStatus = {
    [SourceDocumentDtoStatus.INDEXED]: (
      <Show when={canOperateOnDocument} fallback={errorMessage}>
        <Surface className="space-y-6 px-7 py-8" radius="sm">
          <Show when={canManageDocument}>
            <div className="flex items-center justify-end">
              <AddLanguageModal documentIds={[documentId]}>
                <Button variant="text">
                  <AccessibleIcon label={tDocument('addLanguagesIcon')} icon="ri-add-line" />
                  {tDocument('addLanguages')}
                </Button>
              </AddLanguageModal>
            </div>
          </Show>
          <DataTable.Root columns={columns} data={translations} loading={isFetching}>
            <DataTable.Content layout="fixed">
              <DataTable.Header />
              <DataTable.Body
                fallback={
                  <div className="flex flex-col items-center gap-2">
                    <AccessibleIcon
                      label="arrow-down"
                      icon="ri-chat-new-line"
                      className="h-9 w-9 items-center justify-center text-[1.75rem] text-alpha-400"
                    />
                    <div className="w-full max-w-sm space-y-1 text-center">
                      <Text size="sm" weight="bold" color="tertiary">
                        {tDocument('thisDocumentHasNoTargetLanguageYet')}
                      </Text>
                      <Text size="sm" color="tertiary">
                        {tDocument('clickAddToSetTargetLanguagesAndTranslators')}
                      </Text>
                    </div>
                  </div>
                }
              />
            </DataTable.Content>
          </DataTable.Root>
        </Surface>
      </Show>
    ),
    [SourceDocumentDtoStatus.UPLOADED]: <DocumentTranslationsUploaded />,
    [SourceDocumentDtoStatus.INDEXING_ERROR]: (
      <DocumentTranslationsIndexingError indexingError={documentDetail.sourceDocument.indexingError} />
    )
  };

  return renderBasedOnDocumentStatus[documentDetail.sourceDocument.status] ?? <p>{tDocument('statusUnknown')}</p>;
};

const DocumentTranslationsUploaded = () => {
  const { t: tDocument } = useTranslation('document');

  return (
    <div className="flex h-[480px] w-full flex-col items-center">
      <InlineMessage.Root type="informational" orientation="horizontal" rootClassName="w-full self-start">
        <InlineMessage.Title size="sm" weight="semibold" className="text-blue-800">
          {tDocument('documentIsCurrentlyBeingIndexed')}
        </InlineMessage.Title>
      </InlineMessage.Root>
      <div className="flex h-full justify-center">
        <Spinner size="lg" />
      </div>
    </div>
  );
};
DocumentTranslationsUploaded.displayName = 'DocumentTranslationsUploaded';

interface DocumentTranslationsIndexingErrorProps {
  indexingError?: string;
}
const DocumentTranslationsIndexingError = (props: DocumentTranslationsIndexingErrorProps) => {
  const { indexingError } = props;

  const { t: tDocument } = useTranslation('document');

  const handleContactSupport = () => {
    Beacon('open');
  };

  return (
    <InlineMessage.Root type="error" orientation="horizontal" rootClassName="w-full">
      <InlineMessage.Title size="sm" weight="bold" className="text-red-700">
        {tDocument('documentInlineMessageTitleIndexingError')}
      </InlineMessage.Title>
      <Show when={indexingError}>
        <InlineMessage.Text size="sm" weight="regular" className="text-red-700">
          {indexingError}
        </InlineMessage.Text>
      </Show>

      <div className="mt-4 flex justify-end gap-4">
        <Button onClick={handleContactSupport} variant="surface" color="secondary">
          {tDocument('contactSupport')}
        </Button>
        <ButtonLink href={`/documents/new`} variant="solid">
          <p>{tDocument('uploadAgain')}</p>
        </ButtonLink>
      </div>
    </InlineMessage.Root>
  );
};
DocumentTranslationsIndexingError.displayName = 'DocumentTranslationsIndexingError';

export { DocumentTranslations };
export type { DocumentTranslationsProps };
