import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableColumnDef } from '@/components/v2/DataTable';
import { GlossaryEntryListItemDto } from '@/redux/api/types';
import { Text } from '@/components/v2/Text';
import { format } from '@/utils/date';
import { LanguageContentHeaderCell } from '@/routes/GlossaryDetail/components/LanguageContentHeaderCell';
import { ActionCell } from '@/routes/GlossaryDetail/components/ActionCell';

interface UseColumnsProps {
  glossaryId: number;
}

const useColumns = ({ glossaryId }: UseColumnsProps) => {
  const { t: tGlossary } = useTranslation('glossary');

  const columns: DataTableColumnDef<GlossaryEntryListItemDto>[] = useMemo(
    () => [
      {
        id: 'source-content',
        accessorKey: 'sourceContent',
        header: () => <LanguageContentHeaderCell glossaryId={glossaryId} language="source" />,
        cell: (value) => <Text>{value.cell.getValue<string>()}</Text>
      },
      {
        id: 'target-content',
        accessorKey: 'targetContent',
        header: () => <LanguageContentHeaderCell glossaryId={glossaryId} language="target" />,
        cell: (value) => <Text>{value.cell.getValue<string>()}</Text>
      },
      {
        id: 'notes',
        accessorKey: 'notes',
        header: () => tGlossary('notes'),
        cell: (value) => <Text>{value.cell.getValue<string>() ?? '-'}</Text>
      },
      {
        id: 'last-updated-at',
        accessorKey: 'lastUpdatedAt',
        header: () => tGlossary('lastUpdate'),
        cell: (value) => <Text>{format(value.cell.getValue<string>(), 'DD/MM/YYYY, HH:mm:ss')}</Text>
      },
      {
        id: 'actions',
        cell: () => <ActionCell glossaryId={glossaryId} />
      }
    ],
    [glossaryId, tGlossary]
  );

  return columns;
};

export { useColumns };
