import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { ElementRef, forwardRef } from 'react';

import { Text } from '@/components/v2/Text';
import { TextProps } from '@/components/v2/Text/types';
import { tooltipVariant } from '@/components/v2/Tooltip/styles';
import { TooltipContentType } from '@/components/v2/Tooltip/types';
import { classNames } from '@/utils/classNames';

export const TooltipProvider = TooltipPrimitive.Provider;

export const Tooltip = TooltipPrimitive.Root;

export const TooltipTrigger = TooltipPrimitive.Trigger;

export const TooltipArrow = TooltipPrimitive.Arrow;

export const TooltipLabel = forwardRef<ElementRef<typeof Text>, TextProps>(
  ({ size = 'xs', color = 'primary', ...props }, ref) => <Text ref={ref} size={size} color={color} {...props} />
);
TooltipLabel.displayName = 'TooltipLabel';

export const TooltipContent = forwardRef<ElementRef<typeof TooltipPrimitive.Content>, TooltipContentType>(
  ({ className, sideOffset = 4, size, ...props }, ref) => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={classNames(tooltipVariant({ size }), className)}
      {...props}
    />
  )
);

TooltipContent.displayName = TooltipPrimitive.Content.displayName;
