import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { surface } from './styles';
import type { SurfaceElement, SurfaceProps } from './types';

const Surface = forwardRef<SurfaceElement, SurfaceProps>(
  ({ asChild, border, radius, shadow, className, ...props }, ref) => {
    const Component = asChild ? Slot : 'div';
    return <Component {...props} ref={ref} className={classNames(surface({ border, radius, shadow }), className)} />;
  }
);
Surface.displayName = 'Surface';

export { Surface };
