import { find as _find, isNumber as _isNumber, noop as _noop } from 'lodash';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDebouncedCallback } from 'use-debounce';

import type { IParagraph } from '@/redux/api/types';

import { NO_PAGE_NUMBER, PAGE_CHANGE_WAIT } from './constants';
import type { IDocumentLanguageParagraphsProps } from './types';
import { findFirstInViewPage, groupParagraphs } from './utils';

export const DocumentLanguageParagraphs = ({
  paragraphs,
  teamPresence,
  rootMargin,
  renderParagraph,
  onPageChange = _noop
}: IDocumentLanguageParagraphsProps) => {
  const { t } = useTranslation('documentLanguageParagraphs');
  const debouncedOnPageChange = useDebouncedCallback(onPageChange, PAGE_CHANGE_WAIT);

  const groupedParagraphs = useMemo(() => groupParagraphs(paragraphs), [paragraphs]);
  const inViewPagesRef = useRef<Record<number, boolean>>({});

  const onInViewPageChange = (pageNumber: number, inView: boolean) => {
    const inViewPages = inViewPagesRef.current;
    if (inViewPages[pageNumber] === inView) {
      return;
    }

    inViewPages[pageNumber] = inView;

    const firstInViewPage = findFirstInViewPage(inViewPages);
    if (_isNumber(firstInViewPage)) {
      debouncedOnPageChange(firstInViewPage);
    }
  };

  const renderParagraphs = (paragraphs: IParagraph[]) =>
    paragraphs.map((paragraph) => {
      const presenceOnParagraph = _find(teamPresence, { paragraphId: paragraph.id });
      return renderParagraph(paragraph, presenceOnParagraph?.teamMember);
    });

  return (
    <div className="space-y-8">
      {groupedParagraphs.map(([pageNumber, paragraphs]) =>
        pageNumber !== NO_PAGE_NUMBER ? (
          <InView
            key={pageNumber}
            rootMargin={rootMargin}
            onChange={(inView) => {
              onInViewPageChange(pageNumber, inView);
            }}
            className="space-y-0.5"
          >
            <div className="flex h-8 items-center rounded-sm bg-white px-4 font-bold text-gray-500">
              {t('page', { number: pageNumber })}
            </div>

            {renderParagraphs(paragraphs)}
          </InView>
        ) : (
          <div key={pageNumber} className="space-y-0.5">
            {renderParagraphs(paragraphs)}
          </div>
        )
      )}
    </div>
  );
};
