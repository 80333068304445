import { first as _first } from 'lodash';
import { useLayoutEffect, useMemo, useState } from 'react';

export type TUseMeasureRect = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;
export type TUseMeasureRef<E extends Element = Element> = (element: E) => void;
export type TUseMeasureResult<E extends Element = Element> = [TUseMeasureRef<E>, TUseMeasureRect];

const defaultState: TUseMeasureRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
};

export const useMeasure = <E extends Element = Element>(): TUseMeasureResult<E> => {
  const [element, ref] = useState<E | null>(null);
  const [rect, setRect] = useState<TUseMeasureRect>(defaultState);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const entry = _first(entries);
        if (entry) {
          const { x, y, width, height, top, left, bottom, right } = entry.contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return [ref, rect];
};
