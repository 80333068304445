import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z, ZodType } from 'zod';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { Layout } from '@/routes/CreateGlossaryEntry/components/Layout';
import { Surface } from '@/components/v2/Surface';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { to } from '@/utils/awaitToJs';
import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import Form from '@/components/v2/Form';
import { useGetGlossaryByIdQuery, useUpdateGlossaryEntryMutation } from '@/redux/api/glossaryApi';
import { useToast } from '@/components/v2/Toast';
import { GlossaryUpdateEntrySkeleton } from '@/routes/GlossaryUpdateEntry/components/GlossaryUpdateEntrySkeleton';

export const GlossaryUpdateEntry = () => {
  const { t: tGlossary } = useTranslation('glossary');
  const history = useHistory();
  const { glossaryId, entryId } = useCreateGlossaryEntryParams();
  const { toast } = useToast();

  const { data, isLoading, isUninitialized } = useGetGlossaryByIdQuery({ id: glossaryId });
  const [triggerUpdateGlossaryEntity] = useUpdateGlossaryEntryMutation();

  const formSchema: ZodType = z.object({
    sourceContent: z
      .string()
      .trim()
      .min(1, { message: tGlossary('thisFieldIsRequired') }),
    targetContent: z
      .string()
      .trim()
      .min(1, { message: tGlossary('thisFieldIsRequired') }),
    notes: z.string().optional()
  });

  const entry = useMemo(() => data?.entries.find((entry) => entry.id === entryId), [data?.entries, entryId]);

  const form = Form.useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      sourceContent: entry?.sourceContent,
      targetContent: entry?.targetContent,
      notes: entry?.notes
    }
  });

  const onSubmit = async (body: z.infer<typeof formSchema>) => {
    const [err, res] = await to(triggerUpdateGlossaryEntity({ entryId, glossaryId, body }).unwrap());

    if (!err && res.id) {
      toast({
        title: tGlossary('entryUpdatedCorrectly'),
        kind: 'success'
      });
      history.push(`/glossaries/show/${glossaryId}`);
    }
  };

  if (isLoading || isUninitialized) {
    return <GlossaryUpdateEntrySkeleton />;
  }

  return (
    <Layout>
      <Form.Root {...form} onSubmit={onSubmit} className="flex flex-col gap-7" devTool>
        <Surface className="flex flex-col gap-8 px-7 py-8">
          <Text size="lg" weight="bold" color="primary">
            {tGlossary('updateGlossaryEntry')}
          </Text>

          <Form.Field name="sourceContent" rules={{ required: true }} className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
            <Label htmlFor="sourceContent" text={tGlossary('sourceContent')} isRequired />
            <Form.Control>{({ field }) => <Input {...field} id="sourceContent" />}</Form.Control>
            <Form.Error />
          </Form.Field>

          <Form.Field name="targetContent" rules={{ required: true }} className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
            <Label htmlFor="targetContent" text={tGlossary('targetContent')} isRequired />
            <Form.Control>{({ field }) => <Input {...field} id="targetContent" />}</Form.Control>
            <Form.Error />
          </Form.Field>

          <Form.Field name="notes" rules={{ required: false }} className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
            <Label htmlFor="notes" text={tGlossary('notes')} isRequired={false} />
            <Form.Control>{({ field }) => <Input {...field} id="notes" />}</Form.Control>
            <Form.Error />
          </Form.Field>
        </Surface>
        <Button variant="solid" color="primary" className="self-end" type="submit">
          {tGlossary('updateEntry')}
        </Button>
      </Form.Root>
    </Layout>
  );
};

const useCreateGlossaryEntryParams = () => {
  const params = useParams<{ glossaryId: string; entryId: string }>();
  const parsedParams = useMemo(
    () => ({ glossaryId: parseInt(params.glossaryId), entryId: parseInt(params.entryId) }),
    [params]
  );
  return parsedParams;
};
