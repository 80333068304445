import { compact } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useAppLayoutLinks = () => {
  const { t: tLayout } = useTranslation('layout');

  return useMemo(
    () =>
      compact([
        {
          key: 'documents',
          href: '/documents',
          icon: 'ri-file-text-fill',
          label: tLayout('documents')
        },
        {
          key: 'translationMemory',
          href: `/translation-memories`,
          icon: 'ri-save-fill',
          label: tLayout('translationMemory')
        },
        {
          key: 'glossary',
          href: `/glossaries`,
          icon: 'ri-translate-2',
          label: tLayout('glossary')
        }
      ]),
    [tLayout]
  );
};

export { useAppLayoutLinks };
