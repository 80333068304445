import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import { ErrorModalAction } from '@/redux/slices/modalsSlice/constants';
import type { IErrorModalProps } from '@/redux/slices/modalsSlice/types/props';

export const ErrorModal: FunctionComponent<TJumboModalProps & { props: IErrorModalProps }> = ({
  props: { content, action = ErrorModalAction.CLOSE },
  ...modalProps
}) => {
  const { t } = useTranslation('modals');

  const renderFooter = () => {
    switch (action) {
      case ErrorModalAction.RELOAD:
        return (
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('reloadThePage')}
          </Button>
        );

      default:
        return (
          <Button
            onClick={() => {
              modalProps.onClose(false);
            }}
          >
            {t('close')}
          </Button>
        );
    }
  };

  return (
    <Modal.Jumbo {...modalProps}>
      {{
        header: t('anErrorOccurred'),
        content: content ?? t('anErrorHasOccurredPleaseTryAgainGetInTouch'),
        footer: renderFooter()
      }}
    </Modal.Jumbo>
  );
};
