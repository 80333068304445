import { first as _first } from 'lodash';
import { FunctionComponent } from 'react';

import { Avatar } from '@/components/v1/Avatar';
import { Popover } from '@/components/v1/Popover';
import { Show } from '@/components/v1/Show';
import { useOffline } from '@/hooks/useOffline';
import { EllipsisVIcon } from '@/icons/solid';
import { useCurrentAccountContext } from '@/providers/CurrentAccountProvider';
import { classNames } from '@/utils/classNames';
import { standardDateTimeFormat } from '@/utils/date';

import { CommentActions } from '../CommentActions';
import { CommentRenderer } from '../CommentRenderer';
import type { ICommentItemProps } from './types';

export const CommentItem: FunctionComponent<ICommentItemProps> = ({
  elementId,
  account,
  content,
  createdAt,
  defaultTeamMemberColor,
  teamMembersColors,
  onDelete
}) => {
  const { currentAccount } = useCurrentAccountContext();
  const offline = useOffline();

  return (
    <div id={elementId} className="flex items-center rounded bg-white p-1 hover:bg-gray-100">
      <div className="flex-1">
        <div className="flex items-center space-x-2">
          <Avatar size="sm" alt={_first(account.fullName)} src={account.avatarUrl || undefined} />

          <div className="space-x-2">
            <span className="font-bold">{account.fullName}</span>

            <span className="text-2xs text-gray-500">{standardDateTimeFormat(createdAt)}</span>
          </div>
        </div>

        <CommentRenderer
          className="ml-6"
          comment={content}
          defaultMentionColor={defaultTeamMemberColor}
          mentionColors={teamMembersColors}
        />
      </div>

      <Show when={account.id === currentAccount.id}>
        <Popover placement="bottom-end" className="w-60" content={<CommentActions onDeleteClick={onDelete} />}>
          <Popover.Button
            tabIndex={-1}
            className={classNames('w-6 rounded px-2 py-1 text-blue-500', {
              'hover:bg-blue-50 hover:text-blue-600 focus:bg-blue-100': !offline,
              'cursor-not-allowed opacity-50': offline
            })}
            disabled={offline}
          >
            <EllipsisVIcon />
          </Popover.Button>
        </Popover>
      </Show>
    </div>
  );
};
