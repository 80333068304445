import { escapeRegExp } from 'lodash';
import { FocusEventHandler, KeyboardEventHandler, ReactNode, useCallback, useEffect } from 'react';
import { Editor, NodeEntry, Range, Text, Transforms } from 'slate';
import { Editable, ReactEditor, RenderPlaceholderProps, useSlate } from 'slate-react';

import { PenIcon } from '@/icons/solid';
import { renderElement, renderLeaf } from '@/slate/elements';
import { editorIsEmpty } from '@/slate/utils';
import type { IAttributes } from '@/types/slate';
import { classNames } from '@/utils/classNames';
import { composeEventHandlers } from '@/utils/composeEventHandlers';

import { Show } from '../Show';
import { ETranslationInputStatus } from './constants';
import { useTranslationInputContext } from './TranslationInputRoot';

export interface ITranslationInputEditableProps {
  autoFocus?: boolean;
  dir?: string;
  disabled?: boolean;
  lang?: string;
  placeholder?: string;
  readOnly?: boolean;
  search?: string;
  action?: ReactNode;
  message?: ReactNode;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onKeyUp?: KeyboardEventHandler<HTMLDivElement>;
}

export const TranslationInputEditable = (props: ITranslationInputEditableProps) => {
  const { autoFocus, disabled, placeholder, readOnly, search, action, message, onBlur, onFocus, ...editableProps } =
    props;

  const editor = useSlate();
  const context = useTranslationInputContext('TranslationInputEditable');

  const empty = editorIsEmpty(editor);
  const showPlaceholder = !disabled && !context.focused;

  const decorate = useCallback(
    (entry: NodeEntry): Range[] => {
      const [node, path] = entry;
      const ranges: (Range & { attributes?: IAttributes })[] = [];

      if (search && Text.isText(node)) {
        const { text } = node;
        const parts = text.split(new RegExp(escapeRegExp(search), 'i'));
        let offset = 0;

        parts.forEach((part, i) => {
          if (i > 0) {
            ranges.push({
              anchor: { path, offset: offset - search.length },
              focus: { path, offset },
              attributes: { highlight: true }
            });
          }

          offset = offset + part.length + search.length;
        });
      }

      return ranges;
    },
    [search]
  );

  const handleFocus = composeEventHandlers(onFocus, () => {
    context.onFocusChange(true);
  });

  const handleBlur = composeEventHandlers(onBlur, () => {
    context.onFocusChange(false);
  });

  useEffect(() => {
    if (autoFocus && !disabled && !readOnly) {
      if (!ReactEditor.isFocused(editor)) {
        ReactEditor.focus(editor);
      }

      const end = Editor.end(editor, []);
      Transforms.select(editor, { anchor: end, focus: end });
    }
  }, [autoFocus, disabled, editor, readOnly]);

  return (
    <div
      className={classNames(
        'grid h-full rounded border',
        disabled && 'border-gray-300 bg-gray-100',
        !disabled &&
          (context.focused
            ? 'border-blue-500 ring-1 ring-blue-500'
            : {
                'border-red-500 bg-red-100': context.status === ETranslationInputStatus.ERROR,
                'border-yellow-500': context.status === ETranslationInputStatus.WARNING,
                'border-green-500': context.status === ETranslationInputStatus.SUCCESS,
                'border-gray-300': !context.status,
                'bg-yellow-100': context.status !== ETranslationInputStatus.ERROR && empty
              })
      )}
      style={{ gridTemplateColumns: '1fr auto', gridTemplateRows: '1fr auto' }}
    >
      <Editable
        {...editableProps}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        className="break-anywhere focus:outline-none relative whitespace-pre-wrap px-2 py-1 leading-relaxed"
        decorate={decorate}
        disableDefaultStyles
        disabled={disabled}
        placeholder={showPlaceholder ? placeholder : undefined}
        readOnly={disabled || readOnly}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        renderPlaceholder={renderPlaceholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      <span className="flex items-center justify-center py-1 pr-2 leading-none" style={{ height: 30 }}>
        <Show when={context.focused} fallback={action}>
          <PenIcon className="h-4 w-4 opacity-40" />
        </Show>
      </span>

      <div className="col-span-2">{message}</div>
    </div>
  );
};

const renderPlaceholder = (props: RenderPlaceholderProps) => {
  const { attributes, children } = props;

  return (
    <span
      className="break-anywhere whitespace-pre-wrap px-2 py-1 leading-relaxed"
      {...attributes}
      style={{ ...attributes.style, top: 0, left: 0, opacity: 0.4 }}
    >
      {children}
    </span>
  );
};
