import { Slot } from '@radix-ui/react-slot';
import { ComponentPropsWithoutRef, ElementRef, forwardRef, SyntheticEvent, useCallback } from 'react';

import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useAppDispatch } from '@/redux/hooks';
import { openFeatureNotAvailableModal } from '@/redux/slices/modalsSlice';
import { composeEventHandlers } from '@/utils/composeEventHandlers';
import { PlanFeaturesDto } from '@/redux/api/types';

/**
 * Component to handle the requirement of an upgrade for a feature.
 * Some features are only available for certain plans.
 * This component will check if the current plan has the feature enabled and, if not, it will open the feature not available modal.
 */
const RequireUpgrade = forwardRef<RequireUpgradeElement, RequireUpgradeProps>((props, ref) => {
  const { onChange, onClick, onSelect, feature, ...slotProps } = props;

  const dispatch = useAppDispatch();

  const { currentAccount } = useCurrentAccount();
  const { subscriptionInfo } = currentAccount;

  const handleAction = useCallback(
    (e: Event | SyntheticEvent) => {
      const { features } = subscriptionInfo;
      const isFeatureEnabled = Boolean(features[feature as keyof typeof features]);

      if (!isFeatureEnabled) {
        e.stopPropagation();
        e.preventDefault();

        // Show feature not enabled modal
        dispatch(openFeatureNotAvailableModal({ feature }));
      }
    },
    [dispatch, feature, subscriptionInfo]
  );

  const handleChange = composeEventHandlers(onChange, handleAction);
  const handleClick = composeEventHandlers(onClick, handleAction);
  const handleSelect = composeEventHandlers(onSelect, handleAction);

  return <Slot {...slotProps} ref={ref} onChange={handleChange} onClick={handleClick} onSelect={handleSelect} />;
});
RequireUpgrade.displayName = 'RequireUpgrade';

interface RequireUpgradeProps extends ComponentPropsWithoutRef<typeof Slot> {
  feature: keyof PlanFeaturesDto;
}
type RequireUpgradeElement = ElementRef<typeof Slot>;

export { RequireUpgrade };
export type { RequireUpgradeProps };
