import { Tab as BaseTab } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, FunctionComponent } from 'react';

import { isFunction } from '@/utils/isFunction';

import { Label } from '../Label';
import { Tab } from './Tab';
import type { ITabsProps } from './types';
import { findTabs } from './utils';

export const Tabs: FunctionComponent<ITabsProps> & { Tab: typeof Tab } = ({ tabsExtra, children, className }) => {
  const tabs = findTabs(children);

  return (
    <BaseTab.Group
      as="div"
      className={(props) =>
        classNames('divide-y text-sm text-gray-800', isFunction(className) ? className(props) : className)
      }
    >
      <div className="flex items-end">
        <BaseTab.List className="flex h-6 flex-1 divide-x">
          {tabs.map((tab, i) => {
            const { props } = tab;

            return (
              <BaseTab
                key={i}
                className={classNames('flex items-end px-4', { 'pointer-events-none': props.clickable === false })}
              >
                {({ selected }) => (
                  <Label underline={selected} className="font-bold">
                    {props.tab}
                  </Label>
                )}
              </BaseTab>
            );
          })}
        </BaseTab.List>

        <div>{tabsExtra}</div>
      </div>

      <BaseTab.Panels className="px-4 py-2">
        {tabs.map((tab, i) => (
          <Fragment key={i}>{tab}</Fragment>
        ))}
      </BaseTab.Panels>
    </BaseTab.Group>
  );
};

Tabs.Tab = Tab;
