import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { useToast } from '@/components/v2/Toast';
import { useRemoveTranslatorMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';

export const CofirmRemoveTranslatorModal = (props: ConfirmDeleteCurrentUserAvatarModalProps) => {
  const { children, translatorAccountId, ...modalProps } = props;
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const [removeTranlsatorTrigger, { isLoading }] = useRemoveTranslatorMutation();

  const handleConfirm = async () => {
    const [err] = await to(removeTranlsatorTrigger({ translatorAccountId }).unwrap());
    if (!err) {
      toast({ title: tSettings('removedTranslatorWithSuccessToastTitle'), kind: 'success' });
    }
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="md">
        <Modal.Header>
          <Modal.Title size="lg">{tSettings('confirmRemoveTranslatorModalTitle')}</Modal.Title>
          <Modal.Description size="md">{tSettings('thisActionCanNotBeUndone')}</Modal.Description>
        </Modal.Header>

        <Modal.Footer orientation={'vertical'}>
          <Button color="negative" onClick={handleConfirm} disabled={isLoading}>
            {tSettings('removeTranslator')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tSettings('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface ConfirmDeleteCurrentUserAvatarModalProps extends ModalProps {
  translatorAccountId: number;
}
