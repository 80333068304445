import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TImportNotification, TImportSegmentsSlice } from './types';

const INITIAL_STATE: TImportSegmentsSlice = {
  batchId: 0,
  importStatus: '',
  errorMessage: '',
  newStatusErrorMessage: '',
  newStatusImportStatus: '',
  notifications: []
};

export const importSegmentsSlice = createSlice({
  name: 'importSegments',
  initialState: INITIAL_STATE,

  reducers: {
    setBatchId: (state, { payload }: PayloadAction<number>) => {
      state.batchId = payload;
    },
    setImportStatus: (state, { payload }: PayloadAction<{ importStatus: string; errorMessage: string }>) => {
      state.importStatus = payload.importStatus;
      state.errorMessage = payload.errorMessage;
    },
    setNewStatus: (
      state,
      { payload }: PayloadAction<{ newStatusImportStatus: string; newStatusErrorMessage: string }>
    ) => {
      state.newStatusImportStatus = payload.newStatusImportStatus;
      state.newStatusErrorMessage = payload.newStatusErrorMessage;
    },
    setImportNotifications: (state, { payload }: PayloadAction<TImportNotification[]>) => {
      state.notifications = payload;
    },
    resetImportStatus: (state) => {
      Object.assign(state, INITIAL_STATE);
    }
  }
});

export const { setBatchId, setImportStatus, setNewStatus, resetImportStatus, setImportNotifications } =
  importSegmentsSlice.actions;
