import { cva } from 'cva';

import s from './Skeleton.module.css';

const skeleton = cva(
  [
    'skeleton',
    s['skeleton'],
    'pointer-events-none relative block select-none overflow-hidden rounded-xl bg-gradient-to-r from-gray-200 text-transparent'
  ],
  {
    variants: {
      fitContent: {
        true: 'h-auto w-[fit-content]',
        false: ''
      }
    }
  }
);

export { skeleton };
