import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipArrow, TooltipLabel } from './Tooltip';

const TooltipDefault = {
  Provider: TooltipProvider,
  Root: Tooltip,
  Trigger: TooltipTrigger,
  Content: TooltipContent,
  Arrow: TooltipArrow,
  Label: TooltipLabel
};

export { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger, TooltipLabel };
export default TooltipDefault;
