import classNames from 'classnames';

import { BUTTON_BASE_CLASS_NAME, BUTTON_COLORS, BUTTON_DEFAULTS, BUTTON_SIZES } from './constants';
import type { IButtonCustomProps } from './types';

export const getButtonClassName = ({
  btnType = BUTTON_DEFAULTS.btnType,
  color = BUTTON_DEFAULTS.color,
  disabled,
  size = BUTTON_DEFAULTS.size,
  className,
  loading
}: IButtonCustomProps) => {
  const { colors, hoverColors } = BUTTON_COLORS[btnType];
  const hoverClassName = hoverColors[color];

  return classNames(
    BUTTON_BASE_CLASS_NAME,
    colors[color],
    BUTTON_SIZES[size],
    { [hoverClassName]: !disabled && !loading },
    className
  );
};
