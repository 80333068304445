import type { ReactNode } from 'react';
import { useState } from 'react';
import type { Descendant } from 'slate';
import { ReactEditor, Slate } from 'slate-react';

import { createContext } from '@/utils/createContext';
import type { TAppEditor } from '@/types/slate';

import { ETranslationInputStatus } from './constants';

export interface ITranslationInputRootProps {
  editor: TAppEditor;
  initialValue: Descendant[];
  onChange?: ((value: Descendant[]) => void) | undefined;

  status?: ETranslationInputStatus | null;

  children: ReactNode;
}

export interface ITranslationInputContextValue {
  focused: boolean;
  onFocusChange: (focused: boolean) => void;
  status?: ETranslationInputStatus | null;
}

const [TranslationInputProvider, useTranslationInputContext] =
  createContext<ITranslationInputContextValue>('TranslationInput');

export const TranslationInputRoot = (props: ITranslationInputRootProps) => {
  const { editor, initialValue, onChange, status, children } = props;
  const [focused, setFocused] = useState(() => ReactEditor.isFocused(editor));

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
      <TranslationInputProvider focused={focused} onFocusChange={setFocused} status={status}>
        {children}
      </TranslationInputProvider>
    </Slate>
  );
};

export { useTranslationInputContext };
