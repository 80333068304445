import { TFunction } from 'i18next';

import { LanguagesDto } from '@/redux/api/types';
import { LanguageGroup } from '@/routes/Document/types';

export const createLanguageGroups = (data: LanguagesDto, t: TFunction): LanguageGroup[] => {
  const groupAll: LanguageGroup = {
    name: t('otherLanguages'),
    options: data.all.map((language) => ({
      ...language,
      id: language.id.toString(),
      label: language.name
    }))
  };

  return [groupAll];
};
