import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { APP_URL } from '@/config/urls';
import { useUnmount } from '@/hooks/useUnmount';
import { useWindowScroll } from '@/hooks/useWindowScroll';
import { CogIcon, InboxIcon } from '@/icons/solid';
import { useGetNotificationsQuery, useMarkNotificationReadMutation } from '@/redux/api';
import { ENotificationType, EPlaceholdersVersion } from '@/redux/api/constants';
import { useAppDispatch } from '@/redux/hooks';
import { scrollToParagraph } from '@/redux/slices/documentLanguageSlice';
import { DocumentComments } from '@/routes/DocumentLanguage/components/DocumentComments';
import { ParagraphActivityComments } from '@/routes/DocumentLanguage/components/ParagraphActivityComments';

import { Badge } from '../Badge';
import { Divider } from '../Divider';
import { Label } from '../Label';
import { Show } from '../Show';
import { Spinner } from '../Spinner';
import { TranslationContentV1, TranslationContentV2 } from '../TranslationContent';
import { NotificationItem } from './NotificationItem';
import type { INotificationsProps } from './types';

// Notifications entry point
export const Notifications = ({ account, onGoTo }: INotificationsProps) => {
  const { t } = useTranslation('notifications');
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { scrollToTop } = useWindowScroll();

  const { data: notificationsData, isFetching: isNotificationsDataFetching } = useGetNotificationsQuery();
  const notifications = useMemo(() => notificationsData?.notifications || [], [notificationsData]);
  const [markNotificationRead] = useMarkNotificationReadMutation();

  useUnmount(() => {
    const notification = notifications[0];
    if (notification && !notification.isRead) {
      markNotificationRead({ body: { lastReadNotificationId: notification.id } });
    }
  });

  return (
    <div className="pt-2">
      <div className="flex items-center justify-between px-4">
        <Label underline className="space-x-1 pb-0.5 font-bold">
          <span>{t('inbox')}</span>
          <Badge
            shape="squared"
            value={account.unreadNotificationCount > 0 ? account.unreadNotificationCount : undefined}
          />
        </Label>

        <a
          href={`${APP_URL}/settings/notifications`}
          target="_blank"
          rel="noreferrer"
          className="text-gray-500 hover:text-gray-600"
        >
          <CogIcon />
        </a>
      </div>

      <Divider />

      {isNotificationsDataFetching ? (
        <div className="flex h-28 items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="max-h-144 divide-y overflow-y-scroll">
          {notifications.length ? (
            notifications.map((notification) => {
              const { target, senderAccount } = notification;
              const { sourceLanguage, targetLanguage, document } = target;

              const goToDocumentHref = `/documents/show/${document.id}/translations/${targetLanguage.id}`;
              const sameDocument = pathname === goToDocumentHref;

              switch (notification.type) {
                case ENotificationType.NEW_DOCUMENT_TRANSLATED_COMMENT: {
                  return (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      goToText={t('goToDocument')}
                      onGoToClick={() => {
                        if (sameDocument) {
                          scrollToTop('smooth');
                        } else {
                          window.open(goToDocumentHref, '_blank');
                        }
                        onGoTo();
                      }}
                      title={
                        <Trans
                          i18nKey="notifications:commentedIn"
                          components={{ 1: <span className="font-bold" />, 2: <span className="font-bold" /> }}
                          values={{
                            user: senderAccount.fullName,
                            documentName: document.name,
                            languageName: targetLanguage.name
                          }}
                        />
                      }
                      detail={<DocumentComments documentId={document.id} languageId={targetLanguage.id} compact />}
                    />
                  );
                }
                case ENotificationType.NEW_TRANSLATED_PARAGRAPH_COMMENT: {
                  const { paragraph } = notification.target;

                  return (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      goToText={t('goToTranslation')}
                      onGoToClick={() => {
                        if (sameDocument) {
                          dispatch(
                            scrollToParagraph({
                              paragraphId: paragraph.id,
                              paginationPage: paragraph.paginationPage,
                              openComments: true
                            })
                          );
                        } else {
                          const goToParagraphHref = `?paragraphId=${paragraph.id}&pagePagination=${
                            paragraph.paginationPage
                          }&openComments=${true}`;
                          window.open(`${goToDocumentHref}${goToParagraphHref}`, '_blank');
                        }
                        onGoTo();
                      }}
                      title={
                        <Trans
                          i18nKey="notifications:commentedATranslationIn"
                          components={{ 1: <span className="font-bold" />, 2: <span className="font-bold" /> }}
                          values={{
                            user: senderAccount.fullName,
                            documentName: document.name,
                            languageName: targetLanguage.name
                          }}
                        />
                      }
                      detail={
                        <div className="space-y-3">
                          <div>
                            <Label underline className="ml-4 pb-0.5 font-bold">
                              {t('moreInfo')}
                            </Label>

                            <Divider />

                            <div className="mt-2 space-x-1 px-4">
                              <span className="font-bold">{t('source', { languageName: sourceLanguage.name })}</span>
                              <Show when={document.placeholderVersion === EPlaceholdersVersion.V1}>
                                <TranslationContentV1 content={paragraph.content} />
                              </Show>
                              <Show when={document.placeholderVersion === EPlaceholdersVersion.V2}>
                                <TranslationContentV2 content={paragraph.content} />
                              </Show>
                            </div>

                            <div className="mt-2 space-x-1 px-4">
                              <span className="font-bold">
                                {t('translation', { languageName: targetLanguage.name })}
                              </span>
                              {paragraph.translation ? (
                                <>
                                  <Show when={document.placeholderVersion === EPlaceholdersVersion.V1}>
                                    <TranslationContentV1 content={paragraph.translation} />
                                  </Show>
                                  <Show when={document.placeholderVersion === EPlaceholdersVersion.V2}>
                                    <TranslationContentV2 content={paragraph.translation} />
                                  </Show>
                                </>
                              ) : (
                                <span className="italic text-gray-400">{t('empty')}</span>
                              )}
                            </div>
                          </div>

                          <ParagraphActivityComments
                            documentId={document.id}
                            languageId={targetLanguage.id}
                            paragraphId={paragraph.id}
                          />
                        </div>
                      }
                    />
                  );
                }
                default: {
                  return null;
                }
              }
            })
          ) : (
            <div className="flex flex-col items-center justify-center space-y-2 p-4">
              <InboxIcon size="2x" className="text-gray-500" />
              <p className="text-sm font-bold text-gray-500">{t('youAllCaughtUp')}</p>
              <p className="text-center text-sm text-gray-500">{t('whenSomeoneMentionsOrReplies')}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
