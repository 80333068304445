import { FunctionComponent } from 'react';

import { EventTypes } from '@/redux/api/constants';
import { Document } from '@/routes/Document';
import { GettingKnowToYou } from '@/routes/Onboarding/Welcome/steps/GettingKnowToYou';
import { TranslateYourFirstDocument } from '@/routes/Onboarding/Welcome/steps/TranslateYourFirstDocument';
import { WhatYouCanDo } from '@/routes/Onboarding/Welcome/steps/WhatYouCanDo';
import { PreTranslate } from '@/routes/PreTranslate';
import { PreTranslateReport, PreTranslateReportProps } from '@/routes/PreTranslate/components/PreTranslateReport';
import { SetSourceLanguage, SetTargetLanguage, UploadFile } from '@/routes/UploadDocuments';
import { useTrackEvent } from '@/routes/useTrackEvent';

export const LoaderGettingKnowToYou: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_SURVEY);

  return <GettingKnowToYou />;
};
export const LoaderWhatYouCanDo: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_WHAT_YOU_CAN_DO);

  return <WhatYouCanDo />;
};

export const LoaderTranslateYourFirstDocument: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_TRANSLATE_YOUR_FIRST_DOCUMENT);

  return <TranslateYourFirstDocument />;
};

export const LoaderUploadFile: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_UPLOAD_DOCUMENT);

  return <UploadFile />;
};

export const LoaderSetSourceLanguage: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_SET_SOURCE_LANGUAGE);

  return <SetSourceLanguage />;
};

export const LoaderSetTargetLanguage: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_SET_TARGET_LANGUAGES);

  return <SetTargetLanguage />;
};

export const LoaderDocument: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_DOCUMENT_PAGE);

  return <Document />;
};

export const LoaderPreTranslate: FunctionComponent = () => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_PRE_TRANSLATE_PAGE);

  return <PreTranslate />;
};

export const LoaderPreTranslateReport = (props: PreTranslateReportProps) => {
  useTrackEvent(EventTypes.ONBOARDING_VISITED_PRE_TRANSLATE_REPORT_PAGE);

  return <PreTranslateReport {...props} />;
};
