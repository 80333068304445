import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { HintBox } from '@/components/v2/HintBox';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import Tooltip from '@/components/v2/Tooltip';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetDocumentDetailQuery, useGetFoldersQuery } from '@/redux/api';
import { TRIAL_WORDCOUNT_LIMIT } from '@/redux/api/constants';
import { SourceDocumentDtoStatus } from '@/redux/api/types';

import { ManageDocumentDropdownMenu } from '../ManageDocumentDropdownMenu';
import { SourceDocumentHeaderBreadcrumb } from './SourceDocumentHeaderBreadcrumb';
import { SourceDocumentHeaderSkeleton } from './SourceDocumentHeaderSkeleton';

interface SourceDocumentHeaderProps {
  documentId: number;
}
const SourceDocumentHeader = (props: SourceDocumentHeaderProps) => {
  const { documentId } = props;

  const { t: tDocument } = useTranslation('document');

  const { currentAccount } = useCurrentAccount();

  const {
    data: documentDetail,
    isUninitialized: isDocumentDetailUninitialized,
    isLoading: isDocumentDetailLoading
  } = useGetDocumentDetailQuery(documentId);
  const { data: folders, isUninitialized: isFoldersUninitialized, isLoading: isFoldersLoading } = useGetFoldersQuery();
  const isUninitialized = isDocumentDetailUninitialized || isFoldersUninitialized;
  const isLoading = isDocumentDetailLoading || isFoldersLoading;

  if (isUninitialized || isLoading) {
    return <SourceDocumentHeaderSkeleton />;
  }

  if (!documentDetail || !folders) {
    return null;
  }

  const { sourceDocument, translations } = documentDetail;

  const { language, filename, id, hasWriteAccess: canManageDocument, status } = sourceDocument;

  const isTrial = currentAccount?.subscriptionInfo.isTrial;
  const isWordCountToobig = sourceDocument.wordCount > TRIAL_WORDCOUNT_LIMIT;

  const canOperateOnDocument = !(isTrial && isWordCountToobig);

  const { hasConfirmedAnyDocument, hasPreTranslatedAnyDocument, mustCompleteOnboarding } =
    currentAccount.onboardingFlags;
  // if document is uploaded but not indexed disable document actions
  // the only possible thing that can be done is deleting the document
  // https://github.com/redokun/redokun-frontend/issues/804
  const documentNotReady = status !== SourceDocumentDtoStatus.INDEXED;
  // in the future will be other checks (ex. if subscription is expired)
  const isActionDisabled = documentNotReady || !translations.length;
  const enableHints =
    mustCompleteOnboarding && !hasPreTranslatedAnyDocument && !hasConfirmedAnyDocument && !isActionDisabled;

  return (
    <div>
      <SourceDocumentHeaderBreadcrumb folders={folders} sourceDocument={sourceDocument} />

      <div className="flex items-center justify-between gap-4 py-2">
        <div className="overflow-hidden break-words">
          <OverflowTooltip label={filename}>
            <Title className="whitespace-nowrap">{filename}</Title>
          </OverflowTooltip>
        </div>

        <div className="flex flex-1 items-center gap-2">
          <FlagIcon label={language.name} code={language.flagCode || 'xx'} />
          <Text>{language.name}</Text>
        </div>

        <Show when={canManageDocument}>
          <ManageDocumentDropdownMenu documentId={id} sourceDocument={sourceDocument} />
        </Show>

        <Show when={canOperateOnDocument}>
          <Tooltip.Root open={enableHints}>
            <HintBox when={enableHints}>
              <Tooltip.Trigger asChild>
                <RequireSubscription>
                  <ButtonLink href={`/documents/pre-translate/${id}`} rel="noopener noreferrer">
                    <AccessibleIcon label={tDocument('preTranslateIcon')} icon="ri-timer-flash-line" />
                    {tDocument('preTranslate')}
                  </ButtonLink>
                </RequireSubscription>
              </Tooltip.Trigger>
            </HintBox>
            <Tooltip.Content className="max-w-xxs whitespace-normal text-sm" sideOffset={8} side="bottom">
              <Text className="text-typography-inverse-primary" size="xs">
                {tDocument('weWillUseAutomaticTranslationServicesToSpeedUpYourTranslationProcess')}
              </Text>
              <Text className="text-typography-inverse-secondary" size="xs">
                {tDocument('youCanCheckAndEditItLater')}
              </Text>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
        </Show>
      </div>
    </div>
  );
};

export { SourceDocumentHeader };
export type { SourceDocumentHeaderProps };
