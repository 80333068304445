import pusherAuthorizer from 'pusher-js-auth';

import { APP_URL } from './urls';

const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_APP_CLUSTER } = import.meta.env;

export const PUSHER_APP_KEY = REACT_APP_PUSHER_APP_KEY!;

export const PUSHER_OPTIONS = {
  cluster: REACT_APP_PUSHER_APP_CLUSTER,
  authorizer: pusherAuthorizer,
  authEndpoint: `${APP_URL}/api/v1/pusher/auth`,
  authDelay: 200
};
