import { useTranslation } from 'react-i18next';

import InlineMessage from '@/components/v2/InlineMessage';

export const FormBlockInlineMessage = () => {
  const { t: tSettings } = useTranslation('settings');

  return (
    <InlineMessage.Root orientation="horizontal" type="error">
      <InlineMessage.Title className="text-red-700">{tSettings('accountDeletionWarningTitle')}</InlineMessage.Title>
      <InlineMessage.Text color="secondary">{tSettings('accountDeletionWarningText')}</InlineMessage.Text>
    </InlineMessage.Root>
  );
};
