import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/v2/Avatar';
import { Chip } from '@/components/v2/Chip';
import { getAvatarInitials } from '@/utils/email';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { PartialTranslatorDto } from '@/routes/Document/types';
import { AddTranslatorCombobox } from '@/routes/Document/components/AddLanguageTranslator/components/AddTranslatorCombobox';

import { AddLanguageTranslatorProps } from './types';

export const AddLanguageTranslator = (props: AddLanguageTranslatorProps) => {
  const {
    item,
    index,
    isOnboarding = false,
    hasMultipleDocuments = false,
    onAddTranslator,
    onAddNewTranslator,
    onAddTranslatorToAllDocuments,
    onRemoveLanguage
  } = props;
  const { t } = useTranslation('document');
  const { currentAccount } = useCurrentAccount();
  const { hasConfirmedAnyDocument, mustCompleteOnboarding } = currentAccount.onboardingFlags;

  const isFirstItem = index !== undefined && index === 0;
  const displayTooltip = isFirstItem && Boolean(isOnboarding) && !hasConfirmedAnyDocument && mustCompleteOnboarding;
  // show add translators to all documents button (in combobox) if there are more documents
  const canAddTranslatorsToAllDocuments = hasMultipleDocuments;

  const language = item.language;

  const handleRemoveTranslator = (translator: PartialTranslatorDto) => {
    const existingItem = item.translators?.some((item) => item.id === translator.id);

    if (existingItem) {
      const updated = item.translators?.filter((i) => i.id !== translator.id);

      if (updated) {
        onAddTranslator(updated);
      }
    }
  };

  return (
    <div className="flex flex-col gap-2 py-1">
      <div className="flex flex-row">
        <div className="mr-auto flex items-center gap-2">
          <FlagIcon label={language.name} code={language.flagCode || 'xx'} size="md" />
          <Text size="md" weight="semibold" color="primary">
            {language.name}
          </Text>
        </div>
        <div className="relative flex flex-row items-center gap-5 px-2">
          <AddTranslatorCombobox
            item={item}
            displayTooltip={displayTooltip}
            displayAddTranslatorsToAllDocumentsButton={canAddTranslatorsToAllDocuments}
            onAddTranslator={onAddTranslator}
            onAddNewTranslator={onAddNewTranslator}
            onAddTranslatorToAllDocuments={onAddTranslatorToAllDocuments}
          />
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className="py-2">
              <Button variant="text" color="primary" className="h-full">
                <AccessibleIcon label="ri-more-2-fill" icon="ri-more-2-fill" />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end" className="z-50">
              <DropdownMenu.Item className="cursor-pointer text-states-negative-active" onClick={onRemoveLanguage}>
                <AccessibleIcon icon="ri-delete-bin-line" label={'ri-delete-bin-line'} />
                {t('delete')}
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {item.translators.map((translator) => (
          <Chip key={translator.id} onClose={() => handleRemoveTranslator(translator)}>
            <Avatar size="xxs">
              {translator.avatarUrl ? (
                <AvatarImage src={translator.avatarUrl} />
              ) : (
                <AvatarFallback className="uppercase">{getAvatarInitials(translator.email)}</AvatarFallback>
              )}
            </Avatar>
            <Text color="primary" size="sm" weight="semibold">
              {translator.fullName ? translator.fullName : translator.email}
            </Text>
          </Chip>
        ))}
      </div>
    </div>
  );
};
