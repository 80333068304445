import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DataTable from '@/components/v2/DataTable';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { GlossaryDto } from '@/redux/api/types';

export const SourceLanguageCell = () => {
  const { t: tGlossary } = useTranslation('glossary');
  const { cell } = DataTable.useCellContext<GlossaryDto, GlossaryDto['sourceLanguage']>('SourceLanguageCell');
  const { name, flagCode } = cell.getValue();
  const { glossaryMatchIsBeta } = cell.row.original;

  return (
    <div className="justify-left flex flex-row items-center gap-2">
      <FlagIcon label={name} code={flagCode || 'xx'} size="md" />
      <Text size="sm" weight="medium" color="secondary">
        {name}
      </Text>
      <Show when={glossaryMatchIsBeta}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <AccessibleIcon
              label="warning-icon"
              icon="ri-alert-line"
              className="text-base text-states-alert-active hover:text-states-alert-hover"
            />
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-xxs" side="right">
            <Text className="text-typography-inverse-primary" size="sm" weight="medium">
              {tGlossary('theGlossaryIsStillInBetaForThisLanguage')}
            </Text>
            <Text className="text-typography-inverse-secondary" size="sm" weight="medium">
              {tGlossary('reachOutSupportTeamForAssistance')}
            </Text>
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Tooltip.Root>
      </Show>
    </div>
  );
};
