import React from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import Popover from '@/components/v2/Popover';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import { MtRateLimitingDto, SubscriptionInfoDto } from '@/redux/api/types';
import { Beacon } from '@/utils/helpScout';
import { APP_URL } from '@/config/urls.ts';

type LowResourceTagWarningProps =
  | (Pick<SubscriptionInfoDto, 'hasLowWordBalance' | 'exportedWordBalance'> &
      Partial<Pick<MtRateLimitingDto, 'shouldWarnUser'>> & { mtWordBalance?: number })
  | (Pick<MtRateLimitingDto, 'shouldWarnUser'> &
      Partial<Pick<SubscriptionInfoDto, 'hasLowWordBalance' | 'exportedWordBalance'>> & {
        mtWordBalance?: number;
      });

export const LowResourceTagWarning: React.FC<LowResourceTagWarningProps> = ({
  hasLowWordBalance,
  shouldWarnUser,
  exportedWordBalance,
  mtWordBalance
}: LowResourceTagWarningProps) => {
  const bothLowResource = hasLowWordBalance === true && shouldWarnUser === true;

  const { t: tLayout } = useTranslation('layout');

  const lowResourcesStates = {
    bothLowResource: {
      tag: tLayout('warningTagLowWordAndMt'),
      title: tLayout('warningTagLowWordAndMtTitle'),
      description: tLayout('warningTagLowWordAndMtDescription', {
        mtLeft: mtWordBalance,
        wordsLeft: exportedWordBalance
      }),
      cta: (
        <Button variant="solid" className="mt-6 w-full" onClick={() => Beacon('open')}>
          {tLayout('warningTagLowWordAndMtCtaText')}
        </Button>
      )
    },
    shouldWarnUser: {
      tag: tLayout('warningTagLowMt'),
      title: tLayout('warningTagLowMtTitle', { count: mtWordBalance }),
      description: tLayout('warningTagLowMtDescription'),
      cta: (
        <Button variant="solid" className="mt-6 w-full" onClick={() => Beacon('open')}>
          {tLayout('warningTagLowMtCtaText')}
        </Button>
      )
    },
    hasLowWordBalance: {
      tag: tLayout('warningTagLowWord'),
      title: tLayout('warningTagLowWordTitle', { count: exportedWordBalance }),
      description: tLayout('warningTagLowWordDescription'),
      cta: (
        <ButtonLink variant="solid" className="mt-6 w-full" href={`${APP_URL}/settings/plans`}>
          {tLayout('warningTagLowWordCtaText')}
        </ButtonLink>
      )
    }
  };

  const getLowResourcesStatus = () => {
    if (bothLowResource) return lowResourcesStates.bothLowResource;
    if (shouldWarnUser) return lowResourcesStates.shouldWarnUser;
    if (hasLowWordBalance) return lowResourcesStates.hasLowWordBalance;
  };

  const warningTagStatus = getLowResourcesStatus()!;

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Tag className="border-1 text-negative border border-states-negative-active bg-surface-error">
          <AccessibleIcon className="text-base text-states-negative-decorative" label={'c'} icon="ri-alert-line" />
          <Text className="text-sm text-states-negative-decorative">{warningTagStatus.tag}</Text>
        </Tag>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="shadow max-w-xs border border-gray-200 p-7 shadow-lg">
          <Popover.Arrow className="" />
          <Text weight={'bold'}>{warningTagStatus.title}</Text>
          <Text className="whitespace-pre-line" size="sm">
            {warningTagStatus.description}
          </Text>
          {warningTagStatus.cta}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
