import { filter as _filter } from 'lodash';
import { useCallback, useEffect } from 'react';

import { usePusherBindToEvent, usePusherContext, usePusherSubscribeToChannel } from '@/providers/PusherProvider/hooks';
import { languageApiUtil } from '@/redux/api';
import {
  addTeamMember,
  copySameTranslation,
  removeTeamMember,
  updateFirstEmptyTranslation,
  updateLanguageStatus,
  updateTranslationContent,
  updateTranslationListPage,
  updateTranslationsStatus
} from '@/redux/api/actions/apiThunks';
import { EThumbnailStatus } from '@/redux/api/constants';
import {
  bootstrapSegmentsChanged,
  documentCommentCreated,
  documentCommentDeleted,
  notificationCountChanged,
  notificationCountIncreased,
  paragraphCommentCreated,
  paragraphCommentDeleted,
  paragraphCommentsCountersChanged
} from '@/redux/api/thunks';
import type { ITeamMemberDto } from '@/redux/api/types';
import { getTeamMember } from '@/redux/api/utils';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { selectFocusedParagraphId } from '@/redux/slices/documentLanguageSlice/selectors';
import {
  openDocumentNotAvailableModal,
  openDocumentPreTranslatedModal,
  openDocumentTranslationLockedModal,
  openNewDocumentRevisionAvailableModal,
  openNewTranslationsImportedModal
} from '@/redux/slices/modalsSlice';
import {
  addMemberFromPosition,
  addPresenceMember,
  removeMemberFromPosition,
  removePresenceMember,
  syncPresenceMember
} from '@/redux/slices/presenceSlice';
import { setSocketId } from '@/redux/slices/pusherSlice';
import { addTeamMemberColor, removeTeamMemberColor } from '@/redux/slices/teamMembersSlice';

import type { IUseRealtimeProps } from './types';

// This hook could be split into multiple hooks
export const useRealtime = ({
  channelNames,
  currentAccountId,
  documentId,
  languageId,
  translationListQueryArg,
  teamMembers,
  activeMembers
}: IUseRealtimeProps) => {
  const dispatch = useAppDispatch();
  const focusedParagraphId = useAppSelector(selectFocusedParagraphId);

  const { pusher } = usePusherContext();
  const { channel: accountChannel } = usePusherSubscribeToChannel(channelNames?.account || null);
  const { channel: documentChannel } = usePusherSubscribeToChannel(channelNames?.document || null);
  const { channel: documentTranslatedChannel } = usePusherSubscribeToChannel(channelNames?.documentTranslated || null);
  const { channel: webEditorChannel } = usePusherSubscribeToChannel(channelNames?.webEditor || null);

  // Pusher handlers

  const handleConnected = useCallback(() => {
    if (pusher.connection && pusher.connection.socket_id) {
      dispatch(setSocketId(pusher.connection.socket_id));
    }
  }, [pusher, dispatch]);

  // Document translated handlers

  const handleDeleted = useCallback(() => {
    dispatch(openDocumentNotAvailableModal());
  }, [dispatch]);

  const handleUpdated = useCallback(
    ({ newDocumentId }) => {
      dispatch(openNewDocumentRevisionAvailableModal({ documentId: newDocumentId, languageId }));
    },
    [languageId, dispatch]
  );

  const handleNewPreviewStatus = useCallback(
    ({ newStatus }) => {
      if (newStatus === 'ERROR') {
        const thumbnailStatus = EThumbnailStatus.CANNOT_GENERATE;

        dispatch(
          updateTranslationListPage(translationListQueryArg, { pageNumber: 'ALL', pageFields: { thumbnailStatus } })
        );
      }
    },
    [translationListQueryArg, dispatch]
  );

  const handlePreviewReady = useCallback(
    ({ thumbnailUrl, pageNumber }) => {
      const thumbnailStatus = EThumbnailStatus.OK;

      dispatch(
        updateTranslationListPage(translationListQueryArg, {
          pageNumber,
          pageFields: { thumbnailUrl, thumbnailStatus }
        })
      );
    },
    [translationListQueryArg, dispatch]
  );

  const handleStatusUpdated = useCallback(
    ({ newStatus, accountName, isBlocked, segmentsDraftCount }) => {
      dispatch(updateLanguageStatus({ documentId, languageId }, { newStatus, segmentsDraftCount }));

      if (isBlocked) {
        dispatch(openDocumentTranslationLockedModal({ accountName }));
      }
    },
    [documentId, languageId, dispatch]
  );

  const handleTranslatorsAdded = useCallback(
    ({ accountId, fullName, avatarUrl }) => {
      dispatch(addTeamMember({ documentId, languageId }, { newTeamMember: { id: accountId, fullName, avatarUrl } }));
      dispatch(addTeamMemberColor(accountId));
    },
    [documentId, languageId, dispatch]
  );

  const handleTranslatorsRemoved = useCallback(
    ({ accountId }) => {
      dispatch(removeTeamMember({ documentId, languageId }, { teamMemberId: accountId }));
      dispatch(removeTeamMemberColor(accountId));

      if (accountId === currentAccountId) {
        dispatch(openDocumentNotAvailableModal());
      }
    },
    [currentAccountId, documentId, languageId, dispatch]
  );

  const handlePretranslated = useCallback(
    ({ accountName }) => {
      dispatch(openDocumentPreTranslatedModal({ accountName }));
    },
    [dispatch]
  );

  const handleSegmentImportNewStatus = useCallback(
    ({ importStatus, accountName }) => {
      if (importStatus === 'DONE') {
        dispatch(openNewTranslationsImportedModal({ accountName }));
      }
    },
    [dispatch]
  );

  // Account channel handlers

  const handleNewNotification = useCallback(() => {
    dispatch(notificationCountIncreased());
  }, [dispatch]);

  const handleDeletedNotification = useCallback(
    ({ unreadNotificationCount }) => {
      dispatch(notificationCountChanged(unreadNotificationCount));
    },
    [dispatch]
  );

  // Web Editor channel handlers

  const handleTranslationContentUpdated = useCallback(
    ({
      paragraphId,
      newContent,
      segmentsTranslatedCount,
      segmentsDraftCount,
      glossaryEntries,
      firstEmptyTranslation
    }) => {
      dispatch(updateTranslationContent(translationListQueryArg, paragraphId, { newContent, glossaryEntries }));
      dispatch(bootstrapSegmentsChanged({ documentId, languageId }, { segmentsTranslatedCount, segmentsDraftCount }));
      dispatch(languageApiUtil.invalidateTags(['paragraphComment']));

      // Update new empty translation cell
      dispatch(
        updateFirstEmptyTranslation({ documentId, languageId }, { firstEmptyTranslation: firstEmptyTranslation })
      );
    },
    [dispatch, documentId, languageId, translationListQueryArg]
  );

  const handleTranslationContentCopied = useCallback(
    ({ paragraphs, newContent, segmentsTranslatedCount, segmentsDraftCount, firstEmptyTranslation }) => {
      dispatch(
        copySameTranslation(translationListQueryArg, { copiedContent: newContent, updatedParagraphs: paragraphs })
      );
      dispatch(bootstrapSegmentsChanged({ documentId, languageId }, { segmentsTranslatedCount, segmentsDraftCount }));
      dispatch(languageApiUtil.invalidateTags(['paragraphComment']));

      // Update new empty translation cell
      dispatch(
        updateFirstEmptyTranslation({ documentId, languageId }, { firstEmptyTranslation: firstEmptyTranslation })
      );
    },
    [dispatch, documentId, languageId, translationListQueryArg]
  );

  const handleTranslationStatusUpdated = useCallback(
    ({ paragraphIds, newStatus, segmentsDraftCount }) => {
      dispatch(updateTranslationsStatus(translationListQueryArg, paragraphIds, newStatus));
      dispatch(bootstrapSegmentsChanged({ documentId, languageId }, { segmentsDraftCount }));
      dispatch(languageApiUtil.invalidateTags(['paragraphComment']));
    },
    [dispatch, documentId, languageId, translationListQueryArg]
  );

  const handleDocumentCommentCreated = useCallback(
    ({ accountId, content, documentTranslatedCommentId, createdAt }) => {
      const account = teamMembers ? getTeamMember(teamMembers, accountId) : null;
      if (!account) {
        return;
      }

      const comment = { id: documentTranslatedCommentId, createdAt, content, isRead: false, account };
      dispatch(documentCommentCreated({ documentId, languageId }, comment));
    },
    [dispatch, documentId, languageId, teamMembers]
  );
  const handleDocumentCommentDeleted = useCallback(
    ({ commentId }) => {
      dispatch(documentCommentDeleted({ documentId, languageId }, commentId));
    },
    [dispatch, documentId, languageId]
  );

  const handleParagraphCommentCreated = useCallback(
    ({
      accountId,
      paragraphId,
      paragraphCommentId,
      content,
      createdAt,
      totalCommentsCount,
      segmentsWithMessagesCount
    }) => {
      const account = teamMembers ? getTeamMember(teamMembers, accountId) : null;
      if (!account) {
        return;
      }

      const comment = { id: paragraphCommentId, createdAt, content, isRead: false, account };
      dispatch(paragraphCommentCreated({ documentId, languageId, paragraphId }, comment));
      dispatch(
        paragraphCommentsCountersChanged(
          { documentId, languageId },
          { paragraphId, totalCommentsCount: totalCommentsCount }
        )
      );
      dispatch(bootstrapSegmentsChanged({ documentId, languageId }, { segmentsWithMessagesCount }));
    },
    [dispatch, documentId, languageId, teamMembers]
  );

  const handleParagraphCommentDeleted = useCallback(
    ({ paragraphId, paragraphCommentId, totalCommentsCount, segmentsWithMessagesCount }) => {
      dispatch(paragraphCommentDeleted({ documentId, languageId, paragraphId }, paragraphCommentId));
      dispatch(
        paragraphCommentsCountersChanged(
          { documentId, languageId },
          { paragraphId, totalCommentsCount: totalCommentsCount }
        )
      );
      dispatch(bootstrapSegmentsChanged({ documentId, languageId }, { segmentsWithMessagesCount }));
    },
    [dispatch, documentId, languageId]
  );

  const handleGlossaryWarningsCountUpdated = useCallback(
    ({ segmentsWithGlossaryWarnings }) => {
      dispatch(
        bootstrapSegmentsChanged(
          { documentId, languageId },
          {
            segmentsWithGlossaryWarnings: segmentsWithGlossaryWarnings
          }
        )
      );
    },
    [dispatch, documentId, languageId]
  );

  const handleInputFocusOut = useCallback(
    ({ accountId, paragraphId }) => {
      const teamMember = getTeamMember(teamMembers || [], accountId);
      if (teamMember) {
        dispatch(removeMemberFromPosition({ teamMember: teamMember, paragraphId: paragraphId }));
      }
    },
    [dispatch, teamMembers]
  );
  const handleInputFocusIn = useCallback(
    ({ accountId, paragraphId }) => {
      const teamMember = getTeamMember(teamMembers || [], accountId);
      if (teamMember) {
        dispatch(addMemberFromPosition({ teamMember: teamMember, paragraphId: paragraphId }));
      }
    },
    [dispatch, teamMembers]
  );

  // Presence
  const handleSubscriptionSucceeded = useCallback(
    (data) => {
      const { members } = data;

      // Sync list of presences
      if (teamMembers) {
        const activeTeamMembers: ITeamMemberDto[] = _filter(
          teamMembers,
          (teamMember) => teamMember.id in members && teamMember.id !== currentAccountId
        );
        if (activeTeamMembers) {
          dispatch(syncPresenceMember(activeTeamMembers));
        }
      }

      // Sadly this event does not contain custom data in the payload that we can set from the client
      // ... so we need to use this artisanal way. We send a "ping" and expect a "pong" from active member
      // with their cursor location
      webEditorChannel?.trigger('client-ping', {});
    },
    [currentAccountId, teamMembers, webEditorChannel, dispatch]
  );
  const handleMemberAdded = useCallback(
    ({ id }) => {
      if (teamMembers) {
        const newTeamMember = getTeamMember(teamMembers, id);
        if (newTeamMember) {
          dispatch(addPresenceMember(newTeamMember));
        }
      }
    },
    [dispatch, teamMembers]
  );
  const handleMemberRemoved = useCallback(
    ({ id }) => {
      dispatch(removePresenceMember(id));
    },
    [dispatch]
  );

  // When a new client joins, it will send a 'ping' event to know from other users
  // where their cursor is
  const handlePing = useCallback(() => {
    webEditorChannel?.trigger('client-pong', {
      paragraphId: focusedParagraphId,
      accountId: currentAccountId
    });
  }, [webEditorChannel, currentAccountId, focusedParagraphId]);

  // Selection timeout. Check every few seconds if we should free up expired team member selections
  useEffect(() => {
    const timer = setInterval(() => {
      if (activeMembers) {
        Object.values(activeMembers).forEach((m) => {
          if (m.paragraphId && m.expireAt && new Date(m.expireAt) < new Date()) {
            handleInputFocusOut({ accountId: m.teamMember.id, paragraphId: m.paragraphId });
          }
        });
      }
    }, 10 * 1000);
    return () => clearTimeout(timer);
  }, [activeMembers, handleInputFocusOut]);

  // Pusher connected event (socket ID)
  usePusherBindToEvent(pusher.connection, 'connected', handleConnected);

  // Try getting Pusher socket ID at startup (in case above event is triggered before the bind call)
  useEffect(() => {
    handleConnected();
  }, [handleConnected]);

  // Notification events
  usePusherBindToEvent(accountChannel, 'notification:new', handleNewNotification);
  usePusherBindToEvent(accountChannel, 'notification:deleted', handleDeletedNotification);

  // Document channel events
  usePusherBindToEvent(documentChannel, 'deleted', handleDeleted);
  usePusherBindToEvent(documentChannel, 'updated', handleUpdated);
  usePusherBindToEvent(documentChannel, 'new_preview_status', handleNewPreviewStatus);
  usePusherBindToEvent(documentChannel, 'preview:ready', handlePreviewReady);

  // Document translated channel events
  usePusherBindToEvent(documentTranslatedChannel, 'status_updated', handleStatusUpdated);
  usePusherBindToEvent(documentTranslatedChannel, 'deleted', handleDeleted);
  usePusherBindToEvent(documentTranslatedChannel, 'translators:added', handleTranslatorsAdded);
  usePusherBindToEvent(documentTranslatedChannel, 'translators:removed', handleTranslatorsRemoved);
  usePusherBindToEvent(documentTranslatedChannel, 'pretranslated', handlePretranslated);
  usePusherBindToEvent(documentTranslatedChannel, 'segment_import:new_status', handleSegmentImportNewStatus);

  // Web editor channel
  usePusherBindToEvent(webEditorChannel, 'paragraph:translation:content_updated', handleTranslationContentUpdated);
  usePusherBindToEvent(webEditorChannel, 'paragraph:translation:copied', handleTranslationContentCopied);
  usePusherBindToEvent(webEditorChannel, 'paragraph:translation:status_updated', handleTranslationStatusUpdated);
  usePusherBindToEvent(webEditorChannel, 'comment:created', handleDocumentCommentCreated);
  usePusherBindToEvent(webEditorChannel, 'comment:deleted', handleDocumentCommentDeleted);
  usePusherBindToEvent(webEditorChannel, 'paragraph:comment:created', handleParagraphCommentCreated);
  usePusherBindToEvent(webEditorChannel, 'paragraph:comment:deleted', handleParagraphCommentDeleted);
  usePusherBindToEvent(webEditorChannel, 'counter:glossary_warnings_count_updated', handleGlossaryWarningsCountUpdated);

  // Web editor: presence events
  usePusherBindToEvent(webEditorChannel, 'pusher:subscription_succeeded', handleSubscriptionSucceeded);
  usePusherBindToEvent(webEditorChannel, 'pusher:member_added', handleMemberAdded);
  usePusherBindToEvent(webEditorChannel, 'pusher:member_removed', handleMemberRemoved);

  // Web editor: client events
  usePusherBindToEvent(webEditorChannel, 'client-ping', handlePing);
  usePusherBindToEvent(webEditorChannel, 'client-pong', handleInputFocusIn);
  usePusherBindToEvent(webEditorChannel, 'client-paragraph:focusout', handleInputFocusOut);
  usePusherBindToEvent(webEditorChannel, 'client-paragraph:focusin', handleInputFocusIn);

  // Client events
  const notifySelectedParagraphId = useCallback(
    (newParagraphId: number) => {
      webEditorChannel?.trigger('client-paragraph:focusin', {
        paragraphId: newParagraphId,
        accountId: currentAccountId
      });
    },
    [webEditorChannel, currentAccountId]
  );

  const notifyUnselectedParagraphId = useCallback(
    (oldParagraphId: number) => {
      webEditorChannel?.trigger('client-paragraph:focusout', {
        paragraphId: oldParagraphId,
        accountId: currentAccountId
      });
    },
    [webEditorChannel, currentAccountId]
  );

  return { notifySelectedParagraphId, notifyUnselectedParagraphId };
};
