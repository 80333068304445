import { Trans } from 'react-i18next';

import DataTable from '@/components/v2/DataTable';
import { DocumentTranslationDto } from '@/redux/api/types';
import { Progress } from '@/components/v1/Progress';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';

export const ProgressCell = () => {
  const { cell } = DataTable.useCellContext<DocumentTranslationDto, DocumentTranslationDto['completedPercentage']>(
    'LanguageCell'
  );
  const { row } = DataTable.useRowContext<DocumentTranslationDto>('LanguageCell');
  const { translatedSegments, draftSegments, untranslatedSegments } = row.original.translationProgress;

  const progress = cell.getValue();

  const confirmed = translatedSegments - draftSegments;
  const total = translatedSegments + untranslatedSegments;
  const successPercent = Math.floor((100 * confirmed) / total) || 0;

  return (
    <Tooltip.Root>
      <Tooltip.Trigger className="w-full min-w-[96px] cursor-default">
        <Progress percent={progress} successPercent={successPercent} />
      </Tooltip.Trigger>
      <Tooltip.Content className="max-w-xxs" side="bottom">
        <Text size="sm" weight="medium" className="text-typography-inverse-secondary">
          <Trans
            i18nKey="document:confirmedPopover"
            values={{
              value: confirmed
            }}
            components={{ 1: <span className="text-typography-inverse-primary"></span> }}
          />
        </Text>
        <Text size="sm" weight="medium" className="text-typography-inverse-secondary">
          <Trans
            i18nKey="document:draft"
            values={{
              value: draftSegments
            }}
            components={{ 1: <span className="text-typography-inverse-primary"></span> }}
          />
        </Text>
        <Tooltip.Arrow />
      </Tooltip.Content>
    </Tooltip.Root>
  );
};
