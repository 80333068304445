import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { Navbar } from '@/components/v2/Navbar';
import { StepperGroup } from '@/components/v2/StepperGroup';
import { Text } from '@/components/v2/Text';
import { OnboardingLayoutProps } from '@/routes/Onboarding/types';
import { onboardingSteps } from '@/routes/Onboarding/Welcome/constants';

export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { t: tLayout } = useTranslation('layout');
  const { t } = useTranslation('uploadDocuments');

  const index = onboardingSteps.map((s) => s.path).indexOf(location.pathname);
  if (index === -1) {
    return null;
  }
  const key = onboardingSteps[index].label as unknown as TemplateStringsArray;
  const label = key !== undefined ? t(key) : '';

  return (
    <div className="flex min-h-screen flex-col text-sm text-gray-800">
      <Helmet>
        <title>{tLayout('welcomeToRedokun')}</title>
      </Helmet>
      <Navbar sticky>
        <div className="ml-auto flex flex-row items-center gap-10">
          <Text>{label}</Text>
          <StepperGroup
            current={index}
            items={onboardingSteps}
            orientation="horizontal"
            className="w-40"
            hideSeparator
            hideLabel
          />
        </div>
      </Navbar>

      <div className="flex-1 bg-gray-50 px-8 pb-28">
        <div className="mx-auto w-full max-w-6xl">{children}</div>
      </div>
    </div>
  );
};
