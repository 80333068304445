import { isEmpty } from 'lodash';

import { FolderHierarchyDto } from '@/redux/api/types';

export interface FolderNode {
  id: number;
  name: string;
  active?: boolean;
}

export function findPathToFolderId(data: FolderHierarchyDto[], targetId: number): FolderNode[] {
  function findPath(node: FolderHierarchyDto, id: number, path: FolderNode[]): FolderNode[] {
    if (node.id === id) {
      return [...path, { id: node.id, name: node.name, active: true }];
    }

    if (Array.isArray(node.children)) {
      for (const child of node.children) {
        const result = findPath(child, id, [...path, { id: node.id, name: node.name }]);
        if (result.length > 0) {
          return result;
        }
      }
    }

    return [];
  }

  for (const node of data) {
    const result = findPath(node, targetId, []);
    if (result.length > 0) {
      return result;
    }
  }

  return [];
}

export const getPathToFolder = (currentFolderId?: number, children?: FolderNode[]) => {
  const nestedFolderStructure = children ?? [];
  if (!currentFolderId) {
    return [];
  }
  // @ts-expect-error: TS 5 migration error
  return findPathToFolderId(nestedFolderStructure, currentFolderId);
};

export const getCurrentFolder = (pathToFolder: FolderNode[], currentFolderId?: number) => {
  if (isEmpty(pathToFolder)) {
    return undefined;
  }
  return pathToFolder.find((folder) => folder.id === currentFolderId);
};

/**
 * Searches for a folder name by ID in a nested folder structure.
 *
 * @param {FolderHierarchyDto} folder - The root folder object to search through.
 * @param {number} id - The ID of the folder to find.
 * @returns {string|null} The name of the folder if found, otherwise null.
 */
export function findFolderNameById(folder: FolderHierarchyDto, id: number): string | null {
  if (folder.id === id) {
    return folder.name;
  }
  if (folder.children) {
    for (const child of folder.children) {
      const result = findFolderNameById(child, id);
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
}

/**
 * Searches for the parent folder ID by a given folder ID in a nested folder structure.
 *
 * @param {FolderHierarchyDto} folder - The root folder object to search through.
 * @param {number} targetId - The ID of the folder to find.
 * @param {number|null} parentId - The ID of the parent folder, initially null.
 * @returns {number|null} The ID of the parent folder if found, otherwise null.
 */
export function findParentFolderIdById(
  folder: FolderHierarchyDto,
  targetId: number,
  parentId: number | null = null
): number | null {
  if (folder.id === targetId) {
    return parentId;
  }
  if (folder.children) {
    for (const child of folder.children) {
      const result = findParentFolderIdById(child, targetId, folder.id);
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
}

/**
 * Collects all folder IDs and their children IDs starting from a specific folder ID.
 *
 * @param {FolderHierarchyDto} folder - The root folder object to search through.
 * @param {number} folderId - The ID of the folder to start collecting IDs from.
 * @returns {string[]} The list of folder IDs including the specified folder and its children.
 */
export function collectFolderAndChildrenIds(folder: FolderHierarchyDto, folderId: number): string[] {
  const targetFolder = findFolderById(folder, folderId);
  if (!targetFolder) return [];

  return collectIds(targetFolder);
}

/**
 * Helper function to collect all IDs from a given folder and its children.
 *
 * @param {FolderHierarchyDto} folder - The folder to collect IDs from.
 * @returns {string[]} The list of collected IDs.
 */
function collectIds(folder: FolderHierarchyDto): string[] {
  let ids = [String(folder.id)];
  if (folder.children) {
    for (const child of folder.children) {
      ids = ids.concat(collectIds(child));
    }
  }
  return ids;
}

/**
 * Searches for a folder by ID in a nested folder structure.
 *
 * @param {FolderHierarchyDto} folder - The root folder object to search through.
 * @param {number} id - The ID of the folder to find.
 * @returns {FolderHierarchyDto | null} The found folder object, or null if not found.
 */
function findFolderById(folder: FolderHierarchyDto, id: number): FolderHierarchyDto | null {
  if (folder.id === id) return folder;
  if (folder.children) {
    for (const child of folder.children) {
      const result = findFolderById(child, id);
      if (result) return result;
    }
  }
  return null;
}
