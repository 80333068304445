import { useTranslation } from 'react-i18next';

import DataTable from '@/components/v2/DataTable';
import { TranslationStatus } from '@/redux/api/constants';
import { DocumentTranslationDto } from '@/redux/api/types';
import { Tag } from '@/components/v2/Tag';

export const StatusCell = () => {
  const { cell } = DataTable.useCellContext<DocumentTranslationDto, DocumentTranslationDto['status']>('StatusCell');
  const value = cell.getValue();
  const { t } = useTranslation('document');

  const status = {
    // confirmed
    [TranslationStatus.CONFIRMED]: 'positive',
    // in progress
    [TranslationStatus.CREATED]: 'primary',
    // downloaded
    [TranslationStatus.GENERATED]: 'neutral',
    // pending
    [TranslationStatus.GENERATING]: 'warning'
  } as const;

  const label = {
    // confirmed
    [TranslationStatus.CONFIRMED]: t('confirmed'),
    // in progress
    [TranslationStatus.CREATED]: t('inProgress'),
    // downloaded
    [TranslationStatus.GENERATED]: t('downloaded'),
    // pending
    [TranslationStatus.GENERATING]: t('pending')
  };

  return <Tag color={status[value]}>{label[value]}</Tag>;
};
