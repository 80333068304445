import { createSelector } from '@reduxjs/toolkit';

import type { TAppState } from '../../store/types';

const selectConnectionState = (state: TAppState) => state.connection;

export const selectConnection = createSelector(selectConnectionState, (connection) => connection);

export const selectOnline = createSelector(selectConnectionState, (connection) => connection.online);

export const selectOffline = createSelector(selectConnectionState, (connection) => !connection.online);
