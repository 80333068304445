import { format } from '@/utils/date';

import { FIVE_MINUTES, redokunApi } from './redokunApi';
import {
  ChangeDocumentTranslatorsQueryArgs,
  ConfirmSegmentsImportPayload,
  ConfirmSegmentsImportResponse,
  CreateDocumentFromStashDto,
  CreateDocumentFromStashQueryArgs,
  DeleteLanguageTranslationQueryArg,
  DocumentCreateFromSampleQueryArg,
  DocumentCreateFromSampleResponseDto,
  DocumentDetailDto,
  DocumentPreTranslateQueryArg,
  DocumentRevisionRollbackQueryArgs,
  DocumentUploadQueryArgs,
  DocumentUploadResponseDto,
  GenerateDocumentTranslationQueryArg,
  IDocumentRevisionRollbackResponseDto,
  LaunchPreTranslationResponseDto,
  LaunchTranslationGenerationResponseDto,
  ListImportSegmentsResponseDto,
  OkResponseDto,
  StashSegmentsImportPayload,
  StashSegmentsImportResponse,
  TGetBatchIdSegmentsPayload,
  TGetExportDocumentSegmentsPayload,
  TImportDocumentSegmentsPayload,
  TImportDocumentSegmentsResponse,
  UpdateDocumentPropertiesQueryArgs,
  UploadDocumentRevisionQueryArgs,
  UploadDocumentRevisionResponse
} from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const documentApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    createDocumentFromSample: builder.mutation<DocumentCreateFromSampleResponseDto, DocumentCreateFromSampleQueryArg>({
      query: (data) => ({ url: 'document/from-sample', method: 'POST', body: data })
    }),

    /**
     * Load all data necessary to show a single document page
     */
    getDocumentDetail: builder.query<DocumentDetailDto, number>({
      query: (documentId) => `document/${documentId}`,
      keepUnusedDataFor: 60,
      providesTags: ['documentDetail']
    }),

    /**
     * Update properties of a document
     */
    updateDocumentProperties: builder.mutation<OkResponseDto, UpdateDocumentPropertiesQueryArgs>({
      query: ({ documentId, documentOwnerId, parentFolderId }) => ({
        url: `document/${documentId}`,
        method: 'PATCH',
        body: {
          documentOwnerId,
          parentFolderId
        }
      }),
      invalidatesTags: ['documentDetail', 'documentList']
    }),

    /**
     * Delete a document
     */
    deleteDocument: builder.mutation<OkResponseDto, number>({
      query: (documentId) => ({ url: `document/${documentId}`, method: 'DELETE' }),
      invalidatesTags: ['documentList']
    }),

    /**
     * Upload a document
     * this endpoint expects the encoding "multipart/form-data" instead of "application/json"
     */
    uploadFiles: builder.mutation<DocumentUploadResponseDto, DocumentUploadQueryArgs>({
      query: ({ files, folderId }) => {
        const bodyFormData = new FormData();

        files.forEach((file) => {
          bodyFormData.append(`files[]`, file);
          if (folderId) {
            bodyFormData.append('folderId', String(folderId));
          }
        });

        return {
          url: 'document/upload',
          method: 'POST',
          // timout for large files
          // https://github.com/redokun/redokun-frontend/issues/1054
          timeout: FIVE_MINUTES,
          body: bodyFormData
        };
      }
    }),

    /**
     * Create document from stash
     * Create a document from a previously uploaded file
     */
    createDocumentFromStash: builder.mutation<CreateDocumentFromStashDto, CreateDocumentFromStashQueryArgs>({
      query: ({ documentsStashed, enableHtmlSubFilter = false, importNumericData = false }) => {
        return {
          url: 'document/from-stash',
          method: 'POST',
          body: { documentsStashed, enableHtmlSubFilter, importNumericData }
        };
      }
    }),

    /**
     * Launch pre-translation on a document
     */
    preTranslate: builder.mutation<LaunchPreTranslationResponseDto, DocumentPreTranslateQueryArg>({
      query: ({ documentId, body }) => ({
        url: `document/${documentId}/pre-translate`,
        method: 'POST',
        body
      })
    }),

    /**
     * Generate a translation for the document, given the selected language
     */
    generateDocumentTranslation: builder.mutation<
      LaunchTranslationGenerationResponseDto,
      GenerateDocumentTranslationQueryArg
    >({
      query: ({ documentId, languageId }) => {
        return {
          url: `document/${documentId}/translation/${languageId}/generate`,
          method: 'POST',
          body: {}
        };
      },
      invalidatesTags: ['documentDetail']
    }),

    /**
     * Delete a language translation for a document
     */
    deleteLanguageTranslation: builder.mutation<OkResponseDto, DeleteLanguageTranslationQueryArg>({
      query: ({ documentId, languageId }) => ({
        url: `document/${documentId}/translation/${languageId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['documentDetail']
    }),

    /**
     * Upload document revision
     */
    uploadDocumentRevision: builder.mutation<UploadDocumentRevisionResponse, UploadDocumentRevisionQueryArgs>({
      query: ({ documentId, stashedFileId, skipNotification }) => {
        return {
          url: `document/${documentId}/revision`,
          method: 'POST',
          body: {
            stashedFileId,
            skipNotification
          }
        };
      }
    }),

    /**
     * Rollback document revision
     */
    documentRevisionRollback: builder.mutation<IDocumentRevisionRollbackResponseDto, DocumentRevisionRollbackQueryArgs>(
      {
        query: ({ documentId }) => {
          return {
            url: `document/${documentId}/revision/rollback`,
            method: 'POST'
          };
        }
      }
    ),
    /**
     * Updates the list of accounts assigned as translators for a specific document translation
     */
    changeDocumentTranslators: builder.mutation<OkResponseDto, ChangeDocumentTranslatorsQueryArgs>({
      query: ({ documentId, languageId, translatorAccountIds }) => ({
        url: `document/${documentId}/translation/${languageId}/translator`,
        method: 'PATCH',
        body: {
          translatorAccountIds
        }
      }),
      invalidatesTags: ['documentDetail']
    }),

    /**
     * Export segments
     */
    getExportDocumentSegments: builder.mutation<unknown, TGetExportDocumentSegmentsPayload>({
      query: ({ documentId, languageId, format, excludeTranslated }) => {
        return {
          url: `document/${documentId}/translation/${languageId}/export-segments`,
          method: 'POST',
          body: { format, excludeTranslated },
          cache: 'no-cache',
          // timout for large files
          // https://github.com/redokun/redokun-frontend/issues/1054
          timeout: FIVE_MINUTES,
          responseHandler: (response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.blob();
          }
        };
      },
      transformResponse: (response: Blob | void, meta) => {
        if (meta && response instanceof Blob) {
          const { response: metaResponse } = meta;
          if (metaResponse) {
            const contentDispositions = metaResponse.headers.get('content-disposition');
            const contentType = metaResponse.headers.get('Content-Type');

            if (contentDispositions && contentType) {
              let filename = format(new Date(), 'YYYY-MM-DD_HH-mm');

              // NOTE: This pivot value has changed on the server side without being noticed.
              // Now we do additional checks and download the file even if the string changes again.
              const pivot = 'filename*=';
              const splitContentDisposition = contentDispositions.split(pivot);
              if (splitContentDisposition.length) {
                const secondHalf = splitContentDisposition[1];
                filename = secondHalf.replace(/['"]/g, '').replace('UTF-8', '');
              }

              const file = new File([response], filename, { type: contentType });
              const url = URL.createObjectURL(file);

              const link = document.createElement('a');
              link.href = url;
              link.download = filename;
              document.body.appendChild(link);

              link.dispatchEvent(
                new MouseEvent('click', {
                  bubbles: true,
                  cancelable: true,
                  view: window
                })
              );

              document.body.removeChild(link);
            }
          }
        }

        return {};
      }
    }),

    /**
     * Import segments
     */
    importDocumentSegments: builder.mutation<TImportDocumentSegmentsResponse, TImportDocumentSegmentsPayload>({
      query: ({ documentId, languageId, file }) => {
        const bodyFormData = new FormData();
        bodyFormData.append(`file`, file);
        return {
          url: `document/${documentId}/translation/${languageId}/import-segments`,
          method: 'POST',
          body: bodyFormData,
          cache: 'no-cache'
        };
      }
    }),

    /**
     * Stash segments import
     * Use useFileUploadMutation to get the stashedFileId.
     * It returns batchId, to be used in getBatchIdSegments and confirmSegmentsImport.
     */
    stashSegmentsImport: builder.mutation<StashSegmentsImportResponse, StashSegmentsImportPayload>({
      query: ({ documentId, languageId, stashedFileId }) => {
        return {
          url: `document/${documentId}/translation/${languageId}/import-segments`,
          method: 'POST',
          body: {
            stashedFileId
          }
        };
      }
    }),

    /**
     * Get batchId segments
     */
    getBatchIdSegments: builder.query<ListImportSegmentsResponseDto, TGetBatchIdSegmentsPayload>({
      query: ({ documentId, languageId, batchId }) =>
        `document/${documentId}/translation/${languageId}/import-segments/${batchId}`
    }),

    /**
     * Confirm segments import
     */
    confirmSegmentsImport: builder.mutation<ConfirmSegmentsImportResponse, ConfirmSegmentsImportPayload>({
      query: ({ documentId, languageId, batchId }) => {
        return {
          url: `document/${documentId}/translation/${languageId}/import-segments/${batchId}`,
          method: 'POST'
        };
      }
    })
  })
});

export const {
  useCreateDocumentFromSampleMutation,
  useDeleteDocumentMutation,
  useGetDocumentDetailQuery,
  useLazyGetDocumentDetailQuery,
  usePreTranslateMutation,
  useUploadFilesMutation,
  useCreateDocumentFromStashMutation,
  useGenerateDocumentTranslationMutation,
  useDeleteLanguageTranslationMutation,
  useUploadDocumentRevisionMutation,
  useDocumentRevisionRollbackMutation,
  useGetExportDocumentSegmentsMutation,
  useChangeDocumentTranslatorsMutation,
  useImportDocumentSegmentsMutation,
  useGetBatchIdSegmentsQuery,
  useConfirmSegmentsImportMutation,
  useUpdateDocumentPropertiesMutation,
  useStashSegmentsImportMutation
} = documentApi;

export const documentApiUtil = {
  ...documentApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(documentApi.util.updateQueryData)
};
