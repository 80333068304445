import { useTranslation } from 'react-i18next';

import AppLayout from '@/components/layouts/AppLayout';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Navbar } from '@/components/v2/Navbar';
import { Text } from '@/components/v2/Text';
import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Button } from '@/components/v2/Button';

export const LayoutSkeleton = () => {
  const { t: tLayout } = useTranslation('layout');

  return (
    <AppLayout.Root title={tLayout('createNewGlossary')}>
      <Navbar sticky>
        <ButtonLink href={`/glossaries`} variant="surface" color="secondary" className="ml-auto" disabled>
          {tLayout('close')}
        </ButtonLink>
      </Navbar>

      <div className="w-full border-b border-gray-100 bg-white">
        <div className="mx-auto max-w-8xl px-7 py-5">
          <Skeleton width="20%">
            <Text weight="semibold" size="md" color="primary">
              &nbsp;
            </Text>
          </Skeleton>
        </div>
      </div>
      <AppLayout.Content>
        <div className="flex flex-col gap-7">
          <Surface className="flex flex-col gap-8 px-7 py-8">
            <Skeleton width="20%">
              <Text size="lg" weight="bold" color="primary">
                &nbsp;
              </Text>
            </Skeleton>

            <div className="flex w-full flex-col gap-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <Skeleton width="25%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
              <Skeleton width="100%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
            </div>

            <div className="flex w-full flex-col gap-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <Skeleton width="25%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
              <Skeleton width="100%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
            </div>

            <div className="flex w-full flex-col gap-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <Skeleton width="25%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
              <Skeleton width="100%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
            </div>

            <div>
              <Skeleton width="30%">
                <Button variant="text" color="primary" asChild>
                  &nbsp;
                </Button>
              </Skeleton>
            </div>
          </Surface>
          <Skeleton width="30%" className="self-end">
            <Button variant="solid" color="primary">
              &nbsp;
            </Button>
          </Skeleton>
        </div>
      </AppLayout.Content>
    </AppLayout.Root>
  );
};
