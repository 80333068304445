import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { TRIAL_WORDCOUNT_LIMIT } from '@/redux/api/constants';
import { SourceDocumentDto, SourceDocumentDtoStatus } from '@/redux/api/types';
import { classNames } from '@/utils/classNames';

import { DeleteDocumentModal } from './DeleteDocumentModal';
import { DocumentSettingsModal } from './DocumentSettingsModal';

enum ModalKey {
  SETTINGS = 'SETTINGS',
  DELETE = 'DELETE'
}

export const ManageDocumentDropdownMenu = (props: ManageDocumentDropdownMenuProps) => {
  const { documentId, sourceDocument } = props;

  const { t: tDocument } = useTranslation('document');
  const history = useHistory();
  const { currentAccount } = useCurrentAccount();

  const isTrial = currentAccount?.subscriptionInfo.isTrial;
  const isWordCountToobig = sourceDocument.wordCount > TRIAL_WORDCOUNT_LIMIT;

  const canOperateOnDocument = !(isTrial && isWordCountToobig);
  // if document is uploaded but not indexed disable document actions
  // the only possible thing that can be done is deleting the document
  // https://github.com/redokun/redokun-frontend/issues/804
  const documentNotReady = sourceDocument.status !== SourceDocumentDtoStatus.INDEXED;
  // in the future will be other checks (ex. if subscription is expired)
  const isActionDisabled = documentNotReady;

  // https://github.com/radix-ui/primitives/issues/1836
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState<ModalKey | null>(null);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  // https://github.com/radix-ui/primitives/issues/1836
  const handleModalItemSelect = (key: ModalKey) => (event: Event) => {
    event.preventDefault();
    setMenuOpen(false);
    setModalOpen(key);
  };

  // https://github.com/radix-ui/primitives/issues/1836
  const modalProps = (key: ModalKey) => ({
    open: modalOpen === key,
    onOpenChange: (open: boolean) => {
      if (open) {
        setMenuOpen(false);
        setModalOpen(key);
      } else {
        setModalOpen(null);
        menuTriggerRef.current?.focus();
      }
    }
  });

  const handleDelete = () => {
    history.push('/documents');
  };

  const handleConfirmSettings = () => {
    setModalOpen(null);
    menuTriggerRef.current?.focus();
  };

  const disabledClass = classNames({ 'pointer-events-none opacity-25': isActionDisabled });

  return (
    <>
      <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
          <Button variant="surface" color="secondary" ref={menuTriggerRef}>
            {tDocument('manageDocument')}
            <AccessibleIcon label={tDocument('manageDocumentIcon')} icon="ri-arrow-down-s-line" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content align="end">
          <Show when={canOperateOnDocument}>
            <RequireSubscription>
              <DropdownMenu.Item asChild>
                <a href={`/documents/update/${documentId}`} rel="noopener noreferrer" className={disabledClass}>
                  <AccessibleIcon icon="ri-file-upload-line" label={tDocument('uploadNewRevisionIcon')} />
                  {tDocument('uploadNewRevision')}
                </a>
              </DropdownMenu.Item>
            </RequireSubscription>

            <DropdownMenu.Item asChild>
              <a
                href={`${APP_URL}/documents/download/${documentId}`}
                rel="noopener noreferrer"
                className={disabledClass}
                download
              >
                <AccessibleIcon icon="ri-file-download-line" label={tDocument('downloadOriginalDocumentIcon')} />
                {tDocument('downloadOriginalDocument')}
              </a>
            </DropdownMenu.Item>

            <RequireSubscription>
              <DropdownMenu.Item onSelect={handleModalItemSelect(ModalKey.SETTINGS)} className={disabledClass}>
                <AccessibleIcon icon="ri-settings-5-line" label={tDocument('openSettingsIcon')} />
                {tDocument('openSettings')}
              </DropdownMenu.Item>
            </RequireSubscription>
          </Show>

          <DropdownMenu.Item color="negative" onSelect={handleModalItemSelect(ModalKey.DELETE)}>
            <AccessibleIcon icon="ri-delete-bin-6-line" label={tDocument('deleteThisDocumentIcon')} />
            {tDocument('deleteThisDocument')}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      <DocumentSettingsModal
        documentId={documentId}
        onConfirm={handleConfirmSettings}
        {...modalProps(ModalKey.SETTINGS)}
      />
      <DeleteDocumentModal documentId={documentId} onDelete={handleDelete} {...modalProps(ModalKey.DELETE)} />
    </>
  );
};

export interface ManageDocumentDropdownMenuProps {
  documentId: number;
  sourceDocument: SourceDocumentDto;
}
