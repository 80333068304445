import { useTranslation } from 'react-i18next';

import { FlagIcon } from '@/components/v2/FlagIcon';
import { Text } from '@/components/v2/Text';
import { useGetGlossaryByIdQuery } from '@/redux/api/glossaryApi';
import { assert } from '@/utils/assert';
import { Show } from '@/components/v2/Show';

interface LanguageContentHeaderCellProps {
  glossaryId: number;
  language: 'source' | 'target';
}

export const LanguageContentHeaderCell = ({ glossaryId, language }: LanguageContentHeaderCellProps) => {
  const { t: tGlossary } = useTranslation('glossary');
  const { data, isLoading, isUninitialized } = useGetGlossaryByIdQuery({ id: glossaryId });

  if (isLoading || isUninitialized) {
    return null;
  }

  assert(data, 'Glossary data not found');

  const { sourceLanguage, targetLanguage } = data.glossary;

  return (
    <Show
      when={language === 'source'}
      fallback={
        <div className="flex flex-row items-center gap-4">
          <FlagIcon label={targetLanguage.name} code={targetLanguage.flagCode || 'xx'} size="md" />
          <Text size="md" weight="medium" color="primary">
            {tGlossary('languageContent', { value: targetLanguage.name })}
          </Text>
        </div>
      }
    >
      <div className="flex flex-row items-center gap-4">
        <FlagIcon label={sourceLanguage.name} code={sourceLanguage.flagCode || 'xx'} size="md" />
        <Text size="md" weight="medium" color="primary">
          {tGlossary('languageContent', { value: sourceLanguage.name })}
        </Text>
      </div>
    </Show>
  );
};
