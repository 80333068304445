import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './DropdownMenu';

const DropdownObject = {
  Root: DropdownMenu,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  Trigger: DropdownMenuTrigger
};

export type {
  DropdownMenuContentProps,
  DropdownMenuItemProps,
  DropdownMenuProps,
  DropdownMenuTriggerProps
} from './types';
export { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger };

export default DropdownObject;
