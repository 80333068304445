import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '@/components/v1/Button';
import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import { APP_URL } from '@/config/urls';
import type { ISessionExpiredModalProps } from '@/redux/slices/modalsSlice/types/props';

export const SessionExpiredModal: FunctionComponent<TJumboModalProps & { props: ISessionExpiredModalProps }> = ({
  ...modalProps
}) => {
  const { t } = useTranslation('modals');

  return (
    <Modal.Jumbo {...modalProps}>
      {{
        header: t('yourSessionHasExpired'),
        content: t('thisErrorOccursWhenYouHaventLoggedInForLongTime'),
        footer: (
          <ButtonLink href={`${APP_URL}/login`} rel="noreferrer">
            {t('goToLogin')}
          </ButtonLink>
        )
      }}
    </Modal.Jumbo>
  );
};
