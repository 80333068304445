import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useDocumentListParams = () => {
  const { folderId } = useParams<{ folderId?: string }>();
  const parsedParams = useMemo(() => ({ folderId: !isNil(folderId) ? parseInt(folderId) : undefined }), [folderId]);
  return parsedParams;
};

export { useDocumentListParams };
