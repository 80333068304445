import type { ElementType, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v1/Box';
import { For } from '@/components/v1/For';
import { PlaceholderV1 } from '@/components/v1/Placeholder';
import { Show } from '@/components/v1/Show';
import { Tooltip } from '@/components/v1/Tooltip';
import type { IGlossaryEntry, LanguageDto } from '@/redux/api/types';
import { classNames } from '@/utils/classNames';

import { DEFAULT_ELEMENT } from './constants';
import type { DefaultElementType, TNode, TTranslationContentProps } from './types';
import { parseContent } from './utils';

export const TranslationContent = <E extends ElementType = DefaultElementType>(props: TTranslationContentProps<E>) => {
  const { content, glossaryEntries, searchWord, targetLanguage, ...boxProps } = props;

  const nodes = useMemo(
    () => parseContent({ content, glossaryEntries, searchWord, targetLanguage }),
    [glossaryEntries, searchWord, content, targetLanguage]
  );

  return (
    // @ts-expect-error: TS 5 migration error
    <Box as={DEFAULT_ELEMENT} {...boxProps}>
      <Nodes value={nodes} />
    </Box>
  );
};

const Nodes = ({ value }: { value: TNode[] }) => (
  <For each={value}>
    {(node, nodeIndex) => {
      const key = `${node.type}_${nodeIndex}`;

      if (node.type === 'TEXT') {
        return (
          <span
            key={key}
            className={classNames('whitespace-pre-wrap', {
              'bg-yellow-100': node.highlight
            })}
          >
            {node.text}
          </span>
        );
      }

      if (node.type === 'PLACEHOLDER') {
        return <PlaceholderV1 key={key} value={node.placeholder} className="mx-0.5" />;
      }

      if (node.type === 'GLOSSARY_ENTRY') {
        return (
          <GlossaryEntry key={key} glossaryEntry={node.glossaryEntry} targetLanguage={node.targetLanguage}>
            <Nodes value={node.children} />
          </GlossaryEntry>
        );
      }

      return null;
    }}
  </For>
);

const GlossaryEntry = ({
  glossaryEntry,
  targetLanguage,
  children
}: {
  glossaryEntry: IGlossaryEntry;
  targetLanguage: LanguageDto;
  children: ReactNode;
}) => {
  const { t } = useTranslation('documentLanguageParagraph');

  return (
    <Tooltip
      title={
        <div className="max-w-xs space-y-1 px-2 py-1">
          <p>{t('glossaryEntry')}</p>

          <p className="flex items-center space-x-2 font-normal">
            <img src={targetLanguage.flagUrl ?? ''} alt={targetLanguage.name} className="h-4" />

            <span>{glossaryEntry.targetContent}</span>
          </p>

          <Show when={glossaryEntry.notes}>
            <p className="pt-2 font-normal">{t('notes', { notes: glossaryEntry.notes })}</p>
          </Show>
        </div>
      }
      containerClassName={classNames('inline border-b-4 border-dotted', {
        'border-orange-400': glossaryEntry.isWarning,
        'border-green-500': !glossaryEntry.isWarning
      })}
    >
      {children}
    </Tooltip>
  );
};
