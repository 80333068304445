import { useTranslation } from 'react-i18next';

import { For } from '@/components/v1/For';
import { PlaceholderV2 } from '@/components/v1/Placeholder';
import { Show } from '@/components/v1/Show';
import { Tooltip } from '@/components/v1/Tooltip';
import { classNames } from '@/utils/classNames';

import type { TGlossaryEntryNode, TNode, TPlaceholderNode, TTextNode } from './types';

export const Nodes = (props: { value: TNode[] | undefined | null }) => {
  const { value } = props;

  return (
    <For each={value}>
      {(node, nodeIndex) => {
        const key = `${node.type}_${nodeIndex}`;

        if (node.type === 'TEXT') {
          return <TextNode key={key} value={node} />;
        }

        if (node.type === 'PLACEHOLDER') {
          return <PlaceholderNode key={key} value={node} />;
        }

        if (node.type === 'GLOSSARY_ENTRY') {
          return <GlossaryEntryNode key={key} value={node} />;
        }

        return null;
      }}
    </For>
  );
};

const TextNode = (props: { value: TTextNode }) => {
  const { value } = props;

  return (
    <span
      className={classNames('whitespace-pre-wrap', {
        'bg-yellow-100': value.highlight
      })}
    >
      {value.text}
    </span>
  );
};

const PlaceholderNode = (props: { value: TPlaceholderNode }) => {
  const { value } = props;
  const { placeholder, children } = value;

  const color = placeholder.ctype === 'x-marker' ? 'blue' : 'orange';
  const label = placeholder.desc || placeholder.id;

  return (
    <Show
      when={children}
      fallback={
        <span className="mx-0.5">
          <PlaceholderV2 color={color} tag="self-closing">
            {label}
          </PlaceholderV2>
        </span>
      }
    >
      <span>
        <span className="mx-0.5">
          <PlaceholderV2 color={color} tag="opening">
            {label}
          </PlaceholderV2>
        </span>

        <Nodes value={children} />

        <span className="mx-0.5">
          <PlaceholderV2 color={color} tag="closing">
            {label}
          </PlaceholderV2>
        </span>
      </span>
    </Show>
  );
};

const GlossaryEntryNode = (props: { value: TGlossaryEntryNode }) => {
  const { value } = props;
  const { glossaryEntry, targetLanguage, children } = value;

  const { t } = useTranslation('documentLanguageParagraph');

  return (
    <Tooltip
      title={
        <div className="max-w-xs space-y-1 px-2 py-1">
          <p>{t('glossaryEntry')}</p>

          <p className="flex items-center space-x-2 font-normal">
            <img src={targetLanguage.flagUrl ?? ''} alt={targetLanguage.name} className="h-4" />

            <span>{glossaryEntry.targetContent}</span>
          </p>

          <Show when={glossaryEntry.notes}>
            <p className="pt-2 font-normal">{t('notes', { notes: glossaryEntry.notes })}</p>
          </Show>
        </div>
      }
      containerClassName={classNames('inline border-b-4 border-dotted', {
        'border-orange-400': glossaryEntry.isWarning,
        'border-green-500': !glossaryEntry.isWarning
      })}
    >
      <Nodes value={children} />
    </Tooltip>
  );
};
