import classNames from 'classnames';
import type { ReactNode } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import { Button } from '../Button';
import { Show } from '../Show';
import { ALERT_ICONS } from './constants';
import type { IAlertProps, TConfirmAlertProps } from './types';

export const Alert = ({
  type = 'info',
  message,
  icon,
  showIcon = true,
  actions,
  size = 'md',
  border = true,
  className
}: IAlertProps) => {
  const AlertIcon = ALERT_ICONS[type];

  return (
    <div
      className={classNames(
        'rounded',
        {
          border,
          'border-green-400 bg-green-50': type === 'success',
          'border-blue-400 bg-blue-50': type === 'info',
          'border-orange-600 bg-orange-50': type === 'warning',
          'border-red-500 bg-red-200': type === 'error',
          'p-2': size === 'md',
          'p-6': size === 'lg'
        },
        className
      )}
    >
      <div
        className={classNames('flex items-center space-x-2 text-sm', {
          'text-green-500': type === 'success',
          'text-blue-500': type === 'info',
          'text-orange-700': type === 'warning',
          'text-red-600': type === 'error'
        })}
      >
        <Show when={showIcon}>
          <Show when={isNonNullable(icon)} fallback={<AlertIcon />}>
            {icon}
          </Show>
        </Show>

        <Show when={isNonNullable(message)}>
          <div className="flex-1">{message}</div>
        </Show>
      </div>

      <Show when={isNonNullable(actions)}>
        <div className="mt-2 flex justify-center space-x-2">{actions}</div>
      </Show>
    </div>
  );
};

Alert.Confirm = ({ okText, cancelText, onOk, onCancel, ...alertProps }: TConfirmAlertProps) => {
  const actions: ReactNode[] = [];
  if (isNonNullable(okText)) {
    actions.push(
      <Button key="ok_button" onClick={onOk}>
        {okText}
      </Button>
    );
  }
  if (isNonNullable(cancelText)) {
    actions.push(
      <Button key="cancel_button" btnType="outline" onClick={onCancel}>
        {cancelText}
      </Button>
    );
  }

  return <Alert actions={actions.length ? actions : null} {...alertProps} />;
};
