import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonLink } from '@/components/v1/Button';
import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import { APP_URL } from '@/config/urls';
import type { IDocumentTranslationLockedModalProps } from '@/redux/slices/modalsSlice/types/props';
import { isNonNullable } from '@/utils/isNonNullable';

export const DocumentTranslationLockedModal: FunctionComponent<
  TJumboModalProps & { props: IDocumentTranslationLockedModalProps }
> = ({ props: { accountName }, ...modalProps }) => {
  const { t } = useTranslation('modals');

  return (
    <Modal.Jumbo {...modalProps}>
      {{
        header: isNonNullable(accountName)
          ? t('documentTranslationLockedBy', { accountName })
          : t('documentTranslationLocked'),
        content: (
          <div className="space-y-8">
            <div>{t('translationCannotBeEditedBecauseHadBeenConfirmed')}</div>
            <div>
              <div className="font-bold">{t('doYouNeedToEditThisDocument')}</div>
              <div>{t('ifYouNeedToEditItCloseThisAndClickAskToUnlockTranslation')}</div>
            </div>
          </div>
        ),
        footer: (
          <div className="space-x-2">
            <Button
              onClick={() => {
                modalProps.onClose(false);
              }}
            >
              {t('close')}
            </Button>
            <ButtonLink btnType="outline" href={`${APP_URL}/documents`} rel="noreferrer">
              {t('goBackToDocumentList')}
            </ButtonLink>
          </div>
        )
      }}
    </Modal.Jumbo>
  );
};
