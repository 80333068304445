import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { usePusherSubscribeToChannel } from '@/providers/PusherProvider';
import { pusherChannelsAvailable, PusherEvent } from '@/providers/PusherProvider/constants';
import { operationApiUtil, useGetDocumentDetailQuery } from '@/redux/api';
import { SourceDocumentDtoStatus } from '@/redux/api/types';
import { useAppDispatch } from '@/redux/hooks';

import { DocumentTranslations } from './components/DocumentTranslations';
import { SourceDocumentDetail } from './components/SourceDocumentDetail';
import { SourceDocumentHeader } from './components/SourceDocumentHeader';
import { DocumentLayout } from './DocumentLayout';

const useDocumentParams = () => {
  const params = useParams<{ documentId: string }>();
  const parsedParams = useMemo(() => ({ documentId: parseInt(params.documentId) }), [params]);
  return parsedParams;
};

export const Document = () => {
  const { documentId } = useDocumentParams();

  const dispatch = useAppDispatch();

  const { channelString, event, callback } = {
    channelString: pusherChannelsAvailable.privateDocument(String(documentId)),
    event: PusherEvent.NEW_STATUS,
    callback: () => dispatch(operationApiUtil.invalidateTags(['documentDetail']))
  };

  const { channel: pusherChannel } = usePusherSubscribeToChannel(channelString);
  pusherChannel?.bind(event, callback);

  // refetchOnMountOrArgChange is needed due to state changes in webeditor
  const { data: documentDetail } = useGetDocumentDetailQuery(documentId, { refetchOnMountOrArgChange: true });

  const documentStatus = documentDetail?.sourceDocument?.status;

  if (!!documentStatus && documentStatus !== SourceDocumentDtoStatus.UPLOADED) {
    pusherChannel?.unbind(event, callback);
  }

  return (
    <DocumentLayout title={documentDetail?.sourceDocument?.filename ?? ''}>
      <SourceDocumentHeader documentId={documentId} />
      <SourceDocumentDetail documentId={documentId} />
      <DocumentTranslations documentId={documentId} />
    </DocumentLayout>
  );
};
Document.displayName = 'Document';
