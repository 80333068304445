import { zodResolver } from '@hookform/resolvers/zod';
import { ReactNode, useMemo } from 'react';
import { FieldErrors } from 'react-hook-form';

import { Form, useForm } from '@/components/v2/Form';
import { CountryDto } from '@/redux/api/types';

import { FORM_ID } from './constants';
import { BillingDetailsFormSchema, configureBillingDetailsFormSchema, defaultValues } from './utils';

const BillingDetailsForm = (props: BillingDetailsFormProps) => {
  const { children, defaultValues: defaultValuesProp, values, errors, countries, onSubmit } = props;

  const schema = useMemo(() => configureBillingDetailsFormSchema({ countries }), [countries]);
  const form = useForm<BillingDetailsFormSchema>({
    defaultValues: defaultValuesProp ?? defaultValues,
    errors,
    values,
    resolver: zodResolver(schema)
  });

  return (
    <Form {...form} id={FORM_ID} onSubmit={onSubmit} devTool>
      {children}
    </Form>
  );
};
BillingDetailsForm.displayName = 'BillingDetailsForm';

interface BillingDetailsFormProps {
  children: ReactNode;
  countries: CountryDto[];
  defaultValues?: BillingDetailsFormSchema;
  errors?: FieldErrors<BillingDetailsFormSchema>;
  values?: BillingDetailsFormSchema;
  onSubmit: (data: BillingDetailsFormSchema) => void | Promise<void>;
}

export { BillingDetailsForm };
export type { BillingDetailsFormProps };
