import { PaymentIssuesModal } from '@/providers/ModalsProvider/modals/PaymentIssuesModal';
import { EModalType } from '@/redux/slices/modalsSlice/constants';
import { EditAssignedLanguagesModal } from '@/routes/SettingsTranslators/components/EditLanguagesModal';

import {
  AskToUnlockTranslationModal,
  AskUnlockTranslationModal,
  ConfirmTranslationModal,
  DeleteDocumentCommentModal,
  DeleteParagraphCommentModal,
  DocumentNotAvailableModal,
  DocumentPreTranslatedModal,
  DocumentTranslationLockedModal,
  ErrorModal,
  FeedbackModal,
  LowWordBalanceModal,
  NewDocumentRevisionAvailableModal,
  NewTranslationsImportedModal,
  NotEnoughWordsModal,
  SendFeedbackModal,
  SessionExpiredModal,
  SubscriptionExpiredModal,
  UnlockTranslationModal,
  WorkInvitationModal,
  FeatureRequireUpgradeModal
} from './modals';

export const MODAL_COMPONENTS = {
  [EModalType.ASK_UNLOCK_TRANSLATION]: AskUnlockTranslationModal,
  [EModalType.CONFIRM_TRANSLATION]: ConfirmTranslationModal,
  [EModalType.DELETE_DOCUMENT_COMMENT]: DeleteDocumentCommentModal,
  [EModalType.DELETE_PARAGRAPH_COMMENT]: DeleteParagraphCommentModal,
  [EModalType.DOCUMENT_NOT_AVAILABLE]: DocumentNotAvailableModal,
  [EModalType.DOCUMENT_PRE_TRANSLATED]: DocumentPreTranslatedModal,
  [EModalType.DOCUMENT_TRANSLATION_LOCKED]: DocumentTranslationLockedModal,
  [EModalType.ERROR]: ErrorModal,
  [EModalType.FEEDBACK]: FeedbackModal,
  [EModalType.NEW_DOCUMENT_REVISION_AVAILABLE]: NewDocumentRevisionAvailableModal,
  [EModalType.NEW_TRANSLATIONS_IMPORTED]: NewTranslationsImportedModal,
  [EModalType.SESSION_EXPIRED]: SessionExpiredModal,
  [EModalType.UNLOCK_TRANSLATION]: UnlockTranslationModal,
  [EModalType.WORK_INVITATION]: WorkInvitationModal,
  [EModalType.NOT_ENOUGH_WORDS_ERROR]: NotEnoughWordsModal,
  [EModalType.EDIT_TRANSLATOR_ASSIGNED_LANGUAGES]: EditAssignedLanguagesModal,
  // new version of ASK_UNLOCK_TRANSLATION modal
  [EModalType.ASK_TO_UNLOCK_TRANSLATION]: AskToUnlockTranslationModal,
  // new version of FEEDBACK modal
  [EModalType.SEND_FEEDBACK]: SendFeedbackModal,
  [EModalType.SUBSCRIPTION_EXPIRED]: SubscriptionExpiredModal,
  [EModalType.PAYMENT_ISSUES]: PaymentIssuesModal,
  [EModalType.LOW_WORD_BALANCE]: LowWordBalanceModal,
  [EModalType.FEATURE_NOT_AVAILABLE]: FeatureRequireUpgradeModal
} as const;
