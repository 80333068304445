import { useTranslation } from 'react-i18next';

import { MTSuggestionIcon } from '../MTSuggestionIcon';
import { TranslationSuggestion } from '../TranslationSuggestion';
import type { IMTSuggestionProps } from './types';
import { getProviderDescription } from './utils';

export const MTSuggestion = ({
  suggestion,
  shortcut,
  showShortcut,
  sourceLanguage,
  targetLanguage,
  onClick
}: IMTSuggestionProps) => {
  const { t } = useTranslation('documentLanguageParagraph');

  return (
    <TranslationSuggestion
      showShortcut={showShortcut}
      shortcut={shortcut}
      sourceLanguage={sourceLanguage}
      targetLanguage={targetLanguage}
      cleanSuggestion={suggestion.translationClean}
      icon={<MTSuggestionIcon vendor={suggestion.vendor} />}
      tooltip={t('translationSuggestionFrom', { provider: getProviderDescription(suggestion.vendor) })}
      onClick={onClick}
    />
  );
};
