import { FunctionComponent } from 'react';

import { classNames } from '@/utils/classNames';

import { Spinner } from '../Spinner';
import type { TButtonProps } from './types';
import { getButtonClassName } from './utils';

export const Button: FunctionComponent<TButtonProps> = ({
  btnType,
  children,
  className,
  containerClassName,
  color,
  disabled,
  icon,
  size,
  loading,
  tabIndex,
  ...htmlButtonProps
}) => {
  const buttonClassName = getButtonClassName({ color, size, btnType, disabled, className, loading });

  return (
    <Spinner enabled={loading} containerClassName={classNames('inline-block', containerClassName)}>
      <button
        className={classNames(buttonClassName, { 'cursor-not-allowed opacity-50': disabled })}
        disabled={disabled || loading}
        {...htmlButtonProps}
      >
        {icon && <span className="mr-2">{icon}</span>}
        <span>{children}</span>
      </button>
    </Spinner>
  );
};
