import { cva } from 'cva';

const modalContent = cva(
  'z-10 flex w-full max-w-xl flex-col rounded-xl border border-gray-200 bg-surface-background shadow-md',
  {
    variants: {
      size: {
        sm: 'max-w-[20.5rem] gap-y-7 p-7',
        md: 'max-w-sm gap-y-7 p-5',
        lg: 'max-w-[36.75rem] gap-y-9 px-9 py-7',
        xl: 'max-w-[48rem] gap-y-9 px-9 py-7'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

const modalHeader = cva('flex flex-col', {
  variants: {
    size: {
      sm: 'gap-y-2',
      md: 'gap-y-2.5',
      lg: 'gap-y-3',
      xl: 'gap-y-3'
    }
  },
  defaultVariants: {
    size: 'md'
  }
});

const modalFooter = cva('flex', {
  variants: {
    orientation: {
      horizontal: 'flex-row-reverse gap-x-4 children:flex-1',
      vertical: 'flex-col gap-y-4'
    }
  },
  defaultVariants: {
    orientation: 'vertical'
  }
});

export { modalContent, modalHeader, modalFooter };
