import { find } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Show } from '@/components/v2/Show';
import { Skeleton } from '@/components/v2/Skeleton';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetSavedViewsQuery } from '@/redux/api/documentListApi';
import { useDocumentListSearchParams } from '@/routes/DocumentList/hooks/useDocumentListSearchParams';

import { SavedViewEmptySetModal } from '../SavedViewEmptySetModal';
import { SavedViewsModal } from '../SavedViewsModal';
import { SaveViewModal } from '../SaveViewModal';
import { UpgradePremiumModal } from '../UpgradePremiumModal';
import { Search } from './components/Search';
import { StatusCombobox } from './components/StatusCombobox';
import { TargetLanguageCombobox } from './components/TargetLanguageCombobox';
import { TranslatorCombobox } from './components/TranslatorCombobox';
import { UploaderCombobox } from './components/UploaderCombobox';

interface FiltersProps {
  searchTerm?: string;
  areFilterActive: boolean;
  onSearch: (value: string) => void;
}

export const Filters = ({ searchTerm, areFilterActive, onSearch }: FiltersProps) => {
  const { t: tDocumentList } = useTranslation('documentList');

  const [{ savedView }, { deleteAll: onClearFilters }] = useDocumentListSearchParams();

  const { currentAccount } = useCurrentAccount();
  const isSavedViewsEnabled = currentAccount.subscriptionInfo.features.savedViews;

  const { data: { savedViewList } = {} } = useGetSavedViewsQuery(undefined, { skip: !isSavedViewsEnabled });
  const currentSavedView = find(savedViewList, { id: savedView });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row justify-between">
        <Search onSearch={onSearch} searchTerm={searchTerm} />

        {/* Saved views disabled in prod for now */}
        <Show when={import.meta.env.REACT_APP_ENV !== 'production'}>
          <Show
            when={isSavedViewsEnabled}
            fallback={
              <UpgradePremiumModal>
                <Button variant="soft" color="primary">
                  {tDocumentList('openSavedView')}
                </Button>
              </UpgradePremiumModal>
            }
          >
            <Show
              when={savedViewList?.length}
              fallback={
                <SavedViewEmptySetModal>
                  <Button variant="soft" color="primary">
                    {tDocumentList('openSavedView')}
                  </Button>
                </SavedViewEmptySetModal>
              }
            >
              <SavedViewsModal>
                <Button variant="soft" color="primary">
                  {tDocumentList('openSavedView')}
                </Button>
              </SavedViewsModal>
            </Show>
          </Show>
        </Show>
      </div>

      <Show when={currentSavedView}>
        {(savedView) => (
          <Text size="sm" weight="semibold">
            <Trans
              i18nKey="documentList:currentView"
              components={{ tag: <Tag color="primary" /> }}
              values={{ name: savedView.name }}
            />
          </Text>
        )}
      </Show>

      <div className="flex flex-row justify-between">
        <div className="flex flex-row justify-start gap-3 overflow-auto p-0.5">
          <UploaderCombobox />
          <TranslatorCombobox />
          <TargetLanguageCombobox />
          <StatusCombobox />
        </div>
        <Show when={areFilterActive}>
          <div className="flex h-auto flex-row items-center gap-5 px-3">
            <Button variant="text" color="negative" onClick={onClearFilters}>
              {tDocumentList('clearFilters')}
            </Button>

            {/* Saved views disabled in prod for now */}
            <Show when={import.meta.env.REACT_APP_ENV !== 'production'}>
              <Show
                // cannot save a view if user has not a premium sub
                when={isSavedViewsEnabled}
              >
                <SaveViewModal>
                  <Button variant="text" color="primary">
                    {tDocumentList('saveView')}
                    <AccessibleIcon icon="ri-save-line" label="save view icon" />
                  </Button>
                </SaveViewModal>
              </Show>
            </Show>
          </div>
        </Show>
      </div>
    </div>
  );
};
Filters.displayName = 'Filters';

export const FiltersSkeleton = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row gap-3">
        <Skeleton width="100%" maxWidth="17.75rem" height="2.25rem" />
        <Skeleton width="5.5rem" height="2.25rem" />
      </div>

      <div className="flex flex-row justify-start gap-3">
        <Skeleton width="10rem" height="2.25rem" />
        <Skeleton width="10rem" height="2.25rem" />
        <Skeleton width="10rem" height="2.25rem" />
        <Skeleton width="10rem" height="2.25rem" />
      </div>
    </div>
  );
};
FiltersSkeleton.displayName = 'FiltersSkeleton';
