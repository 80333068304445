import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ETranslationListFilter } from '../../api/constants';
import type { INextParagraph, IParagraph } from '../../api/types';
import { DEFAULT_PAGINATION_PAGE, INITIAL_STATE } from './constants';

export const documentLanguageSlice = createSlice({
  name: 'documentLanguage',
  initialState: INITIAL_STATE,

  reducers: {
    setPaginationPage: (state, { payload }: PayloadAction<number>) => {
      state.paginationPage = payload;
    },
    setSearchSource: (state, { payload }: PayloadAction<string | null | undefined>) => {
      state.searchSource = payload || undefined;
    },
    setDebouncedSearchSource: (state, { payload }: PayloadAction<string | null | undefined>) => {
      state.debouncedSearchSource = payload || undefined;
      state.paginationPage = DEFAULT_PAGINATION_PAGE;
    },
    setSearchTarget: (state, { payload }: PayloadAction<string | null | undefined>) => {
      state.searchTarget = payload || undefined;
    },
    setDebouncedSearchTarget: (state, { payload }: PayloadAction<string | null | undefined>) => {
      state.debouncedSearchTarget = payload || undefined;
      state.paginationPage = DEFAULT_PAGINATION_PAGE;
    },
    setFilter: (state, { payload }: PayloadAction<ETranslationListFilter | null | undefined>) => {
      state.filter = payload || undefined;
      state.paginationPage = DEFAULT_PAGINATION_PAGE;
    },
    setPreviewPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.previewPageNumber = payload;
    },
    clearFilters: (state) => {
      state.paginationPage = DEFAULT_PAGINATION_PAGE;
      state.searchSource = undefined;
      state.debouncedSearchSource = undefined;
      state.searchTarget = undefined;
      state.debouncedSearchTarget = undefined;
      state.filter = undefined;
    },

    changePreviewPage: (
      state,
      { payload }: PayloadAction<{ previewPageNumber: number; paginationPage: number; paragraphId: number }>
    ) => {
      const { previewPageNumber, paginationPage, paragraphId } = payload;

      state.previewPageNumber = previewPageNumber;
      state.paginationPage = paginationPage;
      state.scrollToParagraphId = paragraphId;
      state.searchSource = undefined;
      state.debouncedSearchSource = undefined;
      state.searchTarget = undefined;
      state.debouncedSearchTarget = undefined;
      state.filter = undefined;
    },

    // Scroll to paragraph
    scrollToParagraph: (
      state,
      {
        payload
      }: PayloadAction<{ paginationPage: number; paragraphId: number; keepFilters?: boolean; openComments?: boolean }>
    ) => {
      const { paginationPage, paragraphId, keepFilters, openComments } = payload;

      state.paginationPage = paginationPage;
      state.scrollToParagraphId = paragraphId;
      state.openParagraphCommentsId = openComments ? paragraphId : undefined;
      if (!keepFilters) {
        state.searchSource = undefined;
        state.debouncedSearchSource = undefined;
        state.searchTarget = undefined;
        state.debouncedSearchTarget = undefined;
        state.filter = undefined;
      }
    },
    scrolledToParagraph: (state, { payload }: PayloadAction<number>) => {
      if (state.scrollToParagraphId === payload) {
        state.scrollToParagraphId = undefined;

        if (state.openParagraphCommentsId === payload) {
          state.openParagraphComments = true;
        } else {
          state.focusedParagraphId = payload;
          state.suggestionsParagraphId = payload;
        }
      }
    },
    openedParagraphComments: (state, { payload }: PayloadAction<number>) => {
      if (state.openParagraphCommentsId === payload) {
        state.openParagraphCommentsId = undefined;
        state.openParagraphComments = undefined;
      }
    },

    // Current paragraph
    paragraphFocused: (state, { payload }: PayloadAction<IParagraph>) => {
      const { id, page } = payload;
      const previewPageNumber = page?.number;

      state.focusedParagraphId = id;
      state.suggestionsParagraphId = id;

      if (previewPageNumber) {
        state.previewPageNumber = previewPageNumber;
      }
    },
    paragraphBlurred: (state, { payload }: PayloadAction<IParagraph>) => {
      const { id } = payload;

      if (state.focusedParagraphId === id) {
        state.focusedParagraphId = null;
      }
    },
    nextParagraphFocused: (state, { payload }: PayloadAction<INextParagraph>) => {
      const { id, paginationPage } = payload;

      state.focusedParagraphId = id;
      state.suggestionsParagraphId = id;
      state.paginationPage = paginationPage;
    },

    // Saved indicator
    showSavedIndicator: (state) => {
      state.savedIndicator = true;
    },
    hideSavedIndicator: (state) => {
      state.savedIndicator = false;
    }
  }
});

export const {
  setFilter,
  setPaginationPage,
  setPreviewPageNumber,
  setSearchSource,
  setDebouncedSearchSource,
  setSearchTarget,
  setDebouncedSearchTarget,
  scrollToParagraph,
  scrolledToParagraph,
  paragraphFocused,
  paragraphBlurred,
  nextParagraphFocused,
  hideSavedIndicator,
  showSavedIndicator,
  openedParagraphComments,
  changePreviewPage,
  clearFilters
} = documentLanguageSlice.actions;
