import { useState } from 'react';

import Modal, { ModalProps } from '@/components/v2/Modal';
import { SaveViewContent } from '@/routes/DocumentList/components/DocumentListContent/components/SaveViewModal/components/SaveViewContent';

export interface SaveViewModalProps extends ModalProps {
  onConfirm?: () => void;
}

export const SaveViewModal = (props: SaveViewModalProps) => {
  const [open, setOpen] = useState(false);
  const { onConfirm, children, ...modalProps } = props;

  return (
    <Modal.Root open={open} onOpenChange={setOpen} {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>
      <Modal.Content onOpenAutoFocus={(e) => e.preventDefault()} className="max-w-[484px]">
        <SaveViewContent
          onConfirm={() => {
            setOpen(false);
            onConfirm?.();
          }}
        />
      </Modal.Content>
    </Modal.Root>
  );
};
