import { ExpandedState } from '@tanstack/react-table';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_EXPANDED: ExpandedState = {};

/**
 * Backward compatibility workaround used as default column size
 * - https://github.com/TanStack/table/discussions/3192#discussioncomment-580252
 * - https://github.com/TanStack/table/discussions/4179#discussioncomment-7142606
 */
export const COLUMN_SIZE_AUTO = 'auto' as unknown as number;

export const DEFAULT_COLUMN = {
  size: COLUMN_SIZE_AUTO
};

export const LOADING_DATA_TOKEN = Symbol.for('DataTable/LOADING_DATA_TOKEN');
