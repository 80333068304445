import type { DefaultElementType } from './types';

export const DEFAULT_ELEMENT: DefaultElementType = 'div';

export const MARKER_TEXT_MAP: Record<string, string> = {
  rightIndentTab: ' ',
  indentHereTab: ' ',
  noBreakSpace: ' ',
  oghamSpace: ' ',
  mongolianVowelSeparator: ' ',
  enQuad: ' ',
  emQuad: ' ',
  enSpace: ' ',
  emSpace: ' ',
  threePerEmSpace: ' ',
  fourPerEmSpace: ' ',
  sixPerEmSpace: ' ',
  figureSpace: ' ',
  punctuationSpace: ' ',
  thinSpace: ' ',
  hairSpace: ' ',
  zeroWidthSpace: ' ',
  softReturn: ' ',
  narrowNoBreakSpace: ' ',
  mediumMathematicalSpace: ' ',
  ideographicSpace: ' ',
  zeroWidthNoBreakSpace: ' '
};
