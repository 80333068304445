import { zodResolver } from '@hookform/resolvers/zod';
import { isNaN } from 'lodash';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { Box } from '@/components/v2/Box';
import { Button } from '@/components/v2/Button';
import { Label } from '@/components/v2/Label';
import { Select, SelectItem } from '@/components/v2/Select';
import { useGetLanguagesQuery } from '@/redux/api';
import { useImportTranslationMemoriesMutation } from '@/redux/api/translationMemoryApi';
import { addOperationToQueue } from '@/redux/slices/operationsSlice/operationsSlice';
import { OperationsKind } from '@/redux/slices/operationsSlice/types';
import { to } from '@/utils/awaitToJs';

import { TranslationMemoryUploadContext } from './TranslationMemoryUploadLayout';
import { Text } from '@/components/v2/Text';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { InlineMessage } from '@/components/v2/InlineMessage';

interface TranslationMemoryConfirmProps {}

export const TranslationMemoryConfirmUpload: React.FC<TranslationMemoryConfirmProps> = () => {
  const { t: tTM } = useTranslation('translationMemory');
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationMemoryUploadState, setTranslationMemoryUploadState } = useContext(TranslationMemoryUploadContext);

  const {
    data: languages,
    isLoading: languagesIsLoading,
    isUninitialized: languagesIsUninitialized
  } = useGetLanguagesQuery();

  const [importTranslationMemoriesTrigger, { isLoading: importTranslationMemoriesIsLoading }] =
    useImportTranslationMemoriesMutation();

  const formSchema = z.object({
    stashedFileId: z.number(),
    sourceLanguageId: z.number(),
    targetLanguageId: z.number(),
    cleanIdentical: z.boolean(),
    trim: z.boolean()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...translationMemoryUploadState
    }
  });

  if (translationMemoryUploadState.stashedFileId === undefined) {
    // Redirect to upload TM page step if there isn't a stashedFileId in context state.
    history.push('/translation-memories/upload');
  }

  if (languagesIsUninitialized || languagesIsLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setTranslationMemoryUploadState({ ...data });
    const [err, res] = await to(importTranslationMemoriesTrigger(data).unwrap());
    if (!err && res) {
      const { operationId } = res;
      dispatch(
        addOperationToQueue({
          operationId,
          message: tTM('translationMemoryImportSuccessMessage'),
          kind: OperationsKind.UPLOAD_TM
        })
      );
      setTranslationMemoryUploadState({ ...data, operationId });
    }
  };

  const formFields = languages ? (
    <div className="mx-auto mt-6 max-w-screen-md space-y-9">
      <Box className="space-y-9 border-0">
        <Controller
          name="sourceLanguageId"
          control={form.control}
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState }) => (
            <div className="max-w-sm space-y-1">
              <div className="flex flex-col gap-4">
                <Label text={tTM('sourceLanguage')} />
                <Select
                  onChange={(value) => {
                    const toNumber = Number(value);
                    if (!isNaN(toNumber)) {
                      onChange(Number(value));
                    }
                  }}
                  value={value === undefined ? undefined : `${value}`}
                  placeholder={tTM('selectLanguage')}
                  sideOffset={5}
                  bordered
                >
                  {languages.all.map((item) => (
                    <SelectItem value={`${item.id}`} key={item.id}>
                      {item.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              {fieldState.error?.message && <p className="text-sm text-red-400">{fieldState.error.message}</p>}
            </div>
          )}
        />
        <Controller
          name="targetLanguageId"
          control={form.control}
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState }) => (
            <div className="max-w-sm space-y-1">
              <div className="flex flex-col gap-4">
                <Label text={tTM('targetLanguage')} />
                <Select
                  onChange={(value) => {
                    const toNumber = Number(value);
                    if (!isNaN(toNumber)) {
                      onChange(Number(value));
                    }
                  }}
                  value={value === undefined ? undefined : `${value}`}
                  placeholder={tTM('selectLanguage')}
                  sideOffset={5}
                  bordered
                >
                  {languages.active.map((item) => (
                    <SelectItem value={`${item.id}`} key={item.id}>
                      {item.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              {fieldState.error?.message && <p className="text-sm text-red-400">{fieldState.error.message}</p>}
            </div>
          )}
        />
        <InlineMessage type="informational" orientation="horizontal" className="flex-grow">
          <div className="flex w-full items-center justify-between">
            <Text size="md" weight="semibold" className="text-blue-800">
              {tTM('youCanAddMoreTargetLanguages')}
            </Text>
            <ButtonLink href={`/settings/languages`} color="secondary">
              {tTM('goToSettings')}
            </ButtonLink>
          </div>
        </InlineMessage>
      </Box>
      <div className="flex justify-end">
        <Button type="submit" disabled={importTranslationMemoriesIsLoading}>
          {tTM('importRecords')}
        </Button>
      </div>
    </div>
  ) : null;

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tTM('translationMemoryUpload')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>{formFields}</form>
        </FormProvider>
      </div>
    </div>
  );
};
