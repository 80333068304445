import React, { Children, isValidElement, ReactElement } from 'react';

interface HintBoxProps {
  when: boolean;
  children: JSX.Element;
}

export const HintBox = ({ when, children }: HintBoxProps): JSX.Element => {
  if (when) {
    const filteredChildren = Children.toArray(children).filter(Boolean);

    let firstChildFound = false;

    const modifiedChildren = filteredChildren.map((child) => {
      if (isValidElement(child)) {
        if (!firstChildFound) {
          firstChildFound = true;
          const existingClassName = child.props.className || '';
          const mergedClassName = `${existingClassName} shadow-glow`.trim();
          return React.cloneElement(child as ReactElement, {
            className: mergedClassName
          });
        }
      }
      return child;
    });

    return <>{modifiedChildren}</>;
  }

  return children;
};
HintBox.displayName = 'HintBox';
