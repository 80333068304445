import {
  Combobox,
  ComboboxContent,
  ComboboxItem,
  ComboboxTrigger,
  ComboboxTriggerIcon,
  ComboboxTriggerValue,
  ComboboxSearchItem,
  ComboboxItems
} from './Combobox';

const Object = {
  Root: Combobox,
  Content: ComboboxContent,
  Item: ComboboxItem,
  Trigger: ComboboxTrigger,
  TriggerIcon: ComboboxTriggerIcon,
  TriggerValue: ComboboxTriggerValue,
  SearchItem: ComboboxSearchItem,
  ItemsContainer: ComboboxItems
};

export type {
  ComboboxContentProps,
  ComboboxContext,
  ComboboxItemProps,
  ComboboxProps,
  ComboboxTriggerProps,
  ComboboxSearchItemProps,
  ComboboxItemType
} from './types';
export { Combobox, ComboboxContent, ComboboxItem, ComboboxTrigger, ComboboxSearchItem, ComboboxItems };

export default Object;
