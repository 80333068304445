import { classNames } from '@/utils/classNames';

import type { IAvatarProps } from './types';

export const Avatar = (props: IAvatarProps) => {
  const { alt, src, fallback, loading = 'lazy', size = 'md', className, style } = props;
  const hasSrc = !!src;

  const mergedClassName = classNames(
    'rounded-full',
    {
      'inline-block': hasSrc,
      'inline-flex items-center justify-center bg-gray-400': !hasSrc,
      'h-4 w-4': size === 'sm',
      'h-8 w-8': size === 'md',
      'h-10 w-10': size === 'lg'
    },
    className
  );

  if (hasSrc) {
    return <img className={mergedClassName} src={src} alt={alt} loading={loading} style={style} />;
  }

  return (
    <span className={mergedClassName} style={style}>
      <span
        className={classNames('font-bold leading-none text-white', {
          'text-sm': size === 'sm',
          'text-2xl': size === 'md',
          'text-3xl': size === 'lg'
        })}
      >
        {fallback ?? alt}
      </span>
    </span>
  );
};
