import React, { createContext, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { addTeamMemberColor } from '@/redux/slices/teamMembersSlice';
import { DEFAULT_COLOR } from '@/redux/slices/teamMembersSlice/constants';

import type { ICommentsContext, ICommentsProviderProps } from './types';

export const CommentsContext = createContext<ICommentsContext>({
  teamMembers: [],
  teamMembersColors: { colors: {}, defaultColor: DEFAULT_COLOR },
  suggestions: []
});

// TODO: When document language changes it should reset all colors.
export const CommentsProvider: React.FC<ICommentsProviderProps> = ({ teamMembers = [], children }) => {
  const dispatch = useAppDispatch();

  const colors = useAppSelector((state) => state.teamMembers.colors);
  const teamMembersColors = useMemo(() => ({ colors, defaultColor: DEFAULT_COLOR }), [colors]);
  const suggestions = useMemo(
    () =>
      teamMembers.map(({ id, fullName }) => ({ id, name: fullName, className: teamMembersColors.colors[id]?.text })) ||
      [],
    [teamMembers, teamMembersColors]
  );

  useEffect(() => {
    teamMembers.forEach(({ id }) => {
      dispatch(addTeamMemberColor(id));
    });
  }, [teamMembers, dispatch]);

  return (
    <CommentsContext.Provider value={{ teamMembers, teamMembersColors, suggestions }}>
      {children}
    </CommentsContext.Provider>
  );
};
