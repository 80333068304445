import { useTranslation } from 'react-i18next';

import { Text } from '@/components/v2/Text';

export const FormBlockIntro = () => {
  const { t: tSettings } = useTranslation('settings');

  return (
    <div className="space-y-2">
      <Text size="lg" color="primary" weight="bold">
        {tSettings('areYouSureYouWantToDeleteYourAccountTitle')}
      </Text>
      <Text size="sm" color="tertiary">
        {tSettings('areYouSureYouWantToDeleteYourAccountText')}
      </Text>
    </div>
  );
};
