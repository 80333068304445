import { findIndex } from 'lodash';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { matchPath, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { ButtonLink } from '@/components/v2/ButtonLink';
import { Navbar } from '@/components/v2/Navbar';
import { StepperGroup } from '@/components/v2/StepperGroup';
import { usePusher } from '@/hooks/usePusher';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { pusherChannelsAvailable, PusherEvent } from '@/providers/PusherProvider/constants';
import { redokunApiUtil, useGetDocumentDetailQuery, useUpdateFlagsMutation } from '@/redux/api';
import { LaunchPreTranslationResponseDto } from '@/redux/api/types';
import { useAppDispatch } from '@/redux/hooks';
import { LoaderPreTranslateReport } from '@/routes/loaders';

import { LaunchPreTranslate } from './components/LaunchPreTranslate';

export const PreTranslate = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const routes = usePreTranslateRoutes();

  const { t } = useTranslation('pretranslate');
  const { t: tLayout } = useTranslation('layout');

  const { documentId } = usePreTranslateParams();
  const { currentAccount } = useCurrentAccount();
  const { hasConfirmedAnyDocument, mustCompleteOnboarding } = currentAccount.onboardingFlags;
  const [updateFlag] = useUpdateFlagsMutation();

  const {
    data: documentDetail
    // refetchOnMountOrArgChange is needed due to state changes in webeditor
  } = useGetDocumentDetailQuery(documentId, { refetchOnMountOrArgChange: true });

  const isCompleted = !mustCompleteOnboarding && hasConfirmedAnyDocument;

  const steps = [
    { id: 'options' as const, label: t('selectPretranslationOptions') },
    { id: 'report' as const, label: t('pretranslateReport') }
  ];
  const stepIndex = findIndex(steps, (step) => !!matchPath(location.pathname, routes[step.id]));

  const handleSkipForNow = () => {
    if (!isCompleted) {
      updateFlag({ hasHiddenOnboardingWizardWidget: true });
    }
    history.push('/documents');
  };

  usePusher<LaunchPreTranslationResponseDto>({
    channel: pusherChannelsAvailable.privateAccount(String(currentAccount.id)),
    event: PusherEvent.OPERATION_COMPLETED,
    callback: () => {
      dispatch(redokunApiUtil.invalidateTags(['documentDetail', 'operationStatus']));
    }
  });

  return (
    <div className="min-h-screen bg-surface-background">
      <Helmet>
        <title>{`${tLayout('preTranslate')} ${documentDetail?.sourceDocument.filename}`}</title>
      </Helmet>
      <Navbar sticky>
        <ButtonLink
          href={`/documents/show/${documentId}`}
          variant="surface"
          color="secondary"
          onClick={handleSkipForNow}
          className="ml-auto"
        >
          {isCompleted ? t('close') : t('skipForNow')}
        </ButtonLink>
      </Navbar>

      <div className="mx-auto w-full max-w-8xl px-20 py-12">
        <StepperGroup className="mb-20" items={steps} current={stepIndex} />

        <Switch>
          <Route {...routes.options}>
            <LaunchPreTranslate />
          </Route>

          <Route {...routes.report}>
            <LoaderPreTranslateReport documentId={documentId} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const usePreTranslateParams = () => {
  const params = useParams<{ documentId: string }>();
  const parsedParams = useMemo(() => ({ documentId: parseInt(params.documentId) }), [params]);
  return parsedParams;
};

const usePreTranslateRoutes = () => {
  const { path } = useRouteMatch();

  const routes = useMemo(
    () => ({
      options: { path, exact: true },
      report: { path: `${path}/report/:operationId`, exact: true }
    }),
    [path]
  );

  return routes;
};
