import { Step } from '@/routes/Onboarding/types';
import { EUploadingDocumentSteps } from '@/routes/UploadDocuments/types';

export const uploadingDocumentSteps: Step[] = [
  {
    id: EUploadingDocumentSteps.UPLOAD_FILE,
    path: '/documents/new',
    label: 'uploadFile'
  },
  {
    id: EUploadingDocumentSteps.SET_SOURCE_LANGUAGE,
    path: '/documents/new/source-language',
    label: 'setSourceLanguage'
  },
  {
    id: EUploadingDocumentSteps.SET_TARGET_LANGUAGE,
    path: '/documents/new/target-language',
    label: 'setTargetLanguage'
  }
];
