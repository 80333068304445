import { useTranslation } from 'react-i18next';
import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AppLayout from '@/components/layouts/AppLayout';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Navbar } from '@/components/v2/Navbar';
import { Text } from '@/components/v2/Text';
import { assert } from '@/utils/assert';
import { useGetGlossaryByIdQuery } from '@/redux/api/glossaryApi';
import { LayoutSkeleton } from '@/routes/CreateGlossaryEntry/components/LayoutSkeleton';

export const Layout = ({ children }: { children: ReactNode }) => {
  const { t: tLayout } = useTranslation('layout');
  const { t: tGlossary } = useTranslation('glossary');
  const { glossaryId } = useLayoutParams();

  const { data, isLoading, isUninitialized } = useGetGlossaryByIdQuery({ id: glossaryId });

  if (isLoading || isUninitialized) {
    return <LayoutSkeleton />;
  }

  assert(data, 'Glossary data not found');
  const { sourceLanguage, targetLanguage } = data.glossary;
  const title = `${sourceLanguage.name} ${tGlossary('to')} ${targetLanguage.name}`;

  return (
    <AppLayout.Root title={tLayout('createNewGlossary')}>
      <Navbar sticky>
        <ButtonLink href={`/glossaries/show/${glossaryId}`} variant="surface" color="secondary" className="ml-auto">
          {tLayout('close')}
        </ButtonLink>
      </Navbar>

      <div className="w-full border-b border-gray-100 bg-white">
        <div className="mx-auto max-w-8xl px-7 py-5">
          <Text weight="semibold" size="md" color="primary">
            {title}
          </Text>
        </div>
      </div>
      <AppLayout.Content>{children}</AppLayout.Content>
    </AppLayout.Root>
  );
};

const useLayoutParams = () => {
  const params = useParams<{ glossaryId: string }>();
  const parsedParams = useMemo(() => ({ glossaryId: parseInt(params.glossaryId) }), [params]);
  return parsedParams;
};
