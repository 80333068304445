import { CardExpiryElement as StripeCardExpiryElement } from '@stripe/react-stripe-js';
import { ComponentPropsWithoutRef } from 'react';

import { defaultClasses, defaultStyle } from '../styles';

const CardExpiryElement = (props: CardExpiryElementProps) => {
  const { options: optionsProp, ...cardExpiryElementProps } = props;
  const options = {
    ...optionsProp,
    style: { ...defaultStyle, ...optionsProp?.style },
    classes: { ...defaultClasses, ...optionsProp?.classes }
  };

  return <StripeCardExpiryElement options={options} {...cardExpiryElementProps} />;
};
CardExpiryElement.displayName = 'CardExpiryElement';

type CardExpiryElementProps = ComponentPropsWithoutRef<typeof StripeCardExpiryElement>;

export { CardExpiryElement };
export type { CardExpiryElementProps };
