import { zodResolver } from '@hookform/resolvers/zod';
import { push } from 'connected-react-router';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner'; // @todo: v2
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { FileContainer, FileContainerProps } from '@/components/v2/FileContainer';
import FileUploader from '@/components/v2/FileUploader';
import { Text } from '@/components/v2/Text';
import { useGetDocumentDetailQuery, useStashSegmentsImportMutation } from '@/redux/api';
import { useFileUploadMutation } from '@/redux/api/fileApi';
import { EntityType } from '@/redux/api/types';
import { useAppDispatch } from '@/redux/hooks';
import { TAppState } from '@/redux/store/types';
import { assert } from '@/utils/assert';
import { to } from '@/utils/awaitToJs';
import { sizeToMb } from '@/utils/sizeToMb';

import { InlineMessage } from '../../../../components/v2/InlineMessage';
import { acceptCreator } from './acceptCreator';
import { MAX_FILES, MAX_SIZE } from './constants';

export const ImportSegmentsUploadFile = () => {
  const { t } = useTranslation('importSegments');

  const [showSpinner, setShowSpinner] = useState(false);
  const [stashedFileId, setStashedFileId] = useState<number | undefined>();

  const dispatch = useAppDispatch();
  const importState = useSelector((state: TAppState) => state.importSegments);

  const [importErrorMessages, setImportErrorMessages] = useState<{ title: string; text: string }[]>([]);
  const { documentId, languageId } = useDocumentParams();
  const {
    data: documentDetail,
    isUninitialized: documentDetailUninitialized,
    isLoading: documentDetailLoading
  } = useGetDocumentDetailQuery(documentId);
  const [uploadFileTrigger, { isLoading: uploadFileLoading, isSuccess: uploadFileSuccess, isError: uploadFileError }] =
    useFileUploadMutation();
  const [stashSegmentTrigger] = useStashSegmentsImportMutation();

  const formSchema = z.object({
    file: z.instanceof(File)
  });

  const { control, formState, handleSubmit } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {}
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    if (data.file) {
      setShowSpinner(true);
      if (stashedFileId) {
        stashSegmentTrigger({ stashedFileId, documentId, languageId });
      }
    }
  };

  useEffect(() => {
    if (importState.importStatus === 'PARSED') {
      setShowSpinner(false);
      const { batchId } = importState;
      dispatch(push(`/documents/show/${documentId}/translations/${languageId}/import-phrase/${batchId}/confirm`));
    }
  }, [importState, dispatch, documentId, languageId]);

  if (documentDetailUninitialized || documentDetailLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  assert(documentDetail, 'Document data not found');

  const handleOnDrop = async (field: ControllerRenderProps<{ file: File }, 'file'>, files: File[]) => {
    setImportErrorMessages([]);
    if (files.length) {
      field.onChange(files[0]);
      const [err, response] = await to(uploadFileTrigger({ entityType: EntityType.segmentsFile, files }).unwrap());
      if (response?.successfulUploads.length) {
        setStashedFileId(response.successfulUploads[0].stashedFileId);
      } else if (err) {
        return;
      }
    }
  };

  const acceptedFiles = { ...acceptCreator('xliff'), ...acceptCreator('xlsx'), ...acceptCreator('ods') };

  let fileContainerStatus: FileContainerProps['status'] = 'active';
  if (uploadFileSuccess) {
    fileContainerStatus = 'completed';
  } else if (uploadFileError) {
    fileContainerStatus = 'error';
  }

  return (
    <div className="mx-auto mt-20 max-w-4xl">
      <div className="">
        {/* Header */}
        <div>
          <div className="flex items-center space-x-2">
            <Text size="lg" color="primary" className="font-bold">
              {t('uploadAXlsxOdsXliffFile')}
            </Text>
          </div>
          <div className="pb-4 pt-1">
            <Text size="md" color="secondary">
              <Trans
                i18nKey="importSegments:limits"
                values={{
                  fileNum: MAX_FILES,
                  sizeMB: 100
                }}
              />
            </Text>
          </div>
          {/* End Header */}

          {/* Upload form */}
          <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit(onSubmit)}>
            {/* Left */}
            <div>
              <div className="w-full">
                <Controller
                  name="file"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div>
                      <FileUploader.Root
                        dropzoneOptions={{
                          maxFiles: MAX_FILES,
                          maxSize: MAX_SIZE,
                          onDrop: (files) => {
                            handleOnDrop(field, files);
                          },
                          accept: acceptedFiles
                        }}
                      >
                        <FileUploader.DragArea>
                          <FileUploader.DragAreaContent>
                            <AccessibleIcon
                              icon="ri-upload-2-line"
                              label="ri-upload-2-line"
                              className="text-4xl text-alpha-400"
                            />
                            <FileUploader.DragAreaTitle>{t('dragAndDropFiles')}</FileUploader.DragAreaTitle>
                            <FileUploader.DragAreaDescription>
                              {t('supportedFileFormat')}
                            </FileUploader.DragAreaDescription>
                          </FileUploader.DragAreaContent>
                          <FileUploader.Divider className="py-4">{t('or')}</FileUploader.Divider>
                          <FileUploader.Button>{t('browseFiles')}</FileUploader.Button>
                        </FileUploader.DragArea>
                      </FileUploader.Root>
                      {field.value && stashedFileId ? (
                        <div className="mt-4">
                          <FileContainer
                            id={uniqueId()}
                            fileName={field.value.name}
                            fileSize={`${sizeToMb(field.value.size)} MB`}
                            status={fileContainerStatus}
                            percent={uploadFileSuccess ? 100 : 0}
                            onClose={() => {
                              field.onChange();
                              setStashedFileId(undefined);
                            }}
                            helperMessage=""
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                />
              </div>
            </div>
            {/* end left */}

            {/* Right */}
            <div className="space-y-4">
              {showSpinner ? (
                <InlineMessage type="informational" orientation="horizontal">
                  <Text size="sm" weight="semibold" className="flex space-x-2" color="primary">
                    <Spinner /> <span className="inline-block">{t('wereImportingYourDocument')}</span>
                  </Text>
                  <Text size="sm" weight="regular" className="mt-2">
                    {t('thisWillOnlyTakeAMomentDonTCloseThisTabYet')}
                  </Text>
                </InlineMessage>
              ) : null}
              {importState.importStatus === 'PARSED' ? (
                <InlineMessage type="success" orientation="horizontal">
                  <Text size="sm" weight="semibold" className="flex space-x-2" color="primary">
                    {t('documentSuccesfullyParsed')}
                  </Text>
                </InlineMessage>
              ) : null}
              {!showSpinner
                ? importErrorMessages.map((error, i) => {
                    return (
                      <InlineMessage key={i} type="error" orientation="horizontal">
                        <Text size="sm" weight="semibold" className="" color="primary">
                          {error.title}
                        </Text>
                        <Text size="sm" weight="regular" className="mt-2">
                          {error.text}
                        </Text>
                      </InlineMessage>
                    );
                  })
                : null}
              {!showSpinner && importState.errorMessage ? (
                <InlineMessage type="error" orientation="horizontal">
                  <Text size="sm" weight="semibold" className="" color="primary">
                    {t('error')}
                  </Text>
                  <Text size="sm" weight="regular" className="mt-2">
                    {importState.errorMessage}
                  </Text>
                </InlineMessage>
              ) : null}
            </div>
            {/* end right */}

            {/* Bottom */}
            <div className="col-span-2 flex justify-end">
              <Button
                size="md"
                color="primary"
                type="submit"
                disabled={!formState.isValid || uploadFileError || uploadFileLoading || !stashedFileId}
              >
                {t('upload')}
                <AccessibleIcon className="text-xl" label="ri-arrow-right-line" icon="ri-arrow-right-line" />
              </Button>
            </div>
            {/* end bottom */}
          </form>
        </div>
      </div>
      <div className="mt-8">
        <InlineMessage type="informational" orientation="horizontal">
          <Text size="sm" weight="semibold" className="" color="primary">
            {t('infoExcelTitle')}
          </Text>
          <Text size="sm" weight="regular" className="mt-2">
            {t('infoExcelContent')}
          </Text>
        </InlineMessage>
      </div>
    </div>
  );
};

const useDocumentParams = () => {
  const params = useParams<{ documentId: string; languageId: string }>();
  const parsedParams = useMemo(
    () => ({ documentId: parseInt(params.documentId), languageId: parseInt(params.languageId) }),
    [params]
  );
  return parsedParams;
};
