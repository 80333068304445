import { find, isEmpty } from 'lodash';
import { useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import {
  useAddLanguagesToDocumentsMutation,
  useGetAvailableDocumentLanguagesQuery,
  useGetLanguagesQuery,
  useGetTranslatorsQuery,
  useInviteTranslatorMutation
} from '@/redux/api';
import { AddLanguagesToDocumentsRequestQueryArgs, LanguagesDto, LanguageSettingDto } from '@/redux/api/types';
import { AddLanguageTranslator } from '@/routes/Document/components/AddLanguageTranslator';
import { AddLanguageTranslatorType } from '@/routes/Document/components/AddLanguageTranslator/types';
import { LanguageSearchBox } from '@/routes/Document/components/LanguageSearchBox';
import { LanguageGroup, LanguageOption, PartialTranslatorDto } from '@/routes/Document/types';
import { createTargetLanguageGroups, removeDuplicateLanguages } from '@/routes/Document/utils';
import { to } from '@/utils/awaitToJs';

export interface AddLanguageModalProps extends ModalProps {
  documentIds: number[];
  isBatchOperation?: boolean;
  onConfirm?: () => void;
  onError?: () => void;
}
export interface AddLanguageTranslatorForm {
  language: AddLanguageTranslatorType[];
}

export const AddLanguageModal = (props: AddLanguageModalProps) => {
  const { documentIds, isBatchOperation = false, onConfirm, onError, children, ...modalProps } = props;
  const { t: tDocument } = useTranslation('document');

  // Fetch all available languages if this is a batch operation, otherwise use the languages from the current document
  const { data: documentLanguages } = useGetAvailableDocumentLanguagesQuery(documentIds[0], {
    skip: isBatchOperation
  });
  const { data: languages } = useGetLanguagesQuery(undefined, { skip: !isBatchOperation });
  const availableLanguages: LanguagesDto | undefined = isBatchOperation ? languages : documentLanguages;

  const [inviteTranslator] = useInviteTranslatorMutation();
  const [addLanguagesToDocuments] = useAddLanguagesToDocumentsMutation();
  const { data: translators } = useGetTranslatorsQuery({});

  const { currentAccount } = useCurrentAccount();
  const [defaultTranslators, storeDefaultTranslators] = useLocalStorage<LanguageSettingDto[] | undefined>(
    'defaultTranslators',
    undefined
  );

  const [selectedItems, setSelectedItems] = useState<LanguageOption[] | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const languageGroups: LanguageGroup[] = availableLanguages
    ? createTargetLanguageGroups(availableLanguages, tDocument)
    : [];

  const { control, handleSubmit, watch, reset } = useForm<AddLanguageTranslatorForm>({
    defaultValues: {
      language: []
    }
  });
  const { fields, append, remove, update } = useFieldArray<AddLanguageTranslatorForm, 'language', 'id'>({
    name: 'language',
    control
  });

  const onSubmit: SubmitHandler<AddLanguageTranslatorForm> = async (data, e) => {
    e?.preventDefault();

    const languages: LanguageSettingDto[] = data.language.map((l) => ({
      languageId: parseInt(l.languageId),
      accountIds: l.translators.map((t) => t.id)
    }));

    // remove duplicate translators and save them in localstorage
    const updatedTranslators = defaultTranslators ?? [];

    storeDefaultTranslators(removeDuplicateLanguages([...updatedTranslators, ...languages]));

    const body: AddLanguagesToDocumentsRequestQueryArgs = {
      documentIds,
      languages
    };

    const [error] = await to(addLanguagesToDocuments(body).unwrap());
    if (!error) {
      onConfirm?.();
    } else {
      onError?.();
    }
    handleOnOpenChange(false);
  };

  const handleAddItem = (items?: LanguageOption[]) => {
    setSelectedItems(items);

    // set current account as default translator
    const currentTranslator: PartialTranslatorDto = {
      id: currentAccount.id,
      avatarUrl: currentAccount.avatarUrl || '',
      email: currentAccount.email,
      fullName: `${currentAccount.fullName} (${tDocument('you')})`
    };

    const getTranslatorInfo = (language: LanguageOption) =>
      find(defaultTranslators, (t) => String(t.languageId) === language.id);

    const getTranslatorCompleteInfo = (accountIds: number[]) =>
      accountIds
        .map((accountId) => {
          // accountIds should match translators and also our account
          return find([currentTranslator, ...(translators?.translators ?? [])], { id: accountId });
        })
        .filter((t): t is PartialTranslatorDto => t !== undefined);

    if (items) {
      const newItems = items.map((language) => {
        if (defaultTranslators && !isEmpty(defaultTranslators)) {
          // search if there're any saved translators associated with the language
          const selectedLanguageTranslator = getTranslatorInfo(language);

          if (selectedLanguageTranslator !== undefined) {
            // for each translator id get translator data
            const translatorsCompleteInfo = getTranslatorCompleteInfo(selectedLanguageTranslator.accountIds);

            if (!isEmpty(translatorsCompleteInfo)) {
              return {
                languageId: String(language.id),
                language: language,
                translators: [...translatorsCompleteInfo]
              };
            }
          }
        }

        return {
          languageId: String(language.id),
          language: language,
          translators: [currentTranslator]
        };
      });

      // Iterate through new items and append if not already present
      newItems.forEach((newItem) => {
        const exists = fields.some((field) => field.languageId === newItem.languageId);

        if (!exists) {
          append(newItem);
        }
      });

      // get deselected items indexes
      const indexes = fields.reduce((acc, field, index) => {
        const exists = items.some((item) => item.id.toString() === field.languageId);
        if (!exists) {
          acc.push(index);
        }
        return acc;
      }, [] as number[]);

      if (!isEmpty(indexes)) {
        // remove languages
        remove(indexes);
      }
    }
  };

  const onAddTranslators = (translators: PartialTranslatorDto[], language: LanguageOption, index: number) => {
    update(index, {
      languageId: language.id,
      language: language,
      translators
    });
  };

  const onAddNewTranslator = async (email: string, language: LanguageOption, index: number) => {
    const [, data] = await to(inviteTranslator({ email, languageId: parseInt(language.id) }).unwrap());

    const translators = watch(`language.${index}.translators`);

    if (data?.translatorAccountId) {
      const pendingTranslator: PartialTranslatorDto = {
        id: data.translatorAccountId,
        email,
        fullName: '',
        avatarUrl: ''
      };
      update(index, {
        languageId: language.id,
        language,
        translators: [...translators, pendingTranslator]
      });
    }
  };

  const handleRemoveLanguage = (languageId: string, index: number) => {
    setSelectedItems((prev) => prev?.filter((p) => p.id !== languageId));
    remove(index);
  };

  const handleOnOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      setSelectedItems([]);
      reset();
      remove();
    }
  };

  const disabled = isEmpty(selectedItems) || fields.some((field) => field.translators.length === 0);
  const documentsCount = documentIds.length;

  return (
    <Modal.Root {...modalProps} onOpenChange={handleOnOpenChange} open={isOpen}>
      <RequireSubscription>
        <Modal.Trigger>{children}</Modal.Trigger>
      </RequireSubscription>

      {/* prevent closing modal when click outside */}
      <Modal.Content size="xl" className="bg-gray-50" onInteractOutside={(e) => e.preventDefault()}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-7">
          <Modal.Header>
            <Show
              when={isBatchOperation}
              fallback={<Modal.Title size="md">{tDocument('addLanguagesToDocument')}</Modal.Title>}
            >
              <Modal.Title size="md">{tDocument('addTargetLanguages')}</Modal.Title>
              <Modal.Description size="md">
                <Show
                  when={documentsCount === 1}
                  fallback={tDocument('youAreAddingTargetLanguagesToCountDocuments', {
                    count: documentsCount
                  })}
                >
                  {tDocument('youAreAddingTargetLanguagesToOneDocument')}
                </Show>
              </Modal.Description>
            </Show>
          </Modal.Header>

          <div className="flex flex-col gap-3">
            <LanguageSearchBox
              defaultValues={selectedItems}
              languageGroups={languageGroups}
              multipleDocuments={false}
              buttonLabel={tDocument('selectLanguages')}
              className="w-1/2"
              onAddLanguage={handleAddItem}
            />
            {!isEmpty(fields) ? (
              <div className="flex h-80 flex-col gap-4 overflow-y-scroll rounded-lg border border-gray-200 bg-white p-3">
                {fields.map((item, index) => (
                  <Controller
                    key={item.id}
                    render={({ field }) => (
                      <AddLanguageTranslator
                        {...field}
                        item={item}
                        hasMultipleDocuments={false}
                        onAddTranslator={(translators) => onAddTranslators(translators, item.language, index)}
                        onAddNewTranslator={(email) => onAddNewTranslator(email, item.language, index)}
                        onRemoveLanguage={() => handleRemoveLanguage(item.languageId, index)}
                      />
                    )}
                    name={`language.${index}.languageId`}
                    control={control}
                  />
                ))}
              </div>
            ) : (
              <div className="flex h-80 flex-col items-center justify-center gap-2 rounded-lg border border-gray-200 bg-white px-11 py-3 text-center">
                <AccessibleIcon
                  label="ri-search-line"
                  icon="ri-search-line"
                  className="text-3xl text-typography-tertiary"
                />
                <Text size="sm" color="tertiary" weight="bold">
                  {tDocument('searchTargetLanguage')}
                </Text>
                <Text size="sm" color="tertiary" weight="regular">
                  {tDocument('typeTheLanguageNameInTheSearchBar')}
                </Text>
              </div>
            )}
          </div>

          <Modal.Footer className="flex w-full flex-row items-center justify-center gap-9">
            <Modal.Close>
              <Button color="secondary" variant="surface" className="w-full">
                {tDocument('cancel')}
              </Button>
            </Modal.Close>
            <Button color="primary" className="w-full" disabled={disabled}>
              <Show when={isBatchOperation} fallback={tDocument('confirmLanguages')}>
                {tDocument('addLanguages')}
              </Show>
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
