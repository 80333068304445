import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITeamMemberDto } from '../../api/types';
import { INITIAL_STATE } from './constants';
import { IPresenceState, ITeamMemberPresence } from './types';

export const presenceSlice = createSlice({
  name: 'presence',
  initialState: INITIAL_STATE,
  reducers: {
    addPresenceMember: (state: IPresenceState, { payload }: PayloadAction<ITeamMemberDto>) => {
      state.activeMembers[payload.id] = { teamMember: payload } as ITeamMemberPresence;
    },
    removePresenceMember: (state: IPresenceState, { payload }: PayloadAction<number>) => {
      if (state.activeMembers[payload]) {
        delete state.activeMembers[payload];
      }
    },
    syncPresenceMember: (state: IPresenceState, { payload }: PayloadAction<ITeamMemberDto[]>) => {
      payload.forEach((t) => {
        state.activeMembers[t.id] = { teamMember: t } as ITeamMemberPresence;
      });
    },
    removeMemberFromPosition: (
      state: IPresenceState,
      { payload }: PayloadAction<{ teamMember: ITeamMemberDto; paragraphId?: number }>
    ) => {
      const { teamMember, paragraphId } = payload;
      if (state.activeMembers[teamMember.id] && state.activeMembers[teamMember.id].paragraphId === paragraphId) {
        state.activeMembers[teamMember.id].paragraphId = undefined;
      }
    },
    addMemberFromPosition: (
      state: IPresenceState,
      { payload }: PayloadAction<{ teamMember: ITeamMemberDto; paragraphId?: number }>
    ) => {
      const { teamMember, paragraphId } = payload;
      const activeMember = state.activeMembers[teamMember.id];
      if (activeMember) {
        activeMember.paragraphId = paragraphId;
        activeMember.expireAt = new Date(new Date().getTime() + 60 * 5 * 1000).toISOString(); // Expire in 5min
      }
    }
  }
});

export const {
  addPresenceMember,
  removePresenceMember,
  syncPresenceMember,
  removeMemberFromPosition,
  addMemberFromPosition
} = presenceSlice.actions;
