import { useCombobox, UseComboboxReturnValue, useMultipleSelection } from 'downshift';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, some } from 'lodash';

import Combobox from '@/components/v2/Combobox';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import Avatar from '@/components/v2/Avatar';
import { Text } from '@/components/v2/Text';
import { Checkbox } from '@/components/v2/Checkbox';
import { Button } from '@/components/v2/Button';
import { validateEmail } from '@/utils/email';
import { Show } from '@/components/v2/Show';
import { getFallbackName } from '@/routes/Document/utils';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { PartialTranslatorDto } from '@/routes/Document/types';
import { getFilteredTranslators } from '@/utils/translators';

interface TranslatorSearchBoxProps {
  options: PartialTranslatorDto[];
  defaultValues: PartialTranslatorDto[];
  buttonLabel?: string;
  onAddTranslators: (selectedItems?: PartialTranslatorDto[]) => void;
  onAddNewTranslator: (email?: string) => void;
}

export const TranslatorSearchBox = (props: TranslatorSearchBoxProps) => {
  const { t } = useTranslation('document');
  const { options, defaultValues, buttonLabel = t('addTranslators'), onAddTranslators, onAddNewTranslator } = props;

  const [inputSearch, setInputSearch] = useState<string | undefined>();
  const [selectedItems, setSelectedItems] = useState<PartialTranslatorDto[]>();
  const { getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            setSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    }
  });

  const state: UseComboboxReturnValue<PartialTranslatorDto> = useCombobox({
    items: options,
    itemToString(item) {
      return item ? item.fullName : '';
    },
    inputValue: inputSearch,
    selectedItem: null,
    stateReducer(_state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            // keep the menu open after selection. only if is already opened
            isOpen: state.isOpen,
            // with the first option highlighted.
            highlightedIndex: 0
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          // added state.isOpen check as workaround to prevent side-effects
          // we want to trigger add items only if dropdown is open
          if (newSelectedItem && Boolean(newSelectedItem) && state.isOpen) {
            if (selectedItems && selectedItems.some((s) => s.id === newSelectedItem.id)) {
              // need to pass the original object to removeSelectedItem otherwise it cannot match the index
              // therefore it doesn't remove the element
              const item = selectedItems?.find((item) => item.id === newSelectedItem.id);

              if (item) {
                removeSelectedItem(item);
              }
            } else {
              setSelectedItems([...(selectedItems || []), newSelectedItem]);
            }
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputSearch(newInputValue);
          break;
        default:
          break;
      }
    }
  });

  // if user remove an item from state, update selected items
  useEffect(() => {
    if (defaultValues !== undefined) {
      setSelectedItems(defaultValues);
    }
  }, [defaultValues]);

  const handleAddTranslators = () => {
    if (selectedItems) {
      onAddTranslators(selectedItems);
    }
  };

  const handleAddNewTranslator = (email?: string) => {
    setInputSearch('');
    onAddNewTranslator(email);
  };

  // compute filtered translators list based on input search criteria, current account email, and available data
  const filteredTranslators: PartialTranslatorDto[] = useMemo(() => {
    // filter translators based on input search or use all translators if no search criteria provided
    const filteredTranslators = inputSearch ? getFilteredTranslators(inputSearch)(options) : options;

    return filteredTranslators;
  }, [inputSearch, options]);

  const isInvitingTranslator = useMemo(() => {
    // check if inputSearch is not empty and is a valid email
    const isValidEmail = !!inputSearch && validateEmail(inputSearch);

    // check if inputSearch is not present in the translators array
    const isNotInTranslators = !some(options, (o) => o.email === inputSearch);

    // return true if it's a valid email and not present in the translators array, otherwise false
    return isValidEmail && isNotInTranslators;
  }, [inputSearch, options]);

  const renderItems = (renderedTranslators: PartialTranslatorDto[]) => {
    return renderedTranslators.map((translator) => {
      const index = options.indexOf(translator);

      return (
        <Combobox.Item
          key={translator.id}
          index={index}
          item={translator}
          className="w-auto max-w-max items-center gap-3"
        >
          <Checkbox
            checked={Boolean(selectedItems?.find((i) => i.email === translator.email))}
            onClick={(e) => e.preventDefault()}
          />
          <Avatar.Root key={translator.id} className="min-h-[40px] min-w-[40px]">
            <Avatar.Image src={translator.avatarUrl} />
            <Avatar.Fallback>{getFallbackName(translator.fullName ?? translator.email)}</Avatar.Fallback>
          </Avatar.Root>
          <div className="flex flex-col gap-0.5 overflow-hidden">
            <OverflowTooltip label={translator.fullName} side="right">
              <Text color="primary" size="sm">
                {translator.fullName}
              </Text>
            </OverflowTooltip>
            <OverflowTooltip label={translator.email} side="right">
              <Text color="tertiary" size="xs">
                {translator.email}
              </Text>
            </OverflowTooltip>
          </div>
        </Combobox.Item>
      );
    });
  };

  const renderSendInviteButton = (email?: string) => {
    return (
      <Button
        variant="solid"
        color="primary"
        className="mt-1 w-full"
        size="sm"
        type="button"
        // this is needed to prevent combobox state to trigger input blur event therefore closing the dropdown
        // tldr this is required to keep the menu opened
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => handleAddNewTranslator(email)}
      >
        {t('sendInvite')}
      </Button>
    );
  };

  return (
    <Combobox.Root state={state} placement="bottom-start">
      <Combobox.Trigger asChild>
        <div
          className="min-w-select-trigger flex w-1/2 gap-1 rounded-md border border-gray-200 bg-white px-5"
          onClick={(e) => e.preventDefault()}
        >
          <input
            {...state.getInputProps({
              ...getDropdownProps({ preventKeyAction: state.isOpen }),
              ...state.getToggleButtonProps(),
              value: inputSearch,
              onChange: (e) => {
                setInputSearch((e.target as HTMLInputElement).value);
              }
            })}
            placeholder={t('searchByNameOrEmail')}
            className="outline-none mr-auto flex-1 py-2 text-base text-typography-primary placeholder-typography-tertiary"
          />
          <AccessibleIcon label="ri-search-line" icon="ri-search-line" className="text-lg text-gray-600" />
        </div>
      </Combobox.Trigger>
      <Combobox.Content className="z-50 w-auto max-w-xs">
        <Combobox.ItemsContainer>
          <div className="flex justify-center py-2">
            <div className="flex w-60 flex-col gap-1 text-center">
              <Text size="sm" weight="bold" color="tertiary">
                {t('inviteYourCollaborators')}
              </Text>
              <Text size="sm" weight="regular" color="tertiary">
                {t('youCanInviteNewTranslatorsByTypingTheirEmail')}
              </Text>
            </div>
          </div>
          {/* if translators array is not empty or is not inviting a translator, show the divider */}
          <Show when={isNotEmpty(filteredTranslators) && !isInvitingTranslator}>
            <hr className="my-2 h-px w-full bg-gray-200"></hr>
          </Show>
          {renderItems(filteredTranslators)}
        </Combobox.ItemsContainer>
        <Show when={!isInvitingTranslator || !inputSearch} fallback={renderSendInviteButton(inputSearch)}>
          <Button
            variant="solid"
            color="primary"
            className="mt-1 w-full"
            size="sm"
            onClick={handleAddTranslators}
            type="button"
            disabled={!selectedItems || isEmpty(selectedItems)}
          >
            {buttonLabel}
          </Button>
        </Show>
      </Combobox.Content>
    </Combobox.Root>
  );
};
