import { CurrencyEnum } from '@/redux/api/types';

/* Format to dollar */
export const dollarFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol'
});

/* Format to euro */
export const euroFormat = Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 2
});

export const getCurrencyFormat = (currency: CurrencyEnum) => {
  switch (currency) {
    case CurrencyEnum.USD:
      return dollarFormat;

    case CurrencyEnum.EUR:
    default:
      return euroFormat;
  }
};
