import { TFunction } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Text } from '@/components/v2/Text';
import { ExportItemTypeEnum, LanguagePairMemoriesResponseDto } from '@/redux/api/types';
import { formatDecimal } from '@/utils/number';

import { FlagAndName } from './components/FlagAndName';
import { TranslationMemoriesTableRow } from './types';

export const createDataForTranslationMemoriesRows = (
  tTM: TFunction<'translationMemory'>,
  translationMemories?: LanguagePairMemoriesResponseDto
) => {
  const rows: TranslationMemoriesTableRow[] = [];

  if (translationMemories?.languagePairs) {
    for (let i = 0; i < translationMemories.languagePairs.length; i++) {
      const pair = translationMemories.languagePairs[i];
      const { sourceLanguage, targetLanguage } = pair;

      // Handle rows of languages dividers
      rows.push({
        source: (
          <div className="flex space-x-3">
            <FlagAndName name={sourceLanguage.name} flagCode={sourceLanguage.flagCode} />
            <AccessibleIcon label="ri-arrow-right-line" icon="ri-arrow-right-line" className="text-lg" />
            <FlagAndName name={targetLanguage.name} flagCode={targetLanguage.flagCode} />
          </div>
        ),
        segmentCount: null,
        translationMemoryId: undefined
      });

      // Handle rows of internal translations (show only if there are internal translations)
      if (pair.internalTranslationMemoriesCount > 0) {
        rows.push({
          source: (
            <Text color="tertiary" size="sm">
              {tTM('internalTranslations')}
            </Text>
          ),
          segmentCount: <Text size="sm">{formatDecimal(pair.internalTranslationMemoriesCount)}</Text>,
          translationMemoryId: undefined,
          kind: ExportItemTypeEnum.DOCUMENT,
          sourceLanguage: sourceLanguage,
          targetLanguage: targetLanguage
        });
      }

      // Handle rows of uploaded files
      for (let j = 0; j < pair.uploadedFiles.length; j++) {
        const { fileName: source, translationMemoriesCount, fileId, isDeleting } = pair.uploadedFiles[j];
        rows.push({
          source: <Text size="sm">{source}</Text>,
          segmentCount: <Text size="sm">{formatDecimal(translationMemoriesCount)}</Text>,
          translationMemoryId: fileId,
          kind: ExportItemTypeEnum.IMPORT,
          isDeleting
        });
      }
    }

    return rows;
  }

  return [];
};
