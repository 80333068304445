import { cva } from 'cva';

export const toggleVariant = cva(['rounded-3xl bg-primary-active ring-primary-selected hover:ring-2'], {
  variants: {
    size: {
      sm: 'h-5 w-10',
      md: 'h-6 w-12',
      lg: 'h-8 w-14'
    },
    checked: {
      false: 'bg-gray-200 hover:ring-0',
      true: ''
    }
  },
  defaultVariants: {
    size: 'md'
  }
});

export const knobVariant = cva(
  'will-change-transform block translate-x-2 transform rounded-full bg-white shadow-md transition-transform',
  {
    variants: {
      size: {
        sm: 'h-3 w-4',
        md: 'h-5 w-5',
        lg: 'h-6 w-6'
      },
      checked: {
        false: 'translate-x-1',
        true: 'translate-x-6'
      }
    },
    compoundVariants: [
      {
        size: 'sm',
        checked: true,
        className: 'translate-x-[1.4rem] sm:translate-x-[1rem] md:translate-x-[1.1rem]'
      }
    ],
    defaultVariants: {
      size: 'md'
    }
  }
);
