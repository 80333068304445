import { convertToRaw, EditorState } from 'draft-js';

import type { ICommentContentDto, IMentionRangeDto } from '@/redux/api/types';

export const convertEditorStateToCommentContent = (editorState: EditorState): ICommentContentDto => {
  const content = editorState.getCurrentContent();
  const text = content.getPlainText();
  const { blocks, entityMap } = convertToRaw(content);

  const mentionRanges = blocks[0].entityRanges.reduce((mentionRanges, { key, offset, length }) => {
    const { type, data } = entityMap[key];
    if (type !== 'mention') {
      return mentionRanges;
    }
    return [...mentionRanges, { offset, length, accountId: data.mention.id }];
  }, [] as IMentionRangeDto[]);

  return { text, mentionRanges };
};
