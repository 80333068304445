import { redokunApi } from './redokunApi';
import type { ISendFeedbackQueryArg } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const feedbackApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation<void, ISendFeedbackQueryArg>({
      query: ({ body }) => ({
        url: 'feedback',
        method: 'POST',
        body
      })
    })
  })
});

export const { useSendFeedbackMutation } = feedbackApi;

export const accountApiUtil = {
  ...feedbackApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(feedbackApi.util.updateQueryData)
};
