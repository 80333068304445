import { ISaveOnboardingQueryArg, ISurveyBoostrapResponseDto } from '@/redux/api/types';

import { redokunApi } from './redokunApi';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const onboardingApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    saveOnboardingSurveyInfo: builder.mutation<void, ISaveOnboardingQueryArg>({
      query: ({ body }) => ({ url: `onboarding/survey`, method: 'POST', body })
    }),
    getOnboardingSurveyInfo: builder.query<ISurveyBoostrapResponseDto, void>({
      query: () => 'bootstrap'
    })
  })
});

export const {
  useSaveOnboardingSurveyInfoMutation,
  useGetOnboardingSurveyInfoQuery,
  useLazyGetOnboardingSurveyInfoQuery
} = onboardingApi;

export const onboardingApiUtil = {
  ...onboardingApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(onboardingApi.util.updateQueryData)
};
