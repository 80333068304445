import { Descendant, Element, Text } from 'slate';

import { EPlaceholderType } from '@/redux/api/constants';
import { getXmlAttribsFromPlaceholder } from '@/utils/placeholder';
import { escapeXliff } from '@/utils/xliff';

import { EElementType } from '../constants';

export const valueToXliff = (value: Descendant[]): string => {
  const element = value[0] as Element;

  return element.children.reduce((xliff, child) => {
    if (Text.isText(child)) {
      return xliff.concat(escapeXliff(child.text));
    }

    if (Element.isElement(child) && child.type === EElementType.PLACEHOLDER_V1) {
      const attribs = getXmlAttribsFromPlaceholder(child.placeholder);
      if (!attribs) {
        return xliff;
      }

      // <x id="t1" ctype="TAG"/>
      const attribsString = attribsToString(attribs);
      return xliff.concat(`<x ${attribsString}/>`);
    }

    if (Element.isElement(child) && child.type === EElementType.PLACEHOLDER_V2) {
      if (child.placeholder.type === EPlaceholderType.TAG_PAIR) {
        const attribs = { id: child.placeholder.id, ctype: 'x-tag', 'x-desc': child.placeholder.label };

        if (child.tag === 'opening') {
          // <g id=\"1\" ctype=\"x-tag\" x-desc=\"1\"/>
          const attribsString = attribsToString(attribs);
          return xliff.concat(`<g ${attribsString}>`);
        }

        if (child.tag === 'closing') {
          return xliff.concat('</g>');
        }

        return xliff;
      }

      const attribs = {
        id: child.placeholder.id,
        ctype: child.placeholder.type === EPlaceholderType.MARKER ? 'x-marker' : 'x-tag',
        'x-desc': child.placeholder.label
      };

      // <x id=\"1\" ctype=\"x-tag\" x-desc=\"1\"/>
      const attribsString = attribsToString(attribs);
      return xliff.concat(`<x ${attribsString}/>`);
    }

    return xliff;
  }, '');
};

const attribsToString = (attribs: Record<string, string>) =>
  Object.entries(attribs).reduce((attribsString, [key, value]) => {
    const attrib = `${key}="${value}"`;
    return attribsString ? attribsString.concat(' ', attrib) : attrib;
  }, '');
