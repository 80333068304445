import { useEffect, useState } from 'react';

import { useOnline } from '@/hooks/useOnline';

import { RESTORE_TIMEOUT } from './constants';

export const useConnectionState = () => {
  const online = useOnline();
  const [restore, setRestore] = useState(false);

  useEffect(() => {
    if (!online) {
      setRestore(true);
    } else {
      const timeout = setTimeout(() => {
        setRestore(false);
      }, RESTORE_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [online]);

  return { online, restore };
};
