import { cva } from 'cva';

const breadcrumbLink = cva(
  'border-b border-transparent text-typography-primary transition hover:text-typography-secondary data-active:border-alpha-400 data-active:text-typography-tertiary',
  {
    variants: {
      size: {
        sm: 'text-sm leading-5',
        md: 'text-base leading-6',
        lg: 'text-xl leading-8'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

const breadcrumbSeparator = cva('text-alpha-400', {
  variants: {
    size: {
      sm: 'mx-1 text-sm leading-5',
      md: 'mx-2 text-base leading-6',
      lg: 'mx-3 text-xl leading-8'
    }
  },
  defaultVariants: {
    size: 'md'
  }
});

export { breadcrumbLink, breadcrumbSeparator };
