import { Channel } from 'pusher-js';
import { useCallback, useEffect, useState } from 'react';

import { usePusherContext } from './usePusherContext';

export const usePusherSubscribeToChannel = (channelName: string | null) => {
  const { pusher } = usePusherContext();
  const [channel, setChannel] = useState<Channel | null>(null);

  useEffect(() => {
    if (!channelName) {
      return;
    }

    // Subscribe to the channel and save it in the state
    const channel = pusher.subscribe(channelName);
    setChannel(() => channel);

    return () => {
      // Channel will be unsubscribed if there are no more callbacks
      // This behavior should be improved in the future
      const callbacksCount = Object.keys(channel.callbacks._callbacks).length;

      if (callbacksCount < 1) {
        // Unsubscribe from the channel and remove it from the state
        pusher.unsubscribe(channelName);
        setChannel(null);
      }
    };
  }, [pusher, channelName]);

  const bind = useCallback(
    <T>(eventName: string, callback: (data: T) => void, context?: unknown) =>
      channel?.bind(eventName, callback, context) ?? null,
    [channel]
  );

  const unbind = useCallback(
    <T>(eventName?: string, callback?: (data: T) => void, context?: unknown) =>
      channel?.unbind(eventName, callback, context) ?? null,
    [channel]
  );

  return { channel, bind, unbind };
};
