import { Slot } from '@radix-ui/react-slot';
import { ComponentPropsWithoutRef, ElementRef, forwardRef, SyntheticEvent, useCallback } from 'react';

import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { SubscriptionSuspendedReason } from '@/redux/api/constants';
import { useAppDispatch } from '@/redux/hooks';
import { openPaymentIssuesModal, openSubscriptionExpiredModal } from '@/redux/slices/modalsSlice';
import { composeEventHandlers } from '@/utils/composeEventHandlers';

/**
 * This component ensures that a user has an active subscription before performing a specific action.
 * If the user's subscription is suspended, it displays a modal explaining the suspension reason and provides steps to resolve it.
 * Built on the Radix `Slot` component, it uses the `asChild` pattern, allowing any valid element or component to be seamlessly wrapped.
 * It should be used as the outermost wrapper for any action that requires an active subscription.
 *
 * @example
 * ```
 * // Button
 * <RequireSubscription>
 *   <Button>Foo</Button>
 * </RequireSubscription>
 *
 * // ButtonLink
 * <RequireSubscription>
 *   <ButtonLink to="/foo">Foo</ButtonLink>
 * </RequireSubscription>
 *
 * // Modal
 * <Modal.Root>
 *   <RequireSubscription>
 *     <Modal.Trigger>
 *       <Button>Foo</Button>
 *     </Modal.Trigger>
 *   </RequireSubscription>
 *   <Modal.Content>Bar</Modal.Content>
 * </Modal.Root>
 *
 * // DropdownMenu
 * <DropdownMenu.Root>
 *   <DropdownMenu.Trigger>Foo</DropdownMenu.Trigger>
 *   <DropdownMenu.Content>
 *     <DropdownMenu.Item>Foo</DropdownMenu.Item>
 *     <RequireSubscription>
 *       <DropdownMenu.Item>Bar</DropdownMenu.Item>
 *     </RequireSubscription>
 *   </DropdownMenu.Content>
 * </DropdownMenu.Root>
 * ```
 */
const RequireSubscription = forwardRef<RequireSubscriptionElement, RequireSubscriptionProps>((props, ref) => {
  const { onChange, onClick, onSelect, ...slotProps } = props;

  const dispatch = useAppDispatch();

  const { currentAccount } = useCurrentAccount();
  const { subscriptionInfo } = currentAccount;

  const handleAction = useCallback(
    (e: Event | SyntheticEvent) => {
      const { isSuspended, suspensionReason, canResolveSuspension } = subscriptionInfo;

      if (isSuspended) {
        e.stopPropagation();
        e.preventDefault();

        switch (suspensionReason) {
          case SubscriptionSuspendedReason.PAYMENT_PROBLEMS: {
            dispatch(openPaymentIssuesModal({ canResolveSuspension }));
            break;
          }

          case SubscriptionSuspendedReason.SUBSCRIPTION_EXPIRED: {
            dispatch(openSubscriptionExpiredModal({ canResolveSuspension }));
            break;
          }
        }
      }
    },
    [dispatch, subscriptionInfo]
  );

  const handleChange = composeEventHandlers(onChange, handleAction);
  const handleClick = composeEventHandlers(onClick, handleAction);
  const handleSelect = composeEventHandlers(onSelect, handleAction);

  return <Slot {...slotProps} ref={ref} onChange={handleChange} onClick={handleClick} onSelect={handleSelect} />;
});
RequireSubscription.displayName = 'RequireSubscription';

type RequireSubscriptionElement = ElementRef<typeof Slot>;
type RequireSubscriptionProps = ComponentPropsWithoutRef<typeof Slot>;

export { RequireSubscription };
export type { RequireSubscriptionProps };
