import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { FormSubmit } from '@/components/v2/Form';
import { useToast } from '@/components/v2/Toast';
import { useGetBillingQuery } from '@/redux/api/billingApi';

import {
  PaymentMethodForm,
  PaymentMethodFormContent,
  PaymentMethodFormPayload,
  PaymentMethodFormSkeleton
} from './components/PaymentMethodForm';
import { TransactionDetails, TransactionDetailsSkeleton } from './components/TransactionDetails';
import { useCheckout, useCheckoutSearchParams } from './hooks';

const CheckoutPaymentMethod = () => {
  const history = useHistory();
  const location = useLocation();
  const [searchParams] = useCheckoutSearchParams();
  const { planCode, currency, billingFrequency } = searchParams;

  const { t: tCheckout } = useTranslation('checkout');

  const { data: billingData, isLoading: isBillingLoading } = useGetBillingQuery();
  const { startCheckout, startCheckoutResult, confirmPayment } = useCheckout();

  const { toast } = useToast();

  useEffect(() => {
    if (!planCode || !currency || !billingFrequency) {
      return;
    }

    startCheckout({ planCode, currency, billingFrequency });
  }, [billingFrequency, startCheckout, currency, planCode]);

  const isLoading = isBillingLoading || startCheckoutResult.isUninitialized || startCheckoutResult.isLoading;
  if (isLoading) {
    return (
      <div>
        <div className="grid grid-cols-2 items-start gap-8">
          <TransactionDetailsSkeleton />

          <PaymentMethodFormSkeleton />
        </div>

        <div className="mt-8 flex items-center justify-between gap-8">
          <ButtonLink href={{ ...location, pathname: '/checkout/billing' }} variant="text">
            <AccessibleIcon icon="ri-arrow-left-line" label="" />
            {tCheckout('back')}
          </ButtonLink>

          <Button disabled>{tCheckout('confirmTransaction')}</Button>
        </div>
      </div>
    );
  }

  if (!startCheckoutResult.data || !billingData) {
    return null;
  }

  const handleSubmit = async ({ data, cardNumber }: PaymentMethodFormPayload) => {
    const { error: confirmPaymentError } = await confirmPayment({
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: data.cardHolderName,
        email: billingData.billingEmail,
        address: {
          city: billingData.city,
          line1: billingData.address,
          postal_code: billingData.zipCode,
          state: billingData.countryName
        }
      }
    });
    if (confirmPaymentError) {
      toast({
        kind: 'error',
        title: tCheckout('paymentError'),
        description: confirmPaymentError.message || tCheckout('paymentErrorDescription')
      });
      return;
    }

    history.push('/settings/current-plan');
  };

  return (
    <PaymentMethodForm onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 items-start gap-8">
        <TransactionDetails
          planName={startCheckoutResult.data.planName}
          billingFrequency={startCheckoutResult.data.billingFrequency}
          currency={startCheckoutResult.data.currency}
          amount={startCheckoutResult.data.amount.unit}
          vatPercent={startCheckoutResult.data.vatPercent}
          vat={startCheckoutResult.data.vat.unit}
          total={startCheckoutResult.data.total.unit}
        />

        <PaymentMethodFormContent />
      </div>

      <div className="mt-8 flex items-center justify-between gap-8">
        <ButtonLink href={{ ...location, pathname: '/checkout/billing' }} variant="text">
          <AccessibleIcon icon="ri-arrow-left-line" label="" />
          {tCheckout('back')}
        </ButtonLink>

        <FormSubmit>{tCheckout('confirmTransaction')}</FormSubmit>
      </div>
    </PaymentMethodForm>
  );
};
CheckoutPaymentMethod.displayName = 'CheckoutPaymentMethod';

export { CheckoutPaymentMethod };
