import { ReactNode } from 'react';

import AppLayout from '@/components/layouts/AppLayout';

import { LeftMenu } from './LeftMenu';

export const SettingsLayout = ({ children }: { children?: ReactNode }) => {
  return (
    <AppLayout.Root>
      {/* Header */}
      <AppLayout.Navbar />

      {/* Content */}
      <div className="mx-auto flex max-w-8xl justify-between">
        <LeftMenu />
        <div className="flex-grow">{children}</div>
      </div>
    </AppLayout.Root>
  );
};
