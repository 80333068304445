import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { useToast } from '@/components/v2/Toast';
import { useDeleteLanguageTranslationMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';

export const DeleteLanguageModal = (props: DeleteLanguageModalProps) => {
  const { documentId, languageId, languageName, children, onDelete, ...modalProps } = props;

  const { t } = useTranslation('document');
  const { toast } = useToast();

  const [deleteLanguage, { isLoading, isSuccess }] = useDeleteLanguageTranslationMutation();

  const handleDeleteClick = async () => {
    const [error] = await to(deleteLanguage({ documentId, languageId }).unwrap());

    if (!error) {
      toast({ title: t('languageDeletedSuccessfully'), kind: 'success' });
      onDelete?.();
    }
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="sm">
        <Modal.Header>
          <Modal.Title size="md">{t('deleteLanguageQuestion')}</Modal.Title>
          <Modal.Description size="sm">
            <Trans
              i18nKey="document:youAreDeletingLanguage"
              values={{ language: languageName }}
              components={{ b: <b /> }}
            />
          </Modal.Description>
          <Modal.Description size="sm">{t('whenYouDeleteLanguageTranslationMemoriesWillBeDeleted')}</Modal.Description>
        </Modal.Header>

        <Modal.Footer>
          <Button color="negative" onClick={handleDeleteClick} disabled={isLoading || isSuccess}>
            {t('deleteLanguage')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {t('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface DeleteLanguageModalProps extends ModalProps {
  documentId: number;
  languageId: number;
  languageName: string;
  onDelete?: () => void;
}
