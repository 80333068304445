import { Trans } from 'react-i18next';

import DataTable from '@/components/v2/DataTable';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { DocumentListItemDto, SourceDocumentDtoStatus } from '@/redux/api/types';
import { isFolderItem } from '@/routes/DocumentList/components/DocumentListContent/utils';
import { standardDateTimeFormat } from '@/utils/date';

export const ExpandedFileNameCell = () => {
  const { cell } = DataTable.useCellContext<DocumentListItemDto, DocumentListItemDto>('FileNameCell');
  const { original: data } = cell.row;

  if (isFolderItem(data)) return null;

  return (
    <div className="flex h-full flex-col justify-start">
      <Text size="sm" weight="medium" color="tertiary">
        <Trans
          i18nKey="documentList:uploadDate"
          values={{
            date: standardDateTimeFormat(data.item.createdAt)
          }}
          components={{ 1: <span className="text-typography-secondary"></span> }}
        />
      </Text>

      <Show when={data.item.status === SourceDocumentDtoStatus.INDEXED}>
        <Text size="sm" weight="medium" color="tertiary">
          <Trans
            i18nKey="documentList:wordCount"
            values={{
              count: data.item.wordCount
            }}
            components={{ 1: <span className="text-typography-secondary"></span> }}
          />
        </Text>
      </Show>

      <Text size="sm" weight="medium" color="tertiary">
        <Trans
          i18nKey="documentList:sourceLanguage"
          values={{
            language: data.item.language.name
          }}
          components={{ 1: <span className="text-typography-secondary"></span> }}
        />
      </Text>
    </div>
  );
};
