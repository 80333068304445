import { first as _first, map as _map, values as _values } from 'lodash';

import { Avatar } from '@/components/v1/Avatar';
import { Tooltip } from '@/components/v1/Tooltip';
import { useAppSelector } from '@/redux/hooks';
import { classNames } from '@/utils/classNames';

export const ActiveMembers = () => {
  const { activeMembers } = useAppSelector((state) => state.presence);
  const teamMembersColors = useAppSelector((state) => state.teamMembers.colors);

  return (
    <div className="relative flex -space-x-3">
      {_map(_values(activeMembers), ({ teamMember }, memberIndex, members) => {
        const zIndex = members.length - memberIndex;
        const borderColorClassName = teamMembersColors[teamMember.id]?.border;
        const alt = _first(teamMember.fullName);
        const title = `${teamMember.fullName} is viewing the document`;

        return (
          <Tooltip key={teamMember.id} title={title} placement="top" containerStyle={{ zIndex }}>
            <Avatar
              src={teamMember.avatarUrl ?? undefined}
              alt={alt}
              className={classNames('border-2 shadow-lg', borderColorClassName)}
              size="lg"
            />
          </Tooltip>
        );
      })}
    </div>
  );
};
