import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { classNames } from '@/utils/classNames';

import { Spinner } from '../Spinner';
import type { TButtonLinkProps } from './types';
import { getButtonClassName } from './utils';

export const ButtonLink: FunctionComponent<TButtonLinkProps> = ({
  btnType,
  children,
  className,
  containerClassName,
  color,
  disabled,
  icon,
  size,
  loading,
  tabIndex,
  href,
  ...htmlAProps
}) => {
  const buttonClassName = classNames(getButtonClassName({ color, size, btnType, disabled, className, loading }), {
    'pointer-events-none opacity-50': disabled
  });

  const linkComponent =
    href.startsWith('https://') || href.startsWith('http://') ? (
      <a href={href} className={buttonClassName} tabIndex={disabled || loading ? -1 : tabIndex} {...htmlAProps}>
        {icon && <span className="mr-2">{icon}</span>}
        <span>{children}</span>
      </a>
    ) : (
      <Link to={href} className={buttonClassName} tabIndex={disabled || loading ? -1 : tabIndex} {...htmlAProps}>
        {icon && <span className="mr-2">{icon}</span>}
        <span>{children}</span>
      </Link>
    );

  return (
    <Spinner enabled={loading} containerClassName={classNames('inline-block', containerClassName)}>
      <span className={classNames('block', { 'cursor-not-allowed': disabled })}>{linkComponent}</span>
    </Spinner>
  );
};
