// TODO: Waiting for working APIs
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/v2/Text';
import { Box } from '@/components/v2/Box';
// import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
// import { isEmpty } from 'lodash';
// import DataTable, { DataTableColumnDef } from '@/components/v2/DataTable';
// import { useGetBillingsQuery } from '@/redux/api/billingApi';
// import { GetBillingDetailResponseDto } from '@/redux/api/types';

export const SettingsCurrentPlanSubscriptionHistory = () => {
  const { t: tSettings } = useTranslation('settings');
  // const { currentAccount } = useCurrentAccount();

  // const {
  //   data: billingsData,
  //   isLoading: getBillingsIsLoading,
  //   isFetching: getBillingsIsFetching,
  //   isSuccess: getBillingsIsSuccess
  // } = useGetBillingsQuery();

  // const columns: DataTableColumnDef<GetBillingDetailResponseDto>[] = [
  //   {
  //     id: 'startDate',
  //     accessorKey: 'item.',
  //     header: () => tSettings('translator'),
  //     cell: ({ row }) => {
  //       // const { fullName, email, avatarUrl } = row.original;
  //
  //       return <div className="flex items-center gap-2"></div>;
  //     }
  //   }
  // ];

  const table = () => {
    // if (getBillingsIsSuccess) {
    //   if (isEmpty(billingsData.invoi)) {
    //     return <div className="py-4 text-center">{tSettings('noItemsToDisplay')}</div>;
    //   } else {
    //     return (
    //       <DataTable.Root columns={columns} data={translatorsData?.translators}>
    //         <DataTable.Container>
    //           <DataTable.Header />
    //           <DataTable.Body />
    //         </DataTable.Container>
    //       </DataTable.Root>
    //     );
    //   }
    // }
    return null;
  };

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsCurrentPlanSubscriptionHistory')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text color="primary" size="xl" weight="bold">
            {tSettings('subscriptionHistory')}
          </Text>
        </div>

        <div className="mt-6 ">
          {/* History */}
          <Box>{table()}</Box>
        </div>
      </div>
    </div>
  );
};
