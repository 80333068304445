import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { HELP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { Show } from '@/components/v2/Show';
import InlineMessage from '@/components/v2/InlineMessage';

import { CreateTeam } from './components/CreateTeam';
import { InviteManager } from './components/InviteManager';
import { TeamDetails } from './components/TeamDetails';
import { ManagersTable } from './components/ManagersTable';

export const SettingsManagers: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');

  const { currentAccount } = useCurrentAccount();

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsManagers')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('managers')}
          </Text>

          <Button variant="text" color="primary" asChild>
            <a href={`${HELP_URL}/article/23-working-in-a-team`} target="_blank" rel="noreferrer">
              <AccessibleIcon className="text-xl" label="ri-lightbulb-fill" icon="ri-lightbulb-fill" />
              {tSettings('learnMoreAboutWorkingInATeam')}
            </a>
          </Button>
        </div>

        {/* Forms */}
        <div className="grid grid-cols-2 gap-6">
          <CreateTeam />
          <TeamDetails />
          <InviteManager />
        </div>

        {/* Table */}
        <Show
          when={currentAccount.subscriptionInfo.isInATeam}
          fallback={
            <InlineMessage.Root type="informational" orientation="horizontal">
              <InlineMessage.Title className="text-blue-500">
                {tSettings('noTeamInlineMessageTitle')}
              </InlineMessage.Title>
              <div className="prose mt-3 text-typography-secondary">
                <Markdown>{tSettings('noTeamInlineMessageText')}</Markdown>
              </div>
            </InlineMessage.Root>
          }
        >
          <ManagersTable />
        </Show>
      </div>
    </div>
  );
};
