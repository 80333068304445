import { isNumber as _isNumber } from 'lodash';

import type { TAppDispatch, TAppGetState } from '../../store/types';
import { languageApiUtil } from '../languageApi';
import type { IGetTranslationListQueryArg } from '../types';
import { mergePatchCollections } from '../utils/mergePatchCollections';

export const paragraphCommentsCountersChanged =
  (
    args: Pick<IGetTranslationListQueryArg, 'documentId' | 'languageId'>,
    payload: {
      paragraphId: number;
      readCommentsCount?: number;
      totalCommentsCount?: number;
    }
  ) =>
  (dispatch: TAppDispatch, getState: TAppGetState) => {
    const entries = languageApiUtil
      .selectInvalidatedBy(getState(), ['translationList'])
      .filter(
        (
          entry
        ): entry is typeof entry & { endpointName: 'getTranslationList'; originalArgs: IGetTranslationListQueryArg } =>
          entry.endpointName === 'getTranslationList' &&
          entry.originalArgs?.documentId === args.documentId &&
          entry.originalArgs?.languageId === args.languageId
      );

    const patches = entries.map(({ endpointName, originalArgs }) =>
      dispatch(
        languageApiUtil.safeUpdateQueryData(endpointName, originalArgs, (data) => {
          data.paragraphs.forEach((paragraph) => {
            if (paragraph.id !== payload.paragraphId) {
              return;
            }

            paragraph.totalCommentsCount = payload.totalCommentsCount ?? paragraph.totalCommentsCount;
            if (_isNumber(payload.readCommentsCount)) {
              paragraph.readCommentsCount = payload.readCommentsCount;
            } else if (paragraph.readCommentsCount > paragraph.totalCommentsCount) {
              paragraph.readCommentsCount = paragraph.totalCommentsCount;
            }
            paragraph.unreadCommentsCount = paragraph.totalCommentsCount - paragraph.readCommentsCount;
          });
        })
      )
    );

    return mergePatchCollections(...patches);
  };
