import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { useToast } from '@/components/v2/Toast';
import { usePatchCurrentAccountMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { Spinner } from '@/components/v1/Spinner';
import { SettingsDTODeeplFormalityOptions } from '@/redux/api/types';

import { WebEditor } from './form/WebEditor';
import { MachineTranslation } from './form/MachineTranslation';
import { FileUpload } from './form/FileUpload';

export const SettingsAdvancedSettings: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { currentAccount } = useCurrentAccount();
  const { toast } = useToast();
  const [patchCurrentAccount, { isLoading: patchCurrentAccountIsLoading }] = usePatchCurrentAccountMutation();

  const formSchema = z.object({
    // WebEditor
    autoloadMt: z.boolean(),
    enableLiveCharacterCount: z.boolean(),
    enableTmSourceIndicator: z.boolean(),
    enableSearchSimilarity: z.boolean(),
    // MachineTranslation
    deeplFormality: z.union([
      z.literal(SettingsDTODeeplFormalityOptions.less),
      z.literal(SettingsDTODeeplFormalityOptions.more),
      z.literal(SettingsDTODeeplFormalityOptions.default)
    ]),
    // FileUpload
    skipSoftReturnsOnUpload: z.boolean(),
    convertSpecialSpaces: z.boolean(),
    enableFilterSelection: z.boolean()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      // WebEditor
      autoloadMt: currentAccount.settings.autoloadMt,
      enableLiveCharacterCount: currentAccount.settings.enableLiveCharacterCount,
      enableTmSourceIndicator: currentAccount.settings.enableTmSourceIndicator,
      enableSearchSimilarity: currentAccount.settings.enableSearchSimilarity,
      // MachineTranslation
      deeplFormality: currentAccount.settings.deeplFormality ?? undefined,
      // FileUpload
      skipSoftReturnsOnUpload: currentAccount.settings.skipSoftReturnsOnUpload,
      convertSpecialSpaces: currentAccount.settings.convertSpecialSpaces,
      enableFilterSelection: currentAccount.settings.enableFilterSelection
    }
  });
  const { handleSubmit: handleSubmitPatchCurrentAccount } = form;

  const onSave = async (values: z.infer<typeof formSchema>) => {
    const [err] = await to(patchCurrentAccount({ settings: { ...values } }).unwrap());

    if (!err) {
      toast({ title: tSettings('savedSuccessfully'), kind: 'success' });
    }
  };

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsAdvancedSettings')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('advancedSettings')}
          </Text>

          <Button
            color="primary"
            disabled={patchCurrentAccountIsLoading}
            onClick={handleSubmitPatchCurrentAccount(onSave)}
          >
            {patchCurrentAccountIsLoading ? <Spinner size="sm" /> : null}
            {tSettings('save')}
          </Button>
        </div>

        {/* Forms */}
        <FormProvider {...form}>
          <form className="grid grid-cols-1 gap-6">
            <WebEditor />
            <MachineTranslation />
            <FileUpload />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
