import { startsWith } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { For } from '@/components/v2/For';
import { Navbar } from '@/components/v2/Navbar';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { classNames } from '@/utils/classNames';

import { AccountDropdownMenu } from './AccountDropdownMenu';
import { LowResourceTagWarning } from './LowResourcetagWarning';
import type { AppLayoutContentProps, AppLayoutNavbarProps, AppLayoutProps } from './types';
import { useAppLayoutLinks } from './useAppLayoutLinks';

const AppLayout = (props: AppLayoutProps) => {
  const { title, children } = props;
  const { currentAccount } = useCurrentAccount();

  const isAdminLoginAs = currentAccount?.impersonateInfo?.isAdminLoginAs;
  return (
    <>
      <Helmet>{title !== undefined && <title>{title}</title>}</Helmet>

      <div className={classNames('min-h-screen bg-surface-background', isAdminLoginAs ? 'logged-in-as' : '')}>
        {children}
      </div>
    </>
  );
};
AppLayout.displayName = 'AppLayout';

const AppLayoutNavbar = (props: AppLayoutNavbarProps) => {
  const { sticky = true, fullWidth } = props;
  const { currentAccount } = useCurrentAccount();

  const { hasLowWordBalance, exportedWordBalance } = currentAccount.subscriptionInfo;
  const mtRateLimiting = currentAccount.mtRateLimiting;
  const isLowResources = hasLowWordBalance || mtRateLimiting.shouldWarnUser;

  const location = useLocation();
  const links = useAppLayoutLinks();

  return (
    <Navbar sticky={sticky} fullWidth={fullWidth}>
      <div className="ml-7 flex flex-1 flex-row items-stretch justify-between">
        <div className=" flex flex-1 items-center gap-7">
          <For each={links}>
            {(link) => {
              const isActive = startsWith(location.pathname, link.href);

              return (
                <ButtonLink
                  key={link.key}
                  href={link.href}
                  variant="text"
                  className={classNames(
                    'group h-full gap-2 border-b-2 transition hover:text-typography-primary',
                    isActive
                      ? 'border-primary-active text-typography-primary'
                      : 'border-transparent text-typography-secondary'
                  )}
                >
                  <AccessibleIcon
                    icon={link.icon}
                    label={link.label}
                    className={classNames(
                      'text-xl transition group-hover:text-typography-primary',
                      isActive ? 'text-typography-primary' : 'text-typography-tertiary'
                    )}
                  />
                  {link.label}
                </ButtonLink>
              );
            }}
          </For>

          <Show when={isLowResources}>
            <div className="ml-auto mr-3">
              <LowResourceTagWarning
                hasLowWordBalance={hasLowWordBalance}
                shouldWarnUser={mtRateLimiting.shouldWarnUser}
                exportedWordBalance={exportedWordBalance}
                mtWordBalance={mtRateLimiting.threshold - mtRateLimiting.used}
              />
            </div>
          </Show>
        </div>
        <AccountDropdownMenu />
      </div>
    </Navbar>
  );
};
AppLayoutNavbar.displayName = 'AppLayoutNavbar';

const AppLayoutContent = (props: AppLayoutContentProps) => {
  const { children } = props;

  return <div className="mx-auto h-full w-full max-w-8xl space-y-7 px-16 py-12">{children}</div>;
};
AppLayoutContent.displayName = 'AppLayoutContent';
export { AppLayout, AppLayoutContent, AppLayoutNavbar };
