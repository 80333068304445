import * as ScrollArea from '@radix-ui/react-scroll-area';

import { AccordionNested, AccordionNestedProps } from '@/components/v2/AccordionNested';
import { classNames } from '@/utils/classNames';

import s from './FolderBrowser.module.css';

interface FolderBrowserProps extends AccordionNestedProps {}

const FolderBrowser = ({
  nestedFolderStructure,
  defaultValue,
  value,
  isCreatingNewFolder,
  selected,
  disabledIds,
  onSelectItem,
  onValueChange,
  onFolderCreated
}: FolderBrowserProps) => {
  return (
    <ScrollArea.Root className="h-56 max-h-[224px] rounded-lg border border-alpha-200 bg-white px-3 py-2.5">
      {/* scroll-area-viewport class is needed to enable ellipsis on long text */}
      <ScrollArea.Viewport className={classNames(s['scroll-area-viewport'], 'h-full w-full')}>
        <AccordionNested
          nestedFolderStructure={nestedFolderStructure}
          defaultValue={defaultValue}
          value={value}
          selected={selected}
          isCreatingNewFolder={isCreatingNewFolder}
          disabledIds={disabledIds}
          onSelectItem={onSelectItem}
          onValueChange={onValueChange}
          onFolderCreated={onFolderCreated}
        />
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar>
        <ScrollArea.Thumb />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
};
export { FolderBrowser };
