import { cva } from 'cva';

import s from './Tabs.module.css';

const tabsStyle = cva(['tabs', s['tabs']]);

const tabsListStyle = cva([
  'tabs__list',
  s['tabs__list'],
  'flex w-[fit-content] flex-row gap-1 rounded-xl ring-1 ring-inset ring-gray-200'
]);

const tabsTriggerStyle = cva(
  [
    'tabs__trigger',
    s['tabs__trigger'],
    'inline-flex cursor-pointer flex-row items-center justify-center rounded-xl border-0.5 font-semibold leading-normal transition'
  ],
  {
    variants: {
      size: {
        sm: 'px-4 py-2 text-sm',
        md: 'px-5 py-2.5 text-base',
        lg: 'px-6 py-4 text-base'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

const tabsContentStyle = cva(['tabs__content', s['tabs__content']]);

export { tabsStyle, tabsListStyle, tabsTriggerStyle, tabsContentStyle };
