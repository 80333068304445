import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { NestedFolderStructureItem } from '@/components/v2/AccordionNested';
import { Button } from '@/components/v2/Button';
import { FolderBrowser } from '@/components/v2/FolderBrowser';
import Modal from '@/components/v2/Modal';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { DEV } from '@/config/app';
import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { useGetDocumentDetailQuery, useGetFoldersQuery, useUpdateDocumentPropertiesMutation } from '@/redux/api';
import { useGetTeamDetailsQuery } from '@/redux/api/teamApi';
import { FolderHierarchyDto, TeamDetailsResponseDto } from '@/redux/api/types';
import { DocumentOwnerSelect } from '@/routes/Document/components/DocumentSettingsModal/components/DocumentOwnerSelect';
import { to } from '@/utils/awaitToJs';

interface DocumentSettingsContentProps {
  documentId: number;
  onConfirm?: () => void;
}

export const DocumentSettingsContent = ({ documentId, onConfirm }: DocumentSettingsContentProps) => {
  const { t: tDocument } = useTranslation('document');
  const [updateDocument, { isLoading: isLoadingUpdate }] = useUpdateDocumentPropertiesMutation();
  const { data: folderData, isLoading: isLoadingFolders } = useGetFoldersQuery();

  const { data: documentDetail, isLoading: isLoadingDocumentDetail } = useGetDocumentDetailQuery(documentId);
  const { data: teamDetails, isLoading: isLoadingTeamDetails } = useGetTeamDetailsQuery();
  // list of accordion's folder ids
  const [openedAccordionIds, setOpenedAccordionIds] = useState([DOCUMENT_LIST_MOCK_ID]);

  const isLoading = isLoadingFolders || isLoadingDocumentDetail || isLoadingTeamDetails;
  const isDataAvailable = folderData !== undefined && documentDetail !== undefined && teamDetails !== undefined;
  const teamInfo: TeamDetailsResponseDto['members'] = teamDetails?.members || [];
  // if source document folder id is undefined means that the parent folder is document list
  const folderId = documentDetail?.sourceDocument.folderId || DOCUMENT_LIST_MOCK_ID;

  const formSchema: ZodType = z.object({
    parentFolderId: z.string(),
    documentOwnerId: z.number()
  });

  const values = teamInfo.length
    ? {
        parentFolderId: folderId,
        documentOwnerId: documentDetail?.sourceDocument.uploader.id
      }
    : undefined;

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: values
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { documentOwnerId, parentFolderId } = data;

    const parsedParentFolderId = isNaN(Number(parentFolderId)) ? null : parseInt(parentFolderId);
    const parsedDocumentOwnerId = isNaN(Number(documentOwnerId)) ? null : parseInt(documentOwnerId);

    if (parsedDocumentOwnerId !== null && Boolean(documentId)) {
      const [error] = await to(
        updateDocument({
          documentOwnerId: parsedDocumentOwnerId,
          parentFolderId: parsedParentFolderId,
          documentId
        }).unwrap()
      );
      if (!error) {
        onConfirm?.();
      }
    }
  };

  const transformFolders = (folders: FolderHierarchyDto[]): NestedFolderStructureItem[] => {
    return folders.map((folder) => ({
      id: String(folder.id),
      name: folder.name,
      hasFolderIcon: true,
      children: folder.children ? transformFolders(folder.children) : []
    }));
  };

  const nestedFolders: NestedFolderStructureItem[] = [
    {
      id: DOCUMENT_LIST_MOCK_ID,
      name: tDocument('documentList'),
      hasFolderIcon: false,
      children: folderData?.children ? transformFolders(folderData.children) : []
    }
  ];

  const disable = !isValid || isLoadingUpdate;

  return (
    <Show
      when={!isLoading}
      fallback={
        <div className="flex h-[478px] justify-center">
          <Spinner size="lg" />
        </div>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6">
        <Show when={isDataAvailable}>
          <Modal.Header>
            <Modal.Title>{tDocument('documentSettings')}</Modal.Title>
          </Modal.Header>

          <div className="space-y-4">
            <div className="space-y-2">
              <Text>{tDocument('parentFolder')}</Text>
              <Controller
                control={control}
                name="parentFolderId"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FolderBrowser
                    defaultValue={[DOCUMENT_LIST_MOCK_ID]}
                    value={openedAccordionIds}
                    nestedFolderStructure={nestedFolders}
                    selected={value}
                    isCreatingNewFolder={false}
                    onSelectItem={onChange}
                    onValueChange={setOpenedAccordionIds}
                  />
                )}
              />
            </div>

            <div className="space-y-2">
              <Text>{tDocument('documentOwner')}</Text>
              <Controller
                control={control}
                name="documentOwnerId"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  // we used a custom select since the radix one have some issues with dynamic values
                  // https://github.com/radix-ui/primitives/issues/2817
                  <DocumentOwnerSelect items={teamInfo} value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
          {DEV && <DevTool control={control} />}
          <Modal.Footer>
            <Button color="primary" type="submit" disabled={disable}>
              {tDocument('saveChanges')}
            </Button>
            <Modal.Close>
              <Button color="secondary" variant="surface">
                {tDocument('cancel')}
              </Button>
            </Modal.Close>
          </Modal.Footer>
        </Show>
      </form>
    </Show>
  );
};
