import { Trans, useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import CollapsibleInlineMessage from '@/components/v2/CollapsibleInlineMessage';
import { Show } from '@/components/v2/Show';
import { TeamJoinInviteDto } from '@/redux/api/types';

interface PendingTeamInvitationProps {
  pendingTeamJoinInvite: TeamJoinInviteDto;
  isLoading?: boolean;
  onAccept: (invitationId: number) => void;
  onIgnore: (invitationId: number) => void;
}

const PendingTeamInvitation = (props: PendingTeamInvitationProps) => {
  const { pendingTeamJoinInvite, isLoading, onAccept, onIgnore } = props;

  const { t: tDocumentList } = useTranslation('documentList');

  return (
    <CollapsibleInlineMessage.Root className="mt-6">
      <Show
        when={!isLoading}
        fallback={
          <div className="flex h-14 justify-center">
            <Spinner size="lg" />
          </div>
        }
      >
        <CollapsibleInlineMessage.Header>
          <AccessibleIcon
            className="text-2xl text-primary-active"
            label="information-icon"
            icon="ri-information-fill"
          />
          <CollapsibleInlineMessage.Title>
            <Trans
              i18nKey="documentList:userInvitedYouToJoinTeam"
              values={{
                user: pendingTeamJoinInvite.inviter.fullName,
                team: pendingTeamJoinInvite.teamName
              }}
              components={{ 1: <span className="font-bold"></span> }}
            />
          </CollapsibleInlineMessage.Title>
          <div className="ml-auto flex flex-row gap-2">
            <Button variant="surface" color="secondary" size="sm" onClick={() => onAccept(pendingTeamJoinInvite.id)}>
              {tDocumentList('accept')}
            </Button>
            <Button variant="surface" color="secondary" size="sm" onClick={() => onIgnore(pendingTeamJoinInvite.id)}>
              {tDocumentList('ignore')}
            </Button>
          </div>
        </CollapsibleInlineMessage.Header>
      </Show>
    </CollapsibleInlineMessage.Root>
  );
};
PendingTeamInvitation.displayName = 'PendingTeamInvitation';

export { PendingTeamInvitation };
export type { PendingTeamInvitationProps };
