import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '@/components/v1/Button';
import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import type { INewDocumentRevisionAvailableModalProps } from '@/redux/slices/modalsSlice/types/props';

export const NewDocumentRevisionAvailableModal: FunctionComponent<
  TJumboModalProps & { props: INewDocumentRevisionAvailableModalProps }
> = ({ props: { documentId, languageId }, ...modalProps }) => {
  const { t } = useTranslation('modals');

  return (
    <Modal.Jumbo {...modalProps}>
      {{
        header: t('newDocumentRevisionAvailable'),
        content: t('documentWasReplacedWithNewRevision'),
        footer: (
          <ButtonLink
            href={`/documents/show/${documentId}/translations/${languageId}`}
            rel="noreferrer"
            onClick={() => {
              modalProps.onClose(false);
            }}
          >
            {t('goToNewRevision')}
          </ButtonLink>
        )
      }}
    </Modal.Jumbo>
  );
};
