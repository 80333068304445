import { cva } from 'cva';

const tag = cva(
  'inline-flex flex-nowrap items-center justify-center gap-1 whitespace-nowrap rounded text-xs font-semibold leading-4',
  {
    variants: {
      color: {
        primary: '',
        neutral: '',
        negative: '',
        positive: '',
        warning: ''
      },
      size: {
        sm: 'h-5 px-1',
        md: 'h-6 px-2'
      },
      variant: {
        solid: '',
        soft: ''
      }
    },
    compoundVariants: [
      {
        color: 'primary',
        variant: 'solid',
        className: 'bg-blue-500 text-white'
      },
      {
        color: 'primary',
        variant: 'soft',
        className: 'bg-blue-50 text-blue-700'
      },
      {
        color: 'neutral',
        variant: 'solid',
        className: 'bg-gray-700 text-white'
      },
      {
        color: 'neutral',
        variant: 'soft',
        className: 'bg-gray-50 text-gray-700'
      },
      {
        color: 'negative',
        variant: 'solid',
        className: 'bg-red-500 text-white'
      },
      {
        color: 'negative',
        variant: 'soft',
        className: 'bg-red-50 text-red-700'
      },
      {
        color: 'positive',
        variant: 'solid',
        className: 'bg-green-500 text-white'
      },
      {
        color: 'positive',
        variant: 'soft',
        className: 'bg-green-50 text-green-700'
      },
      {
        color: 'warning',
        variant: 'solid',
        className: 'bg-yellow-500 text-yellow-900'
      },
      {
        color: 'warning',
        variant: 'soft',
        className: 'bg-yellow-50 text-yellow-900'
      }
    ],
    defaultVariants: {
      color: 'primary',
      size: 'md',
      variant: 'soft'
    }
  }
);

export { tag };
