import { ELanguageDirection } from '@/redux/api/constants';
import { LanguageDto } from '@/redux/api/types';

type createSelectedLanguagesReturn = {
  id: string;
  label: string;
  name: string;
  code: string;
  direction: ELanguageDirection;
  flagUrl?: string;
  flagCode?: string;
}[];

export const createSelectedLanguages = (
  languages: { [key: number]: LanguageDto },
  assignedLanguagesIds: number[]
): createSelectedLanguagesReturn => {
  const selectedLanguages = [];

  for (let i = 0; i < assignedLanguagesIds.length; i++) {
    const assignedLanguageId = assignedLanguagesIds[i];
    const language: LanguageDto | undefined = languages[assignedLanguageId];

    if (language) {
      const id = language.id.toString();
      const label = language.name;

      selectedLanguages.push({
        ...language,
        id,
        label
      });
    }
  }

  return selectedLanguages;
};
