import { isNotEmpty } from '@/utils/isNotEmpty';

type GenerateArrayParamsReturnType = { [key: string]: number | string };

export function generateArrayParams(filters: {
  [key: string]: number[] | string[] | undefined;
}): GenerateArrayParamsReturnType {
  const params: GenerateArrayParamsReturnType = {};

  // iterate over each filter entry
  for (const [filterName, values] of Object.entries(filters)) {
    // check if values is defined and is an array
    if (Array.isArray(values) && isNotEmpty(values)) {
      let index = 0;
      for (const value of values) {
        params[`${filterName}[${index}]`] = value;
        index++;
      }
    }
  }

  return params;
}
