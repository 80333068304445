import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UploadDocumentState } from '@/redux/slices/uploadDocumentsSlice/types';
import { INITIAL_STATE } from '@/redux/slices/uploadDocumentsSlice/constants';

export const uploadDocumentsSlice = createSlice({
  name: 'uploadDocuments',
  initialState: INITIAL_STATE,
  reducers: {
    stashFiles: (state, { payload }: PayloadAction<UploadDocumentState['stashDocuments']>) => {
      state.stashDocuments = [...payload];
    },
    clearStashedFiles: (state) => {
      state.stashDocuments = [];
    },
    setCreatedDocumentIds: (state, { payload }: PayloadAction<number[]>) => {
      state.createdDocumentIds = [...payload];
    },
    clearCreatedDocumentIds: (state) => {
      state.createdDocumentIds = [];
    }
  }
});

export const { clearStashedFiles, stashFiles, setCreatedDocumentIds, clearCreatedDocumentIds } =
  uploadDocumentsSlice.actions;
