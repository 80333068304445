import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { redokunApi } from '../api';
import { apiErrorsMiddleware, documentLanguageMiddleware } from '../middlewares';
import {
  connectionSlice,
  documentLanguageSlice,
  importSegmentsSlice,
  modalsSlice,
  onboardingSlice,
  operationsSlice,
  presenceSlice,
  pusherSlice,
  teamMembersSlice,
  uploadDocumentsSlice
} from '../slices';
import { listenerMiddleware } from './listenerMiddleware';

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: {
    // Router
    router: connectRouter<any>(history), // eslint-disable-line @typescript-eslint/no-explicit-any

    // API
    [redokunApi.reducerPath]: redokunApi.reducer,

    // Slices
    [connectionSlice.name]: connectionSlice.reducer,
    [documentLanguageSlice.name]: documentLanguageSlice.reducer,
    [importSegmentsSlice.name]: importSegmentsSlice.reducer,
    [modalsSlice.name]: modalsSlice.reducer,
    [onboardingSlice.name]: onboardingSlice.reducer,
    [operationsSlice.name]: operationsSlice.reducer,
    [presenceSlice.name]: presenceSlice.reducer,
    [pusherSlice.name]: pusherSlice.reducer,
    [teamMembersSlice.name]: teamMembersSlice.reducer,
    [uploadDocumentsSlice.name]: uploadDocumentsSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(routerMiddleware(history), redokunApi.middleware, apiErrorsMiddleware, documentLanguageMiddleware)
});

setupListeners(store.dispatch);
