export enum ManagerDtoStatus {
  DISABLED = 'DISABLED',
  ACCEPTED = 'ACCEPTED',
  INVITED = 'INVITED'
}

export enum EBlockedReason {
  EXPIRED_SUBSCRIPTION = 'EXPIRED_SUBSCRIPTION',
  CONFIRMED = 'CONFIRMED',
  NOT_ACCEPTED = 'NOT_ACCEPTED'
}

export enum MachineTranslationVendor {
  GOOGLE_TRANSLATE = 'GOOGLE_TRANSLATE',
  DEEPL = 'DEEPL'
}

export enum EThumbnailStatus {
  OK = 'OK',
  UNAVAILABLE = 'UNAVAILABLE',
  GENERATING = 'GENERATING',
  NOT_FOUND = 'NOT_FOUND',
  CANNOT_GENERATE = 'CANNOT_GENERATE'
}

export enum TranslationStatus {
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED'
}

export enum ETranslationUpdateSource {
  CAT = 'CAT',
  CAT_SUGGESTION = 'CAT_SUGGESTION',
  CAT_MT = 'CAT_MT',
  CAT_COPY = 'CAT_COPY'
}

export enum EParagraphObjectType {
  GENERIC = 'GENERIC',
  UNKNOWN = 'UNKNOWN',
  SECTION = 'SECTION',
  VARIABLE = 'VARIABLE',
  TEXTFRAME = 'TEXTFRAME',
  CELL = 'CELL',
  TOC_TITLE = 'TOC_TITLE',
  PPTX_SLIDE_NOTES = 'PPTX_SLIDE_NOTES',
  PPTX_SLIDE_MASTER = 'PPTX_SLIDE_MASTER'
}

export enum ETranslationListFilter {
  ONLY_WITH_COMMENTS = 'ONLY_WITH_COMMENTS',
  ONLY_EMPTY = 'ONLY_EMPTY',
  ONLY_DRAFT = 'ONLY_DRAFT',
  ONLY_GLOSSARY_WARNINGS = 'ONLY_GLOSSARY_WARNINGS'
}

export enum EPlaceholderType {
  TAG = 'TAG',
  TAG_PAIR = 'TAG_PAIR',
  MARKER = 'MARKER'
}

export enum EAppliedFilter {
  REMOVED_JUNK = 'REMOVED_JUNK',
  MISSING_TAGS_ADDED = 'MISSING_TAGS_ADDED'
}

export enum EParagraphTranslationStatus {
  CONFIRMED = 'CONFIRMED',
  DRAFT = 'DRAFT'
}

export enum EStandardErrorCode {
  GENERIC_ERROR = 'GENERIC_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  BALANCE_LOW_NOT_ENOUGH_WORDS = 'BALANCE_LOW_NOT_ENOUGH_WORDS'
}

export enum EAclErrorCode {
  ACL_ERROR = 'ACL_ERROR',
  SESSION_EXPIRED = 'SESSION_EXPIRED'
}

export enum ENotificationType {
  NEW_DOCUMENT_TRANSLATED_COMMENT = 'NEW_DOCUMENT_TRANSLATED_COMMENT',
  NEW_TRANSLATED_PARAGRAPH_COMMENT = 'NEW_TRANSLATED_PARAGRAPH_COMMENT'
}

export enum EErrorStatus {
  ERROR = 'error'
}

export enum ELanguageDirection {
  LTR = 'LTR',
  RTL = 'RTL'
}

export enum ETranslationInvitationStatus {
  ACCEPTED = 'ACCEPTED'
}

export enum EDocumentType {
  IDML = 'IDML',
  DOCX = 'DOCX',
  PPTX = 'PPTX',
  XLSX = 'XLSX',
  HTML = 'HTML',
  SRT = 'SRT',
  TXT = 'TXT',
  XLIFF = 'XLIFF',
  JSON = 'JSON',
  XML = 'XML'
}

export enum ESuggestionReferenceType {
  DOCUMENT = 'DOCUMENT',
  IMPORTED_TM = 'IMPORTED_TM'
}

export enum EPlaceholdersVersion {
  V1 = 1,
  V2 = 2
}

export enum EListableType {
  COMMENT = 'COMMENT',
  ACTIVITY = 'ACTIVITY',
  REDACTED_ACTIVITY = 'REDACTED_ACTIVITY'
}

export enum EActivityEventContentType {
  TRANSLATION_EDITED = 'TRANSLATION_EDITED',
  TRANSLATION_ADDED = 'TRANSLATION_ADDED',
  TRANSLATION_DELETED = 'TRANSLATION_DELETED',
  TRANSLATION_IMPORTED = 'TRANSLATION_IMPORTED',
  TRANSLATION_PRE_TRANSLATED = 'TRANSLATION_PRE_TRANSLATED',
  TRANSLATION_MARKED_AS_DRAFT = 'TRANSLATION_MARKED_AS_DRAFT',
  TRANSLATION_MARKED_AS_CONFIRMED = 'TRANSLATION_MARKED_AS_CONFIRMED',
  UNKNOWN = 'UNKNOWN'
}

export enum InviteStatus {
  WORKING = 'WORKING',
  INVITED = 'INVITED',
  DISMISSED = 'DISMISSED'
}

export enum OperationType {
  PRE_TRANSLATE = 'PRE_TRANSLATE'
}

export enum DocumentListItemType {
  FOLDER = 'FOLDER',
  DOCUMENT = 'DOCUMENT'
}
export enum EventTypes {
  ONBOARDING_VISITED_SURVEY = 'ONBOARDING_VISITED_SURVEY',
  ONBOARDING_VISITED_WHAT_YOU_CAN_DO = 'ONBOARDING_VISITED_WHAT_YOU_CAN_DO',
  ONBOARDING_VISITED_TRANSLATE_YOUR_FIRST_DOCUMENT = 'ONBOARDING_VISITED_TRANSLATE_YOUR_FIRST_DOCUMENT',
  ONBOARDING_VISITED_UPLOAD_DOCUMENT = 'ONBOARDING_VISITED_UPLOAD_DOCUMENT',
  ONBOARDING_VISITED_SET_SOURCE_LANGUAGE = 'ONBOARDING_VISITED_SET_SOURCE_LANGUAGE',
  ONBOARDING_VISITED_SET_TARGET_LANGUAGES = 'ONBOARDING_VISITED_SET_TARGET_LANGUAGES',
  ONBOARDING_VISITED_DOCUMENT_PAGE = 'ONBOARDING_VISITED_DOCUMENT_PAGE',
  ONBOARDING_VISITED_PRE_TRANSLATE_PAGE = 'ONBOARDING_VISITED_PRE_TRANSLATE_PAGE',
  ONBOARDING_VISITED_PRE_TRANSLATE_REPORT_PAGE = 'ONBOARDING_VISITED_PRE_TRANSLATE_REPORT_PAGE',
  DOCUMENT_FAILED_UPLOAD = 'DOCUMENT_FAILED_UPLOAD'
}

export const TRIAL_WORDCOUNT_LIMIT = 10_000;

export enum SubscriptionSuspendedReason {
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
  PAYMENT_PROBLEMS = 'PAYMENT_PROBLEMS',
  ACCOUNT_DISABLED = 'ACCOUNT_DISABLED'
}
