import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';

import { isFunction } from '@/utils/isFunction';
import { createContext } from '@/utils/createContext';

import { Item } from './Item';
import type { IDropdownProps } from './types';

export const [DropdownProvider, useDropdownContext] = createContext<{ open: boolean; close: () => void }>('Dropdown');

export const Dropdown = ({
  containerClassName,
  containerStyle,
  children,
  className,
  content,
  placement,
  strategy,
  style
}: IDropdownProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers: [
      { name: 'flip', enabled: false },
      { name: 'offset', options: { offset: [0, 8] } }
    ]
  });

  return (
    <Menu as={Fragment}>
      {(props) => (
        <DropdownProvider {...props}>
          <div
            ref={setReferenceElement}
            className={isFunction(containerClassName) ? containerClassName(props) : containerClassName}
            style={containerStyle}
          >
            {isFunction(children) ? children(props) : children}

            <Transition
              as={Fragment}
              enter="transition-opacity ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items
                ref={setPopperElement}
                as={'ul'}
                className={(props) =>
                  classNames(
                    'z-10 rounded-sm border border-gray-300 bg-white py-1 shadow-2xl',
                    isFunction(className) ? className(props) : className
                  )
                }
                style={style ? { ...style, ...styles.popper } : styles.popper}
                {...attributes.popper}
              >
                {content}
              </Menu.Items>
            </Transition>
          </div>
        </DropdownProvider>
      )}
    </Menu>
  );
};

Dropdown.Button = Menu.Button;
Dropdown.Item = Item;
