import * as Sentry from '@sentry/react';
import z from 'zod';

// RTK Query rejection (v1.9.5)
// https://github.com/reduxjs/redux-toolkit/blob/d3bb412e064ad6db4fa8a83f54ba20476556f665/packages/toolkit/src/query/core/buildMiddleware/queryLifecycle.ts#L48
const rtkQueryRejectionSchema = z.object({
  isUnhandledError: z.boolean(),
  error: z.unknown(),
  meta: z.unknown()
});
export const isRtkQueryRejection = (_event: Sentry.Event, hint: Sentry.EventHint): boolean => {
  const { originalException } = hint;
  const parseResult = rtkQueryRejectionSchema.safeParse(originalException);
  return parseResult.success;
};
