import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v2/Box';
import { ButtonLink } from '@/components/v2/ButtonLink';
import {
  BillingFrequencyEnum,
  CurrencyEnum,
  CurrentAccountDto,
  GetPlansResponseDto,
  SubscriptionInfoDto
} from '@/redux/api/types';

import { createChangePlanUrl } from '../utils/createChangePlanUrl';
import { getCurrentPlanPrice } from '../utils/getCurrentPlanPrice';
import { getFormattedPrice } from '../utils/getFormattedPrice';
import { getPriceByFrequencyAndCurrency } from '../utils/getPriceByFrequencyAndCurrency';
import { checkIfIsBlueprint } from './checkIfIsBlueprint';
import { checkIfUpOrDowngrade } from './checkIfUpOrDowngrade';
import { PricingTableFeatureList } from './PricingTableFeatureList';
import { PricingTableHeader } from './PricingTableHeader';

export const PricingTable = ({
  plansData,
  currency,
  frequency,
  currentAccount
}: {
  plansData?: GetPlansResponseDto;
  currency: CurrencyEnum;
  frequency: BillingFrequencyEnum;
  currentAccount: CurrentAccountDto;
}) => {
  const { t: tPlans } = useTranslation('plans');

  const formattedPrice: string[] = [];
  const planButtons: string[] = [];
  const isBlueprint: boolean[] = [];

  const getButtonText = (
    subscriptionInfo: SubscriptionInfoDto,
    trialBlueprintPlanId: string,
    planPrice: number,
    currentPlanPrice: number,
    planId: string,
    tPlans: TFunction
  ) => {
    const { isTrial, planCode } = subscriptionInfo;

    if (isTrial) {
      if (planId === trialBlueprintPlanId) {
        return tPlans('switchToPaidPlan');
      } else {
        return checkIfUpOrDowngrade(planPrice, currentPlanPrice, tPlans);
      }
    } else if (planCode === planId) {
      return tPlans('yourPlan');
    } else {
      return checkIfUpOrDowngrade(planPrice, currentPlanPrice, tPlans);
    }
  };

  if (plansData && plansData.plans.length) {
    const { plans, trialBlueprintPlanId } = plansData;
    const { subscriptionInfo } = currentAccount;

    const currentPlanPrice = getCurrentPlanPrice(subscriptionInfo, plans, frequency, currency);

    for (let i = 0; i < plans.length; i++) {
      const plan = plans[i];

      isBlueprint[i] = false;

      // Button Text
      const planPrice = getPriceByFrequencyAndCurrency(frequency, currency, plan);
      planButtons[i] = getButtonText(
        subscriptionInfo,
        trialBlueprintPlanId,
        planPrice,
        currentPlanPrice,
        plan.planCode,
        tPlans
      );

      // Check if is Blueprint
      isBlueprint[i] = checkIfIsBlueprint(subscriptionInfo.isTrial, trialBlueprintPlanId, plan.planCode);

      // Currency format
      formattedPrice[i] = getFormattedPrice(plan, frequency, currency);
    }
  }

  const getButtonColor = (index: number) => (planButtons[index] === tPlans('upgrade') ? 'primary' : 'secondary');

  return (
    <div className="grid grid-cols-3 gap-7">
      {plansData?.plans.map((plan, i) => {
        const isDisabled = currentAccount.subscriptionInfo.planCode === plan.planCode;
        return (
          <Box key={i}>
            <PricingTableHeader
              onFreeTrial={isBlueprint[i]}
              price={formattedPrice[i]}
              frequency={frequency}
              planName={plan.planName}
            />
            <div>
              <ButtonLink
                href={createChangePlanUrl({ planCode: plan.planCode, billingFrequency: frequency, currency })}
                className="w-full"
                color={getButtonColor(i)}
                disabled={isDisabled}
              >
                {planButtons[i]}
              </ButtonLink>
            </div>
            <div>
              <PricingTableFeatureList plan={plan} />
            </div>
          </Box>
        );
      })}
    </div>
  );
};
