import { createSlice } from '@reduxjs/toolkit';

import { redokunApi } from '../../api';
import { INITIAL_STATE } from './constants';

export const connectionSlice = createSlice({
  name: 'connection',
  initialState: INITIAL_STATE,

  reducers: {},

  extraReducers: (builder) => {
    const { onOnline, onOffline } = redokunApi.internalActions;

    // Online
    builder.addCase(onOnline, (state) => {
      state.online = true;
    });
    builder.addCase(onOffline, (state) => {
      state.online = false;
    });
  }
});
