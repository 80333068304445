import type { TAppDispatch } from '@/redux/store/types';

import { EListableType } from '../constants';
import { languageApiUtil } from '../languageApi';
import type { IActivityListableItem, IGetParagraphCommentQueryArg, IParagraphComment } from '../types';
import { mergePatchCollections } from '../utils/mergePatchCollections';

export const paragraphCommentCreated =
  (args: IGetParagraphCommentQueryArg, payload: IParagraphComment) => (dispatch: TAppDispatch) => {
    const commentPatch = dispatch(
      languageApiUtil.safeUpdateQueryData('getParagraphComment', args, (data) => {
        const commentIndex = data.comments.findIndex((comment) => comment.id === payload.id);
        if (commentIndex >= 0) {
          return;
        }

        data.comments.push(payload);
      })
    );

    const activityCommentsPatch = dispatch(
      languageApiUtil.safeUpdateQueryData('getParagraphActivityComments', args, (data) => {
        const activityIndex = data.activities.findIndex((activity) => activity.id === payload.id);
        if (activityIndex >= 0) {
          return;
        }

        const activity: IActivityListableItem = { ...payload, type: EListableType.COMMENT };
        data.activities.push(activity);
      })
    );

    return mergePatchCollections(commentPatch, activityCommentsPatch);
  };
