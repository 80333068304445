import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Show } from '@/components/v1/Show';
import { Spinner } from '@/components/v1/Spinner';
import { useAppDispatch } from '@/redux/hooks';
import { clearFilters } from '@/redux/slices/documentLanguageSlice';

import type { IDocumentLanguageParagraphsFallbackProps } from './types';

export const DocumentLanguageParagraphsFallback = (props: IDocumentLanguageParagraphsFallbackProps) => {
  const { isLoading, hasFilters } = props;

  const { t } = useTranslation('documentLanguageParagraphsFallback');
  const dispatch = useAppDispatch();

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  return (
    <>
      <Show when={isLoading}>
        <div className="flex h-64 items-center justify-center">
          <Spinner size="lg" className="mx-auto" />
        </div>
      </Show>

      <Show when={!isLoading}>
        <div className="flex h-64 flex-col items-center justify-center space-y-2 rounded bg-white">
          <Show when={hasFilters} fallback={<p className="font-bold">{t('noResults')}</p>}>
            <p className="font-bold">{t('noResultsFoundForSelectedFilters')}</p>

            <Button onClick={handleClearFilters}>{t('resetAllFilters')}</Button>
          </Show>
        </div>
      </Show>
    </>
  );
};
