import { useControllableState } from '@radix-ui/react-use-controllable-state';
import {
  ColumnFiltersState,
  createColumnHelper,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  Table as ReactTableProp,
  RowData,
  RowSelectionState,
  SortingState,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table';
import { isNil } from 'lodash';
import { forwardRef } from 'react';

import { Checkbox } from '@/components/v2/Checkbox';
import { For } from '@/components/v2/For';
import { Pagination } from '@/components/v2/Pagination';
import { Select, SelectItem } from '@/components/v2/Select';
import { Show } from '@/components/v2/Show';
import { Skeleton } from '@/components/v2/Skeleton';
import Table from '@/components/v2/Table';
import { createContext } from '@/utils/createContext';

import s from '../Table/Table.module.css';
import { DEFAULT_COLUMN, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from './constants';
import type {
  DataTableBodyElement,
  DataTableBodyProps,
  DataTableCellContext,
  DataTableCheckboxElement,
  DataTableCheckboxProps,
  DataTableContentElement,
  DataTableContentProps,
  DataTableContext,
  DataTableHeaderElement,
  DataTableHeaderProps,
  DataTablePaginationElement,
  DataTablePaginationProps,
  DataTableProps,
  DataTableRowContext,
  DataTableSelectElement,
  DataTableSelectProps
} from './types';
import { getCellSkeletonWidth, getColumnWidth, getData } from './utils';

const [DataTableProvider, useDataTableContext] = createContext<DataTableContext>('DataTable');
const useTypedDataTableContext = useDataTableContext as <Data extends RowData = unknown>(
  consumerName: string
) => DataTableContext<Data>;

const [DataTableRowProvider, useDataTableRowContext] = createContext<DataTableRowContext>('DataTableRow');
const useTypedDataTableRowContext = useDataTableRowContext as <Data extends RowData = unknown>(
  consumerName: string
) => DataTableRowContext<Data>;

const [DataTableCellProvider, useDataTableCellContext] = createContext<DataTableCellContext>('DataTableCell');
const useTypedDataTableCellContext = useDataTableCellContext as <Data extends RowData = unknown, Value = unknown>(
  consumerName: string
) => DataTableCellContext<Data, Value>;

const DataTable = <Data extends RowData = unknown>({
  columns,
  data,
  pageCount,
  columnFilters: columnFiltersProp,
  defaultColumnFilters = [],
  columnVisibility: columnVisibilityProp,
  defaultColumnVisibility = {},
  rowSelection: rowSelectionProp,
  defaultRowSelection = {},
  sorting: sortingProp,
  pagination: paginationProp,
  expanded: expandedProps,
  defaultSorting = [],
  defaultPageIndex = DEFAULT_PAGE_INDEX,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  defaultExpanding = {},
  loading = false,
  onColumnFiltersChange,
  onColumnVisibilityChange,
  onRowSelectionChange,
  onSortingChange,
  onPaginationChange,
  onExpandedChange,
  enableRowSelection,
  getRowCanExpand,
  getRowId,
  meta,
  children,
  ...props
}: DataTableProps<Data>) => {
  const [columnFilters = defaultColumnFilters, setColumnFilters] = useControllableState({
    prop: columnFiltersProp,
    onChange: onColumnFiltersChange,
    defaultProp: defaultColumnFilters
  });
  const [columnVisibility = defaultColumnVisibility, setColumnVisibility] = useControllableState({
    prop: columnVisibilityProp,
    onChange: onColumnVisibilityChange,
    defaultProp: defaultColumnVisibility
  });
  const [rowSelection = defaultRowSelection, setRowSelection] = useControllableState({
    prop: rowSelectionProp,
    onChange: onRowSelectionChange,
    defaultProp: defaultRowSelection
  });
  const [sorting = defaultSorting, setSorting] = useControllableState({
    prop: sortingProp,
    onChange: onSortingChange,
    defaultProp: defaultSorting
  });
  const defaultPagination = { pageIndex: defaultPageIndex, pageSize: defaultPageSize };
  const [pagination = defaultPagination, setPagination] = useControllableState<PaginationState>({
    prop: paginationProp,
    onChange: onPaginationChange,
    defaultProp: defaultPagination
  });
  const [expanded = defaultExpanding, setExpanded] = useControllableState<ExpandedState>({
    prop: expandedProps,
    onChange: onExpandedChange,
    defaultProp: defaultExpanding
  });

  const table = useReactTable({
    columns,
    data: getData(data),
    defaultColumn: DEFAULT_COLUMN,
    state: {
      columnFilters,
      columnVisibility,
      rowSelection,
      sorting,
      pagination,
      expanded
    },
    getRowId: getRowId,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    onColumnVisibilityChange: setColumnVisibility as OnChangeFn<VisibilityState>,
    onRowSelectionChange: setRowSelection as OnChangeFn<RowSelectionState>,
    onSortingChange: setSorting as OnChangeFn<SortingState>,
    onPaginationChange: setPagination as OnChangeFn<PaginationState>,
    onExpandedChange: setExpanded as OnChangeFn<ExpandedState>,
    enableRowSelection: enableRowSelection,
    getRowCanExpand: getRowCanExpand,
    pageCount,
    manualPagination: true,
    manualExpanding: true,
    autoResetPageIndex: false,
    meta: meta
  });

  return (
    <DataTableProvider
      table={table as ReactTableProp<unknown>}
      columnFilters={columnFilters}
      columnVisibility={columnVisibility}
      rowSelection={rowSelection}
      sorting={sorting}
      pagination={pagination}
      expanded={expanded}
      loading={loading}
      {...props}
    >
      {children}
    </DataTableProvider>
  );
};
DataTable.displayName = 'DataTable';

const DataTableContent = forwardRef<DataTableContentElement, DataTableContentProps>(({ children, ...props }, ref) => {
  const { table } = useDataTableContext('DataTableContent');

  return (
    <Table.Root ref={ref} {...props}>
      <colgroup>
        <For each={table.getAllColumns()}>
          {(column) => {
            const width = getColumnWidth(column);

            return <col key={column.id} style={{ width }} />;
          }}
        </For>
      </colgroup>

      {children}
    </Table.Root>
  );
});
DataTableContent.displayName = 'DataTableContent';

const DataTableHeader = forwardRef<DataTableHeaderElement, DataTableHeaderProps>(({ selection, ...props }, ref) => {
  const { table, loading } = useDataTableContext('DataTableHeader');

  // if row are selected
  const checked = table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate');
  // check if selection prop is defined
  if (checked && selection !== undefined) {
    return (
      <Table.Header {...props} ref={ref}>
        <Table.Row>
          <Table.HeaderCell className="bg-surface-accent" colSpan={table.getAllColumns().length}>
            {/* render custom header if user has selected at least one row and has defined a selected prop */}
            {selection}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  }

  return (
    <Table.Header {...props} ref={ref}>
      <For each={table.getHeaderGroups()}>
        {(headerGroup) => (
          <Table.Row key={headerGroup.id}>
            <For each={headerGroup.headers}>
              {(header) => {
                return (
                  <Table.HeaderCell key={header.id} colSpan={header.colSpan}>
                    <Show when={!header.isPlaceholder}>
                      <Show
                        when={!loading}
                        fallback={
                          <Show when={header.column.columnDef.headerLoader !== undefined} fallback={<Skeleton />}>
                            {flexRender(header.column.columnDef.headerLoader, header.getContext())}
                          </Show>
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Show>
                    </Show>
                  </Table.HeaderCell>
                );
              }}
            </For>
          </Table.Row>
        )}
      </For>
    </Table.Header>
  );
});
DataTableHeader.displayName = 'DataTableHeader';

const DataTableBody = forwardRef<DataTableBodyElement, DataTableBodyProps>(({ fallback, ...props }, ref) => {
  const { table, loading } = useDataTableContext('DataTableBody');

  return (
    <Table.Body {...props} ref={ref}>
      <For
        each={table.getRowModel().rows}
        fallback={
          <Show when={!isNil(fallback)}>
            <Table.Row>
              <Table.Cell colSpan={table.getAllColumns().length}>{fallback}</Table.Cell>
            </Table.Row>
          </Show>
        }
      >
        {(row) => {
          // check if row is expanded
          // disable expanded rows when loading
          const isExpanded = row.getCanExpand() && row.getIsExpanded() && !loading;

          return (
            <DataTableRowProvider row={row} key={row.id}>
              <Table.Row
                // set data state to true if expanded
                data-expanded={isExpanded}
                // @ts-expect-error: unknown is used at the root for type definition of the meta prop so isContentOnly is undefined
                data-content-only={table.options.meta?.isContentOnly(row)}
                data-state={row.getIsSelected() ? 'checked' : 'unchecked'}
                className={s['table-row']}
              >
                <For each={row.getVisibleCells()}>
                  {(cell) => (
                    <DataTableCellProvider cell={cell} key={cell.id}>
                      <Table.Cell>
                        <Show
                          when={!loading}
                          fallback={
                            <Show
                              when={cell.column.columnDef.cellLoader !== undefined}
                              fallback={<Skeleton width={getCellSkeletonWidth(cell)} />}
                            >
                              {flexRender(cell.column.columnDef.cellLoader, cell.getContext())}
                            </Show>
                          }
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Show>
                      </Table.Cell>
                    </DataTableCellProvider>
                  )}
                </For>
              </Table.Row>

              <Show when={isExpanded}>
                <Table.Row data-expanded={isExpanded} className={s['table-row-expanded']}>
                  <For each={row.getVisibleCells()}>
                    {(cell) => (
                      <DataTableCellProvider cell={cell} key={cell.id}>
                        <Table.Cell className="border-0 align-top">
                          {flexRender(cell.column.columnDef.cellExpanded, cell.getContext())}
                        </Table.Cell>
                      </DataTableCellProvider>
                    )}
                  </For>
                </Table.Row>
              </Show>
            </DataTableRowProvider>
          );
        }}
      </For>
    </Table.Body>
  );
});
DataTableBody.displayName = 'DataTableBody';

const DataTableHeaderCheckbox = forwardRef<DataTableCheckboxElement, DataTableCheckboxProps>(
  ({ size = 'sm', ...props }, ref) => {
    const { table } = useDataTableContext('DataTableHeaderCheckbox');

    const checked = table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate');

    return (
      <Checkbox
        {...props}
        ref={ref}
        size={size}
        checked={checked}
        onCheckedChange={(checked) => {
          table.toggleAllPageRowsSelected(!!checked);
        }}
      />
    );
  }
);
DataTableHeaderCheckbox.displayName = 'DataTableHeaderCheckbox';

const DataTableCheckbox = forwardRef<DataTableCheckboxElement, DataTableCheckboxProps>(
  ({ size = 'sm', ...props }, ref) => {
    const { row } = useDataTableRowContext('DataTableCheckbox');

    return (
      <Checkbox
        {...props}
        ref={ref}
        size={size}
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onCheckedChange={(checked) => {
          row.toggleSelected(!!checked);
        }}
      />
    );
  }
);
DataTableCheckbox.displayName = 'DataTableCheckbox';

const DataTablePagination = forwardRef<DataTablePaginationElement, DataTablePaginationProps>(
  ({ size = 'sm', numberOfPages, ...props }, ref) => {
    const { table } = useDataTableContext('DataTablePagination');

    const handleOnChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
      table.setPageIndex(value);
    };

    return (
      <Pagination
        {...props}
        ref={ref}
        size={size}
        numberOfPages={numberOfPages}
        currentPage={table.getState().pagination.pageIndex}
        onChangePage={handleOnChangePage}
      />
    );
  }
);
DataTablePagination.displayName = 'DataTablePagination';

const DataTablePageSizeSelector = forwardRef<DataTableSelectElement, DataTableSelectProps>(
  ({ options, ...props }, ref) => {
    const { table } = useDataTableContext('DataTablePagination');

    const handleOnChange = (value: string) => {
      table.setPagination({
        // every page size reset page index to 1, to avoid having a pageIndex bigger than pageSize
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: parseInt(value)
      });
    };

    return (
      <Select
        ref={ref}
        value={String(table.getState().pagination.pageSize)}
        onChange={handleOnChange}
        rootClassName="w-select-trigger max-w-xs "
        {...props}
      >
        {options.map((page) => (
          <SelectItem value={String(page.value)} key={page.value}>
            {page.label}
          </SelectItem>
        ))}
      </Select>
    );
  }
);
DataTablePageSizeSelector.displayName = 'DataTablePageSizeSelector';

export {
  createColumnHelper as createDataTableColumnHelper,
  DataTable,
  DataTableBody,
  DataTableCheckbox,
  DataTableContent,
  DataTableHeader,
  DataTableHeaderCheckbox,
  DataTablePageSizeSelector,
  DataTablePagination,
  useTypedDataTableCellContext as useDataTableCellContext,
  useTypedDataTableContext as useDataTableContext,
  useTypedDataTableRowContext as useDataTableRowContext
};
