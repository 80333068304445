export const calculateOccurrences = (identicalCount: number) => identicalCount - 1;

/**
 * Get regex and full stop character based on the language
 *
 * @param languageCode ISO 639-1 language code
 * @returns an object with the regex and the characters to use based on the language
 */
export const getFullStopAtEndRegExp = (languageCode: string) => {
  if (languageCode.match(/^(iu|mi|su|sw|uz|so|vi|th|el|hi|fa|ur|az|pa|syr|ha|km)-?/)) {
    // Languages without full stop
    return null;
  } else if (languageCode.match(/^(zh|ja)-?/)) {
    // Languages with ideographic full stop
    return { regExp: /(。)+$/, characters: '。' };
  } else {
    // All other languages
    return { regExp: /(\.)+$/, characters: '.' };
  }
};

/**
 * Fix the full stop at the end of the translation if needed
 *
 * @param sourceLanguageCode source ISO 639-1 language code
 * @param sourceContent source translation content
 * @param targetLanguageCode target ISO 639-1 language code
 * @param targetContent target translation content
 * @returns a string with fixed target content, null otherwise
 */
export const fixFullStopAtEnd = (
  sourceLanguageCode: string,
  sourceContent: string,
  targetLanguageCode: string,
  targetContent: string
) => {
  const sourceRegExp = getFullStopAtEndRegExp(sourceLanguageCode);
  const targetRegExp = getFullStopAtEndRegExp(targetLanguageCode);

  let sourceRegExpMatch: RegExpMatchArray | null;
  if (
    targetContent &&
    sourceRegExp &&
    targetRegExp &&
    (sourceRegExpMatch = sourceContent.trim().match(sourceRegExp.regExp))
  ) {
    const missingCharCount = sourceRegExpMatch[0].length;
    const targetRegExpMatch = targetContent.trim().match(targetRegExp.regExp);

    if (!targetRegExpMatch || missingCharCount > targetRegExpMatch[0].length) {
      const missing = targetRegExp.characters.repeat(missingCharCount);
      return targetContent + missing;
    }
  }

  return null;
};
