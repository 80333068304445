import { ActivityItem } from '@/components/v1/ActivityItem';
import { Show } from '@/components/v1/Show';
import { useCommentsContext } from '@/providers/CommentsProvider';
import { useCurrentAccountContext } from '@/providers/CurrentAccountProvider';
import { EActivityEventContentType, EListableType } from '@/redux/api/constants';
import { useAppDispatch } from '@/redux/hooks';
import { openDeleteParagraphCommentModal } from '@/redux/slices/modalsSlice';

import { useUpdateParagraphTranslationMutation } from '../../hooks';
import { useParagraphActivityCommentsContext } from './ParagraphActivityCommentsContext';
import { IParagraphActivityItemProps, TFilteredActivityListableItem } from './types';

export const ParagraphActivityItem = (props: IParagraphActivityItemProps) => {
  const { activity } = props;

  if (activity.type === EListableType.COMMENT) {
    return <Comment comment={activity} />;
  }

  if (activity.type === EListableType.ACTIVITY) {
    return <Activity activity={activity} />;
  }

  if (activity.type === EListableType.REDACTED_ACTIVITY) {
    return <RedactedActivity redactedActivity={activity} />;
  }

  return null;
};

interface ICommentProps {
  comment: Extract<TFilteredActivityListableItem, { type: EListableType.COMMENT }>;
}
const Comment = (props: ICommentProps) => {
  const { comment } = props;
  const { account } = comment;

  const dispatch = useAppDispatch();
  const { currentAccount } = useCurrentAccountContext();
  const { teamMembersColors } = useCommentsContext();
  const { documentId, languageId, paragraphId } = useParagraphActivityCommentsContext('Comment');

  const handleDelete = () => {
    dispatch(openDeleteParagraphCommentModal({ documentId, languageId, paragraphId, commentId: comment.id }));
  };

  const menu = currentAccount.id === account.id && <ActivityItem.CommentMenu onDelete={handleDelete} />;

  return (
    <ActivityItem
      type="comment"
      user={
        <ActivityItem.User
          avatarSrc={account.avatarUrl}
          badge={!comment.isRead}
          name={account.fullName}
          date={comment.createdAt}
        />
      }
      header={<ActivityItem.Header action="comment-added" />}
      menu={menu}
    >
      <ActivityItem.Comment
        content={comment.content.text}
        mentions={comment.content.mentionRanges}
        defaultMentionColor={teamMembersColors.defaultColor}
        mentionColors={teamMembersColors.colors}
      />
    </ActivityItem>
  );
};

interface IActivityProps {
  activity: Extract<TFilteredActivityListableItem, { type: EListableType.ACTIVITY }>;
}
const Activity = (props: IActivityProps) => {
  const { activity } = props;
  const { account, content } = activity;
  const action = ACTIONS[activity.content.type];

  const { documentId, languageId, paragraphId } = useParagraphActivityCommentsContext('Activity');
  const [updateTranslation] = useUpdateParagraphTranslationMutation({ documentId, languageId, paragraphId });

  const handleRestore = () => {
    updateTranslation(content.previousContent, content.previousSource);
  };

  const hasMenu =
    !!content.previousContent &&
    (content.type === EActivityEventContentType.TRANSLATION_ADDED ||
      content.type === EActivityEventContentType.TRANSLATION_EDITED ||
      content.type === EActivityEventContentType.TRANSLATION_DELETED);
  const menu = hasMenu && <ActivityItem.EventMenu onRestore={handleRestore} />;

  return (
    <ActivityItem
      type="event"
      user={
        <ActivityItem.User
          avatarSrc={account.avatarUrl}
          badge={!activity.isRead}
          name={account.fullName}
          date={activity.createdAt}
        />
      }
      header={<ActivityItem.Header action={action} />}
      menu={menu}
    >
      <Show when={content.contentDiff}>
        <ActivityItem.Event content={content.contentDiff} />
      </Show>
    </ActivityItem>
  );
};

interface IRedactedActivityProps {
  redactedActivity: Extract<TFilteredActivityListableItem, { type: EListableType.REDACTED_ACTIVITY }>;
}
const RedactedActivity = (props: IRedactedActivityProps) => {
  const { redactedActivity } = props;

  return (
    <ActivityItem type="event">
      <ActivityItem.HiddenEvents count={redactedActivity.count} />
    </ActivityItem>
  );
};

const ACTIONS = {
  [EActivityEventContentType.TRANSLATION_EDITED]: 'translation-edited',
  [EActivityEventContentType.TRANSLATION_ADDED]: 'translation-added',
  [EActivityEventContentType.TRANSLATION_DELETED]: 'translation-deleted',
  [EActivityEventContentType.TRANSLATION_IMPORTED]: 'translation-imported',
  [EActivityEventContentType.TRANSLATION_PRE_TRANSLATED]: 'translation-pre-translated',
  [EActivityEventContentType.TRANSLATION_MARKED_AS_DRAFT]: 'translation-marked-as-draft',
  [EActivityEventContentType.TRANSLATION_MARKED_AS_CONFIRMED]: 'translation-marked-as-confirmed',
  [EActivityEventContentType.UNKNOWN]: 'unknown'
} as const;
