import Modal, { ModalProps } from '@/components/v2/Modal';
import { DocumentSettingsContent } from '@/routes/Document/components/DocumentSettingsModal/components/DocumentSettingsContent';

export interface DocumentSettingsModalProps extends ModalProps {
  documentId: number;
  onConfirm?: () => void;
}

export const DocumentSettingsModal = (props: DocumentSettingsModalProps) => {
  const { documentId, onConfirm, children, open, ...modalProps } = props;

  return (
    <Modal.Root {...modalProps} open={open}>
      <Modal.Trigger>{children}</Modal.Trigger>
      <Modal.Content onOpenAutoFocus={(e) => e.preventDefault()} size="lg">
        <DocumentSettingsContent documentId={documentId} onConfirm={onConfirm} />
      </Modal.Content>
    </Modal.Root>
  );
};
