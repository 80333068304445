import { forwardRef } from 'react';

import type { TCheckboxProps } from './types';
import { getCheckboxInputClassName, getCheckboxLabelClassName } from './utils';

export const Checkbox = forwardRef<HTMLInputElement, TCheckboxProps>(
  ({ children, className, disabled, style, ...htmlCheckboxProps }, ref) => {
    const labelClassName = getCheckboxLabelClassName({ disabled, className });
    const inputClassName = getCheckboxInputClassName({ disabled });

    return (
      <label className={labelClassName} style={style}>
        <input type="checkbox" className={inputClassName} {...htmlCheckboxProps} disabled={disabled} ref={ref} />
        {children !== undefined && <span>{children}</span>}
      </label>
    );
  }
);
