import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { Slot } from '@radix-ui/react-slot';
import { Children, CSSProperties, forwardRef, isValidElement } from 'react';

import { createContext } from '@/utils/createContext';
import { classNames } from '@/utils/classNames';

import { For } from '../For';
import s from './Avatar.module.css';
import { avatar, avatarFallback, avatarGroup, avatarIndicator } from './styles';
import type {
  AvatarElement,
  AvatarFallbackElement,
  AvatarFallbackProps,
  AvatarGroupElement,
  AvatarGroupProps,
  AvatarImageElement,
  AvatarImageProps,
  AvatarIndicatorElement,
  AvatarIndicatorProps,
  AvatarProps
} from './types';

const [AvatarGroupProvider, useAvatarGroupContext] = createContext<Pick<AvatarGroupProps, 'dir' | 'size'>>(
  'AvatarGroup',
  {}
);

const AvatarGroup = forwardRef<AvatarGroupElement, AvatarGroupProps>(
  ({ dir = 'ltr', size = 'md', className, children: childrenProp, ...props }, ref) => {
    const children = Children.toArray(childrenProp);

    return (
      <AvatarGroupProvider dir={dir} size={size}>
        <div {...props} ref={ref} className={classNames(avatarGroup({ dir, size }), className)}>
          <For each={children}>
            {(child, childIndex, children) => {
              if (isValidElement(child)) {
                return (
                  <Slot
                    key={child.key}
                    className={s['avatar-group-item']}
                    style={{ '--avatar-group-item-z-index': children.length - childIndex } as CSSProperties}
                  >
                    {child}
                  </Slot>
                );
              }

              return child;
            }}
          </For>
        </div>
      </AvatarGroupProvider>
    );
  }
);
AvatarGroup.displayName = 'AvatarGroup';

const [AvatarProvider, useAvatarContext] = createContext<Pick<AvatarProps, 'size'>>('Avatar');

const Avatar = forwardRef<AvatarElement, AvatarProps>(({ className, size, ...props }, ref) => {
  const avatarGroup = useAvatarGroupContext('Avatar');

  return (
    <AvatarProvider size={size || avatarGroup.size}>
      <AvatarPrimitive.Root
        {...props}
        ref={ref}
        className={classNames(avatar({ size: size || avatarGroup.size }), className)}
      />
    </AvatarProvider>
  );
});
Avatar.displayName = 'Avatar';

const AvatarImage = forwardRef<AvatarImageElement, AvatarImageProps>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    {...props}
    ref={ref}
    className={classNames('h-full w-full rounded-full border border-gray-400', className)}
  />
));
AvatarImage.displayName = 'AvatarImage';

const AvatarFallback = forwardRef<AvatarFallbackElement, AvatarFallbackProps>(({ className, size, ...props }, ref) => {
  const avatar = useAvatarContext('AvatarFallback');

  return (
    <AvatarPrimitive.Fallback
      {...props}
      ref={ref}
      className={classNames(avatarFallback({ size: size || avatar.size }), className)}
    />
  );
});
AvatarFallback.displayName = 'AvatarFallback';

const AvatarIndicator = forwardRef<AvatarIndicatorElement, AvatarIndicatorProps>(
  ({ className, size, online, ...props }, ref) => {
    const avatar = useAvatarContext('AvatarIndicator');

    return (
      <span
        {...props}
        ref={ref}
        className={classNames(avatarIndicator({ online, size: size || avatar?.size }), className)}
      />
    );
  }
);
AvatarIndicator.displayName = 'AvatarIndicator';

export { Avatar, AvatarImage, AvatarFallback, AvatarIndicator, AvatarGroup };
