import { includes as _includes, reject as _reject } from 'lodash';
import { useMemo } from 'react';

import { useGetMtQuery, useGetTmSuggestionsQuery } from '@/redux/api';
import { MachineTranslationVendor } from '@/redux/api/constants';

import { TMtSuggestion } from '../../types';
import type { IUseParagraphSuggestionsProps } from './types';
import { isValidMtResponse } from './utils';

/**
 * Wrapper hook for TM and MT queries with some extra logic.
 * - {getTmSuggestions} needs to be filtered based on current translation content.
 * - {getMt} needs to be called for every MT provider (DeepL and Google Translate)
 * and results are combined in a single array.
 *
 * @returns an object with combined TM and MT suggestions
 */
export const useParagraphSuggestions = ({
  weConfig: { isMtEnabled, mtProvidersEnabled },
  documentId,
  languageId,
  paragraphId,
  translationContent,
  skip
}: IUseParagraphSuggestionsProps) => {
  // TM suggestions
  const {
    data: tmSuggestionsData,
    isLoading: isTmSuggestionsLoading,
    isFetching: isTmSuggestionsFetching,
    isSuccess: isTmSuggestionsSuccess
  } = useGetTmSuggestionsQuery({ documentId, languageId, paragraphId }, { skip });

  // MT DeepL
  const skipMtDeepL = skip || !isMtEnabled || !_includes(mtProvidersEnabled, MachineTranslationVendor.DEEPL);
  const {
    data: mtDeepLData,
    isLoading: isMtDeepLLoading,
    isFetching: isMtDeepLFetching,
    isSuccess: isMtDeepLSuccess
  } = useGetMtQuery(
    { documentId, languageId, paragraphId, vendor: MachineTranslationVendor.DEEPL },
    { skip: skipMtDeepL }
  );

  // MT Google
  const skipMtGoogle =
    skip || !isMtEnabled || !_includes(mtProvidersEnabled, MachineTranslationVendor.GOOGLE_TRANSLATE);
  const {
    data: mtGoogleData,
    isLoading: isMtGoogleLoading,
    isFetching: isMtGoogleFetching,
    isSuccess: isMtGoogleSuccess
  } = useGetMtQuery(
    { documentId, languageId, paragraphId, vendor: MachineTranslationVendor.GOOGLE_TRANSLATE },
    { skip: skipMtGoogle }
  );

  // Combined states
  const result = useMemo(() => {
    const tmSuggestions = isTmSuggestionsSuccess
      ? _reject(tmSuggestionsData?.suggestions, { content: translationContent })
      : [];

    const mtSuggestions: TMtSuggestion[] = [];
    if (isMtDeepLSuccess && isValidMtResponse(mtDeepLData)) {
      mtSuggestions.push({ ...mtDeepLData, vendor: MachineTranslationVendor.DEEPL });
    }
    if (isMtGoogleSuccess && isValidMtResponse(mtGoogleData)) {
      mtSuggestions.push({ ...mtGoogleData, vendor: MachineTranslationVendor.GOOGLE_TRANSLATE });
    }

    const isLoading = isTmSuggestionsLoading || isMtDeepLLoading || isMtGoogleLoading;
    const isFetching = isTmSuggestionsFetching || isMtDeepLFetching || isMtGoogleFetching;
    const isSuccess = isTmSuggestionsSuccess && isMtGoogleSuccess && isMtGoogleSuccess;

    return { data: { tmSuggestions, mtSuggestions }, isLoading, isFetching, isSuccess };
  }, [
    isMtDeepLFetching,
    isMtDeepLLoading,
    isMtDeepLSuccess,
    isMtGoogleFetching,
    isMtGoogleLoading,
    isMtGoogleSuccess,
    isTmSuggestionsFetching,
    isTmSuggestionsLoading,
    isTmSuggestionsSuccess,
    mtDeepLData,
    mtGoogleData,
    tmSuggestionsData,
    translationContent
  ]);

  return result;
};
