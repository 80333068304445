import { useTranslation } from 'react-i18next';

import { For } from '@/components/v2/For';
import { Surface } from '@/components/v2/Surface';

import { CheckoutFeature } from './CheckoutFeature';

const CheckoutFeatures = () => {
  const { t: tCheckout } = useTranslation('checkout');

  const features = [
    { title: tCheckout('thirtyDaysMoneyBack') },
    { title: tCheckout('cancelAnytime'), description: tCheckout('renewalReminder') },
    {
      title: tCheckout('secureCheckout'),
      description: tCheckout('stripeDescription')
    }
  ];

  return (
    <Surface className="space-y-7 px-7 py-8">
      <For each={features}>
        {(feature, featureIndex) => (
          <CheckoutFeature key={featureIndex} title={feature.title} description={feature.description} />
        )}
      </For>
    </Surface>
  );
};
CheckoutFeatures.displayName = 'CheckoutFeatures';

export { CheckoutFeatures };
