import { forwardRef } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Text } from '@/components/v2/Text';
import { classNames } from '@/utils/classNames';

import { alert, alertIcon } from './styles';
import type { AlertElement, AlertProps } from './types';

const Alert = forwardRef<AlertElement, AlertProps>((props, ref) => {
  const { className, children, status = 'warning', ...divProps } = props;

  const icons = {
    ['warning']: 'ri-information-fill',
    ['error']: 'ri-alert-fill'
  };
  const icon = icons[status!];

  return (
    <div {...divProps} className={classNames(alert({ status }), className)} ref={ref}>
      <div className={alertIcon({ status })}>
        <AccessibleIcon icon={icon} label={status!} />
      </div>

      <Text weight="bold" size="md" color="primary">
        {children}
      </Text>
    </div>
  );
});
Alert.displayName = 'Alert';

export { Alert };
