import {
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTrigger
} from './Modal';

const ModalObject = {
  Root: Modal,
  Close: ModalClose,
  Content: ModalContent,
  Description: ModalDescription,
  Footer: ModalFooter,
  Header: ModalHeader,
  Title: ModalTitle,
  Trigger: ModalTrigger
};

export type {
  ModalCloseProps,
  ModalContentProps,
  ModalDescriptionProps,
  ModalProps,
  ModalTitleProps,
  ModalTriggerProps
} from './types';
export { Modal, ModalClose, ModalContent, ModalDescription, ModalFooter, ModalHeader, ModalTitle, ModalTrigger };

export default ModalObject;
