import { InlineMessage, InlineMessageText, InlineMessageTitle } from './InlineMessage';

export type { InlineMessageProps } from './types';

const InlineMessageDefault = {
  Root: InlineMessage,
  Text: InlineMessageText,
  Title: InlineMessageTitle
};

export { InlineMessage, InlineMessageText, InlineMessageTitle };
export default InlineMessageDefault;
