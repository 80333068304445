import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v2/Box';
import { Button } from '@/components/v2/Button';
import Form from '@/components/v2/Form';
import { Label } from '@/components/v2/Label';
import { TextArea } from '@/components/v2/TextArea';
import { Select, SelectItem } from '@/components/v2/Select';
import { DeleteAccountResonOptions } from '@/redux/api/types';
import { Input } from '@/components/v2/Input';
import { useDeleteCurrentAccountMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';
import { APP_URL } from '@/config/urls';

import { FormBlockIntro } from './components/FormBlockIntro';
import { FormBlockInlineMessage } from './components/FormBlockInlineMessage';
import { TitleBlock } from './components/TitleBlock';
import { z, ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const MIN_REASON_DETAIL_LENGTH = 3;

export const SettingsDeleteAccount: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');

  const [deleteCurrentAccountTrigger, { isLoading: deleteCurrentAccountIsLoading }] = useDeleteCurrentAccountMutation();

  const formSchema: ZodType = z.object({
    reason: z
      .string()
      .trim()
      .min(1, { message: tSettings('thisFieldIsRequired') }),
    reasonDetail: z
      .string()
      .trim()
      .min(MIN_REASON_DETAIL_LENGTH, { message: tSettings('thisFieldIsRequiredAtLeastThreeChars') }),
    password: z.string().min(1, { message: tSettings('thisFieldIsRequired') })
  });

  const form = Form.useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reason: '',
      reasonDetail: '',
      password: ''
    }
  });

  const onSubmit = async ({
    reason,
    reasonDetail,
    password
  }: {
    reason: string;
    reasonDetail: string;
    password: string;
  }) => {
    const [err, res] = await to(
      deleteCurrentAccountTrigger({
        reason: reason as DeleteAccountResonOptions,
        reasonDetail,
        password
      }).unwrap()
    );

    if (!err && res.status === 'ok') {
      window.location.href = `${APP_URL}/logout`;
    }
  };

  const formBlock = (
    <div className="mt-6">
      <Form.Root {...form} onSubmit={onSubmit} className="space-y-6">
        <Box>
          <FormBlockIntro />

          <div className="max-w-sm space-y-7">
            {/* Reason */}
            <Form.Field name="reason">
              <Label isRequired text={tSettings('whyAreYouDeletingYourAccount')} />
              <Form.Control>
                {({ field }) => (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    placeholder={tSettings('selectAnAnswer')}
                    bordered
                  >
                    {Object.keys(DeleteAccountResonOptions).map((item, i) => (
                      <SelectItem
                        value={DeleteAccountResonOptions[item as keyof typeof DeleteAccountResonOptions]}
                        key={i}
                      >
                        {DeleteAccountResonOptions[item as keyof typeof DeleteAccountResonOptions]}
                      </SelectItem>
                    ))}
                  </Select>
                )}
              </Form.Control>
              <Form.Error />
            </Form.Field>

            {/* Reason details */}
            <Form.Field name="reasonDetail">
              <Label isRequired text={tSettings('couldYouTellUsMoreAboutYourExperience')} />
              <Form.Control>
                {({ field }) => <TextArea placeholder={tSettings('itDidntWorkForMeBecause')} {...field} />}
              </Form.Control>
              <Form.Error />
            </Form.Field>

            {/* Password */}
            <Form.Field name="password">
              <Label isRequired text={tSettings('pleaseReEnterYourPassword')} />
              <Form.Control>
                {({ field }) => <Input {...field} type="password" placeholder={tSettings('typeYourPassword')} />}
              </Form.Control>
              <Form.Error />
            </Form.Field>
          </div>
        </Box>

        <FormBlockInlineMessage />

        {/* Button */}
        <div className="flex justify-end">
          <Button disabled={deleteCurrentAccountIsLoading} color="negative">
            {tSettings('permanentlyDeleteMyAccount')}
          </Button>
        </div>
      </Form.Root>
    </div>
  );

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsDeleteAccount')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <TitleBlock />

        {/* Form */}
        {formBlock}
      </div>
    </div>
  );
};
