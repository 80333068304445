import { cva } from 'cva';

const surface = cva('border bg-surface-elements text-typography-primary transition-all', {
  variants: {
    border: {
      false: 'border-transparent',
      true: 'border-gray-200'
    },
    radius: {
      none: 'rounded-none',
      sm: 'rounded-lg',
      md: 'rounded-xl',
      lg: 'rounded-2xl'
    },
    shadow: {
      none: 'shadow-none',
      sm: 'shadow-sm',
      md: 'shadow-md',
      lg: 'shadow-lg'
    }
  },
  defaultVariants: {
    border: false,
    radius: 'md',
    shadow: 'none'
  }
});

export { surface };
