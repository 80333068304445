import { TFunction } from 'i18next';

import { LanguagesDto } from '@/redux/api/types';
import { LanguageGroup } from '@/routes/CreateGlossary/types';

export const createLanguageGroups = (data: LanguagesDto, t: TFunction): LanguageGroup[] => {
  const favoriteGroup: LanguageGroup = {
    name: t('favourites'),
    options: data.active.map((language) => ({
      ...language,
      id: language.id.toString(),
      label: language.name
    }))
  };

  const otherGroup: LanguageGroup = {
    name: t('otherLanguages'),
    options: data.all
      .filter((language) => !data.active.some((l) => l.id === language.id))
      .map((language) => ({
        ...language,
        id: language.id.toString(),
        label: language.name
      }))
  };

  return [favoriteGroup, otherGroup];
};

export function getGroupFilter(inputValue?: string) {
  return function groupFilter(group: LanguageGroup): LanguageGroup {
    const filteredOptions = group.options.filter(
      (item) => !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return {
      ...group,
      options: filteredOptions
    };
  };
}

export const flattenGroupOptions = (options: LanguageGroup[]) =>
  options.reduce((prev: LanguageGroup['options'], curr: { options: LanguageGroup['options'] }) => {
    return [...prev, ...curr.options];
  }, []);
