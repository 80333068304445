import { isRejected, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { some as _some } from 'lodash';

import { EAclErrorCode, EStandardErrorCode } from '@/redux/api/constants';
import { isHttpAclError, isHttpStandardError } from '@/redux/api/utils/isError';
import {
  OTHER_ERROR_IGNORED_ENDPOINTS,
  STANDARD_ERROR_IGNORED_ENDPOINTS
} from '@/redux/middlewares/apiErrorsMiddleware/constants';
import { openErrorModal, openNotEnoughWordsErrorModal, openSessionExpiredModal } from '@/redux/slices/modalsSlice';
import { ErrorModalAction } from '@/redux/slices/modalsSlice/constants';

/**
 * This middleware intercepts rejected API actions and handles them
 * (modals, redirects, Sentry, ...)
 */

const errorsToIgnore: Record<string, boolean> = {
  FETCH_ERROR: true
};
const errorsHttpToIgnore: Record<string, boolean> = {
  404: true
};

export const apiErrorsMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const { payload } = action;
      if (isHttpAclError(payload)) {
        // HTTP ACL errors
        const { data: aclError } = payload;
        switch (aclError.code) {
          case EAclErrorCode.SESSION_EXPIRED: {
            dispatch(openSessionExpiredModal());
            break;
          }
          default: {
            dispatch(openErrorModal({ content: aclError.error }));
            break;
          }
        }
      } else if (isHttpStandardError(payload)) {
        // HTTP standard errors
        const { data: standardError } = payload;

        // Ignore validation errors, they are handled by the forms
        if (standardError.code === EStandardErrorCode.VALIDATION_ERROR) {
          return next(action);
        }

        if (!_some(STANDARD_ERROR_IGNORED_ENDPOINTS, (endpoint) => endpoint.matchRejected(action))) {
          if (errorsHttpToIgnore[payload.status]) {
            return next(action);
          }
          switch (standardError.code) {
            // if user has not enough words show a error custom modal
            case EStandardErrorCode.BALANCE_LOW_NOT_ENOUGH_WORDS: {
              dispatch(openNotEnoughWordsErrorModal());
              break;
            }
            default: {
              dispatch(openErrorModal({ content: standardError.error }));
              break;
            }
          }
        }
      } else {
        // Other errors to ignore
        if (errorsToIgnore[payload.status]) {
          return next(action);
        }

        // Other errors
        if (!_some(OTHER_ERROR_IGNORED_ENDPOINTS, (endpoint) => endpoint.matchRejected(action))) {
          dispatch(openErrorModal());
        }
      }
    } else if (isRejected(action)) {
      const { meta } = action;

      if (meta.aborted) {
        dispatch(openErrorModal({ action: ErrorModalAction.RELOAD }));
      }
    }

    return next(action);
  };
