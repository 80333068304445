import classNames from 'classnames';
import { FunctionComponent } from 'react';

import type { ITagProps } from './types';

export const Tag: FunctionComponent<ITagProps> = ({ color = 'black', size = 'md', children, className, style }) => (
  <div
    className={classNames(
      'inline-block max-w-max rounded px-1',
      {
        'bg-gray-800 text-white': color === 'black',
        'bg-gray-500 text-white': color === 'gray',
        'bg-gray-200 text-gray-500': color === 'lightGray',
        'text-2xs': size === 'xs',
        'text-xs': size === 'sm',
        'text-sm': size === 'md'
      },
      className
    )}
    style={style}
  >
    {children}
  </div>
);
