import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Avatar, AvatarImage } from '@/components/v2/Avatar';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { Text } from '@/components/v2/Text';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useAppDispatch } from '@/redux/hooks';
import { openSendFeedbackModal } from '@/redux/slices/modalsSlice';
import { Beacon } from '@/utils/helpScout';

const AccountDropdownMenu = () => {
  const { t: tLayout } = useTranslation('layout');
  const { currentAccount } = useCurrentAccount();
  const { isAdmin, impersonateInfo } = currentAccount;
  const dispatch = useAppDispatch();

  const openBeacon = () => {
    Beacon('open');
  };

  const openFeedbackModal = () => {
    dispatch(openSendFeedbackModal());
  };
  const isAdminLoginAs = impersonateInfo?.isAdminLoginAs;

  return (
    <>
      {/* https://github.com/radix-ui/primitives/issues/1241#issuecomment-1580887090 */}
      {/* https://www.radix-ui.com/primitives/docs/components/dropdown-menu#root */}
      {/* set modal to false to prevent blocking interation from outside when user open a modal */}
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
          <div className="flex cursor-pointer flex-row items-center justify-center gap-2">
            <Avatar>
              <AvatarImage src={currentAccount.avatarUrl} />
            </Avatar>
            <Text size="md" weight="medium">
              {currentAccount.fullName}
            </Text>
            <AccessibleIcon label="arrow-down" icon="ri-arrow-down-s-line" className="text-xl" />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="start" className="z-10 mx-2">
          <DropdownMenu.Item asChild>
            <Link to={`/settings`}>
              <AccessibleIcon label="settings" icon="ri-settings-3-line" className="text-xl text-alpha-400" />
              {tLayout('settings')}
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={openBeacon}>
            <AccessibleIcon label="information" icon="ri-information-line" className="text-xl text-alpha-400" />
            {tLayout('help')}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={openFeedbackModal}>
            <AccessibleIcon label="send-feedback" icon="ri-send-plane-line" className="text-xl text-alpha-400" />
            {tLayout('sendUsYourFeedback')}
          </DropdownMenu.Item>
          {isAdmin && (
            <DropdownMenu.Item asChild>
              {/* @fragment: need to avoid ButtonLink inherits backgroundColor from parent */}
              <>
                <ButtonLink href={`${APP_URL}/admin`} variant="solid" className="w-full text-white">
                  Go to admin
                </ButtonLink>
              </>
            </DropdownMenu.Item>
          )}
          {isAdminLoginAs && (
            <DropdownMenu.Item asChild>
              <>
                <ButtonLink href={`${APP_URL}/admin/accounts/logout-as-user`} variant="solid" className="text-white">
                  Return to admin
                </ButtonLink>
                <div className="flex flex-col pl-1">
                  <Text className="font-bold">Logged in as: {currentAccount.fullName}</Text>
                  <Text className="font-bold ">ID: {currentAccount.id}</Text>
                </div>
              </>
            </DropdownMenu.Item>
          )}
          <DropdownMenu.Item asChild>
            <a href={`${APP_URL}/logout`} rel="noopener noreferrer">
              <AccessibleIcon label="logout" icon="ri-logout-box-line" className="text-xl text-alpha-400" />
              {tLayout('logout')}
            </a>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
};
AccountDropdownMenu.displayName = 'AccountDropdownMenu';

export { AccountDropdownMenu };
