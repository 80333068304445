import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { to } from '@/utils/awaitToJs';
import { useDeleteGlossaryMutation } from '@/redux/api/glossaryApi';

export interface DeleteGlossaryModalProps extends ModalProps {
  glossaryId: number;
  languageNames: string;
  onDelete?: () => void;
}

export const DeleteGlossaryModal = (props: DeleteGlossaryModalProps) => {
  const { glossaryId, languageNames, children, onDelete, ...modalProps } = props;

  const { t: tGlossary } = useTranslation('glossary');

  const [triggerDeleteGlossary, { isLoading, isSuccess }] = useDeleteGlossaryMutation();

  const handleDeleteClick = async () => {
    const [error] = await to(triggerDeleteGlossary({ id: glossaryId }).unwrap());

    if (!error) {
      onDelete?.();
    }
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="sm">
        <Modal.Header>
          <Modal.Title size="md">{tGlossary('deleteGlossaryQuestion')}</Modal.Title>
          <Modal.Description size="md">
            {tGlossary('youAreAboutToDeleteGlossary', { value: languageNames })}
          </Modal.Description>
          <Modal.Description size="md">{tGlossary('thisActionCannotbeUndone')}</Modal.Description>
        </Modal.Header>

        <Modal.Footer>
          <Button color="negative" onClick={handleDeleteClick} disabled={isLoading || isSuccess}>
            {tGlossary('deleteGlossary')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tGlossary('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
