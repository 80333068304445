import classNames from 'classnames';

export const getCheckboxLabelClassName = ({ disabled, className }: { disabled?: boolean; className?: string }) =>
  classNames(
    'inline-flex items-center space-x-2 text-sm text-gray-800',
    {
      'cursor-not-allowed opacity-50': disabled,
      'hover:cursor-pointer hover:text-gray-900': !disabled
    },
    className
  );

export const getCheckboxInputClassName = ({ disabled }: { disabled?: boolean }) =>
  classNames('h-4 w-4 rounded-sm text-blue-500 disabled:cursor-not-allowed', {
    'hover:cursor-pointer hover:text-blue-600': !disabled
  });
