import { DevTool } from '@hookform/devtools';
import { first, noop, reduce } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { For } from '@/components/v2/For';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { DEV } from '@/config/app';
import { MachineTranslationVendor, TranslationStatus } from '@/redux/api/constants';
import { MtOptions, TranslationMtOption } from '@/routes/PreTranslate/components/TranslationMtOption';
import { TmOptions, TranslationTmOption } from '@/routes/PreTranslate/components/TranslationTmOption';
import {
  LaunchPreTranslateFormProps,
  LaunchPreTranslateFormValues,
  MtOption,
  TmOption
} from '@/routes/PreTranslate/components/types';
import { roundPercentage } from '@/utils/percentage';

export const LaunchPreTranslateForm = (props: LaunchPreTranslateFormProps) => {
  const { t } = useTranslation('pretranslate');
  const { translations, onSubmit = noop } = props;

  const getLanguagesValues = reduce(
    translations,
    (languages, { language, preTranslateOptions, completedPercentage }) => ({
      ...languages,
      [language.id]: {
        tm: Boolean(preTranslateOptions.hasAnyTm) && completedPercentage !== 100,
        mt: true,
        mtVendor: first(preTranslateOptions.mtVendorsAvailable) ?? MachineTranslationVendor.GOOGLE_TRANSLATE
      }
    }),
    {}
  );

  const isFormDisabled = translations.every(({ completedPercentage }) => completedPercentage === 100);

  const form = useForm<LaunchPreTranslateFormValues>({
    defaultValues: {
      languages: getLanguagesValues,
      tm: TmOption.MARK_AS_CONFIRMED,
      mt: MtOption.MARK_AS_DRAFT
    },
    disabled: isFormDisabled
  });

  const disabled = form.formState.disabled || form.formState.isSubmitting || form.formState.isSubmitSuccessful;

  return (
    <FormProvider<LaunchPreTranslateFormValues> {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-12 space-y-3">
          <For each={translations}>
            {(translation) => {
              const isTranslationCompleteOrConfirmed =
                translation.completedPercentage === 100 || translation.status === TranslationStatus.CONFIRMED;

              const translationMtStatus =
                !translation.preTranslateOptions.isMtAvailable &&
                translation.preTranslateOptions.mtVendorsAvailable.length === 0;

              const tmAvailability = isTranslationCompleteOrConfirmed || !translation.preTranslateOptions.hasAnyTm;
              const mtAvailability = isTranslationCompleteOrConfirmed || translationMtStatus;

              return (
                <Surface key={translation.id} className="p-7">
                  <div className="flex items-start justify-between">
                    <div className="flex items-center gap-2">
                      <FlagIcon label={translation.language.name} code={translation.language.flagCode || 'xx'} />

                      <Text color="primary" weight="semibold">
                        {translation.language.name}
                      </Text>

                      <Text color="tertiary" weight="medium" size="sm">
                        {t('currentTranslationTranslated', {
                          percentage: roundPercentage(translation.completedPercentage)
                        })}
                      </Text>
                    </div>

                    <div className="flex gap-6">
                      <TranslationTmOption languageId={translation.language.id} disabled={tmAvailability} />
                      <TranslationMtOption
                        languageId={translation.language.id}
                        preTranslateOptions={translation.preTranslateOptions}
                        disabled={mtAvailability}
                      />
                    </div>
                  </div>
                </Surface>
              );
            }}
          </For>

          <TmOptions />

          <MtOptions />
          <ButtonLink variant="text" color="primary" size="sm" href="https://help.redokun.com/article/24-pre-translate">
            <AccessibleIcon label="ri-lightbulb-fill" icon="ri-lightbulb-fill" />
            {t('seeHowPreTranslateWorks')}
          </ButtonLink>
        </div>

        <div className="flex justify-end">
          <Button type="submit" disabled={disabled}>
            {t('continue')}
            <AccessibleIcon icon="ri-arrow-right-line" label="Continue icon" />
          </Button>
        </div>

        {DEV && <DevTool control={form.control} />}
      </form>
    </FormProvider>
  );
};
