import { redokunApi } from './redokunApi';
import type {
  InvitedTranslatorDto,
  InviteTranslatorArgs,
  RemoveTranslatorArgs,
  TranslatorListDto,
  TranslatorListQueryArgs
} from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const translatorApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    /*
     * Get active translators for the account
     */
    getTranslators: builder.query<TranslatorListDto, TranslatorListQueryArgs>({
      query: ({ filterByLanguageId }) => {
        return {
          url: 'translator',
          params: { filterByLanguageId }
        };
      },
      providesTags: ['translators']
    }),
    /*
     * Invite a translator
     */
    inviteTranslator: builder.mutation<InvitedTranslatorDto, InviteTranslatorArgs>({
      query: (body) => ({ url: `translator`, method: 'POST', body }),
      invalidatesTags: ['translators']
    }),
    /*
     * Remove a translator
     */
    removeTranslator: builder.mutation<InvitedTranslatorDto, RemoveTranslatorArgs>({
      query: ({ translatorAccountId }) => ({ url: `translator/${translatorAccountId}`, method: 'DELETE' }),
      invalidatesTags: ['translators']
    }),
    /*
     * Modify the languages assigned to a translator
     */
    putTranslatorLanguages: builder.mutation<
      { status: string },
      { translatorAccountId: number; languageIds: number[] }
    >({
      query: ({ translatorAccountId, languageIds }) => ({
        url: `translator/${translatorAccountId}/languages`,
        method: 'PUT',
        body: {
          languageIds
        }
      }),
      invalidatesTags: ['translators']
    })
  })
});

export const {
  useGetTranslatorsQuery,
  useLazyGetTranslatorsQuery,
  useInviteTranslatorMutation,
  usePutTranslatorLanguagesMutation,
  useRemoveTranslatorMutation
} = translatorApi;

export const translatorApiUtil = {
  ...translatorApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(translatorApi.util.updateQueryData)
};
