import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { Modal } from '@/components/v1/Modal';
import type { TConfirmModalProps } from '@/components/v1/Modal/types';
import { TextArea } from '@/components/v1/TextArea';
import { useAskLanguageUnlockMutation } from '@/redux/api';
import type { IAskUnlockTranslationModalProps } from '@/redux/slices/modalsSlice/types/props';

export const AskUnlockTranslationModal: FunctionComponent<
  TConfirmModalProps & { props: IAskUnlockTranslationModalProps }
> = ({ props: { documentId, languageId }, ...modalProps }) => {
  const { t } = useTranslation('modals');
  const [notes, setNotes] = useState('');
  const [changeLanguageStatus, { isLoading }] = useAskLanguageUnlockMutation();

  return (
    <Modal.Confirm
      cancelText={t('close')}
      okText={t('askUnlockTranslation')}
      okLoading={isLoading}
      onOk={() => {
        changeLanguageStatus({ documentId, languageId, body: { notes } }).then(() => {
          modalProps.onClose(false);
        });
      }}
      {...modalProps}
    >
      {{
        header: t('askUnlockDocumentTranslation'),
        content: (
          <div className="space-y-4">
            <p>{t('translationLockedYouCanUseThisForm')}</p>

            <div>
              <TextArea
                placeholder={t('addNoteForDocumentOwner')}
                containerClassName="w-full"
                className="max-h-64"
                rows={4}
                value={notes}
                onChangeValue={setNotes}
              />
            </div>

            <Alert message={t('unlockingDocumentWillMakeEditable')} />
          </div>
        )
      }}
    </Modal.Confirm>
  );
};
