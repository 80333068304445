import { FunctionComponent, useMemo } from 'react';

import { For } from '@/components/v1/For';
import { Link } from '@/components/v1/Link';

import { ECommentPartType } from './constants';
import type { ICommentRendererProps } from './types';
import { commentToParts } from './utils';

export const CommentRenderer: FunctionComponent<ICommentRendererProps> = ({
  as: Container = 'div',
  className,
  style,
  comment,
  defaultMentionColor,
  mentionColors = {}
}) => {
  const parts = useMemo(() => commentToParts(comment), [comment]);

  return (
    <Container className={className} style={style}>
      <For each={parts}>
        {(part, index) => {
          switch (part.type) {
            case ECommentPartType.TEXT:
              return part.text;

            case ECommentPartType.MENTION:
              return (
                <span key={index} className={mentionColors[part.accountId]?.text ?? defaultMentionColor?.text}>
                  {part.text}
                </span>
              );

            case ECommentPartType.LINK:
              return (
                <Link key={index} href={part.href} target="_blank" rel="noreferrer">
                  {part.text}
                </Link>
              );

            default:
              return null;
          }
        }}
      </For>
    </Container>
  );
};
