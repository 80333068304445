import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';

import { Show } from '@/components/v1/Show';
import { Tooltip } from '@/components/v1/Tooltip';
import { editorText } from '@/slate/utils';
import { classNames } from '@/utils/classNames';

export interface ITranslationInputCharactersCountProps {
  max?: number;
}

export const TranslationInputCharactersCount = (props: ITranslationInputCharactersCountProps) => {
  const { max } = props;

  const editor = useSlate();

  const characters = editorText(editor).length;
  const overLimit = !!max && characters > max;

  const { t } = useTranslation('documentLanguageTranslation');

  return (
    <Tooltip title={t('charactersTranslationVsCharactersOriginal')} placement="top" containerClassName="inline-flex">
      <span className={classNames('text-2xs font-semibold', overLimit ? 'text-red-600' : 'text-gray-500')}>
        <Show when={max} fallback={characters}>
          {characters}/{max}
        </Show>
      </span>
    </Tooltip>
  );
};
