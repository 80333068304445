import { AccessibleIcon } from '@radix-ui/react-accessible-icon';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { flagIcon } from './styles';
import type { FlagIconElement, FlagIconProps } from './types';

const FlagIcon = forwardRef<FlagIconElement, FlagIconProps>(
  ({ code = 'xx', label, size, className, ...props }, ref) => (
    <AccessibleIcon label={label}>
      {code !== 'xx' ? (
        <span
          {...props}
          ref={ref}
          className={classNames(flagIcon({ size, fallback: false }), className, `fi fis fi-${code}`)}
        />
      ) : (
        <span {...props} ref={ref} className={classNames(flagIcon({ size, fallback: true }), className)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <rect x="6" y="11" width="12" height="2" rx="1" fill="#81888F" />
          </svg>
        </span>
      )}
    </AccessibleIcon>
  )
);
FlagIcon.displayName = 'FlagIcon';

export { FlagIcon };
