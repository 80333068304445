export const HELP_URL = 'https://help.redokun.com';

const getAppUrl = () => {
  switch (import.meta.env.REACT_APP_ENV) {
    case 'development':
      return 'https://dev.redokun.lan';
    case 'staging':
      return 'https://app-staging.redokun.com';
    case 'production':
    default:
      return 'https://app.redokun.com';
  }
};

export const APP_URL = getAppUrl();
