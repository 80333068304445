import type { RenderElementProps } from 'slate-react';
import { DefaultElement } from 'slate-react';

import { EElementType } from '../constants';
import { ParagraphElement } from './ParagraphElement';
import { PlaceholderElementV1, PlaceholderElementV2 } from './PlaceholderElement';

export const renderElement = (props: RenderElementProps) => {
  const { element, ...elementProps } = props;

  switch (element.type) {
    case EElementType.PLACEHOLDER_V1:
      return <PlaceholderElementV1 element={element} {...elementProps} />;

    case EElementType.PLACEHOLDER_V2:
      return <PlaceholderElementV2 element={element} {...elementProps} />;

    case EElementType.PARAGRAPH:
      return <ParagraphElement element={element} {...elementProps} />;

    default:
      return <DefaultElement element={element} {...elementProps} />;
  }
};
