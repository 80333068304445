import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { isNumber as _isNumber } from 'lodash';

import type { TAppState } from '../../store/types';

const selectTeamMembersState = (state: TAppState) => state.teamMembers;

export const selectTeamMemberColor = (id: number | undefined) =>
  createDraftSafeSelector(selectTeamMembersState, (teamMembers) =>
    _isNumber(id) ? teamMembers.colors[id] : undefined
  );
