import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import AppLayout from '@/components/layouts/AppLayout';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Navbar } from '@/components/v2/Navbar';

export const Layout = ({ children }: { children: ReactNode }) => {
  const { t: tLayout } = useTranslation('layout');

  return (
    <AppLayout.Root title={tLayout('createNewGlossary')}>
      <Navbar sticky>
        <ButtonLink href={`/glossaries`} variant="surface" color="secondary" className="ml-auto">
          {tLayout('close')}
        </ButtonLink>
      </Navbar>

      <AppLayout.Content>{children}</AppLayout.Content>
    </AppLayout.Root>
  );
};
