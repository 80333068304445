import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import type { IDocumentPreTranslatedModalProps } from '@/redux/slices/modalsSlice/types/props';
import { isNonNullable } from '@/utils/isNonNullable';

export const DocumentPreTranslatedModal: FunctionComponent<
  TJumboModalProps & { props: IDocumentPreTranslatedModalProps }
> = ({ props: { accountName }, ...modalProps }) => {
  const { t } = useTranslation('modals');

  return (
    <Modal.Jumbo {...modalProps}>
      {{
        header: isNonNullable(accountName) ? t('documentPreTranslatedBy', { accountName }) : t('documentPreTranslated'),
        content: isNonNullable(accountName)
          ? t('justPreTranslatedThisDocumentPleaseViewUpdatedDocument', { accountName })
          : t('documentJustBeenPreTranslatedPleaseViewUpdatedDocument'),
        footer: (
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('reloadThePage')}
          </Button>
        )
      }}
    </Modal.Jumbo>
  );
};
