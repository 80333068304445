import { useCombobox, useMultipleSelection } from 'downshift';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import { Checkbox } from '@/components/v2/Checkbox';
import Combobox from '@/components/v2/Combobox';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Show } from '@/components/v2/Show';
import { Skeleton } from '@/components/v2/Skeleton';
import { Text } from '@/components/v2/Text';
import { useGetLanguagesQuery } from '@/redux/api';
import { LanguageGroup, LanguageOption } from '@/routes/Document/types';
import {
  createTargetLanguageInUseGroup,
  doesLanguageExist,
  flattenGroupOptions,
  getGroupFilter
} from '@/routes/Document/utils';
import { useDocumentListSearchParams } from '@/routes/DocumentList/hooks/useDocumentListSearchParams';

const SELECTED_GROUP = 'Selected';

export const TargetLanguageCombobox = () => {
  const [
    { filterByTargetLanguageIds: filterValueFromUrl },
    { setFilterByTargetLanguageIds: setFilterValueFromUrl, setSavedView }
  ] = useDocumentListSearchParams();

  const { t: tDocumentList } = useTranslation('documentList');
  const { t: tDocument } = useTranslation('document');

  const { data, isLoading, isUninitialized } = useGetLanguagesQuery();

  const [inputSearch, setInputSearch] = useState<string | undefined>();
  const [selectedItems, setSelectedItems] = useState<LanguageOption[]>([]);

  useEffect(() => {
    if (data && filterValueFromUrl) {
      const languageGroups = createTargetLanguageInUseGroup(data, tDocument);
      const options = flattenGroupOptions(languageGroups);
      const currentLanguagesSelected = options.filter((option) => filterValueFromUrl.includes(parseInt(option.id)));
      setSelectedItems(currentLanguagesSelected);
    }
  }, [data, filterValueFromUrl, tDocument]);

  const languageGroups = data ? createTargetLanguageInUseGroup(data, tDocument) : [];
  const options = flattenGroupOptions(languageGroups);

  const { removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            setSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    }
  });

  const state = useCombobox({
    items: options,
    itemToString(item) {
      return item ? item.label : '';
    },
    inputValue: inputSearch,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputClick:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            // keep the menu open after selection. only if is already opened
            isOpen: state.isOpen
          };
        default:
          return changes;
      }
    },
    onStateChange({ type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            if (selectedItems && doesLanguageExist(selectedItems, newSelectedItem.id)) {
              // need to pass the original object to removeSelectedItem otherwise it cannot match the index
              // therefore it doesn't remove the element
              const originalItem = selectedItems.find((item) => item.id === newSelectedItem.id);
              if (originalItem) {
                removeSelectedItem(originalItem);
              }
            } else {
              setSelectedItems([...(selectedItems || []), newSelectedItem]);
            }
          }
          break;
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          setInputSearch('');
          break;
        default:
          break;
      }
    }
  });

  const renderGroups = (filteredLanguageGroups: LanguageGroup[]) => {
    const groupedItems: { [key: string]: LanguageGroup } = {};
    const selectedGroup: LanguageGroup = { name: tDocument('selected'), options: [] };

    for (const section of filteredLanguageGroups) {
      const filteredOptions = section.options.filter((option) => {
        const isSelected = selectedItems && doesLanguageExist(selectedItems, option.id);
        if (isSelected) {
          selectedGroup.options.push(option);
          // exclude selected items from the original group
          return false;
        }
        // include non-selected items in the original group
        return true;
      });

      if (!isEmpty(filteredOptions)) {
        groupedItems[section.name] = { ...section, options: filteredOptions };
      }
    }

    // "Selected" group must be the first in the list
    const orderedGroups = [SELECTED_GROUP, ...Object.keys(groupedItems)];

    return orderedGroups.map((groupName, sectionIndex) => {
      const group = groupName === SELECTED_GROUP ? selectedGroup : groupedItems[groupName];

      if (!group || isEmpty(group.options)) {
        // skip rendering if the group is undefined or empty
        return null;
      }

      return (
        <div key={sectionIndex}>
          <Text size="sm" color="tertiary" weight="semibold" className="mx-2 my-2.5">
            {group.name}
          </Text>
          {group.options.map((option, optionIndex) => {
            const index = options.indexOf(option);
            return (
              <Combobox.Item key={optionIndex} item={option} index={index} className="gap-3">
                <Checkbox
                  checked={selectedItems && doesLanguageExist(selectedItems, option.id)}
                  onClick={(e) => e.preventDefault()}
                />
                <FlagIcon label={option.name} code={option.flagCode || 'xx'} size="md" />
                {option.label}
              </Combobox.Item>
            );
          })}
        </div>
      );
    });
  };

  const handleClearFilter = () => {
    setInputSearch('');
    // clear document list
    setFilterValueFromUrl(undefined);
    setSavedView(null);
  };

  useEffect(() => {
    if (isEmpty(filterValueFromUrl)) setSelectedItems([]);
  }, [filterValueFromUrl]);

  const handleOnClick = () => {
    if (selectedItems) {
      setFilterValueFromUrl(selectedItems.flatMap((s) => parseInt(s.id)));
      setSavedView(null);
    }
  };

  const filteredLanguageGroups: LanguageGroup[] = inputSearch
    ? languageGroups.map(getGroupFilter(inputSearch))
    : languageGroups;

  const label = selectedItems?.flatMap((s) => s.label).join(', ');

  if (isUninitialized || isLoading) {
    return <Skeleton width="13rem" height="2.25rem" />;
  }
  return (
    <Combobox.Root state={state} className="max-w-[256px]">
      <Combobox.Trigger bordered className="h-9 place-content-start rounded-xl">
        <Text weight="medium" color="tertiary" className="min-w-[120px]">
          {tDocumentList('targetLanguage')}
        </Text>

        <OverflowTooltip label={label}>
          <Text size="md" color="primary" className="whitespace-nowrap">
            <Show when={isEmpty(selectedItems)} fallback={label}>
              {tDocumentList('all')}
            </Show>
          </Text>
        </OverflowTooltip>
        <Combobox.TriggerIcon className="ml-auto" />
      </Combobox.Trigger>

      <Combobox.Content>
        <Combobox.SearchItem
          placeholder={tDocumentList('typeLanguage')}
          onClick={(e) => e.preventDefault()}
          value={inputSearch}
          onChange={(e) => setInputSearch((e.target as HTMLInputElement).value)}
          size="sm"
        />
        <Combobox.ItemsContainer className="max-h-56">{renderGroups(filteredLanguageGroups)}</Combobox.ItemsContainer>
        <Button
          variant="solid"
          color="primary"
          className="mt-1 w-full"
          size="sm"
          onClick={handleOnClick}
          type="button"
          disabled={!selectedItems}
        >
          {tDocumentList('filterTargetLanguage')}
        </Button>
        <Button
          variant="solid"
          color="secondary"
          className="mt-1 w-full"
          size="sm"
          onClick={handleClearFilter}
          type="button"
          disabled={!selectedItems}
        >
          {tDocumentList('clearFilter')}
        </Button>
      </Combobox.Content>
    </Combobox.Root>
  );
};
