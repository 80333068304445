import { cva } from 'cva';

export const inlineMessageVariant = cva('flex min-w-min items-center gap-4 rounded-lg border p-3', {
  variants: {
    type: {
      informational: 'border-primary-active bg-surface-accent text-blue-500',
      success: 'border-states-positive-active bg-surface-success text-green-500',
      warning: 'border-states-alert-active bg-surface-alert text-yellow-500',
      error: 'border-states-negative-active bg-surface-error text-red-500'
    },
    size: {
      sm: 'gap-1 px-2 py-1'
    }
  },
  defaultVariants: {
    type: 'informational'
  }
});
