import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DataTable from '@/components/v2/DataTable';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { GlossaryEntryListItemDto } from '@/redux/api/types';
import { DeleteEntryModal } from '@/routes/GlossaryDetail/components/DeleteEntryModal';

enum ModalKey {
  ENTRY_DELETE = 'ENTRY_DELETE'
}

interface ActionCellProps {
  glossaryId: number;
}

export const ActionCell = ({ glossaryId }: ActionCellProps) => {
  const { t: tGlossary } = useTranslation('glossary');

  const { cell } = DataTable.useCellContext<GlossaryEntryListItemDto>('ActionsCell');
  const { currentAccount } = useCurrentAccount();

  const entryId = cell.row.original.id;

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState<ModalKey | null>(null);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  const canDeleteGlossary = !currentAccount?.subscriptionInfo.isTranslator;

  // https://github.com/radix-ui/primitives/issues/1836
  const handleModalItemSelect = (key: ModalKey) => (event: Event) => {
    event.preventDefault();
    setMenuOpen(false);
    setModalOpen(key);
  };

  // https://github.com/radix-ui/primitives/issues/1836
  const modalProps = (key: ModalKey) => ({
    open: modalOpen === key,
    onOpenChange: (open: boolean) => {
      if (open) {
        setMenuOpen(false);
        setModalOpen(key);
      } else {
        setModalOpen(null);
        menuTriggerRef.current?.focus();
      }
    }
  });

  return (
    <Show when={canDeleteGlossary}>
      <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
          <AccessibleIcon
            ref={menuTriggerRef}
            icon="ri-more-2-fill"
            label="ri-more-2-fill"
            className="cursor-pointer text-lg text-primary-active"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <RequireSubscription>
            <DropdownMenu.Item asChild>
              <Link to={`/glossaries/show/${glossaryId}/entry/${entryId}/edit`}>
                <AccessibleIcon icon="ri-edit-2-line" label="ri-edit-2-line" />
                {tGlossary('editEntry')}
              </Link>
            </DropdownMenu.Item>
          </RequireSubscription>

          <DropdownMenu.Item
            onSelect={handleModalItemSelect(ModalKey.ENTRY_DELETE)}
            className="text-states-negative-active"
          >
            <AccessibleIcon icon="ri-delete-bin-5-line" label="ri-delete-bin-5-line" />
            {tGlossary('delete')}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
        <DeleteEntryModal {...modalProps(ModalKey.ENTRY_DELETE)} glossaryId={glossaryId} entryId={entryId} />
      </DropdownMenu.Root>
    </Show>
  );
};
