import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';

import { ChevronDownIcon } from '@/icons/solid';
import { isFunction } from '@/utils/isFunction';

import { Option } from './Option';
import type { ISelectProps } from './types';
import { findOptions } from './utils';

export const Select = <T extends number | string>({
  value,
  onChange,
  disabled,
  as,
  children,
  triggerAs,
  triggerClassName,
  trigger,
  optionsPlacement = 'middle'
}: ISelectProps<T>) => {
  const options = findOptions<T>(children);
  const resolvedTrigger =
    trigger !== undefined ? trigger : options.find((option) => option.props.value === value)?.props.trigger;

  return (
    <Listbox
      as={as || 'div'}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className="relative text-sm text-gray-800"
    >
      <Listbox.Button
        as={triggerAs}
        // @ts-expect-error: TS 5 migration error
        className={(props) =>
          classNames(
            'flex h-6 items-center rounded border border-gray-300 bg-white px-2 font-bold',
            { 'opacity-50 hover:cursor-not-allowed': props.disabled },
            isFunction(triggerClassName) ? triggerClassName(props) : triggerClassName
          )
        }
      >
        {(props) => (
          <>
            <span className="flex flex-1">
              {isFunction(resolvedTrigger) ? resolvedTrigger(props) : resolvedTrigger}
            </span>
            <ChevronDownIcon rotation={props.open ? 180 : undefined} className="ml-2 text-gray-500" />
          </>
        )}
      </Listbox.Button>

      <Transition
        as={Fragment}
        enter="transition-opacity ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          className={classNames(
            'absolute z-10 mt-0.5 max-h-96 overflow-y-auto rounded-sm border border-gray-300 bg-white py-1 shadow-2xl',
            {
              'left-0 min-w-max': optionsPlacement === 'left',
              'right-0 min-w-max': optionsPlacement === 'right',
              'left-0 right-0': optionsPlacement === 'middle'
            }
          )}
        >
          {options.map((option, i) => (
            <Fragment key={i}>{option}</Fragment>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

Select.Option = Option;
