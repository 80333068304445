import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { TLinkProps } from './types';

export const Link: FunctionComponent<TLinkProps> = ({ className, color = 'blue', children, ...htmlAProps }) => (
  <a
    {...htmlAProps}
    className={classNames(
      'hover:underline',
      {
        'text-blue-500 hover:text-blue-600': color === 'blue',
        'text-gray-800 hover:text-gray-900': color === 'black'
      },
      className
    )}
  >
    {children}
  </a>
);
