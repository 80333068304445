import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { entries, reduce } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetBillingQuery, usePutBillingMutation } from '@/redux/api/billingApi';
import { EStandardErrorCode } from '@/redux/api/constants';
import { useGetCountryQuery } from '@/redux/api/countryApi';
import { CurrentAccountDto, GetBillingDetailResponseDto } from '@/redux/api/types';
import { isHttpStandardError } from '@/redux/api/utils/isError';
import { to } from '@/utils/awaitToJs';

import {
  BillingDetailsForm,
  BillingDetailsFormContent,
  BillingDetailsFormSchema,
  BillingDetailsFormSkeleton
} from './components/BillingDetailsForm';
import { CheckoutFeatures } from './components/CheckoutFeatures';

const CheckoutBilling = () => {
  const history = useHistory();

  const { t: tCheckout } = useTranslation('checkout');

  const { currentAccount } = useCurrentAccount();

  const { data: countriesData, isLoading: isCountriesLoading } = useGetCountryQuery();
  const countries = countriesData?.countries ?? [];

  const { data: billingData, isLoading: isBillingLoading } = useGetBillingQuery();
  const [putBilling, { error: putBillingError, isLoading: isPutBillingLoading }] = usePutBillingMutation();

  const formValues = useMemo(
    () => getFormValues({ currentAccount, billingDetail: billingData }),
    [billingData, currentAccount]
  );
  const formErrors = useMemo(() => getFormErrors(putBillingError), [putBillingError]);

  const handleSubmit = async (data: BillingDetailsFormSchema) => {
    const body = {
      ...data,
      vatNumber: data.vatNumber || null,
      billingEmail: data.billingEmail || null,
      recipientCode: data.recipientCode || null
    };
    const [error] = await to(putBilling({ body }).unwrap());
    if (!error) {
      history.push({ ...history.location, pathname: '/checkout/payment-method' });
    }
  };

  if (isCountriesLoading || isBillingLoading) {
    return (
      <div>
        <div className="grid grid-cols-2 items-start gap-8">
          <CheckoutFeatures />

          <BillingDetailsFormSkeleton />
        </div>

        <div className="mt-8 flex items-center justify-between gap-8">
          <ButtonLink href="/settings/plans" variant="text">
            <AccessibleIcon icon="ri-arrow-left-line" label="" />
            {tCheckout('back')}
          </ButtonLink>

          <Button disabled>
            {tCheckout('continue')}
            <AccessibleIcon icon="ri-arrow-right-line" label="" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <BillingDetailsForm
      countries={countries}
      defaultValues={formValues}
      errors={formErrors}
      values={formValues}
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-2 items-start gap-8">
        <CheckoutFeatures />

        <BillingDetailsFormContent countries={countries} />
      </div>

      <div className="mt-8 flex items-center justify-between gap-8">
        <ButtonLink href="/settings/plans" variant="text">
          <AccessibleIcon icon="ri-arrow-left-line" label="" />
          {tCheckout('back')}
        </ButtonLink>

        <Button type="submit" disabled={isPutBillingLoading}>
          {tCheckout('continue')}
          <Show when={isPutBillingLoading} fallback={<AccessibleIcon icon="ri-arrow-right-line" label="" />}>
            <Spinner size="sm" />
          </Show>
        </Button>
      </div>
    </BillingDetailsForm>
  );
};
CheckoutBilling.displayName = 'CheckoutBilling';

const getFormValues = (data: {
  currentAccount: CurrentAccountDto;
  billingDetail: GetBillingDetailResponseDto | undefined;
}) => {
  const { currentAccount, billingDetail } = data;

  return {
    companyName: billingDetail?.companyName ?? '',
    addressCountryId: billingDetail?.countryId ?? currentAccount.country?.id ?? ('' as unknown as number),
    addressStreet: billingDetail?.address ?? '',
    addressCity: billingDetail?.city ?? '',
    addressZipCode: billingDetail?.zipCode ?? '',
    addressProvince: billingDetail?.province ?? '',
    vatNumber: billingDetail?.vatNumber ?? '',
    billingEmail: billingDetail?.billingEmail ?? '',
    recipientCode: billingDetail?.recipientCode ?? ''
  };
};

const getFormErrors = (error: FetchBaseQueryError | SerializedError | undefined) => {
  if (!isHttpStandardError(error)) {
    return;
  }

  if (error.data.code === EStandardErrorCode.VALIDATION_ERROR) {
    return reduce(
      entries(error.data.fieldsWithErrors),
      (acc, [field, message]) => ({ ...acc, [field]: { type: 'server', message } }),
      {}
    );
  }

  return undefined;
};

export { CheckoutBilling };
