import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { useGetGlossaryByIdQuery } from '@/redux/api/glossaryApi';
import { assert } from '@/utils/assert';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { Show } from '@/components/v2/Show';
import DataTable from '@/components/v2/DataTable';
import { useColumns } from '@/routes/GlossaryDetail/columns';
import InlineMessage from '@/components/v2/InlineMessage';
import { Beacon } from '@/utils/helpScout';
import { GlossaryDetailContentSkeleton } from '@/routes/GlossaryDetail/components/GlossaryDetailContent/components/GlossaryDetailContentSkeleton';

interface GlossaryDetailContentProps {
  glossaryId: number;
}

export const GlossaryDetailContent = ({ glossaryId }: GlossaryDetailContentProps) => {
  const { t: tGlossary } = useTranslation('glossary');
  const { currentAccount } = useCurrentAccount();
  const { maxGlossaryEntries } = currentAccount.subscriptionInfo;
  const { data, isLoading, isUninitialized, isFetching } = useGetGlossaryByIdQuery({
    id: glossaryId
  });
  const columns = useColumns({ glossaryId });

  if (isLoading || isUninitialized) {
    return <GlossaryDetailContentSkeleton />;
  }

  assert(data, 'Glossary data not found');

  const entries = data.entries;
  const { entryCount, glossaryMatchIsBeta } = data.glossary;

  const openBeacon = () => {
    Beacon('open');
    Beacon('navigate', 'ask/message');
  };

  return (
    <div className="flex flex-col gap-7">
      <Show when={glossaryMatchIsBeta}>
        <InlineMessage.Root rootClassName="w-full" orientation="vertical" type="warning">
          <InlineMessage.Title>{tGlossary('theGlossaryIsStillInBetaForThisLanguage')}</InlineMessage.Title>
          <InlineMessage.Text>
            <Trans
              i18nKey="glossary:ifYouEncounterAnyIssuesReachOutToOurSupportTeam"
              components={{ 1: <span className="cursor-pointer underline" onClick={openBeacon} /> }}
            />
          </InlineMessage.Text>
        </InlineMessage.Root>
      </Show>
      <Surface radius="sm" className="flex flex-row px-7 py-8">
        <div className="flex w-1/2 flex-col gap-1">
          <Text color="tertiary" size="sm" weight="medium">
            {tGlossary('numberOfEntries')}
          </Text>
          <Text color="secondary" size="xl" weight="bold">
            {`${entryCount}/${maxGlossaryEntries}`}
          </Text>
        </div>
        <div className="flex w-1/2 flex-col justify-center">
          <Text color="tertiary" size="sm" weight="medium">
            {tGlossary('youCanAddMaximumOfEntriesPerGlossary', { value: maxGlossaryEntries })}
          </Text>
          <Text color="tertiary" size="sm" weight="medium">
            <Trans
              i18nKey="glossary:pleaseUpgradeYourPlanToIncreaseTheLimit"
              // TODO: add plan link once the page is ready
              components={{
                1: <Link to="/settings/current-plan" className="text-primary-active underline" />
              }}
            />
          </Text>
        </div>
      </Surface>

      <Show
        when={isNotEmpty(entries)}
        fallback={
          <Surface className="flex flex-col items-center px-7 py-8">
            <AccessibleIcon
              icon="ri-menu-add-line"
              label="ri-menu-add-line"
              className="text-3xl text-typography-tertiary"
            />
            <div className="flex max-w-sm flex-col items-center gap-2">
              <Text color="tertiary" size="md" weight="bold">
                {tGlossary('thisGlossaryHasNoEntryYet')}
              </Text>
              <Text color="tertiary" size="md" weight="regular">
                {tGlossary('youCanCreateASingleEntryOrUploadAnExistingGlossary')}
              </Text>
            </div>
          </Surface>
        }
      >
        <Surface className="flex flex-col items-center px-7 py-8">
          <DataTable.Root columns={columns} data={entries} loading={isFetching}>
            <DataTable.Content>
              <DataTable.Header />
              <DataTable.Body />
            </DataTable.Content>
          </DataTable.Root>
        </Surface>
      </Show>
    </div>
  );
};
