import { Transition } from '@headlessui/react';
import type { VirtualElement } from '@popperjs/core';
import type { FocusEvent, KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { Descendant, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { EKeyboardEventKey } from '@/utils/keyboardEvent';
import { composeEventHandlers } from '@/utils/composeEventHandlers';
import { editorIsValueChanged, valueToXliff } from '@/slate/utils';
import { Show } from '@/components/v1/Show';

import { TranslationInputCharactersCount } from '../TranslationInputCharactersCount';
import { TranslationInputEditable } from '../TranslationInputEditable';
import { TranslationInputRoot } from '../TranslationInputRoot';
import type { ITranslationInputProps } from '../types';
import { AvailablePlaceholderItem } from './AvailablePlaceholderItem';
import { useTranslationEditor } from './useTranslationEditor';
import { insertPlaceholder } from './utils';

export const TranslationInput = ({
  initialTranslationContent,
  placeholders,
  status,
  icon,
  footer,
  disabled,
  readOnly,
  autoFocus,
  search,
  dir,
  languageCode,
  liveCharacterCount,
  liveCharacterCountLimit,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyUp
}: ITranslationInputProps) => {
  const { t } = useTranslation('documentLanguageTranslation');
  const {
    editor,
    initialValue,
    onChange: onInternalChange,
    selection,
    availablePlaceholders
  } = useTranslationEditor({ initialTranslationContent, placeholders });

  // Placeholders menu
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [reference, setReferenceElement] = useState<Element | VirtualElement | null>();
  const [popper, setPopperElement] = useState<HTMLElement | null>();
  const { styles, attributes } = usePopper(reference, popper, {
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }]
  });

  const handleChange = (value: Descendant[]) => {
    const valueChanged = editorIsValueChanged(editor);
    if (valueChanged) {
      const translationContent = valueToXliff(editor.children);
      onChange?.(translationContent);
    }

    onInternalChange(value);
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    if (open) {
      return;
    }

    onBlur?.(event);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (open) {
      switch (event.key) {
        case EKeyboardEventKey.ARROW_DOWN: {
          event.preventDefault();
          const placeholdersCount = availablePlaceholders.length;
          setIndex((index) => (index + 1) % placeholdersCount);
          return;
        }

        case EKeyboardEventKey.ARROW_UP: {
          event.preventDefault();
          const placeholdersCount = availablePlaceholders.length;
          setIndex((index) => (index - 1 + placeholdersCount) % placeholdersCount);
          return;
        }

        case EKeyboardEventKey.ENTER:
        case EKeyboardEventKey.TAB: {
          event.preventDefault();
          const placeholder = availablePlaceholders[index];
          if (placeholder) {
            insertPlaceholder(editor, placeholder);
            setOpen(false);
          }
          return;
        }

        case EKeyboardEventKey.ESCAPE: {
          event.preventDefault();
          setOpen(false);
          return;
        }
      }

      const placeholder = availablePlaceholders.find((p) => p.shortcut === event.key);
      if (placeholder) {
        event.preventDefault();
        insertPlaceholder(editor, placeholder);
        setOpen(false);
        return;
      }
    }

    composeEventHandlers(onKeyDown, (event) => {
      // Japanese and other asian languages use the composer (eg. Microsoft IME – Input Method Editor)
      // We should ignore RETURN, TAB or other inputs when the user is using the composer, as it
      // will create unexpected issues (see HS #994).
      if (event.nativeEvent.isComposing) {
        return;
      }

      if (event.key === EKeyboardEventKey.TAB && !event.shiftKey) {
        // Tab key, insert a placeholder
        event.preventDefault();
        setIndex(0);
        setOpen(true);
      }
    })(event);
  };

  useEffect(() => {
    if (selection) {
      const reference = {
        getBoundingClientRect: () => {
          const domRange = ReactEditor.toDOMRange(editor, selection);
          return domRange.getBoundingClientRect();
        }
      };
      setReferenceElement(reference);
    } else {
      setReferenceElement(null);
    }
  }, [editor, selection]);

  return (
    <TranslationInputRoot editor={editor} initialValue={initialValue} onChange={handleChange} status={status}>
      <div className="flex h-full flex-col">
        <TranslationInputEditable
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          disabled={disabled}
          readOnly={readOnly}
          dir={dir}
          lang={languageCode}
          search={search}
          placeholder={t('clickToAddTranslation')}
          message={footer}
          action={icon}
          onKeyDown={handleKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={handleBlur}
        />

        <Show when={liveCharacterCount}>
          <div className="flex justify-end">
            <TranslationInputCharactersCount max={liveCharacterCountLimit} />
          </div>
        </Show>
      </div>

      <Transition
        as="ul"
        ref={setPopperElement}
        show={open}
        className="z-10 max-h-80 overflow-y-auto rounded-sm border border-gray-300 bg-white py-1 opacity-0 shadow-2xl"
        enter="transition-opacity ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        style={styles.popper}
        {...attributes.popper}
      >
        <Show when={availablePlaceholders.length} fallback={<div className="px-4 py-1">No placeholders left</div>}>
          {availablePlaceholders.map((availablePlaceholder, availablePlaceholderIndex) => {
            const focused = index === availablePlaceholderIndex;

            return (
              <AvailablePlaceholderItem
                key={availablePlaceholder.placeholder.id}
                value={availablePlaceholder}
                focused={focused}
                onClick={() => {
                  insertPlaceholder(editor, availablePlaceholder);

                  const { selection } = editor;
                  if (selection) {
                    ReactEditor.focus(editor);
                    Transforms.select(editor, selection);
                  }

                  setOpen(false);
                }}
              />
            );
          })}
        </Show>
      </Transition>
    </TranslationInputRoot>
  );
};
