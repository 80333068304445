import { forwardRef } from 'react';

import { usePagination, UsePaginationItem } from '@/components/v2/Pagination/hooks/usePagination';
import { Text } from '@/components/v2/Text';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { classNames } from '@/utils/classNames';
import { Button } from '@/components/v2/Button';
import { DEFAULT_PAGE_INDEX } from '@/components/v2/DataTable';

import { PaginationProps, PaginationButtonProps } from './types';

// TODO: add variants and styles

/**
 * Pagination component for displaying and navigating through multiple pages.
 * @param {string} size - The size variant for the Pagination component. Must be one of the available size variants.
 * @param {number} numberOfPages - The total number of pages.
 * @param {number} currentPage - The currently active page.
 * @param {function} onChangePage - The callback function triggered when the page is changed. It receives the event and the new page as parameters.
 * @param {boolean} disabled - Determines whether the Pagination component is disabled or not. Defaults to false.
 */
export const Pagination = forwardRef<HTMLDivElement, PaginationProps>((props, ref) => {
  const { numberOfPages, currentPage, onChangePage, size = 'sm', disabled: isDisabled, className } = props;

  const { items } = usePagination({
    count: numberOfPages,
    onChange: onChangePage,
    page: currentPage,
    defaultPage: DEFAULT_PAGE_INDEX
  });

  const renderItems = (items: UsePaginationItem[]) =>
    items.map(({ page, type, selected, disabled, onClick }, index) => {
      let children = null;

      switch (type) {
        case 'end-ellipsis':
        case 'start-ellipsis':
          children = (
            <PaginationButton size={size} disabled={isDisabled} style={{ pointerEvents: 'none' }}>
              ...
            </PaginationButton>
          );
          break;
        case 'page':
          children = (
            <PaginationButton
              size={size}
              active={selected}
              disabled={disabled || isDisabled}
              onClick={(e) => onClick(e)}
            >
              {page}
            </PaginationButton>
          );
          break;
        case 'previous':
          children = (
            <Button
              color="secondary"
              variant="solid"
              size={size}
              disabled={disabled}
              className="h-8 w-8"
              onClick={(e) => onClick(e)}
            >
              <AccessibleIcon icon="ri-arrow-left-s-line" label="ri-arrow-left-s-line" />
            </Button>
          );
          break;
        case 'next':
          children = (
            <Button
              color="secondary"
              variant="surface"
              size={size}
              disabled={disabled}
              className="h-8 w-8"
              onClick={(e) => onClick(e)}
            >
              <AccessibleIcon icon="ri-arrow-right-s-line" label="ri-arrow-right-s-line" />
            </Button>
          );
          break;
      }

      return <li key={index}>{children}</li>;
    });

  return (
    <div ref={ref} className={classNames('flex w-auto flex-row', className, isDisabled && 'pointer-events-none')}>
      <ul className="mx-1 flex flex-grow list-none justify-around gap-2">{renderItems(items)}</ul>
    </div>
  );
});

Pagination.displayName = 'Pagination';

export const PaginationButton = forwardRef<HTMLButtonElement, PaginationButtonProps>((props, ref) => {
  const { size = 'sm', children, active, className, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(
        'flex h-full items-center justify-center rounded-lg px-3',
        { 'border border-gray-200': active },
        className
      )}
      {...rest}
    >
      <Text size={size} weight="regular" color="secondary">
        {children}
      </Text>
    </button>
  );
});

PaginationButton.displayName = 'PaginationButton';
