import { ReactNode } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';

const CheckoutFeature = (props: CheckoutFeatureProps) => {
  const { title, description } = props;

  return (
    <div className="flex items-start gap-4">
      <AccessibleIcon icon="ri-check-fill" label="check" className="text-[1.75rem] leading-none text-green-400" />

      <div className="space-y-2 pt-0.5">
        <Text weight="medium">{title}</Text>

        <Show when={description}>
          <Text size="sm">{description}</Text>
        </Show>
      </div>
    </div>
  );
};
CheckoutFeature.displayName = 'CheckoutFeature';

interface CheckoutFeatureProps {
  title: ReactNode;
  description?: ReactNode;
}

export { CheckoutFeature };
export type { CheckoutFeatureProps };
