import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { Button } from '@/components/v2/Button';
import { Navbar } from '@/components/v2/Navbar';
import { useGetDocumentDetailQuery, useGetExportDocumentSegmentsMutation } from '@/redux/api';
import { EXPORT_FORMATS } from '@/redux/api/types';
import { assert } from '@/utils/assert';

import { Checkbox } from '../../components/v2/Checkbox';
import { InlineMessage } from '../../components/v2/InlineMessage';
import { Select, SelectItem } from '../../components/v2/Select';
import { Text } from '../../components/v2/Text';
import { Box } from './components/Box';
import { SourceDocumentHeader } from './components/SourceDocumentHeader';
import { FORMAT_OPTIONS } from './consts';

export const ExportSegments = () => {
  const { t } = useTranslation('exportSegments');
  const history = useHistory();

  const { documentId, languageId } = useRouterParams();
  const { data: documentDetail, isUninitialized, isLoading } = useGetDocumentDetailQuery(documentId);
  const [triggerExportSegments] = useGetExportDocumentSegmentsMutation();

  const handleClose = () => {
    history.push(`/documents/show/${documentId}`);
  };

  const formSchema = z.object({
    format: z.enum(['XLIFF', 'ODS', 'XLSX']),
    excludeTranslated: z.boolean().optional()
  });

  const { control, handleSubmit, register, watch } = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      format: EXPORT_FORMATS.xliff,
      excludeTranslated: false
    }
  });

  if (isUninitialized || isLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  assert(documentDetail, 'Document data not found');

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { format, excludeTranslated } = data;
    triggerExportSegments({ languageId, documentId, format, excludeTranslated: excludeTranslated ?? false });
  };

  const watchFormatValue = watch('format');

  return (
    <div className="min-h-screen bg-surface-background">
      <Helmet>
        <title>{t('exportSegments')}</title>
      </Helmet>

      {/* Header */}
      <Navbar sticky>
        <Button variant="surface" color="secondary" onClick={handleClose} className="ml-auto">
          {t('close')}
        </Button>
      </Navbar>

      <SourceDocumentHeader sourceDocument={documentDetail.sourceDocument} />

      <div className="mx-auto mt-20 max-w-3xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Container */}
          <Box>
            <Text color="primary" size={'lg'} weight={'bold'}>
              {t('exportTheDocumentPhrases')}
            </Text>
            <div className="max-w-sm pt-7">
              {/* Format picker */}
              <Text color="secondary" asChild weight={'medium'}>
                <label htmlFor="format">{t('format')}</label>
              </Text>
              <Controller
                name={'format'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <div className="flex flex-col gap-4 pt-4">
                    <Select onChange={onChange} value={value} placeholder={t('selectOption')} sideOffset={5} bordered>
                      {FORMAT_OPTIONS.map((option) => {
                        return { ...option, label: t(option.label) };
                      }).map((item) => (
                        <SelectItem value={item.id} key={item.id}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </Select>
                    <Text size={'sm'} weight={'medium'} color="tertiary">
                      {t('formatFieldHelpText')}
                    </Text>
                  </div>
                )}
              />
            </div>
            <div className="max-w-sm pt-6">
              {/* Exclude translated segments */}
              <Controller
                name="excludeTranslated"
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center space-x-3 pt-6">
                      <Checkbox
                        id="excludeTranslated"
                        {...register('excludeTranslated')}
                        onCheckedChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                      <label className="Label" htmlFor="excludeTranslated">
                        {t('excludeTranslatedFieldLabel')}
                      </label>
                    </div>
                    <Text size={'sm'} weight={'medium'} color="tertiary">
                      {t('excludeTranslatedFieldHelpText')}
                    </Text>
                  </div>
                )}
              />
            </div>
          </Box>
          <div className="flex justify-end pt-7">
            <Button type="submit">{t('export')}</Button>
          </div>
        </form>
        {watchFormatValue === EXPORT_FORMATS.ods || watchFormatValue === EXPORT_FORMATS.xlsx ? (
          <div className="mt-7 space-y-3 pb-12">
            <InlineMessage type="warning" orientation="horizontal">
              <Text size="sm" weight="semibold" className="" color="primary">
                {t('warningExcelTitle')}
              </Text>
              <Text size="sm" weight="regular" className="mt-2">
                {t('warningExcelContent')}
              </Text>
            </InlineMessage>
            <InlineMessage type="informational" orientation="horizontal">
              <Text size="sm" weight="semibold" className="" color="primary">
                {t('infoExcelTitle')}
              </Text>
              <Text size="sm" weight="regular" className="mt-2">
                <Trans
                  as="span"
                  i18nKey="exportSegments:infoExcelContent"
                  components={{
                    strong: <strong />,
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={`https://help.redokun.com/article/54-tags-and-markers`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                      />
                    )
                  }}
                />
              </Text>
            </InlineMessage>
          </div>
        ) : null}
        {/* End container */}
      </div>
    </div>
  );
};

const useRouterParams = () => {
  const params = useParams<{ documentId: string; languageId: string }>();
  const parsedParams = useMemo(
    () => ({ documentId: parseInt(params.documentId), languageId: parseInt(params.languageId) }),
    [params]
  );
  return parsedParams;
};
