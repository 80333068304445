import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v1/Box';
import { GetCountriesResponseDto } from '@/redux/api/types';
import { Select, SelectItem } from '@/components/v2/Select';
import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

type BillingDataFormProps = {
  countriesData?: GetCountriesResponseDto;
};

//  105 is the country code for Italy
const ITALY_COUNTRY_CODE = 105;

export const BillingDataForm = ({ countriesData }: BillingDataFormProps) => {
  const { t: tSettings } = useTranslation('settings');

  const { control, getValues } = useFormContext();
  const { addressCountryId } = getValues();

  return (
    <Box>
      <form className="max-w-xs space-y-7">
        <Controller
          control={control}
          name="companyName"
          render={({ field, fieldState: { error } }) => {
            return (
              <div className="space-y-3">
                <Label htmlFor="companyName" text={tSettings('companyName')} />
                <Input {...field} required type="text" id="companyName" />
                {error && (
                  <Text size="sm" className="text-red-500">
                    {error.message}
                  </Text>
                )}
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="addressCountryId"
          render={({ field, fieldState: { error } }) => {
            return (
              <div className="space-y-3">
                <Label htmlFor="country" text={tSettings('country')} />
                <Select
                  onChange={(value) => {
                    field.onChange(parseInt(value));
                  }}
                  value={field.value?.toString()}
                  placeholder={tSettings('country')}
                  bordered
                >
                  {countriesData &&
                    countriesData.countries.map((country) => (
                      <SelectItem value={country.id.toString()} key={country.id.toString()}>
                        {country.name}
                      </SelectItem>
                    ))}
                </Select>
                {error && (
                  <Text size="sm" className="text-red-500">
                    {error.message}
                  </Text>
                )}
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="addressStreet"
          render={({ field, fieldState: { error } }) => {
            return (
              <div className="space-y-3">
                <Label htmlFor="addressStreet" text={tSettings('streetAddress')} />
                <Input {...field} required type="text" id="addressStreet" />
                {error && (
                  <Text size="sm" className="text-red-500">
                    {error.message}
                  </Text>
                )}
              </div>
            );
          }}
        />

        <div className="grid grid-cols-2 gap-3">
          <Controller
            control={control}
            name="addressCity"
            render={({ field, fieldState: { error } }) => {
              return (
                <div className="space-y-3">
                  <Label htmlFor="addressCity" text={tSettings('city')} />
                  <Input {...field} required type="text" id="addressCity" />
                  {error && (
                    <Text size="sm" className="text-red-500">
                      {error.message}
                    </Text>
                  )}
                </div>
              );
            }}
          />
          <Controller
            control={control}
            name="addressZipCode"
            render={({ field, fieldState: { error } }) => {
              return (
                <div className="space-y-3">
                  <Label htmlFor="addressZipCode" text={tSettings('zipCode')} />
                  <Input {...field} required type="text" id="addressZipCode" />
                  {error && (
                    <Text size="sm" className="text-red-500">
                      {error.message}
                    </Text>
                  )}
                </div>
              );
            }}
          />
        </div>

        <Controller
          control={control}
          name="vatNumber"
          render={({ field, fieldState: { error } }) => {
            return (
              <div className="space-y-3">
                <div className="flex items-center space-x-3">
                  <Label htmlFor="vatNumber" text={tSettings('vATNumberEUOnly')} />
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <AccessibleIcon
                        label="warning-icon"
                        icon="ri-information-line"
                        className="text-xl text-blue-500"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content className="max-w-xxs">
                      <Text className="text-typography-inverse-primary" size="sm">
                        {tSettings('vatNumberToolTipText')}
                      </Text>
                      <Tooltip.Arrow />
                    </Tooltip.Content>
                  </Tooltip.Root>
                </div>
                <Input {...field} type="text" id="vatNumber" />
                {error && (
                  <Text size="sm" className="text-red-500">
                    {error.message}
                  </Text>
                )}
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="billingEmail"
          render={({ field, fieldState: { error } }) => {
            return (
              <div className="space-y-3">
                <Label htmlFor="billingEmail" text={tSettings('billingEmailNotRequired')} />
                <Input {...field} type="text" id="billingEmail" />
                {error && (
                  <Text size="sm" className="text-red-500">
                    {error.message}
                  </Text>
                )}
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="recipientCode"
          render={({ field, fieldState: { error } }) => {
            if (addressCountryId === ITALY_COUNTRY_CODE) {
              return (
                <div className="space-y-3">
                  <Label htmlFor="billingEmail" text={tSettings('billingEmailNotRequired')} />
                  <Input {...field} type="text" id="billingEmail" />
                  {error && (
                    <Text size="sm" className="text-red-500">
                      {error.message}
                    </Text>
                  )}
                </div>
              );
            } else {
              return <></>;
            }
          }}
        />
      </form>
    </Box>
  );
};
