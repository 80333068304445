import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Text } from '@/components/v2/Text';
import { Beacon } from '@/utils/helpScout';

interface NotFoundProps {
  title?: TFunction;
  message?: TFunction;
  linkText?: TFunction;
  linkRedirect?: string;
  askForSupport?: TFunction;
}

export const NotFound = ({ title, message, linkText, linkRedirect = '/documents' }: NotFoundProps) => {
  const { t: tGeneral } = useTranslation('general');

  const resolvedTitle = title ?? tGeneral('notFound.titleDefault');
  const resolvedMessage = message ?? tGeneral('notFound.messageDefault');
  const resolvedLinkText = linkText ?? tGeneral('notFound.linkTextDefault');
  const askForSupportLinkText = tGeneral('notFound.askForSupport');

  return (
    <div className="mt-40">
      <div className="max-w-md">
        <Text size={'xxl'} weight={'bold'} color="primary">
          {resolvedTitle}
        </Text>
        <Text color="primary">{resolvedMessage}</Text>
      </div>
      <div className="flex pt-8">
        <ButtonLink href={linkRedirect}>{resolvedLinkText}</ButtonLink>
        <Button onClick={() => Beacon('open')} color="secondary" className="ml-4">
          {askForSupportLinkText}
        </Button>
      </div>
    </div>
  );
};
