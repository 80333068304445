import DataTable from '@/components/v2/DataTable';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Text } from '@/components/v2/Text';
import { GlossaryDto } from '@/redux/api/types';

export const TargetLanguageCell = () => {
  const { cell } = DataTable.useCellContext<GlossaryDto, GlossaryDto['targetLanguage']>('TargetLanguageCell');
  const { name, flagCode } = cell.getValue();

  return (
    <div className="justify-left flex flex-row items-center gap-2">
      <FlagIcon label={name} code={flagCode || 'xx'} size="md" />
      <Text size="sm" weight="medium" color="secondary">
        {name}
      </Text>
    </div>
  );
};
