import { useTranslation } from 'react-i18next';

import { Input } from '@/components/v1/Input';
import { useOffline } from '@/hooks/useOffline';
import { SearchIcon } from '@/icons/solid';

import { useSearchSource, useSearchTarget } from './hooks';

export const ParagraphsSearch = () => {
  const { t } = useTranslation('documentLanguageParagraphsHeader');
  const offline = useOffline();
  const searchSource = useSearchSource();
  const searchTarget = useSearchTarget();

  return (
    <div className="flex h-16 items-center space-x-8">
      <div className="flex flex-1 items-center space-x-2">
        <span className="font-bold uppercase text-gray-500">{t('source')}</span>

        <Input
          className="flex-1"
          placeholder={t('searchInSource')}
          prefix={<SearchIcon className="text-gray-500" />}
          disabled={offline}
          {...searchSource}
        />
      </div>

      <div className="flex flex-1 items-center space-x-2">
        <span className="font-bold uppercase text-gray-500">{t('translation')}</span>

        <Input
          className="flex-1"
          placeholder={t('searchInTranslation')}
          prefix={<SearchIcon className="text-gray-500" />}
          disabled={offline}
          {...searchTarget}
        />
      </div>
    </div>
  );
};
