enum PUSHER_CHANNELS {
  PRIVATE_ACCOUNT = 'private-account-',
  PRIVATE_DOCUMENT = 'private-document-',
  PRIVATE_DOCUMENT_TRANSLATED = 'private-document-translated-',
  PRIVATE_PRESENCE_WE = 'presence-we-'
}

export const pusherChannelsAvailable = {
  privateAccount: (accountId: string | number) => `${PUSHER_CHANNELS.PRIVATE_ACCOUNT}${accountId}` as const,
  privateDocument: (documentId: string | number) => `${PUSHER_CHANNELS.PRIVATE_DOCUMENT}${documentId}` as const,
  privateDocumentTranslated: (documentId: string | number, languageId: string | number) =>
    `${PUSHER_CHANNELS.PRIVATE_DOCUMENT_TRANSLATED}${documentId}-${languageId}` as const,
  privatePresenceWe: (documentId: string | number, languageId: string | number) =>
    `${PUSHER_CHANNELS.PRIVATE_PRESENCE_WE}${documentId}-${languageId}` as const
};

export enum PusherEvent {
  NEW_STATUS = 'new_status',
  OPERATION_COMPLETED = 'operation:completed',
  STATUS_UPDATED = 'status_updated',
  SUBSCRIPTION_CHANGED = 'subscription:changed'
}
