import type { TSpinnerSize } from './types';

export const getSpinnerSize = (size: TSpinnerSize) => {
  switch (size) {
    case 'sm':
      return 18;

    case 'md':
      return 24;

    case 'lg':
      return 30;
  }
};
