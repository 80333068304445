import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { IDocumentCommentDto, IParagraphComment } from '@/redux/api/types';
import { classNames } from '@/utils/classNames';

import type { ICommentsListProps } from './types';

export const CommentsList = <Comment extends IDocumentCommentDto | IParagraphComment>({
  oldComments,
  newComments,
  scrollCommentId,
  className,
  getCommentKey,
  renderComment
}: ICommentsListProps<Comment>) => {
  const { t } = useTranslation('documentLanguageComments');

  const containerRef = useRef<HTMLDivElement>(null);
  const initialScrollRef = useRef(true);

  useEffect(() => {
    if (containerRef.current && scrollCommentId) {
      const elementId = getCommentKey(scrollCommentId);
      const element = document.getElementById(elementId);

      const top = (element?.offsetTop || containerRef.current.scrollHeight) - containerRef.current.clientHeight;
      const behavior = initialScrollRef.current ? 'auto' : 'smooth';
      containerRef.current.scrollTo({ top, behavior });

      initialScrollRef.current = false;
    }
  }, [containerRef, initialScrollRef, scrollCommentId, getCommentKey]);

  return (
    <div ref={containerRef} className={classNames('max-h-72 overflow-y-auto', className)}>
      <div className="space-y-2">
        {oldComments.map((comment) => {
          const key = getCommentKey(comment.id);
          return renderComment(key, comment);
        })}

        {newComments.length > 0 && (
          <div className="flex h-px items-center space-x-2">
            <div className="h-px flex-1 bg-red-600" />
            <span className="text-2xs font-bold leading-none text-red-600">{t('new')}</span>
          </div>
        )}

        {newComments.map((comment) => {
          const key = getCommentKey(comment.id);
          return renderComment(key, comment);
        })}
      </div>
    </div>
  );
};
