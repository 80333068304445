import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { skeleton } from './styles';
import type { SkeletonElement, SkeletonProps } from './types';

const Skeleton = forwardRef<SkeletonElement, SkeletonProps>((props, ref) => {
  const {
    children,
    className,
    loading = true,
    height,
    width,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    style,
    tabIndex = -1,
    ...divProps
  } = props;

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <span
      {...divProps}
      ref={ref}
      aria-hidden
      className={classNames(skeleton({ fitContent: Boolean(children) }), className)}
      tabIndex={tabIndex}
      style={{
        height,
        width,
        maxHeight,
        maxWidth,
        minHeight,
        minWidth,
        ...style
      }}
    >
      {children}
    </span>
  );
});
Skeleton.displayName = 'Skeleton';

export { Skeleton };
