import { useEffect } from 'react';

import { useGetCurrentAccountQuery, useTrackEventMutation } from '@/redux/api';
import { EventTypes } from '@/redux/api/constants';
import { generateTrackEvent } from '@/redux/api/utils/generateTrackEvent';

export const useTrackEvent = (eventType: EventTypes) => {
  const { data: currentAccount } = useGetCurrentAccountQuery();
  const [trackEventTrigger] = useTrackEventMutation();

  const isCompleted = currentAccount?.onboardingFlags.hasConfirmedAnyDocument;

  useEffect(() => {
    if (currentAccount?.isAdmin) return;
    if (!isCompleted) {
      trackEventTrigger(generateTrackEvent(eventType));
    }
  }, [isCompleted, trackEventTrigger, eventType, currentAccount?.isAdmin]);
};
