import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useToast } from '@/components/v2/Toast';
import { usePatchCurrentAccountMutation } from '@/redux/api';
import { Box } from '@/components/v2/Box';
import RadioGroup from '@/components/v2/RadioGroup';
import { to } from '@/utils/awaitToJs';
import { Spinner } from '@/components/v1/Spinner';
import { SettingsDTOFrequencyOptions, SettingsDTONotificationsOptions } from '@/redux/api/types';

export const SettingsNotifications: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  // State

  const { currentAccount } = useCurrentAccount();

  // Mutations

  const [patchCurrentAccount, { isLoading: patchCurrentAccountIsLoading }] = usePatchCurrentAccountMutation();

  // Form

  const formSchema = z.object({
    notificationsSetting: z.union([
      z.literal(SettingsDTONotificationsOptions.all),
      z.literal(SettingsDTONotificationsOptions.partecipating),
      z.literal(SettingsDTONotificationsOptions.never)
    ]),
    digestEmailFrequencyForManager: z.union([
      z.literal(SettingsDTOFrequencyOptions.daily),
      z.literal(SettingsDTOFrequencyOptions.weekly),
      z.literal(SettingsDTOFrequencyOptions.off)
    ]),
    digestEmailFrequencyForTranslator: z.union([
      z.literal(SettingsDTOFrequencyOptions.daily),
      z.literal(SettingsDTOFrequencyOptions.weekly),
      z.literal(SettingsDTOFrequencyOptions.off)
    ])
  });

  const { control: controlSubmitPatchCurrentAccount, handleSubmit: handleSubmitPatchCurrentAccount } = useForm<
    z.infer<typeof formSchema>
  >({
    resolver: zodResolver(formSchema),
    defaultValues: {
      notificationsSetting: currentAccount.settings.notificationsSetting ?? undefined,
      digestEmailFrequencyForManager: currentAccount.settings.digestEmailFrequencyForManager ?? undefined,
      digestEmailFrequencyForTranslator: currentAccount.settings.digestEmailFrequencyForTranslator ?? undefined
    }
  });

  const onSave = async (values: z.infer<typeof formSchema>) => {
    const [err] = await to(
      patchCurrentAccount({
        settings: {
          ...values
        }
      }).unwrap()
    );

    if (!err) {
      toast({ title: tSettings('savedSuccessfully'), kind: 'success' });
    }
  };

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsNotifications')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('notifications')}
          </Text>

          <Button
            color="primary"
            disabled={patchCurrentAccountIsLoading}
            onClick={handleSubmitPatchCurrentAccount(onSave)}
          >
            {patchCurrentAccountIsLoading ? <Spinner size="sm" /> : null}
            {tSettings('save')}
          </Button>
        </div>

        {/* Forms */}
        <form className="space-y-6">
          <Box>
            <Text size="md" color="primary" className="font-bold">
              {tSettings('webEditor')}
            </Text>
            <Controller
              control={controlSubmitPatchCurrentAccount}
              name="notificationsSetting"
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroup.Root className="space-y-6" value={value} onValueChange={onChange} {...field}>
                  <label className="flex space-x-3">
                    <RadioGroup.Item value={SettingsDTONotificationsOptions.all} size="sm" />
                    <Text color="primary" className="-mt-0.5">
                      {tSettings('allActivities')}
                      <Text size="sm" color="tertiary" className="pt-2">
                        {tSettings('allActivitiesDescription')}
                      </Text>
                    </Text>
                  </label>
                  <label className="flex space-x-3">
                    <RadioGroup.Item value={SettingsDTONotificationsOptions.partecipating} size="sm" className="peer" />
                    <Text color="primary" className="-mt-0.5">
                      {tSettings('participatingAndMentions')}
                      <Text size="sm" color="tertiary" className="pt-2">
                        {tSettings('participatingAndMentionsDescription')}
                      </Text>
                    </Text>
                  </label>
                  <label className="flex gap-3">
                    <RadioGroup.Item value={SettingsDTONotificationsOptions.never} size="sm" className="peer" />
                    <Text color="primary" className="-mt-0.5">
                      {tSettings('never')}
                      <Text size="sm" color="tertiary" className="pt-2">
                        {tSettings('neverDescription')}
                      </Text>
                    </Text>
                  </label>
                </RadioGroup.Root>
              )}
            />
          </Box>
          <Box>
            <div className="pb-2">
              <Text size="md" color="primary" className="font-bold">
                {tSettings('projectStatusDigest')}
              </Text>
            </div>
            <Text size="md" color="secondary">
              {tSettings('sendAnEmailDigestForProjectsThatMyTeamAndIManage')}
            </Text>
            <Controller
              control={controlSubmitPatchCurrentAccount}
              name="digestEmailFrequencyForManager"
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroup.Root className="space-y-6" value={value} onValueChange={onChange} {...field}>
                  <label className="flex items-center gap-3">
                    <RadioGroup.Item value={SettingsDTOFrequencyOptions.daily} size="sm" className="peer" />
                    <Text color="primary">{tSettings('daily')}</Text>
                  </label>
                  <label className="flex items-center gap-3">
                    <RadioGroup.Item value={SettingsDTOFrequencyOptions.weekly} size="sm" className="peer" />
                    <Text color="primary">{tSettings('weekly')}</Text>
                  </label>
                  <label className="flex items-center gap-3">
                    <RadioGroup.Item value={SettingsDTOFrequencyOptions.off} size="sm" className="peer" />
                    <Text color="primary">{tSettings('off')}</Text>
                  </label>
                </RadioGroup.Root>
              )}
            />

            <Text size="md" color="secondary">
              {tSettings('sendAnEmailDigestForProjectsThatIAmTranslating')}
            </Text>
            <Controller
              control={controlSubmitPatchCurrentAccount}
              name="digestEmailFrequencyForTranslator"
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroup.Root className="space-y-6" value={value} onValueChange={onChange} {...field}>
                  <label className="flex items-center gap-3">
                    <RadioGroup.Item value={SettingsDTOFrequencyOptions.daily} size="sm" className="peer" />
                    <Text color="primary">{tSettings('daily')}</Text>
                  </label>
                  <label className="flex items-center gap-3">
                    <RadioGroup.Item value={SettingsDTOFrequencyOptions.weekly} size="sm" className="peer" />
                    <Text color="primary">{tSettings('weekly')}</Text>
                  </label>
                  <label className="flex items-center gap-3">
                    <RadioGroup.Item value={SettingsDTOFrequencyOptions.off} size="sm" className="peer" />
                    <Text color="primary">{tSettings('off')}</Text>
                  </label>
                </RadioGroup.Root>
              )}
            />
          </Box>
        </form>
      </div>
    </div>
  );
};
