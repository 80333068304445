import { forwardRef } from 'react';
import * as Portal from '@radix-ui/react-portal';

import { classNames } from '@/utils/classNames';

import { OnboardingWizardPortalProps } from './types';

export const OnboardingWizardPortal = forwardRef<HTMLDivElement, OnboardingWizardPortalProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <Portal.Root
        ref={ref}
        className={classNames('fixed bottom-0 left-0 flex w-full max-w-xl flex-col justify-end p-10', className)}
        {...props}
      >
        {children}
      </Portal.Root>
    );
  }
);
OnboardingWizardPortal.displayName = 'OnboardingWizardPortal';
