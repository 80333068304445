import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { Select, SelectItem } from '@/components/v2/Select';
import { Text } from '@/components/v2/Text';
import { useToast } from '@/components/v2/Toast';
import { ManagerDtoStatus } from '@/redux/api/constants';
import { useLazyGetTeamDetailsQuery, useRemoveManagerFromTeamMutation } from '@/redux/api/teamApi';
import { CurrentAccountDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';

export const ConfirmManagerLeaveTeamModal = ({
  children,
  currentAccount,
  ...modalProps
}: ConfirmManagerLeaveTeamModalProps) => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const [getUserTeam, { data: userTeam, isLoading: userTeamIsLoading, isUninitialized: userTeamIsUninitialized }] =
    useLazyGetTeamDetailsQuery();

  useEffect(() => {
    // If the user isn't in a team using getUserTeam triggers and error
    // so I need to check if the user is in a team.
    if (currentAccount.subscriptionInfo.isInATeam) {
      getUserTeam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const [removeManagerTrigger, { isLoading }] = useRemoveManagerFromTeamMutation();

  const formSchema = z.object({
    newDocumentsOwnerId: z.coerce.number()
  });

  const handleConfirm = async ({ newDocumentsOwnerId }: z.infer<typeof formSchema>) => {
    const [err] = await to(removeManagerTrigger({ managerAccountId: currentAccount.id, newDocumentsOwnerId }).unwrap());
    if (!err) {
      if (modalProps.onOpenChange) {
        modalProps.onOpenChange(false);
      }
      toast({ title: tSettings('youHaveLeftTheTeamSuccesfullyToastTitle'), kind: 'success' });
    }
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      newDocumentsOwnerId: userTeam?.members.length ? userTeam?.members[0].id : undefined
    }
  });

  if (userTeamIsLoading || userTeamIsUninitialized) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="md">
        <Modal.Header>
          <Modal.Title size="lg">{tSettings('leaveTheTeam')}</Modal.Title>
          <Text color="secondary" size="sm">
            {tSettings('allYourDocumentsAndFoldersWillBeReassignedToAnotherManager')}
          </Text>
        </Modal.Header>

        <div>
          {userTeam?.members ? (
            <form id="removeManagerFromTeamForm" onSubmit={form.handleSubmit(handleConfirm)} className="space-y-4">
              <Controller
                name={'newDocumentsOwnerId'}
                control={form.control}
                render={({ field }) => (
                  <div className="space-y-2">
                    <Select
                      onChange={field.onChange}
                      value={field.value?.toString()}
                      placeholder={tSettings('selectMember')}
                      bordered
                    >
                      {userTeam?.members
                        .filter((member) => {
                          return member.status === ManagerDtoStatus.ACCEPTED && member.id !== currentAccount.id;
                        })
                        .map((item) => (
                          <SelectItem value={item.id.toString()} key={item.id.toString()}>
                            {item.name}
                          </SelectItem>
                        ))}
                    </Select>
                  </div>
                )}
              />
            </form>
          ) : null}
        </div>

        <Modal.Footer orientation={'vertical'}>
          <Button color="negative" form="removeManagerFromTeamForm" disabled={isLoading}>
            {tSettings('leaveTheTeam')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tSettings('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface ConfirmManagerLeaveTeamModalProps extends ModalProps {
  currentAccount: CurrentAccountDto;
}
