import { PaginationState } from '@tanstack/table-core';
import { isEmpty } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DataTable, { DEFAULT_EXPANDED, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/components/v2/DataTable';
import { Show } from '@/components/v2/Show';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetDocumentListQuery, useGetSavedViewsQuery } from '@/redux/api/documentListApi';
import { isNotEmpty } from '@/utils/isNotEmpty';

import { useDocumentListSearchParams } from '../../hooks/useDocumentListSearchParams';
import { useColumns } from './columns';
import { BatchOperationHeader } from './components/BatchOperationHeader';
import { Filters, FiltersSkeleton } from './components/Filters';
import { PAGE_SIZES } from './constants';
import { DocumentListContentProps } from './types';
import { enableRowSelection, getRowCanExpand, getRowId } from './utils';

export const DocumentListContent = ({ currentFolderId }: DocumentListContentProps) => {
  const { t: tDocumentList } = useTranslation('documentList');

  const [
    {
      searchTerm,
      page = DEFAULT_PAGE_INDEX,
      filterByTargetLanguageIds,
      filterByTranslatorAccountIds,
      filterByOwnerAccountIds,
      filterByStatus
    },
    { setSavedView, setSearchTerm, setPage }
  ] = useDocumentListSearchParams();

  const { currentAccount } = useCurrentAccount();
  const isSavedViewsEnabled = currentAccount.subscriptionInfo.features.savedViews;

  const columns = useColumns();

  // saving page size in localstorage
  const [perPage, setPerPage] = useLocalStorage<number>('documentListPageSize', DEFAULT_PAGE_SIZE);

  const areFilterActive =
    Boolean(searchTerm) ||
    isNotEmpty(filterByOwnerAccountIds) ||
    isNotEmpty(filterByTargetLanguageIds) ||
    isNotEmpty(filterByStatus) ||
    isNotEmpty(filterByTranslatorAccountIds);

  const [expanded, setExpanded] = useState(DEFAULT_EXPANDED);

  const {
    data: documentList,
    isUninitialized,
    isLoading,
    isFetching
  } = useGetDocumentListQuery({
    folderParentId: currentFolderId,
    perPage,
    searchTerm,
    filterByOwnerAccountIds,
    filterByTranslatorAccountIds,
    filterByTargetLanguageIds,
    filterByStatus,
    // prevent sending 0 as a parameter since backend pagination starting from 1
    page: Math.max(page, DEFAULT_PAGE_INDEX)
  });

  const { isLoading: isSavedViewsLoading } = useGetSavedViewsQuery(undefined, { skip: !isSavedViewsEnabled });

  const pages = useMemo(() => {
    if (documentList?.pagination) {
      const pagesArray = PAGE_SIZES.map((size) => ({
        value: size,
        label: tDocumentList('showDocuments', { total: size })
      }));
      return pagesArray;
    }
    return [];
  }, [documentList?.pagination, tDocumentList]);

  if (isUninitialized || isLoading || isSavedViewsLoading) {
    return (
      <DataTableLayout>
        <FiltersSkeleton />

        <DataTable.Root columns={columns} data={perPage} loading>
          <DataTable.Content layout="fixed">
            <DataTable.Header />
            <DataTable.Body />
          </DataTable.Content>
        </DataTable.Root>
      </DataTableLayout>
    );
  }

  const canSeeOwnerManagerTable = currentAccount.subscriptionInfo.isActive;
  // The `isTranslator` flag is unreliable because it's not updated when a user
  // is promoted to manager, but for this case, it's an OK solution.
  const isTranslator = currentAccount.subscriptionInfo.isTranslator;

  if (isEmpty(documentList?.items) && !canSeeOwnerManagerTable && !areFilterActive && isTranslator) {
    return (
      <div className="text-center text-typography-tertiary">
        <AccessibleIcon label="ri-file-3-line" icon="ri-file-3-line" className="text-3xl" />
        <Text size={'md'} className="pb-8 font-bold">
          {/* if there's no pending job invites show that there's no translation assigned */}
          {/* otherwise invite user to accept pending job to continue */}
          <Show
            when={currentAccount.pendingJobInvites.length == 0}
            fallback={tDocumentList('pendingAssignedDocumentTitle')}
          >
            {tDocumentList('noDocumentsAssignedTitle')}
          </Show>
        </Text>
        <Text size={'md'}>
          <Trans
            i18nKey="documentList:noDocumentsAssignedSubtitle"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              linkTo: <a href="https://help.redokun.com/" />,
              spanText: <span className="text-primary-active" />,
              iconExternal: (
                <AccessibleIcon
                  icon="ri-external-link-line"
                  label="ri-external-link-line"
                  className="inline text-2xl"
                />
              )
            }}
          />
        </Text>
      </div>
    );
  }

  const tableData = isFetching ? perPage : documentList?.items;

  const pagination = documentList?.pagination;
  const showPagination = (pagination?.total ?? 0) > 0;
  const numberOfPages = pagination?.totalPages ?? 0;

  const handlePaginationChanged = (pagination: PaginationState) => {
    // reset expanded state on pagination change
    setExpanded(DEFAULT_EXPANDED);
    setPage(pagination.pageIndex);
    setPerPage(pagination.pageSize);
  };

  const handleSearch = (value: string) => {
    // reset pagination on search
    setPage(DEFAULT_PAGE_INDEX);
    setSearchTerm(value);
    setSavedView(null);
  };

  return (
    <DataTableLayout>
      <Filters searchTerm={searchTerm} onSearch={handleSearch} areFilterActive={areFilterActive} />
      <DataTable.Root
        getRowId={getRowId}
        columns={columns}
        data={tableData}
        defaultPageSize={perPage}
        defaultPageIndex={page}
        pageCount={documentList?.pagination.total}
        pagination={{ pageIndex: page, pageSize: perPage }}
        expanded={expanded}
        enableRowSelection={enableRowSelection}
        getRowCanExpand={getRowCanExpand}
        onExpandedChange={setExpanded}
        onPaginationChange={handlePaginationChanged}
        loading={isFetching}
      >
        <DataTable.Content layout="fixed">
          <DataTable.Header selection={<BatchOperationHeader />} />
          <DataTable.Body
            fallback={
              <Show
                when={areFilterActive}
                fallback={<div className="py-4 text-center text-base">{tDocumentList('noItemsToDisplay')}</div>}
              >
                <div className="flex flex-col gap-3 py-4 text-center text-typography-tertiary">
                  <AccessibleIcon label="ri-filter-off-line" icon="ri-filter-off-line" className="text-3xl" />
                  <Text size="md" className="font-bold" color="tertiary">
                    {tDocumentList('noResultsFound')}
                  </Text>
                  <Text size="md" color="tertiary">
                    {tDocumentList('tryAdjustingOrRemovingFiltersForMoreResults')}
                  </Text>
                </div>
              </Show>
            }
          />
        </DataTable.Content>

        <Show when={showPagination}>
          <div className="flex flex-row justify-end">
            <DataTable.PageSizeSelector
              options={pages}
              value={String(perPage)}
              placeholder={tDocumentList('selectOptions')}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <DataTable.Pagination numberOfPages={numberOfPages} />
          </div>
        </Show>
      </DataTable.Root>
    </DataTableLayout>
  );
};

type DataTableLayoutProps = {
  children?: ReactNode;
};
const DataTableLayout = (props: DataTableLayoutProps) => {
  const { children } = props;

  return (
    <div className="flex flex-1 flex-row">
      <Surface className="w-full space-y-6 px-7 py-8" radius="sm">
        {children}
      </Surface>
    </div>
  );
};
