import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import Tooltip from '@/components/v2/Tooltip';
import { DocumentSamplesModal } from '@/routes/Onboarding/components/DocumentSamplesModal';
import { useAppDispatch } from '@/redux/hooks';
import { setCreatedDocumentIds } from '@/redux/slices/uploadDocumentsSlice';

const UPLOAD_DOCUMENT_URL = '/welcome/documents/new';
const TARGET_LANGUAGE_URL = '/welcome/documents/new/target-language';

export const TranslateYourFirstDocument: React.FC = () => {
  const { t } = useTranslation('uploadDocuments');
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleOnCreated = (documentId?: number) => {
    // save on redux the created documents ids
    if (documentId) {
      dispatch(setCreatedDocumentIds([documentId]));
      history.push(TARGET_LANGUAGE_URL);
    }
  };

  return (
    <div className="flex flex-col items-center pb-8">
      <div className="mt-16 flex max-w-xl flex-col items-start">
        <Title size="md" weight="bold" className="mb-4 text-start leading-9">
          {`${t('translateYourFirstDocument')} ⚡️`}
        </Title>
        <Text size="md">{t('uploadYourFirstDocumentAndSee')}</Text>
        <div className="mb-10 mt-6 flex flex-col gap-6">
          <Text size="md">{t('hereHowItWorks')}</Text>
          <div className="flex flex-col gap-3">
            <Item icon="ri-file-upload-fill" iconLabel="upload" label={t('uploadTheFileYouWantToTranslate')} />
            <Item icon="ri-translate" iconLabel="translate" label={t('preTranslateYourDocumentWithMachine')} />
            <Item icon="ri-file-edit-fill" iconLabel="edit" label={t('editTheTranslationAndDownload')} />
          </div>
        </div>

        <div className="mt-6 flex w-full flex-col items-center gap-6">
          <Tooltip.Root open={true}>
            <Tooltip.Trigger asChild>
              <Button size="lg" asChild>
                <a href={UPLOAD_DOCUMENT_URL}>
                  <AccessibleIcon label="arrow-right" icon="ri-file-upload-line" />
                  {t('uploadDocument')}
                </a>
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content side="right" className="flex max-w-xxs flex-col gap-1">
              <Text className="text-typography-inverse-secondary" size="xs">
                {t('everyTimeYouHaveNewContentToTranslateWillBeFaster')}
              </Text>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
          <DocumentSamplesModal onCreated={handleOnCreated}>
            <Button variant="text" size="sm">
              <AccessibleIcon label="lightbulb" icon="ri-lightbulb-fill" />
              {t('dontHaveDocumentYouUseNow')}
            </Button>
          </DocumentSamplesModal>
        </div>
      </div>
    </div>
  );
};

const Item = ({ label, icon, iconLabel }: { label: string; icon: string; iconLabel: string }) => {
  return (
    <div className="flex flex-row items-center gap-4">
      <div className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-100 text-center">
        <AccessibleIcon label={iconLabel} icon={icon} className="h-5 w-5 text-gray-600" />
      </div>
      <Text size="md">{label}</Text>
    </div>
  );
};
