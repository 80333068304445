import type { ElementType } from 'react';
import { useMemo } from 'react';

import { Box } from '@/components/v1/Box';

import { DEFAULT_ELEMENT } from './constants';
import { Nodes } from './Nodes';
import type { DefaultElementType, TTranslationContentProps } from './types';
import { parseContent } from './utils';

export const TranslationContent = <E extends ElementType = DefaultElementType>(props: TTranslationContentProps<E>) => {
  const { content, searchWord = [], glossaryEntries = [], targetLanguage, ...boxProps } = props;

  const nodes = useMemo(
    () => parseContent({ content, glossaryEntries, searchWord, targetLanguage }),
    [glossaryEntries, searchWord, content, targetLanguage]
  );

  return (
    // @ts-expect-error: TS 5 migration error
    <Box as={DEFAULT_ELEMENT} {...boxProps}>
      <Nodes value={nodes} />
    </Box>
  );
};
