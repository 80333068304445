import { EOnboardingSteps, Step } from '@/routes/Onboarding/types';

export const onboardingSteps: Step[] = [
  {
    id: EOnboardingSteps.GETTING_TO_KNOW_YOU,
    path: '/welcome/survey',
    label: 'gettingToKnowYou'
  },
  {
    id: EOnboardingSteps.WHAT_YOU_CAN_DO,
    path: '/welcome/what-you-can-do',
    label: 'whatYouCanDoWithRedokun'
  },
  {
    id: EOnboardingSteps.TRANSLATE_YOUR_FIRST_DOCUMENT,
    path: '/welcome/translate-your-first-document',
    label: 'startYourFirstTranslationProject'
  }
];
