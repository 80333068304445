import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '@/assets/redokun-dark.svg';
import { classNames } from '@/utils/classNames';

import { NavbarAlert } from './NavbarAlert';
import type { NavbarProps } from './types';

export const Navbar = (props: NavbarProps) => {
  const { sticky, fullWidth, children } = props;

  return (
    <header
      className={classNames('border-b border-gray-100 bg-white', {
        'sticky top-0 z-10': sticky
      })}
    >
      <NavbarAlert />

      <nav
        className={classNames('mx-auto flex h-16 max-w-8xl items-center px-7', {
          'sticky top-0 z-10': sticky,
          'max-w-none': fullWidth
        })}
      >
        <Link to="/documents">
          <Logo />
        </Link>

        {children}
      </nav>
    </header>
  );
};
