import { EAclErrorCode, EErrorStatus, EStandardErrorCode } from '../constants';
import type { IAclError, IStandardError } from '../types';

export const isStandardError = (error: unknown): error is IStandardError => {
  if (error && typeof error === 'object' && 'status' in error && 'error' in error && 'code' in error) {
    const maybeStandardError = error as { status: unknown; error: unknown; code: unknown };
    return (
      Object.values(EErrorStatus).includes(maybeStandardError.status as EErrorStatus) &&
      Object.values(EStandardErrorCode).includes(maybeStandardError.code as EStandardErrorCode) &&
      typeof maybeStandardError.error === 'string'
    );
  }
  return false;
};

export const isAclError = (error: unknown): error is IAclError => {
  if (error && typeof error === 'object' && 'status' in error && 'error' in error && 'code' in error) {
    const maybeStandardError = error as { status: unknown; error: unknown; code: unknown };
    return (
      Object.values(EErrorStatus).includes(maybeStandardError.status as EErrorStatus) &&
      Object.values(EAclErrorCode).includes(maybeStandardError.code as EAclErrorCode) &&
      typeof maybeStandardError.error === 'string'
    );
  }
  return false;
};

export const isHttpError = (error: unknown): error is { data: unknown; status: number } => {
  if (error && typeof error === 'object' && 'status' in error && 'data' in error) {
    const maybeHttpError = error as { data: unknown; status: unknown };
    return typeof maybeHttpError.status === 'number';
  }
  return false;
};

export const isFetchError = (
  error: unknown
): error is {
  status: 'FETCH_ERROR';
  error: string;
} => {
  if (error && typeof error === 'object' && 'status' in error && 'error' in error) {
    const maybeFetchError = error as { status: unknown; error: unknown };
    return (
      typeof maybeFetchError.status === 'string' &&
      typeof maybeFetchError.error === 'string' &&
      maybeFetchError.status === 'FETCH_ERROR'
    );
  }
  return false;
};

export const isHttpStandardError = (error: unknown): error is { data: IStandardError; status: number } =>
  isHttpError(error) && isStandardError(error.data);

export const isHttpAclError = (error: unknown): error is { data: IAclError; status: number } =>
  isHttpError(error) && isAclError(error.data);
