import { find } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { For } from '@/components/v2/For';
import { FormControl, FormError, FormField, useFormContext } from '@/components/v2/Form';
import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import { Select, SelectItem } from '@/components/v2/Select';
import { Show } from '@/components/v2/Show';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { CountryDto } from '@/redux/api/types';

import { FORM_ID } from './constants';
import { BillingDetailsFormSchema, provinces } from './utils';

const BillingDetailsFormContent = (props: BillingDetailsFormContentProps) => {
  const { countries } = props;

  const { t: tCheckout } = useTranslation('checkout');

  const form = useFormContext<BillingDetailsFormSchema>();
  const { control } = form;
  const addressCountryId = useWatch({ control, name: 'addressCountryId' });
  const addressCountry = useMemo(() => find(countries, { id: addressCountryId }), [addressCountryId, countries]);

  return (
    <Surface border className="space-y-7 p-7">
      <Text color="primary" weight="bold">
        {tCheckout('billingDetailsForm.billingDetails')}
      </Text>

      <FormField name="companyName">
        <Label color="tertiary" htmlFor={`${FORM_ID}-companyName`} isRequired size="sm" weight="medium">
          {tCheckout('billingDetailsForm.companyName')}
        </Label>
        <FormControl>
          <Input id={`${FORM_ID}-companyName`} placeholder={tCheckout('billingDetailsForm.companyName')} size="sm" />
        </FormControl>
        <FormError />
      </FormField>

      <FormField name="addressCountryId">
        <Label color="tertiary" htmlFor={`${FORM_ID}-addressCountryId`} isRequired size="sm" weight="medium">
          {tCheckout('billingDetailsForm.countryLabel')}
        </Label>
        <FormControl>
          {({ field: { value, onChange, ...field } }) => (
            <Select
              {...field}
              bordered
              id={`${FORM_ID}-addressCountryId`}
              placeholder={tCheckout('billingDetailsForm.countryPlaceholder')}
              size="sm"
              value={value.toString()}
              onChange={(value) => onChange(parseInt(value))}
            >
              <For each={countries}>
                {(country) => (
                  <SelectItem key={country.id} value={`${country.id}`} textValue={country.name}>
                    {country.name}
                  </SelectItem>
                )}
              </For>
            </Select>
          )}
        </FormControl>
        <FormError />
      </FormField>

      <FormField name="addressStreet">
        <Label color="tertiary" htmlFor={`${FORM_ID}-addressStreet`} isRequired size="sm" weight="medium">
          {tCheckout('billingDetailsForm.streetAddress')}
        </Label>
        <FormControl>
          <Input
            id={`${FORM_ID}-addressStreet`}
            placeholder={tCheckout('billingDetailsForm.streetAddress')}
            size="sm"
          />
        </FormControl>
        <FormError />
      </FormField>

      <div className="grid grid-cols-[2fr,1fr] gap-7">
        <FormField name="addressCity">
          <Label color="tertiary" htmlFor={`${FORM_ID}-addressCity`} isRequired size="sm" weight="medium">
            {tCheckout('billingDetailsForm.city')}
          </Label>
          <FormControl>
            <Input id={`${FORM_ID}-addressCity`} placeholder={tCheckout('billingDetailsForm.city')} size="sm" />
          </FormControl>
          <FormError />
        </FormField>

        <FormField name="addressZipCode">
          <Label color="tertiary" htmlFor={`${FORM_ID}-addressZipCode`} isRequired size="sm" weight="medium">
            {tCheckout('billingDetailsForm.zipCodeLabel')}
          </Label>
          <FormControl>
            <Input
              id={`${FORM_ID}-addressZipCode`}
              placeholder={tCheckout('billingDetailsForm.zipCodePlaceholder')}
              size="sm"
            />
          </FormControl>
          <FormError />
        </FormField>
      </div>

      <Show when={addressCountry?.isItaly}>
        <FormField name="addressProvince">
          <Label color="tertiary" htmlFor={`${FORM_ID}-addressProvince`} isRequired size="sm" weight="medium">
            {tCheckout('billingDetailsForm.provinceLabel')}
          </Label>
          <FormControl>
            <Select
              bordered
              id={`${FORM_ID}-addressProvince`}
              placeholder={tCheckout('billingDetailsForm.provincePlaceholder')}
              size="sm"
            >
              <For each={provinces}>
                {(province) => (
                  <SelectItem key={province.key} value={province.key} textValue={province.value}>
                    {province.value}
                  </SelectItem>
                )}
              </For>
            </Select>
          </FormControl>
          <FormError />
        </FormField>
      </Show>

      <Show when={addressCountry?.isEu}>
        <FormField name="vatNumber">
          <Label color="tertiary" htmlFor={`${FORM_ID}-vatNumber`} isRequired size="sm" weight="medium">
            {tCheckout('billingDetailsForm.vatNumber')}
          </Label>
          <FormControl>
            <Input id={`${FORM_ID}-vatNumber`} placeholder={tCheckout('billingDetailsForm.vatNumber')} size="sm" />
          </FormControl>
          <FormError />
        </FormField>
      </Show>

      <FormField name="billingEmail">
        <Label color="tertiary" htmlFor={`${FORM_ID}-billingEmail`} size="sm" weight="medium">
          {tCheckout('billingDetailsForm.billingEmailLabel')}
        </Label>
        <FormControl>
          <Input
            id={`${FORM_ID}-billingEmail`}
            placeholder={tCheckout('billingDetailsForm.billingEmailPlaceholder')}
            type="email"
            size="sm"
          />
        </FormControl>
        <FormError />
      </FormField>

      <Show when={addressCountry?.isItaly}>
        <FormField name="recipientCode">
          <Label color="tertiary" htmlFor={`${FORM_ID}-recipientCode`} isRequired size="sm" weight="medium">
            {tCheckout('billingDetailsForm.recipientCodeLabel')}
          </Label>
          <FormControl>
            <Input
              id={`${FORM_ID}-recipientCode`}
              placeholder={tCheckout('billingDetailsForm.recipientCodePlaceholder')}
              size="sm"
            />
          </FormControl>
          <FormError />
        </FormField>
      </Show>
    </Surface>
  );
};
BillingDetailsFormContent.displayName = 'CheckoutBillingFormContent';

interface BillingDetailsFormContentProps {
  countries: CountryDto[];
}

export { BillingDetailsFormContent };
export type { BillingDetailsFormContentProps };
