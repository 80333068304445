import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { TConfirmAlertProps } from '@/components/v1/Alert/types';
import { Button, ButtonLink } from '@/components/v1/Button';
import { HELP_URL } from '@/config/urls';

export const MissingTagsConfirmAlert: FunctionComponent<Pick<TConfirmAlertProps, 'onOk' | 'border'>> = ({
  onOk,
  ...props
}) => {
  const { t } = useTranslation('documentLanguageAlerts');

  return (
    <Alert
      type="warning"
      message={t('missingTagsWereAutomaticallyAdded')}
      actions={[
        <Button key="ok_button" onClick={onOk}>
          {t('ok')}
        </Button>,
        <ButtonLink
          key="learn_more_button"
          href={`${HELP_URL}/article/54-tags-and-markers`}
          target="_blank"
          rel="noreferrer"
        >
          {t('learnMore')}
        </ButtonLink>
      ]}
      {...props}
    />
  );
};
