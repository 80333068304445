import { ETranslationListFilter, MachineTranslationVendor } from '../constants';
import type {
  CurrentAccountDto,
  DocumentListOperationItemRequestDto,
  DocumentListStatus,
  EntityType,
  IAskLanguageUnlockRequestDto,
  IChangeLanguageStatusRequestDto,
  IChangeTmRecordVisibilityRequestDto,
  ICopySameTranslationRequestDto,
  ICreateDocumentTranslatedCommentRequestDto,
  ICreateParagraphCommentRequestDto,
  IMarkDocumentTranslatedCommentReadRequestDto,
  IMarkNotificationReadRequestDto,
  IMarkParagraphCommentReadRequestDto,
  ITranslationUpdateRequestDto,
  IUpdateTranslationInvitationRequest,
  IUpdateTranslationStatusRequestDto,
  LanguageSettingDto,
  LaunchPreTranslationRequestDto,
  TrackEventDto,
  UpdateBillingDetailsRequestDto
} from './dtos';

export interface IBaseLanguageApiQueryArg {
  documentId: number;
  languageId: number;
}

export interface IGetBootstrapDataQueryArg extends IBaseLanguageApiQueryArg {
  sendCountersAsync?: boolean;
}

export interface IGetTranslationListQueryArg extends IBaseLanguageApiQueryArg {
  paginationPage: number;
  searchSource?: string;
  searchTarget?: string;
  filter?: ETranslationListFilter;
}

export interface IGetTmSuggestionsQueryArg extends IBaseLanguageApiQueryArg {
  paragraphId: number;
}

export interface IGetMtQueryArg extends IBaseLanguageApiQueryArg {
  paragraphId: number;
  vendor: MachineTranslationVendor;
}

export interface IGetDocumentTranslatedCommentQueryArg extends IBaseLanguageApiQueryArg {}

export interface IGetParagraphCommentQueryArg extends IBaseLanguageApiQueryArg {
  paragraphId: number;
}

export interface IGetParagraphActivityCommentsQueryArg extends IBaseLanguageApiQueryArg {
  paragraphId: number;
}

export interface IUpdateTranslationQueryArg extends IBaseLanguageApiQueryArg {
  body: ITranslationUpdateRequestDto;
}

export interface IChangeLanguageStatusQueryArg extends IBaseLanguageApiQueryArg {
  body: IChangeLanguageStatusRequestDto;
}

export interface IAskLanguageUnlockQueryArg extends IBaseLanguageApiQueryArg {
  body: IAskLanguageUnlockRequestDto;
}

export interface IUpdateTranslationStatusQueryArg extends IBaseLanguageApiQueryArg {
  body: IUpdateTranslationStatusRequestDto;
}

export interface ICopySameTranslationQueryArg extends IBaseLanguageApiQueryArg {
  body: ICopySameTranslationRequestDto;
}

export interface ICreateDocumentTranslatedCommentQueryArg extends IBaseLanguageApiQueryArg {
  currentAccount: CurrentAccountDto;
  body: ICreateDocumentTranslatedCommentRequestDto;
}

export interface IMarkDocumentTranslatedCommentReadQueryArg extends IBaseLanguageApiQueryArg {
  body: IMarkDocumentTranslatedCommentReadRequestDto;
}

export interface IDeleteDocumentTranslatedCommentQueryArg extends IBaseLanguageApiQueryArg {
  commentId: number;
}

export interface ICreateParagraphCommentQueryArg extends IBaseLanguageApiQueryArg {
  paragraphId: number;
  currentAccount: CurrentAccountDto;
  body: ICreateParagraphCommentRequestDto;
}

export interface IMarkParagraphCommentReadQueryArg extends IBaseLanguageApiQueryArg {
  paragraphId: number;
  body: IMarkParagraphCommentReadRequestDto;
}

export interface IDeleteParagraphCommentQueryArg extends IBaseLanguageApiQueryArg {
  paragraphId: number;
  commentId: number;
}

export interface IUpdateTranslationInvitationQueryArg extends IBaseLanguageApiQueryArg {
  body: IUpdateTranslationInvitationRequest;
}

export interface IChangeTmRecordVisibilityQueryArg {
  suggestionId: number;
  body: IChangeTmRecordVisibilityRequestDto;
}

export interface IGetNotificationsQueryArg {
  limit?: number;
}

export interface IMarkNotificationReadQueryArg {
  body: IMarkNotificationReadRequestDto;
}

export interface ISendFeedbackQueryArg {
  body: { body: string };
}

export interface ISaveOnboardingQueryArg {
  body: Record<string, string>;
}

export interface DocumentCreateFromSampleQueryArg {
  documentsFromSample: {
    identifier: string;
  }[];
}

export interface DocumentUploadQueryArgs {
  files: File[];
  folderId?: number;
}

export interface UploadDocumentRevisionQueryArgs {
  documentId: number;
  stashedFileId: number;
  skipNotification?: boolean;
}

export interface UploadDocumentRevisionResponse {
  newRevisionDocumentId: number;
}

export interface DocumentRevisionRollbackQueryArgs {
  documentId: number;
}

export interface BaseDocumentQueryArg {
  documentId: number;
}

export interface DocumentPreTranslateQueryArg extends BaseDocumentQueryArg {
  body: LaunchPreTranslationRequestDto;
}
export interface CreateDocumentFromStashQueryArgs {
  documentsStashed: {
    stashedFileId: number;
    languageId: number;
  }[];
  importNumericData?: boolean;
  enableHtmlSubFilter?: boolean;
}

export interface TranslatorListQueryArgs {
  filterByLanguageId?: number;
}

export interface InviteTranslatorArgs {
  email: string;
  languageId?: number;
}

export interface RemoveTranslatorArgs {
  translatorAccountId: number;
}

export interface AddLanguagesToDocumentsRequestQueryArgs {
  documentIds: number[];
  languages: LanguageSettingDto[];
}

export interface GenerateDocumentTranslationQueryArg {
  documentId: number;
  languageId: number;
}

export interface DeleteLanguageTranslationQueryArg {
  documentId: number;
  languageId: number;
}

export interface GetDocumentListQueryArgs {
  folderParentId?: number;
  searchTerm?: string;
  page?: number;
  perPage?: number;
  filterByTargetLanguageIds?: number[];
  filterByTranslatorAccountIds?: number[];
  filterByOwnerAccountIds?: number[];
  filterByStatus?: DocumentListStatus[];
}

export interface UpdateDocumentListItemsQueryArgs {
  items: DocumentListOperationItemRequestDto[];
  parentFolderId?: number | null;
  ownerAccountId?: number | null;
  parentFolderIsRoot?: boolean;
}

export interface DeleteDocumentListItemsQueryArgs {
  items: DocumentListOperationItemRequestDto[];
}

export interface UpdateFlagsQueryArgs {
  hasHiddenOnboardingWizardWidget: boolean;
}

export interface IUpdateCurrentAccountQueryArgs {
  email?: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  jobTitle?: string;
  avatarFilename?: string;
  settings?: {
    enforceSingleSession?: boolean;
    skipSoftReturnsOnUpload?: boolean;
    autoloadMt?: boolean;
    enableFilterSelection?: boolean;
    useSrxSegmentation?: boolean;
    convertSpecialSpaces?: boolean;
    enableRegionalLanguages?: boolean;
    deeplFormality?: 'LESS' | 'MORE' | 'DEFAULT' | null;
    notificationsSetting?: 'ALL' | 'PARTECIPATING' | 'NEVER' | null;
    digestEmailFrequencyForManager?: 'DAILY' | 'WEEKLY' | 'OFF' | null;
    digestEmailFrequencyForTranslator?: 'DAILY' | 'WEEKLY' | 'OFF' | null;
    enableLiveCharacterCount?: boolean;
    enableTmSourceIndicator?: boolean;
    enableSearchSimilarity?: boolean;
  };
}

export interface IUpdateCurrentAccountAvatarQueryArgs {
  avatarImageBase64: string;
}

export interface ChangeDocumentTranslatorsQueryArgs {
  documentId: number;
  languageId: number;
  translatorAccountIds: number[];
}

export interface TrackEventQueryArg {
  body: TrackEventDto;
}

export interface UpdateJobInvitationStatusArgs {
  translationJobId: number;
  // New status of job invitation (only ACCEPTED for now)
  status: 'ACCEPTED';
}

export interface UpdateTeamInvitationStatusArgs {
  teamMembershipId: number;
  action: 'ACCEPT' | 'IGNORE';
}

export interface CreateFolderQueryArgs {
  name: string;
  // Parent folder. If this is null, then the document will be moved to the root
  parentFolderId: number | null;
}

export interface UpdateFolderQueryArgs {
  name: string;
  // Parent folder. If this is null, then the document will be moved to the root
  parentFolderId: number | null;
  folderId: number;
}

export interface UpdateDocumentPropertiesQueryArgs {
  documentId: number;
  //Account that owns the document
  documentOwnerId: number;
  // Parent folder. If this is null, then the document will be moved to the root
  parentFolderId: number | null;
}

export type FileUploadBodyArg = {
  entityType: EntityType;
  files: File[];
};

export interface PutLanguagesQueryArgs {
  languageIds: number[];
  enableRegionalVariants: boolean;
}

export interface UpdateBillingDetailsRequestQueryArgs {
  body: UpdateBillingDetailsRequestDto;
}

export enum CancelRenewalRequestDtoReason {
  iDonTNeedItAnymore = "I don't need it anymore",
  myProjectHasEnded = 'My project has ended',
  iStartedUsingAnotherTool = 'I started using another tool',
  iAmHavingTechnicalIssuesWithTheProduct = 'I am having technical issues with the product',
  budgetProblem = 'Budget problem',
  somethingElse = 'Something else'
}

export type CancelRenewalRequestDto = {
  reason: CancelRenewalRequestDtoReason;
  reasonDetail: string;
  feedback: string | null;
};

export enum OverrideRegionalSettingsOptions {
  showRegionalVariants = 'SHOW_REGIONAL_VARIANTS',
  hideRegionalVariants = 'HIDE_REGIONAL_VARIANTS'
}

export type GetLanguagesQueryArgs = {
  overrideRegionalSettings?: OverrideRegionalSettingsOptions;
};

export type ChangePasswordQueryArgs = {
  currentPassword: string;
  newPassword: string;
};

export enum DeleteAccountResonOptions {
  troubleGettingStarted = 'Trouble getting started',
  thoughtThisWasSomethingElse = 'Thought this was something else',
  createdASecondAccount = 'Created a second account',
  iWasLookingToBuyTranslations = "I don't need it anymore"
}

export type DeleteAccountRequestDto = {
  password: string;
  reason: DeleteAccountResonOptions;
  reasonDetail: string;
};

export type GetGlossaryByIdQueryArgs = {
  id: number;
};

export type DeleteGlossaryQueryArgs = {
  id: number;
};

export type DeleteGlossaryEntryQueryArgs = {
  glossaryId: number;
  entryId: number;
};

export type CreateGlossaryQueryArgs = {
  sourceLanguageId: number;
  targetLanguageId: number;
};

export type CreateGlossaryEntryQueryArgs = {
  glossaryId: number;
  body: {
    sourceContent: string;
    targetContent: string;
    notes?: string;
  };
};

export type UpdateGlossaryEntryQueryArgs = {
  entryId: number;
  glossaryId: number;
  body: {
    sourceContent: string;
    targetContent: string;
    notes?: string;
  };
};

export type ImportGlossaryQueryArgs = {
  glossaryId: number;
  body: {
    stashedFileId: number;
  };
};

export type DeleteTranslationMemoriesQueryArgs = {
  itemId: number;
};

export type CreateSavedViewQueryArgs = {
  name: string;
  showExpandedView?: boolean;
  folderId?: number;
  status?: DocumentListStatus[];
  searchTerm?: string;
  translatorIds?: number[];
  ownerIds?: number[];
  languageIds?: number[];
};
