import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import DataTable from '@/components/v2/DataTable';

export const ActionHeader = () => {
  const { t: tDocumentList } = useTranslation('documentList');
  const { table } = DataTable.useContext('ActionHeader');

  const isAllRowsExpanded = table.getIsAllRowsExpanded();
  const label = isAllRowsExpanded ? tDocumentList('collapseAll') : tDocumentList('expandAll');
  return (
    <div className="flex w-full justify-end">
      <Button variant="text" color="primary" size="md" onClick={() => table.toggleAllRowsExpanded()}>
        {label}
      </Button>
    </div>
  );
};
