import { redokunApi } from './redokunApi';
import type { OkResponseDto, SendActivationEmailRequestDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const registrationApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    /*
     * Resend the invitation to become a translator
     */
    sendActivationEmail: builder.mutation<OkResponseDto, SendActivationEmailRequestDto>({
      query: ({ email }) => {
        return {
          url: 'registration/send-activation-email',
          method: 'POST',
          body: { email }
        };
      }
    })
  })
});

export const { useSendActivationEmailMutation } = registrationApi;

export const registrationApiUtil = {
  ...registrationApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(registrationApi.util.updateQueryData)
};
