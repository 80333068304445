import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Text } from '@/components/v2/Text';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import Tooltip from '@/components/v2/Tooltip';
import { InlineMessage } from '@/components/v2/InlineMessage';
import { Label } from '@/components/v2/Label';
import { Checkbox } from '@/components/v2/Checkbox';
import { Button } from '@/components/v2/Button';
import { APP_URL } from '@/config/urls';

import { UploadArea } from './components/UploadArea';
import { TranslationMemoryUploadContext } from './TranslationMemoryUploadLayout';
import { seeHowTranslationMemoryImportWorksLink } from './constants';

export const TranslationMemoryUpload = () => {
  const { t: tTM } = useTranslation('translationMemory');
  const history = useHistory();

  const { translationMemoryUploadState, setTranslationMemoryUploadState } = useContext(TranslationMemoryUploadContext);

  const formSchema = z.object({
    cleanIdentical: z.boolean(),
    trim: z.boolean()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cleanIdentical: translationMemoryUploadState.cleanIdentical,
      trim: translationMemoryUploadState.trim
    }
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    // Upadte the context state with the data from the form.
    setTranslationMemoryUploadState({ ...translationMemoryUploadState, ...data });
    history.push('/translation-memories/upload/confirm');
  };

  const titleBlock = (
    <div className="space-y-1">
      <Text color="primary" size="md" weight="bold" className="flex items-center space-x-1">
        <span>{tTM('uploadTmInTMXOrXLSXFormat')}</span>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <AccessibleIcon
              label="ri-information-line"
              icon="ri-information-line"
              className="text-lg font-normal text-blue-600"
            />
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-xs" side="right">
            <Text className="text-typography-inverse-primary" size="sm" weight="medium">
              {tTM('tmxTooltipText')}
            </Text>
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Tooltip.Root>
      </Text>
      <Text color="tertiary" size="sm">
        {tTM('max1File')}
      </Text>
    </div>
  );

  const informationsBlock = (
    <div className="mt-9 space-y-10">
      <ButtonLink href={seeHowTranslationMemoryImportWorksLink} variant="text">
        <AccessibleIcon label="ri-lightbulb-fill" icon="ri-lightbulb-fill" className="text-lg text-blue-600" />
        {tTM('seeHowTranslationMemoryImportWorks')}
      </ButtonLink>
      <InlineMessage type="informational" orientation="horizontal" className="flex-grow">
        <div className="flex w-full items-center justify-between">
          <Text size="md" weight="semibold" className="text-blue-800">
            {tTM('youCanDownloadTheExcelFileTemplateHere')}
          </Text>
          <ButtonLink href={`${APP_URL}/files/private/tm-import-template-excel.xlsx`} color="secondary" download>
            {tTM('download')}
          </ButtonLink>
        </div>
      </InlineMessage>
    </div>
  );

  const formFields = (
    <div className="space-y-6 py-10">
      <Controller
        name={'cleanIdentical'}
        control={form.control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <div className="space-y-2">
            <div className="flex space-x-3">
              <Checkbox id="understand" checked={value} onCheckedChange={onChange} />
              <Label color="primary" htmlFor="understand" text={tTM('skipIdenticalTranslationUnits')} />
            </div>
            <div>
              <Text size="xs" color="tertiary">
                {tTM('deletesAllTUsDescription')}
              </Text>
            </div>
          </div>
        )}
      />
      <Controller
        name={'trim'}
        control={form.control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <div className="space-y-2">
            <div className="flex space-x-3">
              <Checkbox id="trim" checked={value} onCheckedChange={onChange} />
              <Label color="primary" htmlFor="trim" text={tTM('removeUnnecessaryWhiteSpaces')} />
            </div>
            <div>
              <Text size="xs" color="tertiary">
                {tTM('removeUnnecessaryWhiteSpacesDescription')}
              </Text>
            </div>
          </div>
        )}
      />
      <div className="flex justify-end">
        <Button type="submit" disabled={!translationMemoryUploadState.stashedFileId}>
          {tTM('upload')}
        </Button>
      </div>
    </div>
  );

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tTM('translationMemoryUpload')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {titleBlock}
            <UploadArea
              translationMemoryUploadState={translationMemoryUploadState}
              setTranslationMemoryUploadState={setTranslationMemoryUploadState}
            />
            {informationsBlock}
            {formFields}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
