import { ComponentPropsWithRef } from 'react';

export type OnboardingWizardContentHeaderProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  triggerLabel: React.ReactNode;
  collapsed?: boolean;
} & ComponentPropsWithRef<'div'>;

export enum OnboardingWizardId {
  SETUP_FILE,
  PRE_TRANSLATE,
  EDIT_TRANSLATION,
  COMPLETED
}

export type OnboardingWizardItem = {
  id: OnboardingWizardId;
  title: string;
  description: string;
  icon: string;
};

export type OnboardingWizardContentProps = {
  current: OnboardingWizardId;
  items: OnboardingWizardItem[];
  description?: React.ReactNode;
} & ComponentPropsWithRef<'div'>;

export type OnboardingWizardContentItemProps = {
  item: OnboardingWizardItem;
  completed: boolean;
  isLastElement: boolean;
} & ComponentPropsWithRef<'div'>;
