import { find, reduce, sortBy } from 'lodash';
import { Editor, Element, Range, Transforms } from 'slate';

import { EPlaceholderType } from '@/redux/api/constants';
import type { TPlaceholder } from '@/redux/api/types';
import { EElementType } from '@/slate/constants';
import { emptyText } from '@/slate/utils/emptyText';
import type { IPlaceholderElementV2 } from '@/types/slate';

import type { TAvailablePlaceholder } from './types';

const sortPlaceholders = (placeholders: TPlaceholder[]) =>
  sortBy(placeholders, (placeholder) => (placeholder.type !== EPlaceholderType.MARKER ? -1 : 1));

const getPlaceholderNodes = (editor: Editor) =>
  Array.from(
    Editor.nodes<IPlaceholderElementV2>(editor, {
      at: [],
      match: (node) => Element.isElement(node) && node.type === EElementType.PLACEHOLDER_V2
    })
  ).map(([node]) => node);

export const getAvailablePlaceholders = (editor: Editor, placeholders: TPlaceholder[]) => {
  const sortedPlaceholders = sortPlaceholders(placeholders);
  const nodes = getPlaceholderNodes(editor);

  return reduce<TPlaceholder, TAvailablePlaceholder[]>(
    sortedPlaceholders,
    (availablePlaceholders, placeholder) => {
      const shortcut =
        (placeholder.type === EPlaceholderType.TAG || placeholder.type === EPlaceholderType.TAG_PAIR) &&
        placeholder.id.length === 1
          ? placeholder.id
          : undefined;

      if (placeholder.type === EPlaceholderType.TAG_PAIR) {
        const foundOpen = find(nodes, (node) => node.placeholder.id === placeholder.id && node.tag === 'opening');
        const foundClose = find(nodes, (node) => node.placeholder.id === placeholder.id && node.tag === 'closing');

        if (!foundOpen || !foundClose) {
          return [...availablePlaceholders, { placeholder, opening: !foundOpen, closing: !foundClose, shortcut }];
        }
      }

      const found = find(nodes, (node) => node.placeholder.id === placeholder.id);
      if (!found) {
        return [...availablePlaceholders, { placeholder, shortcut }];
      }

      return availablePlaceholders;
    },
    []
  );
};

export const insertPlaceholder = (editor: Editor, availablePlaceholder: TAvailablePlaceholder) => {
  const { placeholder, opening, closing } = availablePlaceholder;

  if (opening && closing) {
    const openingNode: IPlaceholderElementV2 = {
      type: EElementType.PLACEHOLDER_V2,
      placeholder,
      tag: 'opening',
      children: [emptyText()]
    };
    const closingNode: IPlaceholderElementV2 = {
      type: EElementType.PLACEHOLDER_V2,
      placeholder,
      tag: 'closing',
      children: [emptyText()]
    };

    const { selection } = editor;
    if (selection && Range.isExpanded(selection)) {
      const [start, end] = Range.edges(selection);
      Transforms.insertNodes(editor, closingNode, { at: end });
      Transforms.insertNodes(editor, openingNode, { at: start });
    } else {
      Transforms.insertFragment(editor, [openingNode, emptyText(), closingNode]);
      Transforms.move(editor, { reverse: true });
    }
  } else {
    const tag = opening ? 'opening' : closing ? 'closing' : 'self-closing';
    Transforms.insertNodes(editor, { type: EElementType.PLACEHOLDER_V2, placeholder, tag, children: [emptyText()] });
    Transforms.move(editor);
  }
};
