import { TFunction } from 'i18next';

import { LanguagesDto } from '@/redux/api/types';
import { LanguageGroup } from '@/routes/Document/types';

export const createLanguageGroups = (data: LanguagesDto, t: TFunction): LanguageGroup[] => {
  const group: LanguageGroup = {
    name: t('activeLanguages'),
    options: data.active.map((language) => ({
      ...language,
      id: language.id.toString(),
      label: language.name
    }))
  };

  return [group];
};
