import { Accept } from 'react-dropzone';

// Remember to add the dot (example .xlsx) as prefix or it won't be considered.
export const ACCEPT: Accept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.api+json': ['.tmx']
};

const POSSIBILITIES = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  tmx: 'application/vnd.api+json'
};

type KeyOfPossibilities = keyof typeof POSSIBILITIES;
export const KEYS_POSSIBILITIES = Object.keys(POSSIBILITIES);

export function acceptCreator(extension: string): Accept {
  if (!KEYS_POSSIBILITIES.includes(extension)) {
    return {};
  }

  const key = POSSIBILITIES[extension as KeyOfPossibilities];
  return { [key]: ACCEPT[key] };
}
