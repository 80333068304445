import type { ShowProps } from './types';

export const Show = <T,>({ when, fallback, children }: ShowProps<T>) => {
  if (when) {
    return <>{typeof children === 'function' ? children(when) : children}</>;
  }

  return <>{fallback}</>;
};
Show.displayName = 'Show';
