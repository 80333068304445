import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v2/Box';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Show } from '@/components/v2/Show';
import { Skeleton } from '@/components/v2/Skeleton';
import { Text } from '@/components/v2/Text';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { usePusherBindToEvent, usePusherSubscribeToChannel } from '@/providers/PusherProvider';

import { YourSubscription, YourSubscriptionSkeleton } from '../Settings/components/YourSubscription';
import { createBillingFrequencyLabels } from '../Settings/utils';

export const SettingsCurrentPlan: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { currentAccount, refetch } = useCurrentAccount();

  const billingFrequencyLabels = createBillingFrequencyLabels(tSettings);

  let billingFrequencyLabel = '';
  if (currentAccount.subscriptionInfo.billingFrequency) {
    billingFrequencyLabel = billingFrequencyLabels[currentAccount.subscriptionInfo.billingFrequency];
  }

  const { channel: accountChannel } = usePusherSubscribeToChannel(`private-account-${currentAccount.id}`);
  usePusherBindToEvent(accountChannel, 'subscription:changed', () => {
    refetch();
  });

  if (currentAccount.subscriptionInfo.isProcessingSubscriptionChange) {
    return (
      <Layout>
        <Box>
          <Text size="md">
            <Skeleton width="33%" />
          </Text>
          <div>
            <Text size="xl">
              <Skeleton width="33%" />
            </Text>
            <Text size="sm" className="mt-2">
              <Skeleton width="33%" />
            </Text>
          </div>

          <div className="flex justify-end">
            <Skeleton className="mt-7">
              <Button color="primary">{tSettings('upgradeOrDowngrade')}</Button>
            </Skeleton>
          </div>
        </Box>

        <div>
          <YourSubscriptionSkeleton />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      {/* User's active plan */}
      <Box>
        <Text size="md" weight="bold" color="primary">
          {tSettings('activePlan')}
        </Text>
        <div>
          <Text size="xl" weight="semibold" color="primary">
            {currentAccount.subscriptionInfo.planName ?? '–'}
          </Text>
          {billingFrequencyLabel && (
            <Text size="sm" color="tertiary" className="mt-2">
              {billingFrequencyLabel}
            </Text>
          )}
        </div>

        {/* TODO: */}
        {/* Commented because of missing API, to re-enable when it will work as expected. */}
        {/* <Button variant="text" color="primary" className="mt-7" asChild> */}
        {/*   <Link to={`/settings/current-plan/subscription-history`}> */}
        {/*     <AccessibleIcon className="text-sm" label="ri-history-line" icon="ri-history-line" /> */}
        {/*     <Text size="sm" weight="semibold" className="text-primary-active hover:text-primary-hoverHard"> */}
        {/*       {tSettings('seeYourSubscriptionHistoryHere')} */}
        {/*     </Text> */}
        {/*   </Link> */}
        {/* </Button> */}
        <div className="flex justify-end">
          {/* @todo: The "Plans" page is disabled, and we use the old one for now. Change when ready */}
          <ButtonLink color="primary" className="mt-7" href={`${APP_URL}/settings/plans`}>
            {tSettings('upgradeOrDowngrade')}
          </ButtonLink>
        </div>
      </Box>

      <div>
        {/* User's subscription stats */}
        <Show when={!currentAccount.subscriptionInfo.isTranslator}>
          <YourSubscription currentAccount={currentAccount} />
        </Show>
      </div>
    </Layout>
  );
};

const Layout = (props: { children: ReactNode }) => {
  const { t: tSettings } = useTranslation('settings');

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsCurrentPlan')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('currentPlan')}
          </Text>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-6">{props.children}</div>
      </div>
    </div>
  );
};
