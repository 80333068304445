import { useCombobox, UseComboboxStateChange } from 'downshift';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Checkbox } from '@/components/v2/Checkbox';
import Combobox from '@/components/v2/Combobox';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Show } from '@/components/v2/Show';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { useEffectOnceWhen } from '@/hooks/useEffectOnceWhen';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { LanguageExtendedDto } from '@/redux/api/types';
import { StashDocument } from '@/redux/slices/uploadDocumentsSlice/types';
import { LanguageGroup, LanguageOption } from '@/routes/Document/types';
import { getGroupFilter } from '@/routes/Document/utils';
import { DEFAULT_ENGLISH_LANG_ID } from '@/routes/UploadDocuments/steps/SetSourceLanguage/constants';
import { findLanguageById } from '@/routes/UploadDocuments/steps/SetSourceLanguage/utils';

interface SetSourceLanguageForm {
  sources: StashDocument[];
}

interface SourceLanguageItemProps {
  index: number;
  filename?: string;
  languageGroups: LanguageGroup[];
  languages?: LanguageExtendedDto[];
  options: LanguageOption[];
  onSelectedItemChange: (value: UseComboboxStateChange<LanguageOption>) => void;
}

export const SourceLanguageItem = (props: SourceLanguageItemProps) => {
  const { index, filename, languages, options, languageGroups, onSelectedItemChange } = props;
  const { t } = useTranslation('uploadDocuments');
  const { currentAccount } = useCurrentAccount();
  const form = useFormContext<SetSourceLanguageForm>();
  const source = form.watch(`sources.${index}`);

  const isCompleted = currentAccount.onboardingFlags.hasConfirmedAnyDocument;

  const [inputSearch, setInputSearch] = useState<string | undefined>();
  const [isTooltipOpen, setIsTooltipOpen] = useState(!isCompleted);

  const filteredLanguageGroups: LanguageGroup[] = inputSearch
    ? languageGroups.map(getGroupFilter(inputSearch))
    : languageGroups;

  // set default combobox language value
  // if nothing stored in localstorage, pick english as default language
  const defaultValue = findLanguageById(filteredLanguageGroups, source.sourceLanguage?.id, DEFAULT_ENGLISH_LANG_ID);

  const state = useCombobox({
    inputValue: inputSearch,
    items: options,
    itemToString(item) {
      return item ? item.label : '';
    },
    defaultSelectedItem: useMemo(() => defaultValue, [defaultValue]),
    onSelectedItemChange,
    stateReducer(_state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open on input clicked.
            highlightedIndex: 0 // with the first option highlighted.
          };
        default:
          return changes;
      }
    }
  });

  useEffectOnceWhen(() => {
    state.reset();
  }, defaultValue !== undefined);

  // treatContentAsHtml option is visible for only these ['json', 'xlsx', 'xliff'] files
  const canTreatContentAsHtml = (filename: string): boolean => {
    const validExtensions = ['json', 'xlsx', 'xliff', 'xlf']; // TODO :: move this to the BE
    const extensionMatch = filename.match(/\.([^.]+)$/);

    if (!extensionMatch) return false;

    const fileExtension = extensionMatch[1].toLowerCase();
    return validExtensions.includes(fileExtension);
  };

  const renderGroups = () =>
    filteredLanguageGroups.reduce(
      (results, section, sectionIndex) => {
        if (!isEmpty(section.options)) {
          results.sections.push(
            <div key={sectionIndex}>
              <Text size="sm" color="tertiary" weight="semibold" className="mx-2 my-2.5">
                {section.name}
              </Text>
              {section.options.map((option, optionIndex) => {
                const index = options.indexOf(option);
                return (
                  <Combobox.Item key={optionIndex} item={option} index={index} className="gap-3">
                    <FlagIcon label={option.name} code={option.flagCode || 'xx'} size="md" />
                    {option.label}
                  </Combobox.Item>
                );
              })}
            </div>
          );
        }
        return results;
      },
      { sections: [] as JSX.Element[], itemIndex: 0 }
    ).sections;

  return (
    <Surface radius="sm" className="flex w-full flex-col gap-6 px-4 py-3">
      <div className="flex flex-row">
        <div className="flex w-3/4 flex-row items-center gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap break-words px-2">
          <AccessibleIcon label="ri-file-3-fill" icon="ri-file-3-fill" className="text-lg text-gray-400" />
          <OverflowTooltip label={filename}>
            <Text size="md">{filename}</Text>
          </OverflowTooltip>
        </div>
        <div className="ml-auto w-72 max-w-xs">
          {languages && (
            <Tooltip.Root open={isTooltipOpen}>
              <Combobox.Root state={state}>
                <Tooltip.Trigger className="w-full">
                  <Combobox.Trigger
                    bordered
                    onClick={(e) => {
                      e.preventDefault();
                      setIsTooltipOpen(false);
                    }}
                  >
                    <Combobox.TriggerValue placeholder={t('selectOption')}>
                      {state.selectedItem?.label}
                    </Combobox.TriggerValue>
                    <Combobox.TriggerIcon />
                  </Combobox.Trigger>
                </Tooltip.Trigger>
                <Tooltip.Content className="max-w-xxs whitespace-normal text-sm" sideOffset={8}>
                  <Text className="text-typography-inverse-secondary" size="xs">
                    {t('ifEnglishIsNotYourSourceChangeItHere')}
                  </Text>
                  <Tooltip.Arrow />
                </Tooltip.Content>

                <Combobox.Content>
                  <Combobox.SearchItem
                    placeholder={t('selectOption')}
                    onClick={(e) => e.preventDefault()}
                    value={inputSearch}
                    onChange={(e) => setInputSearch((e.target as HTMLInputElement).value)}
                  />
                  <Combobox.ItemsContainer>{renderGroups()}</Combobox.ItemsContainer>
                </Combobox.Content>
              </Combobox.Root>
            </Tooltip.Root>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-12">
        <Show when={currentAccount.settings.enableFilterSelection}>
          <Controller
            control={form.control}
            name={`sources.${index}.importNumericData`}
            render={({ field: { value, onChange, ...field } }) => (
              <label className="flex flex-row gap-3">
                <Checkbox
                  className="peer"
                  size="sm"
                  checked={value}
                  onCheckedChange={(checked) => {
                    onChange(checked);
                    if (!checked) {
                      form.setValue(`sources.${index}.importNumericData`, false);
                    }
                  }}
                  {...field}
                />
                <Text
                  size="sm"
                  weight="medium"
                  color="primary"
                  className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
                >
                  {t('importNumericData')}
                </Text>
              </label>
            )}
          />
        </Show>
        <Show when={filename && canTreatContentAsHtml(filename)}>
          <Controller
            control={form.control}
            name={`sources.${index}.treatContentAsHtml`}
            render={({ field: { value, onChange, ...field } }) => (
              <label className="flex flex-row gap-3 ">
                <Checkbox
                  className="peer"
                  size="sm"
                  checked={value}
                  onCheckedChange={(checked) => {
                    onChange(checked);
                    if (!checked) {
                      form.setValue(`sources.${index}.treatContentAsHtml`, false);
                    }
                  }}
                  {...field}
                />
                <Text
                  size="sm"
                  weight="medium"
                  color="primary"
                  className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
                >
                  {t('treatContentAsHtml')}
                </Text>
                <Tooltip.Root>
                  <Tooltip.Trigger className="ml-[-10px]">
                    <AccessibleIcon className="text-sm text-blue-500" label="" icon="ri-information-line" />
                  </Tooltip.Trigger>
                  <Tooltip.Content className="max-w-xxs" side="right">
                    <Text className="text-typography-inverse-primary" size="xs">
                      {t('treatContentAsHtmlExplanation')}
                    </Text>
                    <Tooltip.Arrow />
                  </Tooltip.Content>
                </Tooltip.Root>
              </label>
            )}
          />
        </Show>
      </div>
    </Surface>
  );
};
