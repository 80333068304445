import type { CSSProperties } from 'react';
import { Trans } from 'react-i18next';

import { APP_URL } from '@/config/urls';
import { classNames } from '@/utils/classNames';

export interface IActivityItemHiddenEventsProps {
  count: number;
  className?: string;
  style?: CSSProperties;
}

export const ActivityItemHiddenEvents = (props: IActivityItemHiddenEventsProps) => {
  const { count, className, style } = props;

  return (
    <div className={classNames('pl-3 text-xs', className)} style={style}>
      <Trans
        as="span"
        i18nKey="components:activityItem.activitiesHidden"
        values={{ count }}
        components={{
          span: <span className="text-blueGray-800 opacity-60" />,
          a: (
            // The content is set dynamically by the Trans component
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={`${APP_URL}/settings/plans`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 hover:text-blue-600"
            />
          )
        }}
      />
    </div>
  );
};
ActivityItemHiddenEvents.displayName = 'ActivityItemHiddenEvents';
