import { ReactNode } from 'react';

import { Surface } from '@/components/v2/Surface';

type DataTableLayoutProps = {
  children?: ReactNode;
};
export const DataTableLayout = (props: DataTableLayoutProps) => {
  const { children } = props;

  return (
    <div className="flex flex-1 flex-row">
      <Surface className="w-full space-y-6 px-7 py-8" radius="sm">
        {children}
      </Surface>
    </div>
  );
};
