import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { dropdownItem } from './styles';
import type {
  DropdownMenuContentElement,
  DropdownMenuContentProps,
  DropdownMenuItemElement,
  DropdownMenuItemProps,
  DropdownMenuProps,
  DropdownMenuTriggerElement,
  DropdownMenuTriggerProps
} from './types';

const DropdownMenu = (props: DropdownMenuProps) => <DropdownMenuPrimitive.Root {...props} />;
DropdownMenu.displayName = 'DropdownMenu';

const DropdownMenuTrigger = forwardRef<DropdownMenuTriggerElement, DropdownMenuTriggerProps>((props, ref) => (
  <DropdownMenuPrimitive.Trigger {...props} asChild ref={ref} />
));
DropdownMenuTrigger.displayName = 'DropdownMenuTrigger';

const DropdownMenuContent = forwardRef<DropdownMenuContentElement, DropdownMenuContentProps>(
  ({ className, container, forceMount, sideOffset = 10, ...props }, ref) => (
    <DropdownMenuPrimitive.Portal container={container} forceMount={forceMount}>
      <DropdownMenuPrimitive.Content
        {...props}
        ref={ref}
        className={classNames(
          'flex flex-col gap-1 rounded-lg border border-gray-200 bg-white px-2.5 py-2 shadow-md transition',
          className
        )}
        sideOffset={sideOffset}
      />
    </DropdownMenuPrimitive.Portal>
  )
);
DropdownMenuContent.displayName = 'DropdownMenuContent';

const DropdownMenuItem = forwardRef<DropdownMenuItemElement, DropdownMenuItemProps>(
  ({ className, color, ...props }, ref) => (
    <DropdownMenuPrimitive.Item {...props} ref={ref} className={classNames(dropdownItem({ color }), className)} />
  )
);
DropdownMenuItem.displayName = 'DropdownMenuItem';

export { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger };
