import { BillingFrequencyEnum, CurrencyEnum, PlanDto } from '@/redux/api/types';
import { getPlanPrice } from '@/utils/plan';

export const getPriceByFrequencyAndCurrency = (
  frequency: BillingFrequencyEnum,
  currency: CurrencyEnum,
  plan?: PlanDto
): number => {
  if (plan) {
    const price = getPlanPrice(plan, { currency, frequency });
    return price.unit;
  }

  return 0;
};
