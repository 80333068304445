import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from './Table';

const TableObject = {
  Root: Table,
  Body: TableBody,
  Cell: TableCell,
  Header: TableHeader,
  HeaderCell: TableHeaderCell,
  Row: TableRow
};

export type {
  TableBodyProps,
  TableCellProps,
  TableHeaderCellProps,
  TableHeaderProps,
  TableProps,
  TableRowProps
} from './types';
export { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow };

export default TableObject;
