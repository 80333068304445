import type { CSSProperties } from 'react';
import { useMemo } from 'react';

import type { IMentionRangeDto } from '@/redux/api/types';
import type { ITeamMemberColor } from '@/redux/slices/teamMembersSlice/types';
import { CommentRenderer } from '@/routes/DocumentLanguage/components/comments/CommentRenderer';
import type { PartialRecord } from '@/types/types';
import { classNames } from '@/utils/classNames';

export interface IActivityItemCommentProps {
  content: string;
  mentions: IMentionRangeDto[];
  mentionColors?: PartialRecord<number, ITeamMemberColor>;
  defaultMentionColor?: ITeamMemberColor;
  className?: string;
  style?: CSSProperties;
}

export const ActivityItemComment = (props: IActivityItemCommentProps) => {
  const { content, mentions, className, defaultMentionColor, mentionColors, style } = props;

  const comment = useMemo(() => ({ text: content, mentionRanges: mentions }), [content, mentions]);

  return (
    <CommentRenderer
      comment={comment}
      defaultMentionColor={defaultMentionColor}
      mentionColors={mentionColors}
      className={classNames('pl-9 text-blueGray-800', className)}
      style={style}
    />
  );
};
ActivityItemComment.displayName = 'ActivityItemComment';
