import { last, reduce } from 'lodash';

import { EListableType } from '@/redux/api/constants';
import type { IActivityListableItem } from '@/redux/api/types';

import type { TFilteredActivityListableItem } from './types';

export const filterActivities = (activities: IActivityListableItem[]) =>
  reduce(
    activities,
    (filteredActivities, activity) => {
      if (activity.type !== EListableType.REDACTED_ACTIVITY) {
        filteredActivities.push(activity);
        return filteredActivities;
      }

      const lastFilteredActivity = last(filteredActivities);
      if (lastFilteredActivity?.type === EListableType.REDACTED_ACTIVITY) {
        lastFilteredActivity.count += 1;
        return filteredActivities;
      }

      filteredActivities.push({ ...activity, count: 1 });
      return filteredActivities;
    },
    [] as TFilteredActivityListableItem[]
  );
