import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import DataTable from '@/components/v2/DataTable';
import { VOID } from '@/constants';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetTeamDetailsQuery } from '@/redux/api/teamApi';

import { managersTableColumns } from '../managersTableColumns';

export const ManagersTable = () => {
  const { t: tSettings } = useTranslation('settings');
  const { currentAccount } = useCurrentAccount();

  // If the user isn't in a team using getUserTeam triggers and error
  // so I need to check if the user is in a team.
  const { data: userTeam, isLoading: userTeamIsLoading } = useGetTeamDetailsQuery(VOID, {
    skip: !currentAccount.subscriptionInfo.isInATeam
  });

  if (userTeamIsLoading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (userTeam) {
    const columns = managersTableColumns({ currentAccount, userTeam }, tSettings);
    return (
      <div className="rounded-xl bg-white p-7">
        <DataTable.Root columns={columns} data={userTeam.members}>
          <DataTable.Content>
            <DataTable.Header />
            <DataTable.Body />
          </DataTable.Content>
        </DataTable.Root>
      </div>
    );
  }

  return null;
};
