import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '@/components/v1/Button';
import { Jumbo } from '@/components/v1/Jumbo';
import { APP_URL } from '@/config/urls';

export const DocumentLanguageNotAvailable: FunctionComponent = () => {
  const { t } = useTranslation('documentLanguage');

  return (
    <Jumbo.Fullscreen>
      {{
        header: t('documentNotAvailable'),
        content: (
          <div className="space-y-8">
            <div className="text-center">{t('youDontHaveAccessToThisDocumentAnymore')}</div>
            <div className="text-center">
              <div className="font-bold">{t('whatCouldHaveCausedTheError')}</div>
              <div>{t('theDocumentHaveBeenDeletedOrTheOwnerHaveRevokedYourAccess')}</div>
            </div>
          </div>
        ),
        footer: (
          <ButtonLink href={`${APP_URL}/documents`} rel="noreferrer">
            {t('goBackToDocumentList')}
          </ButtonLink>
        )
      }}
    </Jumbo.Fullscreen>
  );
};
