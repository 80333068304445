import { FunctionComponent } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import type { IJumboProps } from './types';

export const Jumbo: FunctionComponent<IJumboProps> & { Fullscreen: FunctionComponent<IJumboProps> } = ({
  children = {}
}) => {
  const { header, content, footer } = children;

  return (
    <div className="flex flex-col items-center justify-center space-y-8 p-6 text-center">
      {isNonNullable(header) && <div className="text-3xl font-bold">{header}</div>}
      {isNonNullable(content) && <div className="text-sm">{content}</div>}
      {isNonNullable(footer) && <div className="text-sm">{footer}</div>}
    </div>
  );
};
Jumbo.displayName = 'Jumbo';

Jumbo.Fullscreen = (props) => (
  <div className="flex h-screen w-screen items-center justify-center bg-gray-100">
    <div className="-mt-40 max-w-lg">
      <Jumbo {...props} />
    </div>
  </div>
);
Jumbo.Fullscreen.displayName = 'JumboFullscreen';
