import { isNonNullable } from '@/utils/isNonNullable';
import { range } from '@/utils/range';

import { FIRST_PAGE } from './constants';

export const constrainPage = (page: number, min: number, max: number) => {
  let newPage = page;
  if (newPage < min) {
    newPage = min;
  } else if (newPage > max) {
    newPage = max;
  }
  return newPage;
};

export const getPagesRange = (lastPage: number, maxElements: number, current?: number) => {
  const middle = Math.ceil(maxElements / 2);
  let firstVisiblePage: number;
  let lastVisiblePage: number;

  if (!isNonNullable(current) || current < middle) {
    // At beginning
    firstVisiblePage = FIRST_PAGE;
    lastVisiblePage = Math.min(maxElements, lastPage);
  } else if (current > lastPage - middle) {
    // At the end
    firstVisiblePage = Math.max(1, lastPage - maxElements + 1);
    lastVisiblePage = lastPage;
  } else {
    // In the middle
    firstVisiblePage = current - middle + 1;
    lastVisiblePage = current + middle - 1;
  }

  return range(firstVisiblePage, lastVisiblePage);
};
