import { Accept } from 'react-dropzone';

export const ACCEPT: Accept = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/html': ['.html', '.htm'],
  'application/json': ['.json'],
  'application/xml': ['.xml'],
  'text/plain': ['.txt'],
  'application/vnd.adobe.indesign-idml-package': ['.idml'],
  'application/xliff+xml': ['.xliff'],
  'application/x-xliff+xml': ['.xlf'],
  'application/x-subrip': ['.srt']
};

const POSSIBILITIES = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  html: 'text/html',
  htm: 'text/html',
  json: 'application/json',
  xml: 'application/xml',
  txt: 'text/plain',
  idml: 'application/vnd.adobe.indesign-idml-package',
  xliff: 'application/xliff+xml',
  xlf: 'application/x-xliff+xml',
  srt: 'application/x-subrip'
};

type TKPossibilities = keyof typeof POSSIBILITIES;
export const KEYS_POSSIBILITIES = Object.keys(POSSIBILITIES);

export function acceptCreator(extension: string): Accept {
  if (!KEYS_POSSIBILITIES.includes(extension)) {
    return {};
  }

  const key = POSSIBILITIES[extension as TKPossibilities];
  return { [key]: ACCEPT[key] };
}
