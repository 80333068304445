import { redokunApi } from './redokunApi';
import type {
  OkResponseDto,
  SavePaymentMethodDuringCheckoutRequestDto,
  SavePaymentMethodDuringCheckoutResponseDto,
  StartCheckoutRequestDto,
  StartCheckoutResponseDto
} from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const checkoutApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    startCheckout: builder.mutation<StartCheckoutResponseDto, StartCheckoutRequestDto>({
      query: (body) => ({
        url: '/checkout',
        method: 'POST',
        body
      })
    }),

    savePaymentMethod: builder.mutation<
      SavePaymentMethodDuringCheckoutResponseDto,
      SavePaymentMethodDuringCheckoutRequestDto
    >({
      query: (body) => ({
        url: '/checkout/payment-method',
        method: 'POST',
        body
      })
    }),

    confirmCheckout: builder.mutation<OkResponseDto, void>({
      query: () => ({ url: '/checkout/confirm', method: 'POST' }),
      invalidatesTags: ['currentAccount']
    })
  })
});

export const { useStartCheckoutMutation, useSavePaymentMethodMutation, useConfirmCheckoutMutation } = checkoutApi;

export const checkoutApiUtil = {
  ...checkoutApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(checkoutApi.util.updateQueryData)
};
