import { format } from '@/utils/date';

import { redokunApi } from './redokunApi';
import type {
  ExportTranslationMemoriesRequestDto,
  ExportTranslationMemoriesResponseDto,
  ImportTranslationMemoriesRequestDto,
  ImportTranslationMemoriesResponseDto,
  LanguagePairMemoriesResponseDto
} from './types';
import type { DeleteTranslationMemoriesQueryArgs, OkResponseDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const translationMemoryApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    /*
     * Get translation memories
     */
    getTranslationMemories: builder.query<LanguagePairMemoriesResponseDto, void>({
      query: () => {
        return {
          url: '/translation-memories'
        };
      },
      providesTags: ['translationMemory']
    }),

    /*
     * Start the import process of translation memories from a previously uploaded file.
     * The file must be uploaded using the /api/v1/file/upload endpoint and the stashedFileId
     * returned must be provided here in the request body
     */
    importTranslationMemories: builder.mutation<
      ImportTranslationMemoriesResponseDto,
      ImportTranslationMemoriesRequestDto
    >({
      query: (payload) => {
        return {
          url: '/translation-memories/import',
          method: 'POST',
          body: payload
        };
      },
      invalidatesTags: ['translationMemory']
    }),

    /*
     * Delete all the translation memories imported with the file referencing the ID given in the URL.
     */
    deleteTranslationMemories: builder.mutation<OkResponseDto, DeleteTranslationMemoriesQueryArgs>({
      query: ({ itemId }) => {
        return {
          url: `/translation-memories/${itemId}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['translationMemory']
    }),

    /* And endpoint to start the process of creating an export file of the chosen suggestions/tm for a given account. When the file is ready, an event will be sent through a websocket to the client, along with an ID which can be used to download the file. */
    exportTranslationMemories: builder.mutation<
      ExportTranslationMemoriesResponseDto,
      ExportTranslationMemoriesRequestDto
    >({
      query: ({ pairs, exportFormat }) => {
        return {
          url: `/translation-memories/export`,
          method: 'POST',
          body: { exportFormat, pairs }
        };
      },
      invalidatesTags: ['translationMemory']
    }),

    /*
     * Given an operation ID, which was the operation that created the export file, the system returns a binary stream of the file to the client. The client should use the operation ID that was given to them when the export operation was started. Since this is an arbitrary ID, there is no way for the client to reconstruct it if the first event is missed.
     */
    exportTranslationMemoriesByOperationId: builder.query<unknown, { operationId: number }>({
      query: ({ operationId }) => {
        return {
          url: `/translation-memories/export/${operationId}`,
          method: 'GET',
          cache: 'no-cache',
          responseHandler: (response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.blob();
          }
        };
      },
      transformResponse: (response: Blob | void, meta) => {
        if (meta && response instanceof Blob) {
          const { response: metaResponse } = meta;
          if (metaResponse) {
            const contentDispositions = metaResponse.headers.get('content-disposition');
            const contentType = metaResponse.headers.get('Content-Type');

            if (contentDispositions && contentType) {
              let filename = format(new Date(), 'YYYY-MM-DD_HH-mm');

              // NOTE: This pivot value has changed on the server side without being noticed.
              // Now we do additional checks and download the file even if the string changes again.
              const pivot = 'filename*=';
              const splitContentDisposition = contentDispositions.split(pivot);
              if (splitContentDisposition.length) {
                const secondHalf = splitContentDisposition[1];
                filename = secondHalf.replace(/['"]/g, '').replace('UTF-8', '');
              }

              const file = new File([response], filename, { type: contentType });
              const url = URL.createObjectURL(file);

              const link = document.createElement('a');
              link.href = url;
              link.download = filename;
              document.body.appendChild(link);

              link.dispatchEvent(
                new MouseEvent('click', {
                  bubbles: true,
                  cancelable: true,
                  view: window
                })
              );

              document.body.removeChild(link);
            }
          }
        }

        return {};
      }
    })
  })
});

export const {
  useGetTranslationMemoriesQuery,
  useLazyGetTranslationMemoriesQuery,
  useImportTranslationMemoriesMutation,
  useDeleteTranslationMemoriesMutation,
  useExportTranslationMemoriesMutation,
  useLazyExportTranslationMemoriesByOperationIdQuery
} = translationMemoryApi;

export const translationMemoryUtils = {
  ...translationMemoryApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(translationMemoryApi.util.updateQueryData)
};
