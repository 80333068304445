import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useConfirmSegmentsImportMutation, useGetBatchIdSegmentsQuery } from '@/redux/api';
import { ReactComponent as Loader } from '@/assets/loader.svg';
import { useAppDispatch } from '@/redux/hooks';
import { setBatchId, setImportNotifications } from '@/redux/slices/importSegmentsSlice/importSegmentsSlice';
import { Text } from '@/components/v2/Text';
import DataTable, { DataTableColumnDef } from '@/components/v2/DataTable';
import { SegmentDtoSegmentDto } from '@/redux/api/types';
import { ActivityItemEvent } from '@/components/v1/ActivityItem';
import { Button } from '@/components/v2/Button';
import { to } from '@/utils/awaitToJs';
import { TAppState } from '@/redux/store/types';
import { InlineMessage } from '@/components/v2/InlineMessage';
import { useToast } from '@/components/v2/Toast';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { sanitizeUrl } from '@/utils/sanitizeUrl';

import { StatusCell } from './components/StatusCell';

export const ConfirmStep = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('importSegments');
  const [showSpinner, setShowSpinner] = useState(false);
  const importState = useSelector((state: TAppState) => state.importSegments);
  const { toast } = useToast();
  const history = useHistory();

  const { documentId, languageId, batchId } = useDocumentParams();

  const [triggerConfirmSegmentsImport] = useConfirmSegmentsImportMutation();

  useEffect(() => {
    dispatch(setBatchId(batchId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (importState.newStatusImportStatus === 'DONE') {
      // Create user feedback
      dispatch(setImportNotifications([{ title: t('segmentsUploadedSuccessfully'), kind: 'success', duration: 6000 }]));
      // Redirect after finish
      history.replace(sanitizeUrl(`/documents/show/${documentId}/translations/${languageId}`));
    }
  }, [importState, documentId, languageId, dispatch, history, t]);

  const { data, isLoading, isError, isSuccess } = useGetBatchIdSegmentsQuery({
    batchId,
    documentId,
    languageId
  });

  useEffect(() => {
    if (data) {
      if (data?.isWrongFile !== undefined && data.isWrongFile) {
        toast({ title: t('isWrongFileToastTitle'), kind: 'error', duration: 3000 });
      }
      if (data?.errorCount !== undefined) {
        if (data.errorCount > 0) {
          toast({
            title: t('errorCountToastTitle', { errorCount: `${data.errorCount}` }),
            kind: 'error',
            duration: 3000
          });
        }
      }
    }
  }, [data, toast, t]);

  if (isLoading || showSpinner) {
    return (
      <div className="flex animate-spin justify-center">
        <Loader />
      </div>
    );
  }

  if (isError || (isSuccess && data.status === 'DONE')) {
    window.location.href = `/documents/show/${documentId}/translations/${languageId}/import-phrase`;
  }

  if (isSuccess) {
    const columns: DataTableColumnDef<SegmentDtoSegmentDto>[] = [
      {
        accessorKey: 'row',
        header: t('row'),
        cell: ({ row }) => <Text color="secondary">{row.original.row}</Text>
      },
      {
        accessorKey: 'id',
        header: t('id'),
        cell: ({ row }) => <Text color="secondary">{row.original.id}</Text>
      },
      {
        accessorKey: 'source',
        header: t('sourcePhrase'),
        cell: ({ row }) => <Text color="secondary">{row.original.source}</Text>
      },
      {
        accessorKey: 'target',
        header: t('targetPhrase'),
        cell: ({ row }) => (
          <ActivityItemEvent className="px-0 text-base font-normal text-gray-700" content={row.original.target} />
        )
      },
      {
        accessorKey: 'status',
        header: t('status'),
        cell: ({ row }) => <StatusCell status={row.original.status} />
      }
    ];

    const onConfirm = async () => {
      const [err] = await to(triggerConfirmSegmentsImport({ batchId, languageId, documentId }).unwrap());
      if (!err) {
        setShowSpinner(true);
      }
    };

    return (
      <div className="mx-auto mt-20 max-w-6xl">
        {/* Advices */}
        <div>
          {data.isPreviousRevision ? (
            <div className="pb-7">
              <InlineMessage type="warning" orientation="horizontal">
                <Text size="sm" weight="semibold" className="" color="primary">
                  {t('youReImportingTheTextSegmentsForAnOldRevisionTitle')}
                </Text>
                <Text size="sm" weight="regular" className="mt-2">
                  {t('youReImportingTheTextSegmentsForAnOldRevisionText')}
                </Text>
              </InlineMessage>
            </div>
          ) : null}
        </div>
        <div className="rounded-xl bg-white p-7">
          {/* Dashboard */}
          <div className="grid grid-cols-3 gap-4 pb-7">
            <div>
              <Text color="tertiary" size="sm">
                {t('changedCount')}
              </Text>
              <Text color="secondary" size="md" weight="semibold">
                {data.changedCount}
              </Text>
            </div>
            <div>
              <Text color="tertiary" size="sm">
                {t('notMatchedCount')}
              </Text>
              <Text color="secondary" size="md" weight="semibold">
                {data.notMatchedCount}
              </Text>
            </div>
            <div>
              <Text color="tertiary" size="sm">
                {t('errors')}
              </Text>
              <Text color="secondary" size="md" weight="semibold">
                {data.errorCount}
              </Text>
            </div>
          </div>
          {/* Table */}
          <div className="max-h-160 overflow-y-scroll p-[1px]">
            <DataTable.Root columns={columns} data={data.segments}>
              <DataTable.Container>
                <DataTable.Header className="sticky" />
                <DataTable.Body />
              </DataTable.Container>
            </DataTable.Root>
          </div>
          {/* Confirm button*/}
          <div className="flex justify-end space-x-6 pb-3 pt-6">
            {!data?.changedCount ? (
              <Button
                variant="text"
                color="primary"
                size="md"
                onClick={() => {
                  window.location.href = `/documents/show/${documentId}/translations/${languageId}/import-phrase`;
                }}
              >
                <AccessibleIcon label="arrow-left" icon="ri-arrow-left-line" />
                {t('goBack')}
              </Button>
            ) : null}
            {data?.changedCount !== undefined ? (
              <Button disabled={data.changedCount === 0} onClick={onConfirm}>
                {t('confirm')}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

const useDocumentParams = () => {
  const params = useParams<{ documentId: string; languageId: string; batchId: string }>();
  const parsedParams = useMemo(
    () => ({
      documentId: parseInt(params.documentId),
      languageId: parseInt(params.languageId),
      batchId: parseInt(params.batchId)
    }),
    [params]
  );
  return parsedParams;
};
