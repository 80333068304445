import { first as _first } from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommentsContext } from '@/providers/CommentsProvider';
import { CommentRenderer } from '@/routes/DocumentLanguage/components/comments/CommentRenderer';
import { standardDateTimeFormat } from '@/utils/date';

import { Avatar } from '../Avatar';
import { Badge } from '../Badge';
import { Button } from '../Button';
import { Popover } from '../Popover';
import type { INotificationItemProps } from './types';

export const NotificationItem: FunctionComponent<INotificationItemProps> = ({
  title,
  detail,
  notification,
  goToText,
  onGoToClick
}) => {
  const { id, senderAccount, createdAt, target, isRead } = notification;
  const { t } = useTranslation('notifications');
  const { teamMembersColors } = useCommentsContext();

  return (
    <div key={id} className="flex p-4">
      <div className="flex py-0.5">
        <Avatar size="sm" alt={_first(senderAccount.fullName)} src={senderAccount.avatarUrl || undefined} />
      </div>

      <div className="flex-1 px-2">
        <div>{title}</div>

        <div className="text-2xs text-gray-500">{standardDateTimeFormat(createdAt)}</div>

        <div className="mt-2 text-2xs text-gray-500">{senderAccount.fullName}</div>

        <CommentRenderer
          comment={target.content}
          mentionColors={teamMembersColors.colors}
          defaultMentionColor={teamMembersColors.defaultColor}
        />

        <div className="mt-2 flex space-x-2">
          <Popover placement="bottom-end" className="w-96 py-3" content={detail}>
            <Popover.Button as="div">
              <Button btnType="outline" color="gray" size="sm" className="text-gray-800">
                {t('showDetail')}
              </Button>
            </Popover.Button>
          </Popover>

          <Button btnType="outline" color="gray" size="sm" className="text-gray-800" onClick={onGoToClick}>
            {goToText}
          </Button>
        </div>
      </div>

      <div className="flex w-2 justify-center py-0.5">{!isRead && <Badge empty size="sm" />}</div>
    </div>
  );
};
