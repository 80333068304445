import { useCombobox, useMultipleSelection } from 'downshift';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, AvatarImage } from '@/components/v2/Avatar';
import { Button } from '@/components/v2/Button';
import { Checkbox } from '@/components/v2/Checkbox';
import Combobox from '@/components/v2/Combobox';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Show } from '@/components/v2/Show';
import { Skeleton } from '@/components/v2/Skeleton';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetTranslatorsQuery } from '@/redux/api';
import { PartialTranslatorDto } from '@/routes/Document/types';
import { useDocumentListSearchParams } from '@/routes/DocumentList/hooks/useDocumentListSearchParams';
import { getFilteredTranslators, removeDuplicateTranslators } from '@/utils/translators';

export const TranslatorCombobox = () => {
  const { t: tDocumentList } = useTranslation('documentList');

  const [
    { filterByTranslatorAccountIds: filterValueFromUrl },
    { setFilterByTranslatorAccountIds: setFilterValueFromUrl, setSavedView }
  ] = useDocumentListSearchParams();
  const { currentAccount } = useCurrentAccount();
  const { data, isLoading, isUninitialized } = useGetTranslatorsQuery({});
  const translators: PartialTranslatorDto[] =
    data?.translators?.map((t) => ({
      id: t.id,
      email: t.email,
      avatarUrl: t.avatarUrl,
      fullName: t.fullName
    })) || [];

  const translatorList = (() => {
    const me: PartialTranslatorDto = {
      id: currentAccount.id,
      avatarUrl: currentAccount.avatarUrl || '',
      email: currentAccount.email,
      fullName: `${currentAccount.fullName} (${tDocumentList('you')})`
    };
    return removeDuplicateTranslators([...translators, me]);
  })();

  const [inputSearch, setInputSearch] = useState<string | undefined>();
  const [selectedItems, setSelectedItems] = useState<PartialTranslatorDto[]>();

  useEffect(() => {
    if (data?.translators && filterValueFromUrl) {
      const initialSelectedItems = data.translators
        .filter((translator) => filterValueFromUrl.includes(translator.id))
        .map((t) => ({
          id: t.id,
          email: t.email,
          avatarUrl: t.avatarUrl,
          fullName: t.fullName
        }));
      setSelectedItems(initialSelectedItems);
    }
  }, [data, filterValueFromUrl]);

  const { removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            setSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    }
  });

  const state = useCombobox({
    items: translatorList ?? [],
    itemToString(item) {
      return item ? item.fullName : '';
    },
    inputValue: inputSearch,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputClick:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            // keep the menu open after selection. only if is already opened
            isOpen: state.isOpen
          };
        default:
          return changes;
      }
    },
    onStateChange({ type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          // added state.isOpen check as workaround to prevent side-effects
          // we want to trigger set or remove items only if dropdown is open
          if (!!newSelectedItem && state.isOpen) {
            if (selectedItems && selectedItems.some((s) => s.id === newSelectedItem.id)) {
              const item = selectedItems?.find((item) => item.id === newSelectedItem.id);
              if (item) {
                removeSelectedItem(item);
              }
            } else {
              setSelectedItems([...(selectedItems || []), newSelectedItem]);
            }
          }
          break;
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          setInputSearch('');
          break;
        default:
          break;
      }
    }
  });

  const renderItems = (filteredTranslators: PartialTranslatorDto[]) =>
    filteredTranslators.map((translator) => (
      <Combobox.Item
        key={translator.id}
        item={translator}
        index={translatorList?.indexOf(translator) || 0}
        className="w-auto max-w-max items-center gap-3"
      >
        <Checkbox
          checked={!!selectedItems?.find((i) => i.email === translator.email)}
          onClick={(e) => e.preventDefault()}
        />
        <Avatar size="sm" className="min-h-[40px] min-w-[40px]">
          <AvatarImage src={translator.avatarUrl} />
        </Avatar>
        <div className="flex flex-col gap-0.5 overflow-hidden">
          <OverflowTooltip label={translator.fullName} side="right">
            <Text color="primary" size="sm">
              {translator.fullName}
            </Text>
          </OverflowTooltip>
          <OverflowTooltip label={translator.email} side="right">
            <Text color="tertiary" size="xs">
              {translator.email}
            </Text>
          </OverflowTooltip>
        </div>
      </Combobox.Item>
    ));

  const handleClearFilter = () => {
    setInputSearch('');
    // clear document list
    setFilterValueFromUrl(undefined);
    setSavedView(null);
  };

  useEffect(() => {
    if (isEmpty(filterValueFromUrl)) setSelectedItems([]);
  }, [filterValueFromUrl]);

  const handleOnClick = () => {
    if (selectedItems) {
      setFilterValueFromUrl(selectedItems.flatMap((s) => s.id));
      setSavedView(null);
    }
  };

  // compute filtered translators list based on input search criteria, current account email, and available data
  const filteredTranslators: PartialTranslatorDto[] = useMemo(() => {
    // filter translators based on input search or use all translators if no search criteria provided
    const filteredTranslators = inputSearch ? getFilteredTranslators(inputSearch)(translatorList) : translatorList;

    // filter the filteredTranslators list to include only translators matching current account email or existing in data.translators
    // the aim is to remove stashed translators
    return filteredTranslators.filter(
      (ft) => ft.email === currentAccount.email || translatorList.some((t) => t.email === ft.email)
    );
  }, [inputSearch, translatorList, currentAccount]);

  const names = selectedItems?.flatMap((s) => s.fullName).join(', ');

  if (isUninitialized || isLoading) {
    return <Skeleton width="13rem" height="2.25rem" />;
  }

  return (
    <Combobox.Root state={state} className="max-w-[196px]">
      <Combobox.Trigger bordered className="h-9 place-content-start rounded-xl">
        <Text weight="medium" color="tertiary">
          {tDocumentList('translator')}
        </Text>

        <OverflowTooltip label={names}>
          <Text size="md" color="primary" className="whitespace-nowrap">
            <Show when={isEmpty(selectedItems)} fallback={names}>
              {tDocumentList('all')}
            </Show>
          </Text>
        </OverflowTooltip>
        <Combobox.TriggerIcon className="ml-auto" />
      </Combobox.Trigger>

      <Combobox.Content>
        <Combobox.SearchItem
          placeholder={tDocumentList('typeNameOrEmail')}
          onClick={(e) => e.preventDefault()}
          value={inputSearch}
          onChange={(e) => setInputSearch((e.target as HTMLInputElement).value)}
          size="sm"
        />
        <Combobox.ItemsContainer className="max-h-56">{renderItems(filteredTranslators)}</Combobox.ItemsContainer>
        <Button
          variant="solid"
          color="primary"
          className="mt-1 w-full"
          size="sm"
          onClick={handleOnClick}
          type="button"
          disabled={!selectedItems}
        >
          {tDocumentList('filterTranslators')}
        </Button>
        <Button
          variant="solid"
          color="secondary"
          className="mt-1 w-full"
          size="sm"
          onClick={handleClearFilter}
          type="button"
          disabled={!selectedItems}
        >
          {tDocumentList('clearFilter')}
        </Button>
      </Combobox.Content>
    </Combobox.Root>
  );
};
