import type { RenderElementProps } from 'slate-react';
import { useFocused, useReadOnly, useSelected } from 'slate-react';

import { PlaceholderV1 } from '@/components/v1/Placeholder';
import type { IPlaceholderElementV1 } from '@/types/slate';

export interface IPlaceholderElementProps extends RenderElementProps {
  element: IPlaceholderElementV1;
}

export const PlaceholderElement = ({ attributes, element, children }: IPlaceholderElementProps) => {
  const { placeholder } = element;
  const selected = useSelected();
  const focused = useFocused();
  const readOnly = useReadOnly();
  const ring = !readOnly && selected && focused;

  return (
    <span {...attributes} contentEditable={false}>
      {children}
      <PlaceholderV1 className="mx-0.5" value={placeholder} ring={ring} />
    </span>
  );
};
