import { DEFAULT_PAGE_INDEX } from '@/components/v2/DataTable';
import useControlled from '@/components/v2/Pagination/hooks/useControlled';

export function usePagination(props: UsePaginationProps): UsePaginationResult {
  const {
    boundaryCount = 0,
    componentName = 'usePagination',
    count = 1,
    defaultPage = DEFAULT_PAGE_INDEX,
    disabled = false,
    hideNextButton = false,
    hidePrevButton = false,
    onChange: handleChange,
    page: pageProp,
    showFirstButton = false,
    showLastButton = false,
    siblingCount = 1,
    ...other
  } = props;

  const [page, setPageState] = useControlled({
    controlled: pageProp || DEFAULT_PAGE_INDEX,
    default: defaultPage,
    name: componentName,
    state: 'page'
  });

  const substractToStartFromIndex = (value: number) => {
    // Subtract 1 to start from 0-index
    setPageState(value - 1);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, value: number) => {
    if (!pageProp) {
      substractToStartFromIndex(value);
    }
    if (handleChange) {
      handleChange(event, value);
    }
  };

  // range function
  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count);

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1
  );

  // Basic list of items to render
  // e.g. itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
  const itemList = [
    ...(showFirstButton ? ['first'] : []),
    ...(hidePrevButton ? [] : ['previous']),
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2
      ? ['start-ellipsis']
      : boundaryCount + 1 < count - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    ...(siblingsEnd < count - boundaryCount - 1
      ? ['end-ellipsis']
      : count - boundaryCount > boundaryCount
      ? [count - boundaryCount]
      : []),

    ...endPages,
    ...(hideNextButton ? [] : ['next']),
    ...(showLastButton ? ['last'] : [])
  ];

  const incrementPage = () => {
    // Make sure it doesn't exceed the last page
    return Math.min(page + 1, count);
  };

  const decrementPage = () => {
    // Ensure it doesn't go below page 1
    return Math.max(1, page - 1);
  };

  // Map the button type to its page number
  const buttonPage = (type: string) => {
    switch (type) {
      case 'first':
        return 1; // Start from page 1
      case 'previous':
        return decrementPage();
      case 'next':
        return incrementPage();
      case 'last':
        return count - 1;
      default:
        return DEFAULT_PAGE_INDEX; // Start from page 1
    }
  };

  // Convert the basic item list to PaginationItem props objects
  const items: UsePaginationItem[] = itemList.map((item) => {
    const disabledNav = item === 'next' ? page >= count : page <= 1; // Correct condition for "previous" button

    return typeof item === 'number'
      ? {
          onClick: (event: React.MouseEvent<HTMLElement>) => {
            handleClick(event, item);
          },
          type: 'page' as UsePaginationItem['type'],
          page: item,
          selected: item === page,
          disabled,
          'aria-current': item === page ? 'true' : undefined
        }
      : {
          onClick: (event: React.MouseEvent<HTMLElement>) => {
            handleClick(event, buttonPage(item));
          },
          type: item as UsePaginationItem['type'],
          page: buttonPage(item),
          selected: false,
          disabled: disabledNav || disabled
        };
  });

  return {
    items,
    ...other
  };
}

export interface UsePaginationProps {
  /**
   * Number of always visible pages at the beginning and end.
   * @default 1
   */
  boundaryCount?: number;
  /**
   * The name of the component where this hook is used.
   */
  componentName?: string;
  /**
   * The total number of pages.
   * @default 1
   */
  count?: number;
  /**
   * The page selected by default when the component is uncontrolled.
   * @default 1
   */
  defaultPage?: number;
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, hide the next-page button.
   * @default false
   */
  hideNextButton?: boolean;
  /**
   * If `true`, hide the previous-page button.
   * @default false
   */
  hidePrevButton?: boolean;
  /**
   * Callback fired when the page is changed.
   *
   * @param {React.ChangeEvent<unknown>} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  /**
   * The current page.
   */
  page?: number;
  /**
   * If `true`, show the first-page button.
   * @default false
   */
  showFirstButton?: boolean;
  /**
   * If `true`, show the last-page button.
   * @default false
   */
  showLastButton?: boolean;
  /**
   * Number of always visible pages before and after the current page.
   * @default 1
   */
  siblingCount?: number;
}

export interface UsePaginationItem {
  onClick: React.ReactEventHandler;
  type: 'page' | 'first' | 'last' | 'next' | 'previous' | 'start-ellipsis' | 'end-ellipsis';
  page: number | null;
  selected: boolean;
  disabled: boolean;
}

export interface UsePaginationResult {
  items: UsePaginationItem[];
}
