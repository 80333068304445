import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import { Select } from '@/components/v2/Select';
import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';

const BillingDetailsFormSkeleton = () => (
  <Surface border className="space-y-7 p-7">
    <Text color="primary" weight="bold">
      <Skeleton width="50%" />
    </Text>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Input size="sm" />
      </Skeleton>
    </div>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Select size="sm" placeholder="" />
      </Skeleton>
    </div>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Input size="sm" />
      </Skeleton>
    </div>

    <div className="grid grid-cols-[2fr,1fr] gap-7">
      <div className="flex flex-col gap-3">
        <Label text={<Skeleton width="50%" />} size="sm" />
        <Skeleton width="100%">
          <Input size="sm" />
        </Skeleton>
      </div>

      <div className="flex flex-col gap-3">
        <Label text={<Skeleton width="50%" />} size="sm" />
        <Skeleton width="100%">
          <Input size="sm" />
        </Skeleton>
      </div>
    </div>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Input size="sm" />
      </Skeleton>
    </div>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Input size="sm" />
      </Skeleton>
    </div>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Input size="sm" />
      </Skeleton>
    </div>
  </Surface>
);
BillingDetailsFormSkeleton.displayName = 'BillingDetailsFormSkeleton';

export { BillingDetailsFormSkeleton };
