import { Editor, Transforms } from 'slate';

export const withSingleLine = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length < 1 && editor.children.length > 1) {
      Transforms.mergeNodes(editor);
    }

    normalizeNode([node, path]);
  };

  return editor;
};
