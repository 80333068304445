import { FlagIcon } from '@/components/v2/FlagIcon';
import { Text } from '@/components/v2/Text';

export const FlagAndName = ({ name, flagCode }: { name: string; flagCode?: string }) => {
  return (
    <span className="inline-flex items-center space-x-2">
      <FlagIcon label={name} code={flagCode || 'xx'} size="sm" />
      <Text size="sm" color="primary">
        {name}
      </Text>
    </span>
  );
};
