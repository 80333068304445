import { ETranslationInputStatus } from '../../../../components/v1/TranslationInput/constants';
import { EParagraphTranslationStatus } from '../../../../redux/api/constants';
import type { IUseTranslationInputStatusProps } from './types';

/**
 * Get translation input status based on API errors, alerts and paragraph translation status
 *
 * @param isApiError API error
 * @param isConfirmAlert Any confirm alert
 * @param translationStatus Paragraph translation status
 * @returns translation input status (warning or error)
 */
export const useTranslationInputStatus = ({
  isApiError,
  isConfirmAlert,
  translationStatus
}: IUseTranslationInputStatusProps) => {
  if (isApiError) {
    return ETranslationInputStatus.ERROR;
  }

  if (isConfirmAlert || translationStatus === EParagraphTranslationStatus.DRAFT) {
    return ETranslationInputStatus.WARNING;
  }

  if (translationStatus === EParagraphTranslationStatus.CONFIRMED) {
    return ETranslationInputStatus.SUCCESS;
  }

  return null;
};
