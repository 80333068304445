import { ToastProvider, Viewport } from '@radix-ui/react-toast';

import { Toast, useToast, TOAST_REMOVE_DELAY } from '@/components/v2/Toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider duration={TOAST_REMOVE_DELAY}>
      {toasts.map(({ ...props }, i) => (
        <Toast key={i} {...props} />
      ))}
      <Viewport className="outline-none fixed bottom-0 right-0 z-[9999] grid gap-4 p-8" />
    </ToastProvider>
  );
}
