import React, { forwardRef } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ICON_SIZES, ICONS, ICONS_COLOR, INFORMATIONAL } from '@/components/v2/InlineMessage/constants';
import { inlineMessageVariant } from '@/components/v2/InlineMessage/styles';
import { Text } from '@/components/v2/Text';
import { TextProps } from '@/components/v2/Text/types';
import { classNames } from '@/utils/classNames';

import type { InlineMessageProps } from './types';

export const InlineMessage = forwardRef<HTMLDivElement, InlineMessageProps>((props, ref) => {
  const { type, size, icon, className, children, rootClassName, ...rest } = props;
  const defaultIcon = icon ?? ICONS[type || INFORMATIONAL];
  const iconColor = ICONS_COLOR[type || INFORMATIONAL];
  const iconSize = ICON_SIZES[size || 'md'];

  return (
    <div ref={ref} className={classNames(inlineMessageVariant({ type, size }), rootClassName)} {...rest}>
      <AccessibleIcon
        label={defaultIcon}
        icon={defaultIcon}
        className={classNames(`self-start p-1.5 ${iconSize}`, iconColor)}
      />
      <div className={classNames('', className)}>{children}</div>
    </div>
  );
});
InlineMessage.displayName = 'InlineMessage';

export const InlineMessageTitle = React.forwardRef<React.ElementRef<typeof Text>, TextProps>(
  ({ className, size = 'md', weight = 'bold', ...props }, ref) => {
    return (
      <Text ref={ref} className={classNames('text-yellow-900', className)} size={size} weight={weight} {...props} />
    );
  }
);
InlineMessageTitle.displayName = 'InlineMessageTitle';

export const InlineMessageText = React.forwardRef<
  React.ElementRef<typeof Text>,
  TextProps & { type?: keyof typeof ICONS_COLOR }
>(({ className, type, size = 'sm', weight = 'regular', ...props }, ref) => {
  const color = ICONS_COLOR[type || 'secondary'];

  return <Text ref={ref} className={classNames(color, className)} size={size} weight={weight} {...props} />;
});
InlineMessageText.displayName = 'InlineMessageText';
