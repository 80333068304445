import type { IGetMtResponseDto } from '../../../../redux/api/types';
import { NonNullableField } from '../../../../types/types';

/**
 * Check if MT data is valid
 *
 * @param suggestionData MT API response
 * @returns true if the MT response, translation field and translationClean field are valid, false otherwise
 */
export const isValidMtResponse = (
  suggestionData?: IGetMtResponseDto
): suggestionData is NonNullableField<IGetMtResponseDto, 'translation' | 'translationClean'> =>
  !!suggestionData?.translation && !!suggestionData?.translationClean;
