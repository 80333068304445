import { forwardRef, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { For } from '@/components/v1/For';
import { Popover } from '@/components/v1/Popover';
import { Show } from '@/components/v1/Show';
import { Spinner } from '@/components/v1/Spinner';
import { Tag } from '@/components/v1/Tag';
import { Tooltip } from '@/components/v1/Tooltip';
import { TranslationContentV1, TranslationContentV2 } from '@/components/v1/TranslationContent';
import { useOffline } from '@/hooks/useOffline';
import { CommentAltLinesIcon, CommentAltPlusIcon, CopyIcon, ExclamationTriangleIcon } from '@/icons/solid';
import { EAppliedFilter, EPlaceholdersVersion, ETranslationUpdateSource } from '@/redux/api/constants';
import { isHttpStandardError } from '@/redux/api/utils/isError';
import { useAppSelector } from '@/redux/hooks';
import {
  selectFocusedParagraphId,
  selectOpenParagraphComments,
  selectOpenParagraphCommentsId,
  selectSuggestionsParagraphId
} from '@/redux/slices/documentLanguageSlice/selectors';
import { classNames } from '@/utils/classNames';

import {
  TranslationInputBusy,
  TranslationInputV1,
  TranslationInputV2
} from '../../../../components/v1/TranslationInput';
import { useParagraphSuggestions } from '../../hooks';
import {
  useCopySameParagraphTranslationMutation,
  useUpdateParagraphTranslationMutation,
  useUpdateParagraphTranslationStatusMutation
} from '../../hooks/useParagraphMutations';
import { useTranslationInputStatus } from '../../hooks/useTranslationInputStatus';
import { getParagraphElementId } from '../../utils';
import { FullStopConfirmAlert, MissingTagsConfirmAlert, SameTranslationsConfirmAlert } from '../alerts';
import { DocumentLanguageTranslationIcon } from '../DocumentLanguageTranslationIcon';
import { MTSuggestion } from '../MTSuggestion';
import { ParagraphActivityComments } from '../ParagraphActivityComments';
import { TMSuggestion } from '../TMSuggestion';
import { useTranslationInputEvents } from './hooks';
import type { IDocumentTranslationParagraphProps } from './types';
import { calculateOccurrences, fixFullStopAtEnd } from './utils';

// TODO: this component should be splitted into smaller components/files
// TODO: confirm alerts logic should be moved to a separate component/file and improved
export const DocumentLanguageParagraph = forwardRef<HTMLDivElement, IDocumentTranslationParagraphProps>(
  (
    {
      documentId,
      languageId,
      weConfig,
      placeholdersVersion,
      sourceLanguage,
      targetLanguage,
      paragraph,
      searchSource,
      searchTarget,
      teamMemberEditing,
      scrollMargin,
      onOpenComments,
      onTranslationFocus,
      onTranslationBlur,
      onGoToNextParagraph,
      onGoToFirstEmptyParagraph
    },
    ref
  ) => {
    const {
      translation,
      id: paragraphId,
      content,
      cleanContent,
      totalCommentsCount,
      unreadCommentsCount,
      glossaryEntries
    } = paragraph;
    const offline = useOffline();
    const disabled = !!teamMemberEditing || weConfig.isBlocked || offline;
    const openComments = useAppSelector(
      (state) => selectOpenParagraphCommentsId(state) === paragraphId && selectOpenParagraphComments(state)
    );
    const focused = useAppSelector((state) => selectFocusedParagraphId(state) === paragraphId);
    const showSuggestions = useAppSelector((state) => selectSuggestionsParagraphId(state) === paragraphId);
    const hasCustomLabel = !!paragraph.customLabel;
    const occurrences = calculateOccurrences(paragraph.identicalCount);

    const initialTranslationContent = translation?.content ?? '';
    const [translationContent, setTranslationContent] = useState(initialTranslationContent);
    const [confirmAlert, setConfirmAlert] = useState<ReactNode>();

    const { t } = useTranslation('documentLanguageParagraph');

    // Update translation hook
    const [
      updateTranslation,
      {
        data: updateTranslationData,
        isLoading: updateTranslationIsLoading,
        isSuccess: updateTranslationIsSuccess,
        isError: updateTranslationIsError,
        error: updateTranslationError
      }
    ] = useUpdateParagraphTranslationMutation({ documentId, languageId, paragraphId });

    // Update translation status
    const [updateTranslationStatus, { updateTranslationStatusIsLoading, updateTranslationStatusIsError }] =
      useUpdateParagraphTranslationStatusMutation({ documentId, languageId, paragraphId });

    // Copy same translation hook
    const [
      copySameTranslation,
      {
        copySameTranslationData,
        copySameTranslationIsLoading,
        copySameTranslationIsSuccess,
        copySameTranslationIsError
      }
    ] = useCopySameParagraphTranslationMutation({ documentId, languageId, paragraphId });

    // API hooks states (isLoading, isError, ...)
    const isApiLoading = updateTranslationIsLoading || updateTranslationStatusIsLoading || copySameTranslationIsLoading;
    const isApiError = updateTranslationIsError || updateTranslationStatusIsError || copySameTranslationIsError;

    // TM/MT suggestions queries
    const {
      data: { mtSuggestions, tmSuggestions },
      isFetching: isSuggestionsFetching
    } = useParagraphSuggestions({
      documentId,
      languageId,
      paragraphId,
      translationContent,
      weConfig,
      skip: !showSuggestions
    });

    const translationInputStatus = useTranslationInputStatus({
      isApiError,
      isConfirmAlert: !!confirmAlert,
      translationStatus: translation?.status
    });

    // Default translation content effect
    useEffect(() => {
      setTranslationContent(initialTranslationContent);
    }, [initialTranslationContent]);

    // APIs loading effect
    useEffect(() => {
      if (isApiLoading) {
        setConfirmAlert(null);
      }
    }, [isApiLoading, setConfirmAlert]);

    // Success copy same translation effect
    useEffect(() => {
      if (copySameTranslationData && copySameTranslationIsSuccess) {
        setConfirmAlert(null);
      }
    }, [copySameTranslationData, copySameTranslationIsSuccess, setConfirmAlert]);

    // Success update translation effect
    useEffect(() => {
      if (updateTranslationData && updateTranslationIsSuccess) {
        const {
          identicalEmptySegmentsCount,
          identicalFilledSegmentsCount,
          savedContent,
          filtersApplied,
          isStringDirty
        } = updateTranslationData;
        setTranslationContent(savedContent);

        if (isStringDirty && filtersApplied.includes(EAppliedFilter.MISSING_TAGS_ADDED)) {
          setConfirmAlert(
            <MissingTagsConfirmAlert
              border={false}
              onOk={() => {
                setConfirmAlert(null);
              }}
            />
          );
        } else if (identicalEmptySegmentsCount || identicalFilledSegmentsCount) {
          const isUpdate = !!identicalFilledSegmentsCount;
          const count = identicalEmptySegmentsCount + identicalFilledSegmentsCount;

          setConfirmAlert(
            <SameTranslationsConfirmAlert
              border={false}
              isUpdate={isUpdate}
              count={count}
              onOk={() => {
                copySameTranslation(isUpdate);
              }}
              onCancel={() => {
                setConfirmAlert(null);
              }}
            />
          );
        } else {
          setConfirmAlert(null);
        }
      }
    }, [updateTranslationData, updateTranslationIsSuccess, copySameTranslation, setConfirmAlert]);

    // Update translation error alert effect
    useEffect(() => {
      if (updateTranslationError) {
        const message = isHttpStandardError(updateTranslationError) ? (
          updateTranslationError.data.error
        ) : (
          <Trans
            i18nKey="documentLanguageParagraph:anErrorOccuredTranslationNotSaved"
            components={{ 1: <span className="font-bold" /> }}
          />
        );
        setConfirmAlert(<Alert border={false} type="error" message={message} />);
      }
    }, [updateTranslationError]);

    const saveTranslationContent = useCallback(
      (newTranslationContent: string, translationSource: ETranslationUpdateSource) => {
        if (newTranslationContent !== initialTranslationContent || updateTranslationIsError) {
          setTranslationContent(newTranslationContent);

          const fixedContent = fixFullStopAtEnd(
            sourceLanguage.code,
            content,
            targetLanguage.code,
            newTranslationContent
          );

          if (fixedContent) {
            setConfirmAlert(
              <FullStopConfirmAlert
                border={false}
                onOk={() => {
                  setTranslationContent(fixedContent);
                  updateTranslation(fixedContent, translationSource);
                }}
                onCancel={() => {
                  updateTranslation(newTranslationContent, translationSource);
                }}
              />
            );
          } else {
            updateTranslation(newTranslationContent, translationSource);
          }
        }
      },
      [content, initialTranslationContent, sourceLanguage, targetLanguage, updateTranslation, updateTranslationIsError]
    );

    const getCommentTooltip = () => {
      if (totalCommentsCount) {
        if (unreadCommentsCount > 0) {
          return t('readNewComments');
        } else {
          return t('readComments');
        }
      } else {
        return t('addComment');
      }
    };

    const { showShortcuts, handleFocus, handleBlur, handleKeyDown, handleKeyUp } = useTranslationInputEvents({
      translationContent,
      paragraph,
      tmSuggestions,
      mtSuggestions,
      onSaveContent: saveTranslationContent,
      onGoToNextParagraph,
      onGoToFirstEmptyParagraph
    });

    // Comments popover effect
    // This is not a good solution, but it's the only way to open the popover from outside.
    // https://headlessui.dev/react/popover
    const commentsOpenRef = useRef(false);
    const commentsButtonRef = useRef<HTMLButtonElement | null>(null);
    useEffect(() => {
      if (openComments && !commentsOpenRef.current) {
        commentsButtonRef.current?.click();
        onOpenComments?.(paragraph);
      }
    }, [commentsButtonRef, commentsOpenRef, openComments, paragraph, onOpenComments]);

    return (
      <div
        ref={ref}
        className="grid grid-cols-2 gap-x-4 gap-y-1 rounded-sm bg-white py-4"
        id={getParagraphElementId(paragraphId)}
        style={{ scrollMargin }}
      >
        {/* Source */}
        <div className="flex">
          <div className="flex w-8 items-center justify-center text-2xs text-gray-500">{paragraph.counter}</div>

          <div className="flex flex-1 flex-col space-y-0.5 overflow-hidden overflow-ellipsis break-words">
            <Show when={hasCustomLabel}>
              <Tag color="gray" size="xs">
                <span className="font-bold">{paragraph.customLabel}</span>
              </Tag>
            </Show>

            <Show when={placeholdersVersion === EPlaceholdersVersion.V1}>
              <TranslationContentV1
                content={content}
                sourceLanguage={sourceLanguage}
                targetLanguage={targetLanguage}
                className="leading-relaxed"
                searchWord={searchSource}
                dir={sourceLanguage.direction}
                glossaryEntries={glossaryEntries}
              />
            </Show>
            <Show when={placeholdersVersion === EPlaceholdersVersion.V2}>
              <TranslationContentV2
                content={content}
                sourceLanguage={sourceLanguage}
                targetLanguage={targetLanguage}
                className="leading-relaxed"
                searchWord={searchSource}
                dir={sourceLanguage.direction}
                glossaryEntries={glossaryEntries}
              />
            </Show>

            <Show when={occurrences}>
              <Tag color="lightGray" size="xs">
                <Trans
                  i18nKey="documentLanguageParagraph:moreOccurrences"
                  count={occurrences}
                  components={{ 1: <span className="font-bold" /> }}
                />
              </Tag>
            </Show>
          </div>
        </div>

        {/* Translation */}
        <div className="flex">
          <Spinner enabled={isApiLoading} containerClassName="flex-1">
            <TranslationInputBusy teamMemberEditing={teamMemberEditing}>
              <Show when={placeholdersVersion === EPlaceholdersVersion.V1}>
                <TranslationInputV1
                  key={initialTranslationContent}
                  initialTranslationContent={initialTranslationContent}
                  disabled={disabled}
                  readOnly={isApiLoading}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={focused}
                  placeholders={paragraph.placeholders}
                  dir={targetLanguage.direction}
                  languageCode={targetLanguage.code}
                  liveCharacterCount={weConfig.liveCharacterCountEnabled}
                  liveCharacterCountLimit={cleanContent.length}
                  icon={
                    isApiError ? (
                      <ExclamationTriangleIcon className="h-4 w-4 text-red-600" />
                    ) : (
                      <DocumentLanguageTranslationIcon
                        translationStatus={translation?.status}
                        disabled={disabled}
                        onClick={updateTranslationStatus}
                      />
                    )
                  }
                  footer={confirmAlert}
                  status={translationInputStatus}
                  search={searchTarget}
                  onChange={setTranslationContent}
                  onFocus={(event) => {
                    onTranslationFocus?.(paragraph);
                    handleFocus(event);
                  }}
                  onBlur={(event) => {
                    onTranslationBlur?.(paragraph);
                    handleBlur(event);
                  }}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                />
              </Show>
              <Show when={placeholdersVersion === EPlaceholdersVersion.V2}>
                <TranslationInputV2
                  key={initialTranslationContent}
                  initialTranslationContent={initialTranslationContent}
                  disabled={disabled}
                  readOnly={isApiLoading}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={focused}
                  placeholders={paragraph.placeholders}
                  dir={targetLanguage.direction}
                  languageCode={targetLanguage.code}
                  liveCharacterCount={weConfig.liveCharacterCountEnabled}
                  liveCharacterCountLimit={cleanContent.length}
                  icon={
                    isApiError ? (
                      <ExclamationTriangleIcon className="h-4 w-4 text-red-600" />
                    ) : (
                      <DocumentLanguageTranslationIcon
                        translationStatus={translation?.status}
                        disabled={disabled}
                        onClick={updateTranslationStatus}
                      />
                    )
                  }
                  footer={confirmAlert}
                  status={translationInputStatus}
                  search={searchTarget}
                  onChange={setTranslationContent}
                  onFocus={(event) => {
                    onTranslationFocus?.(paragraph);
                    handleFocus(event);
                  }}
                  onBlur={(event) => {
                    onTranslationBlur?.(paragraph);
                    handleBlur(event);
                  }}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                />
              </Show>
            </TranslationInputBusy>
          </Spinner>

          <div className="mt-2 flex w-12 items-start justify-around">
            <Tooltip title={t('copySourceText')} placement="top" className="z-20">
              <button
                tabIndex={-1}
                className={classNames('flex items-start text-blue-500', {
                  'hover:text-blue-600': !disabled,
                  'cursor-not-allowed opacity-50': disabled
                })}
                disabled={disabled}
                onClick={() => {
                  saveTranslationContent(content, ETranslationUpdateSource.CAT_COPY);
                }}
              >
                <CopyIcon className="h-4 w-4" />
              </button>
            </Tooltip>

            <Popover
              content={
                <ParagraphActivityComments documentId={documentId} languageId={languageId} paragraphId={paragraphId} />
              }
              placement="bottom-start"
              flipModifier={{
                enabled: true,
                options: {
                  fallbackPlacements: ['bottom-end'],
                  flipVariations: false,
                  mainAxis: false
                }
              }}
              className="w-96"
            >
              {({ open }) => {
                commentsOpenRef.current = open;

                return (
                  <Tooltip title={getCommentTooltip()} placement="top" className="z-20">
                    <Popover.Button
                      ref={commentsButtonRef}
                      disabled={offline}
                      tabIndex={-1}
                      className={classNames('flex items-start text-blue-500', {
                        'hover:text-blue-600': !offline,
                        'cursor-not-allowed opacity-50': offline
                      })}
                    >
                      {totalCommentsCount > 0 ? (
                        <CommentAltLinesIcon
                          className={classNames('h-4 w-4', unreadCommentsCount > 0 && 'text-yellow-500')}
                        />
                      ) : (
                        <CommentAltPlusIcon className="h-4 w-4" />
                      )}
                    </Popover.Button>
                  </Tooltip>
                );
              }}
            </Popover>
          </div>
        </div>

        {/* Suggestions */}
        <Show when={showSuggestions}>
          <div className="col-start-2">
            {isSuggestionsFetching && (
              <div className="mr-12 flex items-center justify-center py-4">
                <Spinner />
              </div>
            )}

            {!isSuggestionsFetching && (tmSuggestions.length > 0 || mtSuggestions.length > 0) && (
              <div className="space-y-0.5">
                <For each={tmSuggestions}>
                  {(suggestion, i) => (
                    <TMSuggestion
                      key={suggestion.id}
                      suggestion={suggestion}
                      sourceLanguage={sourceLanguage}
                      targetLanguage={targetLanguage}
                      weConfig={weConfig}
                      shortcut={i + 1}
                      showShortcut={showShortcuts}
                      onClick={() => {
                        saveTranslationContent(suggestion.contentToPaste, ETranslationUpdateSource.CAT_SUGGESTION);
                      }}
                    />
                  )}
                </For>

                <For each={mtSuggestions}>
                  {(suggestion, i) => (
                    <MTSuggestion
                      key={`${i}-${suggestion.vendor}`}
                      suggestion={suggestion}
                      sourceLanguage={sourceLanguage}
                      targetLanguage={targetLanguage}
                      shortcut={tmSuggestions.length + i + 1}
                      showShortcut={showShortcuts}
                      onClick={() => {
                        saveTranslationContent(suggestion.translation, ETranslationUpdateSource.CAT_MT);
                      }}
                    />
                  )}
                </For>
              </div>
            )}
          </div>
        </Show>
      </div>
    );
  }
);

DocumentLanguageParagraph.displayName = 'DocumentLanguageParagraph';
