import { FunctionComponent } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import { IPreviewPlaceholderProps } from './types';

export const PreviewPlaceholder: FunctionComponent<IPreviewPlaceholderProps> = ({ children = {} }) => {
  const { title, description } = children;

  return (
    <div className="flex h-112 flex-col justify-center border border-gray-300 bg-gray-100 p-4 text-center text-gray-500">
      {isNonNullable(title) && <div className="font-bold">{title}</div>}
      {isNonNullable(description) && <div>{description}</div>}
    </div>
  );
};
