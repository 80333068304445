import { FIVE_MINUTES, redokunApi } from './redokunApi';
import type {
  CreateEditGlossaryEntryResponseDto,
  CreateGlossaryEntryQueryArgs,
  CreateGlossaryQueryArgs,
  CreateGlossaryResponseDto,
  DeleteGlossaryEntryQueryArgs,
  DeleteGlossaryQueryArgs,
  GetGlossaryByIdQueryArgs,
  GlossaryListResponseDto,
  ImportGlossaryQueryArgs,
  ImportGlossaryResponseDto,
  ListGlossaryEntriesResponseDto,
  UpdateGlossaryEntryQueryArgs
} from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const glossaryApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getGlossary: builder.query<GlossaryListResponseDto, void>({
      query: () => `/glossary`,
      providesTags: ['glossary']
    }),
    getGlossaryById: builder.query<ListGlossaryEntriesResponseDto, GetGlossaryByIdQueryArgs>({
      query: ({ id }) => `/glossary/${id}`,
      providesTags: ['glossaryEntries']
    }),
    createGlossary: builder.mutation<CreateGlossaryResponseDto, CreateGlossaryQueryArgs>({
      query: (body) => ({
        url: '/glossary',
        method: 'POST',
        body
      })
    }),
    createGlossaryEntry: builder.mutation<CreateEditGlossaryEntryResponseDto, CreateGlossaryEntryQueryArgs>({
      query: ({ glossaryId, body }) => ({
        url: `/glossary/${glossaryId}/entry`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['glossaryEntries']
    }),
    updateGlossaryEntry: builder.mutation<CreateEditGlossaryEntryResponseDto, UpdateGlossaryEntryQueryArgs>({
      query: ({ entryId, glossaryId, body }) => ({
        url: `/glossary/${glossaryId}/entry/${entryId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['glossaryEntries']
    }),
    importGlossaryEntry: builder.mutation<ImportGlossaryResponseDto, ImportGlossaryQueryArgs>({
      query: ({ glossaryId, body }) => ({
        url: `/glossary/${glossaryId}/import`,
        method: 'POST',
        body,
        timeout: FIVE_MINUTES
      }),
      invalidatesTags: ['glossaryEntries']
    }),
    deleteGlossary: builder.mutation<void, DeleteGlossaryQueryArgs>({
      query: ({ id }) => ({
        url: `/glossary/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['glossary']
    }),
    deleteGlossaryEntry: builder.mutation<void, DeleteGlossaryEntryQueryArgs>({
      query: ({ glossaryId, entryId }) => ({
        url: `/glossary/${glossaryId}/entry/${entryId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['glossaryEntries']
    })
  })
});

export const {
  useGetGlossaryQuery,
  useLazyGetGlossaryQuery,
  useGetGlossaryByIdQuery,
  useLazyGetGlossaryByIdQuery,
  useDeleteGlossaryMutation,
  useDeleteGlossaryEntryMutation,
  useCreateGlossaryMutation,
  useCreateGlossaryEntryMutation,
  useUpdateGlossaryEntryMutation,
  useImportGlossaryEntryMutation
} = glossaryApi;

export const glossaryApiUtil = {
  ...glossaryApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(glossaryApi.util.updateQueryData)
};
