import {
  DocumentListItemType,
  EAclErrorCode,
  EActivityEventContentType,
  EAppliedFilter,
  EBlockedReason,
  EDocumentType,
  EErrorStatus,
  ELanguageDirection,
  EListableType,
  ENotificationType,
  EParagraphObjectType,
  EParagraphTranslationStatus,
  EPlaceholdersVersion,
  EPlaceholderType,
  EStandardErrorCode,
  ESuggestionReferenceType,
  EThumbnailStatus,
  ETranslationInvitationStatus,
  ETranslationUpdateSource,
  EventTypes,
  InviteStatus,
  MachineTranslationVendor,
  OperationType,
  SubscriptionSuspendedReason,
  TranslationStatus
} from '../constants';

export interface ICommentContentDto {
  text: string;
  mentionRanges: IMentionRangeDto[];
}

export interface IMentionRangeDto {
  offset: number;
  length: number;
  accountId: number;
}

export interface IAskLanguageUnlockRequestDto {
  notes: string;
}

export interface IChangeLanguageStatusRequestDto {
  status: TranslationStatus;
  doConfirmDraftTranslations?: boolean;
}

export interface ICopySameTranslationRequestDto {
  paragraphId: number;
  isUpdate: boolean;
}

export interface ICreateDocumentTranslatedCommentRequestDto {
  content: ICommentContentDto;
}

export interface ICreateParagraphCommentRequestDto {
  content: ICommentContentDto;
}

export interface IMarkDocumentTranslatedCommentReadRequestDto {
  lastReadDocumentTranslatedCommentId: number;
}

export interface IMarkNotificationReadRequestDto {
  lastReadNotificationId: number;
}

export interface IMarkParagraphCommentReadRequestDto {
  lastReadParagraphCommentId: number;
}

export interface IChangeTmRecordVisibilityRequestDto {
  isHidden: boolean;
}

export interface ITranslationUpdateRequestDto {
  paragraphId: number;
  content: string;
  source: ETranslationUpdateSource;
}

export interface IUpdateTranslationStatusRequestDto {
  paragraphId: number;
  status: EParagraphTranslationStatus;
}

export interface IBootstrapDataResponseDto {
  pusherChannels: IPusherChannelsDto;
  documentType: EDocumentType;
  documentName: string;
  hasPageThumbnails: boolean;
  sourceLanguage: LanguageDto;
  targetLanguage: LanguageDto;
  hasWriteAccess: boolean;
  translationStatus: TranslationStatus;
  segmentsTotalCount: number;
  segmentsTranslatedCount: number;
  segmentsDraftCount: number;
  segmentsWithMessagesCount: number;
  // This is never passed here from now on, but I needed to keep it to update the data async
  // This should be removed and handled in a different way
  segmentsWithGlossaryWarnings?: number;
  firstEmptyTranslation: TFirstEmptyTranslationDto;
  weConfig: IWeConfigDto;
  inviter?: ITeamMemberDto;
  teamMembers: ITeamMemberDto[];
  documentComments: IDocumentCommentDto[];
  placeholdersVersion: EPlaceholdersVersion;
  showActivities: boolean;
}

export type TFirstEmptyTranslationDto =
  | {
      exists: false;
      paragraphId?: number;
      paginationPage?: number;
    }
  | {
      exists: true;
      paragraphId: number;
      paginationPage: number;
    };

export interface LanguageDto {
  /**
   * Internal language ID
   */
  id: number;
  /**
   * Full name of the language
   */
  name: string;
  /**
   * Language code (ISO 639-1 or ISO 639-2 when ISO 639-1 is not available)
   */
  code: string;
  direction: ELanguageDirection;
  /**
   * Flag URL (Used only by legacy application. This will be removed in the future)
   */
  flagUrl?: string;
  /**
   * Flag code, to be used to display a flag in the UI
   */
  flagCode?: string;
}

export interface LanguageExtendedDto extends LanguageDto {
  /**
   * Is the language a common one? Helpful to show the most common on top of the list
   */
  isCommon: boolean;
  /**
   * Regional languages are variations of a language (e.g. UK and US English, en-UK and en-US)
   */
  isRegional: boolean;
}

export interface IPusherChannelsDto {
  account: string;
  document: string;
  documentTranslated: string;
  webEditor: string;
}

export interface IPageDto {
  number: number;
  segmentCount: number;
  firstParagraph?: IFirstParagraphDto;
  thumbnailStatus: EThumbnailStatus;
  thumbnailUrl?: string;
}

export interface ITeamMemberDto {
  id: number;
  fullName: string;
  avatarUrl?: string;
}

export interface IWeConfigDto {
  isBlocked: boolean;
  isConfirmed: boolean;
  blockedReason?: EBlockedReason;
  hasTmVisibilityToggleEnabled: boolean;
  isMtEnabled: boolean;
  mtProvidersEnabled: MachineTranslationVendor[];
  enablePresence: boolean;
  liveCharacterCountEnabled: boolean;
  tmSourceIndicatorEnabled: boolean;
  hasActivityFeed: boolean;
}

export interface ICopySameTranslationResponseDto {
  /** @deprecated use `updatedParagraphs` instead */
  updatedParagraphIds: number[];
  updatedParagraphs: IUpdatedParagraph[];
  updatedSegmentsTranslatedCount: number;
  copiedContent: string;
  firstEmptyTranslation: TFirstEmptyTranslationDto;
}

export interface ICreateDocumentTranslatedCommentResponseDto {
  documentTranslatedCommentId: number;
}

export interface ICreateParagraphCommentResponseDto {
  paragraphCommentId: number;
  segmentsWithMessagesCount: number;
  readCommentsCount: number;
  totalCommentsCount: number;
}

export interface IDeleteParagraphCommentResponseDto {
  segmentsWithMessagesCount: number;
  readCommentsCount: number;
  totalCommentsCount: number;
}

export interface IDocumentCommentDto {
  id: number;
  account: ITeamMemberDto;
  content: ICommentContentDto;
  isRead: boolean;
  createdAt: string;
}

export interface IFirstParagraphDto {
  id: number;
  paginationPage: number;
}

export interface IGetDocumentTranslatedCommentResponseDto {
  comments: IDocumentCommentDto[];
}

export interface IGetMtResponseDto {
  translation?: string;
  translationClean?: string;
}

export interface IDocumentInNotificationDto {
  id: number;
  name: string;
  placeholderVersion: EPlaceholdersVersion;
}

export interface IDocumentTranslatedCommentNotificationDto {
  id: number;
  content: ICommentContentDto;
  document: IDocumentInNotificationDto;
  sourceLanguage: ILanguageInNotificationDto;
  targetLanguage: ILanguageInNotificationDto;
  createdAt: string;
}

export interface IGetNotificationsResponseDto {
  notifications: TNotification[];
}

export interface ILanguageInNotificationDto {
  id: number;
  name: string;
}

export interface INotificationDto {
  id: number;
  type: ENotificationType;
  target: IDocumentTranslatedCommentNotificationDto | IParagraphCommentNotification;
  isRead: boolean;
  readAt?: string;
  senderAccount: ITeamMemberDto;
  createdAt: string;
}

export interface INewDocumentCommentNotification extends INotificationDto {
  type: ENotificationType.NEW_DOCUMENT_TRANSLATED_COMMENT;
  target: IDocumentTranslatedCommentNotificationDto;
}

export interface INewParagraphCommentNotification extends INotificationDto {
  type: ENotificationType.NEW_TRANSLATED_PARAGRAPH_COMMENT;
  target: IParagraphCommentNotification;
}

export type TNotification = INewDocumentCommentNotification | INewParagraphCommentNotification;

export interface IParagraphCommentNotification {
  id: number;
  content: ICommentContentDto;
  document: IDocumentInNotificationDto;
  sourceLanguage: ILanguageInNotificationDto;
  targetLanguage: ILanguageInNotificationDto;
  paragraph: IParagraphInNotification;
  createdAt: string;
}

export interface IParagraphInNotification {
  id: number;
  paginationPage: number;
  content: string;
  translation?: string;
}

export interface IGetParagraphCommentResponse {
  comments: IParagraphComment[];
}

export interface IGetTmSuggestionsResponse {
  suggestions: ISuggestion[];
}

export interface ISuggestion {
  id: number;
  content: string;
  contentWithoutPlaceholders: string;
  contentToPaste: string;
  contentHash: string;
  originalSource: string;
  sourcesComparison: string;
  matchRate: number;
  reference?: ISuggestionReference;
  createdAt: string;
}

export interface ISuggestionReference {
  type: ESuggestionReferenceType;
  name: string;
}

export interface IMarkDocumentTranslatedCommentReadResponse {
  readCommentIds: number[];
}

export interface IMarkNotificationReadResponse {
  readNotificationIds: number[];
}

export interface IMarkParagraphCommentReadResponse {
  readCommentIds: number[];
}

export interface JobInvitesDto {
  id: number;
  inviter: ITeamMemberDto;
  jobs: {
    documentName: string;
    targetLanguageName: string;
  }[];
  createdAt: string;
}

export interface TeamJoinInviteDto {
  id: number;
  inviter: ITeamMemberDto;
  teamName: string;
  createdAt: string;
}
export type ImpersonateInfoDto =
  | { isAdminLoginAs: true; adminAccountId: number }
  | { isAdminLoginAs: false; adminAccountId?: undefined };

export type MtRateLimitingDto = {
  used: number;
  threshold: number;
  shouldWarnUser: boolean;
  thresholdAlmostReachedMessage: string;
};

export interface CurrentAccountDto {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  organization: string;
  jobTitle: string;
  email: string;
  avatarUrl: string;
  unreadNotificationCount: number;
  helpScoutSignature: string;
  onboardingFlags: OnboardingFlagsDto;
  subscriptionInfo: SubscriptionInfoDto;
  settings: SettingsDto;
  pendingJobInvites: JobInvitesDto[];
  pendingTeamJoinInvite?: TeamJoinInviteDto;
  hasBillingData: boolean;
  featureFlags: Record<string, boolean>;
  impersonateInfo: ImpersonateInfoDto;
  isAdmin: boolean;
  mtRateLimiting: MtRateLimitingDto;
  importedTmItemsUsage: ImportedTmItemsUsageDto;
  country?: CountryDto;
}

export enum SettingsDTODeeplFormalityOptions {
  less = 'LESS',
  more = 'MORE',
  default = 'DEFAULT'
}

export enum SettingsDTOFrequencyOptions {
  daily = 'DAILY',
  weekly = 'WEEKLY',
  off = 'OFF'
}

export enum SettingsDTONotificationsOptions {
  all = 'ALL',
  partecipating = 'PARTECIPATING',
  never = 'NEVER'
}

export interface SettingsDto {
  /**
   * If true, the user can only have one session at a time
   */
  enforceSingleSession: boolean;
  /**
   * If true, soft returns are removed from the uploaded documents
   */
  skipSoftReturnsOnUpload: boolean;
  /**
   * If true, machine translation is automatically loaded
   */
  autoloadMt: boolean;
  /**
   * If true, the user can deactivate the default filters during the upload
   */
  enableFilterSelection: boolean;
  /**
   * If true, sentence segmentation is used (using SRX rules)
   */
  useSrxSegmentation: boolean;
  /**
   * If true, special spaces are converted to spaces
   */
  convertSpecialSpaces: boolean;
  /**
   * If true, regional languages are enabled
   */
  enableRegionalLanguages: boolean | null;
  /**
   * The formality setting for DeepL
   */
  deeplFormality: SettingsDTODeeplFormalityOptions | null;
  /**
   * The notification setting
   */
  notificationsSetting: SettingsDTONotificationsOptions | null;
  /**
   * The digest email frequency for the manager
   */
  digestEmailFrequencyForManager: SettingsDTOFrequencyOptions | null;
  /**
   * The digest email frequency for the translator
   */
  digestEmailFrequencyForTranslator: SettingsDTOFrequencyOptions | null;
  /**
   * If true, the live character count is enabled
   */
  enableLiveCharacterCount: boolean;
  /**
   * If true, the TM source indicator is enabled
   */
  enableTmSourceIndicator: boolean;
  /**
   * If true, enable fuzzy translation memory search
   */
  enableSearchSimilarity: boolean;
}

export interface OnboardingFlagsDto {
  hasUploadedAnyDocument: boolean;
  hasPreTranslatedAnyDocument: boolean;
  hasConfirmedAnyDocument: boolean;
  mustCompleteOnboarding: boolean;
  hasHiddenOnboardingWizardWidget: boolean;
}

/**
 * The billing frequency of the subscription
 */
export enum BillingFrequencyEnum {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY'
}

export interface SubscriptionInfoDto {
  isActive: boolean;
  isTrial: boolean;
  pageBalance: number;
  exportedWordBalance: number;
  hasLowWordBalance: boolean;
  lowWordBalanceThreshold: number;
  hasUnlimitedPages: boolean;
  hasUnlimitedWords: boolean;
  totalLanguages: number;
  totalTranslators: number;
  maxTranslators: number;
  isTranslator: boolean;
  teamSize: number;
  teamId: number;
  teamName: string;
  organization: string;
  maxTeamSize: number;
  maxGlossaryEntries: number;
  isInATeam: boolean;
  hasScheduledChanges: boolean;
  currentSubEndAt: string | null;
  isPlanWordBased: boolean;
  isSuspended: boolean;
  suspensionReason: SubscriptionSuspendedReason;
  canResolveSuspension: boolean;
  hasPastDueInvoice: boolean;
  planCode: string;
  features: PlanFeaturesDto;
  teamOwnerAccountId: number;
  planName: string;
  billingFrequency: BillingFrequencyEnum | null;
  isRenewing: boolean;
  nextBillingAt: string | null;
  isProcessingSubscriptionChange: boolean;
}

export interface PlanFeaturesDto {
  tmImport: boolean;
  tmManager: boolean;
  tmVisibilityToggle: boolean;
  machineTranslation: boolean;
  machineTranslationDeepL: boolean;
  glossary: boolean;
  preTranslateMt: boolean;
  activities: boolean;
  savedViews: boolean;
}

export interface OkResponseDto {
  status: string;
}

export type OperationInProgressResponseDto = {
  status: string;
  message: string | null;
};

export interface IUpdateCurrentAccountAvatarResponse {
  avatarFilename: string;
  avatarUrl: string;
}

export interface IParagraphComment {
  id: number;
  account: ITeamMemberDto;
  content: ICommentContentDto;
  isRead: boolean;
  createdAt: string;
}

export interface IStandardError {
  status: EErrorStatus;
  error: string;
  code: EStandardErrorCode;
  fieldsWithErrors: Record<string, string>;
}

export interface IAclError {
  status: EErrorStatus;
  error: string;
  code: EAclErrorCode;
}

export interface IPageReference {
  key: string;
  number: number;
  isMaster: boolean;
}

export interface IParagraph {
  id: number;
  counter: number;
  content: string;
  cleanContent: string;
  placeholders: TPlaceholder[];
  objectType: EParagraphObjectType;
  customLabel?: string;
  identicalCount: number;
  page?: IPageReference;
  translation?: IParagraphTranslation;
  unreadCommentsCount: number;
  readCommentsCount: number;
  totalCommentsCount: number;
  nextParagraph?: INextParagraph;
  glossaryEntries: IGlossaryEntry[];
}

export interface INextParagraph {
  id: number;
  paginationPage: number;
}

export interface IParagraphTranslation {
  id: number;
  content: string;
  status: EParagraphTranslationStatus;
}

export interface IPlaceholder {
  id: string;
  type: EPlaceholderType;
  label: string;
  code?: string;
}
export interface ITagPlaceholder extends IPlaceholder {
  type: EPlaceholderType.TAG;
}
export interface ITagPairPlaceholder extends IPlaceholder {
  type: EPlaceholderType.TAG_PAIR;
}
export interface IMarkerPlaceholder extends IPlaceholder {
  type: EPlaceholderType.MARKER;
  code: string;
}
export type TPlaceholder = ITagPlaceholder | ITagPairPlaceholder | IMarkerPlaceholder;

export interface ITranslationListResponse {
  paragraphs: IParagraph[];
  total: number;
  pages: IPageDto[];
}

export interface IChangeTmRecordVisibilityResponse {
  newSuggestionId?: number;
}

export interface IUpdateTranslationResponse {
  updatedSegmentsTranslatedCount: number;
  updatedSegmentsDraftCount: number;
  identicalEmptySegmentsCount: number;
  identicalFilledSegmentsCount: number;
  isStringDirty: boolean;
  filtersApplied: EAppliedFilter[];
  savedContent: string;
  glossaryEntries: IGlossaryEntry[];
  firstEmptyTranslation: TFirstEmptyTranslationDto;
}

export interface IUpdateTranslationStatusResponse {
  updatedParagraphIds: number[];
  updatedCount: number;
  segmentsDraftCount: number;
}

export interface IUpdateTranslationInvitationRequest {
  status: ETranslationInvitationStatus;
}

export interface IGlossaryEntry {
  sourceContent: string;
  targetContent: string;
  notes?: string;
  matchesOffsets: number[];
  isWarning: boolean;
}

export interface IUpdatedParagraph {
  id: number;
  glossaryEntries: IGlossaryEntry[];
}

export interface IChangeLanguageStatusResponse {
  segmentsDraftCount: number;
}

export interface IParagraphActivityListDto {
  activities: IActivityListableItem[];
  unreadCommentsCount: number;
  readCommentsCount: number;
  totalCommentsCount: number;
  totalActivityEventsCount: number;
}

export type IActivityListableItem =
  | {
      id: number;
      account: ITeamMemberDto;
      isRead: boolean;
      createdAt: string;
      type: EListableType.ACTIVITY;
      content: IActivityEventContentDto;
    }
  | {
      id: number;
      account: ITeamMemberDto;
      isRead: boolean;
      createdAt: string;
      type: EListableType.COMMENT;
      content: ICommentContentDto;
    }
  | {
      id: number;
      isRead: boolean;
      createdAt: string;
      type: EListableType.REDACTED_ACTIVITY;
    };

export interface IActivityEventContentDto {
  source: string;
  type: EActivityEventContentType;
  typeLabel: string;
  phraseStatus: string;
  contentDiff: string;
  previousContent: string;
  previousSource: ETranslationUpdateSource;
}

export interface ISurveyBoostrapResponseDto {
  onboardingSurvey: {
    questions: IQuestion[];
  };
  documentUploadLimitInMb: number;
  defaultCurrency?: CurrencyEnum;
}

export interface IQuestion {
  questionId: string;
  questionLabel: string;
  answers: IAnswer[];
  allowsExplicit: boolean;
}

export interface IAnswer {
  label: string;
  id: string;
}

export interface DocumentCreateFromSampleResponseDto {
  documentIds: number[];
}

export interface DocumentUploadResponseDto {
  successfulUploads: {
    documentStashedId: number;
    filename: string;
    internalFilename: string;
    size: number;
    hash: string;
    type: string;
  }[];
  errors: {
    filename: string;
    errors: string[];
    errorLink: string;
  }[];
}

/*
 * Revision rollback response interface
 */
export interface IDocumentRevisionRollbackResponseDto {
  latestRevisionDocumentId: number;
}

/**
 * The information needed to render the document detail page
 */
export interface DocumentDetailDto {
  sourceDocument: SourceDocumentDto;
  translations: DocumentTranslationDto[];
  previousRevision: {
    documentId: number;
    filename: string;
    revision: number;
    createdAt: string;
  };
}

/**
 * Information about an uploaded source document
 */
export interface SourceDocumentDto {
  id: number;
  uploader: ITeamMemberDto;
  isOwner: boolean;
  hasWriteAccess: boolean;
  language: LanguageDto;
  filename: string;
  folderId?: number;
  fileTypeString: string;
  canDownload: boolean;
  wordCount: number;
  charCount: number;
  phraseCount: number;
  phraseCountUnique: number;
  phraseCountUniquePercentage: number;
  revision: number;
  status: SourceDocumentDtoStatus;
  indexingError?: string;
  powerToolsMetadata?: unknown;
  createdAt: string;
  updatedAt?: string;
}

export enum SourceDocumentDtoStatus {
  INDEXING_ERROR = 'INDEXING_ERROR',
  UPLOADED = 'UPLOADED',
  INDEXED = 'INDEXED'
}

/**
 * Information about a document translation in a specific language
 */
export interface DocumentTranslationDto {
  id: number;
  language: LanguageDto;
  status: TranslationStatus;
  /**
   * Whether the content of this translation are locked/confirmed or not
   */
  isEditable: boolean;
  completedPercentage: number;
  translationProgress: TranslationProgressDto;
  /**
   * List of translators for this translation
   */
  translators: DocumentTranslatorDto[];
  isAllTranslatorsAccepted: boolean;
  preTranslateOptions: LanguagePreTranslateOptionsDto;
  createdAt: string;
  updatedAt?: string;
}

export interface TranslationProgressDto {
  /**
   * Total number of segments in the document (including draft translations)
   */
  translatedSegments: number;
  /**
   * Total number of segments that are missing a translation
   */
  untranslatedSegments: number;
  /**
   * Total number of segments that are in "draft" status
   */
  draftSegments: number;
}

/**
 * Information about pre-translation regarding a specific language
 */
export interface LanguagePreTranslateOptionsDto {
  /**
   * Whether the language pair (source + target) has any TM records or not
   */
  hasAnyTm: boolean;
  /**
   * Whether the language pair (source + target) has MT available or not
   */
  isMtAvailable: boolean;
  /**
   * List of MT vendors available for this language pair (source + target)
   */
  mtVendorsAvailable: MachineTranslationVendor[];
}

/**
 * This DTO is used to represent a translator in the document translation dto, with info on the relation with this document translation
 */
export interface DocumentTranslatorDto {
  id: number;
  fullName: string;
  email: string;
  inviteStatus: InviteStatus;
  isNotAccepted: boolean;
  avatarUrl: string;
}

export interface LaunchPreTranslationRequestDto {
  /**
   * List of languages to pre-translate
   */
  languages: PreTranslateLanguageSettingDto[];
  enableTmIdenticalMatch: boolean;
  markAsDraftIdentical: boolean;
  markAsDraftMt: boolean;
}

export interface PreTranslateLanguageSettingDto {
  languageId: number;
  doTmStep: boolean;
  doMtStep: boolean;
  mtVendor: MachineTranslationVendor;
}

export interface LaunchPreTranslationResponseDto {
  operationId: number;
}

export type GetOperationStatusResponseDto = {
  isDone: boolean;
  kind: OperationType;
  createdAt: string;
  /**
   * The payload. This will be empty if the operation is not yet completed.
   */
  result?: unknown;
} & { kind: OperationType.PRE_TRANSLATE; result?: PreTranslationOperationOutputDto };

/**
 * The output of a pre-translation operation
 */
export interface PreTranslationOperationOutputDto {
  languages: PreTranslatedLanguageResultDto[];
}

export interface PreTranslatedLanguageResultDto {
  language: LanguageDto;
  /**
   * The total number of strings in the language
   */
  total: number;
  /**
   * The number of strings that have been translated
   */
  translatedCount: number;
  /**
   * The new percentage of strings that have been translated
   */
  translatedPercent: number;
  /**
   * The number of strings that were missing before the pre-translation
   */
  missingBefore: number;
  /**
   * The number of strings that are still missing after the pre-translation
   */
  missingAfter: number;
  contextMatchResult: PreTranslationStepResultDto;
  identicalMatchResult: PreTranslationStepResultDto;
  mtResult: PreTranslationStepResultDto;
}

export interface PreTranslationStepResultDto {
  /**
   * The number of strings that have been translated in this particular step
   */
  translatedCount: number;
  hasBeenPerformed: boolean;
}

/**
 * Information about an active languages for the account and all the languages available in the platform for the current account
 */
export interface LanguagesDto {
  active: LanguageDto[];
  all: LanguageExtendedDto[];
  inUse: LanguageExtendedDto[];
}

/**
 * List of document's ids from previously stashed files
 */
export interface CreateDocumentFromStashDto {
  documentIds: number[];
}

/**
 * Get active translators for the account
 */
export type TranslatorDtoStatus = 'CREATED' | 'ACTIVATED';
export enum TranslatorDtoStatuses {
  created = 'CREATED',
  activated = 'ACTIVATED'
}

export interface TranslatorDto {
  id: number;
  fullName: string;
  email: string;
  avatarUrl: string;
  status: TranslatorDtoStatus;
  assignedLanguagesIds: number[];
  inviter: {
    id: number;
    fullName: string;
    avatarUrl: string;
  };
}

export interface TranslatorListDto {
  // List of translators
  translators: TranslatorDto[];
  // List of teammates (managers)
  teammates: TranslatorDto[];
}

/**
 * Get pending invited translator id
 */
export interface InvitedTranslatorDto {
  translatorAccountId: number;
}

/**
 * Add Languages to more documents dtos
 */
export interface LanguageSettingDto {
  languageId: number;
  accountIds: number[];
}
/**
 * Update document items dto
 */
export interface DocumentListOperationItemRequestDto {
  elementId: number;
  type: DocumentListItemType;
}

/**
 * Generate a translation for the document, given the selected language
 */
export interface LaunchTranslationGenerationResponseDto {
  operationId: number;
}

export interface GetDocumentListResponseDto {
  items: DocumentListItemDto[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    totalPages: number;
  };
}

export type DocumentListItemDocumentDto = {
  id: number;
  owner: {
    id: number;
    fullName: string;
    avatarUrl: string;
  };
  isOwner: boolean;
  hasWriteAccess: boolean;
  language: {
    id: number;
    name: string;
  };
  filename: string;
  pageCount: number;
  wordCount: number;
  revision: number;
  status: SourceDocumentDtoStatus;
  translations: {
    id: number;
    language: {
      id: number;
      name: string;
      flagCode: string;
    };
    status: TranslationStatus;
    isEditable: boolean;
    completedPercentage: number;
    translationProgress: {
      translatedSegments: number;
      untranslatedSegments: number;
      draftSegments: number;
    };
    translators: DocumentTranslatorDto[];
    allTranslatorsAccepted: boolean;
    isHidden: boolean;
  }[];
  createdAt: string;
  updatedAt: string;
};

export type DocumentListItemDto =
  | {
      type: DocumentListItemType.FOLDER;
      item: {
        id: number;
        name: string;
        createdAt: string;
      };
    }
  | {
      type: DocumentListItemType.DOCUMENT;
      item: DocumentListItemDocumentDto;
    };

export interface FoldersResponseDto {
  nestedFolderStructure: FolderHierarchyDto[];
}

export type FolderHierarchyDto = {
  id: number;
  name: string;
  children: FolderHierarchyDto[];
};

export const EXPORT_FORMATS = {
  xliff: 'XLIFF',
  ods: 'ODS',
  xlsx: 'XLSX'
} as const;

export type TExportFileFormats = (typeof EXPORT_FORMATS)[keyof typeof EXPORT_FORMATS];

export type SegmentDtoSegmentDto = {
  id: number;
  row: number;
  phrasePk: string;
  source: string;
  old: string;
  target: string;
  status:
    | 'CHANGED'
    | 'NOT_MATCHED'
    | 'DUPLICATE_ID'
    | 'SAME'
    | 'INVALID_STRING'
    | 'INVALID_ORIGINAL_PHRASE'
    | 'TAG_ERROR';
  error: string;
};

export type ListImportSegmentsResponseDto = {
  status: 'DONE' | 'ERROR' | 'PARSED';
  messages: string[];
  segments: SegmentDtoSegmentDto[];
  isWrongFile?: boolean;
  changedCount?: number;
  notMatchedCount?: number;
  errorCount?: number;
  translatedId?: number;
  isPreviousRevision?: boolean;
};

export type TImportDocumentSegmentsResponse = { batchId: number };
export type TImportDocumentSegmentsPayload = { documentId: number; languageId: number; file: File };

export type ConfirmSegmentsImportResponse = { status: 'ok' };
export type StashSegmentsImportResponse = { batchId: number };
export type ConfirmSegmentsImportPayload = {
  documentId: number;
  languageId: number;
  batchId: number;
};
export type StashSegmentsImportPayload = {
  documentId: number;
  languageId: number;
  stashedFileId: number;
};
export type TGetBatchIdSegmentsPayload = {
  documentId: number;
  languageId: number;
  batchId: number;
};

export type TGetExportDocumentSegmentsPayload = {
  documentId: number;
  languageId: number;
  format: TExportFileFormats;
  excludeTranslated: boolean;
};

export type TrackEventDto = {
  event: EventTypes;
  payload?: {
    validationErrorsStr: string;
  };
};

/**
 * Represents the response data containing details about a team.
 *
 */
export interface TeamDetailsResponseDto {
  id: number;
  teamName: string;
  currentAccountIsOwner: boolean;
  owner: ManagerDto;
  members: ManagerDto[];
  maxTeamSize: number;
  companyName: string;
}

export interface ManagerDto {
  id: number;
  name: string;
  avatarUrl: string;
  email: string;
  status: string;
  isYou: boolean;
}

/*
 * File Upload
 */

export enum EntityType {
  document = 'document',
  glossary = 'glossary',
  translationMemory = 'translationMemory',
  segmentsFile = 'segmentsFile'
}

export type SuccessfulUploadDto = {
  stashedFileId: number;
  filename: string;
  /**
   * Size of the uploaded file in bytes
   */
  size: number;
  fileType: EntityType;
  /**
   * True if the file contains HTML code (for documents only)
   */
  hasHtmlContent: boolean;
};

export type FailedUploadDto = {
  filename: string;
  errors: string[];
  /**
   * Some errors might have a link to the help section to help troubleshoot the problem
   */
  errorLink: string;
};

export type UploadedStashedFileResponseDto = {
  /**
   * Files uploaded successfully
   */
  successfulUploads: SuccessfulUploadDto[];
  /**
   * Files that could not be uploaded
   */
  failedUploads: FailedUploadDto[];
};

export enum DocumentListStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CONFIRMED = 'CONFIRMED',
  DOWNLOADED = 'DOWNLOADED'
}
export enum PreferredPaymentMethodOptions {
  stripe = 'STRIPE',
  wire = 'WIRE'
}

/**
 * The currency, use for billing purposes
 */
export enum CurrencyEnum {
  EUR = 'EUR',
  USD = 'USD'
}

export type InvoiceItemDto = {
  stringId: string;
  documentNumber: string;
  description: string;
  date: string;
  /** @deprecated Total amount of the invoice. Deprecated, use the property total instead */
  totalAmount: number;
  total: MoneyValueDto;
  currency: CurrencyEnum;
  isGenerated: boolean;
};

export type GetBillingDetailResponseDto = {
  accountId: number;
  companyName: string;
  countryId: number;
  countryName: string;
  address: string | null;
  city: string | null;
  zipCode: string | null;
  province: string | null;
  phone: string | null;
  vatNumber: string | null;
  billingEmail: string | null;
  recipientCode: string | null;
  isVatCorrect: boolean | null;
  hasPaymentMethod: boolean | null;
  preferredPaymentMethod: PreferredPaymentMethodOptions | null;
  cardBrand: string | null;
  cardExpiration: string | null;
  cardNumber: string | null;
  externalPk: string | null;
  createdAt: string;
  updatedAt: string;
  invoices: InvoiceItemDto[];
};

export interface CountryDto {
  /** Numeric ID of the country */
  id: number;
  /** Country name */
  name: string;
  /** Is the country in the EU (for billing purposes) */
  isEu: boolean;
  /** Is the country Italy (for billing purposes) */
  isItaly: boolean;
}

export type GetCountriesResponseDto = {
  countries: CountryDto[];
};

export interface UpdateBillingDetailsRequestDto {
  companyName: string;
  addressCountryId: number;
  addressStreet: string;
  addressCity: string;
  addressZipCode: string;
  addressProvince?: string | null;
  vatNumber?: string | null;
  billingEmail?: string | null;
  recipientCode?: string | null;
}

export interface PlanFeatureDto {
  /**
   * Name of the feature
   */
  name: string;
  /**
   * Descriptive text of the feature
   */
  description?: string | null;
  /**
   * Whether it's a ticked feature
   */
  hasTick?: boolean | null;
  /**
   * Whether this applies to monthly or yearly billing. If null, it applies to both.
   */
  billingFrequency?: BillingFrequencyEnum | null;
}

export interface PlanDto {
  /** Public identifier of the plan */
  planCode: string;
  /** Plan name */
  planName: string;
  /** Name of the plan the current plan is based upon */
  basedOnPlanName?: string | null;
  /** Monthly price in Euros */
  eurMonthlyPrice: MoneyValueDto;
  /** Yearly price in Euros */
  eurYearlyPrice: MoneyValueDto;
  /** Monthly price in US Dollars */
  usdMonthlyPrice: MoneyValueDto;
  /** Yearly price in US Dollars */
  usdYearlyPrice: MoneyValueDto;
  /** List of features of the plan */
  features: PlanFeatureDto[];
}

export type GetPlansResponseDto = {
  plans: PlanDto[];
  trialBlueprintPlanId: string;
};

export type SendActivationEmailRequestDto = {
  // Email of the account that should be sent a copy of the invitation email
  email: string;
};

// data about a glossary
export type GlossaryDto = {
  id: number;
  entryCount: number;
  lastUpdatedAt: string;
  sourceLanguage: LanguageDto;
  targetLanguage: LanguageDto;
  glossaryMatchIsBeta: boolean;
};

export type GlossaryListResponseDto = {
  glossaries: GlossaryDto[];
};

export type GlossaryEntryListItemDto = {
  // The id of the entry
  id: number;
  // The content of the entry in the source language
  sourceContent: string;
  // The content of the entry in the target language
  targetContent: string;
  // Any note that the user added to this entry
  notes?: string;
  // Whether the entry is case sensitive
  isCaseSensitive: boolean;
  // The last time the entry was updated
  lastUpdatedAt: string;
};

export type ListGlossaryEntriesResponseDto = {
  glossary: GlossaryDto;
  // A list of all the entries in this glossary
  entries: GlossaryEntryListItemDto[];
  // Whether the user has write access to the glossary and can edit entries
  hasWriteAccess: boolean;
};

export type CreateGlossaryResponseDto = {
  // the id of the newly created glossary
  glossaryId: number;
};

export type CreateEditGlossaryEntryResponseDto = {
  // the id of the newly created glossary entry
  id: number;
};

/**
 * Data about the files containing TMs uploaded by the user for a language pair
 */
export type TranslationMemoriesFileDto = {
  /**
   * Reference to the id of the file. Useful to delete the file itself.
   */
  fileId: number;
  /**
   * How many translation memories where extracted from this file.
   */
  translationMemoriesCount: number;
  /**
   * The name of the file.
   */
  fileName: string;
  /**
   * Whether this file is currently being deleted. This is necessary since deleting a file takes time and we could want to provide feedback to the user.
   */
  isDeleting: boolean;
};

/**
 * Data about the translation memories available for a language pair
 */
export type LanguagePairMemoriesDto = {
  sourceLanguage: LanguageDto;
  targetLanguage: LanguageDto;
  /**
   * The amount of TMs available for the language pair, extracted from previously approved translations
   */
  internalTranslationMemoriesCount: number;
  /**
   * Files containing TMs uploaded by the user for the language pair
   */
  uploadedFiles: TranslationMemoriesFileDto[];
};

export type LanguagePairMemoriesResponseDto = {
  /**
   * A list of all the language pairs for which there are translation memories available. Each language pair contains information about the source and target languages, the amount of TMs available for the language pair, and the files containing TMs uploaded by the user.
   */
  languagePairs: LanguagePairMemoriesDto[];
};

export type ImportTranslationMemoriesResponseDto = {
  operationId: number;
};

export type ImportTranslationMemoriesRequestDto = {
  stashedFileId: number;
  sourceLanguageId: number;
  targetLanguageId: number;
  cleanIdentical: boolean;
  trim: boolean;
};

export type ImportGlossaryResponseDto = {
  // Number of imported items
  imported: number;
  // Number of skipped items
  skipped: number;
  // Validation errors
  validationErrors: string[];
};

export enum TmExportFormatEnum {
  TMX = 'TMX',
  XLSX = 'XLSX'
}

export enum ExportItemTypeEnum {
  DOCUMENT = 'DOCUMENT',
  IMPORT = 'IMPORT'
}

export type ExportDocumentLanguagePairDto = {
  sourceLanguageId: number;
  targetLanguageId: number;
  type: ExportItemTypeEnum;
};

export type ExportImportLanguagePairDto = {
  translationMemoryId: number;
  type: ExportItemTypeEnum;
};

export type ExportTranslationMemoriesRequestDto = {
  exportFormat: TmExportFormatEnum;
  // A list of all the items we want to export, with metadata used to retrieve the correct information.
  pairs: (ExportDocumentLanguagePairDto | ExportImportLanguagePairDto)[];
};

export type ExportTranslationMemoriesResponseDto = {
  operationId: number;
};

export type SavedViewCreatedResponseDto = {
  id: number;
};

export type SavedViewDto = {
  id: number;
  isDefault: boolean;
  name: string;
  showExpandedView: boolean;
  folderId?: number;
  status: DocumentListStatus[];
  searchTerm: string;
  translators: number[];
  owners: number[];
  languages: number[];
};

export type GetSavedViewsResponseDto = {
  savedViewList: SavedViewDto[];
};

export interface StartCheckoutRequestDto {
  /**
   * The new plan to upgrade to
   */
  planCode: string;
  currency: CurrencyEnum;
  billingFrequency: BillingFrequencyEnum;
}

export interface StartCheckoutResponseDto {
  /** The ID of the picked plan */
  planId: number;
  /** The name of the picked plan */
  planName: string;
  /** The selected billing frequency */
  billingFrequency: BillingFrequencyEnum;
  /** The amount to be billed, excluding VAT (sub total) */
  amount: MoneyValueDto;
  /** The VAT amount */
  vat: MoneyValueDto;
  /** The VAT percentage (applicable only for Italian companies) */
  vatPercent: number;
  /** The total amount (amount + VAT) */
  total: MoneyValueDto;
  /** Indicates if credit has been applied */
  hasCreditApplied: boolean;
  /** The amount of credit applied */
  creditApplied: number;
  /** Indicates if billing change will occur later (by default it will immediate) */
  willBillLater: boolean;
  /** The date of the next billing cycle (AKA renewal date) */
  nextBillingCycleAt: string;
  /** The amount due (amount + VAT - credits) */
  amountDue: MoneyValueDto;
  /** The currency of the transaction */
  currency: CurrencyEnum;
  /** The client secret for the Stripe payment intent */
  intentClientSecret: string;
}

export interface SavePaymentMethodDuringCheckoutRequestDto {
  /**
   * The paymentMethodId returned by Stripe
   */
  stripePaymentMethodId: string;
}

export interface SavePaymentMethodDuringCheckoutResponseDto {
  /**
   * If this is true, then the charge was completed successfully
   */
  isDone: boolean;
  /**
   * If this parameter is true, the FE needs to go through the 3DS flow to approve the charge on the card
   */
  requiresAction: boolean;
  /**
   * The id of the payment intent just created
   */
  paymentIntentId?: string | null;
  /**
   * During the 3DS flow, the Stripe client-side SDK will require this parameter to complete the authentication for the charge
   */
  paymentIntentClientSecret?: string | null;
}

export interface MoneyValueDto {
  /** Amount expressed in cents */
  cents: number;
  /** Amount expressed as a floating point number */
  unit: number;
}

/**
 * Imported TM items usage information for the current account
 */
export interface ImportedTmItemsUsageDto {
  /** Number of TM items available for the user to import */
  remaining: number;
  /** Max number of TM items included with the user's plan */
  threshold: number;
  /** Whether the user reached the limit of imported TM items */
  isLimitReached: boolean;
}
