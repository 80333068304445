import type { ForProps } from './types';

export const For = <T,>({ each, children, fallback }: ForProps<T>) => {
  if (each && each.length) {
    return <>{each.map(children)}</>;
  }

  return <>{fallback}</>;
};
For.displayName = 'For';
