import type { IModalsState } from './types';

export enum EModalType {
  ASK_UNLOCK_TRANSLATION = 'ASK_UNLOCK_TRANSLATION',
  CONFIRM_TRANSLATION = 'CONFIRM_TRANSLATION',
  DELETE_DOCUMENT_COMMENT = 'DELETE_DOCUMENT_COMMENT',
  DELETE_PARAGRAPH_COMMENT = 'DELETE_PARAGRAPH_COMMENT',
  DOCUMENT_NOT_AVAILABLE = 'DOCUMENT_NOT_AVAILABLE',
  DOCUMENT_PRE_TRANSLATED = 'DOCUMENT_PRE_TRANSLATED',
  DOCUMENT_TRANSLATION_LOCKED = 'DOCUMENT_TRANSLATION_LOCKED',
  ERROR = 'ERROR',
  FEEDBACK = 'FEEDBACK',
  NEW_DOCUMENT_REVISION_AVAILABLE = 'NEW_DOCUMENT_REVISION_AVAILABLE',
  NEW_TRANSLATIONS_IMPORTED = 'NEW_TRANSLATIONS_IMPORTED',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  UNLOCK_TRANSLATION = 'UNLOCK_TRANSLATION',
  WORK_INVITATION = 'WORK_INVITATION',
  NOT_ENOUGH_WORDS_ERROR = 'NOT_ENOUGH_WORDS_ERROR',
  EDIT_TRANSLATOR_ASSIGNED_LANGUAGES = 'EDIT_TRANSLATOR_ASSIGNED_LANGUAGES',
  // new version of ASK_UNLOCK_TRANSLATION, it will replace "openAskUnlockTranslationModal" used in WebEditor
  ASK_TO_UNLOCK_TRANSLATION = 'ASK_TO_UNLOCK_TRANSLATION',
  // new version of FEEDBACK, it will replace "openFeedbackModal" used in WebEditor
  SEND_FEEDBACK = 'SEND_FEEDBACK',
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
  PAYMENT_ISSUES = 'PAYMENT_ISSUES',
  LOW_WORD_BALANCE = 'LOW_WORD_BALANCE',
  FEATURE_NOT_AVAILABLE = 'FEATURE_NOT_AVAILABLE'
}

export enum ModalVersion {
  V1 = 1,
  V2 = 2
}

export enum ErrorModalAction {
  CLOSE = 'CLOSE',
  RELOAD = 'RELOAD'
}

export const INITIAL_STATE: IModalsState = {};
