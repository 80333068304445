import { accountApiUtil } from './accountApi';
import { redokunApi } from './redokunApi';
import type {
  IGetNotificationsQueryArg,
  IGetNotificationsResponseDto,
  IMarkNotificationReadQueryArg,
  IMarkNotificationReadResponse
} from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const notificationApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<IGetNotificationsResponseDto, IGetNotificationsQueryArg | void>({
      query: (arg) => ({ url: 'notification', params: { limit: arg?.limit } }),
      providesTags: ['notifications']
    }),
    markNotificationRead: builder.mutation<IMarkNotificationReadResponse, IMarkNotificationReadQueryArg>({
      query: ({ body }) => ({
        url: 'notification/mark-read',
        method: 'POST',
        body
      }),
      onQueryStarted: ({ body }, { queryFulfilled, dispatch }) => {
        let unreadNotificationsCount = 0;

        const getNotificationsAction = notificationApiUtil.safeUpdateQueryData(
          'getNotifications',
          undefined,
          ({ notifications }) => {
            notifications.forEach((notification) => {
              if (notification.id <= body.lastReadNotificationId) {
                notification.isRead = true;
              }

              if (!notification.isRead) {
                unreadNotificationsCount++;
              }
            });
          }
        );
        const getNotificationsPatch = dispatch(getNotificationsAction);

        const getCurrentAccountAction = accountApiUtil.safeUpdateQueryData(
          'getCurrentAccount',
          undefined,
          (account) => {
            account.unreadNotificationCount = unreadNotificationsCount;
          }
        );
        const getCurrentAccountPatch = dispatch(getCurrentAccountAction);

        queryFulfilled.catch(() => {
          getNotificationsPatch.undo();
          getCurrentAccountPatch.undo();
        });
      }
    })
  })
});

export const { useGetNotificationsQuery, useLazyGetNotificationsQuery, useMarkNotificationReadMutation } =
  notificationApi;

export const notificationApiUtil = {
  ...notificationApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(notificationApi.util.updateQueryData)
};
