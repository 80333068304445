import { Link } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DataTable from '@/components/v2/DataTable';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Text } from '@/components/v2/Text';
import { DocumentListItemDto } from '@/redux/api/types';
import { isFolderItem } from '@/routes/DocumentList/components/DocumentListContent/utils';

export const FileNameCell = () => {
  const { cell } = DataTable.useCellContext<DocumentListItemDto, DocumentListItemDto>('FileNameCell');
  const { original: data } = cell.row;

  const { table } = DataTable.useContext('FileNameCell');

  if (isFolderItem(data)) {
    return (
      <div className="flex max-w-xs flex-row items-center gap-1">
        <AccessibleIcon
          icon="ri-folder-3-fill"
          label="ri-folder-3-fill"
          className="text-base text-states-alert-active"
        />
        <OverflowTooltip label={data.item.name}>
          <Text size="sm" weight="semibold" className="cursor-pointer whitespace-nowrap text-primary-active">
            <Link
              data-id={data.item.id}
              to={`/documents/folder/${data.item.id}`}
              // reset table selection
              onClick={() => table.resetRowSelection()}
            >
              {data.item.name}
            </Link>
          </Text>
        </OverflowTooltip>
      </div>
    );
  }
  const filename = cell.getValue();

  return (
    <div className="flex flex-col gap-5">
      <div className="flex max-w-xs flex-row gap-1 overflow-hidden whitespace-nowrap break-words">
        <AccessibleIcon icon="ri-file-3-fill" label="ri-file-3-fill" className="text-base text-gray-600" />
        <OverflowTooltip label={filename}>
          <Text size="sm" weight="semibold" className="text-primary-active">
            <Link to={`/documents/show/${cell.row.original.item.id}`}>{filename}</Link>
          </Text>
        </OverflowTooltip>
      </div>
    </div>
  );
};
