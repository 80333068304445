import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { Redirect, useLocation } from 'react-router-dom';

const Checkout = () => {
  const location = useLocation();

  const { currentAccount } = useCurrentAccount();

  if (!currentAccount.hasBillingData) {
    return <Redirect to={{ ...location, pathname: '/checkout/billing' }} />;
  }

  return <Redirect to={{ ...location, pathname: '/checkout/payment-method' }} />;
};
Checkout.displayName = 'Checkout';

export { Checkout };
