import { first } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/v1/Avatar';
import { useAppSelector } from '@/redux/hooks';
import { selectTeamMemberColor } from '@/redux/slices/teamMembersSlice/selectors';
import { classNames } from '@/utils/classNames';

import styles from './TranslationInputBusy.module.css';
import type { ITranslationInputBusyProps } from './types';

export const TranslationInputBusy = ({ teamMemberEditing, children }: ITranslationInputBusyProps) => {
  const { t } = useTranslation('documentLanguageTranslation');
  const teamMemberColor = useAppSelector(selectTeamMemberColor(teamMemberEditing?.id));

  if (teamMemberEditing) {
    return (
      <>
        <div className={'absolute h-full w-full rounded border bg-gray-100 px-2 py-1 text-gray-600'}>
          <div className="flex">
            <div className="flex py-0.5">
              <Avatar
                key={teamMemberEditing.id}
                size="sm"
                src={teamMemberEditing.avatarUrl || undefined}
                alt={first(teamMemberEditing.fullName)}
                className={classNames('border', teamMemberColor?.border)}
              />
            </div>
            <div className="flex-1 px-2">{t('teamMemberIsEditing', { teamMember: teamMemberEditing.fullName })}</div>
          </div>
        </div>

        <div className={styles['hidden-children']}>{children}</div>
      </>
    );
  }

  return <>{children}</>;
};
