export const BUTTON_BASE_CLASS_NAME = 'relative inline-flex items-center justify-center border rounded font-bold';

export const BUTTON_DEFAULTS = {
  btnType: 'filled',
  color: 'blue',
  size: 'md'
} as const;

export const BUTTON_SIZES = {
  xs: 'text-xs px-1 h-6',
  sm: 'text-sm px-1 h-6',
  md: 'text-sm px-2 h-8'
} as const;

export const BUTTON_COLORS = {
  filled: {
    colors: {
      black: 'bg-gray-800 border-transparent text-white',
      blue: 'bg-blue-500 border-transparent text-white',
      gray: 'bg-gray-400 border-transparent text-white',
      green: 'bg-green-500 border-transparent text-white'
    },
    hoverColors: {
      black: 'hover:bg-gray-900',
      blue: 'hover:bg-blue-600',
      gray: 'hover:bg-gray-500',
      green: 'hover:bg-green-600'
    }
  },
  outline: {
    colors: {
      black: 'bg-transparent border-gray-800 text-gray-800',
      blue: 'bg-transparent border-blue-500 text-blue-500',
      gray: 'bg-transparent border-gray-400 text-gray-400',
      green: 'bg-transparent border-green-500 text-green-500'
    },
    hoverColors: {
      black: 'hover:bg-gray-100',
      blue: 'hover:bg-blue-100',
      gray: 'hover:bg-gray-100',
      green: 'hover:bg-green-100'
    }
  },
  text: {
    colors: {
      black: 'bg-transparent border-transparent text-gray-800',
      blue: 'bg-transparent border-transparent text-blue-500',
      gray: 'bg-transparent border-transparent text-gray-400',
      green: 'bg-transparent border-transparent text-green-500'
    },
    hoverColors: {
      black: 'hover:text-gray-900',
      blue: 'hover:text-blue-600',
      gray: 'hover:text-gray-500',
      green: 'hover:text-green-600'
    }
  }
} as const;
