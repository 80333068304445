import type { ElementType, ReactElement } from 'react';
import { forwardRef } from 'react';

import { Box } from '@/components/v1/Box';
import { Show } from '@/components/v1/Show';
import { classNames } from '@/utils/classNames';

import { DEFAULT_ELEMENT } from './constants';
import s from './Placeholder.module.css';
import type { TDefaultElement, TPlaceholderProps } from './types';

export const Placeholder: <E extends ElementType = TDefaultElement>(
  props: TPlaceholderProps<E>
) => ReactElement | null = forwardRef(function Placeholder<E extends ElementType = TDefaultElement>(
  props: TPlaceholderProps<E>,
  ref: typeof props.ref
) {
  const { color = 'orange', tag = 'self-closing', ring, className, children, ...boxProps } = props;

  return (
    // @ts-expect-error: TS 5 migration error
    <Box
      as={DEFAULT_ELEMENT}
      ref={ref}
      className={classNames('relative inline-block h-4 text-xs leading-none', className)}
      {...boxProps}
    >
      <Show when={ring}>
        <span
          className={classNames('absolute -bottom-0.5 -left-0.5 -right-0.5 -top-0.5', {
            'bg-blue-400': color === 'blue',
            'bg-orange-400': color === 'orange',
            [s['tag-opening']]: tag === 'opening',
            [s['tag-closing']]: tag === 'closing',
            [s['tag-self-closing']]: tag === 'self-closing'
          })}
        />
      </Show>

      <span
        className={classNames('inline-block h-4 py-0.5', {
          'bg-blue-300': color === 'blue',
          'bg-orange-300': color === 'orange',
          [s['tag-opening']]: tag === 'opening',
          [s['tag-closing']]: tag === 'closing',
          [s['tag-self-closing']]: tag === 'self-closing'
        })}
      >
        {children}
      </span>
    </Box>
  );
});
