import { Slot } from '@radix-ui/react-slot';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';

import Tooltip from '@/components/v2/Tooltip';

interface OverflowTooltipProps {
  label: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  children?: React.ReactNode;
}

export const OverflowTooltip = ({ label, side, children }: OverflowTooltipProps) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const parentRef = useRef<HTMLElement>(null);
  const [element, ref] = useState(parentRef.current);

  const observer = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        if (entry) {
          const overflow =
            entry.target.scrollWidth > entry.target.clientWidth ||
            entry.target.scrollHeight > entry.target.clientHeight;
          setIsOverflow(overflow);
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return isOverflow ? (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Slot className="overflow-hidden overflow-ellipsis">{children}</Slot>
      </Tooltip.Trigger>
      <Tooltip.Content className="max-w-xs whitespace-normal break-words text-sm" sideOffset={8} side={side}>
        {label}
        <Tooltip.Arrow />
      </Tooltip.Content>
    </Tooltip.Root>
  ) : (
    <Slot ref={ref} className="overflow-hidden overflow-ellipsis">
      {children}
    </Slot>
  );
};
