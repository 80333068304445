import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import DataTable from '@/components/v2/DataTable';
import { Show } from '@/components/v2/Show';
import { useToast } from '@/components/v2/Toast';
import {
  ExportDocumentLanguagePairDto,
  ExportImportLanguagePairDto,
  ExportItemTypeEnum,
  TmExportFormatEnum
} from '@/redux/api/types';
import { useExportTranslationMemoriesMutation } from '@/redux/api/translationMemoryApi';
import { to } from '@/utils/awaitToJs';
import { OperationsKind } from '@/redux/slices/operationsSlice/types';
import { useAppDispatch } from '@/redux/hooks';
import { addOperationToQueue } from '@/redux/slices/operationsSlice/operationsSlice';

import { TranslationMemoriesTableRow } from '../../types';

export const BatchOperationHeader = () => {
  const { table } = DataTable.useContext<TranslationMemoriesTableRow>('BatchOperationHeader');
  const { t: tTM } = useTranslation('translationMemory');
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const [exportTranslationMemoryTrigger] = useExportTranslationMemoriesMutation();

  const selectedRows = table.getSelectedRowModel().rows.map(({ original }) => original);
  const filteredRows = selectedRows.filter((row) => {
    return row.segmentCount;
  });

  const handleDownload = async () => {
    const pairsToExport: (ExportDocumentLanguagePairDto | ExportImportLanguagePairDto)[] = [];

    if (filteredRows.length) {
      for (let i = 0; i < filteredRows.length; i++) {
        const { translationMemoryId, kind, sourceLanguage, targetLanguage } = filteredRows[i];

        if (kind) {
          if (kind === ExportItemTypeEnum.IMPORT && translationMemoryId) {
            pairsToExport.push({ translationMemoryId, type: kind });
          }

          if (
            kind === ExportItemTypeEnum.DOCUMENT &&
            sourceLanguage?.id !== undefined &&
            targetLanguage?.id !== undefined
          ) {
            pairsToExport.push({
              sourceLanguageId: sourceLanguage.id,
              targetLanguageId: targetLanguage.id,
              type: ExportItemTypeEnum.DOCUMENT
            });
          }
        }
      }

      const [err, res] = await to(
        exportTranslationMemoryTrigger({
          exportFormat: TmExportFormatEnum.TMX,
          pairs: pairsToExport
        }).unwrap()
      );
      if (!err) {
        dispatch(
          addOperationToQueue({
            operationId: res.operationId,
            message: tTM('downloadStarted'),
            kind: OperationsKind.DOWNLOAD_TM
          })
        );
        toast({ title: tTM('theDownloadIsBeingPrepared'), kind: 'success' });
      }
    }
  };

  return (
    <div className="flex w-full flex-1 flex-row items-center gap-10">
      <DataTable.HeaderCheckbox />
      <div className="flex w-full flex-row items-center justify-between">
        <Text size="md" weight="semibold" color="primary">
          <Show when={filteredRows.length === 1} fallback={tTM('countItemsSelected', { count: filteredRows.length })}>
            {tTM('countItemSelected', { count: filteredRows.length })}
          </Show>
        </Text>
        <div className="flex flex-row gap-7">
          <Button
            variant="text"
            onClick={() => {
              handleDownload();
            }}
          >
            <AccessibleIcon label="ri-file-download-line" icon="ri-file-download-line" className="text-lg" />
            {tTM('downloadAll')}
          </Button>
        </div>
      </div>
    </div>
  );
};
