import { forwardRef, useState } from 'react';
import { Separator } from '@radix-ui/react-separator';
import * as Collapsible from '@radix-ui/react-collapsible';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import { classNames } from '@/utils/classNames';
import { Title } from '@/components/v2/Title';
import { Text } from '@/components/v2/Text';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { useOnboardingWizard } from '@/providers/OnboardingWizardProvider/OnboardingWizardProvider';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

import s from './OnboardingWizardContent.module.css';
import {
  OnboardingWizardContentHeaderProps,
  OnboardingWizardItem,
  OnboardingWizardContentProps,
  OnboardingWizardId,
  OnboardingWizardContentItemProps
} from './types';

export const OnboardingWizardContent = forwardRef<HTMLDivElement, OnboardingWizardContentProps>(
  ({ items, current, description, className, ...props }, ref) => {
    const { t } = useTranslation('uploadDocuments');
    const { hide } = useOnboardingWizard('OnboardingWizardContent');
    const { currentAccount } = useCurrentAccount();

    const [open, setOpen] = useState(false);
    const isCompleted = currentAccount.onboardingFlags.hasConfirmedAnyDocument;

    const handleClick = () => {
      if (open && isCompleted) {
        hide();
      }
      setOpen(!open);
    };

    const renderItems = (items: OnboardingWizardItem[], current: OnboardingWizardId) =>
      items.map((item, i) => {
        const completed = item.id < current;
        const isLastElement = i === items.length - 1;
        return (
          <OnboardingWizardContentItem key={item.id} item={item} completed={completed} isLastElement={isLastElement} />
        );
      });

    const label = open ? t('hide') : t('show');
    const headerDescription = `${current}/${items.length} ${t('completed')}`;

    return (
      <div ref={ref} className={classNames('flex flex-col rounded-xl shadow-xl', className)} {...props}>
        <Collapsible.Root open={open} onOpenChange={setOpen}>
          <OnboardingWizardContentHeader
            title={t('translateYourFirstDocument')}
            description={headerDescription}
            triggerLabel={label}
            onClick={handleClick}
            collapsed={!open}
          />
          <Collapsible.Content className={s['collapsible-content']}>
            <div className="flex flex-col rounded-bl-xl rounded-br-xl bg-white px-6 py-4">
              {current === items.length ? (
                <OnboardingWizardCompleted />
              ) : (
                <>
                  {typeof description === 'string' ? (
                    <Text size="md" weight="regular" className="mb-4 text-typography-secondary">
                      {description}
                    </Text>
                  ) : (
                    description
                  )}
                  <div className="flex flex-col">{renderItems(items, current)}</div>
                </>
              )}
            </div>
          </Collapsible.Content>
        </Collapsible.Root>
      </div>
    );
  }
);

OnboardingWizardContent.displayName = 'OnboardingWizardContent';

export const OnboardingWizardContentHeader = forwardRef<HTMLDivElement, OnboardingWizardContentHeaderProps>(
  ({ title, triggerLabel, description, collapsed, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'flex w-full flex-row bg-primary-active px-6 py-5 text-typography-inverse-primary transition-all duration-300',
          collapsed ? 'rounded-xl' : 'rounded-tl-xl rounded-tr-xl',
          className
        )}
        {...props}
      >
        <div className="flex flex-row items-center justify-center">
          <div className="mr-4 flex h-6 w-6 items-center justify-center rounded-lg bg-white/25">
            <AccessibleIcon icon="ri-pushpin-fill" label="ri-pushpin-fill" className="z-10 text-white" />
          </div>
          <div className="flex flex-col">
            <Title size="md" weight="semibold" className="text-typography-inverse-primary">
              {title}
            </Title>
            <Text size="sm" weight="medium" className="text-typography-inverse-primary">
              {description}
            </Text>
          </div>
        </div>
        <Button
          variant="text"
          color="inverse-primary"
          className="ml-auto px-0 text-typography-inverse-primary hover:text-typography-inverse-primary"
        >
          {triggerLabel}
        </Button>
      </div>
    );
  }
);
OnboardingWizardContentHeader.displayName = 'OnboardingWizardContentHeader';

export const OnboardingWizardContentItem = forwardRef<HTMLDivElement, OnboardingWizardContentItemProps>(
  ({ className, item, completed, isLastElement, ...props }, ref) => {
    return (
      <div ref={ref} key={item.id} {...props}>
        <div className={classNames('flex flex-row items-center gap-4', className)}>
          {completed ? (
            <div
              className={classNames(
                'flex h-10 w-10 items-center justify-center rounded-full p-1',
                completed ? 'bg-surface-success' : 'bg-gray-100'
              )}
            >
              <AccessibleIcon
                icon={completed ? 'ri-checkbox-circle-fill' : item.icon}
                label={completed ? 'ri-checkbox-circle-fill' : item.icon}
                className={classNames(
                  'h-8 w-8 text-2xl',
                  completed ? 'text-states-positive-activeLight' : 'text-typography-light'
                )}
              />
            </div>
          ) : (
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 p-1">
              <AccessibleIcon icon={item.icon} label={item.icon} className="h-8 w-8 text-2xl text-typography-light" />
            </div>
          )}
          <div>
            <Text
              size="md"
              weight="medium"
              className={classNames('text-typography-primary', { 'line-through': completed })}
            >
              {item.title}
            </Text>
            <Text
              size="sm"
              weight="regular"
              className={classNames('text-typography-secondary', { 'line-through': completed })}
            >
              {item.description}
            </Text>
          </div>
        </div>
        {!isLastElement && <Separator className="my-2 h-px w-full bg-gray-100" />}
      </div>
    );
  }
);
OnboardingWizardContentItem.displayName = 'OnboardingWizardContentItem';

export const OnboardingWizardCompleted = () => {
  return (
    <div className="flex flex-col gap-2">
      <Text color="secondary" weight="bold" size="lg">
        All done!
      </Text>
      <Text color="secondary" weight="regular" size="md">
        We credited <span className="text-states-positive-active">10.000</span> words into your account balance! 🎁{' '}
        <br></br> You can use them to download your translated documents.
      </Text>
      <Separator className="my-2 h-px w-full bg-gray-100" />
      <Text color="secondary" size="md" weight="semibold">
        How does it work?
      </Text>
      <Text color="secondary" size="sm" weight="regular">
        Your word count shows how many words you have available for translations. Every time you download a new
        translation, the number of words in that translation will be deducted from your balance. You can check your word
        balance in your account settings.
      </Text>
    </div>
  );
};
OnboardingWizardCompleted.displayName = 'OnboardingWizardCompleted';
