import { createContext, FunctionComponent, useEffect } from 'react';

import { Spinner } from '@/components/v1/Spinner';
import { useGetCurrentAccountQuery } from '@/redux/api';
import { Beacon } from '@/utils/helpScout';

import { useSentryContext } from '../SentryProvider';
import type { ICurrentAccountContext, ICurrentAccountProviderProps } from './types';

export const CurrentAccountContext = createContext<ICurrentAccountContext>({} as ICurrentAccountContext);

export const CurrentAccountProvider: FunctionComponent<ICurrentAccountProviderProps> = ({ children }) => {
  const { sentry } = useSentryContext();
  // refetch feature essential for WE
  const { data: currentAccount, isLoading: isCurrentAccountLoading } = useGetCurrentAccountQuery(undefined, {
    refetchOnReconnect: true
  });

  useEffect(() => {
    if (currentAccount) {
      // @ts-expect-error: TS 5 migration error
      const { id, fullName, email, company, helpScoutSignature } = currentAccount;

      sentry.setUser({ id: id.toString(), username: fullName, email });

      Beacon('identify', {
        name: fullName,
        email,
        company,
        signature: helpScoutSignature,
        'backoffice-url': `https://redokun.com/admin/accounts/${id}`
      });
    }
  }, [sentry, currentAccount]);

  if (isCurrentAccountLoading || !currentAccount) {
    return <Spinner.Fullscreen size="lg" />;
  }

  return <>{children}</>;
};
