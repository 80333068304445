import { CSSProperties, isValidElement, ReactNode } from 'react';

import { Dropdown } from '@/components/v1/Dropdown';
import { Show } from '@/components/v1/Show';
import { EllipsisVIcon } from '@/icons/solid';
import { classNames } from '@/utils/classNames';

import { ActivityItemComment } from './ActivityItemComment';
import { ActivityItemCommentMenu } from './ActivityItemCommentMenu';
import { ActivityItemEvent } from './ActivityItemEvent';
import { ActivityItemEventMenu } from './ActivityItemEventMenu';
import { ActivityItemHeader } from './ActivityItemHeader';
import { ActivityItemHiddenEvents } from './ActivityItemHiddenEvents';
import { ActivityItemUser } from './ActivityItemUser';

export interface IActivityItemProps {
  type: 'comment' | 'event';
  user?: ReactNode;
  header?: ReactNode;
  menu?: ReactNode;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const ActivityItem = (props: IActivityItemProps) => {
  const { menu, children, header, type, user, className, style } = props;

  return (
    <li
      className={classNames(
        'flex items-center gap-1 p-4 transition',
        {
          'bg-white hover:bg-gray-100': type === 'comment',
          'bg-gray-100 hover:bg-gray-200': type === 'event'
        },
        className
      )}
      style={style}
    >
      <div className="flex-1 space-y-2">
        <Show when={user}>{isValidElement(user) ? user : <div>{user}</div>}</Show>

        <div className="space-y-1">
          <Show when={header}>{isValidElement(header) ? header : <div>{header}</div>}</Show>

          <Show when={children}>{isValidElement(children) ? children : <div>{children}</div>}</Show>
        </div>
      </div>

      <Show when={menu} fallback={<div className="pointer-events-none invisible h-6 w-6" aria-hidden />}>
        <Dropdown placement="bottom-end" content={menu}>
          <Dropdown.Button className="inline-flex h-6 w-6 items-center justify-center rounded bg-transparent text-blue-500 transition hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600 active:bg-blue-200 active:text-blue-700 disabled:cursor-not-allowed disabled:bg-transparent disabled:text-blue-500 disabled:opacity-50">
            <EllipsisVIcon className="h-4 w-4" />
          </Dropdown.Button>
        </Dropdown>
      </Show>
    </li>
  );
};
ActivityItem.displayName = 'ActivityItem';

ActivityItem.Comment = ActivityItemComment;
ActivityItem.CommentMenu = ActivityItemCommentMenu;
ActivityItem.Event = ActivityItemEvent;
ActivityItem.EventMenu = ActivityItemEventMenu;
ActivityItem.Header = ActivityItemHeader;
ActivityItem.HiddenEvents = ActivityItemHiddenEvents;
ActivityItem.User = ActivityItemUser;
