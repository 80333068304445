import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { Button } from '@/components/v2/Button';
import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { Select, SelectItem } from '@/components/v2/Select';
import { useToast } from '@/components/v2/Toast';
import { ManagerDtoStatus } from '@/redux/api/constants';
import { useLazyGetTeamDetailsQuery, usePatchTeamMutation } from '@/redux/api/teamApi';
import { CurrentAccountDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';

export const EditTeamModal = ({ currentAccount, children, ...modalProps }: EditTeamModalProps) => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const [patchTeamTrigger, { isLoading }] = usePatchTeamMutation();

  const [getUserTeam, { data: userTeam, isLoading: userTeamIsLoading, isUninitialized: userTeamIsUninitialized }] =
    useLazyGetTeamDetailsQuery();

  useEffect(() => {
    // If the user isn't in a team using getUserTeam triggers and error
    // so I need to check if the user is in a team.
    if (currentAccount.subscriptionInfo.isInATeam) {
      getUserTeam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const formSchema = z.object({
    name: z.string().min(1),
    leaderId: z.coerce.number()
  });

  const onSubmit = async ({ name, leaderId }: z.infer<typeof formSchema>) => {
    const [err] = await to(
      patchTeamTrigger({
        name,
        leaderId
      }).unwrap()
    );
    if (!err) {
      if (modalProps.onOpenChange) {
        modalProps.onOpenChange(false);
      }
      toast({ title: tSettings('editTeamSuccessfullyToastTitle'), kind: 'success' });
    }
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: currentAccount.subscriptionInfo.teamName,
      leaderId: currentAccount.subscriptionInfo.teamOwnerAccountId
    }
  });

  if (userTeamIsLoading || userTeamIsUninitialized) {
    return (
      <div className="flex items-center justify-center py-6">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="md">
        <Modal.Header>
          <Modal.Title size="lg">{tSettings('editTeam')}</Modal.Title>
        </Modal.Header>

        <div>
          <form id="editTeamForm" onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <Controller
              name="name"
              control={form.control}
              render={({ field }) => {
                return (
                  <div className="space-y-2">
                    <Label text={tSettings('teamName')} />
                    <Input
                      type="text"
                      name="name"
                      onChange={field.onChange}
                      value={field.value}
                      placeholder={tSettings('typeDepartmentOrCompanyName')}
                    />
                  </div>
                );
              }}
            />

            <Controller
              name={'leaderId'}
              control={form.control}
              render={({ field }) => (
                <div className="space-y-2">
                  <Label text={tSettings('teamLeader')} />
                  <Select
                    onChange={field.onChange}
                    value={field.value.toString()}
                    placeholder={tSettings('selectMember')}
                    bordered
                  >
                    {userTeam?.members
                      .filter((member) => {
                        return member.status === ManagerDtoStatus.ACCEPTED;
                      })
                      .map((item) => (
                        <SelectItem value={item.id.toString()} key={item.id.toString()}>
                          {item.name}
                        </SelectItem>
                      ))}
                  </Select>
                </div>
              )}
            />
          </form>
        </div>

        <Modal.Footer orientation={'vertical'}>
          <Button color="primary" form="editTeamForm" disabled={isLoading}>
            {tSettings('saveChanges')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tSettings('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface EditTeamModalProps extends ModalProps {
  currentAccount: CurrentAccountDto;
}
