import { nanoid } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { forEach, isArray, isNumber, throttle } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { APP_URL } from '@/config/urls';
import { to } from '@/utils/awaitToJs';

import { FIVE_MINUTES, redokunApi } from './redokunApi';
import { EntityType, FileUploadBodyArg, UploadedStashedFileResponseDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

interface UploadFileProgress {
  lengthComputable: boolean;
  loaded: number;
  progress: number;
  total: number;
}

type UploadFileError =
  | { status: 'ABORT_ERROR' | 'FETCH_ERROR' | 'TIMEOUT_ERROR' | 'PARSING_ERROR' }
  | { status: number; data: unknown };

interface UploadFileMetadata {
  folderId?: number;
}

interface UploadFileConfig {
  body: {
    entityType: EntityType;
    files: File | File[];
    metadata?: UploadFileMetadata;
  };
  progressThrottle?: number;
  timeout?: number;
  onProgress?: (event: UploadFileProgress) => void;
  onReject?: (error: UploadFileError) => void;
  onResolve?: (data: UploadedStashedFileResponseDto) => void;
}

const uploadFile = (config: UploadFileConfig) => {
  const {
    body: { entityType, files, metadata },
    timeout,
    progressThrottle = 333,
    onProgress,
    onReject,
    onResolve
  } = config;

  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  if (timeout) {
    xhr.timeout = timeout;
  }
  if (onProgress && xhr.upload) {
    const handleProgress = throttle((e: ProgressEvent<XMLHttpRequestEventTarget>) => {
      const loaded = e.loaded;
      const total = e.total;
      const progress = loaded / (total || 1);
      const lengthComputable = e.lengthComputable;

      const event: UploadFileProgress = {
        loaded,
        total,
        progress,
        lengthComputable
      };

      onProgress(event);
    }, progressThrottle);

    xhr.upload.addEventListener('loadstart', handleProgress);
    xhr.upload.addEventListener('progress', handleProgress);
    xhr.upload.addEventListener('loadend', (e) => {
      handleProgress(e);
      handleProgress.flush();
    });
  }

  const promise = new Promise<UploadedStashedFileResponseDto>((resolve, reject) => {
    const handleResolve = (data: UploadedStashedFileResponseDto) => {
      resolve(data);
      onResolve?.(data);
    };

    const handleReject = (error: UploadFileError) => {
      reject(error);
      onReject?.(error);
    };

    xhr.addEventListener('abort', () => {
      handleReject({ status: 'ABORT_ERROR' });
    });
    xhr.addEventListener('error', () => {
      handleReject({ status: 'FETCH_ERROR' });
    });
    xhr.addEventListener('timeout', () => {
      handleReject({ status: 'TIMEOUT_ERROR' });
    });
    xhr.addEventListener('load', () => {
      try {
        const data = JSON.parse(xhr.responseText);

        if (xhr.status >= 200 && xhr.status < 300) {
          handleResolve(data);
        } else {
          handleReject({ status: xhr.status, data });
        }
      } catch {
        handleReject({ status: 'PARSING_ERROR' });
      }
    });

    const body = new FormData();
    body.set('entityType', entityType);
    if (isNumber(metadata?.folderId)) {
      body.set('metadata[folderId]', `${metadata.folderId}`);
    }
    if (isArray(files)) {
      forEach(files, (file) => {
        body.append(`files[]`, file);
      });
    } else {
      body.set(`files[]`, files);
    }

    xhr.open('POST', `${APP_URL}/api/v1/file/upload`, true);
    xhr.send(body);
  });

  return promise;
};

interface UploadFileState {
  data?: UploadedStashedFileResponseDto;
  error?: UploadFileError;
  originalArgs?: UploadFileConfig['body'];
  progress?: UploadFileProgress;
  requestId?: string;
  status: QueryStatus;
}
const useUploadFile = (config: Pick<UploadFileConfig, 'progressThrottle' | 'timeout'> = {}) => {
  const { timeout, progressThrottle } = config;

  const [state, setState] = useState<UploadFileState>({ status: QueryStatus.uninitialized });

  const trigger = useCallback(
    async (body: UploadFileConfig['body']) => {
      const requestId = nanoid();
      setState((state) => ({ ...state, originalArgs: body, requestId, status: QueryStatus.pending }));

      const updateState = (nextState: Partial<UploadFileState>) => {
        setState((prevState) => (prevState.requestId === requestId ? { ...prevState, ...nextState } : prevState));
      };

      const [error, data] = await to<UploadedStashedFileResponseDto, UploadFileError>(
        uploadFile({
          body,
          progressThrottle,
          timeout,
          onProgress: (progress) => {
            updateState({ progress });
          }
        })
      );

      if (error) {
        updateState({ status: QueryStatus.rejected, error });
        return { error };
      }

      updateState({ status: QueryStatus.fulfilled, data });
      return { data };
    },
    [progressThrottle, timeout]
  );

  const result = useMemo(
    () => ({
      ...state,
      isUninitialized: state.status === QueryStatus.uninitialized,
      isLoading: state.status === QueryStatus.pending,
      isError: state.status === QueryStatus.rejected,
      isSuccess: state.status === QueryStatus.fulfilled
    }),
    [state]
  );

  return [trigger, result] as const;
};

export const fileApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    fileUpload: builder.mutation<UploadedStashedFileResponseDto, FileUploadBodyArg>({
      query: ({ files, entityType }) => {
        const bodyFormData = new FormData();

        files.forEach((file) => {
          bodyFormData.append(`files[]`, file);
          bodyFormData.append('entityType', entityType);
        });

        return {
          url: 'file/upload',
          method: 'POST',
          // timout for large files
          // https://github.com/redokun/redokun-frontend/issues/1054
          timeout: FIVE_MINUTES,
          body: bodyFormData
        };
      }
    })
  })
});

export const { useFileUploadMutation } = fileApi;

export const fileApiUtil = {
  ...fileApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(fileApi.util.updateQueryData)
};

export { uploadFile, useUploadFile };
export type { UploadFileConfig, UploadFileError, UploadFileMetadata, UploadFileProgress };
