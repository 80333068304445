import { trim as _trim, trimEnd as _trimEnd, trimStart as _trimStart } from 'lodash';
import { Editor, Element, Node, Text } from 'slate';

import { EElementType } from '../constants';

/**
 * Get x-slate-fragment attribute from data-slate-fragment
 *
 * https://github.com/ianstormtaylor/slate/blob/d06706c9e15bbbdd7cdd9a1bbb38c87d37c85ea1/packages/slate-react/src/utils/dom.ts#L238
 */
const catchSlateFragment = /data-slate-fragment="(.+?)"/m;
const getSlateFragmentAttribute = (dataTransfer: DataTransfer): string | void => {
  const htmlData = dataTransfer.getData('text/html');
  const [, fragment] = htmlData.match(catchSlateFragment) || [];
  return fragment;
};

const trimFragment = (fragment: Node[]) =>
  fragment.map((node) => {
    if (!Element.isElement(node) || node.type !== EElementType.PARAGRAPH) {
      return node;
    }

    const children = node.children.map((child, childIndex, children) => {
      if (!Text.isText(child)) {
        return child;
      }

      const isFirstChild = childIndex <= 0;
      const isLastChild = childIndex >= children.length - 1;

      if (isFirstChild && isLastChild) {
        return { ...child, text: _trim(child.text) };
      }

      if (isFirstChild) {
        return { ...child, text: _trimStart(child.text) };
      }

      if (isLastChild) {
        return { ...child, text: _trimEnd(child.text) };
      }

      return child;
    });

    return { ...node, children };
  });

export const withTrim = (editor: Editor) => {
  const { insertFragmentData, insertTextData } = editor;

  editor.insertFragmentData = (data) => {
    const fragment = data.getData('application/x-slate-fragment') || getSlateFragmentAttribute(data);

    if (fragment) {
      const decodedFragment = decodeURIComponent(window.atob(fragment));
      const parsedFragment = JSON.parse(decodedFragment) as Node[];
      const trimmedFragment = trimFragment(parsedFragment);
      editor.insertFragment(trimmedFragment);
      return true;
    }

    return insertFragmentData(data);
  };

  editor.insertTextData = (data) => {
    const text = data.getData('text/plain');

    if (text) {
      const trimmedText = _trim(text);
      editor.insertText(trimmedText);
      return true;
    }

    return insertTextData(data);
  };

  return editor;
};
