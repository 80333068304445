import { FlagIcon } from '@/components/v2/FlagIcon';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import type { SourceDocumentDto } from '@/redux/api/types';

export const SourceDocumentHeader = (props: SourceDocumentHeaderProps) => {
  const { sourceDocument } = props;
  const { language } = sourceDocument;

  return (
    <div className="sticky top-0 z-10 border-b border-gray-100 bg-white">
      <div className="mx-auto flex h-16 max-w-8xl items-center px-7 ">
        <div className="flex items-center justify-between gap-4 py-2">
          <div className="flex space-x-2">
            <AccessibleIcon
              className="cursor-help text-xl text-gray-600"
              label="ri-file-3-fill"
              icon="ri-file-3-fill"
            />
            <Title>{sourceDocument.filename}</Title>
          </div>

          <div className="flex flex-1 items-center gap-2">
            <FlagIcon label={language.name} code={language.flagCode || 'xx'} />
            <Text>{language.name}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface SourceDocumentHeaderProps {
  sourceDocument: SourceDocumentDto;
}
