import { cva } from 'cva';

const button = cva(
  'outline-none focus:outline-none focus-visible:outline-none inline-flex flex-nowrap items-center justify-center whitespace-nowrap font-semibold transition-all disabled:cursor-not-allowed disabled:text-typography-disabled',
  {
    variants: {
      color: {
        primary: '',
        secondary: '',
        negative: '',
        positive: '',
        'inverse-primary': ''
      },
      size: {
        sm: 'gap-2 text-sm leading-[1.375rem]',
        md: 'gap-3 text-base leading-6',
        lg: 'gap-4 text-xl leading-8'
      },
      variant: {
        soft: '',
        solid: '',
        surface: '',
        text: ''
      }
    },
    compoundVariants: [
      {
        size: 'sm',
        variant: ['soft', 'solid', 'surface'],
        className: 'h-9 px-3'
      },
      {
        size: 'md',
        variant: ['soft', 'solid', 'surface'],
        className: 'h-11 px-4'
      },
      {
        size: 'lg',
        variant: ['soft', 'solid', 'surface'],
        className: 'h-14 px-6'
      },
      {
        size: 'sm',
        variant: ['text'],
        className: 'h-6 min-w-[1.5rem]'
      },
      {
        size: 'md',
        variant: ['text'],
        className: 'h-7 min-w-[1.75rem]'
      },
      {
        size: 'lg',
        variant: ['text'],
        className: 'h-8 min-w-[2rem]'
      },
      {
        variant: ['soft', 'solid', 'surface'],
        className:
          'rounded-xl border border-transparent ring-0 ring-primary-selected focus:ring-2 focus-visible:ring-2 disabled:border-transparent disabled:bg-states-disabled'
      },
      {
        color: 'primary',
        variant: 'solid',
        className:
          'bg-primary-active text-typography-inverse-primary hover:bg-primary-hoverHard focus:bg-primary-hoverHard active:bg-primary-selected'
      },
      {
        color: 'primary',
        variant: 'soft',
        className:
          'bg-surface-accent text-primary-active hover:border-primary-hoverHard hover:text-primary-hoverHard focus:border-primary-hoverHard focus:text-primary-hoverHard active:border-transparent active:text-primary-selected'
      },
      {
        color: 'primary',
        variant: 'surface',
        className:
          'border-primary-active bg-surface-elements text-primary-active hover:border-primary-hoverHard hover:text-primary-hoverHard focus:border-primary-hoverHard focus:text-primary-hoverHard active:border-primary-selected active:text-primary-selected'
      },
      {
        color: 'primary',
        variant: 'text',
        className:
          'text-primary-active hover:text-primary-hoverHard focus:text-primary-hoverHard active:text-primary-selected'
      },
      {
        color: 'inverse-primary',
        variant: 'text',
        className:
          'hover:typography-inverse-primary focus:typography-inverse-primary text-typography-inverse-primary active:text-primary-selected'
      },
      {
        color: 'secondary',
        variant: 'solid',
        className:
          'border-gray-200 bg-white text-typography-primary hover:bg-gray-200 focus:bg-gray-200 active:bg-gray-50'
      },
      {
        color: 'secondary',
        variant: 'soft',
        className:
          'bg-gray-50 text-typography-secondary hover:border-typography-primary hover:text-typography-primary focus:border-typography-primary focus:text-typography-primary active:border-transparent active:text-typography-tertiary'
      },
      {
        color: 'secondary',
        variant: 'surface',
        className:
          'border-gray-200 bg-surface-elements text-typography-primary hover:border-typography-primary hover:text-typography-primary focus:border-typography-primary focus:text-typography-primary active:border-typography-tertiary active:text-typography-tertiary'
      },
      {
        color: 'secondary',
        variant: 'text',
        className:
          'text-typography-secondary hover:text-typography-primary focus:text-typography-primary active:text-typography-tertiary'
      },
      {
        color: 'negative',
        variant: 'solid',
        className:
          'bg-states-negative-active text-typography-inverse-primary hover:bg-states-negative-hover focus:bg-states-negative-hover active:bg-states-negative-selected'
      },
      {
        color: 'negative',
        variant: 'soft',
        className:
          'bg-surface-error text-states-negative-active hover:border-states-negative-hover hover:text-states-negative-hover focus:border-states-negative-hover focus:text-states-negative-hover active:border-transparent active:text-states-negative-selected'
      },
      {
        color: 'negative',
        variant: 'surface',
        className:
          'border-states-negative-active bg-surface-elements text-states-negative-active hover:border-states-negative-hover hover:text-states-negative-hover focus:border-states-negative-hover focus:text-states-negative-hover active:border-states-negative-selected active:text-states-negative-selected'
      },
      {
        color: 'negative',
        variant: 'text',
        className:
          'text-states-negative-active hover:text-states-negative-hover focus:text-states-negative-hover active:text-states-negative-selected'
      },
      {
        color: 'positive',
        variant: 'solid',
        className:
          'bg-states-positive-active text-typography-inverse-primary hover:bg-states-positive-hover focus:bg-states-positive-hover active:bg-states-positive-selected'
      },
      {
        color: 'positive',
        variant: 'soft',
        className:
          'bg-surface-success text-states-positive-active hover:border-states-positive-hover hover:text-states-positive-hover focus:border-states-positive-hover focus:text-states-positive-hover active:border-transparent active:text-states-positive-selected'
      },
      {
        color: 'positive',
        variant: 'surface',
        className:
          'border-states-positive-active bg-surface-elements text-states-positive-active hover:border-states-positive-hover hover:text-states-positive-hover focus:border-states-positive-hover focus:text-states-positive-hover active:border-states-positive-selected active:text-states-positive-selected'
      },
      {
        color: 'positive',
        variant: 'text',
        className:
          'text-states-positive-active hover:text-states-positive-hover focus:text-states-positive-hover active:text-states-positive-selected'
      }
    ],
    defaultVariants: {
      variant: 'solid',
      color: 'primary',
      size: 'md'
    }
  }
);

export { button };
