import { ChildNode } from 'domhandler';
import { isTag, isText } from 'domutils';
import { parseDocument } from 'htmlparser2';
import { reduce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { BaseSelection, createEditor, Descendant, Text } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import type { TAppEditor } from '@/types/slate';
import { emptyText } from '@/slate/utils/emptyText';
import { editorIsValueChanged } from '@/slate/utils';
import { withPlaceholdersV2, withSingleLine, withTrim } from '@/slate/plugins';
import { EElementType } from '@/slate/constants';
import type { IMarkerPlaceholder, ITagPairPlaceholder, ITagPlaceholder, TPlaceholder } from '@/redux/api/types';
import { EPlaceholderType } from '@/redux/api/constants';

import { getAvailablePlaceholders } from './utils';

export interface IUseTranslationEditorProps {
  initialTranslationContent: string;
  placeholders: TPlaceholder[];
}

export const useTranslationEditor = (props: IUseTranslationEditorProps) => {
  const { initialTranslationContent, placeholders } = props;

  const [editor] = useState(() =>
    [withReact, withHistory, withSingleLine, withTrim, withPlaceholdersV2].reduce(
      (editor, plugin) => plugin(editor),
      createEditor()
    )
  );

  const [initialValue] = useState(() => xliffToValue(initialTranslationContent));

  const [selection, setSelection] = useState<BaseSelection>();
  const [editorState, setEditorState] = useState(() => updateEditorState(editor, placeholders));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (_value: Descendant[]) => {
    const valueChanged = editorIsValueChanged(editor);
    if (valueChanged) {
      const newEditorState = updateEditorState(editor, placeholders);
      setEditorState(newEditorState);
    }

    setSelection(editor.selection);
  };

  const initialRenderRef = useRef(true);
  useEffect(() => {
    const initialRender = initialRenderRef.current;
    if (initialRender) {
      const newEditorState = updateEditorState(editor, placeholders);
      setEditorState(newEditorState);
    }
    initialRenderRef.current = false;
  }, [editor, placeholders]);

  return { editor, initialValue, onChange, selection, ...editorState };
};

const updateEditorState = (editor: TAppEditor, placeholders: TPlaceholder[]) => ({
  availablePlaceholders: getAvailablePlaceholders(editor, placeholders)
});

const xliffToValue = (xliff: string): Descendant[] => {
  const document = parseDocument(xliff, { xmlMode: true });
  const children = mapChildNodesToDescendants(document.children);
  return [{ type: EElementType.PARAGRAPH, children }];
};

const mapChildNodesToDescendants = (childNodes: ChildNode[]): Descendant[] => {
  const descendants = reduce<ChildNode, Descendant[]>(
    childNodes,
    (elements, childNode, index, childNodes) => {
      const lastElement = elements[elements.length - 1];
      const isLastChildNode = index >= childNodes.length - 1;

      if (isText(childNode)) {
        return [...elements, { text: childNode.data }];
      }

      if (isTag(childNode)) {
        const { name, attribs } = childNode;

        if (name === 'x') {
          const { id, ctype, 'equiv-text': equivText, 'x-desc': desc } = attribs;
          const placeholder: ITagPlaceholder | IMarkerPlaceholder = {
            id,
            type: ctype === 'x-marker' ? EPlaceholderType.MARKER : EPlaceholderType.TAG,
            code: equivText,
            label: desc
          };

          const newElements: Descendant[] = [];
          if (!Text.isText(lastElement)) {
            newElements.push(emptyText());
          }
          newElements.push({
            type: EElementType.PLACEHOLDER_V2,
            placeholder,
            tag: 'self-closing',
            children: [emptyText()]
          });
          if (isLastChildNode) {
            newElements.push(emptyText());
          }

          return [...elements, ...newElements];
        }

        if (name === 'g') {
          const { id, 'equiv-text': equivText, 'x-desc': desc } = attribs;
          const placeholder: ITagPairPlaceholder = {
            id,
            type: EPlaceholderType.TAG_PAIR,
            code: equivText,
            label: desc
          };

          const newElements: Descendant[] = [];
          if (!Text.isText(lastElement)) {
            newElements.push(emptyText());
          }
          newElements.push({
            type: EElementType.PLACEHOLDER_V2,
            placeholder,
            tag: 'opening',
            children: [emptyText()]
          });
          newElements.push(...mapChildNodesToDescendants(childNode.children));
          newElements.push({
            type: EElementType.PLACEHOLDER_V2,
            placeholder,
            tag: 'closing',
            children: [emptyText()]
          });
          if (isLastChildNode) {
            newElements.push(emptyText());
          }

          return [...elements, ...newElements];
        }

        return elements;
      }

      return elements;
    },
    []
  );

  if (!descendants.length) {
    descendants.push(emptyText());
  }

  return descendants;
};
