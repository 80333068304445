import type { RenderLeafProps } from 'slate-react';
import { DefaultLeaf } from 'slate-react';

import { StyledLeaf } from './StyledLeaf';

export const renderLeaf = (props: RenderLeafProps) => {
  if (props.leaf.attributes) {
    return <StyledLeaf {...props} />;
  }

  return <DefaultLeaf {...props} />;
};
