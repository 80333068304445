import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import { Progress } from '@/components/v1/Progress';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Show } from '@/components/v2/Show';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { TranslationStatus } from '@/redux/api/constants';
import { DocumentListItemDocumentDto, DocumentListItemDto, SourceDocumentDtoStatus } from '@/redux/api/types';
import { isNotEmpty } from '@/utils/isNotEmpty';

import { isFolderItem } from '../../utils';

export const ExpandedTargetLanguageCell = () => {
  const { t: tDocumentList } = useTranslation('documentList');

  const { cell } = DataTable.useCellContext<DocumentListItemDto, DocumentListItemDto>('TargetLanguageCell');
  const { original: data } = cell.row;

  if (isFolderItem(data) || isEmpty(data.item.translations)) {
    return <div />;
  }

  const { status: documentStatus, translations } = data.item;
  // translations are filtered on the backend
  const notHiddenTranslations = translations.filter((t) => !t.isHidden);
  const hiddenTranslations = translations.filter((t) => t.isHidden);

  // start from index 1 since the first translation is rendered from the parent row
  const otherNotHiddenTranslation = notHiddenTranslations.slice(1);

  return (
    <div className="flex w-full min-w-[210px] flex-col gap-6">
      {otherNotHiddenTranslation.map((props: DocumentListItemDocumentDto['translations'][number], index: number) => {
        const {
          id,
          language,
          status,
          completedPercentage,
          allTranslatorsAccepted,
          translators,
          translationProgress: { draftSegments, translatedSegments, untranslatedSegments }
        } = props;
        const confirmed = translatedSegments - draftSegments;
        const total = translatedSegments + untranslatedSegments;
        const successPercent = Math.floor((100 * confirmed) / total) || 0;

        const inviteNotAcceptedTranslatorsEmailList = translators.filter((t) => t.isNotAccepted).map((t) => t.email);
        const translatorsEmailListCount = inviteNotAcceptedTranslatorsEmailList.length;

        const length = hiddenTranslations.length;
        const lastItem = index + 1 === otherNotHiddenTranslation.length && length > 0;

        const otherLanguagesName = hiddenTranslations.flatMap((dt) => dt.language.name);

        return (
          <div key={id} className="flex flex-col gap-2">
            <div className="justify-left flex flex-row items-center gap-2">
              <ButtonLink
                href={`/documents/show/${data.item.id}/translations/${language.id}`}
                size="sm"
                variant="text"
                disabled={documentStatus !== SourceDocumentDtoStatus.INDEXED}
              >
                <FlagIcon label={language.name} code={language.flagCode} size="md" />
                {language.name}
                <AccessibleIcon icon="ri-external-link-line" label={tDocumentList('editorIcon')} />
              </ButtonLink>
              <Show when={lastItem}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <Tag size="md" color="primary" variant="soft">
                      {tDocumentList('others', { count: length })}
                    </Tag>
                  </Tooltip.Trigger>
                  <Show when={isNotEmpty(otherLanguagesName)}>
                    <Tooltip.Content className="max-w-xxs" side="right">
                      <Text className="text-typography-inverse-primary" size="sm" weight="medium">
                        {otherLanguagesName.map((name) => name).join('\n')}
                      </Text>
                      <Tooltip.Arrow />
                    </Tooltip.Content>
                  </Show>
                </Tooltip.Root>
              </Show>

              <Show when={!allTranslatorsAccepted}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <AccessibleIcon
                      label="warning-icon"
                      icon="ri-alert-line"
                      className="text-base text-states-alert-active hover:text-states-alert-hover"
                    />
                  </Tooltip.Trigger>
                  <Show when={isNotEmpty(translators)}>
                    <Tooltip.Content className="max-w-xxs" side="right">
                      <Text className="text-typography-inverse-primary" size="sm" weight="medium">
                        {translatorsEmailListCount === 1
                          ? tDocumentList('oneTranslatorIsYetToAcceptYourInvitation')
                          : tDocumentList('multipleTranslatorIsYetToAcceptYourInvitation', {
                              count: translatorsEmailListCount
                            })}
                      </Text>
                      <Text className="text-typography-inverse-secondary" size="sm" weight="medium">
                        {inviteNotAcceptedTranslatorsEmailList.map((t) => t).join('\n')}
                      </Text>
                      <Tooltip.Arrow />
                    </Tooltip.Content>
                  </Show>
                </Tooltip.Root>
              </Show>
            </div>
            <div className="flex w-full flex-row justify-between">
              <Show
                when={documentStatus === SourceDocumentDtoStatus.INDEXED}
                fallback={
                  <Text size="sm" weight="medium">
                    {tDocumentList('importing')}
                  </Text>
                }
              >
                <StatusTag value={status} />
                <Tooltip.Root>
                  <Tooltip.Trigger className="w-32 cursor-default">
                    <Progress percent={completedPercentage} successPercent={successPercent} className="w-32" />
                  </Tooltip.Trigger>
                  <Tooltip.Content className="max-w-xxs" side="bottom">
                    <Text size="sm" weight="medium" className="text-typography-inverse-secondary">
                      <Trans
                        i18nKey="documentList:confirmed"
                        values={{
                          value: confirmed
                        }}
                        components={{
                          1: <span className="text-typography-inverse-primary"></span>
                        }}
                      />
                    </Text>
                    <Text size="sm" weight="medium" className="text-typography-inverse-secondary">
                      <Trans
                        i18nKey="documentList:draft"
                        values={{
                          value: draftSegments
                        }}
                        components={{
                          1: <span className="text-typography-inverse-primary"></span>
                        }}
                      />
                    </Text>
                    <Tooltip.Arrow />
                  </Tooltip.Content>
                </Tooltip.Root>
              </Show>
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface StatusProps {
  value: TranslationStatus;
}

export const StatusTag = ({ value }: StatusProps) => {
  const { t: tDocument } = useTranslation('document');

  const statuses = {
    // confirmed
    [TranslationStatus.CONFIRMED]: { color: 'positive', label: tDocument('confirmed') },
    // in progress
    [TranslationStatus.CREATED]: { color: 'primary', label: tDocument('inProgress') },
    // downloaded
    [TranslationStatus.GENERATED]: { color: 'neutral', label: tDocument('downloaded') },
    // pending
    [TranslationStatus.GENERATING]: { color: 'warning', label: tDocument('pending') }
  } as const;

  return <Tag color={statuses[value].color}>{statuses[value].label}</Tag>;
};
