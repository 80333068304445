import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { TConfirmAlertProps } from '@/components/v1/Alert/types';

export const FullStopConfirmAlert: FunctionComponent<Pick<TConfirmAlertProps, 'onOk' | 'onCancel' | 'border'>> = (
  props
) => {
  const { t } = useTranslation('documentLanguageAlerts');

  return (
    <Alert.Confirm
      type="warning"
      message={
        <Trans
          i18nKey="documentLanguageAlerts:originalSegmentEndsWithFullStop"
          components={{ 1: <span className="font-bold" /> }}
        />
      }
      okText={t('addMissingFullStop')}
      cancelText={t('ignore')}
      {...props}
    />
  );
};
