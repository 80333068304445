import dayjs from 'dayjs';

export type TDate = string | number | Date | null | undefined;

export const format = (date?: TDate, template?: string) => dayjs(date).format(template);

export const standardDateFormat = (date?: TDate) => format(date, 'DD MMM YYYY');
export const standardDateTimeFormat = (date?: TDate) => format(date, 'DD MMM YYYY HH:mm');

export const diff = (a: TDate, b: TDate, unit?: dayjs.QUnitType | dayjs.OpUnitType, float = false) =>
  dayjs(a).diff(dayjs(b), unit, float);
