import { debounce as _debounce } from 'lodash';
import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
  setDebouncedSearchSource,
  setDebouncedSearchTarget,
  setSearchSource,
  setSearchTarget
} from '@/redux/slices/documentLanguageSlice';
import { selectSearchSource, selectSearchTarget } from '@/redux/slices/documentLanguageSlice/selectors';

import { SEARCH_DELAY } from './constants';
import type { TCreateUseSearchProps } from './types';

const createUseSearch =
  ({ selector, action, debouncedAction }: TCreateUseSearchProps) =>
  () => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(selector) ?? '';

    const onChangeValue = useMemo(() => {
      const debounce = _debounce((value: string) => {
        dispatch(debouncedAction(value));
      }, SEARCH_DELAY);

      return (value: string) => {
        dispatch(action(value));
        debounce(value);
      };
    }, [dispatch]);

    return { value, onChangeValue };
  };

export const useSearchSource = createUseSearch({
  selector: selectSearchSource,
  action: setSearchSource,
  debouncedAction: setDebouncedSearchSource
});

export const useSearchTarget = createUseSearch({
  selector: selectSearchTarget,
  action: setSearchTarget,
  debouncedAction: setDebouncedSearchTarget
});
