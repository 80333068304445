export const to = <T, U = Error>(promise: Promise<T>, errorExt?: object): Promise<[U, undefined] | [null, T]> =>
  promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt);
        return [parsedError, undefined];
      }

      return [err, undefined];
    });
