export enum Colors {
  neutral = 'neutral',
  positive = 'positive',
  primary = 'primary',
  negative = 'negative',
  warning = 'warning'
}

export enum StatusLabels {
  statusInvitedManager = 'statusInvitedManager',
  statusAcceptedManager = 'statusAcceptedManager',
  statusOwnerManager = 'statusOwnerManager',
  statusNoneManager = 'statusNoneManager',
  statusCreated = 'statusCreated',
  statusActivated = 'statusActivated'
}
