export enum OperationsKind {
  NOTIFICATION = 'NOTIFICATION',
  DOWNLOAD_TM = 'DOWNLOAD_TM',
  UPLOAD_TM = 'UPLOAD_TM'
}

export type Operation = { operationId: number; message: string; kind: OperationsKind };

export type OperationsState = {
  queue: Operation[];
};
