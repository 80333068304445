import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { useDeleteDocumentMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';

export const DeleteDocumentModal = (props: DeleteDocumentModalProps) => {
  const { documentId, children, onDelete, ...modalProps } = props;

  const { t } = useTranslation('document');

  const [deleteDocument, { isLoading, isSuccess }] = useDeleteDocumentMutation();

  const handleDeleteClick = async () => {
    const [error] = await to(deleteDocument(documentId).unwrap());
    if (!error) {
      onDelete?.();
    }
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="sm">
        <Modal.Header>
          <Modal.Title size="md">{t('deleteDocumentQuestion')}</Modal.Title>
          <Modal.Description size="md">{t('whenYouDeleteDocumentTranslationMemoriesWillBeDeleted')}</Modal.Description>
        </Modal.Header>

        <Modal.Footer>
          <Button color="negative" onClick={handleDeleteClick} disabled={isLoading || isSuccess}>
            {t('deleteDocument')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {t('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface DeleteDocumentModalProps extends ModalProps {
  documentId: number;
  onDelete?: () => void;
}
