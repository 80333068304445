import { Show } from '@/components/v1/Show';
import { DatabaseIcon, FileIcon } from '@/icons/solid';
import { ESuggestionReferenceType } from '@/redux/api/constants';

import type { ITMSuggestionReferenceProps } from './types';

export const TMSuggestionReference = ({ reference }: ITMSuggestionReferenceProps) => {
  const Icon = Icons[reference.type];

  return (
    <div className="flex space-x-1">
      <div>
        <Show when={Icon}>{(Icon) => <Icon />}</Show>
      </div>

      <div style={{ wordBreak: 'break-word' }}>{reference.name}</div>
    </div>
  );
};

const Icons = {
  [ESuggestionReferenceType.DOCUMENT]: FileIcon,
  [ESuggestionReferenceType.IMPORTED_TM]: DatabaseIcon
} as const;
