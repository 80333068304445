import { cva } from 'cva';

export const textAreaVariants = cva(
  'flex w-full rounded-md border border-gray-200 bg-white text-typography-primary placeholder-gray-200 focus:border-transparent focus:ring-2 focus:ring-primary-selected',
  {
    variants: {
      size: {
        sm: 'px-4 py-1',
        md: 'px-5 py-2',
        lg: 'px-5 py-4'
      }
    }
  }
);
