import { Portal } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';
import { useDebouncedCallback } from 'use-debounce';

import { isNonNullable } from '@/utils/isNonNullable';
import { classNames } from '@/utils/classNames';

import { Show } from '../Show';
import type { ITooltipProps } from './types';

export const Tooltip = ({
  title,
  placement,
  strategy,
  enterDelay = 300,
  leaveDelay = 150,
  containerClassName,
  containerStyle,
  className,
  style,
  children,
  portal = true
}: ITooltipProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }]
  });
  const [show, setShow] = useState(false);
  const PopperWrapper = portal ? Portal : Fragment;

  const debouncedEnter = useDebouncedCallback(() => {
    setShow(true);
  }, enterDelay);

  const debouncedLeave = useDebouncedCallback(() => {
    setShow(false);
  }, leaveDelay);

  const handleEnter = () => {
    debouncedLeave.cancel();
    debouncedEnter();
  };

  const handleLeave = () => {
    debouncedEnter.cancel();
    debouncedLeave();
  };

  return (
    <>
      <div
        ref={setReferenceElement}
        className={containerClassName}
        style={containerStyle}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onFocus={handleEnter}
        onBlur={handleLeave}
      >
        {children}
      </div>

      <Show when={show && isNonNullable(title)}>
        <PopperWrapper>
          <div
            ref={setPopperElement}
            className={classNames(
              'z-10 min-w-max rounded bg-gray-800 px-1.5 py-1 text-sm font-bold leading-none text-white shadow-md',
              className
            )}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            onFocus={handleEnter}
            onBlur={handleLeave}
            style={style ? { ...style, ...styles.popper } : styles.popper}
            {...attributes.popper}
          >
            {title}
          </div>
        </PopperWrapper>
      </Show>
    </>
  );
};
