import { useTranslation } from 'react-i18next';
import { useCombobox, useMultipleSelection } from 'downshift';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, some } from 'lodash';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import Combobox from '@/components/v2/Combobox';
import Tooltip from '@/components/v2/Tooltip';
import { Text } from '@/components/v2/Text';
import { PartialTranslatorDto } from '@/routes/Document/types';
import { DocumentTargetLanguage } from '@/routes/Document/components/AddLanguageTranslator/types';
import { getFilteredTranslators, removeDuplicateTranslators } from '@/utils/translators';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { Checkbox } from '@/components/v2/Checkbox';
import { Avatar, AvatarImage } from '@/components/v2/Avatar';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { validateEmail } from '@/utils/email';
import { useGetTranslatorsQuery } from '@/redux/api';
import { Show } from '@/components/v2/Show';

interface AddTranslatorComboboxProps {
  item: DocumentTargetLanguage;
  displayTooltip: boolean;
  displayAddTranslatorsToAllDocumentsButton: boolean;
  onAddTranslator: (selectedItems: PartialTranslatorDto[]) => void;
  onAddNewTranslator: (email: string) => void;
  onAddTranslatorToAllDocuments?: (selectedItems: PartialTranslatorDto[]) => void;
}

export const AddTranslatorCombobox = (props: AddTranslatorComboboxProps) => {
  const {
    item,
    displayTooltip,
    displayAddTranslatorsToAllDocumentsButton,
    onAddNewTranslator,
    onAddTranslator,
    onAddTranslatorToAllDocuments
  } = props;
  const { t } = useTranslation('document');

  const { currentAccount } = useCurrentAccount();
  const { data } = useGetTranslatorsQuery({ filterByLanguageId: parseInt(item.language.id) });

  const tooltipRef = useRef(null);

  const [translators, setTranslators] = useState<PartialTranslatorDto[]>([]);
  const [selectedItems, setSelectedItems] = useState<PartialTranslatorDto[]>();
  const [inputSearch, setInputSearch] = useState<string | undefined>();
  const [isTooltipOpen, setIsTooltipOpen] = useState(displayTooltip);

  const language = item.language;

  const {
    getDropdownProps,
    removeSelectedItem,
    setSelectedItems: setItems
  } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            setSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    }
  });

  const state = useCombobox({
    items: translators ?? [],
    itemToString(item) {
      return item ? item.fullName : '';
    },
    inputValue: inputSearch,
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputClick:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            // keep the menu open after selection. only if is already opened
            isOpen: state.isOpen
          };
        default:
          return changes;
      }
    },
    onStateChange({ type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          // added state.isOpen check as workaround to prevent side-effects
          // we want to trigger set or remove items only if dropdown is open
          if (!!newSelectedItem && state.isOpen) {
            if (selectedItems && selectedItems.some((s) => s.id === newSelectedItem.id)) {
              const item = selectedItems?.find((item) => item.id === newSelectedItem.id);
              if (item) {
                removeSelectedItem(item);
              }
            } else {
              setSelectedItems([...(selectedItems || []), newSelectedItem]);
            }
          }
          break;
        default:
          break;
      }
    }
  });

  useEffect(() => {
    if (item.translators !== undefined) {
      setItems(item.translators);
    }
  }, [item.translators, translators, setTranslators, setItems]);

  useEffect(() => {
    // (async () => {
    if (language && language.id) {
      if (data && currentAccount) {
        const me: PartialTranslatorDto = {
          id: currentAccount.id,
          avatarUrl: currentAccount.avatarUrl || '',
          email: currentAccount.email,
          fullName: `${currentAccount.fullName} (${t('you')})`
        };
        if (isEmpty(data.translators)) {
          setTranslators([me]);
        } else {
          const unique = removeDuplicateTranslators([...data.translators, me]);
          setTranslators(unique);
        }
      }
    }
    // })();
  }, [currentAccount, data, language, t]);

  const handleAddTranslators = () => {
    if (selectedItems) {
      onAddTranslator(selectedItems);
    }
  };

  const handleAddNewTranslator = (inputSearch: string) => {
    setInputSearch('');
    onAddNewTranslator(inputSearch);
  };

  const handleAddTranslatorToAllDocuments = () => {
    if (selectedItems) {
      onAddTranslatorToAllDocuments?.(selectedItems);
    }
  };

  const renderDefaultItem = (filteredTranslators: PartialTranslatorDto[]) =>
    filteredTranslators.map((translator) => (
      <Combobox.Item
        key={translator.id}
        item={translator}
        index={translators?.indexOf(translator) || 0}
        className="w-auto max-w-max items-center gap-3"
      >
        <Checkbox
          checked={!!selectedItems?.find((i) => i.email === translator.email)}
          onClick={(e) => e.preventDefault()}
        />
        <Avatar size="sm" className="min-h-[40px] min-w-[40px]">
          <AvatarImage src={translator.avatarUrl} />
        </Avatar>
        <div className="flex flex-col gap-0.5 overflow-hidden">
          <OverflowTooltip label={translator.fullName} side="right">
            <Text color="primary" size="sm">
              {translator.fullName}
            </Text>
          </OverflowTooltip>
          <OverflowTooltip label={translator.email} side="right">
            <Text color="tertiary" size="xs">
              {translator.email}
            </Text>
          </OverflowTooltip>
        </div>
      </Combobox.Item>
    ));

  const renderPendingTranslator = () => {
    return (
      <div className="mb-1 flex w-60 flex-col gap-1 text-center">
        <Text size="sm" weight="bold" color="tertiary">
          {t('inviteNewTranslator')}
        </Text>
        <Text size="sm" weight="regular" color="tertiary">
          {t('InviteNewTranslationText')}
        </Text>
      </div>
    );
  };

  const renderSendInviteButton = (email: string) => {
    return (
      <Button
        variant="solid"
        color="primary"
        className="mt-1 w-full"
        size="sm"
        type="button"
        // this is needed to prevent combobox state to trigger input blur event therefore closing the dropdown
        // tldr this is required to keep the menu opened
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => handleAddNewTranslator(email)}
      >
        {t('sendInvite')}
      </Button>
    );
  };

  // compute filtered translators list based on input search criteria, current account email, and available data
  const filteredTranslators: PartialTranslatorDto[] = useMemo(() => {
    // filter translators based on input search or use all translators if no search criteria provided
    const filteredTranslators = inputSearch ? getFilteredTranslators(inputSearch)(translators) : translators;

    // filter the filteredTranslators list to include only translators matching current account email or existing in data.translators
    // the aim is to remove stashed translators
    return filteredTranslators.filter(
      (ft) => ft.email === currentAccount.email || data?.translators.some((t) => t.email === ft.email)
    );
  }, [inputSearch, translators, currentAccount, data]);

  const isInvitingTranslator = useMemo(() => {
    // check if inputSearch is not empty and is a valid email
    const isValidEmail = !!inputSearch && validateEmail(inputSearch);

    // check if inputSearch is not present in the translators array
    const isNotInTranslators = !some(translators, (t) => t.email === inputSearch);

    // return true if it's a valid email and not present in the translators array, otherwise false
    return isValidEmail && isNotInTranslators;
  }, [inputSearch, translators]);

  // close tooltip if user click outside
  const handleClickOutsideTooltip = () => {
    setIsTooltipOpen(false);
  };

  useOnClickOutside(tooltipRef, handleClickOutsideTooltip);

  return (
    <Tooltip.Root open={isTooltipOpen}>
      <Combobox.Root state={state} placement="bottom-start">
        <Combobox.Trigger className="py-2" asChild {...getDropdownProps()}>
          <Tooltip.Trigger className="w-full">
            <Button
              variant="text"
              color="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setIsTooltipOpen(false);
              }}
            >
              <AccessibleIcon label="add-line" icon="ri-add-line" className="text-2xl" />
              {t('addTranslator')}
            </Button>
          </Tooltip.Trigger>
        </Combobox.Trigger>
        <Tooltip.Content ref={tooltipRef} className="max-w-xxs whitespace-normal text-sm" sideOffset={8}>
          <Text className="text-typography-inverse-secondary" size="xs">
            {t('youHaveBeenSetAsTheTranslatorFeelFreeToChange')}
          </Text>
          <Tooltip.Arrow />
        </Tooltip.Content>

        <Combobox.Content className="z-50 w-auto max-w-xs">
          <Combobox.SearchItem
            placeholder={t('typeNameOrEmail')}
            {...state.getInputProps({
              value: inputSearch,
              onChange: (e) => {
                setInputSearch((e.target as HTMLInputElement).value);
              }
            })}
            onClick={(e) => e.preventDefault()}
          />
          <Combobox.ItemsContainer>
            {!inputSearch && (
              <>
                <div className="flex justify-center py-2">
                  <div className="flex w-60 flex-col gap-1 text-center">
                    <Text size="sm" weight="bold" color="tertiary">
                      {t('inviteYourCollaborators')}
                    </Text>
                    <Text size="sm" weight="regular" color="tertiary">
                      {t('youCanInviteNewTranslatorsByTypingTheirEmail')}
                    </Text>
                  </div>
                </div>
                <hr className="my-2 h-px w-full bg-gray-200"></hr>
              </>
            )}
            {isInvitingTranslator && !!inputSearch ? renderPendingTranslator() : renderDefaultItem(filteredTranslators)}
          </Combobox.ItemsContainer>
          {isInvitingTranslator && !!inputSearch ? (
            renderSendInviteButton(inputSearch)
          ) : (
            <>
              <Button
                variant="solid"
                color="primary"
                className="mt-1 w-full"
                size="sm"
                onClick={handleAddTranslators}
                type="button"
                disabled={!selectedItems}
              >
                {t('addTranslators')}
              </Button>
              <Show when={displayAddTranslatorsToAllDocumentsButton}>
                <Button
                  variant="surface"
                  color="secondary"
                  className="mt-1 w-full"
                  size="sm"
                  onClick={handleAddTranslatorToAllDocuments}
                  type="button"
                  disabled={!selectedItems}
                >
                  {t('addToAllDocuments')}
                </Button>
              </Show>
            </>
          )}
        </Combobox.Content>
      </Combobox.Root>
    </Tooltip.Root>
  );
};
