import { useCallback, useEffect, useRef, useState } from 'react';

import { useWindowScrolling } from '@/hooks/useWindowScrolling';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { scrolledToParagraph, scrollToParagraph } from '@/redux/slices/documentLanguageSlice';
import { selectScrollToParagraphId } from '@/redux/slices/documentLanguageSlice/selectors';

import { getParagraphElementId } from '../utils';

export const useScrollToParagraph = () => {
  const windowScrolling = useWindowScrolling();
  const windowScrollingRef = useRef(windowScrolling);

  const [paragraphElement, setParagraphElement] = useState<Element | null>(null);

  const dispatch = useAppDispatch();
  const scrollToParagraphId = useAppSelector(selectScrollToParagraphId);

  useEffect(() => {
    windowScrollingRef.current = windowScrolling;
  }, [windowScrolling, windowScrollingRef]);

  useEffect(() => {
    if (paragraphElement && scrollToParagraphId) {
      const paragraphElementId = getParagraphElementId(scrollToParagraphId);
      if (paragraphElement.id !== paragraphElementId) {
        return;
      }

      windowScrollingRef.current = true;
      paragraphElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [paragraphElement, scrollToParagraphId]);

  useEffect(() => {
    if (paragraphElement && scrollToParagraphId && !windowScrollingRef.current && !windowScrolling) {
      dispatch(scrolledToParagraph(scrollToParagraphId));
    }
  }, [paragraphElement, scrollToParagraphId, windowScrolling, windowScrollingRef, dispatch]);

  const scrollToParagraphCallback = useCallback(
    (paragraphId: number, paginationPage: number, options: { keepFilters?: boolean; openComments?: boolean } = {}) => {
      dispatch(scrollToParagraph({ paragraphId, paginationPage, ...options }));
    },
    [dispatch]
  );

  return { scrollToParagraphId, setParagraphElement, scrollToParagraph: scrollToParagraphCallback };
};
