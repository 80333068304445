import { DevTool } from '@hookform/devtools';
import { find, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { HintBox } from '@/components/v2/HintBox';
import { InlineMessage } from '@/components/v2/InlineMessage';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Surface } from '@/components/v2/Surface';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { DEV } from '@/config/app';
import { useEffectOnceWhen } from '@/hooks/useEffectOnceWhen';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRouterParamsQuery } from '@/hooks/useRouterParamsQuery';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import {
  useAddLanguagesToDocumentsMutation,
  useGetDocumentDetailQuery,
  useGetLanguagesQuery,
  useGetTranslatorsQuery,
  useInviteTranslatorMutation
} from '@/redux/api';
import { AddLanguagesToDocumentsRequestQueryArgs, LanguageSettingDto, TranslatorDto } from '@/redux/api/types';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { clearCreatedDocumentIds } from '@/redux/slices/uploadDocumentsSlice';
import { AddLanguageTranslator } from '@/routes/Document/components/AddLanguageTranslator';
import {
  DocumentLanguageItemType,
  DocumentTargetLanguage
} from '@/routes/Document/components/AddLanguageTranslator/types';
import { LanguageSearchBox } from '@/routes/Document/components/LanguageSearchBox';
import { LanguageGroup, LanguageOption, PartialTranslatorDto } from '@/routes/Document/types';
import { createTargetLanguageGroups, removeDuplicateLanguages } from '@/routes/Document/utils';
import { uploadingDocumentSteps } from '@/routes/UploadDocuments/constants';
import { DocumentLanguageItemForm } from '@/routes/UploadDocuments/steps/SetTargetLanguage/types';
import { EUploadingDocumentSteps } from '@/routes/UploadDocuments/types';
import { useUploadDocument } from '@/routes/UploadDocuments/UploadDocumentProvider';
import { to } from '@/utils/awaitToJs';

export const SetTargetLanguage: React.FC = () => {
  const { t } = useTranslation('uploadDocuments');
  const history = useHistory();
  const query = useRouterParamsQuery();
  const { currentAccount, refetch, isFetching } = useCurrentAccount();
  const dispatch = useAppDispatch();

  const folderId = query.get('folderId');
  const parsedFolderId = useMemo(() => (!isNaN(Number(folderId)) ? Number(folderId) : undefined), [folderId]);

  const isCompleted = currentAccount.onboardingFlags.hasConfirmedAnyDocument;
  const createdDocumentIds = useAppSelector((state) => state.uploadDocuments.createdDocumentIds);

  const { welcome } = useUploadDocument('SetTargetLanguage');
  const { data: translators } = useGetTranslatorsQuery({});
  const [defaultTranslators, storeDefaultTranslators] = useLocalStorage<LanguageSettingDto[] | undefined>(
    'defaultTranslators',
    undefined
  );

  const { data } = useGetLanguagesQuery();
  const [inviteTranslator] = useInviteTranslatorMutation();
  const [addLanguagesToDocuments, { isLoading }] = useAddLanguagesToDocumentsMutation();

  const languageGroups: LanguageGroup[] = data ? createTargetLanguageGroups(data, t) : [];

  const form = useForm<DocumentLanguageItemForm>();

  const { fields } = useFieldArray<DocumentLanguageItemForm, 'documents', 'documentId'>({
    control: form.control,
    name: 'documents'
  });

  useEffectOnceWhen(() => {
    if (!isEmpty(createdDocumentIds)) {
      form.setValue(
        'documents',
        createdDocumentIds.map((id) => ({ documentId: String(id) }))
      );
    }
  }, createdDocumentIds !== undefined);

  const prevStep = () => {
    const step = uploadingDocumentSteps.find((s) => s.id === EUploadingDocumentSteps.SET_SOURCE_LANGUAGE);
    if (step) {
      history.push(step.path);
    }
  };

  const addLanguageToDocument = (items: LanguageOption[], index: number) => {
    const document = form.watch(`documents.${index}`);
    // if targetLanguage is undefined, initialize it as an empty array
    const targetLanguage = document.targetLanguage || [];

    // remove languages from targetLanguage that don't exist in the provided items
    const updatedTargetLanguage = targetLanguage.filter((tl) => items.some((item) => item.id === tl.language.id));

    // identify items in 'items' array that are not in the 'targetLanguage' array
    const newItems = items.filter((item) => !targetLanguage.some((tl) => tl.language.id === item.id));

    // set current account as default translator
    const currentTranslator: PartialTranslatorDto = {
      id: currentAccount.id,
      avatarUrl: currentAccount.avatarUrl || '',
      email: currentAccount.email,
      fullName: `${currentAccount.fullName} (${t('you')})`
    };

    // if there's nothing stored in localstorage, set current account as default
    if (!defaultTranslators || isEmpty(defaultTranslators)) {
      // add new items to the 'updatedTargetLanguage' array
      newItems.forEach((newItem) => {
        updatedTargetLanguage.push({
          language: newItem,
          translators: [currentTranslator]
        });
      });
    } else {
      newItems.forEach((newItem) => {
        // search if there're any saved translators associated with the selected language
        const selectedLanguageTranslator = find(defaultTranslators, (t) => String(t.languageId) === newItem.id);
        if (selectedLanguageTranslator) {
          // for each translator id get translator data
          const translatorsCompleteInfo = selectedLanguageTranslator.accountIds
            .map((accountId) => {
              // accountIds should match translators and also our account
              return find([currentTranslator, ...(translators?.translators ?? [])], {
                id: accountId
              });
            })
            .filter((t): t is TranslatorDto => t !== undefined);

          if (!isEmpty(translatorsCompleteInfo)) {
            // add saved translators to language
            updatedTargetLanguage.push({
              language: newItem,
              translators: [...translatorsCompleteInfo]
            });
          } else {
            updatedTargetLanguage.push({
              language: newItem,
              translators: [currentTranslator]
            });
          }
        } else {
          updatedTargetLanguage.push({
            language: newItem,
            translators: [currentTranslator]
          });
        }
      });
    }
    form.setValue(`documents.${index}.targetLanguage`, updatedTargetLanguage);
  };

  const addLanguageToAllDocuments = (items: LanguageOption[]) => {
    const documents = form.watch(`documents`);
    if (!isEmpty(documents)) {
      const updatedDocuments = documents.map((document: DocumentLanguageItemType) => {
        // if the document doesn't have targetLanguage set, initialize it as an empty array
        const targetLanguage = document.targetLanguage || [];

        items.forEach((item: LanguageOption) => {
          // check if the language already exists in the targetLanguage array of the document
          const languageExists = targetLanguage.some((tl) => tl.language.id === item.id);

          // if the language doesn't exist, add it to the targetLanguage array with default translator
          if (!languageExists) {
            const currentTranslator = {
              id: currentAccount.id,
              avatarUrl: currentAccount.avatarUrl || '',
              email: currentAccount.email,
              fullName: `${currentAccount.fullName} (${t('you')})`
            };
            // if there's nothing stored in localstorage, set current account as default
            if (!defaultTranslators || isEmpty(defaultTranslators)) {
              targetLanguage.push({
                language: item,
                translators: [currentTranslator]
              });
            } else {
              // search if there're any saved translators associated with the current language
              const selectedLanguageTranslator = find(defaultTranslators, (t) => String(t.languageId) === item.id);
              if (selectedLanguageTranslator) {
                // for each translator id get translator data
                const translatorsCompleteInfo = selectedLanguageTranslator.accountIds
                  .map((accountId) => {
                    return find(translators?.translators, { id: accountId });
                  })
                  .filter((t): t is TranslatorDto => t !== undefined);

                if (!isEmpty(translatorsCompleteInfo)) {
                  // add saved translators to language
                  targetLanguage.push({
                    language: item,
                    translators: [...translatorsCompleteInfo]
                  });
                } else {
                  targetLanguage.push({
                    language: item,
                    translators: [currentTranslator]
                  });
                }
              } else {
                targetLanguage.push({
                  language: item,
                  translators: [currentTranslator]
                });
              }
            }
          }
        });

        return {
          ...document,
          targetLanguage: targetLanguage
        };
      });

      form.setValue('documents', updatedDocuments);
    }
  };

  const addTranslatorsToAllDocuments = (translators: PartialTranslatorDto[], languageId: string) => {
    const documents = form.watch(`documents`);
    if (!isEmpty(documents)) {
      const updatedDocuments = documents.map((document: DocumentLanguageItemType) => {
        // If no target languages exist for the document, initialize it as an empty array
        const targetLanguages = document.targetLanguage || [];

        const updatedTargetLanguages = targetLanguages.map((targetLanguage) => {
          // Add the translators to the target language if it matches the provided language id
          if (targetLanguage.language.id !== languageId) {
            return targetLanguage;
          }

          // Ensure the provided translators are added to each language if they don't already exist
          const updatedTranslators = [...targetLanguage.translators];

          translators.forEach((newTranslator) => {
            const exists = updatedTranslators.some((existingTranslator) => existingTranslator.id === newTranslator.id);
            if (!exists) {
              updatedTranslators.push(newTranslator);
            }
          });

          return {
            ...targetLanguage,
            translators: updatedTranslators
          };
        });

        return {
          ...document,
          targetLanguage: updatedTargetLanguages
        };
      });

      form.setValue('documents', updatedDocuments);
    }
  };

  const handleRemoveLanguage = (languageId: string, index: number) => {
    const languages = form.watch(`documents.${index}.targetLanguage`) ?? [];
    const target = languages.filter((l) => l.language.id !== languageId);

    form.setValue(`documents.${index}.targetLanguage`, target);
  };

  const handleAddTranslators = (translators: PartialTranslatorDto[], languageId: string, index: number) => {
    const languages = form.watch(`documents.${index}.targetLanguage`) ?? [];

    const target = languages.map((t) => {
      if (t.language.id === languageId) {
        return {
          ...t,
          translators: translators
        };
      }
      return t;
    });
    form.setValue(`documents.${index}.targetLanguage`, target);
  };

  const handleAddNewTranslator = async (email: string, languageId: string, index: number) => {
    const [, data] = await to(inviteTranslator({ email, languageId: parseInt(languageId) }).unwrap());

    const languages = form.watch(`documents.${index}.targetLanguage`) ?? [];

    if (data?.translatorAccountId) {
      const pendingTranslator: PartialTranslatorDto = {
        id: data.translatorAccountId,
        email,
        fullName: '',
        avatarUrl: ''
      };
      const target = languages.map((t) => {
        if (t.language.id === languageId) {
          return {
            ...t,
            translators: [...t.translators, pendingTranslator]
          };
        }
        return t;
      });
      form.setValue(`documents.${index}.targetLanguage`, target);
    }
  };

  const onSubmit: SubmitHandler<DocumentLanguageItemForm> = async (data, e) => {
    e?.preventDefault();

    const updateDefaultTranslators: LanguageSettingDto[] = defaultTranslators ?? [];

    for (const document of data.documents) {
      if (document.targetLanguage) {
        const languages: LanguageSettingDto[] = document.targetLanguage.map((tl) => ({
          languageId: parseInt(tl.language.id),
          accountIds: tl.translators.map((t) => t.id)
        }));

        updateDefaultTranslators.push(...languages);

        const body: AddLanguagesToDocumentsRequestQueryArgs = {
          documentIds: [parseInt(document.documentId)],
          languages
        };
        await addLanguagesToDocuments(body);
      }
    }

    // remove duplicate translators and save them in localstorage
    storeDefaultTranslators(removeDuplicateLanguages(updateDefaultTranslators));

    //if the user is uploading one file redirect to document detail
    if (data.documents.length === 1 && data.documents[0].documentId) {
      if (!isCompleted) {
        // refetching current account to update onboarding flags
        refetch();
      }
      // clear ids from state
      dispatch(clearCreatedDocumentIds());
      history.push(`/documents/show/${data.documents[0].documentId}`);
    } else {
      dispatch(clearCreatedDocumentIds());
      const path = parsedFolderId ? `/documents/folder/${parsedFolderId}` : '/documents';
      history.push(path);
    }
  };

  const documents = form.watch('documents');

  const disabled =
    (Array.isArray(documents) &&
      documents.some(
        (field) =>
          field.targetLanguage === undefined ||
          field.targetLanguage.length === 0 ||
          (Array.isArray(field.targetLanguage) && field.targetLanguage.some((tl) => tl.translators.length === 0))
      )) ||
    // disable submit button when api call is loading
    isLoading ||
    isFetching;

  const showHint = !isCompleted && Boolean(welcome) && !disabled;
  const showTooltip = !isCompleted && Boolean(welcome) && disabled;

  return (
    <div className="flex w-full flex-col items-center gap-8 pb-8">
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full flex-col items-center gap-8 pb-8">
        {query.get('source') === 'sample' && (
          <InlineMessage type="informational" orientation="horizontal" rootClassName="p-0 w-full">
            <Text size="sm" weight="semibold" className="text-blue-700">
              {t('sinceYouUsedSampleFileWeAutomaticallySelectedTheRightLanguage')}
            </Text>
          </InlineMessage>
        )}
        <div className="flex w-full flex-col gap-3">
          {fields.map((item, index) => (
            <Controller
              key={item.documentId}
              name={`documents.${index}.documentId`}
              control={form.control}
              render={() => {
                const targetLanguage = form.watch(`documents.${index}.targetLanguage`) ?? [];
                const selectedLanguages = targetLanguage?.flatMap((ll) => ll.language) ?? [];
                const hasMultipleDocuments = documents.length > 1;

                return (
                  <DocumentLanguageItem
                    item={item}
                    languageGroups={languageGroups}
                    selectedLanguages={selectedLanguages}
                    targetLanguages={targetLanguage}
                    multipleDocuments={hasMultipleDocuments}
                    onAddLanguage={(items) => addLanguageToDocument(items, index)}
                    onAddLanguageToAllDocuments={addLanguageToAllDocuments}
                    onAddTranslators={(translators, languageId) => handleAddTranslators(translators, languageId, index)}
                    onAddNewTranslator={(email, languageId) => handleAddNewTranslator(email, languageId, index)}
                    onRemoveLanguage={(languageId) => handleRemoveLanguage(languageId, index)}
                    onAddTranslatorsToAllDocuments={addTranslatorsToAllDocuments}
                  />
                );
              }}
            />
          ))}
        </div>
        <div className="flex w-full justify-between">
          <Button variant="text" color="primary" size="md" onClick={prevStep} disabled>
            <AccessibleIcon label="arrow-left" icon="ri-arrow-left-line" />
            {t('goBack')}
          </Button>
          <Tooltip.Root open={showTooltip}>
            <Tooltip.Trigger>
              <div>
                <HintBox when={showHint}>
                  <Button
                    variant="solid"
                    color="primary"
                    size="md"
                    className="ml-auto"
                    type="submit"
                    disabled={disabled}
                  >
                    {t('saveAndContinue')}
                    <AccessibleIcon label="arrow-right" icon="ri-arrow-right-line" />
                  </Button>
                </HintBox>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content className="max-w-xxs" sideOffset={12} side="bottom">
              <Text className="text-typography-inverse-primary" size="xs">
                {t('chooseTranslatorForEachLanguageToContinue')}
              </Text>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
        </div>
        {DEV && <DevTool control={form.control} />}
      </form>
    </div>
  );
};

interface DocumentLanguageItemProps {
  item: DocumentLanguageItemType;
  languageGroups: LanguageGroup[];
  selectedLanguages: LanguageOption[];
  targetLanguages: DocumentTargetLanguage[];
  multipleDocuments: boolean;
  onAddLanguage: (items: LanguageOption[]) => void;
  onAddLanguageToAllDocuments: (items: LanguageOption[]) => void;
  onRemoveLanguage: (languageId: string) => void;
  onAddTranslators: (translators: PartialTranslatorDto[], languageId: string) => void;
  onAddNewTranslator: (email: string, languageId: string) => void;
  onAddTranslatorsToAllDocuments: (translators: PartialTranslatorDto[], languageId: string) => void;
}

const DocumentLanguageItem = (props: DocumentLanguageItemProps) => {
  const {
    item,
    languageGroups,
    targetLanguages,
    multipleDocuments,
    onAddLanguage,
    onRemoveLanguage,
    onAddTranslators,
    onAddNewTranslator,
    onAddLanguageToAllDocuments,
    onAddTranslatorsToAllDocuments
  } = props;

  const { data: documentDetail } = useGetDocumentDetailQuery(parseInt(item.documentId));
  const selectedLanguages = targetLanguages?.flatMap((tl) => tl.language);

  if (!documentDetail) {
    return <></>;
  }

  // removing the source language from the possibile target languages
  const filteredLanguageGroups: LanguageGroup[] = languageGroups.map((group) => {
    const modifiedOptions = group.options.filter(
      (option) => option.id !== String(documentDetail.sourceDocument?.language.id)
    );
    return { ...group, options: modifiedOptions };
  });

  const handleAddLanguage = (items?: LanguageOption[]) => {
    if (items) {
      onAddLanguage(items);
    }
  };

  const handleAddLanguageToAllDocuments = (items?: LanguageOption[]) => {
    if (items) {
      onAddLanguageToAllDocuments(items);
    }
  };

  return (
    <Surface id="surface" radius="sm" className="flex w-full flex-col gap-4 px-4 py-3">
      <div className="flex w-full flex-row">
        <div className="mr-auto flex flex-row items-center gap-2 overflow-hidden whitespace-nowrap break-words px-2">
          <AccessibleIcon label="ri-file-3-fill" icon="ri-file-3-fill" className="text-lg text-gray-400" />
          <OverflowTooltip label={documentDetail.sourceDocument.filename}>
            <Text size="md">{documentDetail.sourceDocument.filename}</Text>
          </OverflowTooltip>
          <Tag size="md" color="primary" variant="soft">
            <Text size="xs" weight="semibold" className="text-primary-active">
              {documentDetail.sourceDocument.language.name}
            </Text>
          </Tag>
        </div>
        <div className="w-72">
          <LanguageSearchBox
            defaultValues={selectedLanguages}
            languageGroups={filteredLanguageGroups}
            // if it has multiple documents show add to all document button
            multipleDocuments={multipleDocuments}
            onAddLanguage={handleAddLanguage}
            onAddLanguageToAllDocuments={handleAddLanguageToAllDocuments}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {targetLanguages.map((target, index) => (
          <AddLanguageTranslator
            key={target.language.id}
            index={index}
            item={target}
            isOnboarding
            hasMultipleDocuments={multipleDocuments}
            onRemoveLanguage={() => onRemoveLanguage(target.language.id)}
            onAddTranslator={(translators) => onAddTranslators(translators, target.language.id)}
            onAddNewTranslator={(email) => onAddNewTranslator(email, target.language.id)}
            onAddTranslatorToAllDocuments={(translators) =>
              onAddTranslatorsToAllDocuments(translators, target.language.id)
            }
          />
        ))}
      </div>
    </Surface>
  );
};
