import * as React from 'react';

interface Props {
  controlled: number;
  default: number;
  name?: string;
  state?: string;
}

export default function useControlled({ controlled, default: defaultProp }: Props) {
  // isControlled is ignored in the hook dependency lists as it should never change.
  const { current: isControlled } = React.useRef(controlled !== undefined);
  const [valueState, setValue] = React.useState(defaultProp);
  const value = isControlled ? controlled : valueState;

  const setValueIfUncontrolled = React.useCallback(
    (newValue: number) => {
      if (!isControlled) {
        setValue(newValue);
      }
    },
    [isControlled]
  );

  return [value, setValueIfUncontrolled] as const;
}
