import { FunctionComponent } from 'react';

import { Spinner } from '@/components/v1/Spinner';
import { useGetOnboardingSurveyInfoQuery } from '@/redux/api';

export const OnboardingBootstrap: FunctionComponent = ({ children }) => {
  const { isLoading } = useGetOnboardingSurveyInfoQuery();

  if (isLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  return <>{children}</>;
};
