import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { TextArea } from '@/components/v2/TextArea';
import { useSendFeedbackMutation } from '@/redux/api/feedbackApi';

// new version, it will replace "Feedback" modal used in WebEditor
export const SendFeedbackModal = ({ ...modalProps }: ModalProps) => {
  const { t: tModals } = useTranslation('modals');

  const [feedback, setFeedback] = useState('');
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();

  const handleConfirm = async () => {
    await sendFeedback({ body: { body: feedback } });
    modalProps?.onOpenChange?.(false);
  };

  const disable = isLoading;

  return (
    <Modal.Root {...modalProps}>
      <Modal.Content size="lg" className="gap-6">
        <Modal.Header>
          <AccessibleIcon
            icon="ri-information-2-fill"
            label="ri-information-2-fill"
            className="self-start text-4xl text-primary-active"
          />
          <Modal.Title size="lg">{tModals('sendUsYourFeedback')}</Modal.Title>
          <Modal.Description size="md">{tModals('weAlwaysLookingToImproveRedokunTellUs')}</Modal.Description>
        </Modal.Header>

        <TextArea
          size="lg"
          placeholder={tModals('whatWouldYouChange')}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />

        <Modal.Footer className="flex flex-row gap-4">
          <Modal.Close>
            <Button color="secondary" variant="surface" className="w-full">
              {tModals('close')}
            </Button>
          </Modal.Close>
          <Button color="primary" className="w-full" onClick={handleConfirm} disabled={disable}>
            {tModals('send')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
