import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { useToast } from '@/components/v2/Toast';
import { to } from '@/utils/awaitToJs';
import { useDeleteTeamMutation } from '@/redux/api/teamApi';
import { Text } from '@/components/v2/Text';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

export const ConfirmDeleteTeamModal = (props: ConfirmDeleteTeamModalProps) => {
  const { children, teamName, ...modalProps } = props;
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const [deleteTeamTrigger, { isLoading }] = useDeleteTeamMutation();

  const handleConfirm = async () => {
    const [err] = await to(deleteTeamTrigger({}).unwrap());
    if (!err) {
      if (modalProps.onOpenChange) {
        modalProps.onOpenChange(false);
      }
      toast({ title: tSettings('teamDeletedSuccessfullyToastTitle'), kind: 'success' });
    }
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="md">
        <Modal.Header>
          <div>
            <AccessibleIcon
              icon="ri-alert-fill"
              label={tSettings('deleteTeamModalTitle')}
              className="inline-block text-6xl text-red-600"
            />
          </div>
          <Modal.Title size="lg">{tSettings('deleteTeamModalTitle')}</Modal.Title>
          <Text color="secondary" size="sm">
            {tSettings('deleteTeamModalText', { teamName })}
          </Text>
        </Modal.Header>

        <Modal.Footer orientation={'vertical'}>
          <Button color="negative" onClick={handleConfirm} disabled={isLoading}>
            {tSettings('deleteTeam')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tSettings('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface ConfirmDeleteTeamModalProps extends ModalProps {
  teamName: string;
}
