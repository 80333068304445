import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { RequireSubscription } from '@/components/v2/RequireSubscription';

interface ButtonUploadProps {
  folderId?: number;
}

export const ButtonUpload: React.FC<ButtonUploadProps> = ({ folderId }: ButtonUploadProps) => {
  const { t: tDocumentList } = useTranslation('documentList');

  const path = folderId ? `/documents/new?folderId=${folderId}` : '/documents/new';
  return (
    <RequireSubscription>
      <ButtonLink href={path} variant="solid" color="primary">
        <AccessibleIcon icon="ri-file-upload-line" label={tDocumentList('uploadDocument')} />
        {tDocumentList('uploadDocument')}
      </ButtonLink>
    </RequireSubscription>
  );
};
