import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { NestedFolderStructureItem } from '@/components/v2/AccordionNested';
import { Button } from '@/components/v2/Button';
import { FolderBrowser } from '@/components/v2/FolderBrowser';
import { Input } from '@/components/v2/Input';
import Modal from '@/components/v2/Modal';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { useCreateFolderMutation, useGetFoldersQuery } from '@/redux/api';
import { FolderHierarchyDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';

import { getInitialAccordionIds, getInitialParentFolderId } from './utils';

export interface CreateFolderModalProps {
  currentFolderId?: number;
  children: React.ReactNode;
}

export const CreateFolderModal = (props: CreateFolderModalProps) => {
  const { currentFolderId, children } = props;

  const { t: tDocumentList } = useTranslation('documentList');

  const history = useHistory();

  const [createFolder, { isLoading: isCreateFolderLoading }] = useCreateFolderMutation();
  const { data: rootFolder, isLoading, isFetching } = useGetFoldersQuery();

  const [open, setOpen] = useState(false);
  // store current selected folder id
  const [selectedParentFolderId, setSelectedParentFolderId] = useState(() =>
    getInitialParentFolderId({ currentFolderId })
  );
  // list of accordion's folder ids
  const [openedAccordionIds, setOpenedAccordionIds] = useState(() =>
    getInitialAccordionIds({ rootFolder, currentFolderId })
  );

  // convert folder id from number to string
  const transformFolders = useCallback((folders: FolderHierarchyDto[]): NestedFolderStructureItem[] => {
    return folders.map((folder) => ({
      id: String(folder.id),
      name: folder.name,
      hasFolderIcon: true,
      children: transformFolders(folder.children)
    }));
  }, []);

  const defaultNestedFolders: NestedFolderStructureItem[] = useMemo(
    () => [
      {
        id: DOCUMENT_LIST_MOCK_ID,
        name: tDocumentList('documentList'),
        hasFolderIcon: false,
        children: rootFolder?.children ? transformFolders(rootFolder.children) : []
      }
    ],
    [rootFolder?.children, tDocumentList, transformFolders]
  );

  const { control, handleSubmit, formState, reset } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES
  });

  const onSubmit: SubmitHandler<CreateFolderModalForm> = async (data, e) => {
    e?.preventDefault();

    // if selected folder is document list set param to null as BE expects
    const parentFolderId = selectedParentFolderId === DOCUMENT_LIST_MOCK_ID ? null : Number(selectedParentFolderId);

    const [err] = await to(
      createFolder({
        name: data.folderName,
        parentFolderId
      })
    );

    if (!err) {
      // reset form state
      reset();
      setSelectedParentFolderId(DOCUMENT_LIST_MOCK_ID);
      setOpenedAccordionIds([DOCUMENT_LIST_MOCK_ID]);
      setOpen(false);

      // parentFolderId is null redirect to document list
      // otherwise redirect to parent folder
      if (parentFolderId === null) {
        history.push('/documents');
      } else {
        history.push(`/documents/folder/${parentFolderId}`);
      }
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      // reset form on modal close
      reset({ folderName: '' });
    }
    setOpen(open);
  };

  const handleInputTextSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // submit form on enter key pressed
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const loading = isLoading || isFetching || isCreateFolderLoading;
  const disable = selectedParentFolderId === undefined || !formState.isValid || loading;

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <RequireSubscription>
        <Modal.Trigger>{children}</Modal.Trigger>
      </RequireSubscription>

      <Modal.Content className="max-w-md">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-7">
          <Modal.Header>
            <Modal.Title size="md">{tDocumentList('createFolder')}</Modal.Title>
            <Modal.Description size="md">{tDocumentList('folderName')}</Modal.Description>
          </Modal.Header>
          <Controller
            name="folderName"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                required
                minLength={1}
                maxLength={100}
                placeholder={tDocumentList('newFolder')}
                onKeyPress={handleInputTextSubmit}
              />
            )}
          />
          <Show
            when={!loading}
            fallback={
              <div className="flex h-56 justify-center rounded-lg border border-alpha-200 bg-white">
                <Spinner size="lg" />
              </div>
            }
          >
            <FolderBrowser
              nestedFolderStructure={defaultNestedFolders}
              defaultValue={[DOCUMENT_LIST_MOCK_ID]}
              value={openedAccordionIds}
              selected={selectedParentFolderId}
              onSelectItem={setSelectedParentFolderId}
              onValueChange={setOpenedAccordionIds}
            />
          </Show>
          <Modal.Footer className="flex w-full flex-row items-center justify-center gap-9">
            <Modal.Close>
              <Button color="secondary" variant="surface" className="w-full">
                {tDocumentList('cancel')}
              </Button>
            </Modal.Close>
            <Button type="submit" color="primary" disabled={disable} className="w-full">
              {tDocumentList('createFolder')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
CreateFolderModal.displayName = 'CreateFolderModal';

const formSchema = z.object({
  // prevent submitting names with only spaces
  folderName: z.string().refine((value) => value.trim() !== '')
});

type CreateFolderModalForm = z.infer<typeof formSchema>;

const DEFAULT_VALUES = {
  folderName: ''
};
