import * as BaseSelect from '@radix-ui/react-select';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Text } from '@/components/v2/Text';
import { classNames } from '@/utils/classNames';

import s from './Select.module.css';
import { selectTrigger } from './styles';
import type { SelectProps } from './types';

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      bordered,
      children,
      className,
      defaultOpen,
      id,
      open: controlledOpen,
      placeholder,
      rootClassName,
      sideOffset = 5,
      size,
      value,
      onChange,
      onOpenChange
    },
    ref
  ) => {
    const [open, setOpen] = useControllableState({
      prop: controlledOpen,
      onChange: onOpenChange,
      defaultProp: defaultOpen
    });

    const handleOnOpenChange = (value: boolean) => {
      setOpen(value);
    };

    return (
      <BaseSelect.Root open={open} value={value} onValueChange={onChange} onOpenChange={handleOnOpenChange}>
        <BaseSelect.Trigger id={id} className={classNames(selectTrigger({ bordered, size }), rootClassName)} ref={ref}>
          <BaseSelect.Value
            placeholder={
              <Text size="md" color="tertiary">
                {placeholder}
              </Text>
            }
          />
          <BaseSelect.Icon asChild>
            <span className="flex items-center justify-center">
              {open ? (
                <AccessibleIcon label="arrow-up" icon="ri-arrow-up-s-line" className="text-2xl text-alpha-500" />
              ) : (
                <AccessibleIcon label="arrow-down" icon="ri-arrow-down-s-line" className="text-2xl text-alpha-500" />
              )}
            </span>
          </BaseSelect.Icon>
        </BaseSelect.Trigger>
        <BaseSelect.Portal>
          <BaseSelect.Content
            position="popper"
            sideOffset={sideOffset}
            className={classNames(
              'border-neutralWarm-500 z-50 max-h-select-content-available-height rounded-xl border bg-white ',
              className
            )}
          >
            <BaseSelect.ScrollUpButton>
              <AccessibleIcon label="arrow-up-icon" icon="ri-arrow-up-s-line" />
            </BaseSelect.ScrollUpButton>
            <BaseSelect.Viewport className="px-2.5 py-2">{children}</BaseSelect.Viewport>
            <BaseSelect.ScrollDownButton>
              <AccessibleIcon label="arrow-down-icon" icon="ri-arrow-down-s-line" />
            </BaseSelect.ScrollDownButton>
          </BaseSelect.Content>
        </BaseSelect.Portal>
      </BaseSelect.Root>
    );
  }
);

Select.displayName = 'Select';

export const SelectItem = forwardRef<
  ElementRef<typeof BaseSelect.Item>,
  ComponentPropsWithoutRef<typeof BaseSelect.Item>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <BaseSelect.Item
      {...props}
      ref={forwardedRef}
      className={classNames(
        'my-1 box-border flex w-full select-none rounded-lg border-none px-2.5 py-2 text-base font-medium text-typography-primary',
        s['select-content'],
        className
      )}
    >
      <BaseSelect.ItemText>{children}</BaseSelect.ItemText>
    </BaseSelect.Item>
  );
});

SelectItem.displayName = 'SelectItem';
