import { Descendant, Editor, Text } from 'slate';

export const editorText = (editor: Editor): string => {
  const { children } = editor;
  return children.reduce((text, child) => text + descendantText(child), '');
};

const descendantText = (value: Descendant): string => {
  if (Text.isText(value)) {
    return value.text;
  }

  const { children } = value;
  return children.reduce((text, child) => text + descendantText(child), '');
};
