import { useTranslation } from 'react-i18next';

import { FormControl, FormError, FormField } from '@/components/v2/Form';
import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@/libs/stripe';

import { FORM_ID } from './constants';

const PaymentMethodFormContent = () => {
  const { t } = useTranslation('checkout');

  return (
    <Surface border className="space-y-7 p-7">
      <Text weight="bold" color="primary">
        {t('paymentMethodForm.paymentMethod')}
      </Text>

      <FormField name="cardHolderName">
        <Label
          htmlFor={`${FORM_ID}-cardHolderName`}
          text={t('paymentMethodForm.cardHolderName')}
          size="sm"
          color="tertiary"
        />
        <FormControl>
          <Input id={`${FORM_ID}-cardHolderName`} placeholder={t('paymentMethodForm.cardHolderName')} size="sm" />
        </FormControl>
        <FormError size="xs" />
      </FormField>

      <FormField name="cardNumber">
        <Label htmlFor={`${FORM_ID}-cardNumber`} text={t('paymentMethodForm.cardNumber')} size="sm" color="tertiary" />
        <FormControl>
          <CardNumberElement id={`${FORM_ID}-cardNumber`} />
        </FormControl>
        <FormError size="xs" />
      </FormField>

      <div className="grid grid-cols-2 gap-3">
        <FormField name="cardExpiry">
          <Label
            htmlFor={`${FORM_ID}-cardExpiry`}
            text={t('paymentMethodForm.expirationDate')}
            size="sm"
            color="tertiary"
          />
          <FormControl>
            <CardExpiryElement id={`${FORM_ID}-cardExpiry`} />
          </FormControl>
          <FormError size="xs" />
        </FormField>

        <FormField name="cardCvc">
          <Label htmlFor={`${FORM_ID}-cardCvc`} text={t('paymentMethodForm.cvc')} size="sm" color="tertiary" />
          <FormControl>
            <CardCvcElement id={`${FORM_ID}-cardCvc`} options={{ placeholder: '123' }} />
          </FormControl>
          <FormError size="xs" />
        </FormField>
      </div>
    </Surface>
  );
};
PaymentMethodFormContent.displayName = 'PaymentMethodFormContent';

export { PaymentMethodFormContent };
