import { cva } from 'cva';

const flagIcon = cva('relative z-0 inline-block rounded-full', {
  variants: {
    fallback: {
      true: 'bg-gray-400',
      false: ''
    },
    size: {
      sm: 'h-4 min-h-[1rem] w-4 min-w-[1rem]',
      md: 'h-6 min-h-[1.5rem] w-6 min-w-[1.5rem]',
      lg: 'h-8 min-h-[2rem] w-8 min-w-[2rem]'
    }
  },
  defaultVariants: {
    fallback: true,
    size: 'md'
  }
});

export { flagIcon };
