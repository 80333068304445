import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import { GlossaryDto } from '@/redux/api/types';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { DeleteGlossaryModal } from '@/routes/Glossary/components/GlossaryList/components/DeleteGlossaryModal';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

enum ModalKey {
  GLOSSARY_DELETE = 'GLOSSARY_DELETE'
}

export const ActionsCell: React.FC = () => {
  const { t: tGlossary } = useTranslation('glossary');

  const { cell } = DataTable.useCellContext<GlossaryDto>('ActionsCell');
  const { currentAccount } = useCurrentAccount();

  const { id: glossaryId, sourceLanguage, targetLanguage } = cell.row.original;
  const languageNames = `${sourceLanguage.name} to ${targetLanguage.name}`;

  const canDeleteGlossary = !currentAccount?.subscriptionInfo.isTranslator;

  // https://github.com/radix-ui/primitives/issues/1836
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState<ModalKey | null>(null);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  // https://github.com/radix-ui/primitives/issues/1836
  const handleModalItemSelect = (key: ModalKey) => (event: Event) => {
    event.preventDefault();
    setMenuOpen(false);
    setModalOpen(key);
  };

  // https://github.com/radix-ui/primitives/issues/1836
  const modalProps = (key: ModalKey) => ({
    open: modalOpen === key,
    onOpenChange: (open: boolean) => {
      if (open) {
        setMenuOpen(false);
        setModalOpen(key);
      } else {
        setModalOpen(null);
        menuTriggerRef.current?.focus();
      }
    }
  });

  return (
    <div className="flex flex-row gap-2">
      <ButtonLink href={`glossaries/show/${glossaryId}`} variant="soft" size="sm">
        {tGlossary('seeDetails')}
      </ButtonLink>
      <Show when={canDeleteGlossary}>
        <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
          <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
            <AccessibleIcon
              ref={menuTriggerRef}
              icon="ri-more-2-fill"
              label="ri-more-2-fill"
              className="cursor-pointer text-lg text-primary-active"
            />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item
              onSelect={handleModalItemSelect(ModalKey.GLOSSARY_DELETE)}
              className="text-states-negative-active"
            >
              <AccessibleIcon icon="ri-delete-bin-5-line" label="ri-delete-bin-5-line" />
              {tGlossary('delete')}
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        <DeleteGlossaryModal
          {...modalProps(ModalKey.GLOSSARY_DELETE)}
          glossaryId={glossaryId}
          languageNames={languageNames}
        />
      </Show>
    </div>
  );
};
