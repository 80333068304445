import { useTranslation } from 'react-i18next';
import { useCombobox } from 'downshift';
import { useState } from 'react';
import { isEmpty } from 'lodash';

import Combobox from '@/components/v2/Combobox';
import { Text } from '@/components/v2/Text';
import { LanguageGroup, LanguageOption } from '@/routes/CreateGlossary/types';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { flattenGroupOptions, getGroupFilter } from '@/routes/CreateGlossary/utils';

interface LanguageComboboxProps {
  placeholder: string;
  items: LanguageGroup[];
  onChange?: (value?: number) => void;
}

export const LanguageCombobox = ({ placeholder, items: groups, onChange }: LanguageComboboxProps) => {
  const { t: tGlossary } = useTranslation('glossary');

  const [inputSearch, setInputSearch] = useState<string | undefined>();

  const options: LanguageOption[] = flattenGroupOptions(groups);
  const filteredLanguageGroups: LanguageGroup[] = inputSearch ? groups.map(getGroupFilter(inputSearch)) : groups;

  const state = useCombobox({
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      const id = selectedItem?.id;

      if (id) {
        onChange?.(parseInt(id));
      } else {
        onChange?.(undefined);
      }
    }
  });

  return (
    <Combobox.Root state={state} className="max-w-[256px]">
      <Combobox.Trigger
        bordered
        className="place-content-start rounded-xl"
        // this is needed to prevent submit form when pressing the combobox trigger
        onClick={(e) => e.preventDefault()}
      >
        <Combobox.TriggerValue placeholder={placeholder}>{state.selectedItem?.label}</Combobox.TriggerValue>
        <Combobox.TriggerIcon className="ml-auto" />
      </Combobox.Trigger>
      <Combobox.Content>
        <Combobox.SearchItem
          placeholder={tGlossary('typeLanguage')}
          onClick={(e) => e.preventDefault()}
          value={inputSearch}
          onChange={(e) => setInputSearch((e.target as HTMLInputElement).value)}
          size="sm"
        />
        <Combobox.ItemsContainer className="max-h-56">
          {filteredLanguageGroups.map((group) => {
            if (isEmpty(group.options)) {
              return null;
            }
            return (
              <div key={group.name}>
                <Text size="sm" color="tertiary" weight="semibold" className="mx-2 my-2.5">
                  {group.name}
                </Text>
                {group.options.map((option, optionIndex) => {
                  const index = options.indexOf(option);
                  return (
                    <Combobox.Item key={optionIndex} item={option} index={index} className="gap-3">
                      <FlagIcon label={option.name} code={option.flagCode || 'xx'} size="md" />
                      {option.label}
                    </Combobox.Item>
                  );
                })}
              </div>
            );
          })}
        </Combobox.ItemsContainer>
      </Combobox.Content>
    </Combobox.Root>
  );
};
