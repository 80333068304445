import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { Modal } from '@/components/v1/Modal';
import type { TConfirmModalProps } from '@/components/v1/Modal/types';
import { useChangeLanguageStatusMutation } from '@/redux/api';
import { TranslationStatus } from '@/redux/api/constants';
import type { IUnlockTranslationModalProps } from '@/redux/slices/modalsSlice/types/props';

export const UnlockTranslationModal: FunctionComponent<
  TConfirmModalProps & { props: IUnlockTranslationModalProps }
> = ({ props: { documentId, languageId }, ...modalProps }) => {
  const { t } = useTranslation('modals');
  const [changeLanguageStatus, { isLoading }] = useChangeLanguageStatusMutation();

  return (
    <Modal.Confirm
      cancelText={t('close')}
      okText={t('unlockTranslation')}
      okLoading={isLoading}
      onOk={() => {
        changeLanguageStatus({ documentId, languageId, body: { status: TranslationStatus.CREATED } }).then(() => {
          modalProps.onClose(false);
        });
      }}
      {...modalProps}
    >
      {{
        header: t('unlockDocumentTranslation'),
        content: (
          <div className="space-y-4">
            <Trans as="p" i18nKey="modals:translationHasBeenConfirmed" components={{ 1: <br /> }} />

            <Alert message={t('translatedDocumentHasAlreadyBeenGenerated')} />
          </div>
        )
      }}
    </Modal.Confirm>
  );
};
