import { Separator } from '@radix-ui/react-separator';
import { ComponentPropsWithRef, ElementRef, forwardRef } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';

import { button } from '@/components/v2/Button/styles';
import { Text } from '@/components/v2/Text';
import { TextProps } from '@/components/v2/Text/types';
import { Title } from '@/components/v2/Title';
import { TitleProps } from '@/components/v2/Title/types';
import { classNames } from '@/utils/classNames';
import { createContext } from '@/utils/createContext';

import { FileUploaderButtonProps, FileUploaderProps } from './types';

type PartialExcept<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;
type DropzoneContext = PartialExcept<DropzoneState, 'getInputProps' | 'getRootProps' | 'isDragActive'> & {
  disabled?: boolean;
};

const [FileUploaderProvider, useFileUploaderContext] = createContext<DropzoneContext>('FileUploader');

export const FileUploader = forwardRef<HTMLDivElement, FileUploaderProps>(
  ({ className, children, dropzoneOptions, ...props }, ref) => {
    const { getRootProps, getInputProps, open, isDragActive, acceptedFiles } = useDropzone(dropzoneOptions);

    const value = {
      open,
      getRootProps,
      getInputProps,
      isDragActive,
      acceptedFiles,
      disabled: dropzoneOptions?.disabled
    };

    return (
      <div
        ref={ref}
        className={classNames('flex flex-col gap-4', { 'cursor-not-allowed': dropzoneOptions?.disabled }, className)}
        {...props}
      >
        <FileUploaderProvider {...value}>{children}</FileUploaderProvider>
      </div>
    );
  }
);
FileUploader.displayName = 'FileUploader';

export const FileUploaderDragArea = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    const { getRootProps, isDragActive } = useFileUploaderContext('FileUploaderDragArea');

    return (
      <div
        ref={ref}
        {...getRootProps()}
        className={classNames(
          'flex flex-col items-center rounded-xl border border-dashed border-gray-400 px-6 py-8',
          { 'bg-surface-accent': isDragActive },
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);
FileUploaderDragArea.displayName = 'FileUploaderDragArea';

export const FileUploaderDragAreaContent = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={classNames('flex flex-col items-center gap-2 text-center', className)} {...props}>
        {children}
      </div>
    );
  }
);
FileUploaderDragAreaContent.displayName = 'FileUploaderDragAreaContent';

export const FileUploaderButton = forwardRef<HTMLDivElement, FileUploaderButtonProps>(
  ({ children, disabled, ...props }, ref) => {
    // we won't rely on react-dropzone `open` callback prop anymore, to stay compatible with Safari
    const { getInputProps } = useFileUploaderContext('FileUploaderButton');

    return (
      <>
        <div
          {...props}
          ref={ref}
          className={classNames(button({ color: 'primary', size: 'md', variant: 'surface' }), 'cursor-pointer', {
            // mocking button disabled state
            // we can't user pointer-events-none because blocks tooltip from showing
            'cursor-not-allowed border-transparent bg-states-disabled text-typography-disabled hover:border-transparent hover:text-typography-disabled active:border-transparent active:text-typography-disabled':
              disabled
          })}
        >
          {children}
        </div>
        {/* Safari issue because react-dropzone not updated,solution:
        do not rely on button `onClick` callback, rely on the click in
        the underlying drag&drop area */}
        {/* <Button
          {...props}
          ref={ref}
          variant="surface"
          color="primary"
          size="md"
          onClick={() => open?.()}
          disabled={disabled}
        >
          {children}
        </Button> */}
        <input disabled={disabled} {...getInputProps()} />
      </>
    );
  }
);
FileUploaderButton.displayName = 'FileUploaderButton';

export const FileUploaderDivider = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={classNames('flex w-full flex-row items-center px-12', className)} {...props}>
        <Separator className="h-px w-full rounded-full bg-alpha-200" />
        <Text size="sm" weight="regular" className="px-6 text-typography-secondary">
          {children}
        </Text>
        <Separator className="h-px w-full rounded-full bg-alpha-200" />
      </div>
    );
  }
);
FileUploaderDivider.displayName = 'FileUploaderDivider';

export const FileUploaderTitle = forwardRef<ElementRef<typeof Title>, TitleProps>(({ children, ...props }, ref) => {
  return (
    <Title ref={ref} size="sm" weight="medium" {...props}>
      {children}
    </Title>
  );
});
FileUploaderTitle.displayName = 'FileUploaderTitle';

export const FileUploaderDescription = forwardRef<ElementRef<typeof Text>, TextProps>(({ children, ...props }, ref) => {
  return (
    <Text ref={ref} size="xs" {...props}>
      {children}
    </Text>
  );
});
FileUploaderDescription.displayName = 'FileUploaderDescription';

export const FileUploaderBottomText = forwardRef<ElementRef<typeof Text>, TextProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Text
        ref={ref}
        size="xs"
        weight="regular"
        className={classNames('px-6 text-typography-secondary', className)}
        {...props}
      >
        {children}
      </Text>
    );
  }
);
FileUploaderBottomText.displayName = 'FileUploaderBottomText';
