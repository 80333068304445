import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import { useDeleteDocumentTranslatedCommentMutation } from '@/redux/api';
import type { IDeleteDocumentCommentModalProps } from '@/redux/slices/modalsSlice/types/props';

export const DeleteDocumentCommentModal = ({
  props: { documentId, languageId, commentId },
  ...modalProps
}: TJumboModalProps & { props: IDeleteDocumentCommentModalProps }) => {
  const { t } = useTranslation('modals');
  const [deleteComment] = useDeleteDocumentTranslatedCommentMutation();

  return (
    <Modal.Confirm
      cancelText={t('cancel')}
      okText={t('delete')}
      onOk={() => {
        deleteComment({ documentId, languageId, commentId });
        modalProps.onClose(false);
      }}
      {...modalProps}
    >
      {{
        header: t('wouldYouLikeToDeleteThisComment'),
        content: t('areYouSureToDeleteThisCommentThisCannotBeUndone')
      }}
    </Modal.Confirm>
  );
};
