import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { NestedFolderStructureItem } from '@/components/v2/AccordionNested';
import { Button } from '@/components/v2/Button';
import { FolderBrowser } from '@/components/v2/FolderBrowser';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { useToast } from '@/components/v2/Toast';
import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { useCreateFolderMutation, useGetFoldersQuery } from '@/redux/api';
import { DocumentListItemType } from '@/redux/api/constants';
import { useUpdateDocumentListItemsMutation } from '@/redux/api/documentListApi';
import { DocumentListOperationItemRequestDto, FolderHierarchyDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

export interface MoveItemsModalModalProps extends ModalProps {
  items: DocumentListOperationItemRequestDto[];
  defaultParentFolderId?: string;
  onConfirm?: () => void;
  onError?: () => void;
}

export const MoveToFolderModal = (props: MoveItemsModalModalProps) => {
  const { defaultParentFolderId = DOCUMENT_LIST_MOCK_ID, items, children, onConfirm, onError, ...modalProps } = props;

  const { toast } = useToast();
  const { currentAccount } = useCurrentAccount();

  const { t: tDocumentList } = useTranslation('documentList');
  const [openChangeFolderModal, setOpenChangeFolderModal] = useState(false);

  const [createFolder, { isLoading: isCreateFolderLoading }] = useCreateFolderMutation();
  const [updateDocumentListItems] = useUpdateDocumentListItemsMutation();
  const { data, isFetching, isUninitialized } = useGetFoldersQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const isLoading = isCreateFolderLoading || isFetching || isUninitialized;

  const canCreateFolder = currentAccount?.subscriptionInfo.isActive;

  // if user select some folder rows, we disable them to prevent moving folder inside themself
  const disabledFolderIds = items.flatMap((i) => (i.type === DocumentListItemType.FOLDER ? [String(i.elementId)] : []));

  const transformFolders = useCallback((folders: FolderHierarchyDto[]): NestedFolderStructureItem[] => {
    return folders.map((folder) => ({
      id: String(folder.id),
      name: folder.name,
      hasFolderIcon: true,
      children: folder.children ? transformFolders(folder.children) : []
    }));
  }, []);

  const defaultNestedFolders: NestedFolderStructureItem[] = useMemo(
    () => [
      {
        id: DOCUMENT_LIST_MOCK_ID,
        name: tDocumentList('documentList'),
        hasFolderIcon: false,
        children: data?.children ? transformFolders(data.children) : []
      }
    ],
    [data?.children, tDocumentList, transformFolders]
  );

  const [nestedFolders, setNestedFolders] = useState(defaultNestedFolders);
  // store current selected folder id
  const [selectedParentFolderId, setSelectedParentFolderId] = useState<string | undefined>(defaultParentFolderId);
  // folder creation state
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState<boolean>(false);
  // list of accordion's folder ids
  const [openedAccordionIds, setOpenedAccordionIds] = useState([DOCUMENT_LIST_MOCK_ID]);

  // on search filter folders with given query
  const filterFolders = (folders: NestedFolderStructureItem[], query: string): NestedFolderStructureItem[] => {
    return folders.reduce((acc: NestedFolderStructureItem[], folder) => {
      const filteredChildren = folder.children ? filterFolders(folder.children, query) : [];

      const folderName = folder.name.toLowerCase();
      const searchQuery = query.toLowerCase();

      const match = folderName.includes(searchQuery);
      // if the folder itself matches or it has any matching children
      if (match || isNotEmpty(filteredChildren)) {
        if (isNotEmpty(filteredChildren)) {
          // open the matched folder parentFolderId accordion
          toggleFolderAccordion(folder.id);
        }
        // return the folder with all its original children if it matches
        // otherwise only filtered children
        const folderWithChildren = { ...folder, children: filteredChildren };
        const item = match ? folder : folderWithChildren;

        return [...acc, item];
      }
      return acc;
    }, []);
  };

  const toggleFolderAccordion = (folderId: string) => {
    setOpenedAccordionIds((prev) => {
      const updatedSet = new Set(prev);
      updatedSet.add(folderId);
      return Array.from(updatedSet);
    });
  };

  useEffect(() => {
    if (data?.children) {
      setNestedFolders(defaultNestedFolders);
    }
  }, [data, defaultNestedFolders]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.currentTarget.value;

    debounce(() => {
      if (query === '') {
        setNestedFolders(defaultNestedFolders);
        setOpenedAccordionIds([DOCUMENT_LIST_MOCK_ID]);
      } else {
        const filteredFolders = filterFolders(defaultNestedFolders, query);
        setNestedFolders(filteredFolders);
      }
    }, 500)();
  };

  const handleNewFolder = (parentId?: string) => {
    if (!parentId) return;

    // on new folder button clicked
    // open the parent folder accordion
    toggleFolderAccordion(parentId);
    // enable folder creation state
    setIsCreatingNewFolder(true);
  };

  const handleOnFolderCreated = async (parentFolderId: number | null, name: string) => {
    // check if folder name is space only
    if (name.trim() === '') {
      toast({
        title: tDocumentList('folderNameCannotBeEmpty'),
        kind: 'error'
      });
    } else {
      const [err] = await to(createFolder({ parentFolderId, name }).unwrap());
      if (err) {
        // close modal if there's an error
        // this to prevent to have the globar error modal opened over this one
        handleOnOpenChange?.(false);
      }
      // disable folder creation state
      setIsCreatingNewFolder(false);
    }
  };

  const handleSelectItem = (id: string) => {
    setIsCreatingNewFolder(false);
    setSelectedParentFolderId(id);
  };

  const handleOnOpenChange = (open: boolean) => {
    if (!open) {
      // on modal close, reset state
      setNestedFolders(defaultNestedFolders);
      setSelectedParentFolderId(defaultParentFolderId);
      setIsCreatingNewFolder(false);
    }
    setOpenChangeFolderModal(open);
  };

  const onSubmit = async () => {
    if (selectedParentFolderId && isNotEmpty(items)) {
      const parentFolderIsRoot = selectedParentFolderId === defaultParentFolderId;
      const parentFolderId = parentFolderIsRoot ? null : parseInt(selectedParentFolderId);

      const [err] = await to(
        updateDocumentListItems({
          parentFolderId,
          items,
          parentFolderIsRoot
        }).unwrap()
      );

      if (err) {
        handleOnOpenChange?.(false);
        onError?.();
      } else {
        handleOnOpenChange?.(false);
        onConfirm?.();
      }
    }
  };

  // if user has not selected any item, disable upload and new folder buttons
  const disabled = selectedParentFolderId === undefined;

  return (
    <Modal.Root onOpenChange={handleOnOpenChange} open={openChangeFolderModal} {...modalProps}>
      <RequireSubscription>
        <Modal.Trigger>{children}</Modal.Trigger>
      </RequireSubscription>

      {/* prevent closing modal when click outside */}
      <Modal.Content
        className="max-w-md gap-3"
        onInteractOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => {
          // on esc key pressed block folder creation
          setIsCreatingNewFolder(false);
          // prevent closing modal with esc
          e.preventDefault();
        }}
      >
        <Modal.Header className="gap-3">
          <Modal.Title size="md">{tDocumentList('moveToFolder')}</Modal.Title>
          <Modal.Description size="md">
            {tDocumentList('youAreMovingCountFilesToNewFolder', { count: items.length })}
          </Modal.Description>
          <div
            className="min-w-select-trigger flex w-full gap-2.5 rounded-md border border-gray-200 bg-white px-4"
            onClick={(e) => e.preventDefault()}
          >
            <AccessibleIcon label="ri-search-line" icon="ri-search-line" className="text-lg text-gray-600" />
            <input
              className="outline-none mr-auto flex-1 py-2 text-base text-typography-primary placeholder-typography-tertiary"
              placeholder={tDocumentList('searchFolder')}
              onChange={handleSearch}
            />
          </div>
        </Modal.Header>

        <Show
          when={!isLoading}
          fallback={
            <div className="flex h-56 justify-center rounded-lg border border-alpha-200 bg-white">
              <Spinner size="lg" />
            </div>
          }
        >
          <FolderBrowser
            defaultValue={[DOCUMENT_LIST_MOCK_ID]}
            value={openedAccordionIds}
            nestedFolderStructure={nestedFolders}
            selected={selectedParentFolderId}
            isCreatingNewFolder={isCreatingNewFolder}
            disabledIds={disabledFolderIds}
            onSelectItem={handleSelectItem}
            onValueChange={setOpenedAccordionIds}
            onFolderCreated={handleOnFolderCreated}
          />
        </Show>

        <Modal.Footer className="flex flex-col gap-5">
          <Show when={canCreateFolder}>
            <div className="flex w-full flex-row justify-end">
              <Button variant="text" color="primary" onClick={() => handleNewFolder('2')} disabled={disabled}>
                <AccessibleIcon label={tDocumentList('newFolder')} icon="ri-add-line" />
                {tDocumentList('newFolder')}
              </Button>
            </div>
          </Show>
          <div className="flex w-full flex-row items-center justify-center gap-5">
            <Modal.Close>
              <Button color="secondary" variant="surface" className="w-full">
                {tDocumentList('cancel')}
              </Button>
            </Modal.Close>
            <Button color="primary" disabled={disabled} onClick={onSubmit} className="w-full">
              {tDocumentList('moveHere')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
