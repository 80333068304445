import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { useTranslation } from 'react-i18next';

import idml01 from '@/assets/idml-01.png';
import pptx01 from '@/assets/pptx-01.png';
import xliff01 from '@/assets/xliff-01.png';
import { For } from '@/components/v2/For';
import { Text } from '@/components/v2/Text';

import type { DocumentSamplesToggleGroupProps } from './types';

export const DocumentSamplesToggleGroup = (props: DocumentSamplesToggleGroupProps) => {
  const { value, onValueChange } = props;

  const { t } = useTranslation('uploadDocuments');

  const handleValueChange = (value: string) => {
    if (value) {
      onValueChange?.(value);
    }
  };

  return (
    <div className="space-y-2">
      <Text className="text-alpha-400">{t('chooseType')}</Text>

      <ToggleGroup.Root
        className="flex flex-row gap-4"
        orientation="horizontal"
        type="single"
        value={value}
        onValueChange={handleValueChange}
      >
        <For each={items}>
          {(item) => (
            <ToggleGroup.Item
              className="group flex flex-1 flex-col items-center justify-center gap-y-3 rounded-lg border border-alpha-200 bg-surface-background p-4 ring-2 ring-transparent transition-all hover:border-alpha-400 data-state-on:border-transparent data-state-on:ring-primary-active"
              key={item.value}
              value={item.value}
            >
              <img src={item.image} alt={item.label} className="h-8 w-8 object-contain" />

              <Text weight="medium" className="transition-colors group-data-state-on:text-primary-active">
                {item.label}
              </Text>
            </ToggleGroup.Item>
          )}
        </For>
      </ToggleGroup.Root>
    </div>
  );
};

const items = [
  {
    value: 'IDML-01',
    image: idml01,
    label: 'Sample InDesign IDML file'
  },
  {
    value: 'PPTX-01',
    image: pptx01,
    label: 'Sample PowerPoint file'
  },
  {
    value: 'XLIFF-01',
    image: xliff01,
    label: 'Sample XLIFF file'
  }
];
