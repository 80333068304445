import type { RenderLeafProps } from 'slate-react';

import { classNames } from '@/utils/classNames';

export const StyledLeaf = ({ attributes, children, leaf }: RenderLeafProps) => (
  <span
    {...attributes}
    className={classNames({ 'bg-yellow-100': leaf.attributes?.highlight })}
    style={{ paddingLeft: leaf.text === '' ? '0.1px' : undefined }}
  >
    {children}
  </span>
);
