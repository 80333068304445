import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@/components/v1/Badge';
import { Divider } from '@/components/v1/Divider';
import { Show } from '@/components/v1/Show';
import { Spinner } from '@/components/v1/Spinner';
import { useOffline } from '@/hooks/useOffline';
import { useUnmount } from '@/hooks/useUnmount';
import { useCommentsContext } from '@/providers/CommentsProvider';
import { useCurrentAccountContext } from '@/providers/CurrentAccountProvider';
import {
  useCreateDocumentTranslatedCommentMutation,
  useGetBootstrapDataQuery,
  useGetDocumentTranslatedCommentQuery,
  useMarkDocumentTranslatedCommentReadMutation
} from '@/redux/api';
import { EBlockedReason } from '@/redux/api/constants';
import { useAppDispatch } from '@/redux/hooks';
import { openDeleteDocumentCommentModal } from '@/redux/slices/modalsSlice';
import { classNames } from '@/utils/classNames';

import { useComments } from '../../hooks/useComments';
import { CommentInput } from '../comments/CommentInput';
import { CommentItem } from '../comments/CommentItem';
import { CommentsList } from '../comments/CommentsList';
import type { IDocumentCommentsProps } from './types';
import { getCommentKey } from './utils';

// This component is the entry point for the comments section of the document language page
// TODO: We should find a better way to unify document and paragraph comments
export const DocumentComments = ({ documentId, languageId, compact }: IDocumentCommentsProps) => {
  const { t } = useTranslation('documentLanguageComments');
  const [collapsed, setCollapsed] = useState(false);
  const offline = useOffline();
  const { teamMembersColors, suggestions } = useCommentsContext();
  const dispatch = useAppDispatch();
  const { currentAccount } = useCurrentAccountContext();

  // Bootstrap data query
  const { data: bootstrapData } = useGetBootstrapDataQuery({ documentId, languageId });

  // Document translated comment query
  const { data: commentsData, isFetching: isCommentsDataFetching } = useGetDocumentTranslatedCommentQuery({
    documentId,
    languageId
  });

  // Create document translated comment mutation
  const [createComment, { isLoading: isCreateCommentLoading, isSuccess: isCreateCommentSuccess }] =
    useCreateDocumentTranslatedCommentMutation();

  // Mark document translated comment read mutation
  const [markCommentRead] = useMarkDocumentTranslatedCommentReadMutation();

  const {
    commentsCount,
    unreadCommentsCount,
    oldComments,
    newComments,
    firstUnreadCommentId,
    lastCommentId,
    saveSplit,
    resetSplit
  } = useComments(commentsData?.comments);

  const markRead = () => {
    if (unreadCommentsCount && lastCommentId) {
      markCommentRead({
        documentId,
        languageId,
        body: { lastReadDocumentTranslatedCommentId: lastCommentId }
      });
    }
  };

  useUnmount(() => {
    resetSplit();
    markRead();
  });

  return (
    <div className="bg-white">
      <Divider
        noPadding={!compact}
        extra={
          !compact && (
            <button
              className="ml-2 font-bold text-blue-500 hover:text-blue-600"
              onClick={() => {
                const newCollapsed = !collapsed;
                setCollapsed(newCollapsed);

                if (newCollapsed) {
                  resetSplit();
                  markRead();
                }
              }}
            >
              {collapsed ? t('showHiddenComments', { count: commentsCount }) : t('collapse')}
            </button>
          )
        }
      >
        <span>{compact ? t('comments') : t('briefNotesAndInstructions')}</span>
        {unreadCommentsCount > 0 && <Badge className="ml-1" shape="squared" value={unreadCommentsCount} />}
      </Divider>

      <Show when={!collapsed}>
        <div className={classNames('mt-2', { 'px-4': compact })}>
          {isCommentsDataFetching && commentsCount < 1 ? (
            <div className="flex h-10 items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <CommentsList
              oldComments={oldComments}
              newComments={newComments}
              scrollCommentId={firstUnreadCommentId || lastCommentId}
              getCommentKey={getCommentKey}
              renderComment={(key, { id, account, content, createdAt }) => (
                <CommentItem
                  key={key}
                  elementId={key}
                  account={account}
                  content={content}
                  createdAt={createdAt}
                  defaultTeamMemberColor={teamMembersColors.defaultColor}
                  teamMembersColors={teamMembersColors.colors}
                  onDelete={() => {
                    dispatch(openDeleteDocumentCommentModal({ documentId, languageId, commentId: id }));
                  }}
                />
              )}
            />
          )}

          <div className="mt-2">
            <CommentInput
              disabled={bootstrapData?.weConfig.blockedReason === EBlockedReason.EXPIRED_SUBSCRIPTION || offline}
              isCreating={isCreateCommentLoading}
              isCreated={isCreateCommentSuccess}
              suggestions={suggestions}
              onMarkRead={() => {
                saveSplit();
                markRead();
              }}
              onCreate={(content) => {
                createComment({ documentId, languageId, currentAccount, body: { content } });
              }}
            />
          </div>
        </div>
      </Show>
    </div>
  );
};
