import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

import { ScheduleSubscriptionCancellation } from './components/ScheduleSubscriptionCancellation';
import { ReactivateAutoRenewal } from './components/ReactivateAutoRenewal';
import { InlineMessage } from '@/components/v2/InlineMessage';

export const SettingsManageAutoRenewal: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');

  const { currentAccount } = useCurrentAccount();

  // NOTE: The state of currentAccount.subscriptionInfo.isRenewing isn't updated after the
  // APIs calls, so we need to handle it with an internal state to allow the user to see the
  // correct UI.
  const [isRenewing, setIsRenewing] = useState(currentAccount.subscriptionInfo.isRenewing);

  let pageBlocks: JSX.Element | null = null;
  if (currentAccount.subscriptionInfo.teamOwnerAccountId === currentAccount.id) {
    if (currentAccount.subscriptionInfo.isActive && isRenewing) {
      pageBlocks = (
        <ScheduleSubscriptionCancellation
          currentAccount={currentAccount}
          isRenewingState={{ isRenewing, setIsRenewing }}
        />
      );
    } else if (currentAccount.subscriptionInfo.isActive && !isRenewing) {
      pageBlocks = (
        <ReactivateAutoRenewal currentAccount={currentAccount} isRenewingState={{ isRenewing, setIsRenewing }} />
      );
    }
  } else {
    pageBlocks = (
      <div className={'space-y-7'}>
        <InlineMessage type="warning" orientation="horizontal">
          <Text size="sm" weight="semibold" className="" color="primary">
            {tSettings('onlyTheTeamOwnerCanManageAutoRenewal')}
          </Text>
        </InlineMessage>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsManageAutoRenewal')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('manageAutoRenewal')}
          </Text>
        </div>

        {pageBlocks}
      </div>
    </div>
  );
};
