import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AppLayout from '@/components/layouts/AppLayout';
import { useGetDocumentDetailQuery } from '@/redux/api';
import { EStandardErrorCode } from '@/redux/api/constants';
import { isHttpStandardError } from '@/redux/api/utils/isError';
import { NotFound } from '@/routes/NotFound/NotFound';

export const DocumentDataCheck = ({ children }: { children: React.ReactNode }) => {
  const useDocumentParams = () => {
    const params = useParams<{ documentId: string }>();
    const parsedParams = useMemo(() => ({ documentId: parseInt(params.documentId) }), [params]);
    return parsedParams;
  };
  const { documentId } = useDocumentParams();
  const { error } = useGetDocumentDetailQuery(documentId);
  const { t: tGeneral } = useTranslation('general');

  return isHttpStandardError(error) && error.data.code === EStandardErrorCode.NOT_FOUND ? (
    <AppLayout.Root title={tGeneral('notFound.titleDocument')}>
      <AppLayout.Navbar />
      <AppLayout.Content>
        <NotFound title={tGeneral('notFound.titleDocument')} message={tGeneral('notFound.messageDocument')} />
      </AppLayout.Content>
    </AppLayout.Root>
  ) : (
    <>{children}</>
  );
};
