import { Popover as BasePopover } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';

import { classNames } from '@/utils/classNames';
import { isFunction } from '@/utils/isFunction';

import s from './Popover.module.css';
import type { IPopoverProps } from './types';

export const Popover = ({
  placement,
  strategy,
  containerClassName,
  containerStyle,
  children,
  content,
  className,
  style,
  flipModifier = {}
}: IPopoverProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers: [
      { name: 'flip', enabled: false, ...flipModifier },
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'hide' }
    ]
  });

  return (
    <BasePopover as={Fragment}>
      {(props) => (
        <div
          ref={setReferenceElement}
          className={isFunction(containerClassName) ? containerClassName(props) : containerClassName}
          style={containerStyle}
        >
          {isFunction(children) ? children(props) : children}

          <BasePopover.Panel
            ref={setPopperElement}
            className={(props) =>
              classNames(
                s.panel,
                'z-10 rounded-sm border border-gray-300 bg-white py-1 shadow-2xl',
                isFunction(className) ? className(props) : className
              )
            }
            style={style ? { ...style, ...styles.popper } : styles.popper}
            {...attributes.popper}
          >
            {content}
          </BasePopover.Panel>
        </div>
      )}
    </BasePopover>
  );
};

Popover.Button = BasePopover.Button;
