import { Popover, PopoverArrow, PopoverContent, PopoverLabel, PopoverPortal, PopoverTrigger } from './Popover';

const PopoverDefault = {
  Root: Popover,
  Trigger: PopoverTrigger,
  Content: PopoverContent,
  Portal: PopoverPortal,
  Arrow: PopoverArrow,
  Label: PopoverLabel
};

export default PopoverDefault;
