import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { InlineMessage } from '@/components/v2/InlineMessage';
import { Box } from '@/components/v2/Box';
import RadioGroup from '@/components/v2/RadioGroup';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { to } from '@/utils/awaitToJs';
import { usePatchCurrentAccountMutation } from '@/redux/api';
import { useToast } from '@/components/v2/Toast';
import { Spinner } from '@/components/v1/Spinner';

import { BooleanValues } from './constants';

export const SettingsSegmentation: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { currentAccount } = useCurrentAccount();
  const { toast } = useToast();
  const [patchCurrentAccountTrigger, { isLoading: patchCurrentAccountIsLoading }] = usePatchCurrentAccountMutation();

  const formSchema = z.object({
    useSrxSegmentation: z.string()
  });

  const { control: controlSubmitPatchCurrentAccount, handleSubmit: handleSubmitPatchCurrentAccount } = useForm<
    z.infer<typeof formSchema>
  >({
    resolver: zodResolver(formSchema),
    defaultValues: {
      useSrxSegmentation: currentAccount.settings.useSrxSegmentation ? BooleanValues.true : BooleanValues.false
    }
  });

  const onSave = async (values: z.infer<typeof formSchema>) => {
    const [err] = await to(
      patchCurrentAccountTrigger({
        settings: {
          useSrxSegmentation: values.useSrxSegmentation === BooleanValues.true ? true : false
        }
      }).unwrap()
    );

    if (!err) {
      toast({ title: tSettings('savedSuccessfully'), kind: 'success' });
    }
  };

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsSegmentation')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('segmentation')}
          </Text>

          <Button
            color="primary"
            disabled={patchCurrentAccountIsLoading}
            onClick={handleSubmitPatchCurrentAccount(onSave)}
          >
            {patchCurrentAccountIsLoading ? <Spinner size="sm" /> : null}
            {tSettings('save')}
          </Button>
        </div>

        {/* Forms */}
        <div>
          <InlineMessage type="informational" orientation="horizontal" size="sm">
            <Text size="sm" weight="semibold" className="text-blue-500">
              {tSettings('changesWillOnlyAffectFilesUploadedInTheFuture')}
            </Text>
          </InlineMessage>
        </div>
        <div>
          <Box>
            <form>
              <div>
                <Text size="md" color="primary" className="font-bold">
                  {tSettings('segmentationSettings')}
                </Text>
                <Text size="md" color="tertiary" className="mt-2 whitespace-pre-line">
                  {tSettings('segmentationSettingsDescription')}
                </Text>
              </div>
              <div className="space-y-4 pt-6">
                <Text size="md" color="secondary">
                  {tSettings('segmentTheTextInYourDocumentsBy')}
                </Text>
                <Controller
                  control={controlSubmitPatchCurrentAccount}
                  name="useSrxSegmentation"
                  render={({ field: { value, onChange, ...field } }) => (
                    <RadioGroup.Root className="flex space-x-6" value={value} onValueChange={onChange} {...field}>
                      <label className="flex items-center gap-3">
                        <RadioGroup.Item value={BooleanValues.true} size="sm" className="peer" />
                        <Text
                          color="primary"
                          size="sm"
                          className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
                        >
                          {tSettings('sentence')}
                        </Text>
                      </label>
                      <label className="flex items-center gap-3">
                        <RadioGroup.Item value={BooleanValues.false} size="sm" className="peer" />
                        <Text
                          color="primary"
                          size="sm"
                          className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
                        >
                          {tSettings('paragraph')}
                        </Text>
                      </label>
                    </RadioGroup.Root>
                  )}
                />
              </div>
            </form>
          </Box>
        </div>
      </div>
    </div>
  );
};
