import { useCallback } from 'react';

import { useUpdateTranslationStatusMutation } from '@/redux/api';
import { EParagraphTranslationStatus } from '@/redux/api/constants';

import type { IUseParagraphMutationsProps } from './types';

/**
 * Wrapper for useUpdateTranslationStatusMutation hook used in DocumentLanguageParagraph component
 *
 * @param documentId Document ID
 * @param languageId Language ID
 * @param paragraphId Paragraph ID
 * @returns wrapped mutation hook with less parameters and renamed fields (more readable component)
 */
export const useUpdateParagraphTranslationStatusMutation = ({
  documentId,
  languageId,
  paragraphId
}: IUseParagraphMutationsProps) => {
  const [mutationTrigger, mutationResult] = useUpdateTranslationStatusMutation({
    selectFromResult: ({ data, isLoading, isSuccess, isError }) => ({
      updateTranslationStatusData: data,
      updateTranslationStatusIsLoading: isLoading,
      updateTranslationStatusIsSuccess: isSuccess,
      updateTranslationStatusIsError: isError
    })
  });

  return [
    useCallback(
      (status: EParagraphTranslationStatus) => {
        mutationTrigger({ documentId, languageId, body: { paragraphId, status } });
      },
      [documentId, languageId, paragraphId, mutationTrigger]
    ),
    mutationResult
  ] as const;
};
