import { useLocation } from 'react-router-dom';

/**
 * @deprecated Use the new `useSearchParams` hook instead
 */
export const useRouterParamsQuery = () => {
  const { search } = useLocation();

  return new URLSearchParams(search);
};
