import * as Sentry from '@sentry/react';

const { REACT_APP_SENTRY_INIT, REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT } = import.meta.env;

export const SENTRY_INIT = REACT_APP_SENTRY_INIT === 'true' || REACT_APP_SENTRY_INIT === '1';

export const SENTRY_OPTIONS: Sentry.BrowserOptions = {
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENVIRONMENT,
  /**
   * This error means that ResizeObserver was not able to deliver all observations within a single animation frame. It is benign (your site will not break).
   * - https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/#workaround-4-sentry-error-monitoring
   * - https://github.com/WICG/resize-observer/issues/38#issuecomment-493014026
   *
   * You can avoid this error by wrapping the callback in `requestAnimationFrame`.
   */
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
  integrations: [Sentry.replayIntegration()],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1
};
