import { useEffect, useRef } from 'react';

import { PlaceholderV2 } from '@/components/v1/Placeholder';
import { Show } from '@/components/v1/Show';
import { EPlaceholderType } from '@/redux/api/constants';
import { classNames } from '@/utils/classNames';

import type { TAvailablePlaceholder } from './types';

interface AvailablePlaceholderItemProps {
  value: TAvailablePlaceholder;
  focused?: boolean;
  onClick?: () => void;
}

export const AvailablePlaceholderItem = (props: AvailablePlaceholderItemProps) => {
  const { value, focused, onClick } = props;
  const { placeholder, opening, closing, shortcut } = value;
  const color = placeholder.type === EPlaceholderType.MARKER ? 'blue' : 'orange';
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focused) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [focused]);

  return (
    <li
      ref={ref}
      role="menuitem"
      className={classNames(
        'flex cursor-pointer items-center gap-5 px-4 py-1 hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-200',
        { 'bg-white': !focused, 'bg-gray-100 ': focused }
      )}
      onClick={onClick}
    >
      <div className="flex-1 space-x-1 text-center">
        <Show
          when={opening || closing}
          fallback={
            <PlaceholderV2 color={color} tag="self-closing">
              {placeholder.label}
            </PlaceholderV2>
          }
        >
          <Show when={opening}>
            <PlaceholderV2 color={color} tag="opening">
              {placeholder.label}
            </PlaceholderV2>
          </Show>

          <Show when={closing}>
            <PlaceholderV2 color={color} tag="closing">
              {placeholder.label}
            </PlaceholderV2>
          </Show>
        </Show>
      </div>

      <div>
        <Show when={shortcut}>
          <span className="inline-flex h-4 w-4 items-center justify-center rounded-full bg-gray-300 text-2xs font-bold text-white">
            {shortcut}
          </span>
        </Show>
      </div>
    </li>
  );
};
