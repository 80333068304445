import { findIndex } from 'lodash';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import { StepperGroup } from '@/components/v2/StepperGroup';

import { CheckoutFlowStep } from '../constants';
import { useCheckoutSearchParams } from '../hooks';

const CheckoutFlowLayout = (props: CheckoutFlowLayoutProps) => {
  const { children } = props;

  const { t: tCheckout } = useTranslation('checkout');

  const location = useLocation();
  const [searchParams] = useCheckoutSearchParams();
  const { planCode, currency, billingFrequency } = searchParams;

  if (!planCode || !currency || !billingFrequency) {
    return <Redirect to="/settings/plans" />;
  }

  const itemId = pathToIdMap[location.pathname];
  const items = [
    { id: CheckoutFlowStep.BILLING, label: tCheckout('enterBillingDetails') },
    { id: CheckoutFlowStep.PAYMENT_METHOD, label: tCheckout('addPaymentMethod') }
  ];
  const current = findIndex(items, { id: itemId });

  return (
    <div className="space-y-20">
      <StepperGroup orientation="horizontal" items={items} current={current} className="mx-auto max-w-2xl" />

      <div className="mx-auto max-w-5xl">{children}</div>
    </div>
  );
};
CheckoutFlowLayout.displayName = 'CheckoutFlowLayout';

interface CheckoutFlowLayoutProps {
  children: ReactNode;
}

const pathToIdMap: Record<string, CheckoutFlowStep> = {
  '/checkout/billing': CheckoutFlowStep.BILLING,
  '/checkout/payment-method': CheckoutFlowStep.PAYMENT_METHOD
};

export { CheckoutFlowLayout };
export type { CheckoutFlowLayoutProps };
