import classNames from 'classnames';
import { some } from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { For } from '@/components/v2/For';
import { HintBox } from '@/components/v2/HintBox';
import RadioGroup from '@/components/v2/RadioGroup';
import { Show } from '@/components/v2/Show';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { Toggle } from '@/components/v2/Toggle';
import Tooltip from '@/components/v2/Tooltip';
import { MachineTranslationVendor } from '@/redux/api/constants';
import { LanguagePreTranslateOptionsDto } from '@/redux/api/types';
import { LaunchPreTranslateFormValues, MtOption } from '@/routes/PreTranslate/components/types';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

export const TranslationMtOption = (props: {
  languageId: number;
  preTranslateOptions: LanguagePreTranslateOptionsDto;
  disabled: boolean;
}) => {
  const { t } = useTranslation('pretranslate');
  const { currentAccount } = useCurrentAccount();
  const { mustCompleteOnboarding, hasConfirmedAnyDocument } = currentAccount.onboardingFlags;
  const isCompleted = mustCompleteOnboarding && hasConfirmedAnyDocument;

  const { languageId, preTranslateOptions, disabled } = props;
  const hasVendors = !!preTranslateOptions.mtVendorsAvailable?.length;

  const form = useFormContext<LaunchPreTranslateFormValues>();
  const mtChecked = useWatch({ control: form.control, name: `languages.${languageId}.mt` });

  const vendorLabels = {
    [MachineTranslationVendor.GOOGLE_TRANSLATE]: t('googleTranslate'),
    [MachineTranslationVendor.DEEPL]: t('deepl')
  };

  const handleCheckedChange = (value: boolean, callback: (value: boolean) => void) => {
    const newToggleState = !value;
    callback(newToggleState);

    if (!newToggleState) {
      form.setValue(`languages.${languageId}.mt`, false);
    }
    if (newToggleState) {
      form.setValue(`languages.${languageId}.tm`, true);
    }
  };

  return (
    <HintBox when={isCompleted}>
      <div className={classNames('space-y-5 rounded-lg border border-gray-200 bg-white p-6 shadow-sm')}>
        <Controller
          control={form.control}
          name={`languages.${languageId}.mt`}
          disabled={disabled || !hasVendors}
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex items-center gap-3">
              <Toggle
                size="sm"
                className="peer"
                checked={disabled ? false : value}
                defaultChecked={value}
                onCheckedChange={() => handleCheckedChange(value, onChange)}
                {...field}
              />

              <Text
                size="sm"
                weight="medium"
                color="primary"
                className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
              >
                {t('applyMachineTranslation')}
              </Text>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <AccessibleIcon
                    className="text-xl text-gray-600"
                    label="ri-information-fill"
                    icon="ri-information-line"
                  />
                </Tooltip.Trigger>
                <Tooltip.Content className="max-w-xxs">
                  <Text className="text-typography-inverse-primary" size="xs">
                    {t('translateAnySegmentsNotCoveredByTM')}
                  </Text>
                </Tooltip.Content>
              </Tooltip.Root>
            </label>
          )}
        />

        <Show when={hasVendors && mtChecked}>
          <div className="space-y-3">
            <Title size="sm">{t('chooseVendor')}</Title>

            <Controller
              control={form.control}
              name={`languages.${languageId}.mtVendor`}
              disabled={disabled || !mtChecked}
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroup.Root className="space-y-3" value={value} onValueChange={onChange} {...field}>
                  <For each={preTranslateOptions.mtVendorsAvailable}>
                    {(vendor) => (
                      <label key={vendor} className="flex items-center gap-3">
                        <RadioGroup.Item value={vendor} size="sm" />
                        <Text color="primary" size="sm">
                          {vendorLabels[vendor]}
                        </Text>
                      </label>
                    )}
                  </For>
                </RadioGroup.Root>
              )}
            />
          </div>
        </Show>
      </div>
    </HintBox>
  );
};

export const MtOptions = () => {
  const { t } = useTranslation('pretranslate');

  const form = useFormContext<LaunchPreTranslateFormValues>();
  const languages = useWatch({ control: form.control, name: 'languages' });
  const items = [
    { value: MtOption.MARK_AS_DRAFT, label: t('markAllAsDraftForFurtherReview') },
    { value: MtOption.MARK_AS_CONFIRMED, label: t('markAllAsConfirmed') }
  ];

  return (
    <Surface className="space-y-6 p-7">
      <Title size="sm">{t('machineTranslation')}</Title>

      <Controller
        control={form.control}
        name="mt"
        disabled={!some(languages, { mt: true })}
        render={({ field: { value, onChange, ...field } }) => (
          <RadioGroup.Root className="space-y-4" value={value} onValueChange={onChange} {...field}>
            <For each={items}>
              {(item) => (
                <label key={item.value} className="flex items-center gap-3">
                  <RadioGroup.Item value={item.value} size="sm" className="peer" />

                  <Text
                    color="primary"
                    size="sm"
                    className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
                  >
                    {item.label}
                  </Text>
                </label>
              )}
            </For>
          </RadioGroup.Root>
        )}
      />
    </Surface>
  );
};
