import { forwardRef } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Text } from '@/components/v2/Text';

interface ChipsProps {
  children: React.ReactNode;
  onClose: () => void;
}

export const Chip = forwardRef<HTMLDivElement, ChipsProps>(({ onClose, children }, forwardedRef) => {
  return (
    <div
      ref={forwardedRef}
      className="flex max-h-8 flex-row items-center gap-2 rounded-3xl border border-gray-200 bg-white px-3 py-1"
    >
      {typeof children === 'string' ? (
        <Text color="primary" size="sm" weight="semibold">
          {children}
        </Text>
      ) : (
        children
      )}
      <AccessibleIcon
        label="ri-close-line"
        icon="ri-close-line"
        className="ml-auto h-5 w-5 cursor-pointer text-gray-400"
        onClick={onClose}
      />
    </div>
  );
});

Chip.displayName = 'Chip';
