import { useContext } from 'react';

import { PusherContext } from '../PusherProvider';

export const usePusherContext = () => {
  const context = useContext(PusherContext);
  if (!context) {
    throw new Error('usePusherContext must be used within a PusherProvider');
  }
  return context;
};
