import { useForm } from 'react-hook-form';

import {
  FormControl,
  FormError,
  FormField,
  Form as FormRoot,
  FormSubmit,
  useFormContext,
  useFormFieldContext
} from './Form';

export type { FormControlProps, FormErrorProps, FormFieldProps, FormProps, FormSubmitProps } from './types';
export {
  FormRoot as Form,
  FormControl,
  FormError,
  FormField,
  FormSubmit,
  useForm,
  useFormContext,
  useFormFieldContext
};

const Form = {
  Root: FormRoot,
  Field: FormField,
  Control: FormControl,
  Error: FormError,
  Submit: FormSubmit,
  useForm,
  useContext: useFormContext,
  useFieldContext: useFormFieldContext
};

export default Form;
