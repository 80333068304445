import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_STATE } from '@/redux/slices/onboardingSlice/constants';
import { OnboardingState } from '@/redux/slices/onboardingSlice/types';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: INITIAL_STATE,
  reducers: {
    stashFile: (state, { payload }: PayloadAction<OnboardingState['stashDocument']>) => {
      state.stashDocument = { ...payload };
    },
    clearFilter: (state) => {
      state.stashDocument = {};
    }
  }
});

export const { clearFilter, stashFile } = onboardingSlice.actions;
