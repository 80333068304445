export enum EUploadingDocumentSteps {
  UPLOAD_FILE = 'upload-file',
  SET_SOURCE_LANGUAGE = 'set-source-language',
  SET_TARGET_LANGUAGE = 'set-target-language'
}

export interface UploadDocumentStep {
  id: string;
  path: string[];
  label?: string;
}

export interface UploadDocumentResponseDto {
  successfulUploads: UploadedStashedDocumentDto[];
  errors: UploadedDocumentWithErrorsDto[];
}

export interface UploadedStashedDocumentDto {
  documentStashedId: number;
  filename: string;
  internalFilename: string;
  size: number;
  hash: string;
  type: string;
}
export interface UploadedDocumentWithErrorsDto {
  filename: string;
  errors: string[];
  errorLinks?: string;
}
