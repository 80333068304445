import { forwardRef } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { fileContainerVariant } from '@/components/v2/FileContainer/styles';
import { FileContainerProps } from '@/components/v2/FileContainer/types';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { classNames } from '@/utils/classNames';

export const FileContainer = forwardRef<HTMLDivElement, FileContainerProps>((props, ref) => {
  const { id, fileName, fileSize, status, percent, onClose, children, size, helperMessage, className, ...rest } = props;

  const renderItem = (status: FileContainerProps['status']) => {
    switch (status) {
      case 'active':
        return (
          <div className="animate-spin rounded-full bg-gradient-to-tr from-white via-blue-100 to-blue-500 p-0.5">
            <div className="rounded-full bg-white">
              <div className="h-4 w-4 rounded-full"></div>
            </div>
          </div>
        );
      case 'completed':
        return <AccessibleIcon icon="ri-file-3-fill" label="ri-file-3-fill" className="text-xl text-alpha-200" />;
      case 'error':
        return (
          <AccessibleIcon
            icon="ri-close-circle-fill"
            label="ri-close-circle-fill"
            className="text-xl text-states-negative-active"
          />
        );
      case 'warning':
        return (
          <AccessibleIcon icon="ri-alert-fill" label="ri-alert-fill" className="text-xl text-states-alert-hover" />
        );
    }
  };

  return (
    <div ref={ref} className={classNames(fileContainerVariant({ status }), className)} {...rest}>
      <div>
        <div className="flex flex-row items-center">
          {renderItem(status)}
          <div className="flex flex-1 flex-row items-baseline overflow-hidden overflow-ellipsis whitespace-nowrap">
            <OverflowTooltip label={fileName}>
              <Title size={size === 'md' ? 'sm' : 'xs'} className="ml-2 mr-1 text-typography-primary" weight="medium">
                {fileName}
              </Title>
            </OverflowTooltip>

            <Text size="xs" className="text-typography-tertiary" weight="semibold">
              ({fileSize})
            </Text>
          </div>
          <button className="ml-auto cursor-pointer" onClick={() => onClose?.(id)}>
            <AccessibleIcon icon="ri-close-line" label="ri-close-line" className="text-xl text-alpha-400" />
          </button>
        </div>
        {status === 'error' ? (
          <Text size="xs" className="break-words text-states-negative-active">
            {helperMessage}
          </Text>
        ) : (
          <div className="flex items-center gap-2">
            <div className="flex-1 rounded-full bg-gray-200">
              <div
                className="transition-width h-1 rounded-full bg-states-positive-activeLight duration-300 ease-in-out"
                style={{ width: `${percent}%` }}
              ></div>
            </div>
            <Text size="xs" className="ml-auto text-typography-primary">
              {`${percent}%`}
            </Text>
          </div>
        )}
      </div>
      {children}
    </div>
  );
});
FileContainer.displayName = 'FileContainer';
