import { useContext } from 'react';

import { SentryContext } from './SentryProvider';

export const useSentryContext = () => {
  const context = useContext(SentryContext);
  if (context === undefined) {
    throw new Error('`useSentryContext` must be used within `SentryProvider`');
  }
  return context;
};
