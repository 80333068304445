import {
  COLUMN_SIZE_AUTO,
  DEFAULT_EXPANDED,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  LOADING_DATA_TOKEN
} from './constants';
import {
  createDataTableColumnHelper,
  DataTable,
  DataTableBody,
  DataTableCheckbox,
  DataTableContent,
  DataTableHeader,
  DataTableHeaderCheckbox,
  DataTablePageSizeSelector,
  DataTablePagination,
  useDataTableCellContext,
  useDataTableContext,
  useDataTableRowContext
} from './DataTable';

const DataTableObject = {
  Root: DataTable,
  /** @deprecated Use `Content` instead */
  Container: DataTableContent,
  Content: DataTableContent,
  Body: DataTableBody,
  Checkbox: DataTableCheckbox,
  Header: DataTableHeader,
  HeaderCheckbox: DataTableHeaderCheckbox,
  Pagination: DataTablePagination,
  PageSizeSelector: DataTablePageSizeSelector,
  useCellContext: useDataTableCellContext,
  useContext: useDataTableContext,
  useRowContext: useDataTableRowContext,
  createColumnHelper: createDataTableColumnHelper
};

export type {
  ColumnFiltersState,
  DataTableBodyProps,
  DataTableCellContext,
  DataTableCheckboxProps,
  DataTableColumnDef,
  DataTableContentProps,
  DataTableContext,
  DataTableHeaderProps,
  DataTablePaginationProps,
  DataTableProps,
  DataTableRowContext,
  DataTableSelectProps,
  LoadingDataToken,
  RowSelectionState,
  SortingState,
  VisibilityState
} from './types';
export {
  DataTable,
  DataTableBody,
  DataTableCheckbox,
  DataTableContent,
  DataTableHeader,
  DataTableHeaderCheckbox,
  DataTablePageSizeSelector,
  DataTablePagination,
  useDataTableCellContext,
  useDataTableContext,
  useDataTableRowContext,
  createDataTableColumnHelper,
  COLUMN_SIZE_AUTO,
  DEFAULT_EXPANDED,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  LOADING_DATA_TOKEN
};

export default DataTableObject;
