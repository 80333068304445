import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import Breadcrumb from '@/components/v2/Breadcrumb';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetGlossaryByIdQuery } from '@/redux/api/glossaryApi';
import { GlossaryDetailHeaderSkeleton } from '@/routes/GlossaryDetail/components/GlossaryDetailHeader/components/GlossaryDetailHeaderSkeleton';
import { assert } from '@/utils/assert';

interface GlossaryDetailHeaderProps {
  glossaryId: number;
}

export const GlossaryDetailHeader = ({ glossaryId }: GlossaryDetailHeaderProps) => {
  const { t: tGlossary } = useTranslation('glossary');
  const { data, isLoading, isUninitialized } = useGetGlossaryByIdQuery({ id: glossaryId });

  const { currentAccount } = useCurrentAccount();
  const canEditGlossaries = !currentAccount?.subscriptionInfo.isTranslator;

  if (isLoading || isUninitialized) {
    return <GlossaryDetailHeaderSkeleton />;
  }

  assert(data, 'Glossary data not found');

  const { sourceLanguage, targetLanguage } = data.glossary;
  const title = `${sourceLanguage.name} ${tGlossary('to')} ${targetLanguage.name}`;

  const downloadGlossary = () => {
    window.open(`${APP_URL}/api/v1/glossary/${glossaryId}/export`);
  };

  return (
    <div className="flex flex-col gap-1">
      <Breadcrumb.Root size="sm">
        <Breadcrumb.List>
          <Breadcrumb.Item>
            <Breadcrumb.Link asChild>
              <Link to={`/glossaries`}>{tGlossary('glossary')}</Link>
            </Breadcrumb.Link>
          </Breadcrumb.Item>
          <Breadcrumb.Separator />
          <Breadcrumb.Item>
            <Breadcrumb.Link active asChild>
              <Link to={`/glossaries/show/${glossaryId}`}>{title}</Link>
            </Breadcrumb.Link>
          </Breadcrumb.Item>
        </Breadcrumb.List>
      </Breadcrumb.Root>
      <div className="flex flex-row items-center justify-between">
        <OverflowTooltip label={title}>
          <Text size="xl" weight="bold" color="primary" className="line-clamp-1">
            {title}
          </Text>
        </OverflowTooltip>
        <div className="flex flex-row gap-4">
          <Button variant="solid" color="secondary" onClick={downloadGlossary}>
            <AccessibleIcon icon="ri-download-2-line" label="download-icon" />
            {tGlossary('download')}
          </Button>

          <Show when={canEditGlossaries}>
            <RequireSubscription>
              <ButtonLink href={`/glossaries/show/${glossaryId}/upload`} variant="solid" color="secondary">
                <AccessibleIcon icon="ri-upload-2-line" label="upload-icon" />
                {tGlossary('uploadGlossary')}
              </ButtonLink>
            </RequireSubscription>

            <RequireSubscription>
              <ButtonLink href={`/glossaries/show/${glossaryId}/new-entry`} variant="solid" color="primary">
                <AccessibleIcon icon="ri-add-fill" label="add-icon" />
                {tGlossary('createSingleEntry')}
              </ButtonLink>
            </RequireSubscription>
          </Show>
        </div>
      </div>
    </div>
  );
};
