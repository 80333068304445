import { Accept } from 'react-dropzone';

export const ACCEPT: Accept = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/html': ['.html', '.htm'],
  'application/json': ['.json'],
  'application/xml': ['.xml'],
  'text/plain': ['.txt'],
  'application/vnd.adobe.indesign-idml-package': ['.idml'],
  'application/xliff+xml': ['.xliff'],
  'application/x-xliff+xml': ['.xlf'],
  'application/x-subrip': ['.srt']
};

// 100mb in bytes
export const MAX_SIZE = 104857600;
export const MAX_ONE_FILES = 1;
// export const MAX_TWENTY_FILES = 20;
