import classNames from 'classnames';
import { ContentState, EditorState } from 'draft-js';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Mentions } from '@/components/v1/Mentions';
import { Spinner } from '@/components/v1/Spinner';
import { PaperPlaneIcon } from '@/icons/solid';

import type { ICommentInputProps } from './types';
import { convertEditorStateToCommentContent } from './utils';

export const CommentInput: FunctionComponent<ICommentInputProps> = ({
  isCreating,
  isCreated,
  disabled,
  suggestions,
  onCreate,
  onMarkRead
}) => {
  const { t } = useTranslation('documentLanguageComments');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const content = useMemo(() => convertEditorStateToCommentContent(editorState), [editorState]);
  const createButtonDisabled = !content.text || isCreating;

  // Comment created effect, clear editor state
  useEffect(() => {
    if (!isCreating && isCreated) {
      setEditorState((prevEditorState) => {
        const editorState = EditorState.push(prevEditorState, ContentState.createFromText(''), 'remove-range');
        return EditorState.moveFocusToEnd(editorState);
      });
    }
  }, [isCreating, isCreated, setEditorState]);

  return (
    <Spinner enabled={isCreating}>
      <Mentions
        editorState={editorState}
        suggestions={suggestions}
        readOnly={disabled || isCreating}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        suffix={
          <button
            disabled={createButtonDisabled}
            className={classNames('text-blue-500 disabled:cursor-not-allowed disabled:opacity-50', {
              'hover:text-blue-600': !createButtonDisabled
            })}
            onClick={() => {
              if (content.text && !isCreating) {
                onCreate(content);
              }
            }}
          >
            <PaperPlaneIcon />
          </button>
        }
        placeholder={t('addAComment')}
        onChange={setEditorState}
        onFocus={onMarkRead}
        onReturn={() => {
          if (content.text && !isCreating) {
            onCreate(content);
          }
        }}
      />
    </Spinner>
  );
};
