import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { to } from '@/utils/awaitToJs';
import { useChangeDocumentTranslatorsMutation, useGetTranslatorsQuery, useInviteTranslatorMutation } from '@/redux/api';
import { Spinner } from '@/components/v1/Spinner';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { TranslatorSearchBox } from '@/routes/Document/components/ChangeTranslatorModal/components/TranslatorSearchBox';
import { TranslatorItem } from '@/routes/Document/components/ChangeTranslatorModal/components/TranslatorItem';
import { PartialTranslatorDto } from '@/routes/Document/types';
import { removeDuplicateTranslators } from '@/utils/translators';

export interface ChangeTranslatorModalProps extends ModalProps {
  languageId: number;
  documentId: number;
  languageName: string;
  translators: PartialTranslatorDto[];
  onConfirm?: () => void;
}

interface ChangeTranslatorForm {
  translators: PartialTranslatorDto[] & { key: string };
}

export const ChangeTranslatorModal = (props: ChangeTranslatorModalProps) => {
  const { languageName, languageId, documentId, translators, children, onConfirm, ...modalProps } = props;

  const { t: tDocument } = useTranslation('document');
  const { currentAccount } = useCurrentAccount();
  const { data, isLoading, isUninitialized } = useGetTranslatorsQuery({ filterByLanguageId: languageId });
  const [inviteTranslator] = useInviteTranslatorMutation();

  const translatorList = useMemo(() => {
    const me: PartialTranslatorDto = {
      id: currentAccount.id,
      avatarUrl: currentAccount.avatarUrl || '',
      email: currentAccount.email,
      fullName: `${currentAccount.fullName} (${tDocument('you')})`,
      isNotAccepted: false
    };
    if (data === undefined || isEmpty(data?.translators)) {
      return [me];
    }
    return removeDuplicateTranslators([...data.translators, me]);
  }, [currentAccount, data, tDocument]);

  const { control, handleSubmit, watch } = useForm<ChangeTranslatorForm>({
    defaultValues: {
      translators: translators
    }
  });
  const { fields, remove, replace, append } = useFieldArray<ChangeTranslatorForm, 'translators', 'key'>({
    name: 'translators',
    control,
    keyName: 'key'
  });

  const [changeTranslators, { isLoading: isChangeTranslatorLoading, isSuccess }] =
    useChangeDocumentTranslatorsMutation();

  const onSubmit: SubmitHandler<ChangeTranslatorForm> = async (data, e) => {
    e?.preventDefault();

    const translatorAccountIds = data.translators.map((t) => t.id);
    const [error] = await to(changeTranslators({ documentId, languageId, translatorAccountIds }).unwrap());
    if (!error) {
      onConfirm?.();
    }
  };

  const handleOnDelete = (index: number) => {
    remove(index);
  };

  const handleOnAddTranslators = (items?: PartialTranslatorDto[]) => {
    if (items) {
      replace(items);
    }
  };

  const handleOnAddNewTranslator = async (languageId: number, email?: string) => {
    if (email) {
      const [, data] = await to(inviteTranslator({ email, languageId }).unwrap());

      if (data?.translatorAccountId) {
        const pendingTranslator: PartialTranslatorDto = {
          id: data.translatorAccountId,
          email,
          fullName: '',
          avatarUrl: ''
        };
        append(pendingTranslator);
      }
    }
  };

  const watchTranslators = watch('translators');
  const disabled = isLoading || isChangeTranslatorLoading || isSuccess || isEmpty(watchTranslators);
  const loading = isUninitialized || isLoading;

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      {/* prevent closing modal when click outside */}
      <Modal.Content size="lg" className="bg-gray-50" onInteractOutside={(e) => e.preventDefault()}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-7">
          <Modal.Header>
            <Modal.Title size="lg">{tDocument('changeTranslatorForThisLanguage')}</Modal.Title>
            <Modal.Description size="md">
              {tDocument('youAreChangingTranslatorsFor', { language: languageName })}
            </Modal.Description>
          </Modal.Header>

          <div className="flex flex-col gap-3">
            <TranslatorSearchBox
              options={translatorList}
              defaultValues={watchTranslators}
              onAddTranslators={handleOnAddTranslators}
              onAddNewTranslator={(email) => handleOnAddNewTranslator(languageId, email)}
            />

            <div className="min-h-32 flex max-h-56 flex-col gap-4 overflow-y-scroll rounded-lg border border-gray-200 bg-white p-3">
              <Show
                when={!loading}
                fallback={
                  <div className="flex h-32 justify-center">
                    <Spinner size="lg" />
                  </div>
                }
              >
                <Show
                  when={isNotEmpty(fields)}
                  fallback={
                    <div className="flex h-32 flex-col items-center justify-center gap-2 rounded-lg px-11 py-3 text-center">
                      <AccessibleIcon
                        label="ri-search-line"
                        icon="ri-search-line"
                        className="text-3xl text-typography-tertiary"
                      />
                      <Text size="sm" color="tertiary" weight="bold">
                        {tDocument('searchTranslator')}
                      </Text>
                      <Text size="sm" color="tertiary" weight="regular">
                        {tDocument('typeTheTranslatorNameInTheSearchBarToAdd')}
                      </Text>
                    </div>
                  }
                >
                  {fields.map((item, index) => (
                    <Controller
                      key={item.key}
                      name={`translators.${index}.id`}
                      control={control}
                      render={() => <TranslatorItem translator={item} onDelete={() => handleOnDelete(index)} />}
                    />
                  ))}
                </Show>
              </Show>
            </div>
          </div>

          <Modal.Footer className="flex w-full flex-row items-center justify-center gap-9">
            <Modal.Close>
              <Button color="secondary" variant="surface" className="w-full">
                {tDocument('cancel')}
              </Button>
            </Modal.Close>

            <Button color="primary" type="submit" disabled={disabled} className="w-full">
              {tDocument('confirmTranslators')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
