import { useCallback, useEffect, useState } from 'react';

export const useWindowScroll = () => {
  const [scroll, setScroll] = useState({ x: window.scrollX, y: window.scrollY });

  useEffect(() => {
    const handleScroll = () => {
      setScroll((scroll) => {
        if (scroll.x !== window.scrollX || scroll.y !== window.scrollY) {
          return { x: window.scrollX, y: window.scrollY };
        }
        return scroll;
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScroll]);

  const scrollToTop = useCallback((behavior: ScrollBehavior = 'smooth') => {
    window.scrollTo({ top: 0, behavior });
  }, []);

  const scrollTo = useCallback((options: ScrollToOptions = { behavior: 'smooth' }) => {
    window.scrollTo(options);
  }, []);

  return { scroll, scrollToTop, scrollTo };
};
