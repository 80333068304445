import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

export const SavedViewEmptySetModal = (props: ModalProps) => {
  const { children, ...modalProps } = props;

  const { t: documentList } = useTranslation('documentList');

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="lg">
        <Modal.Header className="text-center">
          <Modal.Title size="lg" weight="semibold" className="text-left">
            {documentList('savedViews')}
          </Modal.Title>
          <AccessibleIcon
            icon="ri-save-line"
            label="ri-save-line"
            className="self-center text-3xl text-typography-tertiary"
          />
          <Modal.Description size="md" weight="bold" color="tertiary">
            {documentList('thereAreNoSavedViewsYet')}
          </Modal.Description>
          <Modal.Description size="md" weight="regular" color="tertiary">
            {documentList('setSomeFiltersAndClickSaveView')}
          </Modal.Description>
        </Modal.Header>

        <Modal.Footer className="flex flex-row gap-4">
          <Modal.Close>
            <Button color="primary" className="w-full">
              {documentList('gotIt')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
