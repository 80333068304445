import * as Sentry from '@sentry/react';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonLink } from '@/components/v1/Button';
import { Jumbo } from '@/components/v1/Jumbo';
import { APP_URL } from '@/config/urls';
import { isRtkQueryRejection } from '@/utils/sentry';

import type { ISentryContext, ISentryProviderProps } from './types';

export const SentryContext = createContext<ISentryContext | undefined>(undefined);

export const SentryProvider = (props: ISentryProviderProps) => {
  const { init, options, children } = props;

  const { t } = useTranslation('sentry');
  const [isUnhandledError, setIsUnhandledError] = useState(false);

  const beforeSend = useCallback(
    (event: Sentry.Event, hint: Sentry.EventHint) => {
      if (isRtkQueryRejection(event, hint)) {
        return null;
      }

      Sentry.showReportDialog({
        eventId: event.event_id,
        title: t('somethingWentWrong'),
        subtitle: t('youSeeingThisScreenBecauseAnErrorOccurred'),
        subtitle2: t('ourTeamHasBeenNotifiedAndYouCouldHelpUs'),
        onLoad: () => {
          setIsUnhandledError(true);
        }
      });

      return event;
    },
    [t, setIsUnhandledError]
  );

  useEffect(() => {
    if (!init) {
      return;
    }

    Sentry.init({ ...options, beforeSend });
  }, [init, options, beforeSend]);

  if (isUnhandledError) {
    return (
      <Jumbo.Fullscreen>
        {{
          header: t('sorryForTheError'),
          content: t('ourTeamHasBeenNotifiedWeWillFixTheIssue'),
          footer: (
            <div className="space-x-2">
              <Button
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t('reload')}
              </Button>
              <ButtonLink btnType="outline" href={`${APP_URL}/documents`} rel="noreferrer">
                {t('goBackToDocumentList')}
              </ButtonLink>
            </div>
          )
        }}
      </Jumbo.Fullscreen>
    );
  }

  return <SentryContext.Provider value={{ sentry: Sentry }}>{children}</SentryContext.Provider>;
};
SentryProvider.displayName = 'SentryProvider';
