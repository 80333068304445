import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';

import { Button } from '@/components/v2/Button';
import Modal from '@/components/v2/Modal';
import Form from '@/components/v2/Form';
import { Label } from '@/components/v2/Label';
import { Input } from '@/components/v2/Input';
import { Checkbox } from '@/components/v2/Checkbox';
import { Text } from '@/components/v2/Text';
import { useDocumentListSearchParams } from '@/routes/DocumentList/hooks/useDocumentListSearchParams';
import { useDocumentListParams } from '@/routes/DocumentList/hooks/useDocumentListParams';
import { useSaveViewMutation } from '@/redux/api/documentListApi';
import { to } from '@/utils/awaitToJs';
import { CreateSavedViewQueryArgs } from '@/redux/api/types';

interface SaveViewContentProps {
  onConfirm?: (id: number) => void;
}

export const SaveViewContent = ({ onConfirm }: SaveViewContentProps) => {
  const { t: tDocumentList } = useTranslation('documentList');

  const [saveView, { isLoading }] = useSaveViewMutation();
  const [documentListSearchParams] = useDocumentListSearchParams();
  const { folderId } = useDocumentListParams();

  const formSchema: ZodType = z.object({
    viewName: z
      .string()
      .trim()
      .min(1, { message: tDocumentList('thisFieldIsRequired') }),
    showExpandedView: z.boolean().optional()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { viewName, showExpandedView } = data;
    const {
      searchTerm,
      filterByOwnerAccountIds,
      filterByStatus,
      filterByTargetLanguageIds,
      filterByTranslatorAccountIds
    } = documentListSearchParams;

    const obj: CreateSavedViewQueryArgs = {
      name: viewName,
      showExpandedView: showExpandedView,
      folderId,
      searchTerm,
      status: filterByStatus,
      translatorIds: filterByTranslatorAccountIds,
      ownerIds: filterByOwnerAccountIds,
      languageIds: filterByTargetLanguageIds
    };

    const [err, res] = await to(saveView({ ...obj }).unwrap());

    if (!err) {
      // on success close modal and disable save view button
      onConfirm?.(res.id);
    }
  };

  const disabled = !form.formState.isValid && isLoading;

  return (
    <Form.Root {...form} onSubmit={onSubmit} className="flex flex-col gap-7" devTool>
      <Modal.Header>
        <Modal.Title>{tDocumentList('saveView')}</Modal.Title>
        <Modal.Description>{tDocumentList('saveYourCurrentLocationAndFilters')}</Modal.Description>
      </Modal.Header>

      <Form.Field name="viewName" rules={{ required: true }}>
        <Label htmlFor="viewName" text={tDocumentList('viewName')} isRequired />
        <Form.Control>{({ field }) => <Input {...field} id="viewName" />}</Form.Control>
        <Form.Error key="viewName" />
      </Form.Field>

      <Form.Field name="showExpandedView">
        <Form.Control>
          {({ field }) => (
            <div className="flex flex-row gap-3">
              <Checkbox {...field} checked={field.value} onCheckedChange={field.onChange} id="showExpandedView" />
              <Text
                size="sm"
                weight="medium"
                color="primary"
                className="hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
                asChild
              >
                <label htmlFor="showExpandedView">{tDocumentList('saveViewWithAllRowsExpanded')}</label>
              </Text>
            </div>
          )}
        </Form.Control>
        <Form.Error />
      </Form.Field>

      <Modal.Footer>
        <Button color="primary" type="submit" disabled={disabled}>
          {tDocumentList('saveView')}
        </Button>
        <Modal.Close>
          <Button color="secondary" variant="surface">
            {tDocumentList('cancel')}
          </Button>
        </Modal.Close>
      </Modal.Footer>
    </Form.Root>
  );
};
