/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */

!(
  function (e: any, t: any, n: any) {
    function a() {
      const e = t.getElementsByTagName('script')[0],
        n = t.createElement('script');
      (n.type = 'text/javascript'),
        (n.async = !0),
        (n.src = 'https://beacon-v2.helpscout.net'),
        e.parentNode.insertBefore(n, e);
    }
    if (
      ((e.Beacon = n =
        function (t: any, n: any, a: any) {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a });
        }),
      (n.readyQueue = []),
      'complete' === t.readyState)
    )
      return a();
    e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
  } as any
)(window, document, (window as any).Beacon || function () {});

export const Beacon = (command: string, payload?: unknown) => {
  (window as any).Beacon(command, payload);
};
