import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { checkbox, checkboxIndicator } from './styles';
import type { CheckboxElement, CheckboxProps } from './types';

const Checkbox = forwardRef<CheckboxElement, CheckboxProps>(({ checked, className, size, ...props }, ref) => (
  <CheckboxPrimitive.Root {...props} checked={checked} className={classNames(checkbox({ size }), className)} ref={ref}>
    <CheckboxPrimitive.Indicator className={checkboxIndicator({ size })}>
      <i className="ri-check-line" data-checked />
      <i className="ri-subtract-line" data-indeterminate />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = 'Checkbox';

export { Checkbox };
