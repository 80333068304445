import { cva } from 'cva';

export const selectTrigger = cva(
  'box-border inline-flex w-full place-content-between items-center gap-2 bg-white px-5 text-base font-medium text-typography-primary',
  {
    variants: {
      bordered: {
        false: '',
        true: 'rounded-md border border-gray-200 hover:ring-2 hover:ring-gray-700 focus:border-transparent focus:ring-2 focus:ring-primary-selected'
      },
      size: {
        sm: 'h-9',
        md: 'h-11'
      }
    },
    defaultVariants: {
      bordered: false,
      size: 'md'
    }
  }
);
