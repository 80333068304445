import { Tabs as TabsRoot, TabsContent, TabsList, TabsTrigger } from './Tabs';

export type { TabsContentProps, TabsListProps, TabsProps, TabsTriggerProps } from './types';
export { TabsRoot as Tabs, TabsList, TabsTrigger, TabsContent };

const Tabs = {
  Root: TabsRoot,
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsContent
};
export default Tabs;
