import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Button } from '@/components/v2/Button';
import Modal from '@/components/v2/Modal';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import InlineMessage from '@/components/v2/InlineMessage';
import { TextArea } from '@/components/v2/TextArea';
import { useAskLanguageUnlockMutation } from '@/redux/api';
import { AskToUnlockTranslationModalProps } from '@/redux/slices/modalsSlice/types/props';

// new version, it will replace "AskUnlockTranslationModal" used in WebEditor
export const AskToUnlockTranslationModal = (props: AskToUnlockTranslationModalProps) => {
  const { documentId, languageId, ...modalProps } = props;
  const { t: tModals } = useTranslation('modals');

  const [notes, setNotes] = useState('');
  const [changeLanguageStatus, { isLoading }] = useAskLanguageUnlockMutation();

  const handleConfirm = async () => {
    await changeLanguageStatus({
      documentId,
      languageId,
      body: { notes }
    });
    modalProps?.onOpenChange?.(false);
  };

  const disable = isLoading;

  return (
    <Modal.Root {...modalProps}>
      <Modal.Content size="lg" className="gap-6">
        <Modal.Header>
          <AccessibleIcon
            icon="ri-information-2-fill"
            label="ri-information-2-fill"
            className="self-start text-4xl text-primary-active"
          />
          <Modal.Title size="lg">{tModals('askUnlockDocumentTranslation')}</Modal.Title>
          <Modal.Description size="md">{tModals('translationLockedYouCanUseThisForm')}</Modal.Description>
        </Modal.Header>

        <TextArea
          size="lg"
          placeholder={tModals('addNoteForDocumentOwner')}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />

        <InlineMessage.Root orientation="horizontal" type="informational">
          <InlineMessage.Title className="text-blue-800">
            {tModals('unlockingDocumentWillMakeEditable')}
          </InlineMessage.Title>
        </InlineMessage.Root>
        <Modal.Footer className="flex flex-row gap-4">
          <Modal.Close>
            <Button color="secondary" variant="surface" className="w-full">
              {tModals('close')}
            </Button>
          </Modal.Close>
          <Button color="primary" className="w-full" onClick={handleConfirm} disabled={disable}>
            {tModals('askUnlockTranslation')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
