import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import AppLayout from '@/components/layouts/AppLayout';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Navbar } from '@/components/v2/Navbar';
import { Elements } from '@/libs/stripe';

const CheckoutLayout = (props: CheckoutLayoutProps) => {
  const { children } = props;

  const { t: tCheckout } = useTranslation('checkout');

  return (
    <AppLayout.Root>
      <Navbar sticky>
        <div className="ml-7 flex flex-1 justify-end">
          <ButtonLink color="secondary" href="/settings/plans">
            {tCheckout('close')}
          </ButtonLink>
        </div>
      </Navbar>

      <Elements>
        <AppLayout.Content>{children}</AppLayout.Content>
      </Elements>
    </AppLayout.Root>
  );
};
CheckoutLayout.displayName = 'CheckoutLayout';

interface CheckoutLayoutProps {
  children: ReactNode;
}

export { CheckoutLayout };
export type { CheckoutLayoutProps };
