import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { APP_URL } from '@/config/urls';

import { selectSocketId } from '../slices/pusherSlice/selectors';
import type { TAppState } from '../store/types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

// https://app.swaggerhub.com/apis/Redokun-Srls/redokun

// this exists only for the Upload feature via vanilla js, remove when able to do it via RTK
// @ts-expect-error: TS 5 migration error
export function getFetchConfig(getState?): RequestInit {
  const headers = new Headers();
  if (getState) {
    const socketId = selectSocketId(getState() as TAppState);

    // Pusher socket ID
    // https://pusher.com/docs/channels/server_api/excluding-event-recipients/
    if (socketId) {
      headers.set('X-Pusher-SocketId', socketId);
    }
  }

  return {
    headers,
    credentials: 'include'
  };
}

const FIFTEEN_SECONDS = 15_000;
export const FIVE_MINUTES = 300_000;

export const redokunApi = createApi({
  reducerPath: 'redokunApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${APP_URL}/api/v1`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const fetchConfig = getFetchConfig(getState);

      // @ts-expect-error: TS 5 migration error
      headers = fetchConfig.headers;
      return headers;
    },
    timeout: FIFTEEN_SECONDS
  }),
  endpoints: () => ({}),
  // Setting keepUnusedDataFor to 0 deletes unused cached data immediately
  // If it's going to cause issues, we should use tags to clear cached data on demand
  // https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#reducing-subscription-time-with-keepunuseddatafor
  keepUnusedDataFor: 0,
  // refetch feature set to false, it's used only in the web editor
  refetchOnReconnect: false,
  tagTypes: [
    'bootstrapData',
    'currentAccount',
    'folders',
    'documentTranslatedComment',
    'mt',
    'notifications',
    'paragraphComment',
    'tmSuggestions',
    'translationList',
    'documentDetail',
    'documentList',
    'translators',
    'operationStatus',
    'team',
    'languages',
    'billing',
    'country',
    'plans',
    'glossary',
    'glossaryEntries',
    'translationMemory',
    'saved-views'
  ]
});

export const redokunApiUtil = {
  ...redokunApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(redokunApi.util.updateQueryData)
};
