import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './constants';
import { setSocketIdReducer } from './utils';

export const pusherSlice = createSlice({
  name: 'pusher',
  initialState: INITIAL_STATE,
  reducers: {
    setSocketId: {
      prepare: (id: string | null) => ({ payload: id }),
      reducer: setSocketIdReducer
    }
  }
});

export const { setSocketId } = pusherSlice.actions;
