import classNames from 'classnames';
import { forwardRef } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import type { TInputProps } from './types';

export const Input = forwardRef<HTMLInputElement, TInputProps>(
  ({ size = 'md', prefix, suffix, className, style, onChangeValue, onChange, ...htmlInputProps }, ref) => {
    const textSizeClassName = classNames({
      'text-xs': size === 'sm',
      'text-sm': size === 'md'
    });

    return (
      <span
        className={classNames(
          'relative inline-flex items-center rounded border border-gray-300 bg-white px-2 py-0.5 text-gray-800 ring-blue-600 focus-within:ring-2',
          textSizeClassName,
          className
        )}
        style={style}
      >
        {isNonNullable(prefix) && <span className="mr-2">{prefix}</span>}

        <input
          type="text"
          className={classNames(
            'flex-1 border-none bg-transparent p-0 leading-none placeholder-gray-500 focus:ring-0',
            textSizeClassName
          )}
          onChange={
            onChange || onChangeValue
              ? (event) => {
                  onChange?.(event);
                  onChangeValue?.(event.target.value);
                }
              : undefined
          }
          {...htmlInputProps}
          ref={ref}
        />

        {isNonNullable(suffix) && <span className="ml-2">{suffix}</span>}
      </span>
    );
  }
);
Input.displayName = 'Input';
