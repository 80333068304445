import { cva } from 'cva';

export const comboboxTrigger = cva(
  'box-border inline-flex h-11 w-full place-content-between items-center gap-2 bg-white px-5 py-2 text-base font-medium text-typography-primary',
  {
    variants: {
      bordered: {
        true: 'rounded-md border border-gray-200 hover:ring-2 hover:ring-gray-700 focus:border-transparent focus:ring-2 focus:ring-primary-selected'
      }
    }
  }
);
