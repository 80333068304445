import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetOnboardingSurveyInfoQuery } from '@/redux/api';
import { useGetPlansQuery } from '@/redux/api/plansApi';
import { BillingFrequencyEnum, CurrencyEnum } from '@/redux/api/types';

import { FAQSection } from './components/FAQSection';
import { PlansOptions } from './components/PlansOptions';
import { PricingTable } from './components/PricingTable';

export const SettingsPlans: React.FC = () => {
  const { t: tPlans } = useTranslation('plans');

  const { data: plansData, isLoading: plansIsLoading, isUninitialized: plansIsUninitialized } = useGetPlansQuery();
  const {
    data: bootstrapData,
    isLoading: bootstrapDataIsLoading,
    isUninitialized: bootstrapDataIsUninitialized
  } = useGetOnboardingSurveyInfoQuery();

  const { currentAccount } = useCurrentAccount();

  const [frequency, setFrequency] = useState<BillingFrequencyEnum>(BillingFrequencyEnum.YEARLY);
  const [currency, setCurrency] = useState<CurrencyEnum>(CurrencyEnum.USD);

  useEffect(() => {
    if (bootstrapData?.defaultCurrency) {
      setCurrency(bootstrapData.defaultCurrency);
    }
  }, [bootstrapData?.defaultCurrency]);

  if (plansIsUninitialized || bootstrapDataIsUninitialized || plansIsLoading || bootstrapDataIsLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tPlans('settingsPlans')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tPlans('plans')}
          </Text>
        </div>

        <Show
          when={!plansIsLoading}
          fallback={
            <div className="flex w-full justify-center py-12">
              <Spinner size="lg" />
            </div>
          }
        >
          <div className="mt-6 grid grid-cols-1 gap-6">
            <PlansOptions frequencyState={{ frequency, setFrequency }} currencyState={{ currency, setCurrency }} />
            <PricingTable
              plansData={plansData}
              currency={currency}
              frequency={frequency}
              currentAccount={currentAccount}
            />
            <div>
              <Text color="tertiary" size="sm">
                {tPlans('importUpTo30000TMRecordsText')}
              </Text>
            </div>
            <FAQSection />
          </div>
        </Show>
      </div>
    </div>
  );
};
