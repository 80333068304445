import { createContext, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Loader } from '@/assets/loader.svg';
import { Show } from '@/components/v2/Show';
import { StepperGroup } from '@/components/v2/StepperGroup';
import { Text } from '@/components/v2/Text';
import { useAppSelector } from '@/redux/hooks';

import { Step, TranslationMemoryUploadContextType, TranslationMemoryUploadStateType } from './types';

export const TranslationMemoryUploadContext = createContext<TranslationMemoryUploadContextType>({
  translationMemoryUploadState: {
    cleanIdentical: true,
    trim: true,
    stashedFileId: undefined,
    sourceLanguageId: undefined,
    targetLanguageId: undefined
  },
  setTranslationMemoryUploadState: () => {}
});

export enum UploadTranslationMemorySteps {
  UPLOAD_FILE = 'upload-file',
  CONFIRM_IMPORT = 'confirm-import'
}

export const TranslationMemoryUploadLayout = ({ children }: { children?: ReactNode }) => {
  const { t: tTM } = useTranslation('translationMemory');
  const location = useLocation();

  const [translationMemoryUploadState, setTranslationMemoryUploadState] = useState<TranslationMemoryUploadStateType>(
    // Initial state of the context state.
    // The other fields must be undefined, they can't have a default.
    {
      cleanIdentical: true,
      trim: true
    }
  );

  const uploadSteps: Step[] = [
    {
      id: UploadTranslationMemorySteps.UPLOAD_FILE,
      path: `/translation-memories/upload`,
      label: tTM('uploadFile')
    },
    {
      id: UploadTranslationMemorySteps.CONFIRM_IMPORT,
      path: `/translation-memories/upload/confirm`,
      label: tTM('selectImportOptions')
    }
  ];

  const index = uploadSteps.map((s) => s.path).indexOf(location.pathname);

  const operation = useAppSelector((state) =>
    state.operations.queue.find((operation) => translationMemoryUploadState?.operationId === operation.operationId)
  );

  const stepper = (
    <div className="mx-auto mt-20 max-w-4xl">
      <div className="mb-20 mt-12 flex items-center justify-center">
        <StepperGroup orientation="horizontal" items={uploadSteps} current={index} className="w-160" />
      </div>
    </div>
  );

  const loader = (
    <div className="flex h-[360px] flex-1 flex-col items-center justify-center gap-4">
      <Loader className="animate-slow-spin" />
      <div className="text-center">
        <Text size="lg" weight="bold" color="tertiary">
          {tTM('weAreImportingYourTranslationMemories')}
        </Text>
        <Text size="md" color="tertiary">
          {tTM('thisWillTakeAMomentDontClose')}
        </Text>
        <Text size="md" color="tertiary">
          {tTM('dontCloseThisTabYet')}
        </Text>
      </div>
    </div>
  );

  return (
    <div>
      <Show when={!operation} fallback={loader}>
        {stepper}

        <TranslationMemoryUploadContext.Provider
          value={{
            translationMemoryUploadState,
            setTranslationMemoryUploadState
          }}
        >
          {children}
        </TranslationMemoryUploadContext.Provider>
      </Show>
    </div>
  );
};
