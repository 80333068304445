import { LanguageOption } from './types';

export const removeLanguageById = (id: string, selectedItems?: LanguageOption[]) => {
  if (selectedItems) {
    return selectedItems.filter((item) => {
      return item.id !== id;
    });
  } else {
    return [];
  }
};
