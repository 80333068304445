import { flatMap, head, isEmpty, last } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { HintBox } from '@/components/v2/HintBox';
import { useEffectOnceWhen } from '@/hooks/useEffectOnceWhen';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRouterParamsQuery } from '@/hooks/useRouterParamsQuery';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useCreateDocumentFromStashMutation, useGetLanguagesQuery } from '@/redux/api';
import { LanguagesDto } from '@/redux/api/types';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { clearStashedFiles, setCreatedDocumentIds } from '@/redux/slices/uploadDocumentsSlice';
import { selectStashDocuments } from '@/redux/slices/uploadDocumentsSlice/selector';
import { StashDocument } from '@/redux/slices/uploadDocumentsSlice/types';
import { LanguageGroup, LanguageOption } from '@/routes/Document/types';
import { createSourceLanguageGroups, flattenGroupOptions } from '@/routes/Document/utils';
import { uploadingDocumentSteps } from '@/routes/UploadDocuments/constants';
import { SourceLanguageItem } from '@/routes/UploadDocuments/steps/SetSourceLanguage/components';
import { DEFAULT_ENGLISH_LANG_ID } from '@/routes/UploadDocuments/steps/SetSourceLanguage/constants';
import { findLanguageById } from '@/routes/UploadDocuments/steps/SetSourceLanguage/utils';
import { EUploadingDocumentSteps } from '@/routes/UploadDocuments/types';
import { useUploadDocument } from '@/routes/UploadDocuments/UploadDocumentProvider';
import { to } from '@/utils/awaitToJs';
import { sanitizeUrl } from '@/utils/sanitizeUrl';

interface SetSourceLanguageForm {
  sources: StashDocument[];
}

export const SetSourceLanguage: React.FC = () => {
  const { t } = useTranslation('uploadDocuments');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentAccount } = useCurrentAccount();

  const query = useRouterParamsQuery();
  const folderId = query.get('folderId');
  const parsedFolderId = !isNaN(Number(folderId)) ? Number(folderId) : undefined;

  const [createDocument, { isLoading: isCreateDocumentLoading }] = useCreateDocumentFromStashMutation();
  const stashedDocuments = useAppSelector(selectStashDocuments);
  const { welcome, skippableTargetLanguage } = useUploadDocument('SetSourceLanguage');
  const isCompleted = currentAccount.onboardingFlags.hasConfirmedAnyDocument;

  //#region languages
  const { data, isLoading } = useGetLanguagesQuery();
  const [languages, setLanguages] = useState<LanguagesDto['all'] | undefined>(data?.all);

  const languageGroups: LanguageGroup[] = data ? createSourceLanguageGroups(data, t) : [];

  const [defaultSourceLanguageId, storeDefaultSourceLanguageId] = useLocalStorage<number | undefined>(
    'defaultSourceLanguageId',
    DEFAULT_ENGLISH_LANG_ID
  );

  const defaultValue = findLanguageById(languageGroups, defaultSourceLanguageId, DEFAULT_ENGLISH_LANG_ID);
  const options = flattenGroupOptions(languageGroups);

  useEffect(() => {
    if (data?.all) {
      setLanguages(data.all);
    }
  }, [data?.all]);

  //#endregion

  //#region form
  const form = useForm<SetSourceLanguageForm>();
  const { fields, update } = useFieldArray<SetSourceLanguageForm, 'sources', 'fileId'>({
    control: form.control,
    name: 'sources'
  });

  // this is needed to set the documents source language
  // since the data come from apis
  useEffectOnceWhen(() => {
    if (stashedDocuments && defaultValue) {
      const sources: StashDocument[] = stashedDocuments.map((doc) => ({
        fileId: doc.fileId,
        filename: doc.filename,
        importNumericData: false,
        treatContentAsHtml: doc.importNumericData,
        sourceLanguage: { ...defaultValue, id: parseInt(defaultValue.id) }
      }));

      form.setValue('sources', sources);
    }
  }, defaultValue !== undefined);

  const addLanguageToSource = (item: StashDocument, index: number, language?: LanguageOption | null) => {
    if (language) {
      update(index, {
        ...item,
        sourceLanguage: {
          ...language,
          id: parseInt(language.id)
        }
      });
    }
  };

  const onSubmit: SubmitHandler<SetSourceLanguageForm> = async (data, e) => {
    e?.preventDefault();

    const documentsStashed = flatMap(data.sources, (ds) => {
      if (ds.fileId !== undefined && ds.sourceLanguage?.id !== undefined) {
        return [
          {
            stashedFileId: ds.fileId,
            languageId: ds.sourceLanguage.id,
            importNumericData: !!ds.importNumericData,
            enableHtmlSubFilter: !!ds.treatContentAsHtml
          }
        ];
      }
      return [];
    });

    const [, res] = await to(createDocument({ documentsStashed }).unwrap());

    if (res && res.documentIds) {
      // store the last source language used
      const lastSourceLanguageId = last(documentsStashed)?.languageId ?? DEFAULT_ENGLISH_LANG_ID;
      storeDefaultSourceLanguageId(lastSourceLanguageId);

      // clear stashed files
      dispatch(clearStashedFiles());
      // save on redux the created documents ids
      dispatch(setCreatedDocumentIds(res.documentIds));

      const step = uploadingDocumentSteps.find((s) => s.id === EUploadingDocumentSteps.SET_TARGET_LANGUAGE);
      if (step) {
        if (welcome) {
          history.replace(sanitizeUrl(`/welcome/${step.path}`));
        } else {
          const path = parsedFolderId ? `${step.path}?folderId=${parsedFolderId}` : step.path;
          history.push(path);
        }
      }
    }
  };

  const onSaveAndExit: SubmitHandler<SetSourceLanguageForm> = async (data, e) => {
    e?.preventDefault();

    const documentsStashed = flatMap(data.sources, (ds) => {
      if (ds.fileId !== undefined && ds.sourceLanguage?.id !== undefined) {
        return [
          {
            stashedFileId: ds.fileId,
            languageId: ds.sourceLanguage.id,
            importNumericData: !!ds.importNumericData,
            enableHtmlSubFilter: !!ds.treatContentAsHtml
          }
        ];
      }
      return [];
    });

    const [, res] = await to(createDocument({ documentsStashed }).unwrap());

    if (res && !isEmpty(res.documentIds)) {
      // store the last source language used
      const lastSourceLanguageId = last(documentsStashed)?.languageId ?? DEFAULT_ENGLISH_LANG_ID;
      storeDefaultSourceLanguageId(lastSourceLanguageId);

      // clear stashed files
      dispatch(clearStashedFiles());

      // if there are more than one document ids redirect to document list page
      // otherwise to document detail page
      if (res.documentIds.length > 1) {
        const path = parsedFolderId ? `/documents/folder/${parsedFolderId}` : '/documents';
        history.push(path);
      } else {
        history.push(`/documents/show/${head(res.documentIds)}`);
      }
    }
  };
  //#endregion

  if (isLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  const prevStep = () => {
    const uploadFileStep = uploadingDocumentSteps.find((s) => s.id === EUploadingDocumentSteps.UPLOAD_FILE);

    if (uploadFileStep) {
      if (welcome) {
        history.replace(sanitizeUrl(`/welcome/${uploadFileStep.path}`));
      } else {
        history.push(uploadFileStep.path);
      }
    }
  };

  const disabled = isEmpty(stashedDocuments) || isCreateDocumentLoading;

  return (
    <FormProvider<SetSourceLanguageForm> {...form}>
      <form className="flex w-full flex-col items-center gap-8 pb-8">
        <div className="flex w-full flex-col gap-3">
          {fields.map((item, index) => (
            <Controller
              key={item.fileId}
              name={`sources.${index}.fileId`}
              control={form.control}
              render={() => (
                <SourceLanguageItem
                  index={index}
                  filename={item.filename}
                  languageGroups={languageGroups}
                  options={options}
                  languages={languages}
                  onSelectedItemChange={(value) => addLanguageToSource(item, index, value.selectedItem)}
                />
              )}
            />
          ))}
        </div>
        <div className="flex w-full justify-between">
          <Button variant="text" color="primary" size="md" onClick={prevStep}>
            <AccessibleIcon label="arrow-left" icon="ri-arrow-left-line" />
            {t('goBack')}
          </Button>
          <div className="flex flex-row gap-3">
            {skippableTargetLanguage && (
              <Button
                variant="solid"
                color="secondary"
                size="md"
                className="ml-auto"
                disabled={disabled}
                onClick={form.handleSubmit(onSaveAndExit)}
              >
                {t('saveAndExit')}
                <AccessibleIcon label="arrow-right" icon="ri-arrow-right-line" />
              </Button>
            )}
            <HintBox when={!isCompleted}>
              <Button
                variant="solid"
                color="primary"
                size="md"
                className="ml-auto"
                disabled={disabled}
                onClick={form.handleSubmit(onSubmit)}
              >
                {t('continue')}
                <AccessibleIcon label="arrow-right" icon="ri-arrow-right-line" />
              </Button>
            </HintBox>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
