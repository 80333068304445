import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { radioGroupItem } from './styles';
import type { RadioGroupElement, RadioGroupItemElement, RadioGroupItemProps, RadioGroupProps } from './types';

const RadioGroup = forwardRef<RadioGroupElement, RadioGroupProps>((props, ref) => (
  <RadioGroupPrimitive.Root {...props} ref={ref} />
));
RadioGroup.displayName = 'RadioGroup';

const RadioGroupItem = forwardRef<RadioGroupItemElement, RadioGroupItemProps>(({ className, size, ...props }, ref) => (
  <RadioGroupPrimitive.Item {...props} ref={ref} className={classNames(radioGroupItem({ size }), className)}>
    <RadioGroupPrimitive.Indicator className="absolute left-0 top-0 h-1/2 w-1/2 translate-x-1/2 translate-y-1/2 rounded-full bg-white" />
  </RadioGroupPrimitive.Item>
));
RadioGroupItem.displayName = 'RadioGroupItem';

export { RadioGroup, RadioGroupItem };
