import { Menu } from '@headlessui/react';
import { FunctionComponent } from 'react';

import { classNames } from '@/utils/classNames';
import { isFunction } from '@/utils/isFunction';

import type { TItemProps } from './types';

export const Item: FunctionComponent<TItemProps> = ({ as = 'li', className, ...props }) => (
  <Menu.Item
    as={as}
    // @ts-expect-error: TS 5 migration error
    className={({ active, disabled }) =>
      classNames(
        'px-4 py-1',
        {
          'bg-gray-100': active,
          'opacity-50 hover:cursor-not-allowed': disabled,
          'hover:cursor-pointer': !disabled
        },
        isFunction(className) ? className({ active, disabled }) : className
      )
    }
    {...props}
  />
);
