import { EndpointDefinitions } from '@reduxjs/toolkit/dist/query';
import { QueryState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { find as _find } from 'lodash';

import { isNonNullable } from '@/utils/isNonNullable';

import type { TAppState } from '../../store/types';
import { EParagraphTranslationStatus } from '../constants';
import type { CurrentAccountDto, IParagraphTranslation, ITeamMemberDto } from '../types';

export const getLanguageUrl = (documentId: number, languageId: number) =>
  `document/${documentId}/translation/${languageId}`;

export const getQueryStatesByName = (queries: QueryState<EndpointDefinitions>, endpointName: string) =>
  Object.values(queries)
    .filter((query) => query?.endpointName === endpointName)
    // isNonNullable could be in the first filter function but it's here for inferring the correct type
    .filter(isNonNullable);

/**
 * Updates the translation content based on API response.
 *
 * @param translation Translation to be updated
 * @param content New translation content
 * @returns Updated translation object or create a new one if the translation is not valid
 */
export const prepareTranslationContent = (translation: IParagraphTranslation | null | undefined, content: string) => {
  if (!content) {
    // Empty saved content, remove translation
    return null;
  } else if (!translation) {
    // Valid saved content and no translation, create new translation
    // At this time translation ID is never used and it's ok to set it to 0
    // but it would be better to get this data from the server
    return { id: 0, content: content, status: EParagraphTranslationStatus.CONFIRMED };
  } else {
    // Update translation content
    return { ...translation, content: content };
  }
};

export const currentAccountSelector = (state: TAppState): CurrentAccountDto | null => {
  const queryStates = getQueryStatesByName(state.redokunApi.queries, 'getCurrentAccount');

  if (queryStates.length < 1) {
    return null;
  }

  const { data } = queryStates[0];
  return data || null;
};

export const getTeamMember = (teamMembers: ITeamMemberDto[], id: number) => _find(teamMembers, { id });
