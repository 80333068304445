import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, ReactNode } from 'react';

import { TimesCircleIcon } from '@/icons/solid';
import { classNames } from '@/utils/classNames';
import { isNonNullable } from '@/utils/isNonNullable';

import { Button } from '../Button';
import { Jumbo } from '../Jumbo';
import { Spinner } from '../Spinner';
import type { IModalProps, TConfirmModalProps, TJumboModalProps } from './types';

export const Modal: FunctionComponent<IModalProps> & {
  Confirm: FunctionComponent<TConfirmModalProps>;
  Jumbo: FunctionComponent<TJumboModalProps>;
} = ({ open, onClose, afterClose, children = {}, size = 'xl', closable = true, loading }) => {
  const { header, content, footer } = children;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={(value) => {
          // Prevent closing modal with default behavior if it's not closable
          if (value || closable) {
            onClose(value);
          }
        }}
      >
        <div className="flex min-h-screen items-center justify-center p-8">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-40"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 opacity-50" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={afterClose}
          >
            <div
              className={classNames('transform rounded-sm border border-gray-300 bg-white shadow-2xl transition-all', {
                'w-full max-w-lg': size === 'lg',
                'w-full max-w-xl': size === 'xl'
              })}
            >
              <Spinner enabled={loading}>
                {(isNonNullable(header) || closable) && (
                  <div className="flex items-center justify-end border-b px-4 py-2 text-gray-800">
                    {isNonNullable(header) && (
                      <Dialog.Title as="h3" className="flex-1 text-lg font-medium">
                        {header}
                      </Dialog.Title>
                    )}

                    {closable && (
                      <button
                        className="text-gray-500 hover:text-gray-600"
                        tabIndex={-1}
                        onClick={() => {
                          onClose(false);
                        }}
                      >
                        <TimesCircleIcon />
                      </button>
                    )}
                  </div>
                )}

                {isNonNullable(content) && (
                  <Dialog.Description as="div" className="p-4 text-sm text-gray-800">
                    {content}
                  </Dialog.Description>
                )}

                {isNonNullable(footer) && <div className="border-t p-4 text-sm text-gray-800">{footer}</div>}
              </Spinner>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
Modal.displayName = 'Modal';

Modal.Confirm = ({ children, cancelText, okText, cancelLoading, okLoading, onClose, onCancel, onOk, ...props }) => {
  const actions: ReactNode[] = [];
  if (isNonNullable(cancelText)) {
    actions.push(
      <Button
        key="cancel_button"
        btnType="outline"
        loading={cancelLoading}
        onClick={() => {
          if (onCancel) {
            onCancel();
          } else {
            onClose(false);
          }
        }}
      >
        {cancelText}
      </Button>
    );
  }
  if (isNonNullable(okText)) {
    actions.push(
      <Button
        key="ok_button"
        loading={okLoading}
        onClick={() => {
          if (onOk) {
            onOk();
          } else {
            onClose(false);
          }
        }}
      >
        {okText}
      </Button>
    );
  }

  return (
    <Modal onClose={onClose} {...props}>
      {{
        header: children?.header,
        content: children?.content,
        footer: actions.length ? <div className="flex justify-end space-x-2">{actions}</div> : null
      }}
    </Modal>
  );
};
Modal.Confirm.displayName = 'ModalConfirm';

Modal.Jumbo = ({ children, ...props }) => (
  <Modal closable={false} {...props}>
    {{
      content: <Jumbo>{children}</Jumbo>
    }}
  </Modal>
);
Modal.Jumbo.displayName = 'ModalJumbo';
