import { filter, map } from 'lodash';
import { useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { useGetDocumentDetailQuery, usePreTranslateMutation } from '@/redux/api';
import { LaunchPreTranslationRequestDto } from '@/redux/api/types';
import { MtOption, TmOption } from '@/routes/PreTranslate/components/types';
import { assert } from '@/utils/assert';
import { to } from '@/utils/awaitToJs';

import { LaunchPreTranslateForm } from './LaunchPreTranslateForm';

export const LaunchPreTranslate = () => {
  const { url } = useRouteMatch();
  const { documentId } = useLaunchPreTranslateParams();
  const history = useHistory();

  const {
    data: documentDetail,
    isUninitialized,
    isLoading
    // refetchOnMountOrArgChange is needed due to state changes in webeditor
  } = useGetDocumentDetailQuery(documentId, { refetchOnMountOrArgChange: true });

  const [preTranslate] = usePreTranslateMutation();

  if (isUninitialized || isLoading) {
    return <>Loading</>;
  }

  assert(documentDetail, 'Document data not found');

  return (
    <LaunchPreTranslateForm
      translations={documentDetail?.translations}
      onSubmit={async (data) => {
        const body: LaunchPreTranslationRequestDto = {
          languages: map(
            filter(Object.entries(data.languages), ([, language]) => language.tm || language.mt),
            ([languageId, { tm, mt, mtVendor }]) => ({
              languageId: parseInt(languageId),
              // TODO: temporary is true due to backend issues
              doTmStep: tm ?? true,
              doMtStep: mt ?? false,
              mtVendor
            })
          ),
          enableTmIdenticalMatch: data.tm !== TmOption.DISABLE,
          markAsDraftIdentical: data.tm === TmOption.MARK_AS_DRAFT,
          markAsDraftMt: data.mt === MtOption.MARK_AS_DRAFT
        };

        const [error, response] = await to(
          preTranslate({ documentId: documentDetail.sourceDocument.id, body }).unwrap()
        );
        if (!error && response) {
          history.push(`${url}/report/${response.operationId}`);
        }
      }}
    />
  );
};

const useLaunchPreTranslateParams = () => {
  const params = useParams<{ documentId: string }>();
  const parsedParams = useMemo(() => ({ documentId: parseInt(params.documentId) }), [params]);
  return parsedParams;
};
