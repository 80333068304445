import { head } from 'lodash';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { HintBox } from '@/components/v2/HintBox';
import { Loader } from '@/components/v2/Loader';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { useToast } from '@/components/v2/Toast';
import Tooltip from '@/components/v2/Tooltip';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useChangeLanguageStatusMutation } from '@/redux/api';
import { TranslationStatus } from '@/redux/api/constants';
import { DocumentTranslationDto } from '@/redux/api/types';
import { useAppDispatch } from '@/redux/hooks';
import { openAskToUnlockTranslationModal, openLowWordBalanceModal } from '@/redux/slices/modalsSlice';
import { ChangeTranslatorModal } from '@/routes/Document/components/ChangeTranslatorModal';
import { DeleteLanguageModal } from '@/routes/Document/components/DeleteLanguageModal';
import { LockDocumentModal } from '@/routes/Document/components/LockDocumentModal';
import { useDownload } from '@/routes/Document/hooks/useDownload';
import { classNames } from '@/utils/classNames';

enum ModalKey {
  CHANGE_TRANSLATOR = 'CHANGE_TRANSLATOR',
  DELETE_LANGUAGE = 'DELETE_LANGUAGE'
}

const translationReadyToDownloadStatuses = new Set([TranslationStatus.GENERATED, TranslationStatus.CONFIRMED]);

interface ActionCellProps {
  documentId: number;
  canUnlock: boolean;
}

export const ActionsCell = (props: ActionCellProps) => {
  const { documentId, canUnlock } = props;
  const canManageDocument = canUnlock;

  const { cell } = DataTable.useCellContext<DocumentTranslationDto>('ActionsCell');
  const { table } = DataTable.useContext<DocumentTranslationDto>('ActionCell');

  const translation = cell.row.original;
  const { status, translators, language, id: translationId } = translation;
  const isFirstDocument = head(table.options.data)?.id === translationId;

  const languageId = language.id;
  const languageName = language.name;

  const { currentAccount, isFetching } = useCurrentAccount();
  const { t: tDocument } = useTranslation('document');
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const [isLowWordBalanceAlertVisible] = useLocalStorage<boolean | undefined>('isLowWordBalanceAlertVisible', true);

  const { hasPreTranslatedAnyDocument, hasConfirmedAnyDocument, mustCompleteOnboarding } =
    currentAccount.onboardingFlags;

  const [changeStatus, { isLoading, isSuccess }] = useChangeLanguageStatusMutation();
  const download = useDownload(documentId, languageId);

  const [isOpen, setIsOpen] = useState(false);

  // https://github.com/radix-ui/primitives/issues/1836
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState<ModalKey | null>(null);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  // https://github.com/radix-ui/primitives/issues/1836
  const handleModalItemSelect = (key: ModalKey) => (event: Event) => {
    event.preventDefault();
    setMenuOpen(false);
    setModalOpen(key);
  };

  const closeModal = () => {
    setModalOpen(null);
    menuTriggerRef.current?.focus();
  };

  // https://github.com/radix-ui/primitives/issues/1836
  const modalProps = (key: ModalKey) => ({
    open: modalOpen === key,
    onOpenChange: (open: boolean) => {
      if (open) {
        setMenuOpen(false);
        setModalOpen(key);
      } else {
        closeModal();
      }
    }
  });

  const isTranslationReady = translationReadyToDownloadStatuses.has(status);
  const showConfirmAndLockHint =
    status === TranslationStatus.CREATED &&
    !hasConfirmedAnyDocument &&
    hasPreTranslatedAnyDocument &&
    isFirstDocument &&
    mustCompleteOnboarding;

  const showDownloadDocumentHint =
    status === TranslationStatus.CONFIRMED && mustCompleteOnboarding && hasPreTranslatedAnyDocument && isFirstDocument;
  const disabledStatusButton = isLoading || isSuccess;

  const disabledDownloadButton = !isTranslationReady || isFetching;
  // if document is generating then show a loader in download button
  const loadingDownloadButton = status === TranslationStatus.GENERATING;

  const handleUnlockDocument = async () => {
    changeStatus({
      documentId,
      languageId,
      body: {
        status: TranslationStatus.CREATED
      }
    });
  };

  const handleConfirmLock = () => {
    toast({
      title: tDocument('yourTranslationIsConfirmed'),
      kind: 'success'
    });
  };

  const handleDownload = () => {
    const {
      subscriptionInfo: { hasLowWordBalance, lowWordBalanceThreshold, hasUnlimitedWords }
    } = currentAccount;
    // check if user has low word balance
    if (hasLowWordBalance && isLowWordBalanceAlertVisible && !hasUnlimitedWords) {
      // if so open an alert
      dispatch(openLowWordBalanceModal({ lowWordBalanceThreshold, documentId, languageId, translationId }));
    } else {
      // otherwise download the document
      download();
    }
  };

  const handleAskToUnlock = () => {
    dispatch(
      openAskToUnlockTranslationModal({
        documentId,
        languageId
      })
    );
  };

  return (
    <div className="flex flex-row items-center justify-end gap-4">
      <ButtonLink href={`/documents/show/${documentId}/translations/${languageId}`} size="sm" variant="soft">
        {tDocument('editor')}
        <AccessibleIcon icon="ri-external-link-line" label={tDocument('editorIcon')} />
      </ButtonLink>

      {translation.isEditable ? (
        <Tooltip.Root open={showConfirmAndLockHint}>
          <Tooltip.Trigger>
            <LockDocumentModal
              open={isOpen}
              onOpenChange={(value) => setIsOpen(value)}
              documentId={documentId}
              languageId={languageId}
              onLock={handleConfirmLock}
            >
              {/* this div is necessary, otherwise the modal cannot capture the onclick event of the button */}
              <div>
                <HintBox when={showConfirmAndLockHint}>
                  <RequireSubscription>
                    <Button size="sm" variant="soft" color="positive" className="w-[140px]">
                      {tDocument('confirmAndLock')}
                    </Button>
                  </RequireSubscription>
                </HintBox>
              </div>
            </LockDocumentModal>
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-xxs" sideOffset={12}>
            <Text className="whitespace-pre-line text-typography-inverse-primary" size="xs">
              {tDocument('confirmAndLockOnceReady')}
            </Text>
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Tooltip.Root>
      ) : (
        <Show
          when={canUnlock}
          fallback={
            <Button size="sm" color="positive" variant="soft" onClick={handleAskToUnlock}>
              {tDocument('askToUnlock')}
            </Button>
          }
        >
          <Button
            size="sm"
            variant="soft"
            color="primary"
            onClick={handleUnlockDocument}
            disabled={disabledStatusButton}
            className="w-[140px]"
          >
            {tDocument('unlockAndEdit')}
          </Button>
        </Show>
      )}

      {/* if user can manage a document, the show the download button */}
      <Show when={canManageDocument}>
        <DownloadButton
          showDownloadDocumentHint={showDownloadDocumentHint}
          disabled={disabledDownloadButton}
          loading={loadingDownloadButton}
          onDownload={handleDownload}
        />
      </Show>

      <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
          <AccessibleIcon
            ref={menuTriggerRef}
            icon="ri-more-2-fill"
            label="ri-more-2-fill"
            className="cursor-pointer text-lg text-primary-active"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end" className="max-w-[17.5rem]">
          <Show when={canManageDocument}>
            <RequireSubscription>
              <DropdownMenu.Item onSelect={handleModalItemSelect(ModalKey.CHANGE_TRANSLATOR)}>
                <AccessibleIcon icon="ri-user-line" label={'Change Translator'} className="text-gray-400" />
                <Text size="md" weight="medium" color="primary">
                  {tDocument('changeTranslator')}
                </Text>
              </DropdownMenu.Item>
            </RequireSubscription>
          </Show>

          <DropdownMenu.Item asChild>
            <Link to={`/documents/show/${documentId}/translations/${languageId}/export-phrase/${translationId}`}>
              <AccessibleIcon icon="ri-download-2-line" label={tDocument('exportPhrases')} className="text-gray-400" />
              <div>
                <Text size="md" weight="medium" color="primary">
                  {tDocument('exportPhrases')}
                </Text>
                <Text size="xs" weight="medium" color="secondary">
                  {tDocument('downloadSentencesForOfflineTranslation')}
                </Text>
              </div>
            </Link>
          </DropdownMenu.Item>

          <RequireSubscription>
            <DropdownMenu.Item asChild>
              <Link to={`/documents/show/${documentId}/translations/${languageId}/import-phrase`}>
                <AccessibleIcon icon="ri-upload-2-line" label={tDocument('importPhrases')} className="text-gray-400" />
                <div>
                  <Text size="md" weight="medium" color="primary">
                    {tDocument('importPhrases')}
                  </Text>
                  <Text size="xs" weight="medium" color="secondary">
                    {tDocument('uploadYourTranslatedExchangeFile')}
                  </Text>
                </div>
              </Link>
            </DropdownMenu.Item>
          </RequireSubscription>

          <Show when={canManageDocument}>
            <DropdownMenu.Item
              onSelect={handleModalItemSelect(ModalKey.DELETE_LANGUAGE)}
              className="text-states-negative-active"
            >
              <AccessibleIcon icon="ri-delete-bin-5-line" label="ri-delete-bin-5-line" />
              {tDocument('deleteThisLanguage')}
            </DropdownMenu.Item>
          </Show>
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      {/* this is needed to unmount the modal and reset react-hook-form */}
      <Show when={modalOpen === ModalKey.CHANGE_TRANSLATOR}>
        <ChangeTranslatorModal
          documentId={documentId}
          languageId={languageId}
          languageName={languageName}
          translators={translators}
          onConfirm={closeModal}
          {...modalProps(ModalKey.CHANGE_TRANSLATOR)}
        />
      </Show>

      <DeleteLanguageModal
        documentId={documentId}
        languageId={languageId}
        languageName={languageName}
        onDelete={closeModal}
        {...modalProps(ModalKey.DELETE_LANGUAGE)}
      />
    </div>
  );
};

interface DownloadButtonProps {
  showDownloadDocumentHint: boolean;
  disabled?: boolean;
  loading?: boolean;
  onDownload: () => void;
}

const DownloadButton = (props: DownloadButtonProps) => {
  const { t: tDocument } = useTranslation('document');
  const { showDownloadDocumentHint, disabled = false, loading = false, onDownload } = props;

  return (
    <Tooltip.Root open={showDownloadDocumentHint}>
      <Tooltip.Trigger>
        <HintBox when={showDownloadDocumentHint}>
          <Button
            size="sm"
            variant="text"
            onClick={onDownload}
            disabled={disabled}
            className={classNames('h-9 rounded-xl px-3', {
              'hover:bg-white': showDownloadDocumentHint
            })}
          >
            <Show
              when={loading}
              fallback={<AccessibleIcon label="ri-file-download-line" icon="ri-file-download-line" />}
            >
              <Loader className="group-hover:bg-surface-accent" />
            </Show>
            {tDocument('download')}
          </Button>
        </HintBox>
      </Tooltip.Trigger>
      <Tooltip.Content className="max-w-xxs" sideOffset={12}>
        <Text className="text-typography-inverse-primary" size="xs">
          {tDocument('downloadYourTranslatedDocument')}
        </Text>
        <Tooltip.Arrow />
      </Tooltip.Content>
    </Tooltip.Root>
  );
};
