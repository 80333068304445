import { languageApiUtil } from '../languageApi';
import type { IDocumentCommentDto, IGetDocumentTranslatedCommentQueryArg } from '../types';

export const documentCommentCreated = (args: IGetDocumentTranslatedCommentQueryArg, payload: IDocumentCommentDto) =>
  languageApiUtil.safeUpdateQueryData('getDocumentTranslatedComment', args, (data) => {
    const commentIndex = data.comments.findIndex((comment) => comment.id === payload.id);
    if (commentIndex >= 0) {
      return;
    }

    data.comments.push(payload);
  });
