import { redokunApi } from './redokunApi';
import type { CreateFolderQueryArgs, FolderHierarchyDto, OkResponseDto, UpdateFolderQueryArgs } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const foldersApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Create a folder
     */
    createFolder: builder.mutation<OkResponseDto, CreateFolderQueryArgs>({
      query: (data) => ({ url: 'folder', method: 'POST', body: data }),
      invalidatesTags: ['documentList', 'folders']
    }),
    /**
     * Retrieve all folders
     */
    getFolders: builder.query<FolderHierarchyDto, void>({
      query: () => `folders`,
      keepUnusedDataFor: 60,
      providesTags: ['folders']
    }),
    /**
     * Update a folder
     */
    updateFolder: builder.mutation<OkResponseDto, UpdateFolderQueryArgs>({
      query: ({ folderId, parentFolderId, name }) => ({
        url: `folder/${folderId}`,
        method: 'PATCH',
        body: {
          name,
          parentFolderId
        }
      }),
      invalidatesTags: ['folders', 'documentList']
    }),
    /**
     * Delete a folder
     */
    deleteFolder: builder.mutation<OkResponseDto, number>({
      query: (folderId) => ({
        url: `folder/${folderId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['documentList', 'folders']
    })
  })
});

export const {
  useCreateFolderMutation,
  useGetFoldersQuery,
  useDeleteFolderMutation,
  useLazyGetFoldersQuery,
  useUpdateFolderMutation
} = foldersApi;

export const foldersApiUtil = {
  ...foldersApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(foldersApi.util.updateQueryData)
};
