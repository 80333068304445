import { useTranslation } from 'react-i18next';

import { Text } from '@/components/v2/Text';
import { Box } from '@/components/v2/Box';
import { Toggle } from '@/components/v2/Toggle';

type LanguageSettingsProps = {
  enableRegionalLanguages: boolean | null;
  setEnableRegionalLanguages: React.Dispatch<React.SetStateAction<boolean | null>>;
};

export const LanguageSettings = ({ enableRegionalLanguages, setEnableRegionalLanguages }: LanguageSettingsProps) => {
  const { t: tSettings } = useTranslation('settings');

  const handleCheckedChange = (value: boolean) => {
    setEnableRegionalLanguages(value);
  };

  return (
    <div>
      <Box>
        <div className="flex items-center space-x-4">
          <Text size="md" color="primary" className="font-bold">
            {tSettings('languageSettings')}
          </Text>
        </div>
        <div>
          <label className="flex gap-5">
            <Toggle
              checked={enableRegionalLanguages ?? undefined}
              defaultChecked={enableRegionalLanguages ?? undefined}
              onCheckedChange={handleCheckedChange}
            />
            <Text
              size="md"
              weight="medium"
              color="primary"
              className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
            >
              {tSettings('enableCountrySpecificLanguages')}
              <Text size="xs" color="tertiary" className="pt-2">
                {tSettings('youCanUseRegionalLanguagesEgEnglishUKEnglishUS')}
              </Text>
            </Text>
          </label>
        </div>
      </Box>
    </div>
  );
};
