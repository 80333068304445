import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v2/Box';
import { Text } from '@/components/v2/Text';
import { Toggle } from '@/components/v2/Toggle';

export const FileUpload = () => {
  const { t: tSettings } = useTranslation('settings');
  const { control: controlFromContext } = useFormContext();

  return (
    <Box>
      <div>
        <Text size="md" color="primary" className="font-bold">
          {tSettings('fileUpload')}
        </Text>
      </div>
      <div className="space-y-6 pt-4">
        <Controller
          control={controlFromContext}
          name="skipSoftReturnsOnUpload"
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex gap-6">
              <Toggle checked={value} defaultChecked={value} onCheckedChange={onChange} {...field} />
              <Text weight="medium" color="primary">
                {tSettings('convertSoftReturns')}
                <Text size="sm" color="tertiary" className="pt-3">
                  {tSettings('convertSoftReturnsDescription')}
                </Text>
              </Text>
            </label>
          )}
        />
        <Controller
          control={controlFromContext}
          name="convertSpecialSpaces"
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex gap-6">
              <Toggle checked={value} defaultChecked={value} onCheckedChange={onChange} {...field} />
              <Text weight="medium" color="primary">
                {tSettings('convertSpecialSpaces')}
                <Text size="sm" color="tertiary" className="whitespace-pre-line pt-3">
                  {tSettings('convertSpecialSpacesDescription')}
                </Text>
              </Text>
            </label>
          )}
        />
        <Controller
          control={controlFromContext}
          name="enableFilterSelection"
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex gap-6">
              <Toggle checked={value} defaultChecked={value} onCheckedChange={onChange} {...field} />
              <Text weight="medium" color="primary">
                {tSettings('enableFilterSelection')}
                <Text size="sm" color="tertiary" className="whitespace-pre-line pt-3">
                  {tSettings('enableFilterSelectionDescription')}
                </Text>
              </Text>
            </label>
          )}
        />
      </div>
    </Box>
  );
};
