import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';
import { inputVariants } from '@/components/v2/Input/styles';

import type { InputProps } from './types';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ onChange, placeholder, className, size = 'md', type = 'text', ...rest }, forwardRef) => {
    return (
      <input
        type={type}
        className={classNames(inputVariants({ size }), className)}
        placeholder={placeholder}
        ref={forwardRef}
        onChange={onChange}
        {...rest}
      />
    );
  }
);

Input.displayName = 'Input';
