import { redokunApi } from './redokunApi';
import type { GetPlansResponseDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const plansApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query<GetPlansResponseDto, void>({
      query: () => `/plans`,
      providesTags: ['plans']
    })
  })
});

export const { useGetPlansQuery, useLazyGetPlansQuery } = plansApi;

export const plansApiUtil = {
  ...plansApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(plansApi.util.updateQueryData)
};
