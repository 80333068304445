import { useControllableState } from '@radix-ui/react-use-controllable-state';
import { find, sortBy, toLower } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '@/components/v2/Button';
import { For } from '@/components/v2/For';
import Modal from '@/components/v2/Modal';
import { Surface } from '@/components/v2/Surface';
import { useGetSavedViewsQuery } from '@/redux/api/documentListApi';

import { createDocumentListSearchParams } from '../../../../utils';
import { SavedViewItem } from './SavedViewItem';
import type { SavedViewsModalProps } from './types';

const SavedViewsModal = (props: SavedViewsModalProps) => {
  const { children, defaultOpen: defaultProp, open: prop, onOpenChange: onChange, ...modalProps } = props;

  const { t: tDocumentList } = useTranslation('documentList');
  const history = useHistory();

  const [open, setOpen] = useControllableState({ defaultProp, prop, onChange });

  const { data: { savedViewList } = {} } = useGetSavedViewsQuery();

  const [selectedId, setSelectedId] = useState<number>();

  const canApply = !!selectedId;
  const handleApply = () => {
    const savedView = find(savedViewList, { id: selectedId });
    if (!savedView) {
      return;
    }

    const searchParams = createDocumentListSearchParams(savedView);
    const search = searchParams.toString();
    history.push({ search });

    setOpen(false);
  };

  return (
    <Modal.Root open={open} onOpenChange={setOpen} {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="lg">
        <Modal.Header className="text-center">
          <Modal.Title size="lg" weight="semibold" className="text-left">
            {tDocumentList('savedViews')}
          </Modal.Title>
        </Modal.Header>

        <Surface className="border">
          <ul className="flex max-h-60 flex-col gap-0.5 overflow-y-auto px-3 py-2.5">
            <For each={sortBy(savedViewList, (savedView) => toLower(savedView.name))}>
              {(savedView) => {
                const isSelected = savedView.id === selectedId;

                return (
                  <SavedViewItem
                    key={savedView.id}
                    id={savedView.id}
                    name={savedView.name}
                    selected={isSelected}
                    default={savedView.isDefault}
                    onSelect={setSelectedId}
                  />
                );
              }}
            </For>
          </ul>
        </Surface>

        <Modal.Footer className="flex flex-row gap-4">
          <Modal.Close>
            <Button color="secondary">{tDocumentList('cancel')}</Button>
          </Modal.Close>

          <Button disabled={!canApply} onClick={handleApply}>
            {tDocumentList('applyView')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
SavedViewsModal.displayName = 'SavedViewsModal';

export { SavedViewsModal };
