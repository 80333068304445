import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import Form from '@/components/v2/Form';
import { Text } from '@/components/v2/Text';
import { Box } from '@/components/v2/Box';
import { Button } from '@/components/v2/Button';
import { useUpdateCurrentAccountPasswordMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';
import { Label } from '@/components/v2/Label';
import { Input } from '@/components/v2/Input';
import { useToast } from '@/components/v2/Toast';
import { Spinner } from '@/components/v1/Spinner';
import { OkResponseDto } from '@/redux/api/types';

export const SettingsChangePassword: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const [updateCurrentAccountPassword, updateCurrentAccountPasswordStatus] = useUpdateCurrentAccountPasswordMutation();

  const formSchema = z
    .object({
      currentPassword: z.string(),
      newPassword: z.string(),
      repeatNewPassword: z.string()
    })
    .superRefine((data, ctx) => {
      if (data.newPassword !== data.repeatNewPassword) {
        ctx.addIssue({
          code: 'custom',
          message: tSettings('theTwoPasswordsDontMatch'),
          path: ['repeatNewPassword']
        });
      }
    });

  const form = Form.useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: ''
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { currentPassword, newPassword } = values;

    type Error = { data: { fieldsWithErrors: { [key: string]: string }; error: string } };

    const [err, res] = await to<OkResponseDto, Error>(
      updateCurrentAccountPassword({ currentPassword, newPassword }).unwrap()
    );

    if (!err && res.status === 'ok') {
      form.reset();
      toast({ title: tSettings('passwordUpdatedSuccessfully'), kind: 'success' });
    } else {
      if (err) {
        if (err.data.fieldsWithErrors.length) {
          const fieldsWithErrors = Object.keys(err.data.fieldsWithErrors);
          for (let i = 0; i < fieldsWithErrors.length; i++) {
            const field = fieldsWithErrors[i] as keyof typeof values;
            form.setError(field, {
              type: 'server',
              message: err.data.fieldsWithErrors[field]
            });
          }
        } else {
          form.setError('currentPassword', {
            type: 'server',
            message: err.data.error
          });
        }
      }
    }
  };

  const titleBlock = (
    <div className="flex items-center justify-between">
      <Text size="xl" weight="bold" color="primary">
        {tSettings('changePassword')}
      </Text>
      <div>
        <Button
          onClick={form.handleSubmit(onSubmit)}
          color="primary"
          disabled={updateCurrentAccountPasswordStatus.isLoading}
        >
          {updateCurrentAccountPasswordStatus.isLoading ? <Spinner size="sm" /> : null}
          {tSettings('save')}
        </Button>
      </div>
    </div>
  );

  const formBlock = (
    <div className="mt-6 grid grid-cols-2 gap-6">
      <Form.Root {...form} className="space-y-6">
        <Box>
          <Text>{tSettings('yourPasswordShouldBeAtLeast8CharactersLong')}</Text>
          {/* Fields  */}
          <Form.Field name="currentPassword" rules={{ required: tSettings('thisFieldIsRequired') }}>
            <Label isRequired text={tSettings('currentPassword')} />
            <Form.Control>{({ field }) => <Input {...field} type="password" />}</Form.Control>
            <Form.Error />
          </Form.Field>

          <Form.Field name="newPassword" rules={{ required: tSettings('thisFieldIsRequired') }}>
            <Label isRequired text={tSettings('newPassword')} />
            <Form.Control>{({ field }) => <Input {...field} type="password" />}</Form.Control>
            <Form.Error />
          </Form.Field>

          <Form.Field name="repeatNewPassword" rules={{ required: tSettings('thisFieldIsRequired') }}>
            <Label isRequired text={tSettings('repeatNewPassword')} />
            <Form.Control>{({ field }) => <Input {...field} type="password" />}</Form.Control>
            <Form.Error />
          </Form.Field>
        </Box>
      </Form.Root>
    </div>
  );

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsChangePassword')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        {titleBlock}
        {/* Form */}
        {formBlock}
      </div>
    </div>
  );
};
