import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DropdownMenu from '@/components/v2/DropdownMenu';
import { useToast } from '@/components/v2/Toast';
import { TranslatorDto, TranslatorDtoStatuses } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { useSendActivationEmailMutation } from '@/redux/api/registrationApi';

import { CofirmRemoveTranslatorModal } from './ConfirmRemoveTranslatorModal';

export const SettingsTranslatorsTableActionsCell = ({ translator }: { translator: TranslatorDto }) => {
  const { t: tSettings } = useTranslation('settings');

  const { toast } = useToast();
  const [reinviteTranslatorTrigger] = useSendActivationEmailMutation();
  const [confirmRemoveTranslatorModalIsOpen, setConfirmRemoveTranslatorModalIsOpen] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  const reinviteTranslatorMenuAction = async () => {
    const [err] = await to(reinviteTranslatorTrigger({ email: translator.email }).unwrap());
    if (!err) {
      const title = tSettings('invitationSendedAgainWithSuccessToastTitle');
      toast({ title, kind: 'success' });
    }
  };

  const renderContent = () => {
    return (
      <>
        {translator.status === TranslatorDtoStatuses.created ? (
          <DropdownMenu.Item onSelect={reinviteTranslatorMenuAction}>
            <AccessibleIcon icon="ri-send-plane-line" label={tSettings('resendInvitation')} />
            {tSettings('resendInvitation')}
          </DropdownMenu.Item>
        ) : null}
        <DropdownMenu.Item
          className="cursor-pointer text-states-negative-active"
          onSelect={() => {
            setMenuOpen(false);
            setConfirmRemoveTranslatorModalIsOpen(true);
          }}
        >
          <AccessibleIcon icon="ri-delete-bin-line" label={tSettings('removeTranslator')} />
          {tSettings('removeTranslator')}
        </DropdownMenu.Item>
      </>
    );
  };

  return (
    <div className="flex flex-row justify-end gap-3">
      <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenu.Trigger onClick={(e) => e.preventDefault()}>
          <AccessibleIcon
            ref={menuTriggerRef}
            icon="ri-more-2-fill"
            label="ri-more-2-fill"
            className="cursor-pointer text-lg text-primary-active"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end">{renderContent()}</DropdownMenu.Content>
      </DropdownMenu.Root>

      <CofirmRemoveTranslatorModal
        open={confirmRemoveTranslatorModalIsOpen}
        onOpenChange={setConfirmRemoveTranslatorModalIsOpen}
        translatorAccountId={translator.id}
      />
    </div>
  );
};
