import { zodResolver } from '@hookform/resolvers/zod';
import { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { z, ZodType } from 'zod';

import { ReactComponent as Loader } from '@/assets/loader.svg';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { FileContainer, FileContainerProps } from '@/components/v2/FileContainer';
import FileUploader from '@/components/v2/FileUploader';
import Form from '@/components/v2/Form';
import InlineMessage from '@/components/v2/InlineMessage';
import { Label } from '@/components/v2/Label';
import { Show } from '@/components/v2/Show';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { useToast } from '@/components/v2/Toast';
import { APP_URL, HELP_URL } from '@/config/urls';
import { useFileUploadMutation } from '@/redux/api/fileApi';
import { useImportGlossaryEntryMutation } from '@/redux/api/glossaryApi';
import { EntityType } from '@/redux/api/types';
import { Layout } from '@/routes/CreateGlossaryEntry/components/Layout';
import { ACCEPTED_FILES, MAX_FILES, MAX_SIZE } from '@/routes/GlossaryUploadEntry/constants';
import { to } from '@/utils/awaitToJs';
import { sizeToMb } from '@/utils/sizeToMb';

export const GlossaryUploadEntry = () => {
  const { t: tGlossary } = useTranslation('glossary');
  const history = useHistory();
  const { glossaryId } = useGlossaryUploadEntryParams();
  const { toast } = useToast();

  const [showSpinner, setShowSpinner] = useState(false);
  const [stashedFileId, setStashedFileId] = useState<number | undefined>();

  // get stashed file id
  const [uploadFileTrigger, { isLoading: uploadFileLoading, isSuccess: uploadFileSuccess, isError: uploadFileError }] =
    useFileUploadMutation();
  // import glossary entry
  const [triggerImportGlossaryEntry] = useImportGlossaryEntryMutation();

  const formSchema: ZodType = z.object({
    file: z.instanceof(File)
  });

  const form = Form.useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    if (data.file) {
      setShowSpinner(true);
      if (stashedFileId) {
        const [err, res] = await to(triggerImportGlossaryEntry({ glossaryId, body: { stashedFileId } }).unwrap());

        if (!err) {
          const count = res.imported;
          const description =
            count > 1
              ? tGlossary('entriesImported', { entriesCount: count })
              : tGlossary('entryImported', { entriesCount: count });
          toast({
            title: tGlossary('glossaryUploadedSuccessfully'),
            description,
            kind: 'success'
          });
          // this timeout is needed otherwise the toast will not show
          setTimeout(() => {
            history.push(`/glossaries/show/${glossaryId}`);
          }, 2000);
        }
      }
    }
  };

  const handleOnDrop = async (field: ControllerRenderProps<FieldValues, string>, files: File[]) => {
    if (files.length) {
      field.onChange(files[0]);
      const [err, response] = await to(uploadFileTrigger({ entityType: EntityType.glossary, files }).unwrap());
      if (response?.successfulUploads.length) {
        setStashedFileId(response.successfulUploads[0].stashedFileId);
      } else if (err) {
        return;
      }
    }
  };

  let fileContainerStatus: FileContainerProps['status'] = 'active';
  if (uploadFileSuccess) {
    fileContainerStatus = 'completed';
  } else if (uploadFileError) {
    fileContainerStatus = 'error';
  }

  return (
    <Layout>
      <Show
        when={!showSpinner}
        fallback={
          // loading spinner
          <div className="flex h-[360px] flex-1 flex-col items-center justify-center gap-4">
            <Loader className="animate-slow-spin" />
            <div className="text-center">
              <Text size="lg" weight="bold" color="tertiary">
                {tGlossary('weAreImportingYourGlossary')}
              </Text>
              <Text size="md" color="tertiary">
                {tGlossary('thisWillTakeAMomentDontClose')}
              </Text>
              <Text size="md" color="tertiary">
                {tGlossary('dontCloseThisTabYet')}
              </Text>
            </div>
          </div>
        }
      >
        {/* form */}
        <Form.Root {...form} onSubmit={onSubmit} className="flex flex-col gap-7" devTool>
          <Surface className="flex flex-col gap-8 px-7 py-8">
            <Form.Field name="file" rules={{ required: true }} className="w-full">
              <Title size="sm" weight="bold">
                {tGlossary('uploadGlossaryInXlsxFormat')}
              </Title>
              <Label htmlFor="file" text={tGlossary('maxOneFile')} isRequired />
              <Form.Control>
                {({ field }) => (
                  <div className="flex flex-row gap-4">
                    <div className="w-1/2">
                      <FileUploader.Root
                        dropzoneOptions={{
                          maxFiles: MAX_FILES,
                          maxSize: MAX_SIZE,
                          onDrop: (files) => {
                            handleOnDrop(field, files);
                          },
                          accept: ACCEPTED_FILES
                        }}
                      >
                        <FileUploader.DragArea>
                          <FileUploader.DragAreaContent>
                            <AccessibleIcon
                              icon="ri-upload-2-line"
                              label="ri-upload-2-line"
                              className="text-4xl text-alpha-400"
                            />
                            <FileUploader.DragAreaTitle>{tGlossary('dragAndDropFiles')}</FileUploader.DragAreaTitle>
                            <FileUploader.DragAreaDescription>
                              {tGlossary('supportedFileFormat')}
                            </FileUploader.DragAreaDescription>
                          </FileUploader.DragAreaContent>
                          <FileUploader.Divider className="py-4">
                            {/* @ts-expect-error: TS 5 migration error */}
                            {tGlossary('or')}
                          </FileUploader.Divider>
                          <FileUploader.Button>{tGlossary('browseFiles')}</FileUploader.Button>
                        </FileUploader.DragArea>
                      </FileUploader.Root>
                    </div>
                    <div className="flex w-1/2 flex-col gap-2">
                      {field.value && stashedFileId && (
                        <FileContainer
                          id={uniqueId()}
                          fileName={field.value.name}
                          fileSize={`${sizeToMb(field.value.size)} MB`}
                          status={fileContainerStatus}
                          percent={uploadFileSuccess ? 100 : 0}
                          onClose={() => {
                            field.onChange();
                            setStashedFileId(undefined);
                          }}
                          helperMessage=""
                        />
                      )}
                    </div>
                  </div>
                )}
              </Form.Control>
              <Form.Error />
            </Form.Field>

            <div>
              <Button variant="text" color="primary" asChild>
                <a href={`${HELP_URL}/article/127-import-and-export-a-glossary`} target="_blank" rel="noreferrer">
                  <AccessibleIcon icon="ri-lightbulb-fill" label="light-bulb" />
                  {tGlossary('readMoreAboutImportingGlossaryTerms')}
                </a>
              </Button>
              <InlineMessage.Root
                type="informational"
                orientation="horizontal"
                rootClassName="my-8 w-full"
                className="w-full"
              >
                <InlineMessage.Text
                  className="flex flex-grow items-center justify-between text-blue-800"
                  weight="semibold"
                >
                  {tGlossary('youCanDownloadTheExcelTemplateHere')}
                  <ButtonLink
                    href={`${APP_URL}/files/private/glossary-import-template-excel.xlsx`}
                    color="secondary"
                    variant="solid"
                    size="sm"
                  >
                    {tGlossary('download')}
                  </ButtonLink>
                </InlineMessage.Text>
              </InlineMessage.Root>
            </div>
          </Surface>
          <Button
            variant="solid"
            color="primary"
            className="self-end"
            type="submit"
            disabled={!form.formState.isValid || uploadFileError || uploadFileLoading || !stashedFileId}
          >
            {tGlossary('continue')}
          </Button>
        </Form.Root>
      </Show>
    </Layout>
  );
};

const useGlossaryUploadEntryParams = () => {
  const params = useParams<{ glossaryId: string }>();
  const parsedParams = useMemo(() => ({ glossaryId: parseInt(params.glossaryId) }), [params]);
  return parsedParams;
};
