import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { DocumentTranslationDto } from '@/redux/api/types';

export interface LanguageCellProps {
  documentId: number;
}

export const LanguageCell = (props: LanguageCellProps) => {
  const { documentId } = props;

  const { cell } = DataTable.useCellContext<DocumentTranslationDto, DocumentTranslationDto['language']>('LanguageCell');
  const language = cell.getValue();
  const { t: tDocument } = useTranslation('document');

  return (
    <ButtonLink href={`/documents/show/${documentId}/translations/${language.id}`} size="sm" variant="text">
      <FlagIcon label={language.name} code={language.flagCode || 'xx'} size="sm" />
      {language.name}
      <AccessibleIcon icon="ri-external-link-line" label={tDocument('editorIcon')} />
    </ButtonLink>
  );
};
