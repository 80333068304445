import * as Switch from '@radix-ui/react-switch';
import { ElementRef, forwardRef } from 'react';

import { classNames } from '@/utils/classNames';
import { SwitchProps } from '@/components/v2/Toggle/types';
import { knobVariant, toggleVariant } from '@/components/v2/Toggle/styles';

//todo: bug of double-click needed when state is previously changed programmatically via hook-form
// issue: search for keyowrds like "double click" or "toggles" in GH issues
export const Toggle = forwardRef<ElementRef<typeof Switch.Root>, SwitchProps>(
  ({ size = 'md', checked, className, ...props }, ref) => (
    <Switch.Root ref={ref} className={classNames(toggleVariant({ size, checked }), className)} {...props}>
      <Switch.Thumb className={knobVariant({ size, checked })} />
    </Switch.Root>
  )
);

Toggle.displayName = 'Toggle';
