import { isNumber } from 'lodash';
import { Trans } from 'react-i18next';

import { Alert } from '@/components/v2/Alert';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { SubscriptionSuspendedReason } from '@/redux/api/constants';
import { diff } from '@/utils/date';

/**
 * Refer to this Notion page https://www.notion.so/redokun/Account-suspension-14536d1964168013a0c9d7082a4cb32a
 */
export const NavbarAlert = () => {
  const { currentAccount } = useCurrentAccount();
  const { subscriptionInfo } = currentAccount;

  const currentSubDaysLeft = subscriptionInfo.currentSubEndAt
    ? diff(subscriptionInfo.currentSubEndAt, new Date(), 'days')
    : null;

  // Subscription Expiring
  if (isNumber(currentSubDaysLeft) && currentSubDaysLeft <= 5 && !subscriptionInfo.isSuspended) {
    return (
      <Alert status="warning">
        <Trans
          i18nKey={`components:navbar.${
            subscriptionInfo.isTranslator
              ? 'translatorYourSubscriptionWillExpireRenewNow'
              : 'yourSubscriptionWillExpireRenewNow'
          }`}
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            a: <a href={`${APP_URL}/settings/plans`} className="underline" />
          }}
          values={{ count: currentSubDaysLeft }}
        />
      </Alert>
    );
  }

  // Subscription Expired
  if (
    subscriptionInfo.isSuspended &&
    subscriptionInfo.suspensionReason === SubscriptionSuspendedReason.SUBSCRIPTION_EXPIRED
  ) {
    return (
      <Alert status="error">
        <Trans
          i18nKey={`components:navbar.${
            subscriptionInfo.isTranslator
              ? 'translatorYourSubscriptionExpiredRenewNow'
              : 'yourSubscriptionExpiredRenewNow'
          }`}
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            a: <a href={`${APP_URL}/settings/plans`} className="underline" />
          }}
        />
      </Alert>
    );
  }

  // Invoices with Payments Due (Soft Alert)
  if (subscriptionInfo.hasPastDueInvoice && !subscriptionInfo.isSuspended) {
    return (
      <Alert status="warning">
        <Trans
          i18nKey={`components:navbar.${
            subscriptionInfo.isTranslator
              ? 'translatorWeCouldntProcessPaymentUpdateCardDetails'
              : 'weCouldntProcessPaymentUpdateCardDetails'
          }`}
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            a: <a href={`${APP_URL}/settings/billing/payment-method`} className="underline" />
          }}
          values={{ count: currentSubDaysLeft }}
        />
      </Alert>
    );
  }

  // Invoices with Payments Due (Account Blocked)
  if (subscriptionInfo.hasPastDueInvoice && subscriptionInfo.isSuspended) {
    return (
      <Alert status="error">
        <Trans
          i18nKey={`components:navbar.${
            subscriptionInfo.isTranslator
              ? 'translatorWeCouldntProcessPaymentUpdateCardDetailsReadOnly'
              : 'weCouldntProcessPaymentUpdateCardDetailsReadOnly'
          }`}
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            a: <a href={`${APP_URL}/settings/billing/payment-method`} className="underline" />
          }}
        />
      </Alert>
    );
  }

  return null;
};
