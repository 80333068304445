import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { button } from './styles';
import { ButtonElement, ButtonProps } from './types';

const Button = forwardRef<ButtonElement, ButtonProps>(({ asChild, className, color, size, variant, ...props }, ref) => {
  const Component = asChild ? Slot : 'button';
  return <Component {...props} className={classNames(button({ color, size, variant }), className)} ref={ref} />;
});
Button.displayName = 'Button';

export { Button };
