import { TFunction } from 'react-i18next';

import { BillingFrequencyEnum } from '@/redux/api/types';

export const createBillingFrequencyLabels = (tSettings: TFunction<'settings'>) => {
  const billingFrequencyLabels: { [key in BillingFrequencyEnum]: string } = {
    [BillingFrequencyEnum.YEARLY]: tSettings('billedAnnually'),
    [BillingFrequencyEnum.MONTHLY]: tSettings('billedMonthly')
  };
  return billingFrequencyLabels;
};
