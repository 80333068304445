import { cva } from 'cva';

export const fileContainerVariant = cva('overflow-hidden overflow-ellipsis rounded-xl border px-4 py-2', {
  variants: {
    status: {
      active: 'border-alpha-200 bg-white',
      completed: 'border-alpha-200 bg-white',
      warning: 'border-states-alert-active bg-surface-alert',
      error: 'border-states-negative-active bg-surface-error'
    },
    size: {
      sm: 'gap-1',
      md: 'gap-3'
    }
  },
  defaultVariants: {
    status: 'active',
    size: 'sm'
  }
});
