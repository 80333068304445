import { first } from 'lodash';

type FormatOptions = Intl.NumberFormatOptions;
const format = (value: number | bigint, options?: FormatOptions) => {
  const language = navigator.language || first(navigator.languages);
  const formatter = new Intl.NumberFormat(language, options);
  return formatter.format(value);
};

type FormatDecimalOptions = Omit<
  Intl.NumberFormatOptions,
  'style' | 'currency' | 'currencyDisplay' | 'currencySign' | 'unit' | 'unitDisplay'
>;
const formatDecimal = (value: number | bigint, options?: FormatDecimalOptions) =>
  format(value, { ...options, style: 'decimal' });

type FormatPercentOptions = Omit<
  Intl.NumberFormatOptions,
  'style' | 'currency' | 'currencyDisplay' | 'currencySign' | 'unit' | 'unitDisplay'
>;
const formatPercent = (value: number | bigint, options?: FormatPercentOptions) =>
  format(value, { ...options, style: 'percent' });

type FormatCurrencyOptions = Omit<Intl.NumberFormatOptions, 'style' | 'unit' | 'unitDisplay'>;
const formatCurrency = (value: number | bigint, options?: FormatCurrencyOptions) =>
  format(value, { ...options, style: 'currency' });

export { format, formatCurrency, formatDecimal, formatPercent };
