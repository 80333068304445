import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

const MenuElement: React.FC<{ text: string; url: string; otherUrls?: string[] }> = ({ url, text, otherUrls }) => {
  const { pathname } = useLocation();
  const isCurrent = (pathname === url || otherUrls?.includes(pathname)) ?? false;

  return (
    <div>
      <Link
        to={url}
        className={`${
          isCurrent ? 'text-typography-primary underline' : 'text-typography-secondary'
        } mb-2.5 block pb-2.5 text-sm font-semibold`}
        // The used version of Tailwind doesn't support underline styling
        style={
          isCurrent
            ? {
                textUnderlineOffset: '14px',
                textDecorationThickness: '2px',
                textDecorationColor: '#1f6cdb'
              }
            : {}
        }
      >
        {text}
      </Link>
    </div>
  );
};

export const LeftMenu: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { currentAccount } = useCurrentAccount();

  return (
    <div className="w-60 pt-14">
      <div className="min-h-screen px-8 pb-8">
        <div className="">
          <Text size="sm" color="tertiary" weight={'bold'} className="uppercase">
            {tSettings('general')}
          </Text>
        </div>
        <div className="p-3 pt-4">
          <MenuElement text={tSettings('account_settings')} url={'/settings'} />

          {/* Some sections should not be accessible to translators */}
          <Show when={!currentAccount.subscriptionInfo.isTranslator}>
            <MenuElement text={tSettings('languages')} url={'/settings/languages'} />
            <MenuElement text={tSettings('translators')} url={'/settings/translators'} />
            <MenuElement text={tSettings('managers')} url={'/settings/managers'} />
            <MenuElement text={tSettings('segmentation')} url={'/settings/segmentation'} />
          </Show>

          <MenuElement text={tSettings('notifications')} url={'/settings/notifications'} />
          <MenuElement text={tSettings('advanced_settings')} url={'/settings/advanced'} />
          <MenuElement text={tSettings('delete_account')} url={'/settings/delete-account'} />
        </div>
        <div className="mt-8">
          <Text size="sm" color="tertiary" weight={'bold'} className="uppercase">
            {tSettings('subscription')}
          </Text>
        </div>
        <div className="p-3 pt-4">
          <MenuElement
            text={tSettings('current_plan')}
            url={'/settings/current-plan'}
            otherUrls={['/settings/current-plan/subscription-history']}
          />

          {/* @todo: The "Plans" page is disabled, and we use the old one for now. Change when ready */}
          <a
            className={`mb-2.5 block pb-2.5 text-sm font-semibold text-typography-secondary`}
            href={`${APP_URL}/settings/plans`}
          >
            {tSettings('plans')}
          </a>

          {!currentAccount.subscriptionInfo.isTrial && currentAccount.hasBillingData ? (
            <MenuElement
              text={tSettings('billing')}
              url={'/settings/billing'}
              otherUrls={['/settings/billing/change']}
            />
          ) : null}
        </div>
        <div className="mt-8">
          <Text size="sm" color="tertiary" weight={'bold'} className="uppercase">
            {tSettings('security')}
          </Text>
        </div>
        <div className="p-3 pt-4">
          <MenuElement text={tSettings('change_password')} url={'/settings/change-password'} />
        </div>
      </div>
    </div>
  );
};
