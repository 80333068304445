import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { useDocumentRevisionRollbackMutation } from '@/redux/api';
import { Text } from '@/components/v2/Text';

export const ConfirmRevisionRollbackModal = (props: DeleteDocumentModalProps) => {
  const { documentId, children, documentDetails, ...modalProps } = props;
  const history = useHistory();

  const { t } = useTranslation('documentRevision');

  const [doRevisionRollback, { isLoading, isSuccess }] = useDocumentRevisionRollbackMutation();

  const handleConfirmRollbackClick = async () => {
    doRevisionRollback({ documentId })
      .unwrap()
      .then((data) => {
        history.push(`/documents/show/${data.latestRevisionDocumentId}`);
      })
      .catch(() => {
        history.push(`/documents`);
      });
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title size="lg">{t('revertToPreviousRevisionQuestion')}</Modal.Title>
          <Modal.Description size="md">{t('theOldRevisionOfTheDocumentWillBeRecovered')}</Modal.Description>
        </Modal.Header>

        <div className="flex-col space-y-2">
          <div className="flex items-center space-x-3">
            <Text color="tertiary" size={'md'}>
              File name
            </Text>
            <Text color="secondary" size={'md'}>
              {documentDetails.name}
            </Text>
          </div>
          <div className="flex items-center space-x-3">
            <Text color="tertiary" size={'md'}>
              {t('uploadedOn')}
            </Text>
            <Text color="secondary" size={'md'}>
              {documentDetails.uploadDate}
            </Text>
          </div>
          <div className="flex items-center space-x-3">
            <Text color="tertiary" size={'md'}>
              Revision
            </Text>
            <Text color="secondary" size={'md'}>
              {documentDetails.revision}
            </Text>
          </div>
        </div>

        <Modal.Footer orientation={'horizontal'}>
          <Button color="primary" onClick={handleConfirmRollbackClick} disabled={isLoading || isSuccess}>
            {t('revertToPreviousRevision')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {t('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface DeleteDocumentModalProps extends ModalProps {
  documentId: number;
  documentDetails: {
    name: string;
    uploadDate: string;
    revision: number;
  };
  onDelete?: () => void;
}
