import { classNames } from '@/utils/classNames';

import { Label } from '../Label';
import { Show } from '../Show';
import { TDividerProps } from './types';

export const Divider = (props: TDividerProps) => {
  const currentProps = { type: 'horizontal' as const, ...props };
  const { type, className } = currentProps;
  const hasChildren = type === 'horizontal' && !!currentProps.children;

  const divider = (
    <div
      className={classNames(
        { 'h-px w-full border-t': type === 'horizontal', 'h-full w-px border-r': type === 'vertical' },
        !hasChildren && className
      )}
    />
  );

  return (
    <Show when={hasChildren && currentProps} fallback={divider}>
      {({ children, extra, noPadding }) => (
        <div className={classNames('w-full', className)}>
          <div className={classNames('flex', !noPadding && 'px-4')}>
            <Label underline className="pb-0.5 font-bold">
              {children}
            </Label>

            {extra}
          </div>

          {divider}
        </div>
      )}
    </Show>
  );
};
