import { sumBy } from 'lodash';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import banner from '@/assets/onboarding-banner.png';
import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import Breadcrumb from '@/components/v2/Breadcrumb';
import { Button } from '@/components/v2/Button';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import {
  useGetFoldersQuery,
  useUpdateJobInvitationStatusMutation,
  useUpdateTeamInvitationStatusMutation
} from '@/redux/api';
import { UpdateTeamInvitationStatusArgs } from '@/redux/api/types';
import { ButtonUpload } from '@/routes/DocumentList/components/DocumentListHeader/components/ButtonUpload';
import { CreateFolderModal } from '@/routes/DocumentList/components/DocumentListHeader/components/CreateFolderModal/CreateFolderModal';
import { DocumentListHeaderProps } from '@/routes/DocumentList/components/DocumentListHeader/types';
import { getCurrentFolder, getPathToFolder } from '@/utils/folder';
import { isNotEmpty } from '@/utils/isNotEmpty';

import { MultiplePendingJobInvitation } from './components/MultiplePendingJobInvitation';
import { PendingTeamInvitation } from './components/PendingTeamInvitation';
import { SinglePendingJobInvitation } from './components/SinglePendingJobInvitation';

export const DocumentListHeader = ({ currentFolderId }: DocumentListHeaderProps) => {
  const { t: tDocumentList } = useTranslation('documentList');
  const { t: tDocument } = useTranslation('document');

  const { data: rootFolder, isLoading, isUninitialized } = useGetFoldersQuery();

  const [updateJobInvitation, { isLoading: isJobInvitationLoading }] = useUpdateJobInvitationStatusMutation();
  const [updateTeamInvitation, { isLoading: isTeamInvitationLoading }] = useUpdateTeamInvitationStatusMutation();

  const { currentAccount } = useCurrentAccount();

  const { pendingJobInvites, pendingTeamJoinInvite } = currentAccount;

  const pathToFolder = useMemo(
    () => getPathToFolder(currentFolderId, rootFolder?.children),
    [currentFolderId, rootFolder?.children]
  );

  const currentFolder = useMemo(() => getCurrentFolder(pathToFolder, currentFolderId), [currentFolderId, pathToFolder]);

  if (isUninitialized || isLoading) {
    return (
      <div className="flex justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  const breadcrumbPath = [{ id: 0, name: tDocument('documents') }, ...pathToFolder];
  const breadcrumbsGenerator = breadcrumbPath.map((folder, index) => {
    const addIfSubFolder = folder.id ? `folder/${folder.id}` : ``;
    const folderNameNotClickableIfCurrent = folder.active ? (
      <span className="whitespace-nowrap">{folder.name}</span>
    ) : (
      <Link to={`/documents/${addIfSubFolder}`} className="whitespace-nowrap">
        {folder.name}
      </Link>
    );

    return (
      <Breadcrumb.Item key={folder.id} className="max-w-[124px]">
        <OverflowTooltip label={folder.name}>
          <Breadcrumb.Link asChild active={folder.active}>
            {folderNameNotClickableIfCurrent}
          </Breadcrumb.Link>
        </OverflowTooltip>
        {index < breadcrumbPath.length - 1 && <Breadcrumb.Separator />}
      </Breadcrumb.Item>
    );
  });

  const currentFolderName = currentFolder ? currentFolder.name : tDocumentList('documentList');

  const showPendingJobInvitation = isNotEmpty(pendingJobInvites);
  const pendingInvitationCount = sumBy(pendingJobInvites, (invite) => (invite.jobs ? invite.jobs.length : 0));

  const handleAcceptInvitation = (invitationId: number) => {
    updateJobInvitation({
      translationJobId: invitationId,
      status: 'ACCEPTED'
    });
  };

  const handleTeamInvitation = (action: UpdateTeamInvitationStatusArgs['action'], teamMembershipId: number) => {
    updateTeamInvitation({ teamMembershipId, action });
  };

  const canUploadAndCreateFolder = currentAccount?.subscriptionInfo.isActive;
  const isTrial = currentAccount.subscriptionInfo.isTrial;
  const isTranslator = currentAccount.subscriptionInfo.isTranslator;

  // The key prop resets `CreateFolderModal` internal state when `rootFolder` or `currentFolderId` changes
  const createFolderModalKey = `${JSON.stringify(rootFolder)}-${currentFolderId}`;

  return (
    <div>
      <Helmet>
        <title>{currentFolderName}</title>
      </Helmet>
      <Show when={isTrial && !isTranslator}>
        <a href="https://bit.ly/3XZIdO8" target="_blank" rel="noreferrer">
          <div className="mb-6 flex w-full justify-center rounded-2xl bg-blue-900 p-2">
            <img src={banner} alt="onboarding banner" />
          </div>
        </a>
      </Show>
      <Breadcrumb.Root size="sm">
        <Breadcrumb.List>{breadcrumbsGenerator}</Breadcrumb.List>
      </Breadcrumb.Root>
      <div className="flex flex-1 flex-row py-2">
        <div className="overflow-hidden break-words">
          <OverflowTooltip label={currentFolderName}>
            <Text size="xl" weight="bold" color="primary" className="whitespace-nowrap">
              {currentFolderName}
            </Text>
          </OverflowTooltip>
        </div>

        <div className="ml-auto flex flex-row gap-3">
          <Show when={canUploadAndCreateFolder}>
            <CreateFolderModal key={createFolderModalKey} currentFolderId={currentFolderId}>
              <Button variant="surface" color="secondary">
                <AccessibleIcon icon="ri-folder-add-line" label={tDocumentList('newFolder')} />
                {tDocumentList('newFolder')}
              </Button>
            </CreateFolderModal>

            <ButtonUpload folderId={currentFolder?.id} />
          </Show>
        </div>
      </div>
      {/* Show team invitation only in document list root and if there is at least one pending invite*/}
      <Show when={pendingTeamJoinInvite}>
        {(pendingTeamJoinInvite) => (
          <PendingTeamInvitation
            pendingTeamJoinInvite={pendingTeamJoinInvite}
            isLoading={isTeamInvitationLoading}
            onAccept={(id) => handleTeamInvitation('ACCEPT', id)}
            onIgnore={(id) => handleTeamInvitation('IGNORE', id)}
          />
        )}
      </Show>
      {/* Show job invitation only in document list root and if there is at least one pending invite*/}
      <Show when={showPendingJobInvitation}>
        <Show
          when={pendingInvitationCount > 1}
          fallback={
            <SinglePendingJobInvitation
              invite={pendingJobInvites[0]}
              isLoading={isJobInvitationLoading}
              onConfirm={handleAcceptInvitation}
            />
          }
        >
          <MultiplePendingJobInvitation
            pendingJobInvites={pendingJobInvites}
            isLoading={isJobInvitationLoading}
            onConfirm={handleAcceptInvitation}
          />
        </Show>
      </Show>
    </div>
  );
};
DocumentListHeader.displayName = 'DocumentListHeader';
