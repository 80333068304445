import { Row } from '@tanstack/react-table';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetTranslationMemoriesQuery } from '@/redux/api/translationMemoryApi';
import { formatDecimal } from '@/utils/number';

import { useColumns } from './columns';
import { BatchOperationHeader } from './components/BatchOperationHeader';
import { createDataForTranslationMemoriesRows } from './createDataForTranslationMemoriesRows';
import { TranslationMemoriesTableRow } from './types';
import { RequireUpgrade } from '@/components/v2/RequireUpgrade';

const TranslationMemory = () => {
  const { t: tTM } = useTranslation('translationMemory');

  const { currentAccount } = useCurrentAccount();
  const { importedTmItemsUsage } = currentAccount;
  const formattedThreshold = formatDecimal(importedTmItemsUsage.threshold);
  const formattedUsed = formatDecimal(importedTmItemsUsage.threshold - importedTmItemsUsage.remaining);

  const {
    data: translationMemories,
    isLoading: translationMemoriesIsLoading,
    isUninitialized: translationMemoriesIsUninitialized
  } = useGetTranslationMemoriesQuery();

  const columns = useColumns();

  if (translationMemoriesIsUninitialized || translationMemoriesIsLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  const titleBlock = (
    <div className="flex justify-between">
      {/* Text header */}
      <div>
        <Text color="primary" size="xl" weight={'bold'}>
          {tTM('translationMemory')}
        </Text>
      </div>
      {/* Links */}
      <div className="flex items-center space-x-3">
        <RequireSubscription>
          <RequireUpgrade feature="tmManager">
            <ButtonLink href={`${APP_URL}/translation-memories/manager`} color="secondary">
              <AccessibleIcon icon="ri-handbag-line" label="ri-handbag-line" className="text-xl" />
              {tTM('tMManager')}
            </ButtonLink>
          </RequireUpgrade>
        </RequireSubscription>

        <RequireSubscription>
          <RequireUpgrade feature="tmImport">
            <ButtonLink href={'/translation-memories/upload'} color="primary">
              <AccessibleIcon icon="ri-upload-2-fill" label="ri-upload-2-fill" className="text-xl" />
              {tTM('uploadTM')}
            </ButtonLink>
          </RequireUpgrade>
        </RequireSubscription>
      </div>
    </div>
  );

  const limitBlock = (
    <Surface className="grid grid-cols-2 items-center gap-7 px-7 py-8">
      <div>
        <Text size="sm" weight="medium" color="tertiary">
          {tTM('importedTmRecords')}
        </Text>
        <Text size="xl" weight="bold" color="secondary">
          {formattedUsed} / {formattedThreshold}
        </Text>
      </div>

      <div>
        <Text size="sm" weight="medium" color="tertiary">
          <Trans
            i18nKey="translationMemory:youCanImportMaximumTmRecordsPleaseUpgrade"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ a: <a href={`${APP_URL}/settings/plans`} className="text-blue-600 underline" /> }}
            values={{ threshold: formattedThreshold }}
          />
        </Text>
      </div>
    </Surface>
  );

  const tableBlock = (
    <DataTable.Root
      columns={columns}
      data={createDataForTranslationMemoriesRows(tTM, translationMemories)}
      meta={{
        isContentOnly: (row: Row<TranslationMemoriesTableRow>) => {
          return row.original.segmentCount === null;
        }
      }}
      loading={translationMemoriesIsLoading}
    >
      <DataTable.Content layout="fixed">
        <DataTable.Header selection={<BatchOperationHeader />} />
        <DataTable.Body />
      </DataTable.Content>
    </DataTable.Root>
  );

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tTM('translationMemory')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {titleBlock}
        {limitBlock}
        {tableBlock}
      </div>
    </div>
  );
};
TranslationMemory.displayName = 'TranslationMemory';

export { TranslationMemory };
