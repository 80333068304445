import { Avatar, AvatarFallback, AvatarGroup, AvatarImage, AvatarIndicator } from './Avatar';

const AvatarObject = {
  Root: Avatar,
  Fallback: AvatarFallback,
  Image: AvatarImage,
  Indicator: AvatarIndicator
};

export type {
  AvatarFallbackProps,
  AvatarGroupProps,
  AvatarImageProps,
  AvatarIndicatorProps,
  AvatarProps
} from './types';
export { Avatar, AvatarFallback, AvatarGroup, AvatarImage, AvatarIndicator };

export default AvatarObject;
