import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { z } from 'zod';

const zStripeElement = () =>
  z.object({
    elementType: z.string(),
    empty: z.boolean(),
    complete: z.boolean(),
    error: z
      .object({
        type: z.literal('validation_error'),
        code: z.string(),
        message: z.string()
      })
      .optional()
  }) as z.ZodType<StripeElementChangeEvent>;

const paymentMethodFormSchema = z.object({
  cardHolderName: z.string().min(3).max(50),
  cardNumber: zStripeElement().refine(
    (element) => !element?.error,
    (element) => ({ message: element?.error?.message })
  ),
  cardExpiry: zStripeElement().refine(
    (element) => !element?.error,
    (element) => ({ message: element?.error?.message })
  ),
  cardCvc: zStripeElement().refine(
    (element) => !element?.error,
    (element) => ({ message: element?.error?.message })
  )
});
type PaymentMethodFormSchema = z.infer<typeof paymentMethodFormSchema>;

const defaultValues: PaymentMethodFormSchema = {
  cardHolderName: '',
  cardNumber: undefined as unknown as StripeElementChangeEvent,
  cardExpiry: undefined as unknown as StripeElementChangeEvent,
  cardCvc: undefined as unknown as StripeElementChangeEvent
};

export { defaultValues, paymentMethodFormSchema };
export type { PaymentMethodFormSchema };
