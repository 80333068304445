export const INFORMATIONAL = 'informational';

export const ICONS = {
  informational: 'ri-question-fill',
  success: 'ri-checkbox-circle-fill',
  warning: 'ri-information-fill',
  error: 'ri-alert-fill'
} as const;

export const ICONS_COLOR = {
  secondary: 'text-typography-secondary',
  informational: 'text-blue-500',
  success: 'text-green-500',
  warning: 'text-yellow-500',
  error: 'text-red-500'
} as const;

export const ICON_SIZES = {
  md: 'text-3xl',
  sm: 'text-xl'
} as const;
