import { KeyboardEvent, useEffect } from 'react';

import Form from '@/components/v2/Form';
import { Input } from '@/components/v2/Input';

import type { SavedViewFormProps } from './types';
import { SavedViewFormSchema, useSavedViewForm } from './useSavedViewForm';

const SavedViewForm = (props: SavedViewFormProps) => {
  const { name, onSubmit, onReset } = props;

  const form = useSavedViewForm({ defaultValues: { name }, values: { name } });
  const { formState, setFocus } = form;
  const { isValid, isDirty, isSubmitting } = formState;

  useEffect(() => {
    // Workaround: focus on the input after the dropdown closes
    const timeout = setTimeout(() => {
      setFocus('name');
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, [setFocus]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      onReset();
    }
  };

  const handleBlur = () => {
    if (!isValid) {
      return;
    }

    if (isDirty) {
      form.handleSubmit(onSubmit)();
    } else {
      onReset();
    }
  };

  const handleSubmit = async (data: SavedViewFormSchema) => {
    if (isDirty) {
      await onSubmit(data);
    } else {
      onReset();
    }
  };

  return (
    <Form.Root {...form} onSubmit={handleSubmit} className="flex-1">
      <Form.Field name="name">
        <Form.Control>
          <Input size="sm" onKeyDown={handleKeyDown} onBlur={handleBlur} disabled={isSubmitting} />
        </Form.Control>
      </Form.Field>
    </Form.Root>
  );
};
SavedViewForm.displayName = 'SavedViewForm';

export { SavedViewForm };
