import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COLORS, INITIAL_STATE } from './constants';
import type { ITeamMemberColor } from './types';
import { getRandomColor } from './utils';

// TODO: probably we should merge presence and team members slices
// and keep all team members related things in the same place
export const teamMembersSlice = createSlice({
  name: 'teamMembers',
  initialState: INITIAL_STATE,
  reducers: {
    addTeamMemberColor: {
      prepare: (id: number, color?: ITeamMemberColor) => {
        const currentColor = color || getRandomColor(COLORS);
        return { payload: { id, color: currentColor } };
      },
      reducer: (state, { payload }: PayloadAction<{ id: number; color: ITeamMemberColor }>) => {
        const { id, color } = payload;
        if (!(id in state.colors)) {
          state.colors[id] = color;
        }
      }
    },
    setTeamMemberColor: {
      prepare: (id: number, color?: ITeamMemberColor) => {
        const currentColor = color || getRandomColor(COLORS);
        return { payload: { id, color: currentColor } };
      },
      reducer: (state, { payload }: PayloadAction<{ id: number; color: ITeamMemberColor }>) => {
        const { id, color } = payload;
        state.colors[id] = color;
      }
    },
    removeTeamMemberColor: (state, { payload }: PayloadAction<number>) => {
      delete state.colors[payload];
    }
  }
});

export const { addTeamMemberColor, setTeamMemberColor, removeTeamMemberColor } = teamMembersSlice.actions;
