import type { ElementType } from 'react';

import { Box } from '../../Box';
import { DEFAULT_ELEMENT } from './constants';
import type { TDefaultElement, TPlaceholderProps } from './types';
import { getClassName, getLabel } from './utils';

export const Placeholder = <E extends ElementType = TDefaultElement>(props: TPlaceholderProps<E>) => {
  const { value, ring, className, ...boxProps } = props;
  const classNameOptions = { ring, className };

  return (
    // @ts-expect-error: TS 5 migration error
    <Box as={DEFAULT_ELEMENT} className={getClassName(value, classNameOptions)} {...boxProps}>
      {getLabel(value)}
    </Box>
  );
};
