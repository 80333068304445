import { useTranslation } from 'react-i18next';

import { TMSuggestionIcon } from '../TMSuggestionIcon';
import { TMSuggestionMore } from '../TMSuggestionMore';
import { TMSuggestionReference } from '../TMSuggestionReference';
import { TranslationSuggestion } from '../TranslationSuggestion';
import type { ITMSuggestionProps } from './types';

export const TMSuggestion = ({
  suggestion,
  shortcut,
  showShortcut,
  sourceLanguage,
  targetLanguage,
  weConfig,
  onClick
}: ITMSuggestionProps) => {
  const { t } = useTranslation('documentLanguageParagraph');

  const reference = weConfig.tmSourceIndicatorEnabled && suggestion.reference && (
    <TMSuggestionReference reference={suggestion.reference} />
  );

  const sourcesComparison = suggestion.matchRate < 100 ? suggestion.sourcesComparison : null;

  return (
    <TranslationSuggestion
      key={suggestion.id}
      showShortcut={showShortcut}
      shortcut={shortcut}
      sourceLanguage={sourceLanguage}
      targetLanguage={targetLanguage}
      cleanSuggestion={suggestion.contentWithoutPlaceholders}
      reference={reference}
      sourcesComparison={sourcesComparison}
      icon={<TMSuggestionIcon matchRate={suggestion.matchRate} />}
      more={<TMSuggestionMore suggestionId={suggestion.id} />}
      tooltip={t('matchFromTranslationMemories', { matchRate: suggestion.matchRate })}
      onClick={onClick}
    />
  );
};
