import type { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Divider } from '@/components/v1/Divider';
import { useDropdownContext } from '@/components/v1/Dropdown';
import { RotateLeftIcon } from '@/icons/solid';
import { composeEventHandlers } from '@/utils/composeEventHandlers';

export interface IActivityItemEventMenuProps {
  onRestore?: () => void;
  className?: string;
  style?: CSSProperties;
}

export const ActivityItemEventMenu = (props: IActivityItemEventMenuProps) => {
  const { className, style, onRestore } = props;

  const { t } = useTranslation('components');
  const { close } = useDropdownContext('ActivityItemEventMenu');

  return (
    <div className={className} style={style}>
      <Divider>{t('activityItem.actions')}</Divider>

      <div className="px-4 pb-1 pt-2">
        <Button btnType="text" icon={<RotateLeftIcon />} size="sm" onClick={composeEventHandlers(onRestore, close)}>
          {t('activityItem.restoreTranslation')}
        </Button>
      </div>
    </div>
  );
};
ActivityItemEventMenu.displayName = 'ActivityItemEventMenu';
