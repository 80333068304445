import { cva } from 'cva';

import s from './DropdownMenu.module.css';

const dropdownItem = cva(
  [
    s['dropdown-item'],
    'outline-none flex cursor-pointer select-none items-center gap-3 rounded-lg bg-white px-2.5 py-2 text-base font-medium leading-6 ring-2 ring-transparent transition data-disabled:cursor-not-allowed data-highlighted:bg-gray-50 data-highlighted:ring-primary-active'
  ],
  {
    variants: {
      color: {
        primary: 'text-typography-primary data-disabled:opacity-20',
        negative: 'text-red-500 data-disabled:opacity-30'
      }
    },
    defaultVariants: {
      color: 'primary'
    }
  }
);

export { dropdownItem };
