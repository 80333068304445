import { cva } from 'cva';

const title = cva('whitespace-pre-line', {
  variants: {
    color: {
      primary: '',
      secondary: '',
      tertiary: ''
    },
    size: {
      xs: 'text-sm leading-[1.375rem]',
      sm: 'text-base leading-6',
      md: 'text-xl leading-8',
      lg: 'text-2xl leading-[2.375rem]'
    },
    theme: {
      light: '',
      dark: ''
    },
    weight: {
      regular: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold'
    }
  },
  compoundVariants: [
    {
      theme: 'light',
      color: 'primary',
      className: 'text-typography-primary'
    },
    {
      theme: 'light',
      color: 'secondary',
      className: 'text-typography-secondary'
    },
    {
      theme: 'light',
      color: 'tertiary',
      className: 'text-typography-tertiary'
    },
    {
      theme: 'dark',
      color: 'primary',
      className: 'text-typography-inverse-primary'
    },
    {
      theme: 'dark',
      color: 'secondary',
      className: 'text-typography-inverse-secondary'
    },
    {
      theme: 'dark',
      color: 'tertiary',
      className: 'text-typography-inverse-tertiary'
    }
  ],
  defaultVariants: {
    color: 'primary',
    size: 'md',
    theme: 'light',
    weight: 'bold'
  }
});

export { title };
