import { debounce } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { NestedFolderStructureItem } from '@/components/v2/AccordionNested';
import { Button } from '@/components/v2/Button';
import { FolderBrowser } from '@/components/v2/FolderBrowser';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { Show } from '@/components/v2/Show';
import { useToast } from '@/components/v2/Toast';
import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { useCreateFolderMutation, useGetFoldersQuery } from '@/redux/api';
import { FolderHierarchyDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { isNotEmpty } from '@/utils/isNotEmpty';

interface ChangeFolderModalProps extends ModalProps {
  defaultSelectedItem?: string;
  children: ReactNode;
  onConfirm?: (folderId?: string) => void;
}

export const ChangeFolderModal = (props: ChangeFolderModalProps) => {
  const { defaultSelectedItem = DOCUMENT_LIST_MOCK_ID, children, onConfirm, onOpenChange, ...modalProps } = props;

  const { toast } = useToast();
  const { t: tUploadDocuments } = useTranslation('uploadDocuments');
  const [createFolder, { isLoading }] = useCreateFolderMutation();
  const { data } = useGetFoldersQuery();

  const transformFolders = useCallback((folders: FolderHierarchyDto[]): NestedFolderStructureItem[] => {
    return folders.map((folder) => ({
      id: String(folder.id),
      name: folder.name,
      hasFolderIcon: true,
      children: folder.children ? transformFolders(folder.children) : []
    }));
  }, []);

  const defaultNestedFolders: NestedFolderStructureItem[] = useMemo(
    () => [
      {
        id: DOCUMENT_LIST_MOCK_ID,
        name: tUploadDocuments('documentList'),
        hasFolderIcon: false,
        children: data?.children ? transformFolders(data.children) : []
      }
    ],
    [data?.children, tUploadDocuments, transformFolders]
  );

  const [nestedFolders, setNestedFolders] = useState(defaultNestedFolders);
  // store current selected folder id
  const [selected, setSelected] = useState<string | undefined>(defaultSelectedItem);
  // folder creation state
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState<boolean>(false);
  // list of accordion's folder ids
  const [openedAccordionIds, setOpenedAccordionIds] = useState([DOCUMENT_LIST_MOCK_ID]);

  // on search filter folders with given query
  const filterFolders = (folders: NestedFolderStructureItem[], query: string): NestedFolderStructureItem[] => {
    return folders.reduce((acc: NestedFolderStructureItem[], folder) => {
      const filteredChildren = folder.children ? filterFolders(folder.children, query) : [];

      const folderName = folder.name.toLowerCase();
      const searchQuery = query.toLowerCase();

      const match = folderName.includes(searchQuery);
      // if the folder itself matches or it has any matching children
      if (match || isNotEmpty(filteredChildren)) {
        if (isNotEmpty(filteredChildren)) {
          // open the matched folder parentFolderId accordion
          toggleFolderAccordion(folder.id);
        }
        // return the folder with all its original children if it matches
        // otherwise only filtered children
        const folderWithChildren = { ...folder, children: filteredChildren };
        const item = match ? folder : folderWithChildren;

        return [...acc, item];
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (data?.children) {
      setNestedFolders(defaultNestedFolders);
    }
  }, [data, defaultNestedFolders]);

  const handleSelectItem = (id: string) => {
    setIsCreatingNewFolder(false);

    // if user select the same item twice, deselect it
    if (selected === id) {
      setSelected(undefined);
    } else {
      setSelected(id);
    }
  };

  const toggleFolderAccordion = (folderId: string) => {
    setOpenedAccordionIds((prev) => {
      const updatedSet = new Set(prev);
      updatedSet.add(folderId);
      return Array.from(updatedSet);
    });
  };

  const handleNewFolder = (parentId?: string) => {
    if (!parentId) return;

    // on new folder button clicked
    // open the parent folder accordion
    toggleFolderAccordion(parentId);
    // enable folder creation state
    setIsCreatingNewFolder(true);
  };

  const handleOnFolderCreated = async (parentFolderId: number | null, name: string) => {
    // check if folder name is space only
    if (name.trim() === '') {
      toast({
        title: tUploadDocuments('folderNameCannotBeEmpty'),
        kind: 'error'
      });
    } else {
      const [err] = await to(createFolder({ parentFolderId, name }).unwrap());
      if (err) {
        onOpenChange?.(false);
      }
      // disable folder creation state
      setIsCreatingNewFolder(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.currentTarget.value;

    debounce(() => {
      if (query === '') {
        setNestedFolders(defaultNestedFolders);
        setOpenedAccordionIds([DOCUMENT_LIST_MOCK_ID]);
      } else {
        const filteredFolders = filterFolders(defaultNestedFolders, query);
        setNestedFolders(filteredFolders);
      }
    }, 500)();
  };

  const handleOnOpenChange = (open: boolean) => {
    if (!open) {
      // on modal close, reset state
      setNestedFolders(defaultNestedFolders);
      setSelected(defaultSelectedItem);
      setIsCreatingNewFolder(false);
    }

    onOpenChange?.(open);
  };

  const onSubmit = (folderId?: string) => {
    onConfirm?.(folderId);
  };

  // if user has not selected any item, disable upload and new folder buttons
  const disabled = selected === undefined;

  return (
    <Modal.Root onOpenChange={handleOnOpenChange} {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      {/* prevent closing modal when click outside */}
      <Modal.Content
        className="max-w-md gap-3"
        onInteractOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => {
          // on esc key pressed block folder creation
          setIsCreatingNewFolder(false);
          // prevent closing modal with esc
          e.preventDefault();
        }}
      >
        <Modal.Header className="gap-3">
          <Modal.Title size="md">{tUploadDocuments('selectFolder')}</Modal.Title>
          <Modal.Description size="md">{tUploadDocuments('yourFolders')}</Modal.Description>
          <div
            className="min-w-select-trigger flex w-full gap-2.5 rounded-md border border-gray-200 bg-white px-4"
            onClick={(e) => e.preventDefault()}
          >
            <AccessibleIcon label="ri-search-line" icon="ri-search-line" className="text-lg text-gray-600" />
            <input
              className="outline-none mr-auto flex-1 py-2 text-base text-typography-primary placeholder-typography-tertiary"
              placeholder={tUploadDocuments('searchFolder')}
              onChange={handleSearch}
            />
          </div>
        </Modal.Header>

        <Show
          when={!isLoading}
          fallback={
            <div className="flex h-56 justify-center rounded-lg border border-alpha-200 bg-white">
              <Spinner size="lg" />
            </div>
          }
        >
          <FolderBrowser
            defaultValue={[DOCUMENT_LIST_MOCK_ID]}
            value={openedAccordionIds}
            nestedFolderStructure={nestedFolders}
            selected={selected}
            isCreatingNewFolder={isCreatingNewFolder}
            onSelectItem={handleSelectItem}
            onValueChange={setOpenedAccordionIds}
            onFolderCreated={handleOnFolderCreated}
          />
        </Show>

        <Modal.Footer className="flex flex-col gap-5">
          <div className="flex w-full flex-row justify-end">
            <Button variant="text" color="primary" onClick={() => handleNewFolder(selected)} disabled={disabled}>
              <AccessibleIcon label={tUploadDocuments('newFolder')} icon="ri-add-line" />
              {tUploadDocuments('newFolder')}
            </Button>
          </div>
          <div className="flex w-full flex-row items-center justify-center gap-9">
            <Modal.Close>
              <Button color="secondary" variant="surface" className="w-full">
                {tUploadDocuments('cancel')}
              </Button>
            </Modal.Close>
            <Button color="primary" disabled={disabled} onClick={() => onSubmit(selected)} className="w-full">
              {tUploadDocuments('uploadFilesHere')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
