import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Pagination } from '@/components/v1/Pagination';
import { Show } from '@/components/v1/Show';
import { Spinner } from '@/components/v1/Spinner';
import { useOffline } from '@/hooks/useOffline';
import { CommentsProvider } from '@/providers/CommentsProvider';
import { useCurrentAccountContext } from '@/providers/CurrentAccountProvider';
import {
  useGetBootstrapDataQuery,
  useGetDocumentTranslatedCommentQuery,
  useGetTranslationListQuery
} from '@/redux/api';
import { EBlockedReason, EStandardErrorCode } from '@/redux/api/constants';
import type { INextParagraph, IParagraph } from '@/redux/api/types';
import { isHttpStandardError } from '@/redux/api/utils/isError';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
  nextParagraphFocused,
  openedParagraphComments,
  paragraphBlurred,
  paragraphFocused,
  setPaginationPage,
  setPreviewPageNumber
} from '@/redux/slices/documentLanguageSlice';
import {
  selectDebouncedSearchSource,
  selectDebouncedSearchTarget,
  selectFilter,
  selectHasFilters,
  selectPaginationPage
} from '@/redux/slices/documentLanguageSlice/selectors';
import { openWorkInvitationModal } from '@/redux/slices/modalsSlice';
import { selectImportNotifications } from '@/redux/slices/importSegmentsSlice/selectors';
import { useToast } from '@/components/v2/Toast';
import { resetImportStatus } from '@/redux/slices/importSegmentsSlice/importSegmentsSlice';

import { ActiveMembers } from './components/ActiveMembers';
import { ConnectionState } from './components/ConnectionState';
import { DocumentComments } from './components/DocumentComments';
import { DocumentLanguageHeader } from './components/DocumentLanguageHeader';
import { DocumentLanguageLayout } from './components/DocumentLanguageLayout';
import { DocumentLanguageNotAvailable } from './components/DocumentLanguageNotAvailable';
import { DocumentLanguageParagraph } from './components/DocumentLanguageParagraph';
import { DocumentLanguageParagraphs } from './components/DocumentLanguageParagraphs';
import { DocumentLanguageParagraphsFallback } from './components/DocumentLanguageParagraphsFallback';
import { ParagraphsHeader } from './components/ParagraphsHeader';
import { ParagraphsSearch } from './components/ParagraphsSearch';
import { Preview } from './components/Preview';
import { PAGINATION_LIMIT, ROOT_MARGIN, SCROLL_MARGIN } from './constants';
import { useRealtime } from './hooks';
import { useScrollToParagraph } from './hooks/useScrollToParagraph';
import type { IDocumentLanguageProps } from './types';

const MemoDocumentLanguageParagraph = memo(DocumentLanguageParagraph);

export const DocumentLanguage: React.FC<IDocumentLanguageProps> = ({
  documentId,
  languageId,
  pagePagination,
  paragraphId,
  openComments
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('documentLanguage');
  const { toast } = useToast();
  const { currentAccount } = useCurrentAccountContext();
  const offline = useOffline();

  // Import segments notifications
  const importSegmentsNotifications = useAppSelector(selectImportNotifications);
  useEffect(() => {
    importSegmentsNotifications.forEach((notification) => {
      toast({ ...notification });
    });
    dispatch(resetImportStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Document language state
  const paginationPage = useAppSelector(selectPaginationPage);
  const filter = useAppSelector(selectFilter);
  const debouncedSearchSource = useAppSelector(selectDebouncedSearchSource);
  const debouncedSearchTarget = useAppSelector(selectDebouncedSearchTarget);
  const hasFilters = useAppSelector(selectHasFilters);

  // Presence state
  const { activeMembers } = useAppSelector((state) => state.presence);

  // Bootstrap data query
  const {
    data: bootstrapData,
    error: bootstrapDataError,
    isLoading: bootstrapDataIsLoading
  } = useGetBootstrapDataQuery(
    { documentId, languageId },
    {
      // refetch feature essential for WE
      refetchOnReconnect: true
    }
  );

  // Translation list query
  const translationListQueryArg = useMemo(
    () => ({
      documentId,
      languageId,
      paginationPage,
      filter,
      searchSource: debouncedSearchSource,
      searchTarget: debouncedSearchTarget
    }),
    [documentId, languageId, paginationPage, filter, debouncedSearchSource, debouncedSearchTarget]
  );
  const {
    data: translationList,
    isLoading: isTranslationListLoading,
    isFetching: isTranslationListFetching
  } = useGetTranslationListQuery(translationListQueryArg, {
    // refetch feature essential for WE
    refetchOnReconnect: true
  });

  // Document translated comment query
  const { isLoading: documentTranslatedCommentIsLoading } = useGetDocumentTranslatedCommentQuery(
    {
      documentId,
      languageId
    },
    {
      // refetch feature essential for WE
      refetchOnReconnect: true
    }
  );

  const { notifySelectedParagraphId, notifyUnselectedParagraphId } = useRealtime({
    channelNames: bootstrapData?.pusherChannels,
    currentAccountId: currentAccount.id,
    documentId,
    languageId,
    translationListQueryArg,
    teamMembers: bootstrapData?.teamMembers,
    activeMembers: activeMembers
  });

  const { scrollToParagraphId, scrollToParagraph, setParagraphElement } = useScrollToParagraph();

  // Scroll to paragraph props effect
  useEffect(() => {
    if (pagePagination && paragraphId) {
      scrollToParagraph(paragraphId, pagePagination, { openComments });
    }
  }, [pagePagination, paragraphId, openComments, scrollToParagraph]);

  // Work invitation modal effect
  useEffect(() => {
    if (bootstrapData?.weConfig.blockedReason === EBlockedReason.NOT_ACCEPTED) {
      dispatch(openWorkInvitationModal({ documentId, languageId, accountName: bootstrapData.inviter?.fullName }));
    }
  }, [bootstrapData?.inviter?.fullName, bootstrapData?.weConfig.blockedReason, dispatch, documentId, languageId]);

  const handleTranslationFocus = useCallback(
    (paragraph: IParagraph) => {
      dispatch(paragraphFocused(paragraph));
      notifySelectedParagraphId(paragraph.id);
    },
    [notifySelectedParagraphId, dispatch]
  );

  const handleTranslationBlur = useCallback(
    (paragraph: IParagraph) => {
      dispatch(paragraphBlurred(paragraph));
      notifyUnselectedParagraphId(paragraph.id);
    },
    [notifyUnselectedParagraphId, dispatch]
  );

  const handleGoToFirstEmptyTranslation = useCallback(() => {
    const emptyTranslation = bootstrapData?.firstEmptyTranslation;
    if (emptyTranslation?.exists) {
      scrollToParagraph(emptyTranslation.paragraphId, emptyTranslation.paginationPage);
    }
  }, [bootstrapData?.firstEmptyTranslation, scrollToParagraph]);

  const handlePaginationChange = useCallback(
    (paginationPage: number) => {
      dispatch(setPaginationPage(paginationPage));
    },
    [dispatch]
  );

  const handleGoToNextParagraph = useCallback(
    (nextParagraph: INextParagraph) => {
      dispatch(nextParagraphFocused(nextParagraph));
    },
    [dispatch]
  );

  const handleParagraphsPageChange = useCallback(
    (pageNumber: number) => {
      dispatch(setPreviewPageNumber(pageNumber));
    },
    [dispatch]
  );

  const handleOpenParagraphComments = useCallback(
    (paragraph: IParagraph) => {
      dispatch(openedParagraphComments(paragraph.id));
    },
    [dispatch]
  );

  // Full page error, document not found
  if (isHttpStandardError(bootstrapDataError) && bootstrapDataError.data.code === EStandardErrorCode.NOT_FOUND) {
    return <DocumentLanguageNotAvailable />;
  }

  // First loading bootstrap data
  if (bootstrapDataIsLoading || !bootstrapData || isTranslationListLoading || documentTranslatedCommentIsLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  return (
    <CommentsProvider teamMembers={bootstrapData.teamMembers}>
      <Helmet>
        <title>
          {t('documentLanguage', {
            documentName: bootstrapData.documentName,
            targetLanguageName: bootstrapData.targetLanguage.name
          })}
        </title>
      </Helmet>

      <DocumentLanguageLayout
        subHeader={
          <DocumentLanguageHeader
            documentId={documentId}
            languageId={languageId}
            hasWriteAccess={bootstrapData.hasWriteAccess}
            isBlocked={bootstrapData.weConfig.isBlocked}
            blockedReason={bootstrapData.weConfig.blockedReason}
            documentName={bootstrapData.documentName}
            languageName={bootstrapData.targetLanguage.name}
            languageFlagUrl={bootstrapData.targetLanguage.flagUrl}
          />
        }
        bottomLeft={
          <ConnectionState>
            <ActiveMembers />
          </ConnectionState>
        }
      >
        <div className="mt-10 rounded bg-white px-6 py-4">
          <DocumentComments documentId={documentId} languageId={languageId} />
        </div>

        <div className="relative z-0 mt-10 min-h-screen pb-10">
          <div className="sticky top-0 z-30 h-10 border-b bg-gray-100 py-2">
            <ParagraphsHeader
              segmentsTotalCount={bootstrapData.segmentsTotalCount}
              segmentsTranslatedCount={bootstrapData.segmentsTranslatedCount}
              segmentsDraftCount={bootstrapData.segmentsDraftCount}
              segmentsWithMessagesCount={bootstrapData.segmentsWithMessagesCount}
              segmentsWithGlossaryWarnings={bootstrapData.segmentsWithGlossaryWarnings ?? 0}
              onGoToFirstEmptyTranslation={handleGoToFirstEmptyTranslation}
            />
          </div>

          <div className="flex items-start">
            {bootstrapData.hasPageThumbnails && (
              <div className="sticky top-10 z-20 mr-8 hidden w-80 bg-gray-100 lg:block">
                <Preview pages={translationList?.pages || []} documentType={bootstrapData.documentType} />
              </div>
            )}

            <div className="flex-1">
              <div className="sticky top-10 z-20 flex flex-col justify-end bg-gray-100">
                <ParagraphsSearch />
              </div>

              <Show
                when={!isTranslationListFetching && !!translationList?.paragraphs.length && translationList}
                fallback={
                  <DocumentLanguageParagraphsFallback isLoading={isTranslationListFetching} hasFilters={hasFilters} />
                }
              >
                {(translationList) => (
                  <>
                    <DocumentLanguageParagraphs
                      rootMargin={ROOT_MARGIN}
                      teamPresence={activeMembers}
                      paragraphs={translationList.paragraphs}
                      onPageChange={handleParagraphsPageChange}
                      renderParagraph={(paragraph, teamMemberEditing) => (
                        <MemoDocumentLanguageParagraph
                          key={paragraph.id}
                          ref={paragraph.id === scrollToParagraphId ? setParagraphElement : undefined}
                          documentId={documentId}
                          languageId={languageId}
                          weConfig={bootstrapData.weConfig}
                          placeholdersVersion={bootstrapData.placeholdersVersion}
                          sourceLanguage={bootstrapData.sourceLanguage}
                          targetLanguage={bootstrapData.targetLanguage}
                          paragraph={paragraph}
                          searchSource={debouncedSearchSource}
                          searchTarget={debouncedSearchTarget}
                          teamMemberEditing={teamMemberEditing}
                          scrollMargin={SCROLL_MARGIN}
                          onOpenComments={handleOpenParagraphComments}
                          onTranslationFocus={handleTranslationFocus}
                          onTranslationBlur={handleTranslationBlur}
                          onGoToNextParagraph={handleGoToNextParagraph}
                          onGoToFirstEmptyParagraph={handleGoToFirstEmptyTranslation}
                        />
                      )}
                    />

                    <div className="mt-8 flex justify-center rounded-sm bg-white py-2">
                      <Pagination
                        current={paginationPage}
                        pageSize={PAGINATION_LIMIT}
                        total={translationList.total}
                        onChange={handlePaginationChange}
                        disabled={offline}
                      />
                    </div>
                  </>
                )}
              </Show>
            </div>
          </div>
        </div>
      </DocumentLanguageLayout>
    </CommentsProvider>
  );
};
