import * as TabsPrimitive from '@radix-ui/react-tabs';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';
import { createContext } from '@/utils/createContext';

import { tabsContentStyle, tabsListStyle, tabsStyle, tabsTriggerStyle } from './styles';
import type {
  TabsContentElement,
  TabsContentProps,
  TabsContext,
  TabsElement,
  TabsListElement,
  TabsListProps,
  TabsProps,
  TabsTriggerElement,
  TabsTriggerProps
} from './types';

const [TabsProvider, useTabsContext] = createContext<TabsContext>('Tabs');

const Tabs = forwardRef<TabsElement, TabsProps>((props, ref) => {
  const { className, size, ...tabsProps } = props;

  return (
    <TabsProvider size={size}>
      <TabsPrimitive.Root className={classNames(tabsStyle(), className)} ref={ref} {...tabsProps} />
    </TabsProvider>
  );
});
Tabs.displayName = 'Tabs';

const TabsList = forwardRef<TabsListElement, TabsListProps>((props, ref) => {
  const { className, ...tabsListProps } = props;

  return <TabsPrimitive.List className={classNames(tabsListStyle(), className)} ref={ref} {...tabsListProps} />;
});
TabsList.displayName = 'TabsList';

const TabsTrigger = forwardRef<TabsTriggerElement, TabsTriggerProps>((props, ref) => {
  const { className, size: tabsTriggerSize, ...tabsTriggerProps } = props;

  const { size: tabsSize } = useTabsContext('TabsTrigger');

  return (
    <TabsPrimitive.Trigger
      className={classNames(tabsTriggerStyle({ size: tabsTriggerSize ?? tabsSize }), className)}
      ref={ref}
      {...tabsTriggerProps}
    />
  );
});
TabsTrigger.displayName = 'TabsTrigger';

const TabsContent = forwardRef<TabsContentElement, TabsContentProps>((props, ref) => {
  const { className, ...tabsContentProps } = props;

  return (
    <TabsPrimitive.Content className={classNames(tabsContentStyle(), className)} ref={ref} {...tabsContentProps} />
  );
});
TabsContent.displayName = 'TabsContent';

export { Tabs, TabsList, TabsTrigger, TabsContent };
