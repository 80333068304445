import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DropdownMenu from '@/components/v2/DropdownMenu';

import type { SavedViewMenuProps } from './types';

const SavedViewMenu = (props: SavedViewMenuProps) => {
  const { children, onRenameSelect, onDeleteSelect, ...menuProps } = props;

  const { t: tDocumentList } = useTranslation('documentList');

  return (
    <DropdownMenu.Root {...menuProps}>
      <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>

      <DropdownMenu.Content className="z-[60]" align="end">
        <DropdownMenu.Item onSelect={onRenameSelect}>
          <AccessibleIcon icon="ri-edit-line" label="Rename" />
          {tDocumentList('rename')}
        </DropdownMenu.Item>

        <DropdownMenu.Item color="negative" onSelect={onDeleteSelect}>
          <AccessibleIcon icon="ri-delete-bin-line" label="Delete" />
          {tDocumentList('delete')}
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
SavedViewMenu.displayName = 'SavedViewMenu';

export { SavedViewMenu };
