import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import { LanguageDto, LanguagesDto, TranslatorDto } from '@/redux/api/types';
import { useAppDispatch } from '@/redux/hooks';
import { openEditAssignedLanguagesModal } from '@/redux/slices/modalsSlice';

export const EditAssignedLanguagesCell = ({
  translator,
  languagesFlagsData,
  languagesData
}: {
  translator: TranslatorDto;
  languagesFlagsData: {
    [key: number]: LanguageDto;
  };
  languagesData?: LanguagesDto;
}) => {
  const { t: tSettings } = useTranslation('settings');

  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(
      openEditAssignedLanguagesModal({
        translator,
        languagesFlagsData,
        languagesData
      })
    );
  };

  return (
    <div className="flex flex-row justify-end gap-3">
      <Button variant="soft" size="sm" onClick={handleClick}>
        {tSettings('editLanguages')}
      </Button>
    </div>
  );
};
