import { TFunction } from 'react-i18next';

import Avatar from '@/components/v2/Avatar';
import { DataTableColumnDef } from '@/components/v2/DataTable';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { ManagerDtoStatus } from '@/redux/api/constants';
import { CurrentAccountDto, ManagerDto, TeamDetailsResponseDto } from '@/redux/api/types';

import { getFallbackName } from '../Document/utils';
import { Colors, StatusLabels } from '../Settings/types';
import { SettingsManagersTableActionsCell } from './components/SettingsManagersTableActionsCell';

export const managersTableColumns = (
  props: { currentAccount: CurrentAccountDto; userTeam: TeamDetailsResponseDto },
  tSettings: TFunction<'settings'>
) => {
  const { currentAccount, userTeam } = props;

  const columns: DataTableColumnDef<ManagerDto>[] = [
    {
      id: 'manager',
      accessorKey: 'item.manager',
      header: () => tSettings('name'),
      cell: ({ row }) => {
        const { name, email, avatarUrl } = row.original;

        return (
          <div className="flex items-center gap-2">
            <Avatar.Root>
              <Avatar.Image src={avatarUrl} />
              <Avatar.Fallback>{getFallbackName(name)}</Avatar.Fallback>
            </Avatar.Root>
            {name ? (
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <Text size="sm" weight="medium" color="primary">
                    {name}
                  </Text>
                </Tooltip.Trigger>
                <Tooltip.Content side="top">
                  <Text className="text-typography-inverse-secondary" size="xs">
                    {email}
                  </Text>
                  <Tooltip.Arrow />
                </Tooltip.Content>
              </Tooltip.Root>
            ) : (
              <Text size="sm" weight="medium" color="primary">
                {email}
              </Text>
            )}
          </div>
        );
      }
    },
    {
      id: 'email',
      accessorKey: 'item.email',
      header: () => tSettings('email'),
      cell: ({ row }) => {
        return (
          <Text size="sm" weight="medium" color="secondary">
            {row.original.email}
          </Text>
        );
      }
    },
    {
      id: 'status',
      accessorKey: 'item.status',
      header: () => tSettings('status'),
      cell: ({ row }) => {
        let color = Colors.neutral;
        let label = StatusLabels.statusNoneManager;

        if (row.original.id === userTeam.owner.id) {
          color = Colors.primary;
          label = StatusLabels.statusOwnerManager;
        } else {
          switch (row.original.status) {
            case ManagerDtoStatus.INVITED:
              color = Colors.neutral;
              label = StatusLabels.statusInvitedManager;
              break;
            case ManagerDtoStatus.ACCEPTED:
              color = Colors.positive;
              label = StatusLabels.statusAcceptedManager;
              break;
            default:
              color = Colors.neutral;
              label = StatusLabels.statusNoneManager;
              break;
          }
        }
        return (
          <div>
            <Tag color={color}>{tSettings(label)}</Tag>
          </div>
        );
      }
    }
  ];

  if (currentAccount.id === userTeam.owner.id) {
    columns.push({
      id: 'actions',
      cell: ({ row }) => {
        if (row.original.id !== userTeam.owner.id) {
          return <SettingsManagersTableActionsCell manager={row.original} members={userTeam.members} />;
        }
        return null;
      }
    });
  }

  return columns;
};
