import { DevTool } from '@hookform/devtools';
import { Slot } from '@radix-ui/react-slot';
import { isFunction, noop } from 'lodash';
import { ForwardedRef, forwardRef } from 'react';
import { Controller, FieldValues, FormProvider, useFormContext } from 'react-hook-form';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { DEV } from '@/config/app';
import { classNames } from '@/utils/classNames';
import { createContext } from '@/utils/createContext';

import type {
  FormComponent,
  FormControlComponent,
  FormControlProps,
  FormElement,
  FormErrorElement,
  FormErrorProps,
  FormFieldComponent,
  FormFieldContext,
  FormFieldElement,
  FormFieldProps,
  FormProps,
  FormSubmitElement,
  FormSubmitProps,
  UseFormFieldContext
} from './types';

const Form = forwardRef((props: FormProps, ref: ForwardedRef<FormElement>) => {
  const {
    // react-hook-form props
    clearErrors,
    control,
    devTool,
    formState,
    getFieldState,
    getValues,
    handleSubmit,
    register,
    reset,
    resetField,
    setError,
    setFocus,
    setValue,
    trigger,
    unregister,
    watch,

    // form props
    asChild,
    onInvalid = noop,
    onSubmit = noop,
    ...formProps
  } = props;
  const Component = asChild ? Slot : 'form';

  return (
    <>
      <Show when={devTool && DEV}>
        <DevTool control={control} />
      </Show>

      <FormProvider<FieldValues>
        clearErrors={clearErrors}
        control={control}
        formState={formState}
        getFieldState={getFieldState}
        getValues={getValues}
        handleSubmit={handleSubmit}
        register={register}
        reset={reset}
        resetField={resetField}
        setError={setError}
        setFocus={setFocus}
        setValue={setValue}
        trigger={trigger}
        unregister={unregister}
        watch={watch}
      >
        <Component ref={ref} onSubmit={handleSubmit(onSubmit, onInvalid)} {...formProps} />
      </FormProvider>
    </>
  );
}) as FormComponent;
Form.displayName = 'Form';

const [FormFieldProvider, useFormFieldContext] = createContext<FormFieldContext>('FormField');
const useTypedFormFieldContext = useFormFieldContext as UseFormFieldContext;
const FormField = forwardRef((props: FormFieldProps, ref: ForwardedRef<FormFieldElement>) => {
  const {
    // react-hook-form props
    control,
    defaultValue,
    disabled,
    name,
    rules,
    shouldUnregister,

    // form field props
    asChild,
    children,
    className,
    ...formFieldProps
  } = props;
  const Component = asChild ? Slot : 'div';

  const form = useFormContext();

  return (
    <Controller
      control={control ?? form.control}
      defaultValue={defaultValue}
      disabled={disabled}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={(renderProps) => (
        <FormFieldProvider {...renderProps}>
          <Component ref={ref} className={classNames('flex flex-col gap-3', className)} {...formFieldProps}>
            {isFunction(children) ? children(renderProps) : children}
          </Component>
        </FormFieldProvider>
      )}
    />
  );
}) as FormFieldComponent;
FormField.displayName = 'FormField';

const FormControl = ((props: FormControlProps) => {
  const { children } = props;

  const { field, fieldState, formState } = useFormFieldContext('FormControl');

  return isFunction(children) ? children({ field, fieldState, formState }) : <Slot {...field}>{children}</Slot>;
}) as FormControlComponent;
FormControl.displayName = 'FormControl';

const FormError = forwardRef<FormErrorElement, FormErrorProps>((props, ref) => {
  const { children, className, size = 'sm', weight = 'medium', ...formErrorProps } = props;

  const { fieldState } = useFormFieldContext('FormError');

  return (
    <Show when={fieldState.error}>
      {(error) => (
        <Text
          ref={ref}
          className={classNames('flex items-center gap-2 text-states-negative-active', className)}
          size={size}
          weight={weight}
          {...formErrorProps}
        >
          <Show
            when={children !== undefined}
            fallback={
              <>
                <AccessibleIcon
                  icon="ri-alert-fill"
                  className="text-[1.3em] leading-[inherit]"
                  label={error.message ?? 'alert'}
                />
                {error.message}
              </>
            }
          >
            {children}
          </Show>
        </Text>
      )}
    </Show>
  );
});
FormError.displayName = 'FormError';

const FormSubmit = forwardRef<FormSubmitElement, FormSubmitProps>((props, ref) => {
  const { type = 'submit', disabled: disabledProp, ...buttonProps } = props;

  const form = useFormContext();
  const { formState } = form;

  const disabled = disabledProp || formState.isSubmitting;

  return <Button type={type} disabled={disabled} ref={ref} {...buttonProps} />;
});
FormSubmit.displayName = 'FormSubmit';

export {
  Form,
  FormControl,
  FormError,
  FormField,
  FormSubmit,
  useFormContext,
  useTypedFormFieldContext as useFormFieldContext
};
