import { isNil, map } from 'lodash';

import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { FolderHierarchyDto } from '@/redux/api/types';
import { getPathToFolder } from '@/utils/folder';

interface GetInitialParentFolderIdProps {
  currentFolderId: number | null | undefined;
}
export const getInitialParentFolderId = (props: GetInitialParentFolderIdProps) => {
  const { currentFolderId } = props;

  return isNil(currentFolderId) ? DOCUMENT_LIST_MOCK_ID : `${currentFolderId}`;
};

interface GetInitialAccordionIdsProps {
  rootFolder: FolderHierarchyDto | null | undefined;
  currentFolderId: number | null | undefined;
}
export const getInitialAccordionIds = (props: GetInitialAccordionIdsProps) => {
  const { rootFolder, currentFolderId } = props;

  if (isNil(rootFolder) || isNil(currentFolderId)) {
    return [DOCUMENT_LIST_MOCK_ID];
  }

  return [
    DOCUMENT_LIST_MOCK_ID,
    ...map(getPathToFolder(currentFolderId, rootFolder.children), (folder) => `${folder.id}`)
  ];
};
