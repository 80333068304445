import { entries as _entries, find as _find, groupBy as _groupBy, map as _map, sortBy as _sortBy } from 'lodash';

import type { IParagraph } from '@/redux/api/types';

import { NO_PAGE_NUMBER } from './constants';

export const groupParagraphs = (paragraphs: IParagraph[]) => {
  const groupedParagraphs = _groupBy(paragraphs, ({ page }) => page?.number ?? NO_PAGE_NUMBER);
  const groupedParagraphsEntries = _map(_entries(groupedParagraphs), ([pageNumber, paragraphs]) => [
    Number(pageNumber),
    paragraphs
  ]) as [number, IParagraph[]][];
  return _sortBy(groupedParagraphsEntries, '0');
};

export const findFirstInViewPage = (inViewPages: Record<number, boolean>) => {
  const inViewPagesEntries = _map(_entries(inViewPages), ([pageNumber, inView]) => [Number(pageNumber), inView]) as [
    number,
    boolean
  ][];
  const sortedInViewPagesEntries = _sortBy(inViewPagesEntries, '0');
  return _find(sortedInViewPagesEntries, ([_, inView]) => inView)?.[0];
};
