import { Children, isValidElement, ReactNode } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import { Option } from './Option';
import type { IOptionProps } from './types';

export const findOptions = <T extends number | string>(children: ReactNode) =>
  Children.toArray(children)
    .map((child) => (isValidElement<IOptionProps<T>>(child) && child.type === Option ? child : null))
    .filter(isNonNullable);
