import { useMemo } from 'react';
import { z } from 'zod';

import { useSearchParams } from '@/hooks/useSearchParams';
import { useConfirmCheckoutMutation, useSavePaymentMethodMutation, useStartCheckoutMutation } from '@/redux/api';
import { BillingFrequencyEnum, CurrencyEnum } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { useStripe } from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData } from '@stripe/stripe-js';

const searchParamsSchema = z.object({
  billingFrequency: z.nativeEnum(BillingFrequencyEnum).optional().catch(undefined),
  currency: z.nativeEnum(CurrencyEnum).optional().catch(undefined),
  planCode: z.string().optional().catch(undefined)
});

type CheckoutSearchParams = z.infer<typeof searchParamsSchema>;

const parseCheckoutSearchParams = (searchParams: URLSearchParams): CheckoutSearchParams => {
  const getValue = (name: string) => searchParams.get(name);

  return searchParamsSchema.parse({
    billingFrequency: getValue('billingFrequency'),
    currency: getValue('currency'),
    planCode: getValue('planCode')
  });
};

const useCheckoutSearchParams = (): [CheckoutSearchParams] => {
  const [searchParams] = useSearchParams();

  const checkoutSearchParams = useMemo(() => parseCheckoutSearchParams(searchParams), [searchParams]);

  return [checkoutSearchParams];
};

const useCheckout = () => {
  const stripe = useStripe();

  const [startCheckout, startCheckoutResult] = useStartCheckoutMutation();

  const [savePaymentMethod, savePaymentMethodResult] = useSavePaymentMethodMutation();
  const [confirmCheckout, confirmCheckoutResult] = useConfirmCheckoutMutation();
  const confirmPaymentResult = useMemo(
    () => ({
      isError: savePaymentMethodResult.isError || confirmCheckoutResult.isError,
      isLoading: savePaymentMethodResult.isLoading || confirmCheckoutResult.isLoading,
      isSuccess: savePaymentMethodResult.isSuccess && confirmCheckoutResult.isSuccess,
      isUninitialized: savePaymentMethodResult.isUninitialized && confirmCheckoutResult.isUninitialized,
      error: savePaymentMethodResult.error || confirmCheckoutResult.error,
      data: confirmCheckoutResult.data
    }),
    [confirmCheckoutResult, savePaymentMethodResult]
  );

  const confirmPayment = async (paymentMethodData: CreatePaymentMethodCardData) => {
    const { error: createPaymentMethodError, paymentMethod } = await stripe!.createPaymentMethod(paymentMethodData);
    if (createPaymentMethodError) {
      return { error: createPaymentMethodError };
    }

    const [savePaymentMethodError, savePaymentMethodResponse] = await to(
      savePaymentMethod({ stripePaymentMethodId: paymentMethod.id }).unwrap()
    );
    if (savePaymentMethodError) {
      return { error: savePaymentMethodError };
    }

    if (savePaymentMethodResponse.requiresAction && savePaymentMethodResponse.paymentIntentClientSecret) {
      const { error: cardActionError } = await stripe!.handleCardAction(
        savePaymentMethodResponse.paymentIntentClientSecret
      );
      if (cardActionError) {
        return { error: cardActionError };
      }
    }

    const [confirmCheckoutError, confirmCheckoutResponse] = await to(confirmCheckout().unwrap());
    if (confirmCheckoutError) {
      return { error: confirmCheckoutError };
    }

    return { data: confirmCheckoutResponse };
  };

  return { startCheckout, startCheckoutResult, confirmPayment, confirmPaymentResult };
};

export { useCheckout, useCheckoutSearchParams };
