import { redokunApi } from './redokunApi';
import type {
  ChangePasswordQueryArgs,
  CurrentAccountDto,
  DeleteAccountRequestDto,
  IUpdateCurrentAccountAvatarQueryArgs,
  IUpdateCurrentAccountAvatarResponse,
  IUpdateCurrentAccountQueryArgs,
  OkResponseDto,
  UpdateFlagsQueryArgs
} from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const accountApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentAccount: builder.query<CurrentAccountDto, void>({
      query: () => 'me',
      providesTags: ['currentAccount'],
      keepUnusedDataFor: 60
    }),
    patchCurrentAccount: builder.mutation<OkResponseDto, IUpdateCurrentAccountQueryArgs>({
      query: (body) => ({
        url: 'me',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['currentAccount']
    }),
    updateFlags: builder.mutation<OkResponseDto, UpdateFlagsQueryArgs>({
      query: ({ hasHiddenOnboardingWizardWidget }) => ({
        url: '/me/flag',
        method: 'PATCH',
        body: {
          hasHiddenOnboardingWizardWidget
        }
      }),
      invalidatesTags: ['currentAccount']
    }),
    updateCurrentAccountAvatar: builder.mutation<
      IUpdateCurrentAccountAvatarResponse,
      IUpdateCurrentAccountAvatarQueryArgs
    >({
      query: (body) => ({
        url: '/me/avatar',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['currentAccount']
    }),
    deleteCurrentAccountAvatar: builder.mutation<OkResponseDto, void>({
      query: () => ({
        url: '/me/avatar',
        method: 'DELETE'
      }),
      invalidatesTags: ['currentAccount']
    }),
    updateCurrentAccountPassword: builder.mutation<OkResponseDto, ChangePasswordQueryArgs>({
      query: (body) => ({
        url: '/me/password',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['currentAccount']
    }),
    deleteCurrentAccount: builder.mutation<OkResponseDto, DeleteAccountRequestDto>({
      query: ({ reason, password, reasonDetail }) => ({
        url: '/me',
        method: 'DELETE',
        body: { reason, password, reasonDetail }
      }),
      invalidatesTags: ['currentAccount']
    })
  })
});

export const {
  useGetCurrentAccountQuery,
  useLazyGetCurrentAccountQuery,
  useUpdateFlagsMutation,
  usePatchCurrentAccountMutation,
  useUpdateCurrentAccountAvatarMutation,
  useDeleteCurrentAccountAvatarMutation,
  useUpdateCurrentAccountPasswordMutation,
  useDeleteCurrentAccountMutation
} = accountApi;

export const accountApiUtil = {
  ...accountApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(accountApi.util.updateQueryData)
};
