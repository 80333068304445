import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as Loader } from '@/assets/loader.svg';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import DataTable, { DataTableColumnDef } from '@/components/v2/DataTable';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetOperationStatusQuery } from '@/redux/api';
import { EventTypes } from '@/redux/api/constants';
import { PreTranslatedLanguageResultDto, PreTranslationOperationOutputDto } from '@/redux/api/types';
import { useTrackEvent } from '@/routes/useTrackEvent';
import { assert } from '@/utils/assert';
import { formatDecimal } from '@/utils/number';
import { roundPercentage } from '@/utils/percentage';

export interface PreTranslateReportProps {
  documentId: number;
}

export const PreTranslateReport = ({ documentId }: PreTranslateReportProps) => {
  const { t } = useTranslation('pretranslate');
  const { operationId } = usePreTranslateReportParams();
  useTrackEvent(EventTypes.ONBOARDING_VISITED_PRE_TRANSLATE_REPORT_PAGE);

  const { data: operationStatus, isLoading, isUninitialized } = useGetOperationStatusQuery(operationId);

  const { currentAccount, refetch: refetchAccount } = useCurrentAccount();
  const history = useHistory();
  const isCompleted = currentAccount.onboardingFlags.hasConfirmedAnyDocument;

  if (isUninitialized || isLoading || !operationStatus?.isDone) {
    return (
      <div className="flex flex-1 flex-col items-center gap-3">
        <Loader className="animate-slow-spin" />
        <div>
          <Text size="lg" weight="bold" color="tertiary">
            {t('werePretranslatingYourDocument')}
          </Text>
          <Text size="md" color="tertiary">
            {t('thisWillTakeAMomentDontClose')}
          </Text>
        </div>
      </div>
    );
  }
  assert(operationStatus, 'Operation status not found');

  const columns: DataTableColumnDef<PreTranslatedLanguageResultDto>[] = [
    {
      accessorKey: 'language',
      header: t('language'),
      cell: () => <LanguageCell />
    },
    {
      accessorKey: 'contextMatchResult',
      header: t('translationMemory'),
      cell: () => <TranslationMemoryCell />
    },
    {
      accessorKey: 'mtResult',
      header: t('machineTranslation'),
      cell: () => <MachineTranslationCell />
    },
    {
      accessorKey: 'totalTranslated',
      header: t('totalTranslation'),
      cell: () => <TotalTranslatedCell />
    }
  ];

  const handleOnClick = () => {
    if (!isCompleted) {
      refetchAccount();
    }

    history.push(`/documents/show/${documentId}`);
  };

  return (
    <div className="flex flex-col gap-3">
      <Surface className="px-7 py-8">
        <DataTable.Root
          columns={columns}
          data={operationStatus.result?.languages as PreTranslationOperationOutputDto['languages']}
        >
          <DataTable.Container>
            <DataTable.Header />
            <DataTable.Body />
          </DataTable.Container>
        </DataTable.Root>
      </Surface>
      <div className="flex flex-1 justify-end">
        <Button variant="solid" color="primary" onClick={handleOnClick}>
          {t('seeDocument')}
          <AccessibleIcon label="arrow-right" icon="ri-arrow-right-line" />
        </Button>
      </div>
    </div>
  );
};

export interface PreTranslateOptionsProps {
  children?: ReactNode;
}

const usePreTranslateReportParams = () => {
  const params = useParams<{ documentId: string; operationId: string }>();
  const parsedParams = useMemo(
    () => ({ documentId: parseInt(params.documentId), operationId: parseInt(params.operationId) }),
    [params]
  );
  return parsedParams;
};

const LanguageCell = () => {
  const { cell } = DataTable.useCellContext<PreTranslatedLanguageResultDto, PreTranslatedLanguageResultDto['language']>(
    'LanguageCell'
  );
  const language = cell.getValue();

  return (
    <div className="flex items-center gap-2">
      <FlagIcon label={language.name} code={language.flagCode || 'xx'} size="sm" />
      <Text size="sm" color="secondary" weight="medium">
        {language.name}
      </Text>
    </div>
  );
};

const TranslationMemoryCell = () => {
  const { t } = useTranslation('pretranslate');
  const { row } = DataTable.useRowContext<PreTranslatedLanguageResultDto>('MachineTranslationCell');
  const count = row.original.contextMatchResult.translatedCount + row.original.identicalMatchResult.translatedCount;

  const percentage = (count * 100) / row.original.total || 0;

  const show = row.original.contextMatchResult.hasBeenPerformed && row.original.identicalMatchResult.hasBeenPerformed;

  const roundedPercentage = roundPercentage(percentage);
  return (
    <div className="flex items-center gap-2">
      {show ? (
        <>
          <Text color="secondary" size="sm" weight="medium">{`${formatDecimal(count)} ${t('segments')}`}</Text>
          <Text color="tertiary" size="sm">{`(${roundedPercentage}%)`}</Text>
        </>
      ) : (
        <Text color="secondary" size="sm" weight="medium">
          -
        </Text>
      )}
    </div>
  );
};

const MachineTranslationCell = () => {
  const { t } = useTranslation('pretranslate');

  const { cell } = DataTable.useCellContext<PreTranslatedLanguageResultDto, PreTranslatedLanguageResultDto>(
    'MachineTranslationCell'
  );
  const { row } = DataTable.useRowContext<PreTranslatedLanguageResultDto>('MachineTranslationCell');

  const result = cell.getValue();
  const percentage = (result.translatedCount * 100) / row.original.total || 0;

  const show = result.translatedCount > 0 && row.original.mtResult.hasBeenPerformed;
  const roundedPercentage = roundPercentage(percentage);

  return (
    <div className="flex items-center gap-2">
      {show ? (
        <>
          <Text color="secondary" size="sm" weight="medium">{`${formatDecimal(result.translatedCount)} ${t(
            'segments'
          )}`}</Text>
          <Text color="tertiary" size="sm">{`(${roundedPercentage}%)`}</Text>
        </>
      ) : (
        <Text color="secondary" size="sm" weight="medium">
          -
        </Text>
      )}
    </div>
  );
};

const TotalTranslatedCell = () => {
  const { t } = useTranslation('pretranslate');

  const { row } = DataTable.useRowContext<PreTranslatedLanguageResultDto>('MachineTranslationCell');

  return (
    <div className="flex items-center gap-2">
      <>
        <Text color="secondary" size="sm" weight="medium">{`${formatDecimal(row.original.translatedCount)} ${t(
          'segments'
        )}`}</Text>
        <Text color="tertiary" size="sm">{`(${Math.floor(row.original.translatedPercent)}%)`}</Text>
      </>
    </div>
  );
};
