import { ReactNode } from 'react';

export interface Step {
  id: string;
  path: string;
  label?: string;
}

export interface ImportSegmentsLayoutProps {
  steps?: Step[];
  children?: ReactNode;
}

export enum EImportSegmentsSteps {
  UPLOAD_FILE = 'upload-file',
  CONFIRM_IMPORT = 'confirm-import'
}
