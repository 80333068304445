import { ReactNode } from 'react';

import { Text } from '@/components/v2/Text';

type StatProps = { label: ReactNode; data: ReactNode };

export const StatValue = ({ label, data }: StatProps) => {
  return (
    <div>
      <Text color="tertiary" size="sm" className="mb-1">
        {label}
      </Text>
      <Text weight="semibold" size="lg" className="text-green-500">
        {data}
      </Text>
    </div>
  );
};
