import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import Avatar from '@/components/v2/Avatar';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { getFallbackName } from '@/routes/Document/utils';
import Tooltip from '@/components/v2/Tooltip';
import { Show } from '@/components/v2/Show';
import { PartialTranslatorDto } from '@/routes/Document/types';

interface TranslatorItemProps {
  translator: PartialTranslatorDto;
  onDelete: () => void;
}

export const TranslatorItem = ({ translator, onDelete }: TranslatorItemProps) => {
  const { t: tDocument } = useTranslation('document');
  const { fullName, id, avatarUrl, email, isNotAccepted } = translator;

  return (
    <div className="flex w-full flex-row justify-between">
      <div className="flex flex-row gap-2.5">
        <Avatar.Root key={id}>
          <Avatar.Image src={avatarUrl} />
          <Avatar.Fallback>{getFallbackName(fullName === '' ? email : fullName)}</Avatar.Fallback>
        </Avatar.Root>
        <div className="flex flex-col">
          <Text size="sm" weight="medium" color="primary">
            {fullName === '' ? '-' : fullName}
          </Text>
          <Text size="xs" weight="medium" color="secondary">
            {email}
          </Text>
        </div>
        <Show when={isNotAccepted}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <AccessibleIcon
                label="warning-icon"
                icon="ri-alert-line"
                className="text-base text-states-alert-active hover:text-states-alert-hover"
              />
            </Tooltip.Trigger>
            <Tooltip.Content className="max-w-xxs" side="bottom">
              <Text className="text-typography-inverse-primary" size="sm" weight="medium">
                {tDocument('thisTranslatorIsYetToAcceptYourInvitation')}
              </Text>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
        </Show>
      </div>

      <Button variant="text" color="negative" onClick={onDelete}>
        <AccessibleIcon label={'Delete translator'} icon="ri-delete-bin-6-line" className="text-md" />
        <Text size="sm" weight="semibold" className="text-current">
          {tDocument('deleteTranslator')}
        </Text>
      </Button>
    </div>
  );
};
