import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataTable, { DataTableColumnDef } from '@/components/v2/DataTable';
import { Tag } from '@/components/v2/Tag';
import { DocumentListItemType } from '@/redux/api/constants';
import { DocumentListItemDto, SourceDocumentDtoStatus } from '@/redux/api/types';

import { ActionHeader } from './components/ActionHeader';
import { ActionsCell } from './components/ActionsCell';
import { ExpandedFileNameCell, FileNameCell } from './components/FileNameCell';
import { ExpandedTargetLanguageCell, TargetLanguageCell } from './components/TargetLanguageCell';
import { UploaderCell } from './components/UploaderCell';

const useColumns = () => {
  const { t: tDocumentList } = useTranslation('documentList');

  const columns: DataTableColumnDef<DocumentListItemDto>[] = useMemo(
    () => [
      {
        id: 'checkbox',
        header: () => <DataTable.HeaderCheckbox />,
        headerLoader: () => null,
        cell: () => <DataTable.Checkbox />,
        cellLoader: () => null,
        meta: {
          width: '60px'
        }
      },
      {
        id: 'filename',
        accessorKey: 'item.filename',
        header: () => tDocumentList('fileName'),
        cell: () => <FileNameCell />,
        cellExpanded: () => <ExpandedFileNameCell />,
        meta: {
          width: '40%'
        }
      },
      {
        id: 'uploader',
        accessorKey: 'item.owner',
        header: () => tDocumentList('uploader'),
        cell: () => <UploaderCell />,
        meta: {
          width: '25%'
        }
      },
      {
        id: 'target-langugage',
        accessorKey: 'item.translations',
        header: () => tDocumentList('targetLanguage'),
        cell: (fileRowEntry) => {
          const row = fileRowEntry.row.original;
          if (
            row.type === DocumentListItemType.DOCUMENT &&
            row.item.status === SourceDocumentDtoStatus.INDEXING_ERROR
          ) {
            return (
              <Tag color="negative" variant="soft">
                {tDocumentList('documentListRowIndexingError')}
              </Tag>
            );
          }
          return <TargetLanguageCell />;
        },
        cellExpanded: () => <ExpandedTargetLanguageCell />,
        meta: {
          width: '35%'
        }
      },
      {
        id: 'actions',
        header: () => <ActionHeader />,
        cell: () => <ActionsCell />,
        meta: {
          width: '230px'
        }
      }
    ],
    [tDocumentList]
  );

  return columns;
};

export { useColumns };
