import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import DataTable, { DataTableColumnDef } from '@/components/v2/DataTable';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';

import { ActionsCell } from './components/ActionsCell';
import { TranslationMemoriesTableRow } from './types';

export const ActionHeader = () => {
  return <div className="flex w-full justify-end"></div>;
};

const useColumns = () => {
  const { t: tTM } = useTranslation('translationMemory');

  const columns: DataTableColumnDef<TranslationMemoriesTableRow>[] = useMemo(
    () => [
      {
        id: 'checkbox',
        header: () => <DataTable.HeaderCheckbox />,
        headerLoader: () => null,
        cell: (ctx) => {
          const { original } = ctx.cell.row;
          if (original.segmentCount !== null) {
            return <DataTable.Checkbox disabled={original.isDeleting} />;
          }
          return null;
        },
        cellLoader: () => null,
        meta: {
          width: '60px'
        }
      },
      {
        id: 'source',
        accessorKey: 'source',
        header: () => (
          <div className="flex items-center space-x-2">
            <Text weight="semibold" size="md" color="primary">
              {tTM('source')}
            </Text>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <AccessibleIcon
                  className="text-xl text-blue-500"
                  label="ri-information-fill"
                  icon="ri-information-line"
                />
              </Tooltip.Trigger>
              <Tooltip.Content className="max-w-xxs" side="right">
                <Text className="text-typography-inverse-primary" size="xs">
                  {tTM('sourceTooltipText')}
                </Text>
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Root>
          </div>
        ),
        cell: (ctx) => {
          const { original } = ctx.cell.row;
          return <>{original.source}</>;
        }
      },
      {
        id: 'segmentCount',
        accessorKey: 'segmentCount',
        header: () => tTM('segmentCount'),
        cell: (ctx) => {
          const { original } = ctx.cell.row;

          return <div>{original.segmentCount}</div>;
        }
      },
      {
        id: 'actions',
        header: () => <ActionHeader />,

        cell: (ctx) => {
          const { original } = ctx.cell.row;
          if (original.segmentCount !== null) {
            return (
              <ActionsCell
                source={original.source}
                segmentCount={original.segmentCount}
                translationMemoryId={original.translationMemoryId}
                kind={original.kind}
                sourceLanguage={original.sourceLanguage}
                targetLanguage={original.targetLanguage}
                isDeleting={original.isDeleting}
              />
            );
          }
          return null;
        },
        meta: {
          width: '230px'
        }
      }
    ],
    [tTM]
  );

  return columns;
};

export { useColumns };
