import { LanguageDto } from '@/redux/api/types';

export const createSelectedLanguages = (languages: { [key: number]: LanguageDto }, assignedLanguagesIds: number[]) => {
  return assignedLanguagesIds.map((id) => {
    const language = languages[id];
    return {
      ...language,
      id: language.id.toString(),
      label: language.name
    };
  });
};
