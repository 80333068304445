import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';
import { textAreaVariants } from '@/components/v2/TextArea/styles';

import type { TextAreaProps } from './types';

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ onChange, placeholder, className, size = 'md', ...rest }, forwardRef) => {
    return (
      <textarea
        className={classNames(textAreaVariants({ size }), className)}
        placeholder={placeholder}
        ref={forwardRef}
        onChange={onChange}
        {...rest}
      />
    );
  }
);

TextArea.displayName = 'TextArea';
