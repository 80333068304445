import { first } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal from '@/components/v2/Modal';
import { useCreateDocumentFromSampleMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';

import { DocumentSamplesToggleGroup } from '../DocumentSamplesToggleGroup';
import type { DocumentSamplesModalProps } from './types';

export const DocumentSamplesModal = (props: DocumentSamplesModalProps) => {
  const { open, onOpenChange, children, onCreated } = props;

  const { t } = useTranslation('uploadDocuments');

  const [identifier, setIdentifier] = useState<string>();

  const [create, { isLoading: isCreateLoading, isSuccess: isCreateSuccess }] = useCreateDocumentFromSampleMutation();

  const handleChooseClick = async () => {
    if (!identifier) {
      return;
    }

    const [, data] = await to(create({ documentsFromSample: [{ identifier }] }).unwrap());
    const documentId = first(data?.documentIds);
    if (documentId) {
      onCreated?.(documentId);
    }
  };

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title>{t('dontHaveDocumentYouUseNow')}</Modal.Title>
          <Modal.Description>{t('useOneOfOurSampleFiles')}</Modal.Description>
        </Modal.Header>

        <DocumentSamplesToggleGroup value={identifier} onValueChange={setIdentifier} />

        <Modal.Footer orientation="horizontal">
          <Button disabled={!identifier || isCreateLoading || isCreateSuccess} onClick={handleChooseClick}>
            {t('choose')}
          </Button>

          <Modal.Close>
            <Button variant="surface" color="secondary">
              {t('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
