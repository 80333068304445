import { useCallback } from 'react';

import { accountApiUtil, useGetCurrentAccountQuery } from '@/redux/api';
import { useAppDispatch } from '@/redux/hooks';
import { assert } from '@/utils/assert';

export const useCurrentAccount = () => {
  const dispatch = useAppDispatch();

  const { data: currentAccount, isFetching } = useGetCurrentAccountQuery();
  const refetch = useCallback(() => dispatch(accountApiUtil.invalidateTags(['currentAccount'])), [dispatch]);

  assert(currentAccount, '`useCurrentAccount` must be used within `CurrentAccountProvider`');

  return { currentAccount, isFetching, refetch };
};

/**
 * @deprecated use `useCurrentAccount` instead
 */
export const useCurrentAccountContext = useCurrentAccount;
