import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { title } from './styles';
import type { TitleElement, TitleProps } from './types';

export const Title = forwardRef<TitleElement, TitleProps>(
  ({ asChild, as = 'h1', className, color, size, theme, weight, ...props }, ref) => {
    const Component = asChild ? Slot : as;
    return <Component {...props} className={classNames(title({ color, size, theme, weight }), className)} ref={ref} />;
  }
);
Title.displayName = 'Title';
