import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { useLazyGetLanguagesQuery, usePutLanguagesMutation } from '@/redux/api';
import { LanguageDto, OverrideRegionalSettingsOptions } from '@/redux/api/types';
import { Show } from '@/components/v2/Show';
import { removeLanguageById } from '@/utils/removeLanguageById';
import { createSelectedLanguages } from '@/utils/createSelectedLanguages';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { to } from '@/utils/awaitToJs';
import { useToast } from '@/components/v2/Toast';

import { LanguageSettings } from './components/LanguageSettings';
import { FavouriteLanguages } from './components/FavouriteLanguages';
import { LanguageGroup, LanguageOption } from '../Document/types';
import { createLanguageGroups } from './utils/createLanguagesGroup';

export const SettingsLanguages: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const { currentAccount } = useCurrentAccount();

  // Internal state

  const [selectedItems, setSelectedItems] = useState<LanguageOption[] | undefined>();
  const [enableRegionalLanguages, setEnableRegionalLanguages] = useState<boolean | null>(
    currentAccount.settings.enableRegionalLanguages
  );

  // Queries

  const [
    getLanguagesTrigger,
    { data: languagesData, isLoading: languagesDataIsLoading, isFetching: languagesDataIsFetching }
  ] = useLazyGetLanguagesQuery();
  const languageGroups: LanguageGroup[] = languagesData ? createLanguageGroups(languagesData, tSettings) : [];

  const [putLanguagesTrigger, { isLoading: putLanguagesIsLoading }] = usePutLanguagesMutation();

  // Effects
  //
  useEffect(() => {
    getLanguagesTrigger({
      overrideRegionalSettings: enableRegionalLanguages
        ? OverrideRegionalSettingsOptions.showRegionalVariants
        : OverrideRegionalSettingsOptions.hideRegionalVariants
    });
  }, [enableRegionalLanguages, getLanguagesTrigger]);

  useEffect(() => {
    if (languagesData) {
      const languagesFlagsData: { [key: number]: LanguageDto } = {};

      languagesData.all.forEach((languageInUse) => {
        languagesFlagsData[languageInUse.id] = languageInUse;
      });

      setSelectedItems(
        createSelectedLanguages(
          languagesFlagsData,
          languagesData.active.map((language) => {
            return language.id;
          })
        )
      );
    }
  }, [languagesData]);

  useEffect(() => {
    setEnableRegionalLanguages(currentAccount.settings.enableRegionalLanguages);
  }, [currentAccount]);

  // Handle actions

  const handleAddLanguage = (items?: LanguageOption[]) => {
    setSelectedItems(items);
  };

  const handleRemoveLanguage = (id: string) => {
    const items = removeLanguageById(id, selectedItems);
    setSelectedItems(items);
  };

  const handleSave = async () => {
    if (selectedItems !== undefined && enableRegionalLanguages !== undefined) {
      const [err, res] = await to(
        putLanguagesTrigger({
          languageIds: selectedItems.map((item) => parseInt(item.id)),
          enableRegionalVariants: enableRegionalLanguages ?? false
        }).unwrap()
      );

      if (!err && res.status === 'ok') {
        toast({ title: tSettings('savedSuccesfully'), kind: 'success' });
      }
    }
  };

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsLanguages')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('languages')}
          </Text>

          <Button
            disabled={languagesDataIsLoading || languagesDataIsFetching || putLanguagesIsLoading}
            color="primary"
            onClick={handleSave}
          >
            {putLanguagesIsLoading ? <Spinner size="sm" /> : null}
            {tSettings('save')}
          </Button>
        </div>

        {/* Forms */}
        <div className="grid grid-cols-2 gap-6">
          <Show
            when={selectedItems !== undefined}
            fallback={
              <div className="flex w-full justify-center py-12">
                <Spinner size="lg" />
              </div>
            }
          >
            <FavouriteLanguages
              languageGroups={languageGroups}
              defaultValues={selectedItems}
              handleAddLanguage={handleAddLanguage}
              handleRemoveLanguage={handleRemoveLanguage}
            />
          </Show>
          <Show
            when={enableRegionalLanguages !== undefined}
            fallback={
              <div className="flex w-full justify-center py-12">
                <Spinner size="lg" />
              </div>
            }
          >
            <LanguageSettings
              enableRegionalLanguages={enableRegionalLanguages}
              setEnableRegionalLanguages={setEnableRegionalLanguages}
            />
          </Show>
        </div>
      </div>
    </div>
  );
};
