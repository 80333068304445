import { filter, includes, map } from 'lodash';

import { redokunApi } from './redokunApi';
import type { DocumentDetailDto, LanguageDto, LanguageExtendedDto, LanguagesDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const customApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableDocumentLanguages: builder.query<LanguagesDto, number>({
      queryFn: async (documentId, _api, _extraOptions, baseQuery) => {
        const [languagesResult, documentResult] = await Promise.all([
          baseQuery('language'),
          baseQuery(`document/${documentId}`)
        ]);

        if (languagesResult.error) {
          return languagesResult;
        }
        if (documentResult.error) {
          return documentResult;
        }

        const languages = languagesResult.data as LanguagesDto;
        const document = documentResult.data as DocumentDetailDto;

        const documentLanguagesIds = [
          ...map(document.translations, (translation) => translation.language.id),
          document.sourceDocument.language.id
        ];
        const documentLanguagesFilter = <L extends LanguageDto | LanguageExtendedDto>(languages: L[]) =>
          filter(languages, (language) => !includes(documentLanguagesIds, language.id));

        return {
          data: {
            active: documentLanguagesFilter(languages.active),
            all: documentLanguagesFilter(languages.all),
            inUse: documentLanguagesFilter(languages.inUse)
          },
          meta: languagesResult.meta
        };
      },
      keepUnusedDataFor: 60,
      providesTags: ['languages', 'documentDetail']
    })
  })
});

export const { useGetAvailableDocumentLanguagesQuery, useLazyGetAvailableDocumentLanguagesQuery } = customApi;

export const customApiUtil = {
  ...customApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(customApi.util.updateQueryData)
};
