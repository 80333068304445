import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import DataTable from '@/components/v2/DataTable';
import { MoveToFolderModal } from '@/routes/DocumentList/components/DocumentListContent/components/MoveToFolderModal';
import { DocumentListItemDto } from '@/redux/api/types';
import { Show } from '@/components/v2/Show';
import { DocumentListItemType } from '@/redux/api/constants';
import { AddLanguageModal } from '@/routes/Document/components/AddLanguageModal';
import Tooltip from '@/components/v2/Tooltip';
import { DeleteBatchItemModal } from '@/routes/DocumentList/components/DocumentListContent/components/DeleteBatchItemModal';

export const BatchOperationHeader = () => {
  const { table } = DataTable.useContext<DocumentListItemDto>('BatchOperationHeader');
  const { t: tDocumentList } = useTranslation('documentList');
  const [isMouseHovering, setIsMouseHovering] = useState(false);

  const selectedRows = table.getSelectedRowModel().rows.map(({ original }) => original);

  const items = selectedRows.map((row) => ({
    type: row.type,
    elementId: row.item.id
  }));
  // if user is selecting also at least one folder
  const isMixedSelection = selectedRows.some((row) => row.type === DocumentListItemType.FOLDER);

  const documentIds = selectedRows.flatMap((i) => (i.type === DocumentListItemType.DOCUMENT ? [i.item.id] : []));
  const rowSelectionCount = selectedRows.length;

  const resetRowSelection = () => {
    table.resetRowSelection();
  };

  return (
    <div className="flex w-full flex-1 flex-row items-center gap-10">
      <DataTable.HeaderCheckbox />
      <div className="flex w-full flex-row items-center justify-between">
        <Text size="md" weight="semibold" color="primary">
          <Show
            when={rowSelectionCount === 1}
            fallback={tDocumentList('countItemsSelected', { count: rowSelectionCount })}
          >
            {tDocumentList('countItemSelected', { count: rowSelectionCount })}
          </Show>
        </Text>
        <div className="flex flex-row gap-7">
          <MoveToFolderModal items={items} onConfirm={resetRowSelection} onError={resetRowSelection}>
            <Button variant="text" color="primary" size="md" className="gap-2">
              <AccessibleIcon label="ri-folder-shared-line" icon="ri-folder-shared-line" className="text-lg" />
              {tDocumentList('moveToFolder')}
            </Button>
          </MoveToFolderModal>
          <Tooltip.Root open={isMixedSelection && isMouseHovering}>
            <Tooltip.Trigger>
              <AddLanguageModal
                documentIds={documentIds}
                isBatchOperation
                onConfirm={resetRowSelection}
                onError={resetRowSelection}
              >
                {/* workound to show tooltip on hover */}
                <div onMouseEnter={() => setIsMouseHovering(true)} onMouseLeave={() => setIsMouseHovering(false)}>
                  <Button variant="text" color="primary" size="md" disabled={isMixedSelection} className="gap-2">
                    <AccessibleIcon label="ri-add-line" icon="ri-add-line" className="text-lg" />
                    {tDocumentList('addTargetLanguages')}
                  </Button>
                </div>
              </AddLanguageModal>
            </Tooltip.Trigger>
            <Tooltip.Content className="max-w-xxs" sideOffset={12}>
              <Text className="whitespace-pre-line text-typography-inverse-primary" size="xs">
                {tDocumentList('pleaseChooseOnlyDocumentsToEnableThisOption')}
              </Text>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
          <DeleteBatchItemModal items={items}>
            <Button variant="text" color="negative" className="gap-2" size="md">
              <AccessibleIcon label={'Delete translator'} icon="ri-delete-bin-6-line" className="text-md" />
              {tDocumentList('delete')}
            </Button>
          </DeleteBatchItemModal>
        </div>
      </div>
    </div>
  );
};
