import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import DataTable from '@/components/v2/DataTable';
import { useGetLanguagesQuery, useGetTranslatorsQuery } from '@/redux/api';
import { CurrentAccountDto, LanguageDto } from '@/redux/api/types';
import { useAppDispatch } from '@/redux/hooks';
import { closeModal } from '@/redux/slices/modalsSlice';
import { EModalType } from '@/redux/slices/modalsSlice/constants';

import { createColumns } from '../createColumns';

type TranslatorsTableProps = {
  currentAccount: CurrentAccountDto;
};

export const TranslatorsTable = ({ currentAccount }: TranslatorsTableProps) => {
  const { t: tSettings } = useTranslation('settings');

  const dispatch = useAppDispatch();

  const { data: languagesData, isLoading: languagesIsLoading } = useGetLanguagesQuery();
  const {
    data: translatorsData,
    isLoading: translatorsIsLoading,
    isSuccess: translatorsIsSuccess,
    isFetching
  } = useGetTranslatorsQuery({});

  useEffect(() => {
    if (!isFetching) {
      dispatch(closeModal(EModalType.EDIT_TRANSLATOR_ASSIGNED_LANGUAGES));
    }
  }, [dispatch, isFetching]);

  const languagesFlagsData: { [key: number]: LanguageDto } = {};
  if (languagesData) {
    languagesData.all.forEach((languageInUse) => {
      languagesFlagsData[languageInUse.id] = languageInUse;
    });
  }

  const table = () => {
    if (translatorsIsSuccess) {
      if (isEmpty(translatorsData?.translators)) {
        return <div className="py-4 text-center">{tSettings('noItemsToDisplay')}</div>;
      } else {
        return (
          <DataTable.Root
            columns={createColumns(tSettings, currentAccount.id, languagesFlagsData, languagesData)}
            data={translatorsData?.translators}
          >
            <DataTable.Container>
              <DataTable.Header />
              <DataTable.Body />
            </DataTable.Container>
          </DataTable.Root>
        );
      }
    }
    return null;
  };

  return (
    <div className="w-full max-w-full">
      {translatorsIsLoading || languagesIsLoading ? (
        <div className="flex h-[50vh] items-center justify-center">
          <Spinner size="lg" />
        </div>
      ) : null}
      {table()}
    </div>
  );
};
