import { Descendant, Editor, Element, Transforms } from 'slate';

import type { TPlaceholder } from '@/redux/api/types';
import { EElementType } from '@/slate/constants';

export const getNextPlaceholder = (placeholders: TPlaceholder[], value: Descendant[]) => {
  const availablePlaceholders = placeholders.filter(
    (placeholder) =>
      !(value[0] as Element).children.find(
        (element) =>
          Element.isElement(element) &&
          element.type === EElementType.PLACEHOLDER_V1 &&
          element.placeholder.id === placeholder.id
      )
  );
  return availablePlaceholders.length ? availablePlaceholders[0] : null;
};

export const insertPlaceholder = (editor: Editor, placeholder: TPlaceholder) => {
  Transforms.insertNodes(editor, { type: EElementType.PLACEHOLDER_V1, placeholder, children: [{ text: '' }] });
  Transforms.move(editor);
};
