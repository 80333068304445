import { Slot } from '@radix-ui/react-slot';
import { isString, startsWith } from 'lodash';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { Show } from '@/components/v2/Show';

import { Button } from '../Button';
import type { ButtonLinkElement, ButtonLinkProps } from './types';

const ButtonLink = forwardRef<ButtonLinkElement, ButtonLinkProps>(
  ({ asChild, className, style, color, size, variant, disabled, ...props }, ref) => {
    const renderLink = () => {
      if (asChild) {
        return <Slot {...props} ref={ref} />;
      }

      const { href, ...linkProps } = props;

      if (isString(href) && (startsWith(href, 'http:') || startsWith(href, 'https:'))) {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a {...linkProps} ref={ref} href={href} />;
      }

      return <Link {...linkProps} ref={ref} to={href} />;
    };

    return (
      <Show
        when={!disabled}
        fallback={
          // since a or link element cannot be disabled, show a normal button
          <Button style={style} color={color} size={size} variant={variant} className={className} disabled>
            {props.children}
          </Button>
        }
      >
        <Button asChild className={className} style={style} color={color} size={size} variant={variant}>
          {renderLink()}
        </Button>
      </Show>
    );
  }
);
ButtonLink.displayName = 'ButtonLink';

export { ButtonLink };
