import { uniqueId } from 'lodash';
import { useCallback, useState } from 'react';
import { ErrorCode, FileError, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { FileContainerProps } from '@/components/v2/FileContainer';
import { useTrackEventMutation } from '@/redux/api';
import { EventTypes } from '@/redux/api/constants';
import { uploadFile } from '@/redux/api/fileApi';
import { EntityType } from '@/redux/api/types';
import { generateTrackEvent } from '@/redux/api/utils/generateTrackEvent';
import { MAX_SIZE } from '@/routes/UploadDocuments/steps/UploadFile/constants';
import { to } from '@/utils/awaitToJs';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { sizeToMb } from '@/utils/sizeToMb';

interface FileState {
  id: string;
  stashId?: number;
  name: string;
  size: number;
  percent: number;
  importNumericData?: boolean;
  status: FileContainerProps['status'];
  errorMessage?: string;
}

interface UseDocumentUploadProps {
  maxFiles?: number;
  folderId?: number;
}

/**
 * This hook should be moved to the `FileUploader`/`FileContainer` component to encapsulate the file upload logic within the component that directly manages file states and UI updates
 */
export const useDocumentUpload = ({ maxFiles, folderId }: UseDocumentUploadProps) => {
  const [trackEventTrigger] = useTrackEventMutation();

  const [files, setFiles] = useState<FileState[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const { t: tUploadDocuments } = useTranslation('uploadDocuments');

  const localizeErrorMessage = (error: FileError) => {
    switch (error.code) {
      case ErrorCode.TooManyFiles:
        return tUploadDocuments('youCanUploadMaxFilesPleaseRetry', { maxFiles });
      case ErrorCode.FileTooLarge:
        return tUploadDocuments('fileTooLarge', { size: sizeToMb(MAX_SIZE, false) });
      default:
        return error.message;
    }
  };

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (isNotEmpty(rejectedFiles)) {
      const fileErrors = rejectedFiles.map((file) => {
        const errorMessage = file?.errors.map((error) => localizeErrorMessage(error)).join('\n');

        const trackErrorMessage = {
          validationErrorsStr: file?.errors.map((error) => error.code).join(',')
        };

        // track errors that are returned by react-dropzone
        trackEventTrigger(generateTrackEvent(EventTypes.DOCUMENT_FAILED_UPLOAD, trackErrorMessage));

        return {
          id: uniqueId(),
          name: file.file.name,
          size: file.file.size,
          status: 'error' as FileContainerProps['status'],
          percent: 0,
          errorMessage
        };
      });

      setFiles((prevFiles) => [...prevFiles, ...fileErrors]);
    }

    if (isNotEmpty(acceptedFiles)) {
      setIsUploading(true);

      const filesWithId = acceptedFiles.map((file) => ({ id: uniqueId(), file }));
      const newFiles = filesWithId.map(({ id, file }) => ({
        id,
        name: file.name,
        size: file.size,
        status: 'active' as FileContainerProps['status'],
        percent: 0
      }));
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      const promises = filesWithId.map(async ({ id: fileId, file }) => {
        const updateFile = (partialFile: Partial<FileState>) => {
          setFiles((files) => files.map((file) => (file.id === fileId ? { ...file, ...partialFile } : file)));
        };

        const [error, data] = await to(
          uploadFile({
            body: { entityType: EntityType.document, files: file, metadata: { folderId } },
            onProgress: (e) => {
              updateFile({ percent: Math.round(e.progress * 100) });
            }
          })
        );

        if (error) {
          updateFile({ status: 'error' as FileContainerProps['status'], percent: 0 });
          return { error };
        }

        if (data.failedUploads?.length) {
          const failedUpload = data.failedUploads[0];
          const errorMessage = failedUpload?.errors.map((error) => error).join('\n');
          updateFile({ status: 'error' as FileContainerProps['status'], errorMessage, percent: 0 });
        } else if (data.successfulUploads?.length) {
          const successfulUpload = data.successfulUploads[0];

          updateFile({
            stashId: successfulUpload.stashedFileId,
            status: 'completed' as FileContainerProps['status'],
            importNumericData: !!successfulUpload.hasHtmlContent,
            percent: 100
          });
        }
        return { data };
      });

      Promise.all(promises).finally(() => setIsUploading(false));
    }
  };

  const handleOnClose = useCallback((id: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  }, []);

  return { files, onDrop, handleOnClose, isUploading };
};
