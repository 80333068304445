import { redokunApi } from './redokunApi';
import type { GetCountriesResponseDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const countryApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountry: builder.query<GetCountriesResponseDto, void>({
      query: () => `/country`,
      providesTags: ['country'],
      keepUnusedDataFor: 60
    })
  })
});

export const { useGetCountryQuery } = countryApi;

export const billingApiUtil = {
  ...countryApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(countryApi.util.updateQueryData)
};
