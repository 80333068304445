import { isNumber as _isNumber } from 'lodash';

import type { TAppDispatch } from '../../store/types';
import { EBlockedReason, EParagraphTranslationStatus, MachineTranslationVendor, TranslationStatus } from '../constants';
import { languageApiUtil } from '../languageApi';
import type {
  IGetBootstrapDataQueryArg,
  IGetTranslationListQueryArg,
  IGlossaryEntry,
  IPageDto,
  ITeamMemberDto,
  IUpdatedParagraph
} from '../types';
import { TFirstEmptyTranslationDto } from '../types';
import { prepareTranslationContent } from '../utils';

// TODO: move these to separete files into thunks folder

/**
 * Update language status in bootstrap data query
 */
export const updateLanguageStatus = (
  args: IGetBootstrapDataQueryArg,
  { newStatus, segmentsDraftCount }: { newStatus: TranslationStatus; segmentsDraftCount?: number | null }
) =>
  languageApiUtil.safeUpdateQueryData('getBootstrapData', args, (bootstrapData) => {
    const { weConfig } = bootstrapData;

    bootstrapData.translationStatus = newStatus;

    switch (newStatus) {
      case TranslationStatus.CONFIRMED:
        // Translation is CONFIRMED, block the web editor and set blocked reason to CONFIRMED
        // if it's empty
        if (!weConfig.blockedReason) {
          weConfig.isBlocked = true;
          weConfig.blockedReason = EBlockedReason.CONFIRMED;
        }
        break;

      case TranslationStatus.CREATED:
        // Translation is CREATED, unblock the web editor and remove blocked reason
        // if it's CONFIRMED (we need to keep it blocked if the reason is EXPIRED_SUBSCRIPTION)
        if (weConfig.blockedReason === EBlockedReason.CONFIRMED) {
          weConfig.isBlocked = false;
          // @ts-expect-error: TS 5 migration error
          weConfig.blockedReason = null;
        }
        break;
    }

    if (_isNumber(segmentsDraftCount)) {
      bootstrapData.segmentsDraftCount = segmentsDraftCount;
    }
  });

/**
 * Add team member in bootstrap data query
 */
export const addTeamMember = (args: IGetBootstrapDataQueryArg, { newTeamMember }: { newTeamMember: ITeamMemberDto }) =>
  languageApiUtil.safeUpdateQueryData('getBootstrapData', args, ({ teamMembers }) => {
    const found = !!teamMembers.find((teamMember) => teamMember.id === newTeamMember.id);
    if (!found) {
      teamMembers.push(newTeamMember);
    }
  });

/**
 * Remove team member from bootstrap data query
 */
export const removeTeamMember = (args: IGetBootstrapDataQueryArg, { teamMemberId }: { teamMemberId: number }) =>
  languageApiUtil.safeUpdateQueryData('getBootstrapData', args, (data) => {
    data.teamMembers = data.teamMembers.filter((tm) => tm.id !== teamMemberId);
  });

/**
 * Update first empty paragraph reference
 */
export const updateFirstEmptyTranslation = (
  args: IGetBootstrapDataQueryArg,
  { firstEmptyTranslation }: { firstEmptyTranslation: TFirstEmptyTranslationDto }
) =>
  languageApiUtil.safeUpdateQueryData('getBootstrapData', args, (data) => {
    data.firstEmptyTranslation = firstEmptyTranslation;
  });

/**
 * Update page data in all translation list queries (by document ID and language ID)
 */
export const updateTranslationListPage = (
  args: IGetTranslationListQueryArg,
  { pageNumber, pageFields }: { pageNumber: number | 'ALL'; pageFields: Partial<IPageDto> }
) =>
  languageApiUtil.safeUpdateQueryData('getTranslationList', args, ({ pages }) => {
    pages.forEach((page, i, array) => {
      if (pageNumber === 'ALL' || page.number === pageNumber) {
        array[i] = { ...page, ...pageFields };
      }
    });
  });

/*
 * Update translation content
 */
export const updateTranslationContent = (
  args: IGetTranslationListQueryArg,
  paragraphId: number,
  { newContent, glossaryEntries }: { newContent: string; glossaryEntries: IGlossaryEntry[] }
) =>
  languageApiUtil.safeUpdateQueryData('getTranslationList', args, ({ paragraphs }) => {
    paragraphs.forEach((paragraph) => {
      if (paragraph.id === paragraphId) {
        // @ts-expect-error: TS 5 migration error
        paragraph.translation = prepareTranslationContent(paragraph.translation, newContent);
        paragraph.glossaryEntries = glossaryEntries;
      }
    });
  });

/*
 * Update translation status
 */
export const updateTranslationsStatus = (
  args: IGetTranslationListQueryArg,
  updatedParagraphIds: number[],
  newStatus: EParagraphTranslationStatus
) =>
  languageApiUtil.safeUpdateQueryData('getTranslationList', args, ({ paragraphs }) => {
    paragraphs.forEach((paragraph) => {
      if (updatedParagraphIds.includes(paragraph.id) && paragraph.translation) {
        paragraph.translation.status = newStatus;
      }
    });
  });

/*
 * Confirm all draft translations
 */
export const confirmDraftTranslations = (args: IGetTranslationListQueryArg) =>
  languageApiUtil.safeUpdateQueryData('getTranslationList', args, ({ paragraphs }) => {
    paragraphs.forEach((paragraph) => {
      if (paragraph.translation?.status === EParagraphTranslationStatus.DRAFT) {
        paragraph.translation.status = EParagraphTranslationStatus.CONFIRMED;
      }
    });
  });

/*
 * Update translation status
 */
export const copySameTranslation = (
  args: IGetTranslationListQueryArg,
  { copiedContent, updatedParagraphs }: { copiedContent: string; updatedParagraphs: IUpdatedParagraph[] }
) =>
  languageApiUtil.safeUpdateQueryData('getTranslationList', args, ({ paragraphs }) => {
    paragraphs.forEach((paragraph) => {
      const updatedParagraph = updatedParagraphs.find((updatedParagraph) => updatedParagraph.id === paragraph.id);
      if (updatedParagraph) {
        // @ts-expect-error: TS 5 migration error
        paragraph.translation = prepareTranslationContent(paragraph.translation, copiedContent);
        paragraph.glossaryEntries = updatedParagraph.glossaryEntries;
      }
    });
  });

/*
 * Clear MT and TM
 */
export const clearMtAndTm =
  ({ documentId, languageId, paragraphId }: { documentId: number; languageId: number; paragraphId: number }) =>
  (dispatch: TAppDispatch) => {
    [
      languageApiUtil.safeUpdateQueryData('getTmSuggestions', { documentId, languageId, paragraphId }, (data) => {
        if (data) {
          data.suggestions = [];
        }
      }),
      languageApiUtil.safeUpdateQueryData(
        'getMt',
        { documentId, languageId, paragraphId, vendor: MachineTranslationVendor.DEEPL },
        (data) => {
          if (data) {
            data.translation = undefined;
            data.translationClean = undefined;
          }
        }
      ),
      languageApiUtil.safeUpdateQueryData(
        'getMt',
        { documentId, languageId, paragraphId, vendor: MachineTranslationVendor.GOOGLE_TRANSLATE },
        (data) => {
          if (data) {
            data.translation = undefined;
            data.translationClean = undefined;
          }
        }
      )
    ].map(dispatch);
  };
