export const recipientCodeRegEx = new RegExp('^[a-zA-Z0-9]{7}$');

export const defaultBillingsValues = {
  companyName: '',
  addressCountryId: 0,
  addressStreet: '',
  addressCity: '',
  addressZipCode: '',
  vatNumber: null,
  billingEmail: null,
  recipientCode: null
};
