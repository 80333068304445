import { EPlaceholderType } from '@/redux/api/constants';
import type { TPlaceholder } from '@/redux/api/types';
import { classNames } from '@/utils/classNames';

import type { TGetClassNameOptions } from './types';

export const getClassName = ({ type }: TPlaceholder, { ring, className }: TGetClassNameOptions = {}) =>
  classNames(
    'inline-block rounded-sm px-1.5 py-0.5 text-xs leading-none',
    {
      'ring-2': ring,
      'bg-blue-300 ring-blue-400': type === EPlaceholderType.MARKER,
      'bg-orange-300 ring-orange-400': type === EPlaceholderType.TAG
    },
    className
  );

export const getLabel = ({ id, label }: TPlaceholder) => {
  if (label) {
    return label;
  }

  const [idLabel] = id.match(/\d+/) ?? [id];
  return idLabel;
};
