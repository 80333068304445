import {
  confirmDraftTranslations,
  copySameTranslation,
  updateFirstEmptyTranslation,
  updateLanguageStatus,
  updateTranslationContent,
  updateTranslationsStatus
} from './actions';
import { redokunApi } from './redokunApi';
import {
  bootstrapSegmentsChanged,
  documentCommentCreated,
  documentCommentDeleted,
  paragraphCommentCreated,
  paragraphCommentDeleted
} from './thunks';
import { paragraphCommentsCountersChanged } from './thunks/paragraphCommentsCountersChanged';
import type {
  AddLanguagesToDocumentsRequestQueryArgs,
  GetLanguagesQueryArgs,
  IAskLanguageUnlockQueryArg,
  IBootstrapDataResponseDto,
  IChangeLanguageStatusQueryArg,
  IChangeLanguageStatusResponse,
  ICopySameTranslationQueryArg,
  ICopySameTranslationResponseDto,
  ICreateDocumentTranslatedCommentQueryArg,
  ICreateDocumentTranslatedCommentResponseDto,
  ICreateParagraphCommentQueryArg,
  ICreateParagraphCommentResponseDto,
  IDeleteDocumentTranslatedCommentQueryArg,
  IDeleteParagraphCommentQueryArg,
  IDeleteParagraphCommentResponseDto,
  IGetBootstrapDataQueryArg,
  IGetDocumentTranslatedCommentQueryArg,
  IGetDocumentTranslatedCommentResponseDto,
  IGetMtQueryArg,
  IGetMtResponseDto,
  IGetParagraphActivityCommentsQueryArg,
  IGetParagraphCommentQueryArg,
  IGetParagraphCommentResponse,
  IGetTmSuggestionsQueryArg,
  IGetTmSuggestionsResponse,
  IGetTranslationListQueryArg,
  IMarkDocumentTranslatedCommentReadQueryArg,
  IMarkDocumentTranslatedCommentReadResponse,
  IMarkParagraphCommentReadQueryArg,
  IMarkParagraphCommentReadResponse,
  IParagraphActivityListDto,
  ITranslationListResponse,
  IUpdateTranslationInvitationQueryArg,
  IUpdateTranslationQueryArg,
  IUpdateTranslationResponse,
  IUpdateTranslationStatusQueryArg,
  IUpdateTranslationStatusResponse,
  LanguagesDto,
  OkResponseDto,
  PutLanguagesQueryArgs
} from './types';
import { getLanguageUrl, getQueryStatesByName } from './utils';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const languageApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getBootstrapData: builder.query<IBootstrapDataResponseDto, IGetBootstrapDataQueryArg>({
      query: ({ documentId, languageId, sendCountersAsync = true }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/config`,
        params: { sendCountersAsync }
      }),
      providesTags: ['bootstrapData']
    }),

    getTmSuggestions: builder.query<IGetTmSuggestionsResponse, IGetTmSuggestionsQueryArg>({
      query: ({ documentId, languageId, paragraphId }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/suggestions`,
        params: { paragraphId }
      }),
      providesTags: ['tmSuggestions']
    }),

    getMt: builder.query<IGetMtResponseDto, IGetMtQueryArg>({
      query: ({ documentId, languageId, paragraphId, vendor }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/mt`,
        params: { paragraphId, vendor }
      }),
      providesTags: ['mt']
    }),

    getTranslationList: builder.query<ITranslationListResponse, IGetTranslationListQueryArg>({
      query: ({ documentId, languageId, paginationPage, searchSource, searchTarget, filter }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu`,
        params: { paginationPage, searchSource, searchTarget, filter }
      }),
      providesTags: ['translationList']
    }),

    getDocumentTranslatedComment: builder.query<
      IGetDocumentTranslatedCommentResponseDto,
      IGetDocumentTranslatedCommentQueryArg
    >({
      query: ({ documentId, languageId }) => `${getLanguageUrl(documentId, languageId)}/comment`,
      providesTags: ['documentTranslatedComment']
    }),

    getParagraphComment: builder.query<IGetParagraphCommentResponse, IGetParagraphCommentQueryArg>({
      query: ({ documentId, languageId, paragraphId }) =>
        `${getLanguageUrl(documentId, languageId)}/tu/${paragraphId}/comment`,
      providesTags: ['paragraphComment']
    }),

    getParagraphActivityComments: builder.query<IParagraphActivityListDto, IGetParagraphActivityCommentsQueryArg>({
      query: ({ documentId, languageId, paragraphId }) =>
        `${getLanguageUrl(documentId, languageId)}/tu/${paragraphId}/activity-comments`,
      providesTags: ['paragraphComment']
    }),

    changeLanguageStatus: builder.mutation<IChangeLanguageStatusResponse, IChangeLanguageStatusQueryArg>({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/status`,
        method: 'POST',
        body
      }),
      onQueryStarted: (arg, { queryFulfilled, dispatch, getState }) => {
        const { documentId, languageId, body } = arg;
        const { status, doConfirmDraftTranslations } = body;

        queryFulfilled.then(({ data }) => {
          const { segmentsDraftCount } = data;

          dispatch(updateLanguageStatus({ documentId, languageId }, { newStatus: status, segmentsDraftCount }));

          if (doConfirmDraftTranslations) {
            const { selectInvalidatedBy } = languageApi.util;
            const { getTranslationList } = languageApi.endpoints;
            const queries = selectInvalidatedBy(getState(), ['translationList']).filter(
              (query) => query.endpointName === getTranslationList.name
            );
            queries.forEach((query) => dispatch(confirmDraftTranslations(query.originalArgs)));
          }
        });
      },
      invalidatesTags: ['documentDetail']
    }),

    askLanguageUnlock: builder.mutation<OkResponseDto, IAskLanguageUnlockQueryArg>({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/ask-unlock`,
        method: 'POST',
        body
      })
    }),

    updateTranslation: builder.mutation<IUpdateTranslationResponse, IUpdateTranslationQueryArg>({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/content`,
        method: 'POST',
        body
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled, getState }) => {
        const { documentId, languageId } = arg;
        const { paragraphId } = arg.body;

        queryFulfilled.then(({ data }) => {
          dispatch(
            bootstrapSegmentsChanged(
              { documentId, languageId },
              {
                segmentsDraftCount: data.updatedSegmentsDraftCount,
                segmentsTranslatedCount: data.updatedSegmentsTranslatedCount
              }
            )
          );

          const queryStates = getQueryStatesByName(getState().redokunApi.queries, 'getTranslationList').filter(
            ({ originalArgs }) => originalArgs?.documentId === documentId && originalArgs?.languageId === languageId
          );
          queryStates.forEach(({ originalArgs }) => {
            dispatch(
              updateTranslationContent(originalArgs, paragraphId, {
                newContent: data.savedContent,
                glossaryEntries: data.glossaryEntries
              })
            );

            // Update new empty translation cell
            dispatch(
              updateFirstEmptyTranslation(
                { documentId, languageId },
                {
                  firstEmptyTranslation: data?.firstEmptyTranslation
                }
              )
            );
          });
        });
      }
    }),

    updateTranslationStatus: builder.mutation<IUpdateTranslationStatusResponse, IUpdateTranslationStatusQueryArg>({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/status`,
        method: 'POST',
        body
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled, getState }) => {
        const { documentId, languageId } = arg;
        const { status } = arg.body;

        queryFulfilled.then(({ data }) => {
          dispatch(
            bootstrapSegmentsChanged({ documentId, languageId }, { segmentsDraftCount: data.segmentsDraftCount })
          );

          const queryStates = getQueryStatesByName(getState().redokunApi.queries, 'getTranslationList').filter(
            ({ originalArgs }) => originalArgs?.documentId === documentId && originalArgs?.languageId === languageId
          );
          queryStates.forEach(({ originalArgs }) => {
            dispatch(updateTranslationsStatus(originalArgs, data.updatedParagraphIds, status));
          });
        });
      }
    }),

    copySameTranslation: builder.mutation<ICopySameTranslationResponseDto, ICopySameTranslationQueryArg>({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/copy`,
        method: 'POST',
        body
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled, getState }) => {
        const { documentId, languageId } = arg;

        queryFulfilled.then(({ data }) => {
          dispatch(
            bootstrapSegmentsChanged(
              { documentId, languageId },
              {
                segmentsTranslatedCount: data.updatedSegmentsTranslatedCount
              }
            )
          );

          const queryStates = getQueryStatesByName(getState().redokunApi.queries, 'getTranslationList').filter(
            ({ originalArgs }) => originalArgs?.documentId === documentId && originalArgs?.languageId === languageId
          );
          queryStates.forEach(({ originalArgs }) => {
            dispatch(
              copySameTranslation(originalArgs, {
                copiedContent: data.copiedContent,
                updatedParagraphs: data.updatedParagraphs
              })
            );

            // Update new empty translation cell
            dispatch(
              updateFirstEmptyTranslation(
                { documentId, languageId },
                {
                  firstEmptyTranslation: data?.firstEmptyTranslation
                }
              )
            );
          });
        });
      }
    }),

    createDocumentTranslatedComment: builder.mutation<
      ICreateDocumentTranslatedCommentResponseDto,
      ICreateDocumentTranslatedCommentQueryArg
    >({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/comment`,
        method: 'POST',
        body
      }),
      onQueryStarted: ({ documentId, languageId, currentAccount, body }, { queryFulfilled, dispatch }) => {
        queryFulfilled.then(({ data }) => {
          const comment = {
            id: data.documentTranslatedCommentId,
            createdAt: new Date().toISOString(),
            content: body.content,
            isRead: true,
            account: {
              id: currentAccount.id,
              fullName: currentAccount.fullName,
              avatarUrl: currentAccount.avatarUrl
            }
          };
          dispatch(documentCommentCreated({ documentId, languageId }, comment));
        });
      }
    }),

    markDocumentTranslatedCommentRead: builder.mutation<
      IMarkDocumentTranslatedCommentReadResponse,
      IMarkDocumentTranslatedCommentReadQueryArg
    >({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/comment/mark-read`,
        method: 'POST',
        body
      }),
      onQueryStarted: ({ documentId, languageId, body }, { queryFulfilled, dispatch }) => {
        const getDocumentTranslatedCommentAction = languageApiUtil.safeUpdateQueryData(
          'getDocumentTranslatedComment',
          { documentId, languageId },
          ({ comments }) => {
            comments.forEach((comment) => {
              if (comment.id <= body.lastReadDocumentTranslatedCommentId) {
                comment.isRead = true;
              }
            });
          }
        );
        const getDocumentTranslatedCommentPatch = dispatch(getDocumentTranslatedCommentAction);

        queryFulfilled.catch(() => {
          getDocumentTranslatedCommentPatch.undo();
        });
      }
    }),

    deleteDocumentTranslatedComment: builder.mutation<OkResponseDto, IDeleteDocumentTranslatedCommentQueryArg>({
      query: ({ documentId, languageId, commentId }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/comment/${commentId}`,
        method: 'DELETE'
      }),
      onQueryStarted: ({ documentId, languageId, commentId }, { queryFulfilled, dispatch }) => {
        const patch = dispatch(documentCommentDeleted({ documentId, languageId }, commentId));
        queryFulfilled.catch(() => {
          patch.undo();
        });
      }
    }),

    createParagraphComment: builder.mutation<ICreateParagraphCommentResponseDto, ICreateParagraphCommentQueryArg>({
      query: ({ documentId, languageId, paragraphId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/${paragraphId}/comment`,
        method: 'POST',
        body
      }),
      onQueryStarted: ({ documentId, languageId, paragraphId, currentAccount, body }, { queryFulfilled, dispatch }) => {
        queryFulfilled.then(({ data }) => {
          const comment = {
            id: data.paragraphCommentId,
            createdAt: new Date().toISOString(),
            content: body.content,
            isRead: true,
            account: { id: currentAccount.id, fullName: currentAccount.fullName, avatarUrl: currentAccount.avatarUrl }
          };
          dispatch(paragraphCommentCreated({ documentId, languageId, paragraphId }, comment));
          dispatch(
            paragraphCommentsCountersChanged(
              { documentId, languageId },
              { paragraphId, readCommentsCount: data.readCommentsCount, totalCommentsCount: data.totalCommentsCount }
            )
          );
          dispatch(
            bootstrapSegmentsChanged(
              { documentId, languageId },
              { segmentsWithMessagesCount: data.segmentsWithMessagesCount }
            )
          );
        });
      }
    }),

    markParagraphCommentRead: builder.mutation<IMarkParagraphCommentReadResponse, IMarkParagraphCommentReadQueryArg>({
      query: ({ documentId, languageId, paragraphId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/${paragraphId}/comment/mark-read`,
        method: 'POST',
        body
      }),
      onQueryStarted: ({ documentId, languageId, paragraphId, body }, { queryFulfilled, dispatch, getState }) => {
        // TODO: update cache based on response

        let readCommentsCount = 0;
        let unreadCommentsCount = 0;

        const getParagraphCommentAction = languageApiUtil.safeUpdateQueryData(
          'getParagraphComment',
          { documentId, languageId, paragraphId },
          ({ comments }) => {
            comments.forEach((comment) => {
              if (comment.id <= body.lastReadParagraphCommentId) {
                comment.isRead = true;
              }

              if (comment.isRead) {
                readCommentsCount++;
              } else {
                unreadCommentsCount++;
              }
            });
          }
        );
        const getParagraphCommentPatch = dispatch(getParagraphCommentAction);

        const queryStates = getQueryStatesByName(getState().redokunApi.queries, 'getTranslationList');
        const getTranslationListPatches = queryStates.map(({ originalArgs }) => {
          if (originalArgs?.documentId !== documentId || originalArgs?.languageId !== languageId) {
            return null;
          }

          const getTranslationListAction = languageApiUtil.safeUpdateQueryData(
            'getTranslationList',
            originalArgs,
            ({ paragraphs }) => {
              paragraphs.forEach((paragraph) => {
                if (paragraph.id === paragraphId) {
                  paragraph.readCommentsCount = readCommentsCount;
                  paragraph.unreadCommentsCount = unreadCommentsCount;
                  paragraph.totalCommentsCount = readCommentsCount + unreadCommentsCount;
                }
              });
            }
          );
          return dispatch(getTranslationListAction);
        });

        queryFulfilled.catch(() => {
          getParagraphCommentPatch.undo();
          getTranslationListPatches.forEach((patch) => {
            patch?.undo();
          });
        });
      }
    }),

    deleteParagraphComment: builder.mutation<IDeleteParagraphCommentResponseDto, IDeleteParagraphCommentQueryArg>({
      query: ({ documentId, languageId, paragraphId, commentId }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/tu/${paragraphId}/comment/${commentId}`,
        method: 'DELETE'
      }),
      onQueryStarted: ({ documentId, languageId, paragraphId, commentId }, { queryFulfilled, dispatch }) => {
        const patch = dispatch(paragraphCommentDeleted({ documentId, languageId, paragraphId }, commentId));
        queryFulfilled
          .then(({ data }) => {
            dispatch(
              paragraphCommentsCountersChanged(
                { documentId, languageId },
                { paragraphId, readCommentsCount: data.readCommentsCount, totalCommentsCount: data.totalCommentsCount }
              )
            );
            dispatch(
              bootstrapSegmentsChanged(
                { documentId, languageId },
                { segmentsWithMessagesCount: data.segmentsWithMessagesCount }
              )
            );
          })
          .catch(() => {
            patch.undo();
          });
      }
    }),

    updateTranslationInvitation: builder.mutation<OkResponseDto, IUpdateTranslationInvitationQueryArg>({
      query: ({ documentId, languageId, body }) => ({
        url: `${getLanguageUrl(documentId, languageId)}/invitation`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['bootstrapData']
    }),

    getLanguages: builder.query<LanguagesDto, GetLanguagesQueryArgs | void>({
      query: ({ overrideRegionalSettings } = {}) => {
        const params = { overrideRegionalSettings };
        return { url: '/language', params };
      },
      providesTags: ['languages']
    }),

    putLanguages: builder.mutation<OkResponseDto, PutLanguagesQueryArgs>({
      query: (payload) => ({
        url: '/language',
        method: 'PUT',
        body: payload
      }),
      // The enableRegionalVariants is provided by getCurrentAccount.settings
      invalidatesTags: ['currentAccount', 'languages']
    }),

    addLanguagesToDocuments: builder.mutation<OkResponseDto, AddLanguagesToDocumentsRequestQueryArgs>({
      query: ({ documentIds, languages }) => ({
        url: '/document-list/translation',
        method: 'PATCH',
        body: {
          documentIds,
          languages
        }
      }),
      // we need to update document list after batch operation
      invalidatesTags: ['documentDetail', 'documentList']
    })
  })
});

export const {
  useAskLanguageUnlockMutation,
  useChangeLanguageStatusMutation,
  useCopySameTranslationMutation,
  useCreateDocumentTranslatedCommentMutation,
  useCreateParagraphCommentMutation,
  useGetBootstrapDataQuery,
  useGetDocumentTranslatedCommentQuery,
  useGetMtQuery,
  useGetParagraphCommentQuery,
  useGetParagraphActivityCommentsQuery,
  useGetTmSuggestionsQuery,
  useGetTranslationListQuery,
  useLazyGetBootstrapDataQuery,
  useLazyGetDocumentTranslatedCommentQuery,
  useLazyGetMtQuery,
  useLazyGetParagraphCommentQuery,
  useLazyGetParagraphActivityCommentsQuery,
  useLazyGetTmSuggestionsQuery,
  useLazyGetTranslationListQuery,
  useMarkDocumentTranslatedCommentReadMutation,
  useMarkParagraphCommentReadMutation,
  useUpdateTranslationMutation,
  useUpdateTranslationStatusMutation,
  useUpdateTranslationInvitationMutation,
  useDeleteDocumentTranslatedCommentMutation,
  useDeleteParagraphCommentMutation,
  useGetLanguagesQuery,
  useLazyGetLanguagesQuery,
  useAddLanguagesToDocumentsMutation,
  usePutLanguagesMutation
} = languageApi;

export const languageApiUtil = {
  ...languageApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(languageApi.util.updateQueryData)
};
