import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AppLayout from '@/components/layouts/AppLayout';
import { Button } from '@/components/v2/Button';
import { Navbar } from '@/components/v2/Navbar';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useUpdateFlagsMutation } from '@/redux/api';

interface DocumentLayoutProps {
  title: string;
  children?: ReactNode;
}

const DocumentLayout = (props: DocumentLayoutProps) => {
  const { title, children } = props;

  const history = useHistory();
  const { t: tLayout } = useTranslation('layout');

  const [updateFlag] = useUpdateFlagsMutation();
  const { currentAccount } = useCurrentAccount();

  const handleSkipForNow = () => {
    updateFlag({ hasHiddenOnboardingWizardWidget: true });
    history.push('/documents');
  };

  return (
    <AppLayout.Root title={title}>
      <Show
        when={currentAccount.onboardingFlags.hasConfirmedAnyDocument}
        fallback={
          <Navbar sticky>
            <Button onClick={handleSkipForNow} variant="surface" color="secondary" className="ml-auto">
              {tLayout('skipForNow')}
            </Button>
          </Navbar>
        }
      >
        <AppLayout.Navbar />
      </Show>

      <AppLayout.Content>{children}</AppLayout.Content>
    </AppLayout.Root>
  );
};
DocumentLayout.displayName = 'DocumentLayout';

export { DocumentLayout };
export type { DocumentLayoutProps };
