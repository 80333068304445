import { Editor, Element, Transforms } from 'slate';

import { EPlaceholderType } from '@/redux/api/constants';
import type { TPlaceholder } from '@/redux/api/types';

import { EElementType } from '../../../constants';

export const withPlaceholders = (placeholders: TPlaceholder[]) => (editor: Editor) => {
  const { isInline, isVoid, normalizeNode } = editor;
  const tags = placeholders.filter((placeholder) => placeholder.type === EPlaceholderType.TAG);

  editor.isInline = (element) => {
    return element.type === EElementType.PLACEHOLDER_V1 ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === EElementType.PLACEHOLDER_V1 ? true : isVoid(element);
  };

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 1) {
      // Tag placeholders reindexing
      const tagsGenerator = Editor.nodes(editor, {
        at: path,
        match: (n) =>
          Element.isElement(n) && n.type === EElementType.PLACEHOLDER_V1 && n.placeholder.type === EPlaceholderType.TAG
      });

      let tagEntry;
      let tagIndex = 0;
      while ((tagEntry = tagsGenerator.next().value) && tagIndex < tags.length) {
        const tag = tags[tagIndex];
        const [, tagPath] = tagEntry;
        Transforms.setNodes(editor, { placeholder: tag }, { at: tagPath });
        tagIndex++;
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};
