import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import { text } from './styles';
import type { TextProps } from './types';

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  ({ asChild, color, size, theme, weight, className, ...props }, ref) => {
    const Component = asChild ? Slot : 'p';
    return <Component {...props} className={classNames(text({ color, size, theme, weight }), className)} ref={ref} />;
  }
);
Text.displayName = 'Text';
