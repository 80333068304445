import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { Box } from '@/components/v2/Box';
import { Button } from '@/components/v2/Button';
import { Show } from '@/components/v2/Show';
import Table from '@/components/v2/Table';
import { Text } from '@/components/v2/Text';
import { APP_URL } from '@/config/urls';
import { GetBillingDetailResponseDto } from '@/redux/api/types';
import { standardDateFormat } from '@/utils/date';
import { formatCurrency } from '@/utils/number';

export const InvoicesTable = ({
  isLoading,
  billingsData
}: {
  isLoading: boolean;
  billingsData?: GetBillingDetailResponseDto;
}) => {
  const { t: tSettings } = useTranslation('settings');

  const handleDownload = (invoiceId: string) => {
    window.open(`${APP_URL}/api/v1/billing/invoice/${invoiceId}`);
  };

  const tableHeader = (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>{tSettings('number')}</Table.HeaderCell>
        <Table.HeaderCell>{tSettings('description')}</Table.HeaderCell>
        <Table.HeaderCell>{tSettings('date')}</Table.HeaderCell>
        <Table.HeaderCell>{tSettings('total')}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  const tableBody = (
    <Table.Body>
      {billingsData?.invoices.map((invoice, i) => {
        const { currency, isGenerated, description, documentNumber, date, totalAmount, stringId } = invoice;

        return (
          <Table.Row key={i}>
            {/* Number */}
            <Table.Cell>{isGenerated ? documentNumber : '–'}</Table.Cell>
            {/* Description */}
            <Table.Cell>{description}</Table.Cell>
            {/* Date */}
            <Table.Cell>{standardDateFormat(date)}</Table.Cell>
            {/* Total */}
            <Table.Cell>{formatCurrency(totalAmount, { currency })}</Table.Cell>
            {/* Download */}
            <Table.Cell align="right">
              <Button onClick={() => handleDownload(stringId)} variant="text" disabled={!isGenerated}>
                {tSettings('download')}
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );

  return (
    <Show
      when={!isLoading}
      fallback={
        <Box className="border-none">
          <div className="flex h-56 justify-center rounded-lg">
            <Spinner size="lg" />
          </div>
        </Box>
      }
    >
      <Box className="border-none">
        <Text size="md" weight="bold" color="primary">
          {tSettings('invoices')}
        </Text>
        <Table.Root>
          {tableHeader}
          {tableBody}
        </Table.Root>
      </Box>
    </Show>
  );
};
