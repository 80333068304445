import * as RadixToast from '@radix-ui/react-toast';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { classNames } from '@/utils/classNames';
import { Show } from '@/components/v2/Show';

import { ICONS, ICONS_COLOR } from './constants';
import { toastVariants } from './styles';
import { ToastProps } from './types';
import s from './toast.module.css';

export const Toast = (props: ToastProps) => {
  const { title, description, actionLabel, action, kind, className, ...rootProps } = props;

  const icon = ICONS[kind || 'informational'];
  const iconColor = ICONS_COLOR[kind || 'informational'];

  const columns = (action && description) || (action === undefined && description) ? 'grid-cols-6' : 'grid-cols-8';

  return (
    <RadixToast.Root {...rootProps} className={classNames(s['toast'], toastVariants({ kind }), columns, className)}>
      <div className="col-span-1">
        <Show
          when={kind !== 'loading'}
          fallback={
            <div className="flex h-8 w-8 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-white via-blue-100 to-blue-500 p-1">
              <div className="h-6 w-6 rounded-full bg-white"></div>
            </div>
          }
        >
          <AccessibleIcon label={icon} icon={icon} className={classNames('text-4xl', iconColor)} />
        </Show>
      </div>
      <div
        className={
          (action && description === undefined) || (description && action === undefined) || (action && description)
            ? 'col-span-4'
            : 'col-span-6'
        }
      >
        {title ? (
          <RadixToast.Title className={!description ? 'mt-2' : 'mt-1'}>
            <Text weight={'bold'} size={'md'} color="secondary">
              {title}
            </Text>
          </RadixToast.Title>
        ) : (
          ''
        )}
        {description ? (
          <RadixToast.Description className="mt-3">
            <Text>{description}</Text>
          </RadixToast.Description>
        ) : (
          ''
        )}
      </div>
      {action && description === undefined ? (
        <div className="col-span-2">
          <Button
            color="secondary"
            variant={'surface'}
            onClick={() => {
              action();
              if (rootProps.onOpenChange) {
                rootProps.onOpenChange(false);
              }
            }}
          >
            {actionLabel}
          </Button>
        </div>
      ) : null}
      <div className="col-span-1 flex justify-end">
        <div>
          <RadixToast.Close>
            <AccessibleIcon
              label="ri-close-line"
              icon="ri-close-line"
              className="cursor-pointer text-4xl text-gray-400"
            />
          </RadixToast.Close>
        </div>
      </div>
      {action && description ? (
        <div className="col-span-6 flex justify-end px-2">
          <Button
            color="secondary"
            variant={'surface'}
            onClick={() => {
              action();
              if (rootProps.onOpenChange) {
                rootProps.onOpenChange(false);
              }
            }}
          >
            {actionLabel ?? 'Action'}
          </Button>
        </div>
      ) : null}
    </RadixToast.Root>
  );
};

Toast.displayName = 'Toast';
