import {
  CollapsibleInlineMessage,
  CollapsibleInlineMessageContent,
  CollapsibleInlineMessageHeader,
  CollapsibleInlineMessageTitle,
  CollapsibleInlineMessageTrigger
} from './CollapsibleInlineMessage';

const CollapsibleInlineMessageDefault = {
  Root: CollapsibleInlineMessage,
  Header: CollapsibleInlineMessageHeader,
  Title: CollapsibleInlineMessageTitle,
  Content: CollapsibleInlineMessageContent,
  Trigger: CollapsibleInlineMessageTrigger
};

export default CollapsibleInlineMessageDefault;

export {
  CollapsibleInlineMessage,
  CollapsibleInlineMessageContent,
  CollapsibleInlineMessageTrigger,
  CollapsibleInlineMessageTitle,
  CollapsibleInlineMessageHeader
} from './CollapsibleInlineMessage';
