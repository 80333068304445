import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import { Progress } from '@/components/v1/Progress';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Show } from '@/components/v2/Show';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { TranslationStatus } from '@/redux/api/constants';
import { DocumentListItemDocumentDto, DocumentListItemDto, SourceDocumentDtoStatus } from '@/redux/api/types';
import { isFolderItem } from '@/routes/DocumentList/components/DocumentListContent/utils';
import { isNotEmpty } from '@/utils/isNotEmpty';

export const TargetLanguageCell = () => {
  const { t: tDocumentList } = useTranslation('documentList');

  const { cell } = DataTable.useCellContext<DocumentListItemDto, DocumentListItemDto>('TargetLanguageCell');
  const { original: data, getIsExpanded } = cell.row;

  if (isFolderItem(data) || isEmpty(data.item.translations)) {
    return <div />;
  }

  const isNotExpanded = !getIsExpanded();
  const { status: documentStatus, translations } = data.item;
  const length = translations.length;

  const notHiddenTranslations = translations.filter((t) => t.isHidden === false);
  const firstDocument = notHiddenTranslations[0];

  if (!firstDocument) {
    return <div />;
  }

  const showOtherTagOnFirstElement = notHiddenTranslations.length === 1 && translations.length > 1;
  const flagCode = firstDocument.language.flagCode;
  // get other language from not filtered (all) translations
  // ignore the firstDocument and get langauge names
  const otherLanguagesName = translations.filter((t) => t.id !== firstDocument.id).flatMap((dt) => dt.language.name);

  // returns an array with all translators who have not yet accepted an invitation
  function getNotAcceptedTranslatorsEmails(translations: DocumentListItemDocumentDto['translations']): string[] {
    const emails: string[] = [];

    translations.forEach((translation) => {
      translation.translators.forEach((translator) => {
        if (translator.isNotAccepted) {
          emails.push(translator.email);
        }
      });
    });

    return emails;
  }
  const inviteNotAcceptedTranslatorsEmailList = getNotAcceptedTranslatorsEmails(notHiddenTranslations);
  const translatorsEmailListCount = inviteNotAcceptedTranslatorsEmailList.length;
  const firstDocumentLanguageName = firstDocument.language.name;

  // render a tooltip tag with a list of other languages used as target language
  const renderOtherLanguagesTag = (otherLanguagesName: string[]) => {
    return (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Tag size="md" color="primary" variant="soft">
            {tDocumentList('others', { count: length - 1 })}
          </Tag>
        </Tooltip.Trigger>
        <Show when={isNotEmpty(otherLanguagesName)}>
          <Tooltip.Content className="max-w-xxs" side="right">
            <Text className="text-typography-inverse-primary" size="sm" weight="medium">
              {otherLanguagesName.map((name) => name).join(', ')}
            </Text>
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Show>
      </Tooltip.Root>
    );
  };

  // render a warning icon with tooltip with a list of emails from translators who have not yet accepted the invitation
  const renderInviteNotAcceptedWarning = (translatorEmailList: string[], count: number) => {
    return (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <AccessibleIcon
            label="warning-icon"
            icon="ri-alert-line"
            className="text-base text-states-alert-active hover:text-states-alert-hover"
          />
        </Tooltip.Trigger>
        <Show when={isNotEmpty(translatorEmailList)}>
          <Tooltip.Content className="max-w-xxs" side="right">
            <Text className="text-typography-inverse-primary" size="sm" weight="medium">
              {count === 1
                ? tDocumentList('oneTranslatorIsYetToAcceptYourInvitation')
                : tDocumentList('multipleTranslatorIsYetToAcceptYourInvitation', {
                    count: count
                  })}
            </Text>
            <Text className="text-typography-inverse-secondary" size="sm" weight="medium">
              {translatorEmailList.map((t) => t).join('\n')}
            </Text>
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Show>
      </Tooltip.Root>
    );
  };

  // render the first expanded language
  const renderExpandedFirstLanguage = () => {
    const {
      id,
      language,
      status,
      completedPercentage,
      allTranslatorsAccepted,
      translators,
      translationProgress: { draftSegments, translatedSegments, untranslatedSegments }
    } = firstDocument;
    const confirmed = translatedSegments - draftSegments;
    const total = translatedSegments + untranslatedSegments;
    const successPercent = Math.floor((100 * confirmed) / total) || 0;

    const inviteNotAcceptedTranslatorsEmailList = translators.filter((t) => t.isNotAccepted).map((t) => t.email);
    const translatorsEmailListCount = inviteNotAcceptedTranslatorsEmailList.length;

    return (
      <div key={id} className="flex flex-col gap-2">
        <div className="justify-left flex flex-row items-center gap-2">
          <ButtonLink
            href={`/documents/show/${data.item.id}/translations/${language.id}`}
            size="sm"
            variant="text"
            disabled={documentStatus !== SourceDocumentDtoStatus.INDEXED}
          >
            <FlagIcon label={language.name} code={language.flagCode} size="md" />
            {language.name}
            <AccessibleIcon icon="ri-external-link-line" label={tDocumentList('editorIcon')} />
          </ButtonLink>

          <Show when={showOtherTagOnFirstElement}>{renderOtherLanguagesTag(otherLanguagesName)}</Show>

          <Show when={!allTranslatorsAccepted}>
            {renderInviteNotAcceptedWarning(inviteNotAcceptedTranslatorsEmailList, translatorsEmailListCount)}
          </Show>
        </div>
        <div className="flex w-full flex-row justify-between">
          <Show
            when={documentStatus === SourceDocumentDtoStatus.INDEXED}
            fallback={
              <Text size="sm" weight="medium">
                {tDocumentList('importing')}
              </Text>
            }
          >
            <StatusTag value={status} />
            <Tooltip.Root>
              <Tooltip.Trigger className="w-32 cursor-default">
                <Progress percent={completedPercentage} successPercent={successPercent} className="w-32" />
              </Tooltip.Trigger>
              <Tooltip.Content className="max-w-xxs" side="bottom">
                <Text size="sm" weight="medium" className="text-typography-inverse-secondary">
                  <Trans
                    i18nKey="documentList:confirmed"
                    values={{
                      value: confirmed
                    }}
                    components={{ 1: <span className="text-typography-inverse-primary"></span> }}
                  />
                </Text>
                <Text size="sm" weight="medium" className="text-typography-inverse-secondary">
                  <Trans
                    i18nKey="documentList:draft"
                    values={{
                      value: draftSegments
                    }}
                    components={{ 1: <span className="text-typography-inverse-primary"></span> }}
                  />
                </Text>
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Root>
          </Show>
        </div>
      </div>
    );
  };

  return (
    <Show when={isNotExpanded} fallback={renderExpandedFirstLanguage()}>
      <div className="flex flex-row items-center gap-2">
        <FlagIcon label={firstDocumentLanguageName} code={flagCode} size="md" />
        <OverflowTooltip label={firstDocumentLanguageName}>
          <Text size="sm" weight="medium" color="secondary" className="whitespace-nowrap">
            {firstDocumentLanguageName}
          </Text>
        </OverflowTooltip>
        <Show when={length > 1}>{renderOtherLanguagesTag(otherLanguagesName)}</Show>
        <Show when={isNotEmpty(inviteNotAcceptedTranslatorsEmailList)}>
          {renderInviteNotAcceptedWarning(inviteNotAcceptedTranslatorsEmailList, translatorsEmailListCount)}
        </Show>
      </div>
    </Show>
  );
};

interface StatusProps {
  value: TranslationStatus;
}

export const StatusTag = ({ value }: StatusProps) => {
  const { t: tDocument } = useTranslation('document');

  const statuses = {
    // confirmed
    [TranslationStatus.CONFIRMED]: { color: 'positive', label: tDocument('confirmed') },
    // in progress
    [TranslationStatus.CREATED]: { color: 'primary', label: tDocument('inProgress') },
    // downloaded
    [TranslationStatus.GENERATED]: { color: 'neutral', label: tDocument('downloaded') },
    // pending
    [TranslationStatus.GENERATING]: { color: 'warning', label: tDocument('pending') }
  } as const;

  return <Tag color={statuses[value].color}>{statuses[value].label}</Tag>;
};
