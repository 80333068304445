import classNames from 'classnames';

import { Show } from '@/components/v2/Show';
import { roundPercentage } from '@/utils/percentage';

import type { IProgressProps } from './types';

export const Progress = (props: IProgressProps) => {
  const { percent = 0, successPercent = 0, showPercent = true, showRounded = true, status, className } = props;
  const maxPercent = Math.max(percent, successPercent);
  const currentPercent = successPercent || percent;
  const currentStatus = status || (currentPercent >= 100 && successPercent >= 100 ? 'success' : 'active');

  return (
    <div className={classNames('flex items-center space-x-2', className)}>
      <div
        className="relative flex h-2 flex-1 items-center justify-center overflow-hidden rounded-full bg-gray-300"
        role="progressbar"
      >
        <div
          className={classNames('absolute bottom-0 left-0 top-0 rounded-full', {
            'bg-yellow-500': currentStatus === 'active',
            'bg-green-500': currentStatus === 'success',
            'bg-red-500': currentStatus === 'exception'
          })}
          style={{ width: `${percent}%` }}
        />

        <div
          className="absolute bottom-0 left-0  top-0 rounded-full bg-green-500"
          style={{ width: `${successPercent}%` }}
        />
      </div>

      <Show when={showPercent}>
        <div className="w-10 text-end text-sm font-bold text-gray-500">
          {showRounded ? roundPercentage(maxPercent) : maxPercent}%
        </div>
      </Show>
    </div>
  );
};
