import { classNames } from '@/utils/classNames';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
}

export const Box = ({ children, className }: BoxProps) => {
  return <div className={classNames('space-y-5 rounded-xl border bg-white p-7', className)}>{children}</div>;
};

Box.displayName = 'Box';
