export enum EKeyboardEventKey {
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  C = 'KeyC',
  N = 'KeyN',
  TAB = 'Tab',
  DIGIT_REGEX = '^Digit([1-9])$'
}
