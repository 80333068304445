import { pusherChannelsAvailable, PusherEvent } from '@/providers/PusherProvider/constants';
import { usePusherBindToEvent, usePusherSubscribeToChannel } from '@/providers/PusherProvider/hooks';

export const usePusher = <ReturnType>({ channel, event, callback }: UsePusherProps<ReturnType>) => {
  const { channel: pusherChannel } = usePusherSubscribeToChannel(channel);

  usePusherBindToEvent(pusherChannel, event, callback);
};

type PusherChannel = ReturnType<(typeof pusherChannelsAvailable)[keyof typeof pusherChannelsAvailable]>;

type UsePusherProps<ReturnType> = {
  channel: PusherChannel;
  event: PusherEvent;
  callback: (value: ReturnType) => void;
};
