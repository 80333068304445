import { Root, Trigger, Content } from '@radix-ui/react-collapsible';
import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';

import { classNames } from '@/utils/classNames';
import { Text } from '@/components/v2/Text';
import {
  CollapsibleInlineMessageContentProps,
  CollapsibleInlineMessageProps,
  CollapsibleInlineMessageTitleProps
} from '@/components/v2/CollapsibleInlineMessage/types';

export const CollapsibleInlineMessage = forwardRef<HTMLDivElement, CollapsibleInlineMessageProps>((props, ref) => {
  const { className, children, ...rest } = props;

  const [open, setOpen] = useState(false);

  return (
    <Root
      ref={ref}
      className={classNames(
        'flex flex-col rounded-lg border border-primary-active bg-surface-accent text-blue-500',
        className
      )}
      open={open}
      onOpenChange={setOpen}
      {...rest}
    >
      {children}
    </Root>
  );
});
CollapsibleInlineMessage.displayName = 'CollapsibleInlineMessage';

export const CollapsibleInlineMessageHeader = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  (props, ref) => {
    const { className, children, ...rest } = props;
    return (
      <div ref={ref} className={classNames('flex flex-row items-center gap-4 p-3', className)} {...rest}>
        {children}
      </div>
    );
  }
);
CollapsibleInlineMessageHeader.displayName = 'CollapsibleInlineMessageHeader';

export const CollapsibleInlineMessageTitle = forwardRef<HTMLDivElement, CollapsibleInlineMessageTitleProps>(
  (props, ref) => {
    const { children, className, weight = 'bold', size = 'md', ...rest } = props;
    return (
      <Text ref={ref} weight={weight} size={size} className={classNames('text-blue-800', className)} {...rest}>
        {children}
      </Text>
    );
  }
);
CollapsibleInlineMessageTitle.displayName = 'CollapsibleInlineMessageTitle';

export const CollapsibleInlineMessageTrigger = Trigger;

export const CollapsibleInlineMessageContent = forwardRef<HTMLDivElement, CollapsibleInlineMessageContentProps>(
  (props, ref) => {
    const { children, className, ...rest } = props;
    return (
      <Content
        ref={ref}
        className={classNames('flex flex-col gap-5 data-state-open:px-4 data-state-open:py-3', className)}
        {...rest}
      >
        {children}
      </Content>
    );
  }
);
CollapsibleInlineMessageContent.displayName = 'CollapsibleInlineMessageContent';
