import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Tabs from '@/components/v2/Tabs';
import { Tag } from '@/components/v2/Tag';
import { BillingFrequencyEnum, CurrencyEnum } from '@/redux/api/types';

type PlansOptionsProps = {
  frequencyState: {
    frequency: BillingFrequencyEnum;
    setFrequency: React.Dispatch<React.SetStateAction<BillingFrequencyEnum>>;
  };
  currencyState: {
    currency: CurrencyEnum;
    setCurrency: React.Dispatch<React.SetStateAction<CurrencyEnum>>;
  };
};
export const PlansOptions = ({
  frequencyState: { frequency, setFrequency },
  currencyState: { currency, setCurrency }
}: PlansOptionsProps) => {
  const { t: tPlans } = useTranslation('plans');

  const currencySwitch = (currency: CurrencyEnum, setCurrency: React.Dispatch<React.SetStateAction<CurrencyEnum>>) => {
    if (currency === CurrencyEnum.EUR) {
      return (
        <Button
          onClick={() => {
            setCurrency(CurrencyEnum.USD);
          }}
          color="secondary"
        >
          {tPlans('switchToUSDollars')}
        </Button>
      );
    } else if (currency === CurrencyEnum.USD) {
      return (
        <Button
          onClick={() => {
            setCurrency(CurrencyEnum.EUR);
          }}
          color="secondary"
        >
          {tPlans('switchToEuros')}
        </Button>
      );
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3">
        <Tabs.Root
          value={frequency}
          onValueChange={(value) => {
            setFrequency(value as BillingFrequencyEnum);
          }}
        >
          <Tabs.List>
            <Tabs.Trigger value={BillingFrequencyEnum.YEARLY}>{tPlans('payAnnually')}</Tabs.Trigger>
            <Tabs.Trigger value={BillingFrequencyEnum.MONTHLY}>{tPlans('payMonthly')}</Tabs.Trigger>
          </Tabs.List>
        </Tabs.Root>
        <Tag color="positive">{tPlans('getTwoMonthsFreeWithAnAnnualSubscription')}</Tag>
      </div>
      <div>{currencySwitch(currency, setCurrency)}</div>
    </div>
  );
};
