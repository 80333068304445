import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { CurrentAccountDto } from '@/redux/api/types';

import { ConfirmDeleteTeamModal } from './ConfirmDeleteTeamModal';
import { ConfirmManagerLeaveTeamModal } from './ConfirmManagerLeaveTeamModal';
import { EditTeamModal } from './EditTeamModal';

type Props = {
  currentAccount: CurrentAccountDto;
};

export const TeamDetailsActions = ({ currentAccount }: Props) => {
  const { t: tSettings } = useTranslation('settings');

  const [editTeamModalIsOpen, setEditTeamModalIsOpen] = useState(false);
  const [deleteTeamModalIsOpen, setDeleteTeamModalIsOpen] = useState(false);
  const [leaveTeamModalIsOpen, setLeaveTeamModalIsOpen] = useState(false);

  if (currentAccount.subscriptionInfo.teamOwnerAccountId === currentAccount.id) {
    /* Delete team */
    return (
      <div className="col-span-2 flex justify-end space-x-2">
        <ConfirmDeleteTeamModal
          open={deleteTeamModalIsOpen}
          onOpenChange={setDeleteTeamModalIsOpen}
          teamName={currentAccount.subscriptionInfo.teamName}
        >
          <Button variant="soft" color="negative">
            {tSettings('delete')}
          </Button>
        </ConfirmDeleteTeamModal>
        <EditTeamModal open={editTeamModalIsOpen} onOpenChange={setEditTeamModalIsOpen} currentAccount={currentAccount}>
          <Button variant="soft" color="primary">
            <AccessibleIcon label={tSettings('editTeam')} icon="ri-edit-line" />
            {tSettings('editTeam')}
          </Button>
        </EditTeamModal>
      </div>
    );
  } else {
    /* Leave team */
    return (
      <div className="col-span-2 flex justify-end">
        <ConfirmManagerLeaveTeamModal
          open={leaveTeamModalIsOpen}
          onOpenChange={setLeaveTeamModalIsOpen}
          currentAccount={currentAccount}
        >
          <Button variant="soft" color="negative">
            {tSettings('leaveTeam')}
          </Button>
        </ConfirmManagerLeaveTeamModal>
      </div>
    );
  }
};
