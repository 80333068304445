import { redokunApi } from './redokunApi';
import type { OkResponseDto, UpdateJobInvitationStatusArgs, UpdateTeamInvitationStatusArgs } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const invitationApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    updateJobInvitationStatus: builder.mutation<OkResponseDto, UpdateJobInvitationStatusArgs>({
      query: ({ translationJobId, status }) => ({
        url: `/invitation/job/${translationJobId}`,
        method: 'POST',
        body: {
          status
        }
      }),
      invalidatesTags: ['currentAccount', 'documentList']
    }),
    updateTeamInvitationStatus: builder.mutation<OkResponseDto, UpdateTeamInvitationStatusArgs>({
      query: ({ teamMembershipId, action }) => ({
        url: `/invitation/team/${teamMembershipId}`,
        method: 'POST',
        body: {
          action
        }
      }),
      invalidatesTags: ['currentAccount', 'documentList']
    })
  })
});

export const { useUpdateJobInvitationStatusMutation, useUpdateTeamInvitationStatusMutation } = invitationApi;

export const invitationApiUtil = {
  ...invitationApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(invitationApi.util.updateQueryData)
};
