import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { Breadcrumb } from '@/components/v1/Breadcrumb';
import { Button, ButtonLink } from '@/components/v1/Button';
import { APP_URL } from '@/config/urls';
import { useOffline } from '@/hooks/useOffline';
import { ArrowLeftIcon, BoltIcon } from '@/icons/solid';
import { EBlockedReason } from '@/redux/api/constants';
import { useAppDispatch } from '@/redux/hooks';
import {
  openAskUnlockTranslationModal,
  openConfirmTranslationModal,
  openUnlockTranslationModal
} from '@/redux/slices/modalsSlice';

import type { IDocumentLanguageHeaderProps } from './types';

export const DocumentLanguageHeader: FunctionComponent<IDocumentLanguageHeaderProps> = ({
  documentId,
  languageId,
  hasWriteAccess,
  isBlocked,
  blockedReason,
  documentName,
  languageName,
  languageFlagUrl
}) => {
  const { t } = useTranslation('documentLanguageHeader');
  const dispatch = useAppDispatch();
  const offline = useOffline();
  const blockedOrOffline = isBlocked || offline;

  const confirmed = blockedReason === EBlockedReason.CONFIRMED;
  const expiredSubscription = blockedReason === EBlockedReason.EXPIRED_SUBSCRIPTION;

  return (
    <div className="space-y-6 py-2">
      {confirmed &&
        (hasWriteAccess ? (
          <Alert size="lg" message={t('documentIsLockedClickMakeEditable')} />
        ) : (
          <Alert
            type="warning"
            size="lg"
            message={
              <Trans
                i18nKey="documentLanguageHeader:translationCannotBeEdited"
                components={{ 1: <span className="font-bold" /> }}
              />
            }
          />
        ))}

      {expiredSubscription &&
        (hasWriteAccess ? (
          <Alert
            size="lg"
            message={
              <div className="flex items-center justify-between">
                <div>{t('yourSubscriptionHasExpired')}</div>
                <ButtonLink
                  href={`${APP_URL}/settings/plans`}
                  target="_blank"
                  rel="noreferrer"
                  containerClassName="flex-shrink-0"
                  color="green"
                >
                  {t('renewSubscription')}
                </ButtonLink>
              </div>
            }
          />
        ) : (
          <Alert type="warning" size="lg" message={t('documentOwnerSubscriptionHasExpired')} />
        ))}

      <div className="flex items-center justify-between space-x-4">
        <div className="flex items-center space-x-2">
          <ButtonLink href={`${APP_URL}/documents/show/${documentId}`} btnType="outline" icon={<ArrowLeftIcon />}>
            {t('back')}
          </ButtonLink>

          <Breadcrumb>
            <a href={`${APP_URL}/documents/show/${documentId}`} className="hover:text-gray-900 hover:underline">
              {documentName}
            </a>

            <span className="flex items-center space-x-1">
              {languageFlagUrl && <img src={languageFlagUrl} alt={languageName} className="h-4" />}
              <span>{languageName}</span>
            </span>
          </Breadcrumb>
        </div>

        {!expiredSubscription && (
          <div className="flex-none space-x-2">
            <ButtonLink
              href={`${APP_URL}/documents/pre-translate/${documentId}`}
              target="_blank"
              rel="noreferrer"
              btnType="outline"
              icon={<BoltIcon />}
              disabled={blockedOrOffline}
            >
              {t('preTranslate')}
            </ButtonLink>

            {!isBlocked && (
              <Button
                disabled={offline}
                onClick={() => {
                  dispatch(openConfirmTranslationModal({ documentId, languageId }));
                }}
              >
                {t('confirmTranslation')}
              </Button>
            )}
            {isBlocked && hasWriteAccess && (
              <Button
                disabled={offline}
                color="green"
                onClick={() => {
                  dispatch(openUnlockTranslationModal({ documentId, languageId }));
                }}
              >
                {t('makeEditable')}
              </Button>
            )}
            {isBlocked && !hasWriteAccess && (
              <Button
                disabled={offline}
                color="green"
                onClick={() => {
                  dispatch(openAskUnlockTranslationModal({ documentId, languageId }));
                }}
              >
                {t('askUnlockTranslation')}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
