import { useTranslation } from 'react-i18next';

import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import { BillingFrequencyEnum } from '@/redux/api/types';

type PricingTableHeaderProps = {
  onFreeTrial: boolean;
  price: string;
  frequency: BillingFrequencyEnum;
  planName: string;
};

export const PricingTableHeader = ({ onFreeTrial, price, frequency, planName }: PricingTableHeaderProps) => {
  const { t: tPlans } = useTranslation('plans');

  const titleSection = (
    <div className="flex space-x-2">
      <Text color="secondary" weight="bold">
        {planName}
      </Text>
      {onFreeTrial && <Tag color="primary">{tPlans('currentlyOnFreeTrial')}</Tag>}
    </div>
  );

  const priceSection = (
    <div className="flex items-center space-x-2">
      <Text size="lg" color="primary" weight="extrabold">
        {price}
      </Text>
      <Text color="secondary" className="lowercase">
        /{frequency === BillingFrequencyEnum.YEARLY ? tPlans('year') : tPlans('month')}
      </Text>
    </div>
  );

  return (
    <div className="space-y-2">
      {titleSection}
      {priceSection}
    </div>
  );
};
