import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Divider } from '@/components/v1/Divider';
import { TrashIcon } from '@/icons/solid';

import type { ICommentActionsProps } from './types';

export const CommentActions: FunctionComponent<ICommentActionsProps> = ({ onDeleteClick }) => {
  const { t } = useTranslation('documentLanguageComments');

  return (
    <div>
      <Divider>{t('actions')}</Divider>

      <div className="px-4 pb-1 pt-2">
        <Button btnType="text" icon={<TrashIcon />} size="sm" onClick={onDeleteClick}>
          {t('deleteComment')}
        </Button>
      </div>
    </div>
  );
};
