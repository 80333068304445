import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';
import { createContext } from '@/utils/createContext';

import { AccessibleIcon } from '../AccessibleIcon';
import { breadcrumbLink, breadcrumbSeparator } from './styles';
import type {
  BreadcrumbElement,
  BreadcrumbItemElement,
  BreadcrumbItemProps,
  BreadcrumbLinkElement,
  BreadcrumbLinkProps,
  BreadcrumbListElement,
  BreadcrumbListProps,
  BreadcrumbProps,
  BreadcrumbSeparatorElement,
  BreadcrumbSeparatorProps
} from './types';

const [BreadcrumbProvider, useBreadcrumbContext] =
  createContext<Pick<BreadcrumbProps, 'separator' | 'size'>>('Breadcrumb');

const Breadcrumb = forwardRef<BreadcrumbElement, BreadcrumbProps>(
  ({ separator = <AccessibleIcon icon="ri-arrow-right-s-line" label="Separator" />, size, ...props }, ref) => (
    <BreadcrumbProvider separator={separator} size={size}>
      <NavigationMenu.Root {...props} ref={ref} orientation="horizontal" />
    </BreadcrumbProvider>
  )
);
Breadcrumb.displayName = 'Breadcrumb';

const BreadcrumbList = forwardRef<BreadcrumbListElement, BreadcrumbListProps>(
  ({ asChild, className, ...props }, ref) => {
    const Component = asChild ? Slot : 'ol';

    return (
      <NavigationMenu.List asChild>
        <Component {...props} ref={ref} className={classNames('flex flex-wrap items-center', className)} />
      </NavigationMenu.List>
    );
  }
);
BreadcrumbList.displayName = 'BreadcrumbList';

const BreadcrumbItem = forwardRef<BreadcrumbItemElement, BreadcrumbItemProps>(({ className, ...props }, ref) => (
  <NavigationMenu.Item {...props} ref={ref} className={classNames('inline-flex flex-nowrap items-center', className)} />
));
BreadcrumbItem.displayName = 'BreadcrumbItem';

const BreadcrumbLink = forwardRef<BreadcrumbLinkElement, BreadcrumbLinkProps>(({ className, size, ...props }, ref) => {
  const breadcrumb = useBreadcrumbContext('BreadcrumbLink');
  // this uses <a> instead of React Router's Link, this fires a fullpage-reload
  // if you want to use React Router's Link, you can use the `asChild` prop on the BreadcrumbLink
  return (
    <NavigationMenu.Link
      {...props}
      ref={ref}
      className={classNames(breadcrumbLink({ size: size || breadcrumb.size }), className)}
    />
  );
});
BreadcrumbLink.displayName = 'BreadcrumbLink';

const BreadcrumbSeparator = forwardRef<BreadcrumbSeparatorElement, BreadcrumbSeparatorProps>(
  ({ children, className, role = 'presentation', size, ...props }, ref) => {
    const breadcrumb = useBreadcrumbContext('BreadcrumbSeparator');

    return (
      <span
        {...props}
        ref={ref}
        role={role}
        className={classNames(breadcrumbSeparator({ size: size || breadcrumb.size }), className)}
      >
        {children || breadcrumb.separator}
      </span>
    );
  }
);
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';

export { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator };
