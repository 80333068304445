import DataTable from '@/components/v2/DataTable';
import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { useColumns } from '@/routes/GlossaryDetail/columns';

export const GlossaryDetailContentSkeleton = () => {
  const columns = useColumns({ glossaryId: 0 });

  return (
    <div className="flex flex-col gap-7">
      <Surface radius="sm" className="flex flex-row px-7 py-8">
        <div className="flex w-1/2 flex-col gap-1">
          <Skeleton width="30%">
            <Text color="tertiary" size="sm" weight="medium">
              &nbsp;
            </Text>
          </Skeleton>
          <Skeleton width="50%">
            <Text color="secondary" size="xl" weight="bold">
              &nbsp;
            </Text>
          </Skeleton>
        </div>
        <div className="flex w-1/2 flex-col justify-center gap-1">
          <Skeleton width="50%">
            <Text color="tertiary" size="sm" weight="medium">
              &nbsp;
            </Text>
          </Skeleton>
          <Skeleton width="50%">
            <Text color="tertiary" size="sm" weight="medium">
              &nbsp;
            </Text>
          </Skeleton>
        </div>
      </Surface>

      <Surface className="flex flex-col items-center px-7 py-8">
        <DataTable.Root columns={columns} data={5} loading={true}>
          <DataTable.Content>
            <DataTable.Header />
            <DataTable.Body />
          </DataTable.Content>
        </DataTable.Root>
      </Surface>
    </div>
  );
};
