import { LOADING_DATA_TOKEN, LoadingDataToken } from '@/components/v2/DataTable';
import { DocumentListItemType } from '@/redux/api/constants';
import { DocumentListItemDto } from '@/redux/api/types';
import { Row } from '@tanstack/react-table';

export function isFolderItem(item: DocumentListItemDto): item is {
  type: DocumentListItemType.FOLDER;
  item: { id: number; name: string; createdAt: string };
} {
  return item.type === DocumentListItemType.FOLDER;
}

export const getRowId = (originalRow: DocumentListItemDto | LoadingDataToken, index: number) =>
  originalRow === LOADING_DATA_TOKEN ? `index-${index}` : `${originalRow.type}-${originalRow.item.id}`;

export const getRowCanExpand = (row: Row<DocumentListItemDto>) => row.original.type !== DocumentListItemType.FOLDER;

export const enableRowSelection = (row: Row<DocumentListItemDto>) => {
  // Folders are always selectable. This is not always the case, but we will
  // find a better solution in the future (maybe the BE can do a recursive
  // evaluation and pass "hasWriteAccess" also for the folder).
  if (row.original.type === DocumentListItemType.FOLDER) {
    return true;
  }

  // Documents are selectable if the user has write access.
  // We do this because the checkbox is used to select documents for batch
  // operations and all batch operations require write access.
  return row.original?.item?.hasWriteAccess;
};
