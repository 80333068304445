import { useTranslation } from 'react-i18next';
import type { Descendant } from 'slate';

import { Show } from '@/components/v1/Show';
import { editorIsValueChanged, valueToXliff } from '@/slate/utils';
import { composeEventHandlers } from '@/utils/composeEventHandlers';
import { EKeyboardEventKey } from '@/utils/keyboardEvent';

import { TranslationInputCharactersCount } from '../TranslationInputCharactersCount';
import { TranslationInputEditable } from '../TranslationInputEditable';
import { TranslationInputRoot } from '../TranslationInputRoot';
import type { ITranslationInputProps } from '../types';
import { useTranslationEditor } from './useTranslationEditor';
import { getNextPlaceholder, insertPlaceholder } from './utils';

export const TranslationInput = ({
  initialTranslationContent,
  placeholders,
  status,
  icon,
  footer,
  disabled,
  readOnly,
  autoFocus,
  search,
  dir,
  languageCode,
  liveCharacterCount,
  liveCharacterCountLimit,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyUp
}: ITranslationInputProps) => {
  const { t } = useTranslation('documentLanguageTranslation');
  const slate = useTranslationEditor({ initialTranslationContent, placeholders });
  const { editor, initialValue, onChange: onInternalChange } = slate;

  const handleChange = (value: Descendant[]) => {
    const valueChanged = editorIsValueChanged(editor);
    if (valueChanged) {
      const translationContent = valueToXliff(editor.children);
      onChange?.(translationContent);
    }

    onInternalChange(value);
  };

  const handleKeyDown = composeEventHandlers(onKeyDown, (event) => {
    // Japanese and other asian languages use the composer (eg. Microsoft IME – Input Method Editor)
    // We should ignore RETURN, TAB or other inputs when the user is using the composer, as it
    // will create unexpected issues (see HS #994).
    if (event.nativeEvent.isComposing) {
      return;
    }

    if (event.key === EKeyboardEventKey.TAB && !event.shiftKey) {
      // Tab key, insert a placeholder
      event.preventDefault();
      const placeholder = getNextPlaceholder(placeholders, editor.children);
      if (placeholder) {
        insertPlaceholder(editor, placeholder);
      }
    }
  });

  return (
    <TranslationInputRoot editor={editor} initialValue={initialValue} onChange={handleChange} status={status}>
      <div className="flex h-full flex-col">
        <TranslationInputEditable
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          disabled={disabled}
          readOnly={readOnly}
          dir={dir}
          lang={languageCode}
          search={search}
          placeholder={t('clickToAddTranslation')}
          message={footer}
          action={icon}
          onKeyDown={handleKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <Show when={liveCharacterCount}>
          <div className="flex justify-end">
            <TranslationInputCharactersCount max={liveCharacterCountLimit} />
          </div>
        </Show>
      </div>
    </TranslationInputRoot>
  );
};
