import { redokunApi } from './redokunApi';
import type { IChangeTmRecordVisibilityQueryArg, IChangeTmRecordVisibilityResponse } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const tmApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    changeTmRecordVisibility: builder.mutation<IChangeTmRecordVisibilityResponse, IChangeTmRecordVisibilityQueryArg>({
      query: ({ suggestionId, body }) => ({ url: `tm/record/${suggestionId}/visibility`, method: 'POST', body }),
      invalidatesTags: ['tmSuggestions']
    })
  })
});

export const { useChangeTmRecordVisibilityMutation } = tmApi;

export const tmApiUtil = {
  ...tmApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(tmApi.util.updateQueryData)
};
