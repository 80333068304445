import React from 'react';

export function createContext<ContextValue extends object | null>(
  rootComponentName: string,
  defaultContext?: ContextValue
) {
  const Context = React.createContext<ContextValue | undefined>(defaultContext);
  Context.displayName = rootComponentName + 'Context';

  function Provider(props: ContextValue & { children: React.ReactNode }) {
    const { children, ...context } = props;
    // Only re-memoize when prop values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const value = React.useMemo(() => context, Object.values(context)) as ContextValue;
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }
  Provider.displayName = rootComponentName + 'Provider';

  function useContext(consumerName: string) {
    const context = React.useContext(Context);
    if (context) {
      return context;
    }
    if (defaultContext !== undefined) {
      return defaultContext;
    }
    // if a defaultContext wasn't specified, it's a required context.
    throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
  }
  useContext.displayName = 'use' + rootComponentName + 'Context';

  return [Provider, useContext] as const;
}
