import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Table, TableBody, TableCell, TableRow } from '@/components/v2/Table';
import { Text } from '@/components/v2/Text';

const TransactionDetailsSkeleton = () => (
  <Surface className="space-y-7 px-7 py-8">
    <Text weight="bold" color="primary">
      <Skeleton width="50%" />
    </Text>

    <Table>
      <TableBody>
        <TableRow>
          <TableCell weight="regular">
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell weight="regular">
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell weight="regular">
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell weight="regular">
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell weight="regular">
            <Skeleton />
          </TableCell>
          <TableCell className="text-green-400">
            <Skeleton />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Surface>
);
TransactionDetailsSkeleton.displayName = 'TransactionDetailsSkeleton';

export { TransactionDetailsSkeleton };
