import { redokunApi } from './redokunApi';
import type { GetBillingDetailResponseDto, OkResponseDto, UpdateBillingDetailsRequestQueryArgs } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const billingApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getBilling: builder.query<GetBillingDetailResponseDto, void>({
      query: () => `/billing`,
      providesTags: ['billing'],
      keepUnusedDataFor: 60
    }),

    putBilling: builder.mutation<OkResponseDto, UpdateBillingDetailsRequestQueryArgs>({
      query: ({ body }) => ({ url: `/billing`, method: 'PUT', body }),
      invalidatesTags: ['currentAccount', 'billing']
    })
  })
});

export const { useGetBillingQuery, useLazyGetBillingQuery, usePutBillingMutation } = billingApi;

export const billingApiUtil = {
  ...billingApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(billingApi.util.updateQueryData)
};
