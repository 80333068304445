import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { SpinnerCircular } from 'spinners-react';

import { isNonNullable } from '@/utils/isNonNullable';

import type { ISpinnerProps } from './types';
import { getSpinnerSize } from './utils';

export const Spinner: FunctionComponent<ISpinnerProps> & { Fullscreen: FunctionComponent<ISpinnerProps> } = ({
  size = 'md',
  enabled,
  className,
  containerClassName,
  children
}) => {
  const hasChildren = isNonNullable(children);

  const spinner = (
    <SpinnerCircular
      className={className}
      size={getSpinnerSize(size)}
      enabled={enabled}
      thickness={150}
      speed={150}
      color="rgb(107, 114, 128)" // gray-500
      secondaryColor="rgb(229, 231, 235)" // gray-200
    />
  );

  return hasChildren ? (
    <div className={classNames('relative', containerClassName)}>
      {enabled && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-25">{spinner}</div>
      )}

      <div className={classNames('h-full w-full', enabled && 'pointer-events-none select-none opacity-50')}>
        {children}
      </div>
    </div>
  ) : (
    spinner
  );
};

Spinner.Fullscreen = (props) => (
  <div className="flex h-screen w-screen items-center justify-center">
    <Spinner {...props} />
  </div>
);
