import { useTranslation } from 'react-i18next';

import AppLayout from '@/components/layouts/AppLayout';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Navbar } from '@/components/v2/Navbar';
import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';

export const GlossaryUpdateEntrySkeleton = () => {
  const { t: tLayout } = useTranslation('layout');

  return (
    <AppLayout.Root>
      <Navbar sticky>
        <ButtonLink href={`/glossaries`} variant="surface" color="secondary" className="ml-auto" disabled>
          {tLayout('close')}
        </ButtonLink>
      </Navbar>
      <AppLayout.Content>
        <div className="flex flex-col gap-7">
          <Surface className="flex flex-col gap-8 px-7 py-8">
            <Skeleton width="20%">
              <Text size="lg" weight="bold" color="primary">
                &nbsp;
              </Text>
            </Skeleton>

            <div className="flex w-full flex-col gap-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <Skeleton width="25%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
              <Skeleton width="100%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
            </div>

            <div className="flex w-full flex-col gap-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <Skeleton width="25%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
              <Skeleton width="100%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
            </div>

            <div className="flex w-full flex-col gap-3 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <Skeleton width="25%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
              <Skeleton width="100%">
                <Text size="lg" weight="bold" color="primary">
                  &nbsp;
                </Text>
              </Skeleton>
            </div>
          </Surface>
          <Skeleton width="30%" className="self-end">
            <Button variant="solid" color="primary">
              &nbsp;
            </Button>
          </Skeleton>
        </div>
      </AppLayout.Content>
    </AppLayout.Root>
  );
};
