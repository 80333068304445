export const ICONS = {
  loading: 'ri-loader-fill',
  informational: 'ri-question-fill',
  success: 'ri-checkbox-circle-fill',
  warning: 'ri-information-fill',
  error: 'ri-alert-fill'
} as const;

export const ICONS_COLOR = {
  loading: 'text-blue-500',
  informational: 'text-blue-500',
  success: 'text-green-500',
  warning: 'text-yellow-500',
  error: 'text-red-500'
} as const;

export const TOAST_LIMIT = 3;
export const TOAST_REMOVE_DELAY = 2500;
