const XLIFF_ESCAPE_MAP: Record<string, string> = {
  '"': '&quot;',
  "'": '&apos;',
  '<': '&lt;',
  '>': '&gt;',
  '&': '&amp;'
};

export const escapeXliff = (text: string) =>
  text.replace(/["'<>&]/g, (character) => XLIFF_ESCAPE_MAP[character] ?? character);
