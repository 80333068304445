import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import DataTable from '@/components/v2/DataTable';
import { RequireSubscription } from '@/components/v2/RequireSubscription';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { HELP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { useGetGlossaryQuery } from '@/redux/api/glossaryApi';
import { useColumns } from '@/routes/Glossary/components/GlossaryList/columns';
import { DataTableLayout } from '@/routes/Glossary/components/GlossaryList/components/DataTableLayout';
import { SkeletonGlossaryList } from '@/routes/Glossary/components/GlossaryList/components/SkeletonGlossaryList';
import { isNotEmpty } from '@/utils/isNotEmpty';
import { RequireUpgrade } from '@/components/v2/RequireUpgrade';

export const GlossaryList: React.FC = () => {
  const { data, isLoading, isFetching, isUninitialized } = useGetGlossaryQuery();
  const { t: tGlossary } = useTranslation('glossary');

  const columns = useColumns();
  const { currentAccount } = useCurrentAccount();

  const showGlossaries = isNotEmpty(data?.glossaries);
  const canCreateGlossary = !currentAccount?.subscriptionInfo.isTranslator;
  const showEmptySet = isEmpty(data?.glossaries) && !isLoading;

  if (isUninitialized || isLoading) {
    return <SkeletonGlossaryList columns={columns} />;
  }

  return (
    <div className="flex flex-col gap-6">
      {/* glossary title */}
      <div className="flex flex-row justify-between">
        <Title size="lg">{tGlossary('glossary')}</Title>
        <Show when={showGlossaries}>
          <div className="flex flex-row items-center gap-4">
            <Button variant="text" color="primary" asChild>
              <a href={`${HELP_URL}/article/126-glossary-what-is-it-how-does-it-work`} target="_blank" rel="noreferrer">
                <AccessibleIcon icon="ri-lightbulb-fill" label="light-bulb" />
                {tGlossary('readMoreAboutHowTheGlossaryWorks')}
              </a>
            </Button>
            <Show when={canCreateGlossary}>
              <RequireSubscription>
                <RequireUpgrade feature="glossary">
                  <ButtonLink href={'/glossaries/new'} variant="solid" color="primary" size="md">
                    <AccessibleIcon label="ri-menu-add-fill" icon="ri-menu-add-fill" />
                    {tGlossary('createGlossary')}
                  </ButtonLink>
                </RequireUpgrade>
              </RequireSubscription>
            </Show>
          </div>
        </Show>
      </div>

      {/* create your first glossary empty set */}
      <Show when={showEmptySet}>
        <div className="flex flex-col items-center justify-center gap-10 px-7 py-8">
          <div className="flex flex-col justify-center gap-2 text-center">
            <AccessibleIcon label="ri-translate-2" icon="ri-translate-2" className="text-5xl text-alpha-400" />
            <Text color="tertiary" weight="bold" size="md">
              {tGlossary('createYourFirstGlossary')}
            </Text>
            <Text color="tertiary" weight="regular">
              {tGlossary('startCreatingYourFirstGlossary')}
            </Text>
          </div>
          <div className="flex flex-col items-center gap-6">
            <RequireSubscription>
              <RequireUpgrade feature="glossary">
                <ButtonLink
                  href={'/glossaries/new'}
                  variant="solid"
                  color="primary"
                  size="lg"
                  className="max-w-[250px]"
                >
                  <AccessibleIcon label="ri-menu-add-fill" icon="ri-menu-add-fill" />
                  {tGlossary('createGlossary')}
                </ButtonLink>
              </RequireUpgrade>
            </RequireSubscription>

            <ButtonLink
              href={`${HELP_URL}/article/126-glossary-what-is-it-how-does-it-work`}
              target="_blank"
              rel="noreferrer"
              variant="text"
              color="primary"
              className="mt-7"
            >
              <AccessibleIcon icon="ri-lightbulb-fill" label="light-bulb" />
              {tGlossary('readMoreAboutHowTheGlossaryWorks')}
            </ButtonLink>
          </div>
        </div>
      </Show>

      {/* glossary table */}
      <Show when={showGlossaries}>
        <DataTableLayout>
          <DataTable.Root columns={columns} data={data?.glossaries} loading={isFetching}>
            <DataTable.Content>
              <DataTable.Header />
              <DataTable.Body />
            </DataTable.Content>
          </DataTable.Root>
        </DataTableLayout>
      </Show>
    </div>
  );
};
