import { useTranslation } from 'react-i18next';

import { Show } from '@/components/v1/Show';
import { ExclamationCircleIcon } from '@/icons/solid';

import { useConnectionState } from './hooks';
import type { ConnectionStateProps } from './types';

export const ConnectionState = ({ children }: ConnectionStateProps) => {
  const { t } = useTranslation('documentLanguage');
  const { online, restore } = useConnectionState();

  return (
    <Show
      when={online}
      fallback={
        <div className="rounded bg-gray-800 p-4 font-bold text-white shadow-lg">
          <ExclamationCircleIcon size="lg" /> {t('youAreOfflineTryingToConnect')}
        </div>
      }
    >
      <Show
        when={!restore}
        fallback={
          <div className="rounded bg-gray-800 p-4 font-bold text-white shadow-lg">
            <ExclamationCircleIcon size="lg" /> {t('connectionRestored')}
          </div>
        }
      >
        {children}
      </Show>
    </Show>
  );
};
