import { cva } from 'cva';

const alert = cva('flex items-center justify-center gap-3 px-6 py-4', {
  variants: {
    status: {
      warning: 'bg-yellow-50',
      error: 'bg-red-50'
    }
  },
  defaultVariants: {
    status: 'warning'
  }
});

const alertIcon = cva('text-[1.75rem] leading-none', {
  variants: {
    status: {
      warning: 'text-yellow-500',
      error: 'text-red-500'
    }
  },
  defaultVariants: {
    status: 'warning'
  }
});

export { alert, alertIcon };
