import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';

const PaymentMethodFormSkeleton = () => (
  <Surface border className="space-y-7 p-7">
    <Text weight="bold" color="primary">
      <Skeleton width="50%" />
    </Text>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Input size="sm" />
      </Skeleton>
    </div>

    <div className="flex flex-col gap-3">
      <Label text={<Skeleton width="50%" />} size="sm" />
      <Skeleton width="100%">
        <Input size="sm" />
      </Skeleton>
    </div>

    <div className="grid grid-cols-2 gap-3">
      <div className="flex flex-col gap-3">
        <Label text={<Skeleton width="50%" />} size="sm" />
        <Skeleton width="100%">
          <Input size="sm" />
        </Skeleton>
      </div>

      <div className="flex flex-col gap-3">
        <Label text={<Skeleton width="50%" />} size="sm" />
        <Skeleton width="100%">
          <Input size="sm" />
        </Skeleton>
      </div>
    </div>
  </Surface>
);
PaymentMethodFormSkeleton.displayName = 'PaymentMethodFormSkeleton';

export { PaymentMethodFormSkeleton };
