import { CardCvcElement as StripeCardCvcElement } from '@stripe/react-stripe-js';
import { ComponentPropsWithoutRef } from 'react';

import { defaultClasses, defaultStyle } from '../styles';

const CardCvcElement = (props: CardCvcElementProps) => {
  const { options: optionsProp, ...cardCvcElementProps } = props;
  const options = {
    ...optionsProp,
    style: { ...defaultStyle, ...optionsProp?.style },
    classes: { ...defaultClasses, ...optionsProp?.classes }
  };

  return <StripeCardCvcElement options={options} {...cardCvcElementProps} />;
};
CardCvcElement.displayName = 'CardCvcElement';

type CardCvcElementProps = ComponentPropsWithoutRef<typeof StripeCardCvcElement>;

export { CardCvcElement };
export type { CardCvcElementProps };
