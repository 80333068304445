import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Breadcrumb from '@/components/v2/Breadcrumb';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { FolderHierarchyDto, SourceDocumentDto } from '@/redux/api/types';
import { getPathToFolder } from '@/utils/folder';

interface SourceDocumentHeaderBreadcrumbProps {
  folders: FolderHierarchyDto;
  sourceDocument: SourceDocumentDto;
}
const SourceDocumentHeaderBreadcrumb = (props: SourceDocumentHeaderBreadcrumbProps) => {
  const { folders, sourceDocument } = props;
  const { filename, id, folderId } = sourceDocument;

  const { t: tDocument } = useTranslation('document');

  const pathToFolder = useMemo(() => getPathToFolder(folderId, folders.children), [folderId, folders.children]);

  const breadcrumbPath = [{ id: 0, name: tDocument('documents') }, ...pathToFolder];
  const breadcrumbsGenerator = breadcrumbPath.map((folder) => {
    const addIfSubFolder = folder.id ? `folder/${folder.id}` : ``;

    return (
      <Breadcrumb.Item key={folder.id} className="max-w-[124px]">
        <OverflowTooltip label={folder.name}>
          <Breadcrumb.Link asChild>
            <Link to={`/documents/${addIfSubFolder}`} className="whitespace-nowrap">
              {folder.name}
            </Link>
          </Breadcrumb.Link>
        </OverflowTooltip>
        <Breadcrumb.Separator />
      </Breadcrumb.Item>
    );
  });

  return (
    <Breadcrumb.Root size="sm">
      <Breadcrumb.List>
        {breadcrumbsGenerator}
        <Breadcrumb.Item className="overflow-hidden break-words">
          <OverflowTooltip label={filename}>
            <Breadcrumb.Link asChild active>
              <Link to={`/documents/show/${id}`} className="max-w-xs whitespace-nowrap">
                {filename}
              </Link>
            </Breadcrumb.Link>
          </OverflowTooltip>
        </Breadcrumb.Item>
      </Breadcrumb.List>
    </Breadcrumb.Root>
  );
};
SourceDocumentHeaderBreadcrumb.displayName = 'SourceDocumentHeaderBreadcrumb';

export { SourceDocumentHeaderBreadcrumb };
export type { SourceDocumentHeaderBreadcrumbProps };
