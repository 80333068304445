import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/components/v2/Toast/useToast';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import {
  PrivateDocumentTranslatedStatusUpdatedPayload,
  pusherChannelsAvailable,
  PusherEvent,
  usePusherContext
} from '@/providers/PusherProvider';
import { documentApiUtil, useGenerateDocumentTranslationMutation, useGetDocumentDetailQuery } from '@/redux/api';
import { TranslationStatus } from '@/redux/api/constants';
import { useAppDispatch } from '@/redux/hooks';
import { download } from '@/utils/download';

/**
 * This hook need to be used together with useDownloadEvents to handle the download flow.
 * This hook is designed to be used at the row level.
 */
export const useDownload = (documentId: number, languageId: number) => {
  const [generateTranslation] = useGenerateDocumentTranslationMutation();
  const { data: documentDetail } = useGetDocumentDetailQuery(documentId);
  const { refetch } = useCurrentAccount();
  const { t: tDocument } = useTranslation('document');

  return async () => {
    const status = documentDetail?.translations.find((t) => t.language.id === languageId)?.status;

    // this refetch is needed to update onboarding flags
    refetch();

    switch (status) {
      // generate the file if it's not generated yet
      case TranslationStatus.CONFIRMED: {
        await generateTranslation({ documentId, languageId });
        break;
      }

      // if file is already generated and the user wants to download file again
      case TranslationStatus.GENERATED: {
        download({
          href: `${APP_URL}/api/v1/document/${documentId}/translation/${languageId}/download`,
          download: documentDetail?.sourceDocument.filename ?? ''
        });
        toast({ title: tDocument('yourTranslationWasSuccessfullyDownloaded'), kind: 'success' });
        break;
      }
    }
  };
};

/**
 * This hook need to be used together with useDownload to handle the download flow.
 * This hook is designed to be used at the page level.
 */
export const useDownloadEvents = (documentId: number) => {
  const { data: documentDetail } = useGetDocumentDetailQuery(documentId);
  const { pusher } = usePusherContext();
  const { refetch } = useCurrentAccount();
  const { t: tDocument } = useTranslation('document');
  const dispatch = useAppDispatch();

  useEffect(() => {
    const filename = documentDetail?.sourceDocument.filename;
    const translations = documentDetail?.translations;
    if (!filename || !translations?.length) {
      return;
    }

    // for each translation subscribe and bind a channel
    const channelUnbinds = translations.map((translation) => {
      const languageId = translation.language.id;

      const channelName = pusherChannelsAvailable.privateDocumentTranslated(documentId, languageId);
      const channel = pusher.subscribe(channelName);

      const handleStatusUpdated = (payload: PrivateDocumentTranslatedStatusUpdatedPayload) => {
        // invalidate documentDetail to update translation status
        dispatch(documentApiUtil.invalidateTags(['documentDetail']));

        // if status is generated, then download the document and show a toast
        if (payload.newStatus === TranslationStatus.GENERATED) {
          download({
            href: `${APP_URL}/api/v1/document/${documentId}/translation/${languageId}/download`,
            download: filename
          });
          toast({ title: tDocument('yourTranslationWasSuccessfullyDownloaded'), kind: 'success' });
        }

        // update onboarding flags
        refetch();
      };

      channel.bind(PusherEvent.STATUS_UPDATED, handleStatusUpdated);
      return () => {
        channel.unbind(PusherEvent.STATUS_UPDATED, handleStatusUpdated);
      };
    });

    return () => {
      // cleanup
      channelUnbinds.forEach((channelUnbind) => {
        channelUnbind();
      });
    };
  }, [
    dispatch,
    documentDetail?.sourceDocument.filename,
    documentDetail?.translations,
    documentId,
    pusher,
    refetch,
    tDocument
  ]);
};
