import { createContext, FunctionComponent, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { closeModal, destroyModal, openModal } from '@/redux/slices/modalsSlice';
import { EModalType, ModalVersion } from '@/redux/slices/modalsSlice/constants';
import { selectModals } from '@/redux/slices/modalsSlice/selectors';
import type { TOpenModalPayload } from '@/redux/slices/modalsSlice/types';

import { MODAL_COMPONENTS } from './constants';
import type { IModalsContext } from './types';

export const ModalsContext = createContext<IModalsContext>({} as IModalsContext);

export const ModalsProvider: FunctionComponent = ({ children }) => {
  const modals = useAppSelector(selectModals);
  const dispatch = useAppDispatch();

  const openModalCallback = useCallback(
    (payload: TOpenModalPayload) => {
      dispatch(openModal(payload));
    },
    [dispatch]
  );

  const closeModalCallback = useCallback(
    (modalType: EModalType) => {
      dispatch(closeModal(modalType));
    },
    [dispatch]
  );

  return (
    <ModalsContext.Provider value={{ openModal: openModalCallback, closeModal: closeModalCallback }}>
      {children}

      {Object.entries(modals).map(([key, modalState]) => {
        if (!Object.values(EModalType).includes(key as EModalType) || !modalState) {
          return null;
        }

        const type = key as EModalType;
        const { open, props, version } = modalState;

        const Modal = MODAL_COMPONENTS[type];

        switch (version) {
          case ModalVersion.V2: {
            // moved this const inside case due to type inference issues
            const CustomModal = MODAL_COMPONENTS[type];

            return (
              <CustomModal
                key={key}
                open={open}
                onOpenChange={(open) => {
                  if (!open) {
                    dispatch(closeModal(type));
                    dispatch(destroyModal(type));
                  }
                }}
                {...props}
              />
            );
          }
          default: {
            return (
              // @ts-expect-error: TS 5 migration error
              <Modal
                key={type}
                open={open}
                onClose={(open) => {
                  if (!open) {
                    dispatch(closeModal(type));
                  }
                }}
                afterClose={() => {
                  dispatch(destroyModal(type));
                }}
                props={props}
              />
            );
          }
        }
      })}
    </ModalsContext.Provider>
  );
};
