import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@/components/v2/Skeleton';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { useGetDocumentDetailQuery } from '@/redux/api';
import { standardDateTimeFormat } from '@/utils/date';
import { formatDecimal } from '@/utils/number';
import { Show } from '@/components/v2/Show';

interface SourceDocumentDetailProps {
  documentId: number;
}
const SourceDocumentDetail = (props: SourceDocumentDetailProps) => {
  const { documentId } = props;

  const { t } = useTranslation('document');

  const { data: documentDetail, isUninitialized, isLoading } = useGetDocumentDetailQuery(documentId);

  if (isUninitialized || isLoading) {
    return (
      <DetailLayout>
        <DetailItemSkeleton />
        <DetailItemSkeleton />
        <DetailItemSkeleton />
        <DetailItemSkeleton />
        <DetailItemSkeleton />
        <DetailItemSkeleton />
        <DetailItemSkeleton />
        <DetailItemSkeleton />
      </DetailLayout>
    );
  }

  if (!documentDetail) {
    return null;
  }

  const sourceDocument = documentDetail.sourceDocument;

  return (
    <DetailLayout>
      <DetailItem title={t('revision')} text={sourceDocument.revision} />
      <DetailItem title={t('type')} text={sourceDocument.fileTypeString} />
      <DetailItem title={t('uploadDate')} text={standardDateTimeFormat(sourceDocument.createdAt)} />
      <DetailItem title={t('uploader')} text={sourceDocument.uploader.fullName} />
      <Show when={sourceDocument.phraseCount !== undefined}>
        <DetailItem title={t('textSegments')} text={formatDecimal(sourceDocument.phraseCount)} />
        <DetailItem title={t('uniqueTextSegments')} text={formatDecimal(sourceDocument.phraseCountUnique)} />
        <DetailItem title={t('words')} text={formatDecimal(sourceDocument.wordCount)} />
        <DetailItem title={t('characters')} text={formatDecimal(sourceDocument.charCount)} />
      </Show>
    </DetailLayout>
  );
};
SourceDocumentDetail.displayName = 'SourceDocumentDetail';

interface DetailLayoutProps {
  children?: ReactNode;
}
const DetailLayout = (props: DetailLayoutProps) => {
  const { children } = props;

  return (
    <Surface className="grid grid-cols-4 gap-x-2 gap-y-6 px-7 py-8" radius="sm">
      {children}
    </Surface>
  );
};
DetailLayout.displayName = 'DetailLayout';

interface DetailItemProps {
  title: ReactNode;
  text: ReactNode;
}
const DetailItem = (props: DetailItemProps) => {
  const { title, text } = props;

  return (
    <div className="space-y-0.5">
      <Title size="xs" as="h4" color="tertiary" weight="medium">
        {title}
      </Title>

      <Text weight="semibold">{text}</Text>
    </div>
  );
};
DetailItem.displayName = 'DetailItem';

const DetailItemSkeleton = () => <DetailItem title={<Skeleton width="40%" />} text={<Skeleton width="80%" />} />;
DetailItemSkeleton.displayName = 'DetailItemSkeleton';

export { SourceDocumentDetail };
export type { SourceDocumentDetailProps };
