import type { ICommentContentDto } from '../../../../../redux/api/types';
import { ECommentPartType, LINK_REG_EXP } from './constants';
import type { TCommentPart, TMentionPart } from './types';

/**
 * Returns the substring at the specified location within a String object (polyfill).
 * @param string The string to extract the substring from.
 * @param start The zero-based index number indicating the beginning of the substring.
 * @param end Zero-based index number indicating the end of the substring. The substring includes the characters up to, but not including, the character indicated by end.
 * If end is omitted, the characters from start through the end of the original string are returned.
 */
export const substring = (string: string, start?: number, end?: number) =>
  Array.from(string).slice(start, end).join('');

export const commentToParts = (comment: ICommentContentDto): TCommentPart[] => {
  const { lastMentionEnd, parts } = comment.mentionRanges.reduce(
    ({ lastMentionEnd, parts }, mentionRange) => {
      // Get text between last mention end and current mention offset and
      // replace it with text parts (text and link parts)
      const text = substring(comment.text, lastMentionEnd, mentionRange.offset);
      const textParts = textToParts(text);

      // Get text between mention offset and mention end (offset + length) and
      // replace it with mention part
      const mentionEnd = mentionRange.offset + mentionRange.length;
      const mentionPart: TMentionPart = {
        type: ECommentPartType.MENTION,
        accountId: mentionRange.accountId,
        text: substring(comment.text, mentionRange.offset, mentionEnd)
      };

      // Return text (not changed), last mention end (updated) and nodes (updated).
      return { lastMentionEnd: mentionEnd, parts: [...parts, ...textParts, mentionPart] };
    },
    { lastMentionEnd: 0, parts: [] as TCommentPart[] }
  );

  // Get text after last mention end (if any), replace it with text parts (text and link parts) and
  // append to current parts
  const text = substring(comment.text, lastMentionEnd);
  const textParts = textToParts(text);
  parts.push(...textParts);

  return parts;
};

const textToParts = (text: string): TCommentPart[] =>
  text.split(LINK_REG_EXP).map((part) => {
    if (part.startsWith('http')) {
      return { type: ECommentPartType.LINK, text: part, href: part };
    }

    return { type: ECommentPartType.TEXT, text: part };
  });
