import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/v2/Text';
import { useGetBillingQuery } from '@/redux/api/billingApi';

import { BillingDetails } from './components/BillingDetails';
import { InvoicesTable } from './components/InvoicesTable';

export const SettingsBilling: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');

  const { data: billingsData, isLoading } = useGetBillingQuery();

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsBilling')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('billing')}
          </Text>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-6">
          {/* Billing details */}
          <BillingDetails isLoading={isLoading} billingsData={billingsData} />
          {/* Invoices */}
          <InvoicesTable isLoading={isLoading} billingsData={billingsData} />
        </div>
      </div>
    </div>
  );
};
