import classNames from 'classnames';
import { Children, Fragment, FunctionComponent } from 'react';

import type { IBreadcrumbProps } from './types';

export const Breadcrumb: FunctionComponent<IBreadcrumbProps> = ({ separator = '>', children }) => {
  const childrenArray = Children.toArray(children);
  const childrenArrayLastIndex = childrenArray.length - 1;

  return (
    <nav>
      <ol className="flex flex-wrap items-center space-x-2">
        {childrenArray.map((child, i) => {
          const isLast = i >= childrenArrayLastIndex;

          return (
            <Fragment key={i}>
              <li className={classNames({ 'text-gray-500': isLast })}>{child}</li>
              {!isLast && <li className="text-xs text-gray-500">{separator}</li>}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};
