import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import Form from '@/components/v2/Form';

const schema = z.object({
  name: z.string().min(1)
});

type SavedViewFormSchema = z.infer<typeof schema>;

interface UseSavedViewFormProps {
  defaultValues?: SavedViewFormSchema;
  values?: SavedViewFormSchema;
}

const DEFAULT_VALUES: SavedViewFormSchema = {
  name: ''
};

const useSavedViewForm = (props: UseSavedViewFormProps) => {
  const { defaultValues = DEFAULT_VALUES, values } = props;

  const form = Form.useForm<SavedViewFormSchema>({
    defaultValues,
    values,
    mode: 'all',
    resolver: zodResolver(schema)
  });

  return form;
};

export { useSavedViewForm };
export type { SavedViewFormSchema };
