import { classNames } from '@/utils/classNames';
import { isNonNullable } from '@/utils/isNonNullable';

import { Show } from '../Show';
import { WITH_CHILDREN_CLASS_NAME } from './constants';
import type { IBadgeProps } from './types';

export const Badge = (props: IBadgeProps) => {
  const { value, shape = 'rounded', size = 'md', empty = false, className, style, children } = props;
  const hasValue = isNonNullable(value);
  const hasChildren = isNonNullable(children);

  return (
    <div className={classNames('relative inline-block', className)} style={style}>
      <Show when={!empty && hasValue}>
        <div
          className={classNames(
            'inline-flex items-center justify-center bg-red-500 px-1 py-0.5 font-semibold leading-none text-white',
            {
              rounded: shape === 'squared',
              'rounded-full': shape === 'rounded',
              'text-2xs': size === 'sm',
              'text-xs': size === 'md',
              [WITH_CHILDREN_CLASS_NAME]: hasChildren
            }
          )}
        >
          {value}
        </div>
      </Show>

      <Show when={empty}>
        <div
          className={classNames('bg-red-500', {
            rounded: shape === 'squared',
            'rounded-full': shape === 'rounded',
            'h-2 w-2': size === 'sm',
            'h-3 w-3': size === 'md',
            [WITH_CHILDREN_CLASS_NAME]: hasChildren
          })}
        />
      </Show>

      {children}
    </div>
  );
};
