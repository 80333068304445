import { classNames } from '@/utils/classNames';

// TODO: for the future add variants
interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className="animate-spin rounded-full bg-gradient-to-tr from-white via-blue-100 to-blue-500 p-0.5">
      <div className={classNames('rounded-full bg-white', className)}>
        <div className="h-4 w-4 rounded-full"></div>
      </div>
    </div>
  );
};
