import { TeamDetailsResponseDto } from '@/redux/api/types';

import { redokunApi } from './redokunApi';
import type { OkResponseDto } from './types';

export type CreateTeamResponse = {
  id: number;
};

export const teamApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeamDetails: builder.query<TeamDetailsResponseDto, void>({
      query: () => ({
        url: `/team`,
        method: 'GET'
      }),
      providesTags: ['team']
    }),
    crateTeam: builder.mutation<CreateTeamResponse, { teamName: string }>({
      query: (body) => ({
        url: `/team`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['team', 'currentAccount']
    }),
    patchTeam: builder.mutation<
      OkResponseDto,
      {
        name?: string;
        leaderId?: number;
      }
    >({
      query: (body) => ({
        url: `/team`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['team', 'currentAccount']
    }),
    deleteTeam: builder.mutation<OkResponseDto, unknown>({
      query: () => ({
        url: `/team`,
        method: 'DELETE'
      }),
      invalidatesTags: ['currentAccount']
    }),
    inviteManagerInTeam: builder.mutation<OkResponseDto, { email: string }>({
      query: ({ email }) => ({
        url: `/team/invite`,
        method: 'POST',
        body: {
          email
        }
      }),
      invalidatesTags: ['currentAccount']
    }),
    removeManagerFromTeam: builder.mutation<OkResponseDto, { managerAccountId: number; newDocumentsOwnerId: number }>({
      query: ({ managerAccountId, newDocumentsOwnerId }) => ({
        url: `/team/manager/${managerAccountId}`,
        method: 'DELETE',
        body: {
          newDocumentsOwnerId
        }
      }),
      invalidatesTags: ['currentAccount']
    })
  })
});

export const {
  useGetTeamDetailsQuery,
  useLazyGetTeamDetailsQuery,
  useCrateTeamMutation,
  useDeleteTeamMutation,
  usePatchTeamMutation,
  useInviteManagerInTeamMutation,
  useRemoveManagerFromTeamMutation
} = teamApi;
