import { cva } from 'cva';

export const toastVariants = cva(
  'grid w-112 gap-4 rounded-lg border border-l-4 border-gray-100 bg-white p-4 shadow-lg',
  {
    variants: {
      kind: {
        loading: 'border-l-blue-500',
        informational: 'border-l-blue-500',
        success: 'border-l-green-500',
        error: 'border-l-red-500',
        warning: 'border-l-yellow-500'
      }
    },

    defaultVariants: {
      kind: 'informational'
    }
  }
);
