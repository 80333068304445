import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Spinner } from '@/components/v1/Spinner';
import { Button } from '@/components/v2/Button';
import { Navbar } from '@/components/v2/Navbar';
import { StepperGroup } from '@/components/v2/StepperGroup';
import { useGetDocumentDetailQuery } from '@/redux/api';
import { TAppState } from '@/redux/store/types';
import { assert } from '@/utils/assert';

import { SourceDocumentHeader } from './components/SourceDocumentHeader';
import { useImportRealtime } from './hooks/useImportRealtime';
import { EImportSegmentsSteps, ImportSegmentsLayoutProps, Step } from './types';

export const ImportSegmentsLayout = ({ children }: ImportSegmentsLayoutProps) => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation('importSegments');
  const { documentId, languageId } = useDocumentParams();

  const importState = useSelector((state: TAppState) => state.importSegments);

  useImportRealtime({ documentId, languageId });

  const importSegmentsSteps: Step[] = [
    {
      id: EImportSegmentsSteps.UPLOAD_FILE,
      path: `/documents/show/${documentId}/translations/${languageId}/import-phrase`,
      label: t('uploadFile')
    },
    {
      id: EImportSegmentsSteps.CONFIRM_IMPORT,
      path: `/documents/show/${documentId}/translations/${languageId}/import-phrase/${importState.batchId}/confirm`,
      label: t('confirmImport')
    }
  ];

  const { data: documentDetail, isUninitialized, isLoading } = useGetDocumentDetailQuery(documentId);

  const handleClose = () => {
    history.push(`/documents/show/${documentId}`);
  };

  if (isUninitialized || isLoading) {
    return <Spinner.Fullscreen size="lg" />;
  }

  assert(documentDetail, 'Document data not found');

  const index = importSegmentsSteps.map((s) => s.path).indexOf(location.pathname);

  return (
    <div className="min-h-screen bg-surface-background">
      <Helmet>
        <title>{t('importSegments')}</title>
      </Helmet>

      {/* Header */}
      <Navbar sticky>
        <Button variant="surface" color="secondary" onClick={handleClose} className="ml-auto">
          {t('close')}
        </Button>
      </Navbar>

      <SourceDocumentHeader sourceDocument={documentDetail.sourceDocument} />

      <div className="mx-auto mt-20 max-w-4xl">
        <div className="mb-20 mt-12 flex items-center justify-center">
          <StepperGroup orientation="horizontal" items={importSegmentsSteps} current={index} className="w-160" />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

const useDocumentParams = () => {
  const params = useParams<{ documentId: string; languageId: string; batchId: string }>();
  const parsedParams = useMemo(
    () => ({
      documentId: parseInt(params.documentId),
      languageId: parseInt(params.languageId)
    }),
    [params]
  );
  return parsedParams;
};
