import { DEFAULT_PAGE_INDEX } from '@/components/v2/DataTable';
import { createSearchParams } from '@/hooks/useSearchParams';
import { SavedViewDto } from '@/redux/api/types';

import { createDocumentListSearchParamsSetters } from './hooks/useDocumentListSearchParams';

const createDocumentListSearchParams = (savedView: SavedViewDto) => {
  let searchParams = new URLSearchParams();

  const setters = createDocumentListSearchParamsSetters((nextInit) => {
    searchParams = createSearchParams(typeof nextInit === 'function' ? nextInit(searchParams) : nextInit);
  });

  setters.setFilterByOwnerAccountIds(savedView.owners);
  setters.setFilterByStatus(savedView.status);
  setters.setFilterByTargetLanguageIds(savedView.languages);
  setters.setFilterByTranslatorAccountIds(savedView.translators);
  setters.setPage(DEFAULT_PAGE_INDEX);
  setters.setSavedView(savedView.id);
  setters.setSearchTerm(savedView.searchTerm);

  return searchParams;
};

export { createDocumentListSearchParams };
