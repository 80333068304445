import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import classNames from 'classnames';
import { DraftHandleValue, EditorState, Modifier } from 'draft-js';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import { forwardRef, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import type { IMentionsProps } from './types';
import { searchSuggestions } from './utils';

// In this component there is a workaround for disabling auto focus.
// DraftJS Plugins forces focus on mount and this causes strange behaviors when input is mounted, specially in Popover component
// (https://github.com/draft-js-plugins/draft-js-plugins/blob/5d5f9ba68998d3b565b3d50332521d57e6812770/packages/editor/src/Editor/index.tsx#L102)

export const Mentions = forwardRef<Editor, IMentionsProps>(
  ({ suggestions = [], prefix, suffix, autoFocus, onChange, onReturn, ...props }, ref) => {
    const mountedRef = useRef(false);
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [suggestionsFilter, setSuggestionsFilter] = useState('');
    const filteredSuggestions = useMemo(
      () => searchSuggestions(suggestions, suggestionsFilter),
      [suggestions, suggestionsFilter]
    );

    // Mention plugin
    const mentionPlugin = useMemo(
      () =>
        createMentionPlugin({
          mentionPrefix: '@',
          entityMutability: 'IMMUTABLE',
          supportWhitespace: true,
          theme: {
            mentionSuggestionsEntry: 'px-4 py-1',
            mentionSuggestionsEntryFocused: 'bg-gray-100 px-4 py-1 cursor-pointer',
            mentionSuggestionsPopup: 'bg-white border border-gray-300 rounded-sm max-h-96 shadow-2xl z-10 py-1'
          },
          mentionComponent: ({ children, mention }) => (
            <span className={classNames(mention.className)} spellCheck={false}>
              {children}
            </span>
          )
        }),
      []
    );

    // Single line plugin
    const singleLinePlugin = useMemo(() => createSingleLinePlugin({ stripEntities: false }), []);

    // Auto focus workaround
    useEffect(() => {
      mountedRef.current = true;
      return () => {
        mountedRef.current = false;
      };
    }, []);

    // Auto focus workaround
    const handleOnChange = (editorState: EditorState) => {
      const selection = editorState.getSelection();
      const removeAutoFocus = autoFocus === false && !mountedRef.current && selection.getHasFocus();

      if (removeAutoFocus) {
        onChange(EditorState.set(editorState, { selection: selection.set('hasFocus', false) }));
      } else {
        onChange(editorState);
      }
    };

    const handleBeforeInput = (chars: string, editorState: EditorState): DraftHandleValue => {
      // https://github.com/facebook/draft-js/issues/2422
      // OS X "Add period with double-space" feature breaks Editor if invoked after a decorator
      if (chars === '. ') {
        const currentSelection = editorState.getSelection();
        const newEditorState = EditorState.set(editorState, {
          currentContent: Modifier.replaceText(editorState.getCurrentContent(), currentSelection, ' ')
        });
        onChange(newEditorState);
        return 'handled';
      }

      return 'not-handled';
    };

    const handleReturn = (e: KeyboardEvent, editorState: EditorState): DraftHandleValue => {
      // If suggestions panel is open, let the plugin handle the return key
      if (suggestionsOpen) {
        return 'not-handled';
      }

      // If callback is defined, handle the return key
      if (onReturn) {
        onReturn(e, editorState);
        return 'handled';
      }

      return 'not-handled';
    };

    const handleSearchChange = ({ value }: { value: string }) => {
      setSuggestionsFilter(value);
    };

    return (
      <div
        className={classNames(
          'mentions-root flex rounded border border-gray-100 bg-gray-100 px-2 py-0.5 text-gray-800 ring-blue-600 focus-within:ring-2'
        )}
        onKeyDown={(e) => {
          // Stop propagation of Tab key if suggestions panel is open.
          // This is necessary because HeadlessUI has an internal focus management system that
          // breaks Mentions component.
          if (e.key === 'Tab' && suggestionsOpen) {
            e.stopPropagation();
          }
        }}
      >
        {isNonNullable(prefix) && <span className="mr-1 flex flex-none items-center">{prefix}</span>}

        <Editor
          ref={ref}
          plugins={[mentionPlugin, singleLinePlugin]}
          blockRenderMap={singleLinePlugin.blockRenderMap}
          onChange={handleOnChange}
          handleReturn={handleReturn}
          handleBeforeInput={handleBeforeInput}
          {...props}
        />

        {isNonNullable(suffix) && <span className="ml-1 flex flex-none items-center">{suffix}</span>}

        <mentionPlugin.MentionSuggestions
          open={suggestionsOpen}
          onOpenChange={setSuggestionsOpen}
          suggestions={filteredSuggestions}
          onSearchChange={handleSearchChange}
        />
      </div>
    );
  }
);
Mentions.displayName = 'Mentions';
