import { ComponentProps, forwardRef, useEffect, useState } from 'react';
import { Slot, SlotProps } from '@radix-ui/react-slot';
import { usePopper } from 'react-popper';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Text } from '@/components/v2/Text';
import { classNames } from '@/utils/classNames';
import { Input } from '@/components/v2/Input';
import {
  ComboboxContentProps,
  ComboboxContext,
  ComboboxItemProps,
  ComboboxProps,
  ComboboxSearchItemProps,
  ComboboxTriggerIconProps,
  ComboboxTriggerProps,
  ComboboxTriggerValueProps
} from '@/components/v2/Combobox/types';
import { createContext } from '@/utils/createContext';
import { comboboxTrigger } from '@/components/v2/Combobox/styles';

const [Provider, useComboboxContext] = createContext('Combobox');

/**
 * Hook for accessing Combobox context with type checking.
 * @function
 * @param {string} consumerName - The name of the consumer component.
 * @returns {UseComboboxReturnValue<T>} - The typed Combobox context value.
 */
const useTypedComboboxContext = useComboboxContext as <T>(consumerName: string) => ComboboxContext<T>;

/**
 * Combobox component for managing state and providing context.
 * @function
 * @template T
 * @param {ComboboxProps<T>} props
 * @param {UseComboboxReturnValue<T>} props.state - The state of the Combobox.
 * @param {React.ReactNode} props.children - The child elements.
 * @returns {React.ReactNode} - The rendered Combobox component.
 */
export const Combobox = <T,>(props: ComboboxProps<T>) => {
  const { state, placement = 'bottom-start', strategy = 'fixed', modifiers, className, children } = props;

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers
  });

  const updatePopperPositioning = async () => {
    await update?.();
  };

  // this is needed to update popperjs placement
  useEffect(() => {
    if (state.isOpen) {
      updatePopperPositioning();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isOpen]);

  const providerValue: ComboboxContext<T> = {
    ...state,
    setReferenceElement,
    setPopperElement,
    attributes,
    styles
  };

  return (
    <Provider {...providerValue}>
      <div className={classNames('inline-block w-full', className)}>{children}</div>
    </Provider>
  );
};

/**
 * ComboboxTrigger component for rendering the trigger button.
 * @function
 * @param {ComboboxTriggerProps} props
 * @param {React.ReactNode} [props.children] - The child elements.
 * @param {boolean} [props.bordered] - Whether the trigger button should have a border.
 * @param {string} [props.className] - Additional class name(s) for styling.
 * @param {Function} [props.forwardedRef] - Ref for the button element.
 * @returns {React.ReactNode} - The rendered ComboboxTrigger component.
 */
export const ComboboxTrigger = ({ children, bordered, asChild, className, ...rest }: ComboboxTriggerProps) => {
  const { getToggleButtonProps, setReferenceElement } = useTypedComboboxContext('ComboboxTrigger');

  // this due to ref typing errors HTMLElement !== HTMLButtonElement
  const Comp = asChild
    ? (Slot as React.ForwardRefExoticComponent<SlotProps & React.RefAttributes<HTMLButtonElement>>)
    : 'button';
  return (
    <Comp
      className={asChild ? className : classNames(comboboxTrigger({ bordered }), className)}
      {...getToggleButtonProps({ ref: setReferenceElement, ...rest })}
    >
      {children}
    </Comp>
  );
};
ComboboxTrigger.displayName = 'ComboboxTrigger';

/**
 * ComboboxTriggerValue component for rendering the selected item value.
 * @function
 * @param {ComboboxTriggerValueProps} props
 * @param {React.ReactNode} [props.children] - The child elements.
 * @param {string} [props.placeholder] - The placeholder text for the value.
 * @param {string} [props.className] - Additional class name(s) for styling.
 * @param {Function} [props.forwardedRef] - Ref for the span element.
 * @returns {React.ReactNode} - The rendered ComboboxTriggerValue component.
 */
export const ComboboxTriggerValue = forwardRef<HTMLSpanElement, ComboboxTriggerValueProps>(
  ({ children, placeholder, className, ...props }, forwardedRef) => {
    const { selectedItem } = useTypedComboboxContext('ComboboxTriggerValue');
    return (
      <span ref={forwardedRef} {...props}>
        {selectedItem ? (
          children
        ) : (
          <Text size="md" color="primary" className={className}>
            {placeholder}
          </Text>
        )}
      </span>
    );
  }
);
ComboboxTriggerValue.displayName = 'ComboboxTriggerValue';

/**
 * ComboboxTriggerIcon component for rendering the trigger icon.
 * @function
 * @param {ComboboxTriggerIconProps} props
 * @param {string} [props.className] - Additional class name(s) for styling.
 * @param {Function} [props.forwardedRef] - Ref for the span element.
 * @returns {React.ReactNode} - The rendered ComboboxTriggerIcon component.
 */
export const ComboboxTriggerIcon = forwardRef<HTMLSpanElement, ComboboxTriggerIconProps>(
  ({ className, ...props }, forwardedRef) => {
    const { isOpen } = useTypedComboboxContext('ComboboxTriggerIcon');
    return (
      <span ref={forwardedRef} className={classNames('flex items-center justify-center', className)} {...props}>
        {isOpen ? (
          <AccessibleIcon label="arrow-up" icon="ri-arrow-up-s-line" className="text-2xl text-alpha-500" />
        ) : (
          <AccessibleIcon label="arrow-down" icon="ri-arrow-down-s-line" className="text-2xl text-alpha-500" />
        )}
      </span>
    );
  }
);
ComboboxTriggerIcon.displayName = 'ComboboxTriggerIcon';

/**
 * ComboboxContent component for rendering the dropdown content.
 * @function
 * @param {ComboboxContentProps} props
 * @param {React.ReactNode} [props.children] - The child elements.
 * @param {string} [props.className] - Additional class name(s) for styling.
 * @param {Function} [props.forwardedRef] - Ref for the ul element.
 * @returns {React.ReactNode} - The rendered ComboboxContent component.
 */

export const ComboboxContent = ({ children, className, ...rest }: ComboboxContentProps) => {
  const { isOpen, getMenuProps, setPopperElement, styles, attributes } = useTypedComboboxContext('ComboboxContent');

  return (
    <ul
      {...getMenuProps({ ...rest })}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes?.popper}
      className={classNames(
        `border-neutralWarm-500 min-w-select-trigger z-[999] mt-1 cursor-pointer rounded-xl border bg-white px-2.5 py-2 shadow-md`,
        !isOpen && 'hidden',
        className
      )}
    >
      {children}
    </ul>
  );
};
ComboboxContent.displayName = 'ComboboxContent';

/**
 * ComboboxItems component for rendering the list of items.
 * @function
 * @param {ComponentProps<'div'>} props
 * @param {React.ReactNode} [props.children] - The child elements.
 * @param {string} [props.className] - Additional class name(s) for styling.
 * @param {Function} [props.forwardedRef] - Ref for the div element.
 * @returns {React.ReactNode} - The rendered ComboboxItems component.
 */
export const ComboboxItems = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  ({ className, children, ...rest }, forwardedRef) => {
    return (
      <div ref={forwardedRef} className={classNames('max-h-72 w-full overflow-y-scroll', className)} {...rest}>
        {children}
      </div>
    );
  }
);
ComboboxItems.displayName = 'ComboboxItems';

/**
 * ComboboxItem component for rendering an individual item.
 * @function
 * @param {ComboboxItemProps<T>} props
 * @param {T & ComboboxItemType} props.item - The item data.
 * @param {number} props.index - The index of the item.
 * @param {React.ReactNode} [props.children] - The child elements.
 * @param {string} [props.className] - Additional class name(s) for styling.
 * @param {Function} [props.itemRef] - Ref for the li element.
 * @returns {React.ReactNode} - The rendered ComboboxItem component.
 */
export const ComboboxItem = <T,>({ item, index, children, className, itemRef, ...props }: ComboboxItemProps<T>) => {
  const { getItemProps } = useTypedComboboxContext('ComboboxItem');

  return (
    <li
      ref={itemRef}
      className={classNames(
        'outline-none my-1 flex w-full select-none px-2.5 py-2 text-base font-medium text-typography-primary',
        className
      )}
      {...getItemProps({ item, index, ...props })}
    >
      {children}
    </li>
  );
};
ComboboxItem.displayName = 'ComboboxItem';

/**
 * ComboboxSearchItem component for rendering the search input.
 * @function
 * @param {ComboboxSearchItemProps} props
 * @param {string} [props.placeholder] - The placeholder text for the input.
 * @param {string} [props.className] - Additional class name(s) for styling.
 * @param {Function} [props.forwardedRef] - Ref for the input element.
 * @returns {React.ReactNode} - The rendered ComboboxSearchItem component.
 */
export const ComboboxSearchItem = forwardRef<HTMLInputElement, ComboboxSearchItemProps>(
  ({ size, ...props }, forwardedRef) => {
    const { getInputProps } = useTypedComboboxContext('ComboboxSearchItem');

    return (
      <div className="min-w-select-trigger w-full pb-2">
        <Input ref={forwardedRef} size={size} {...getInputProps({ ...props })} />
      </div>
    );
  }
);
ComboboxSearchItem.displayName = 'ComboboxSearchItem';
