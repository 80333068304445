import { Children, isValidElement, ReactNode } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import { Tab } from './Tab';
import type { ITabProps } from './types';

export const findTabs = (children: ReactNode) =>
  Children.toArray(children)
    .map((child) => (isValidElement<ITabProps>(child) && child.type === Tab ? child : null))
    .filter(isNonNullable);
