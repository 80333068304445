import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './constants';
import { Operation } from './types';

export const operationsSlice = createSlice({
  name: 'operations',
  initialState: INITIAL_STATE,

  reducers: {
    addOperationToQueue: (state, { payload }: PayloadAction<Operation>) => {
      state.queue.push(payload);
    },
    removeOperationFromQueue: (state, { payload }: PayloadAction<Pick<Operation, 'operationId'>>) => {
      const newQueue = (state.queue = state.queue.filter((operation) => {
        return operation.operationId !== payload.operationId;
      }));

      state.queue = newQueue;
    }
  }
});

export const { addOperationToQueue, removeOperationFromQueue } = operationsSlice.actions;
