import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ElementRef, forwardRef } from 'react';

import { popoverVariant } from '@/components/v2/Popover/styles';
import { PopoverContentType } from '@/components/v2/Popover/types';
import { Text } from '@/components/v2/Text';
import { TextProps } from '@/components/v2/Text/types';
import { classNames } from '@/utils/classNames';

export const Popover = PopoverPrimitive.Root;

export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverArrow = forwardRef<
  ElementRef<typeof PopoverPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>(({ className, ...props }, ref) => (
  <PopoverPrimitive.Arrow ref={ref} className={classNames(' fill-[#dee4e9] ', className)} {...props} />
));

export const PopoverLabel = forwardRef<ElementRef<typeof Text>, TextProps>(
  ({ size = 'xs', color = 'primary', ...props }, ref) => <Text ref={ref} size={size} color={color} {...props} />
);

export const PopoverContent = forwardRef<ElementRef<typeof PopoverPrimitive.Content>, PopoverContentType>(
  ({ className, sideOffset = 4, size, ...props }, ref) => (
    <PopoverPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={classNames(popoverVariant({ size }), className)}
      {...props}
    />
  )
);

export const PopoverPortal = PopoverPrimitive.Portal;

PopoverLabel.displayName = 'PopoverLabel';
PopoverArrow.displayName = PopoverPrimitive.Arrow.displayName;
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
