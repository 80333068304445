import type { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { forEach, reduce, reverse } from 'lodash';

export const mergePatchCollections = (...patchCollections: PatchCollection[]): PatchCollection => {
  const reversePatchCollections = reverse([...patchCollections]);

  const patches = reduce<PatchCollection, PatchCollection['patches']>(
    reversePatchCollections,
    (patches, patchCollection) => [...patches, ...patchCollection.patches],
    []
  );

  const inversePatches = reduce<PatchCollection, PatchCollection['inversePatches']>(
    reversePatchCollections,
    (inversePatches, patchCollection) => [...inversePatches, ...patchCollection.inversePatches],
    []
  );

  const undo = () => {
    forEach(reversePatchCollections, (patchCollection) => {
      patchCollection.undo();
    });
  };

  return { patches, inversePatches, undo };
};
