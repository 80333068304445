import { useCallback } from 'react';

import { usePusherBindToEvent, usePusherSubscribeToChannel } from '@/providers/PusherProvider/hooks';
import { useAppDispatch } from '@/redux/hooks';
import { setBatchId, setImportStatus, setNewStatus } from '@/redux/slices/importSegmentsSlice/importSegmentsSlice';

import type { IUseRealtimeProps } from './types';

/**
 * Hook for the management of Pusher messages in ImportSegments.
 *
 * @param {IUseRealtimeProps} { documentId, languageId }
 */
export const useImportRealtime = ({ documentId, languageId }: IUseRealtimeProps) => {
  const dispatch = useAppDispatch();

  /**
   *
   * Pusher channels
   *
   */
  const { channel: documentChannel } = usePusherSubscribeToChannel(
    `private-document-translated-${documentId}-${languageId}` || null
  );

  /**
   *
   * Pusher handlers
   *
   */

  //
  // Step 1:
  //    The user uploads the translated file, and the system parses it to show a summary
  //    preview of what changes, errors, and warnings would result from proceeding with this file.

  const handleSegmentPreImport = useCallback(
    ({
      importStatus,
      errorMessage,
      importBatchId
    }: {
      importStatus: string;
      errorMessage: string;
      importBatchId: number;
    }) => {
      // Save batchId in Redux slice for Step 2 usage
      dispatch(setBatchId(importBatchId));

      dispatch(setImportStatus({ errorMessage, importStatus }));
    },
    [dispatch]
  );
  usePusherBindToEvent(documentChannel, 'segment_pre_import:completed', handleSegmentPreImport);

  //
  // Import Step 2:
  //    If Step 1 detected valid changes, the customer now has the option to “confirm”
  //    them, applying the new translations and integrating them with the existing ones.

  const handleSegmentImportNewStatus = useCallback(
    ({
      importStatus,
      errorMessage
    }: {
      importStatus: string;
      importBatchId: number;
      errorMessage: string;
      accountId?: number;
      accountName?: string;
    }) => {
      dispatch(setNewStatus({ newStatusErrorMessage: errorMessage, newStatusImportStatus: importStatus }));
    },
    [dispatch]
  );

  // Notify the client of the completion of this step, "segment_import:new_status" event with "importStatus=DONE" in the payload
  usePusherBindToEvent(documentChannel, 'segment_import:new_status', handleSegmentImportNewStatus);

  return {};
};
