import Pusher, { Channel, ConnectionManager, PresenceChannel } from 'pusher-js';
import { useEffect } from 'react';

export const usePusherBindToEvent = <T>(
  sourceOfConnection: Pusher | Channel | ConnectionManager | PresenceChannel | null,
  eventName: string,
  callback: (data: T) => void,
  context?: unknown
) => {
  useEffect(() => {
    if (!sourceOfConnection) {
      return;
    }

    // Bind the event to the dispatcher
    sourceOfConnection.bind(eventName, callback, context);

    return () => {
      // Unbind the event from the dispatcher
      sourceOfConnection.unbind(eventName, callback, context);
    };
  }, [sourceOfConnection, eventName, callback, context]);
};
