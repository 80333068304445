import { AppLayout as AppLayoutRoot, AppLayoutContent, AppLayoutNavbar } from './AppLayout';

const AppLayout = {
  Root: AppLayoutRoot,
  Navbar: AppLayoutNavbar,
  Content: AppLayoutContent
};

export type { AppLayoutContentProps, AppLayoutNavbarProps, AppLayoutProps } from './types';
export { AppLayoutRoot as AppLayout, AppLayoutContent, AppLayoutNavbar };
export default AppLayout;
