import { useTranslation } from 'react-i18next';

import AppLayout from '@/components/layouts/AppLayout';
import { GlossaryList } from '@/routes/Glossary/components/GlossaryList';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { CreateTeam } from '@/routes/Glossary/components/CreateTeam';

export const Glossary: React.FC = () => {
  const { t: tLayout } = useTranslation('layout');
  const { currentAccount } = useCurrentAccount();

  const { isInATeam } = currentAccount.subscriptionInfo;
  return (
    <AppLayout.Root title={tLayout('glossary')}>
      <AppLayout.Navbar />

      <AppLayout.Content>
        {/* show GlossaryList only if the user is in a team */}
        <Show when={isInATeam} fallback={<CreateTeam />}>
          <GlossaryList />
        </Show>
      </AppLayout.Content>
    </AppLayout.Root>
  );
};
