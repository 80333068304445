import { cva } from 'cva';

const avatarGroup = cva('relative z-0 flex items-center', {
  variants: {
    dir: {
      ltr: 'flex-row',
      rtl: 'flex-row-reverse space-x-reverse'
    },
    size: {
      '2xs': '-space-x-2',
      xs: '-space-x-3',
      sm: '-space-x-4',
      md: '-space-x-5',
      lg: '-space-x-6',
      xl: '-space-x-8',
      '2xl': '-space-x-10'
    }
  },
  defaultVariants: {
    dir: 'ltr',
    size: 'md'
  }
});

const avatar = cva('inline-flex h-6 w-6 items-center justify-center rounded-full', {
  variants: {
    size: {
      '2xs': 'h-4 w-4 min-w-[1rem]',
      xxs: 'h-5 w-5 min-w-[1.25rem]',
      xs: 'h-6 w-6 min-w-[1.5rem]',
      sm: 'h-9 w-9 min-w-[2.25rem]',
      md: 'h-10 w-10 min-w-[2.5rem]',
      lg: 'h-11 w-11 min-w-[2.75rem]',
      xl: 'h-16 w-16 min-w-[4rem]',
      '2xl': 'h-20 w-20 min-w-[5rem]'
    }
  },
  defaultVariants: {
    size: 'md'
  }
});

const avatarFallback = cva(
  'flex h-full w-full select-none items-center justify-center rounded-full bg-primary-active font-semibold leading-none text-typography-inverse-primary',
  {
    variants: {
      size: {
        '2xs': 'text-[0.25rem]',
        xxs: 'text-[0.5rem]',
        xs: 'text-xs',
        sm: 'text-sm',
        md: 'text-base',
        lg: 'text-lg',
        xl: 'text-2xl',
        '2xl': 'text-2xl'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

const avatarIndicator = cva('absolute -bottom-px -right-px rounded-full ring-2 ring-white', {
  variants: {
    online: {
      true: 'bg-green-400',
      false: 'bg-gray-200'
    },
    size: {
      '2xs': '',
      xxs: '',
      xs: 'h-2 w-2',
      sm: 'h-2.5 w-2.5',
      md: 'h-3 w-3',
      lg: 'h-3.5 w-3.5',
      xl: 'h-4 w-4',
      '2xl': 'h-5 w-5'
    }
  },
  defaultVariants: {
    online: false,
    size: 'md'
  }
});

export { avatar, avatarFallback, avatarIndicator, avatarGroup };
