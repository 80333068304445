import { zodResolver } from '@hookform/resolvers/zod';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Avatar, AvatarImage } from '@/components/v2/Avatar';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import { Text } from '@/components/v2/Text';
import { useToast } from '@/components/v2/Toast';
import {
  useGetCurrentAccountQuery,
  usePatchCurrentAccountMutation,
  useUpdateCurrentAccountAvatarMutation
} from '@/redux/api';
import { to } from '@/utils/awaitToJs';
import { Show } from '@/components/v2/Show';

import { ConfirmDeleteCurrentUserAvatarModal } from './components/ConfirmDeleteCurrentUserAvatarModal';
import { Panel } from './components/Panel';
import { YourSubscription } from './components/YourSubscription';

export const Settings: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');

  const inputAvatartRef = useRef<HTMLInputElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { toast } = useToast();

  const [patchCurrentAccount] = usePatchCurrentAccountMutation();
  const [updateCurrentAvatarAccount] = useUpdateCurrentAccountAvatarMutation();

  const {
    data: currentAccount,
    isLoading: currentGetAccountIsLoading,
    isSuccess: currentGetAccountisSuccess
  } = useGetCurrentAccountQuery();

  const formSchema: ZodType = z.object({
    email: z.string().email({ message: tSettings('thisFieldIsRequired') }),
    firstName: z
      .string()
      .trim()
      .min(1, { message: tSettings('thisFieldIsRequired') }),
    lastName: z.string(),
    jobTitle: z.string(),
    organization: z.string()
  });

  const { handleSubmit, control, formState } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: async () => {
      return {
        email: currentAccount?.email,
        firstName: currentAccount?.firstName,
        lastName: currentAccount?.lastName,
        jobTitle: currentAccount?.jobTitle,
        organization: currentAccount?.organization
      };
    }
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const [err] = await to(patchCurrentAccount(values).unwrap());

    if (!err) {
      // If email has changed, show different message
      if (formState?.defaultValues?.email !== values.email) {
        toast({
          title: tSettings('account_data_saved'),
          description: tSettings('toast_message_email_changed'),
          kind: 'warning'
        });
      } else {
        toast({ title: tSettings('account_data_saved'), kind: 'success' });
      }
    } else {
      toast({ title: tSettings('account_data_not_saved'), kind: 'error' });
    }
  };

  // Avatar edit logic

  /**
   * Trigger the file picker buy ref.
   */
  const handleEditButtonClick = () => {
    if (inputAvatartRef && inputAvatartRef.current) {
      inputAvatartRef.current.click();
    }
  };

  /**
   * Get the file in input, convert it to Base64,
   * and use send it to the server using updateCurrentAvatarAccount.
   */
  const handleAvatarFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (reader.result) {
        const [, updateAvatarResults] = await to(
          updateCurrentAvatarAccount({
            avatarImageBase64: reader.result.toString()
          }).unwrap()
        );
        if (updateAvatarResults) {
          const [, res] = await to(
            patchCurrentAccount({ avatarFilename: updateAvatarResults.avatarFilename }).unwrap()
          );

          if (res) {
            toast({
              title: tSettings('avatarUpdatedSuccessfully'),
              kind: 'success'
            });
          }
        }
      }
    };
    reader.onerror = () => {
      toast({
        title: tSettings('avatarNotUpdated'),
        kind: 'error'
      });
    };
  };
  // End avatar edit logic

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsAccountSettings')}</title>
      </Helmet>

      <div className="flex justify-between">
        {/* Text header */}
        <div>
          <Text color="primary" size="xl" weight={'bold'}>
            {tSettings('account_settings')}
          </Text>
          <div className="mt-4 inline-flex items-center space-x-2">
            <Text color="secondary" size="md" weight={'regular'}>
              {tSettings('need_help_setting_up_your_account_check_out_the')}
            </Text>
            <ButtonLink variant={'text'} href={'https://help.redokun.com/'} className="font-medium text-blue-400">
              {tSettings('help_center')}
              <AccessibleIcon icon="ri-external-link-line" label="ri-external-link-line" className="text-xl" />
            </ButtonLink>
          </div>
        </div>
        {/* Save button */}
        <div>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            {tSettings('save')}
          </Button>
        </div>
      </div>

      {/* Two columns */}
      {currentGetAccountIsLoading ? (
        <div className="p-3">
          <Spinner />
        </div>
      ) : null}
      {currentGetAccountisSuccess && currentAccount ? (
        <div className="mt-6 grid grid-cols-2 gap-6">
          <div className="grid grid-cols-1 gap-6">
            {/* Settings form */}
            <form>
              <Panel>
                <div className="space-y-6">
                  <Text size={'md'} weight={'bold'} color="primary">
                    {tSettings('account_information')}
                  </Text>
                  <div className="space-y-4">
                    <Label htmlFor="email" isRequired text={tSettings('work_email')}></Label>
                    <Controller
                      name="email"
                      rules={{
                        required: true
                      }}
                      control={control}
                      render={({ field }) => <Input {...field} required type="email" id="email"></Input>}
                    />
                    {formState.errors.email && <Text className="text-red-500">{formState.errors.email.message}</Text>}
                  </div>

                  <div className="space-y-4">
                    <Label htmlFor="firstName" isRequired text={tSettings('first_name')}></Label>
                    <Controller
                      name="firstName"
                      rules={{
                        required: true
                      }}
                      control={control}
                      render={({ field }) => <Input {...field} id="firstName"></Input>}
                    />
                    {formState.errors.firstName && (
                      <Text className="text-red-500">{formState.errors.firstName.message}</Text>
                    )}
                  </div>

                  <div className="space-y-4">
                    <Label htmlFor="lastName" text={tSettings('last_name')}></Label>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => <Input {...field} id="lastName"></Input>}
                    />
                  </div>

                  <div className="space-y-4">
                    <Label htmlFor="jobTitle" text={tSettings('job_title')}></Label>
                    <Controller
                      name="jobTitle"
                      control={control}
                      render={({ field }) => <Input {...field} id="jobTitle"></Input>}
                    />
                  </div>

                  <div className="space-y-4">
                    <Label htmlFor="organization" text={tSettings('organization')}></Label>
                    <Controller
                      name="organization"
                      control={control}
                      render={({ field }) => <Input {...field} id="organization"></Input>}
                    />
                  </div>
                </div>
              </Panel>
            </form>
            <Panel>
              <Text size={'md'} weight={'bold'} color="primary">
                {tSettings('avatar')}
              </Text>
              <div className="mt-3">
                <Avatar size="2xl">
                  <AvatarImage src={currentAccount.avatarUrl} />
                </Avatar>
              </div>
              <div className="mt-3 flex items-center space-x-4">
                <div>
                  <input
                    style={{ display: 'none' }}
                    ref={inputAvatartRef}
                    type="file"
                    onChange={handleAvatarFileChange}
                  />
                  <Button variant="soft" color="primary" onClick={handleEditButtonClick}>
                    <AccessibleIcon icon="ri-edit-line" label="ri-edit-line" />
                    {tSettings('edit')}
                  </Button>
                </div>
                <ConfirmDeleteCurrentUserAvatarModal
                  open={modalOpen}
                  onOpenChange={(open) => setModalOpen(open)}
                  onConfirm={() => setModalOpen(false)}
                >
                  <Button variant="text" color="negative">
                    <AccessibleIcon icon="ri-delete-bin-line" label="ri-delete-bin-line" />
                    {tSettings('delete')}
                  </Button>
                </ConfirmDeleteCurrentUserAvatarModal>
              </div>
            </Panel>
          </div>
          <div>
            <Show when={!currentAccount.subscriptionInfo.isTranslator}>
              <YourSubscription currentAccount={currentAccount} showCurrentPlanLink />
            </Show>
          </div>
        </div>
      ) : null}
    </div>
  );
};
