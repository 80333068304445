import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonLink } from '@/components/v1/Button';
import { Modal } from '@/components/v1/Modal';
import type { TJumboModalProps } from '@/components/v1/Modal/types';
import { APP_URL } from '@/config/urls';
import { useUpdateTranslationInvitationMutation } from '@/redux/api';
import { ETranslationInvitationStatus } from '@/redux/api/constants';
import type { IWorkInvitationModalProps } from '@/redux/slices/modalsSlice/types/props';

export const WorkInvitationModal: FunctionComponent<TJumboModalProps & { props: IWorkInvitationModalProps }> = ({
  props: { documentId, languageId, accountName },
  ...modalProps
}) => {
  const { t } = useTranslation('modals');
  const [updateTranslationInvitation, { isLoading: updateTranslationInvitationIsLoading }] =
    useUpdateTranslationInvitationMutation();

  return (
    <Modal.Jumbo {...modalProps}>
      {{
        header: accountName ? t('invitedToWorkBy', { accountName }) : t('invitedToWork'),
        content: t('acceptInvitationToStartWorking'),
        footer: (
          <div className="space-x-2">
            <ButtonLink btnType="outline" href={`${APP_URL}/documents`} rel="noreferrer">
              {t('goBackToDocumentList')}
            </ButtonLink>
            <Button
              loading={updateTranslationInvitationIsLoading}
              onClick={() => {
                updateTranslationInvitation({
                  documentId,
                  languageId,
                  body: { status: ETranslationInvitationStatus.ACCEPTED }
                }).then(() => {
                  modalProps.onClose(false);
                });
              }}
            >
              {t('acceptInvitation')}
            </Button>
          </div>
        )
      }}
    </Modal.Jumbo>
  );
};
