import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { to } from '@/utils/awaitToJs';
import { DocumentListOperationItemRequestDto } from '@/redux/api/types';
import { useDeleteDocumentListItemsMutation } from '@/redux/api/documentListApi';

export interface DeleteBatchItemModalProps extends ModalProps {
  items: DocumentListOperationItemRequestDto[];
  onDelete?: () => void;
}

export const DeleteBatchItemModal = (props: DeleteBatchItemModalProps) => {
  const { items, children, onDelete, onOpenChange, ...modalProps } = props;
  const [openDeleteItemsModal, setDeleteItemsModal] = useState(false);

  const { t: tDocumentList } = useTranslation('documentList');

  const [deleteFolder, { isLoading, isSuccess }] = useDeleteDocumentListItemsMutation();

  const handleDeleteClick = async () => {
    const [error] = await to(deleteFolder({ items }).unwrap());

    if (!error) {
      onDelete?.();
    }

    onOpenChange?.(false);
  };

  return (
    <Modal.Root onOpenChange={setDeleteItemsModal} open={openDeleteItemsModal} {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="sm">
        <Modal.Header>
          <Modal.Title size="md">{tDocumentList('deleteBatchItemsQuestion', { count: items.length })}</Modal.Title>
          <Modal.Description size="md">{tDocumentList('deleteBatchItemsFirstWarning')}</Modal.Description>
          <Modal.Description size="md">{tDocumentList('deleteBatchItemsSecondWarning')}</Modal.Description>
        </Modal.Header>

        <Modal.Footer>
          <Button color="negative" onClick={handleDeleteClick} disabled={isLoading || isSuccess}>
            {tDocumentList('deleteFiles')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tDocumentList('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
