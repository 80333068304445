import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { FlagIcon } from '@/components/v2/FlagIcon';
import Modal from '@/components/v2/Modal';
import { Text } from '@/components/v2/Text';
import { useToast } from '@/components/v2/Toast';
import { usePutTranslatorLanguagesMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';

import { createLanguageGroups } from '../../utils/createLanguagesGroup';
import { SettingsEditLanguageSearchBox } from '../SettingsEditLanguageSearchBox';
import { EditAssignedLanguagesModalProps, LanguageGroup, LanguageOption } from './types';
import { createSelectedLanguages } from './createSelectedLanguages';
import { removeLanguageById } from './removeLanguageById';

export const EditAssignedLanguagesModal = ({
  props: { translator, languagesData, languagesFlagsData },
  ...modalProps
}: EditAssignedLanguagesModalProps) => {
  const { t: tSettings } = useTranslation('settings');
  const [putTranslatorLanguagesTrigger, putTranslatorLanguagesState] = usePutTranslatorLanguagesMutation();
  const { toast } = useToast();

  const [selectedItems, setSelectedItems] = useState<LanguageOption[] | undefined>(
    createSelectedLanguages(languagesFlagsData, translator.assignedLanguagesIds)
  );

  useEffect(() => {
    setSelectedItems(createSelectedLanguages(languagesFlagsData, translator.assignedLanguagesIds));
  }, [translator.assignedLanguagesIds, languagesFlagsData]);

  const languageGroups: LanguageGroup[] = languagesData ? createLanguageGroups(languagesData, tSettings) : [];

  const handleAddLanguage = (items?: LanguageOption[]) => {
    setSelectedItems(items);
  };

  const handleRemoveLanguage = (id: string) => {
    const items = removeLanguageById(id, selectedItems);
    setSelectedItems(items);
  };

  const handleSave = async () => {
    if (selectedItems) {
      // Save
      const [err, res] = await to(
        putTranslatorLanguagesTrigger({
          translatorAccountId: translator.id,
          languageIds: selectedItems.map((item) => {
            return parseInt(item.id);
          })
        }).unwrap()
      );
      // Close modal
      if (!err && res.status === 'ok') {
        toast({ title: tSettings('languagesUpdatedToastTitle'), kind: 'success' });
      }
    }
  };

  return (
    <Modal.Root {...modalProps} onOpenChange={modalProps.onClose}>
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title>{tSettings('editLanguages')}</Modal.Title>
        </Modal.Header>

        <div>
          <Text>{tSettings('youReAssigningALanguagesTo', { name: translator.fullName || translator.email })}</Text>
        </div>

        <div>
          <SettingsEditLanguageSearchBox
            defaultValues={selectedItems}
            languageGroups={languageGroups}
            buttonLabel={tSettings('addLanguages')}
            className="w-1/2"
            onAddLanguage={handleAddLanguage}
          />
        </div>

        {selectedItems?.length ? (
          <div className="space-y-6 rounded-lg border bg-white p-4">
            {selectedItems?.map((languageOption, key) => {
              const { id, name, flagCode } = languageOption;
              return (
                <div className="flex justify-between" key={key}>
                  <div className="flex items-center space-x-2">
                    <FlagIcon label={name} code={flagCode || 'xx'} size="md" />
                    <p>{name}</p>
                  </div>
                  <div>
                    <Button variant="text" color="negative" onClick={() => handleRemoveLanguage(id)}>
                      <AccessibleIcon icon="ri-delete-bin-line" label={tSettings('delete')} className="text-xl" />
                      {tSettings('delete')}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        <Modal.Footer>
          <Button color="primary" onClick={handleSave} disabled={putTranslatorLanguagesState.isLoading}>
            {putTranslatorLanguagesState.isLoading ? <Spinner size="lg" /> : null}
            {tSettings('save')}
          </Button>
          <Modal.Close>
            <Button
              // eslint-disable-next-line
              autoFocus={true}
              color="secondary"
              variant="surface"
              onClick={() => {
                if (modalProps.onClose) {
                  modalProps.onClose(true);
                }
              }}
            >
              {tSettings('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
