import { redokunApi } from './redokunApi';
import type { CancelRenewalRequestDto, OkResponseDto } from './types';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const subscriptionApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    // Cancel automatic renewal of current user's subscription
    deleteSubscription: builder.mutation<OkResponseDto, CancelRenewalRequestDto>({
      query: (body) => ({
        url: `/subscription`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['currentAccount']
    }),

    // Revert the cancellation of automatic renewal of current user's subscription
    deleteSubscriptionCancellation: builder.mutation<OkResponseDto, void>({
      query: () => ({
        url: `/subscription/cancellation`,
        method: 'DELETE'
      }),
      invalidatesTags: ['currentAccount']
    })
  })
});

export const { useDeleteSubscriptionMutation, useDeleteSubscriptionCancellationMutation } = subscriptionApi;

export const subscriptionApiUtil = {
  ...subscriptionApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(subscriptionApi.util.updateQueryData)
};
