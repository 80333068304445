import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { ButtonLink } from '@/components/v2/ButtonLink';
import Modal from '@/components/v2/Modal';
import { APP_URL } from '@/config/urls';
import { FeatureRequireUpgradeModalProps } from '@/redux/slices/modalsSlice/types/props';

export const FeatureRequireUpgradeModal = (props: FeatureRequireUpgradeModalProps) => {
  const { ...modalProps } = props;
  const { t: tModals } = useTranslation('modals');
  const { feature } = modalProps;
  const featureName = {
    tmImport: tModals('translationMemory'),
    tmManager: tModals('translationMemoryManager'),
    glossary: tModals('glossary')
  }[feature];

  return (
    <Modal.Root {...modalProps}>
      <Modal.Content
        size="lg"
        // prevent closing modal when click outside
        onInteractOutside={(e) => e.preventDefault()}
        // since there's a case where no inputs are available, this prevents the display of a focus ring around the modal window
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Modal.Header>
          <AccessibleIcon
            icon="ri-alert-line"
            label="warning-icon"
            className="self-start text-4xl text-states-alert-active"
          />
          <Modal.Title size="lg">{tModals('featureNotAvailable', { feature: featureName })}</Modal.Title>
          <Modal.Description size="md">{tModals('thisFeatureNotAvailableInYourCurrentPlan')}</Modal.Description>
        </Modal.Header>

        <Modal.Footer className="flex flex-row">
          <Modal.Close>
            <Button color="secondary">{tModals('goBack')}</Button>
          </Modal.Close>

          <ButtonLink color="primary" href={`${APP_URL}/account/subscription`}>
            {tModals('upgrade')}
          </ButtonLink>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
