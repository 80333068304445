import { CheckedState } from '@radix-ui/react-checkbox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Checkbox } from '@/components/v2/Checkbox';
import Modal from '@/components/v2/Modal';
import { Text } from '@/components/v2/Text';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LowWordBalanceModalProps } from '@/redux/slices/modalsSlice/types/props';
import { useDownload } from '@/routes/Document/hooks/useDownload';

export const LowWordBalanceModal = (props: LowWordBalanceModalProps) => {
  const { lowWordBalanceThreshold, documentId, languageId, translationId, children, ...modalProps } = props;
  const [checked, setChecked] = useState<CheckedState>(false);
  const [, storeIsLowWordBalanceAlertVisible] = useLocalStorage<boolean | undefined>(
    'isLowWordBalanceAlertVisible',
    true
  );

  const download = useDownload(documentId, languageId);

  const { t: tModals } = useTranslation('modals');

  const handleOnConfirm = () => {
    download().then(() => {
      // when download finishes close the modal
      closeModal(false);
    });
  };

  const closeModal = (open: boolean) => {
    if (!open && checked) {
      // prevent showing the alert next time
      storeIsLowWordBalanceAlertVisible(false);
    }
    modalProps.onOpenChange?.(open);
  };

  return (
    <Modal.Root {...modalProps} onOpenChange={closeModal}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="lg">
        <Modal.Header className="gap-6">
          <AccessibleIcon
            icon="ri-question-fill"
            label="ri-question-fill"
            className="justify-start text-4xl text-primary-active"
          />
          <span className="flex flex-col gap-3">
            <Modal.Title size="lg">{tModals('lessThanCountWordsLeft', { count: lowWordBalanceThreshold })}</Modal.Title>
            <Modal.Description size="md">
              {tModals('downloadingThisDocumentWillLeaveYourWordBalanceFewer', { count: lowWordBalanceThreshold })}
            </Modal.Description>
          </span>
          <span className="flex flex-row gap-3">
            <Checkbox id="checkbox" checked={checked} onCheckedChange={setChecked} size="sm" className="peer" />
            <Text
              size="sm"
              weight="medium"
              color="primary"
              className="hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
              asChild
            >
              <label htmlFor="checkbox">{tModals('dontShowThisMessageAgain')}</label>
            </Text>
          </span>
        </Modal.Header>

        <Modal.Footer className="flex flex-row gap-4">
          <Modal.Close>
            <Button color="secondary" variant="surface" className="w-full">
              {tModals('goBack')}
            </Button>
          </Modal.Close>
          <Button color="primary" className="w-full" onClick={handleOnConfirm}>
            {tModals('downloadAnyway')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
