import * as AccessibleIconPrimitive from '@radix-ui/react-accessible-icon';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';

import type { AccessibleIconElement, AccessibleIconProps } from './types';

/**
 * Represents an accessible icon component.
 *
 * @remarks
 * This component renders an accessible icon with a label.
 *
 * @param className - The CSS class name for the component.
 * @param icon - The icon to be rendered.
 * @param label - The label for the accessible icon.
 * @param props - Additional props for the component.
 * @param ref - The ref to be forwarded to the component.
 * @returns The rendered accessible icon component.
 */
const AccessibleIcon = forwardRef<AccessibleIconElement, AccessibleIconProps>(
  ({ className, icon, label, ...props }, ref) => (
    <AccessibleIconPrimitive.Root label={label}>
      <i {...props} ref={ref} className={classNames(icon, 'flex items-center justify-center', className)} />
    </AccessibleIconPrimitive.Root>
  )
);
AccessibleIcon.displayName = 'AccessibleIcon';

export { AccessibleIcon };
