import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v2/Box';
import { ButtonLink } from '@/components/v2/ButtonLink';
import InlineMessage from '@/components/v2/InlineMessage';
import { Skeleton } from '@/components/v2/Skeleton';
import { Text } from '@/components/v2/Text';
import { CurrentAccountDto } from '@/redux/api/types';
import { standardDateTimeFormat } from '@/utils/date';

import { StatValue } from './Stat';

type YourSubscriptionProps = {
  currentAccount: CurrentAccountDto;
  showCurrentPlanLink?: boolean;
};

export const YourSubscription = ({ currentAccount, showCurrentPlanLink }: YourSubscriptionProps) => {
  const { t: tSettings } = useTranslation('settings');

  const blockStats = () => {
    const translatorData = `${currentAccount.subscriptionInfo.totalTranslators}/${currentAccount.subscriptionInfo.maxTranslators}`;
    const managersData = `${currentAccount.subscriptionInfo.teamSize}/${currentAccount.subscriptionInfo.maxTeamSize}`;
    // Check if the user has unlimited words
    const availableWordsData = currentAccount.subscriptionInfo.hasUnlimitedWords
      ? '∞'
      : currentAccount.subscriptionInfo.exportedWordBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return (
      <div className="mt-3 grid grid-cols-3 gap-3">
        <StatValue label={tSettings('translator')} data={translatorData} />
        <StatValue label={tSettings('managers')} data={managersData} />
        <StatValue label={tSettings('available_words')} data={availableWordsData} />
      </div>
    );
  };

  const blockMessage = () => {
    if (currentAccount.subscriptionInfo.currentSubEndAt) {
      return (
        <div className="mt-3">
          <InlineMessage.Root
            rootClassName="w-full"
            orientation="horizontal"
            type={currentAccount.subscriptionInfo.isActive ? 'success' : 'error'}
            size="sm"
            className="w-full"
          >
            <InlineMessage.Text
              size="sm"
              weight="semibold"
              type={currentAccount.subscriptionInfo.isActive ? 'success' : 'error'}
              className="flex flex-grow items-center justify-between"
            >
              {currentAccount.subscriptionInfo.isActive ? tSettings('validUntil') : tSettings('expiredOn')}{' '}
              {standardDateTimeFormat(currentAccount.subscriptionInfo.currentSubEndAt)}
              {showCurrentPlanLink && (
                <ButtonLink
                  href={'/settings/current-plan'}
                  variant="solid"
                  color="secondary"
                  size="sm"
                  className="text-typography-primary"
                >
                  {tSettings('see_your_current_plan')}
                </ButtonLink>
              )}
            </InlineMessage.Text>
          </InlineMessage.Root>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Box>
      <Text size={'md'} weight={'bold'} color="primary">
        {tSettings('your-subscription')}
      </Text>
      {/* Three columns stats */}
      {blockStats()}
      {/* Status and date of the subscription */}
      {blockMessage()}
    </Box>
  );
};

export const YourSubscriptionSkeleton = () => (
  <Box>
    <Text size={'md'} weight={'bold'} color="primary">
      <Skeleton width="50%" />
    </Text>

    <div className="mt-3 grid grid-cols-3 gap-3">
      <StatValue label={<Skeleton />} data={<Skeleton />} />
      <StatValue label={<Skeleton />} data={<Skeleton />} />
      <StatValue label={<Skeleton />} data={<Skeleton />} />
    </div>
  </Box>
);
