import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { PlanDto, PlanFeatureDto } from '@/redux/api/types';

type PricingTableFeatureListProps = {
  plan: PlanDto;
};

export const PricingTableFeatureList = ({ plan }: PricingTableFeatureListProps) => {
  const textWithTooltip = (feature: PlanFeatureDto) => {
    return (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Text color="secondary" className="underline-offset-2 decoration-dashed py-1.5 underline">
            {feature.name}
          </Text>
        </Tooltip.Trigger>
        <Tooltip.Content className="max-w-xxs" sideOffset={12}>
          <Text className="whitespace-pre-line text-typography-inverse-primary" size="xs">
            {feature.description}
          </Text>
          <Tooltip.Arrow />
        </Tooltip.Content>
      </Tooltip.Root>
    );
  };

  return (
    <ul className="space-y-5">
      {plan.features.map((feature, i) => {
        return (
          <li key={i}>
            <div className="flex items-start space-x-2">
              {/* Tick */}
              {feature.hasTick && (
                <AccessibleIcon className="text-4xl text-green-500" label="ri-check-line" icon="ri-check-line" />
              )}

              {/* Description */}
              {feature.description ? (
                textWithTooltip(feature)
              ) : (
                <Text color="secondary" className="py-1 text-left">
                  {feature.name}
                </Text>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
