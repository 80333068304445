import { ReactNode } from 'react';

import AppLayout from '@/components/layouts/AppLayout';

export const TranslationMemoryLayout = ({ children }: { children?: ReactNode }) => {
  return (
    <AppLayout.Root>
      {/* Header */}
      <AppLayout.Navbar />

      {/* Content */}
      <div className="mx-auto flex justify-between">
        <div className="flex-grow">{children}</div>
      </div>
    </AppLayout.Root>
  );
};
