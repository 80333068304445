import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import Breadcrumb from '@/components/v2/Breadcrumb';
import { Button } from '@/components/v2/Button';
import { Skeleton } from '@/components/v2/Skeleton';
import { Title } from '@/components/v2/Title';

const SourceDocumentHeaderSkeleton = () => {
  const { t: tDocument } = useTranslation('document');

  return (
    <div>
      <Skeleton width="33%">
        <Breadcrumb.Root size="sm">
          <Breadcrumb.List>
            <Breadcrumb.Link>&nbsp;</Breadcrumb.Link>
          </Breadcrumb.List>
        </Breadcrumb.Root>
      </Skeleton>

      <div className="flex items-center justify-between gap-4 py-2">
        <Skeleton width="50%">
          <Title>&nbsp;</Title>
        </Skeleton>

        <div className="flex items-center gap-4">
          <Skeleton>
            <Button>
              {tDocument('manageDocument')}
              <AccessibleIcon label={tDocument('manageDocumentIcon')} icon="ri-arrow-down-s-line" />
            </Button>
          </Skeleton>

          <Skeleton>
            <Button>
              <AccessibleIcon label={tDocument('preTranslateIcon')} icon="ri-timer-flash-line" />
              {tDocument('preTranslate')}
            </Button>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
SourceDocumentHeaderSkeleton.displayName = 'SourceDocumentHeaderSkeleton';

export { SourceDocumentHeaderSkeleton };
