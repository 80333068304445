import type { RenderElementProps } from 'slate-react';
import { useFocused, useReadOnly, useSelected } from 'slate-react';

import { Placeholder } from '@/components/v1/Placeholder/v2';
import { EPlaceholderType } from '@/redux/api/constants';
import type { IPlaceholderElementV2 } from '@/types/slate';

export interface IPlaceholderElementProps extends RenderElementProps {
  element: IPlaceholderElementV2;
}

export const PlaceholderElement = ({ attributes, element, children }: IPlaceholderElementProps) => {
  const { placeholder, tag } = element;
  const color = placeholder.type === EPlaceholderType.MARKER ? 'blue' : 'orange';

  const selected = useSelected();
  const focused = useFocused();
  const readOnly = useReadOnly();
  const ring = !readOnly && selected && focused;

  return (
    <span {...attributes} contentEditable={false} className="mx-0.5">
      <ChromiumBugfix />

      {children}

      <Placeholder color={color} tag={tag} ring={ring}>
        {placeholder.label}
      </Placeholder>

      <ChromiumBugfix />
    </span>
  );
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const ChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);
