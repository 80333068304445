import { useEffectOnce } from './useEffectOnce';
import { useLatest } from './useLatest';

export const useUnmount = (callback: VoidFunction) => {
  const callbackRef = useLatest(callback);

  useEffectOnce(() => () => {
    callbackRef.current();
  });
};
