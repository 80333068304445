/**
 * in get folder endpoint, since the root folder ('document list') doesn't have an id, we provide a mocked one
 */
export const DOCUMENT_LIST_MOCK_ID = 'document-list';

/**
 * used to pass a void argument to a RTK query
 * @example useQuery(VOID, { ... })
 */
export const VOID: void = undefined;
