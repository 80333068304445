import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import AppLayout from '@/components/layouts/AppLayout';
import { RequireSubscriptionRoute } from '@/components/v2/RequireSubscriptionRoute';
import { Toaster, useToast } from '@/components/v2/Toast';
import Tooltip from '@/components/v2/Tooltip';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { OnboardingWizardProvider } from '@/providers/OnboardingWizardProvider';
import { pusherChannelsAvailable, PusherEvent } from '@/providers/PusherProvider/constants';
import { usePusherSubscribeToChannel } from '@/providers/PusherProvider/hooks/usePusherSubscribeToChannel';
import {
  Checkout,
  CheckoutBilling,
  CheckoutFlowLayout,
  CheckoutLayout,
  CheckoutPaymentMethod
} from '@/routes/Checkout';
import { CreateGlossary } from '@/routes/CreateGlossary';
import { CreateGlossaryEntry } from '@/routes/CreateGlossaryEntry';
import { DocumentDataCheck } from '@/routes/DocumentDataCheck';
import { DocumentLanguage } from '@/routes/DocumentLanguage';
import { DocumentList } from '@/routes/DocumentList';
import { DocumentRevision } from '@/routes/DocumentRevision';
import { ExportSegments } from '@/routes/ExportSegments/ExportSegments';
import { Glossary } from '@/routes/Glossary';
import { GlossaryDetail } from '@/routes/GlossaryDetail';
import { GlossaryUpdateEntry } from '@/routes/GlossaryUpdateEntry';
import { GlossaryUploadEntry } from '@/routes/GlossaryUploadEntry';
import { ImportSegmentsLayout } from '@/routes/ImportSegments/ImportSegmentsLayout';
import { ConfirmStep } from '@/routes/ImportSegments/steps/ConfirmStep';
import { ImportSegmentsUploadFile } from '@/routes/ImportSegments/steps/ImportSegmentsUploadFile';
import {
  LoaderDocument,
  LoaderGettingKnowToYou,
  LoaderPreTranslate,
  LoaderSetSourceLanguage,
  LoaderSetTargetLanguage,
  LoaderTranslateYourFirstDocument,
  LoaderUploadFile,
  LoaderWhatYouCanDo
} from '@/routes/loaders';
import { NotFound } from '@/routes/NotFound/NotFound';
import { OnboardingLayout } from '@/routes/Onboarding/Welcome';
import { OnboardingBootstrap } from '@/routes/Onboarding/Welcome/providers/bootstrap';
import { Settings } from '@/routes/Settings';
import { SettingsLayout } from '@/routes/Settings/components/SettingsLayout';
import { SettingsAdvancedSettings } from '@/routes/SettingsAdvancedSettings';
import { SettingsBilling } from '@/routes/SettingsBilling';
import { SettingsBillingData } from '@/routes/SettingsBillingData';
import { SettingsCurrentPlan } from '@/routes/SettingsCurrentPlan';
import { SettingsCurrentPlanSubscriptionHistory } from '@/routes/SettingsCurrentPlanSubscriptionHistory';
import { SettingsLanguages } from '@/routes/SettingsLanguages';
import { SettingsManageAutoRenewal } from '@/routes/SettingsManageAutoRenewal';
import { SettingsManagers } from '@/routes/SettingsManagers/SettingsManagers';
import { SettingsNotifications } from '@/routes/SettingsNotifications';
import { SettingsSegmentation } from '@/routes/SettingsSegmentation';
import { SettingsTranslators } from '@/routes/SettingsTranslators';
import { SetSourceLanguage, SetTargetLanguage, UploadDocumentProvider, UploadFile } from '@/routes/UploadDocuments';

import { usePusherBindToEvent } from './providers/PusherProvider';
import { redokunApi } from './redux/api';
import { useLazyExportTranslationMemoriesByOperationIdQuery } from './redux/api/translationMemoryApi';
import { removeOperationFromQueue } from './redux/slices/operationsSlice/operationsSlice';
import { OperationsKind } from './redux/slices/operationsSlice/types';
import { TAppState } from './redux/store/types';
import { SettingsChangePassword } from './routes/SettingsChangePassword';
import { SettingsDeleteAccount } from './routes/SettingsDeleteAccount';
import { SettingsPlans } from './routes/SettingsPlans';
import { TranslationMemory } from './routes/TranslationMemory';
import { TranslationMemoryLayout } from './routes/TranslationMemory/TranslationMemoryLayout';
import { TranslationMemoryUpload } from './routes/TranslationMemoryUpload';
import { TranslationMemoryConfirmUpload } from './routes/TranslationMemoryUpload/TranslationMemoryConfirmUpload';
import { TranslationMemoryUploadLayout } from './routes/TranslationMemoryUpload/TranslationMemoryUploadLayout';

export const App = () => {
  const { t: tGeneral } = useTranslation('general');
  const dispatch = useDispatch();
  const { toast } = useToast();
  const operations = useSelector((state: TAppState) => state.operations);
  const history = useHistory();

  const { currentAccount } = useCurrentAccount();
  const channelForSubscription = pusherChannelsAvailable.privateAccount(String(currentAccount.id));
  const { channel } = usePusherSubscribeToChannel(channelForSubscription);
  const [triggerExportTranslationMemoriesByOperationId] = useLazyExportTranslationMemoriesByOperationIdQuery();

  // Read all messages from Pusher's "operation:completed" events.
  // Use them in combination with the operations slice and its queue.
  usePusherBindToEvent<{ operationId: number }>(channel, PusherEvent.OPERATION_COMPLETED, (payload) => {
    const operationIndex = operations.queue.findIndex((operation) => {
      return operation.operationId === payload.operationId;
    });
    if (operationIndex !== -1) {
      const queueElement = operations.queue[operationIndex];
      toast({ title: queueElement.message, kind: 'success' });
      if (queueElement.kind === OperationsKind.DOWNLOAD_TM) {
        triggerExportTranslationMemoriesByOperationId({ operationId: payload.operationId });
      }
      if (queueElement.kind === OperationsKind.UPLOAD_TM) {
        dispatch(redokunApi.util.invalidateTags(['translationMemory']));
        history.push('/translation-memories');
      }
      dispatch(removeOperationFromQueue({ operationId: payload.operationId }));
    }
  });

  return (
    <>
      <Toaster />
      <Tooltip.Provider>
        <OnboardingWizardProvider>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/documents" />
            </Route>

            {/* Settings */}
            <Route path="/settings">
              <SettingsLayout>
                <Switch>
                  <Route path="/settings" exact>
                    <Settings />
                  </Route>
                  <Route path="/settings/translators" exact>
                    <SettingsTranslators />
                  </Route>
                  <Route path="/settings/managers" exact>
                    <SettingsManagers />
                  </Route>
                  <Route path="/settings/segmentation" exact>
                    <SettingsSegmentation />
                  </Route>
                  <Route path="/settings/notifications" exact>
                    <SettingsNotifications />
                  </Route>
                  <Route path="/settings/advanced" exact>
                    <SettingsAdvancedSettings />
                  </Route>
                  <Route path="/settings/languages" exact>
                    <SettingsLanguages />
                  </Route>
                  <Route path="/settings/current-plan" exact>
                    <SettingsCurrentPlan />
                  </Route>
                  <Route path="/settings/current-plan/subscription-history" exact>
                    <SettingsCurrentPlanSubscriptionHistory />
                  </Route>
                  <Route path="/settings/billing" exact>
                    <SettingsBilling />
                  </Route>
                  <Route path="/settings/billing/change" exact>
                    <SettingsBillingData />
                  </Route>
                  <Route path="/settings/billing/manage-auto-renewal" exact>
                    <SettingsManageAutoRenewal />
                  </Route>
                  <Route path="/settings/change-password" exact>
                    <SettingsChangePassword />
                  </Route>
                  <Route path="/settings/plans" exact>
                    <SettingsPlans />
                  </Route>
                  <Route path="/settings/delete-account" exact>
                    <SettingsDeleteAccount />
                  </Route>
                </Switch>
              </SettingsLayout>
            </Route>

            {/* Documents */}
            <Route path="/documents" exact>
              <DocumentList />
            </Route>
            <Route path="/documents/folder/:folderId?" exact>
              {({ match }) => {
                if (!match) {
                  return null;
                }

                const { params } = match;
                if (!params.folderId) {
                  return <Redirect to="/documents" />;
                }

                return <DocumentList />;
              }}
            </Route>
            <Route path="/documents/show/:documentId/translations/:languageId" exact>
              {({ match, location }) => {
                const params = match?.params;
                const documentId = Number(params?.documentId);
                const languageId = Number(params?.languageId);
                const searchParams = new URLSearchParams(location.search);
                const paragraphId = Number(searchParams.get('paragraphId'));
                const pagePagination = Number(searchParams.get('pagePagination'));
                const openComments = Boolean(searchParams.get('openComments'));
                return (
                  <DocumentLanguage
                    documentId={documentId}
                    languageId={languageId}
                    paragraphId={paragraphId}
                    pagePagination={pagePagination}
                    openComments={openComments}
                  />
                );
              }}
            </Route>
            <RequireSubscriptionRoute path="/documents/new" redirectTo="/documents">
              <UploadDocumentProvider multiple folderManagement skippableTargetLanguage>
                <Switch>
                  <Route path="/documents/new" exact>
                    <UploadFile />
                  </Route>
                  <Route path="/documents/new/source-language" exact>
                    <SetSourceLanguage />
                  </Route>
                  <Route path="/documents/new/target-language" exact>
                    <SetTargetLanguage />
                  </Route>
                </Switch>
              </UploadDocumentProvider>
            </RequireSubscriptionRoute>
            <Route path="/documents/show/:documentId">
              <DocumentDataCheck>
                <Switch>
                  <Route path="/documents/show/:documentId" exact>
                    <LoaderDocument />
                  </Route>
                  <Route path="/documents/show/:documentId/translations/:languageId/export-phrase/:translationId" exact>
                    <ExportSegments />
                  </Route>
                  <RequireSubscriptionRoute
                    path="/documents/show/:documentId/translations/:languageId/import-phrase"
                    redirectTo="/documents"
                  >
                    <ImportSegmentsLayout>
                      <Switch>
                        <Route path="/documents/show/:documentId/translations/:languageId/import-phrase" exact>
                          <ImportSegmentsUploadFile />
                        </Route>
                        <Route
                          path="/documents/show/:documentId/translations/:languageId/import-phrase/:batchId/confirm"
                          exact
                        >
                          <ConfirmStep />
                        </Route>
                      </Switch>
                    </ImportSegmentsLayout>
                  </RequireSubscriptionRoute>
                </Switch>
              </DocumentDataCheck>
            </Route>
            <RequireSubscriptionRoute path="/documents/update/:documentId" redirectTo="/documents" exact>
              <DocumentDataCheck>
                <DocumentRevision />
              </DocumentDataCheck>
            </RequireSubscriptionRoute>
            <RequireSubscriptionRoute path="/documents/pre-translate/:documentId" redirectTo="/documents">
              <DocumentDataCheck>
                <LoaderPreTranslate />
              </DocumentDataCheck>
            </RequireSubscriptionRoute>

            {/* Translation Memory */}
            <Route path="/translation-memories">
              <TranslationMemoryLayout>
                <Switch>
                  <Route path="/translation-memories" exact>
                    <TranslationMemory />
                  </Route>
                  <RequireSubscriptionRoute path="/translation-memories/upload" redirectTo="/translation-memories">
                    <TranslationMemoryUploadLayout>
                      <Switch>
                        <Route path="/translation-memories/upload" exact>
                          <TranslationMemoryUpload />
                        </Route>
                        <Route path="/translation-memories/upload/confirm" exact>
                          <TranslationMemoryConfirmUpload />
                        </Route>
                      </Switch>
                    </TranslationMemoryUploadLayout>
                  </RequireSubscriptionRoute>
                </Switch>
              </TranslationMemoryLayout>
            </Route>

            {/* Onboarding */}
            <Route path="/welcome/*">
              <OnboardingBootstrap>
                <OnboardingLayout>
                  <Switch>
                    <Route path="/welcome/survey">
                      <LoaderGettingKnowToYou />
                    </Route>
                    <Route path="/welcome/what-you-can-do" exact>
                      <LoaderWhatYouCanDo />
                    </Route>
                    <Route path="/welcome/translate-your-first-document" exact>
                      <LoaderTranslateYourFirstDocument />
                    </Route>
                  </Switch>
                </OnboardingLayout>
                <Route path="/welcome/documents/*">
                  <UploadDocumentProvider showSuggestions welcome>
                    <Switch>
                      <Route path="/welcome/documents/new" exact>
                        <LoaderUploadFile />
                      </Route>
                      <Route path="/welcome/documents/new/source-language" exact>
                        <LoaderSetSourceLanguage />
                      </Route>
                      <Route path="/welcome/documents/new/target-language" exact>
                        <LoaderSetTargetLanguage />
                      </Route>
                    </Switch>
                  </UploadDocumentProvider>
                </Route>
              </OnboardingBootstrap>
            </Route>

            {/* Glossary */}
            <Route path="/glossaries" exact>
              <Glossary />
            </Route>
            <RequireSubscriptionRoute
              path="/glossaries/show/:glossaryId/entry/:entryId/edit"
              redirectTo="/glossaries"
              exact
            >
              <GlossaryUpdateEntry />
            </RequireSubscriptionRoute>
            <RequireSubscriptionRoute path="/glossaries/show/:glossaryId/new-entry" redirectTo="/glossaries" exact>
              <CreateGlossaryEntry />
            </RequireSubscriptionRoute>
            <Route path="/glossaries/show/:glossaryId" exact>
              <GlossaryDetail />
            </Route>
            <RequireSubscriptionRoute path="/glossaries/new" redirectTo="/glossaries" exact>
              <CreateGlossary />
            </RequireSubscriptionRoute>
            <RequireSubscriptionRoute path="/glossaries/show/:glossaryId/upload" redirectTo="/glossaries" exact>
              <GlossaryUploadEntry />
            </RequireSubscriptionRoute>

            {/* Checkout */}
            <Route path="/checkout">
              <CheckoutLayout>
                <Switch>
                  <Route path="/checkout" exact>
                    <Checkout />
                  </Route>
                  <Route path={['/checkout/billing', '/checkout/payment-method']}>
                    <CheckoutFlowLayout>
                      <Switch>
                        <Route path="/checkout/billing" exact>
                          <CheckoutBilling />
                        </Route>
                        <Route path="/checkout/payment-method" exact>
                          <CheckoutPaymentMethod />
                        </Route>
                      </Switch>
                    </CheckoutFlowLayout>
                  </Route>
                </Switch>
              </CheckoutLayout>
            </Route>

            <Route path="/__debug__" exact>
              <Debug />
            </Route>

            {/* Fallback */}
            <Route path="*">
              <AppLayout.Root title={tGeneral('notFound.titleDefault')}>
                <AppLayout.Navbar />
                <AppLayout.Content>
                  <NotFound />
                </AppLayout.Content>
              </AppLayout.Root>
            </Route>
          </Switch>
        </OnboardingWizardProvider>
      </Tooltip.Provider>
    </>
  );
};

const Debug = () => {
  return (
    <div className="p-8">
      <button
        onClick={() => {
          throw new Error('Boom 💥');
        }}
      >
        Boom 💥
      </button>
    </div>
  );
};
