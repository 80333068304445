import { forEach } from 'lodash';
import type { NodeEntry } from 'slate';
import { Editor, Element, Transforms } from 'slate';

import type { IPlaceholderElementV2 } from '@/types/slate';

import { EElementType } from '../../../constants';

export const withPlaceholders = (editor: Editor) => {
  const { isInline, isVoid, normalizeNode } = editor;

  editor.isInline = (element) => element.type === EElementType.PLACEHOLDER_V2 || isInline(element);

  editor.isVoid = (element) => element.type === EElementType.PLACEHOLDER_V2 || isVoid(element);

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 1) {
      const entries = Array.from(
        Editor.nodes<IPlaceholderElementV2>(editor, {
          at: path,
          match: (node) =>
            Element.isElement(node) &&
            node.type === EElementType.PLACEHOLDER_V2 &&
            (node.tag === 'opening' || node.tag === 'closing')
        })
      );

      const previousEntries: Record<string, NodeEntry<IPlaceholderElementV2>> = {};
      forEach(entries, (entry) => {
        const [node, path] = entry;
        const { placeholder } = node;

        const previousEntry = previousEntries[placeholder.id];

        if (previousEntry) {
          const [, previousPath] = previousEntry;
          Transforms.setNodes(
            editor,
            { type: EElementType.PLACEHOLDER_V2, placeholder, tag: 'opening' },
            { at: previousPath }
          );
          Transforms.setNodes(editor, { type: EElementType.PLACEHOLDER_V2, placeholder, tag: 'closing' }, { at: path });
        } else {
          previousEntries[placeholder.id] = entry;
        }
      });
    }

    normalizeNode([node, path]);
  };

  return editor;
};
