import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableColumnDef } from '@/components/v2/DataTable';
import { DocumentTranslationDto } from '@/redux/api/types';

import { ActionsCell } from './components/ActionsCell';
import { LanguageCell } from './components/LanguageCell';
import { ProgressCell } from './components/ProgressCell';
import { StatusCell } from './components/StatusCell';
import { TranslatorsCell } from './components/TranslatorCell';

type UseColumnsProps = {
  documentId: number;
  canManageDocument: boolean;
};

const useColumns = (props: UseColumnsProps) => {
  const { documentId, canManageDocument } = props;

  const { t: tDocument } = useTranslation('document');

  const columns: DataTableColumnDef<DocumentTranslationDto>[] = useMemo(
    () => [
      // {
      //   id: 'checkbox',
      //   header: () => <DataTable.HeaderCheckbox />,
      //   cell: () => <DataTable.Checkbox />,
      //   meta: {
      //     width: '60px'
      //   }
      // },
      {
        accessorKey: 'language',
        header: tDocument('language'),
        cell: () => <LanguageCell documentId={documentId} />,
        meta: {
          width: '37%'
        }
      },
      {
        accessorKey: 'status',
        header: tDocument('status'),
        cell: () => <StatusCell />,
        meta: {
          width: '130px'
        }
      },
      {
        accessorKey: 'completedPercentage',
        header: tDocument('progress'),
        cell: () => <ProgressCell />,
        meta: {
          width: '26%'
        }
      },
      {
        accessorKey: 'translators',
        header: tDocument('translators'),
        cell: () => <TranslatorsCell />,
        meta: {
          width: '37%'
        }
      },
      {
        id: 'actions',
        // canUnlock only if is the owner or manager
        cell: () => <ActionsCell documentId={documentId} canUnlock={canManageDocument} />,
        meta: {
          width: '445px'
        }
      }
    ],
    [canManageDocument, documentId, tDocument]
  );

  return columns;
};

export { useColumns };
