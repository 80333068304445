import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Tabs } from '@/components/v1/Tabs';
import { APP_URL } from '@/config/urls';
import { BriefcaseIcon, TimesCircleIcon } from '@/icons/solid';
import { useChangeTmRecordVisibilityMutation } from '@/redux/api';

import type { ITMSuggestionMoreProps } from './types';

export const TMSuggestionMore = ({ suggestionId }: ITMSuggestionMoreProps) => {
  // TODO: loader
  // TODO: error handling, missing design
  const [changeTmRecordVisibility] = useChangeTmRecordVisibilityMutation();

  const { t } = useTranslation('documentLanguageTMComparison');

  return (
    <div>
      <Tabs>
        <Tabs.Tab tab={t('actions')} clickable={false}>
          <div className="flex flex-col items-start">
            <a
              href={`${APP_URL}/translation-memories/manager?gotoSuggestionId=${suggestionId}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button btnType="text" icon={<BriefcaseIcon />} size="sm">
                {t('viewSuggestionInTMManager')}
              </Button>
            </a>

            <Button
              btnType="text"
              icon={<TimesCircleIcon />}
              size="sm"
              onClick={() => {
                changeTmRecordVisibility({ suggestionId, body: { isHidden: true } });
              }}
            >
              {t('hideSuggestion')}
            </Button>
          </div>
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};
