import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import AppLayout from '@/components/layouts/AppLayout';
import { GlossaryDetailContent } from '@/routes/GlossaryDetail/components/GlossaryDetailContent';
import { GlossaryDetailHeader } from '@/routes/GlossaryDetail/components/GlossaryDetailHeader';

export const GlossaryDetail = () => {
  const { t: tGlossary } = useTranslation('glossary');
  const { glossaryId } = useGlossaryParams();

  return (
    <AppLayout.Root title={tGlossary('glossary')}>
      <AppLayout.Navbar />
      <AppLayout.Content>
        <GlossaryDetailHeader glossaryId={glossaryId} />
        <GlossaryDetailContent glossaryId={glossaryId} />
      </AppLayout.Content>
    </AppLayout.Root>
  );
};

const useGlossaryParams = () => {
  const params = useParams<{ glossaryId: string }>();
  const parsedParams = useMemo(() => ({ glossaryId: parseInt(params.glossaryId) }), [params]);
  return parsedParams;
};
