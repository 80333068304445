import { escapeRegExp as _escapeRegExp, isArray as _isArray, last as _last } from 'lodash';

export const highlight = ({ text, searchWord }: { text: string; searchWord: string | string[] }) => {
  const chunks = findChunks({ text, searchWord });
  return mergeChunks({ chunks });
};

const mergeChunks = ({ chunks }: { chunks: { start: number; end: number }[] }) =>
  [...chunks]
    .sort((a, b) => a.start - b.start)
    .reduce((mergedChunks, nextChunk) => {
      const lastMergedChunk = _last(mergedChunks);

      if (lastMergedChunk && nextChunk.start <= lastMergedChunk.end) {
        const start = lastMergedChunk.start;
        const end = Math.max(lastMergedChunk.end, nextChunk.end);

        return [...mergedChunks.slice(0, -1), { start, end }];
      }

      return [...mergedChunks, nextChunk];
    }, [] as { start: number; end: number }[]);

const findChunks = ({ text, searchWord }: { text: string; searchWord: string | string[] }) => {
  const searchWords = _isArray(searchWord) ? searchWord : [searchWord];

  return searchWords
    .filter((searchWord) => searchWord)
    .reduce((chunks, searchWord) => {
      const escapedSearchWord = _escapeRegExp(searchWord);
      const regex = new RegExp(escapedSearchWord, 'gi');

      let match;
      while ((match = regex.exec(text))) {
        const start = match.index;
        const end = regex.lastIndex;

        if (end > start) {
          chunks.push({ start, end });
        }

        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, [] as { start: number; end: number }[]);
};
