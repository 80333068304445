import 'flag-icons/css/flag-icons.min.css';
import 'remixicon/fonts/remixicon.css';

import './index.css';

import './i18n';
import './utils/delighted';

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { App } from './App';
import { Show } from './components/v2/Show';
import { DEV } from './config/app';
import { SENTRY_INIT, SENTRY_OPTIONS } from './config/sentry';
import { pusher } from './libs/pusher';
import { CurrentAccountProvider } from './providers/CurrentAccountProvider';
import { ModalsProvider } from './providers/ModalsProvider';
import { PusherProvider } from './providers/PusherProvider';
import { SentryProvider } from './providers/SentryProvider';
import { history, store } from './redux/store';
import { Beacon } from './utils/helpScout';

const { REACT_APP_HELP_SCOUT_BEACON_ID } = import.meta.env;

Beacon('init', REACT_APP_HELP_SCOUT_BEACON_ID);

ReactDOM.render(
  <React.StrictMode>
    <SentryProvider init={SENTRY_INIT} options={SENTRY_OPTIONS}>
      {/* Indicates the app is in development mode, helping developers distinguish it from production */}
      <Show when={DEV}>
        <div className="pointer-events-none fixed left-0.5 top-0.5 z-[9999] inline-flex rounded-full bg-[#ff0000] p-1 text-2xs font-bold leading-none text-white">
          DEV
        </div>
      </Show>

      <Provider store={store}>
        <ConnectedRouter history={history}>
          <HelmetProvider>
            <PusherProvider pusher={pusher}>
              {/* The modal provider must be outside the app tree, otherwise it will not catch session errors */}
              <ModalsProvider>
                <CurrentAccountProvider>
                  <App />
                </CurrentAccountProvider>
              </ModalsProvider>
            </PusherProvider>
          </HelmetProvider>
        </ConnectedRouter>
      </Provider>
    </SentryProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
