import { EXPORT_FORMATS, TExportFileFormats } from '@/redux/api/types';

export const FORMAT_OPTIONS: {
  id: TExportFileFormats;
  label: 'XLIFFOptionLabel' | 'ODSOptionLabel' | 'XLSXOptionLabel';
}[] = [
  { id: EXPORT_FORMATS.xliff, label: 'XLIFFOptionLabel' },
  { id: EXPORT_FORMATS.ods, label: 'ODSOptionLabel' },
  { id: EXPORT_FORMATS.xlsx, label: 'XLSXOptionLabel' }
];
