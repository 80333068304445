import type { ChildNode } from 'domhandler';
import { isTag } from 'domhandler';
import { textContent } from 'domutils';
import { parseDocument } from 'htmlparser2';
import { isArray, isNil, isNumber } from 'lodash';

import { getPlaceholderFromXmlAttribs } from '@/utils/placeholder';
import { highlight } from '@/utils/highlight';
import type { IGlossaryEntry, LanguageDto, TPlaceholder } from '@/redux/api/types';
import { EPlaceholderType } from '@/redux/api/constants';

import { MARKER_TEXT_MAP } from './constants';
import type { TNode } from './types';

export const parseContent = ({
  content,
  searchWord = [],
  glossaryEntries = [],
  targetLanguage
}: {
  content: string;
  searchWord?: string | string[];
  targetLanguage?: LanguageDto;
  glossaryEntries?: IGlossaryEntry[];
}) => {
  const contentNodes = parseDocument(content, { xmlMode: true }).children;
  const placeholderEntries = parsePlaceholderEntries({ contentNodes });

  let nodes: TNode[] = [{ type: 'TEXT', text: textContent(contentNodes) }];

  if (glossaryEntries?.length && targetLanguage) {
    nodes = insertGlossaryEntries(nodes, { glossaryEntries, targetLanguage });
  }

  nodes = insertPlaceholders(nodes, { placeholderEntries });

  const text = getNodesText(nodes);
  const chunksToHighlight = highlight({ text, searchWord });
  nodes = highlightNodes(nodes, chunksToHighlight);

  return nodes;
};

const parsePlaceholderEntries = ({ contentNodes }: { contentNodes: ChildNode[] }) =>
  contentNodes.reduce((placeholders, contentNode, contentNodeIndex) => {
    const placeholder = isTag(contentNode) ? getPlaceholderFromXmlAttribs(contentNode.attribs) : null;
    if (!placeholder) {
      return placeholders;
    }
    const offset = textContent(contentNodes.slice(0, contentNodeIndex)).length;
    return [...placeholders, { placeholder, offset }];
  }, [] as { placeholder: TPlaceholder; offset: number }[]);

const insertGlossaryEntries = (
  nodes: TNode[],
  { glossaryEntries, targetLanguage }: { targetLanguage: LanguageDto; glossaryEntries: IGlossaryEntry[] }
) =>
  glossaryEntries.reduce(
    (nodes, entry) =>
      entry.matchesOffsets.reduce((nodes, offset) => {
        const start = offset;
        const end = offset + entry.sourceContent.length;
        const text = getNodesText(nodes).substring(start, end);
        return insertNode(
          nodes,
          { type: 'GLOSSARY_ENTRY', glossaryEntry: entry, targetLanguage, children: [{ type: 'TEXT', text }] },
          { start, end }
        );
      }, nodes),
    nodes
  );

const insertPlaceholders = (
  nodes: TNode[],
  { placeholderEntries }: { placeholderEntries: { placeholder: TPlaceholder; offset: number }[] }
) =>
  placeholderEntries.reduce((nodes, entry, entryIndex, entries) => {
    const previousText = entries
      .slice(0, entryIndex)
      .reduce((text, { placeholder }) => text + (getPlaceholderText(placeholder) ?? ''), '');
    const text = getPlaceholderText(entry.placeholder);
    const at = entry.offset + previousText.length;
    return insertNode(
      nodes,
      { type: 'PLACEHOLDER', placeholder: entry.placeholder, children: text ? [{ type: 'TEXT', text }] : [] },
      at
    );
  }, nodes);

const getPlaceholderText = (placeholder: TPlaceholder) => {
  if (placeholder.type === EPlaceholderType.MARKER) {
    return MARKER_TEXT_MAP[placeholder.code] ?? null;
  }
  return null;
};

const insertNode = (nodes: TNode[], node: TNode, at?: number | { start: number; end: number }): TNode[] => {
  if (isNil(at)) {
    return [...nodes, node];
  }

  const { start, end } = isNumber(at) ? { start: at, end: at } : at;

  let index = 0;
  let offset = 0;
  while (index < nodes.length && offset + getNodeText(nodes[index]).length <= start) {
    offset += getNodeText(nodes[index]).length;
    index++;
  }

  if (index >= nodes.length) {
    return [...nodes, node];
  }

  const currentNode = nodes[index];
  const offsetStart = start - offset;
  const offsetEnd = end - offset;

  const beforeNodes = nodes.slice(0, index);
  const afterNodes = nodes.slice(index + 1);

  if (currentNode.type === 'TEXT') {
    const beforeText = currentNode.text.slice(0, offsetStart);
    const beforeNode = { type: 'TEXT' as const, text: beforeText };

    const afterText = currentNode.text.slice(offsetEnd);
    const afterNode = { type: 'TEXT' as const, text: afterText };

    return [...beforeNodes, beforeNode, node, afterNode, ...afterNodes];
  }

  if ('children' in currentNode && isArray(currentNode.children) && offsetStart > 0) {
    const children = insertNode(currentNode.children, node, { start: offsetStart, end: offsetEnd });
    const updatedNode = { ...currentNode, children };
    return [...beforeNodes, updatedNode, ...afterNodes];
  }

  return [...beforeNodes, node, currentNode, ...afterNodes];
};

const highlightNodes = (nodes: TNode[], chunks: { start: number; end: number }[]) => {
  let currentIndex = 0;
  const highlightedNodes: TNode[] = [];

  nodes.forEach((node) => {
    if (node.type === 'TEXT') {
      const nodeHighlightedChunks: { start: number; end: number }[] = [];

      chunks.forEach((chunk) => {
        const textLength = node.text.length;
        const nodeStart = currentIndex;
        const nodeEnd = currentIndex + textLength;

        if (nodeStart < chunk.end && nodeEnd > chunk.start) {
          const highlightStart = Math.max(chunk.start - nodeStart, 0);
          const highlightEnd = Math.min(chunk.end - nodeStart, textLength);

          nodeHighlightedChunks.push({
            start: highlightStart,
            end: highlightEnd
          });
        }
      });

      if (nodeHighlightedChunks.length > 0) {
        let lastHighlightEnd = 0;

        nodeHighlightedChunks.forEach((chunk) => {
          if (chunk.start > lastHighlightEnd) {
            highlightedNodes.push({
              type: 'TEXT',
              text: node.text.substring(lastHighlightEnd, chunk.start)
            });
          }

          highlightedNodes.push({
            type: 'TEXT',
            text: node.text.substring(chunk.start, chunk.end),
            highlight: true
          });

          lastHighlightEnd = chunk.end;
        });

        if (lastHighlightEnd < node.text.length) {
          highlightedNodes.push({
            type: 'TEXT',
            text: node.text.substring(lastHighlightEnd)
          });
        }
      } else {
        highlightedNodes.push(node);
      }

      currentIndex += node.text.length;
    } else if ('children' in node && isArray(node.children)) {
      highlightedNodes.push({
        ...node,
        children: highlightNodes(
          node.children,
          chunks.map((r) => ({ ...r, start: r.start - currentIndex, end: r.end - currentIndex }))
        )
      });

      currentIndex += getNodeText(node).length;
    } else {
      highlightedNodes.push(node);
    }
  });

  return highlightedNodes;
};

const getNodeText = (node: TNode): string => {
  if (node.type === 'TEXT') {
    return node.text;
  }

  if ('children' in node && isArray(node.children)) {
    return node.children.reduce((acc, child) => acc + getNodeText(child), '');
  }

  return '';
};

const getNodesText = (nodes: TNode[]): string => nodes.reduce((acc, node) => acc + getNodeText(node), '');
