import { Text } from '@/components/v2/Text';

type FAQBlockProps = {
  title: string;
  text: string | React.ReactNode;
};

export const FAQBlock = ({ title, text }: FAQBlockProps) => {
  return (
    <div className="space-y-2">
      <Text color="primary" size="lg" weight="bold">
        {title}
      </Text>
      <Text color="secondary">{text}</Text>
    </div>
  );
};
