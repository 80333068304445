import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Input } from '@/components/v2/Input';
import { Select, SelectItem } from '@/components/v2/Select';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { useGetOnboardingSurveyInfoQuery, useSaveOnboardingSurveyInfoMutation } from '@/redux/api';
import { IAnswer, IQuestion } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { onboardingSteps } from '@/routes/Onboarding/Welcome/constants';
import { EOnboardingSteps } from '@/routes/Onboarding/types';

type TFormData = Record<IQuestion['questionId'], IAnswer['id']>;

export const GettingKnowToYou: React.FC = () => {
  const { t } = useTranslation('uploadDocuments');
  const history = useHistory();

  const { data } = useGetOnboardingSurveyInfoQuery();
  const [saveSurvey] = useSaveOnboardingSurveyInfoMutation();

  const questions = data?.onboardingSurvey.questions;

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { isValid }
  } = useForm<TFormData>();

  const onSubmit: SubmitHandler<Record<string, string>> = async (data) => {
    const [err] = await to(saveSurvey({ body: data }).unwrap());

    const step = onboardingSteps.find((s) => s.id === EOnboardingSteps.WHAT_YOU_CAN_DO);
    if (!err && step) {
      history.push(step.path);
    }
  };

  const isDisabled = !isValid;

  return (
    <div className="flex flex-col items-center pb-8">
      <div className="mt-16 max-w-xl items-center">
        <Title size="lg" weight="bold" className="mb-4 text-start leading-9">
          {t('welcomeToRedokun')}
        </Title>
        <Text size="lg" className="leading-8" weight="regular">
          {t('wereHereToHelpYouSpeedUpYourDocumentTranslation')}
        </Text>
        <div className="mt-6 flex flex-col">
          <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
            {questions?.map(({ allowsExplicit, answers, questionId, questionLabel }) => {
              const showInput = watch(questionId) === 'other' && allowsExplicit;
              return (
                <div key={questionId}>
                  <Text size="sm" weight="regular" className="mb-4">
                    {questionLabel}
                  </Text>
                  <div className="flex flex-col gap-2">
                    <Controller
                      key={questionId}
                      name={questionId}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div className="flex flex-col gap-2">
                          <Select
                            key={`select-${questionId}`}
                            onChange={onChange}
                            value={value}
                            placeholder={t('selectOption')}
                            bordered
                          >
                            {answers.map((item) => (
                              <SelectItem value={item.id} key={item.id}>
                                {item.label}
                              </SelectItem>
                            ))}
                          </Select>
                        </div>
                      )}
                    />
                    {showInput && (
                      <Input
                        {...register(`${questionId}Explicit`, {
                          required: true,
                          shouldUnregister: true
                        })}
                        placeholder={t('pleaseSpecify')}
                      />
                    )}
                  </div>
                </div>
              );
            })}
            <Button type="submit" variant="solid" color="primary" size="md" className="ml-auto" disabled={isDisabled}>
              {t('next')}
              <AccessibleIcon label="arrow-right" icon="ri-arrow-right-line" />
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
