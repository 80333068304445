import { TFunction } from 'react-i18next';

import Avatar, { AvatarGroup } from '@/components/v2/Avatar';
import { DataTableColumnDef } from '@/components/v2/DataTable';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { LanguageDto, LanguagesDto, TranslatorDto, TranslatorDtoStatuses } from '@/redux/api/types';

import { getFallbackName } from '../Document/utils';
import { Colors, StatusLabels } from '../Settings/types';
import { EditAssignedLanguagesCell } from './components/EditAssignedLanguagesCell';
import { SettingsTranslatorsTableActionsCell } from './components/SettingsTranslatorsTableActionsCell';

export function createColumns(
  tSettings: TFunction<'settings'>,
  currentUserId: number,
  languagesFlagsData: {
    [key: number]: LanguageDto;
  },
  languagesData?: LanguagesDto
) {
  const columns: DataTableColumnDef<TranslatorDto>[] = [
    {
      id: 'translator',
      accessorKey: 'item.translator',
      header: () => tSettings('translator'),
      cell: ({ row }) => {
        const { fullName, email, avatarUrl } = row.original;

        return (
          <div className="flex items-center gap-2">
            <AvatarGroup size="xs">
              <Avatar.Root>
                <Avatar.Image src={avatarUrl} />
                <Avatar.Fallback>{getFallbackName(fullName)}</Avatar.Fallback>
              </Avatar.Root>
            </AvatarGroup>

            {fullName ? (
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <Text size="sm" weight="medium" color="primary">
                    {fullName ? fullName : email}
                  </Text>
                </Tooltip.Trigger>
                <Tooltip.Content side="top">
                  <Text className=" text-typography-inverse-secondary" size="xs">
                    {email}
                  </Text>
                  <Tooltip.Arrow />
                </Tooltip.Content>
              </Tooltip.Root>
            ) : (
              <Text size="sm" weight="medium" color="primary" className="w-52 break-words">
                {email}
              </Text>
            )}
          </div>
        );
      }
    },
    {
      id: 'inviter',
      accessorKey: 'item.inviter',
      header: () => tSettings('inviter'),
      cell: ({ row }) => {
        const { fullName, id, avatarUrl } = row.original.inviter;

        return (
          <div className="flex items-center gap-2">
            <AvatarGroup size="xs">
              <Avatar.Root>
                <Avatar.Image src={avatarUrl} />
                <Avatar.Fallback>{getFallbackName(fullName)}</Avatar.Fallback>
              </Avatar.Root>
            </AvatarGroup>
            <Text size="sm" weight="medium" color="primary">
              {currentUserId === id ? tSettings('you') : fullName ? fullName : id}
            </Text>
          </div>
        );
      }
    },
    {
      id: 'status',
      accessorKey: 'item.status',
      header: () => tSettings('status'),
      cell: ({ row }) => {
        let color = Colors.neutral;
        let label = StatusLabels.statusCreated;

        switch (row.original.status) {
          case TranslatorDtoStatuses.created:
            color = Colors.neutral;
            label = StatusLabels.statusCreated;
            break;
          case TranslatorDtoStatuses.activated:
            color = Colors.positive;
            label = StatusLabels.statusActivated;
            break;
          default:
            color = Colors.neutral;
            label = StatusLabels.statusCreated;
            break;
        }
        return (
          <div>
            <Tag color={color}>{tSettings(label)}</Tag>
          </div>
        );
      }
    },
    {
      id: 'languages',
      accessorKey: 'item.languges',
      header: () => tSettings('languages'),
      cell: ({ row }) => {
        const countAssignedLanguages = row.original.assignedLanguagesIds.length;
        return (
          <div className="flex items-center space-x-2">
            {row.original.assignedLanguagesIds.map((languageId, key) => {
              if (languagesFlagsData[languageId]) {
                const { name, flagCode } = languagesFlagsData[languageId];
                if (key === 0) {
                  return (
                    <div key={key} className="flex items-center space-x-2">
                      <FlagIcon label={name} code={flagCode || 'xx'} size="md" />
                      <p>{name}</p>
                    </div>
                  );
                }
                return null;
              }
              return null;
            })}
            {countAssignedLanguages > 1 ? <Tag>{countAssignedLanguages - 1} other</Tag> : null}
          </div>
        );
      }
    },
    {
      id: 'editLanguages',
      cell: ({ row }) => (
        <EditAssignedLanguagesCell
          translator={row.original}
          languagesFlagsData={languagesFlagsData}
          languagesData={languagesData}
        />
      )
    },
    {
      id: 'actions',
      cell: ({ row }) => <SettingsTranslatorsTableActionsCell translator={row.original} />
    }
  ];

  return columns;
}
