import Pusher from 'pusher-js';

import { PUSHER_APP_KEY, PUSHER_OPTIONS } from '@/config/pusher';

// https://github.com/pusher/pusher-js/issues/471
// Request: ability to set xmlHttpRequest.withCredentials
Pusher.Runtime.createXHR = () => {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr as any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

Pusher.logToConsole = false;

const configurePusher = () => {
  const pusher = new Pusher(PUSHER_APP_KEY, PUSHER_OPTIONS);

  return pusher;
};

const pusher = configurePusher();

export { pusher };
