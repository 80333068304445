import { DataTableColumnDef } from '@/components/v2/DataTable';
import { Skeleton } from '@/components/v2/Skeleton';
import { GlossaryDto } from '@/redux/api/types';
import DataTable from '@/components/v2/DataTable';
import { DataTableLayout } from '@/routes/Glossary/components/GlossaryList/components/DataTableLayout';

interface SkeletonGlossaryListProps {
  columns: DataTableColumnDef<GlossaryDto, unknown>[];
}

export const SkeletonGlossaryList = ({ columns }: SkeletonGlossaryListProps) => {
  return (
    <div className="flex flex-col gap-6">
      {/* glossary title */}
      <div className="flex flex-row justify-between">
        <Skeleton width="5.5rem" height="2.25rem" />
        <div className="flex flex-row items-center gap-4">
          <Skeleton width="20rem" height="2.25rem" />
          <Skeleton width="10rem" height="2.25rem" />
        </div>
      </div>
      <DataTableLayout>
        <DataTable.Root columns={columns} data={4} loading>
          <DataTable.Content layout="fixed">
            <DataTable.Header />
            <DataTable.Body />
          </DataTable.Content>
        </DataTable.Root>
      </DataTableLayout>
    </div>
  );
};
