import { Editor, Text } from 'slate';

export const editorIsEmpty = (editor: Editor): boolean => {
  const { children } = editor;

  return children.every((child) => {
    if (Text.isText(child)) {
      return !child.text;
    }

    return Editor.isEmpty(editor, child);
  });
};
