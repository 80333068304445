import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import Tooltip from '@/components/v2/Tooltip';
import { HELP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

import { InviteTranslatorForm } from './components/InviteTranslatorForm';
import { TranslatorsTable } from './components/TranslatorsTable';

export const SettingsTranslators: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');

  const { currentAccount } = useCurrentAccount();

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settings-translators')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('translators')}
          </Text>

          <Button variant="text" color="primary" asChild>
            <a href={`${HELP_URL}/category/44-translator`} target="_blank" rel="noreferrer">
              <AccessibleIcon className="text-xl" label="ri-lightbulb-fill" icon="ri-lightbulb-fill" />
              {tSettings('whatCanATranslatorDo')}
            </a>
          </Button>
        </div>

        {/* Invitation 50% */}
        <div className="grid grid-cols-2">
          <div className="space-y-5 rounded-xl border bg-white p-7">
            <div className="flex items-center space-x-4">
              <Text size="md" color="primary" className="font-bold">
                {tSettings('inviteNewTranslator')}
              </Text>
              <div>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <AccessibleIcon
                      className="cursor-help text-xl text-blue-600 hover:text-blue-800"
                      label="ri-information-2-line"
                      icon="ri-information-2-line"
                    />
                  </Tooltip.Trigger>
                  <Tooltip.Content side="right" className="flex max-w-xxs flex-col gap-1">
                    {tSettings('anInviteWillBeSentImmediatelyToTheSpecifiedEmailAddress')}
                    <Tooltip.Arrow />
                  </Tooltip.Content>
                </Tooltip.Root>
              </div>
            </div>
            <InviteTranslatorForm />
            <div>
              <Button variant="text" color="primary" className="mt-7" asChild>
                <a href={`${HELP_URL}/article/21-adding-a-translator-to-your-account`} target="_blank" rel="noreferrer">
                  <AccessibleIcon className="text-sm" label="ri-lightbulb-fill" icon="ri-lightbulb-fill" />
                  <Text size="sm" weight="semibold" className="text-primary-active hover:text-primary-hoverHard">
                    {tSettings('learnMoreAboutAddingATranslatorToYourTeam')}
                  </Text>
                </a>
              </Button>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="relative rounded-xl bg-white p-7">
          <TranslatorsTable currentAccount={currentAccount} />
        </div>
      </div>
    </div>
  );
};
