import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { APP_URL } from '@/config/urls';

export const NotEnoughWordsModal = (props: ModalProps) => {
  const { children, ...modalProps } = props;

  const { t: tModals } = useTranslation('modals');

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="lg">
        <Modal.Header>
          <AccessibleIcon
            icon="ri-information-2-fill"
            label="ri-information-2-fill"
            className="self-start text-4xl text-primary-active"
          />
          <Modal.Title size="lg">{tModals('notEnoughWordsLeft')}</Modal.Title>
          <Modal.Description size="md">{tModals('yourWordBalanceDoesntCoverDownload')}</Modal.Description>
          <Modal.Description size="md">{tModals('pleaseConsiderUpgradingYourPlan')}</Modal.Description>
        </Modal.Header>

        <Modal.Footer className="flex flex-row gap-4">
          <Modal.Close>
            <Button color="secondary" variant="surface" className="w-full">
              {tModals('goBack')}
            </Button>
          </Modal.Close>
          <Button color="primary" className="w-full" asChild>
            <a href={`${APP_URL}/account/subscription`}>{tModals('getMoreWords')}</a>
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
