import { Slot } from '@radix-ui/react-slot';
import { cva } from 'cva';
import { forwardRef } from 'react';

import { classNames } from '@/utils/classNames';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

import { BaseStepProps } from './types';

const baseStepVariant = cva('flex h-6 w-6 min-w-6 items-center justify-center rounded-full', {
  variants: {
    status: {
      active: 'h-8 w-8 min-w-8 bg-primary-active',
      disabled: 'bg-gray-200',
      checked: 'bg-primary-active'
    }
  }
});

export const BaseStep = forwardRef<HTMLDivElement, BaseStepProps>((props, ref) => {
  const { className, status, asChild, ...rest } = props;
  const Comp = asChild ? Slot : 'div';

  const renderItem = (status: BaseStepProps['status']) => {
    switch (status) {
      case 'active':
        return <AccessibleIcon label="checkbox" icon="ri-checkbox-blank-circle-fill" className="h-4 w-4 text-white" />;
      case 'checked':
        return <AccessibleIcon label="check" icon="ri-check-line" className="h-2 w-2 text-white" />;
      case 'disabled':
        return (
          <AccessibleIcon label="checkbox" icon="ri-checkbox-blank-circle-line" className="h-2 w-2 text-gray-400" />
        );
      default:
        break;
    }
  };

  return (
    <Comp ref={ref} className={classNames(baseStepVariant({ status }), className)} {...rest}>
      {renderItem(status)}
    </Comp>
  );
});

BaseStep.displayName = 'BaseStep';
