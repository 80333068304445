import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Text } from '@/components/v2/Text';
import { Input } from '@/components/v2/Input';
import { Button } from '@/components/v2/Button';
import { Label } from '@/components/v2/Label';
import { useCrateTeamMutation } from '@/redux/api/teamApi';
import { to } from '@/utils/awaitToJs';
import { useToast } from '@/components/v2/Toast';
import { Show } from '@/components/v2/Show';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

import { Box } from './Box';

export const CreateTeam = () => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();
  const { currentAccount } = useCurrentAccount();

  const [createTeamTrigger] = useCrateTeamMutation();

  const formSchemaCreateTeam = z.object({
    teamName: z.string().min(1)
  });

  const {
    handleSubmit: createTeamHandleSubmit,
    control: createTeamControl,
    reset: createTeamFormReset
  } = useForm<z.infer<typeof formSchemaCreateTeam>>({
    resolver: zodResolver(formSchemaCreateTeam),
    defaultValues: {
      teamName: ''
    }
  });

  const onSubmitCreateTeam = async ({ teamName }: z.infer<typeof formSchemaCreateTeam>) => {
    const [err, res] = await to(createTeamTrigger({ teamName }).unwrap());
    if (!err && res.id) {
      toast({ title: tSettings('createTeamSuccessToastTitle'), kind: 'success' });
    }
    createTeamFormReset();
  };

  // Render only if the user isn't part of a team
  return (
    <Show when={!currentAccount.subscriptionInfo.isInATeam}>
      <Box>
        <div className="flex items-center space-x-4">
          <Text size="md" color="primary" className="font-bold">
            {tSettings('createATeam')}
          </Text>
        </div>
        <div>
          <form onSubmit={createTeamHandleSubmit(onSubmitCreateTeam)}>
            <div className="space-y-4">
              <Label htmlFor="teamName" isRequired text={tSettings('teamName')}></Label>
              <div className="flex items-center space-x-3">
                <Controller
                  name="teamName"
                  control={createTeamControl}
                  render={({ field }) => {
                    return (
                      <Input
                        type="text"
                        name="teamName"
                        onChange={field.onChange}
                        value={field.value}
                        placeholder={tSettings('typeDepartmentOrCompanyName')}
                      />
                    );
                  }}
                />
                <Button type="submit">{tSettings('createTeam')}</Button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Show>
  );
};
