import { Trans, useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { useToast } from '@/components/v2/Toast';
import { useDeleteSubscriptionCancellationMutation } from '@/redux/api/subscriptionApi';
import { CurrentAccountDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { standardDateTimeFormat } from '@/utils/date';

type ReactivateAutoRenewalProps = {
  currentAccount: CurrentAccountDto;
  isRenewingState: {
    isRenewing: boolean;
    setIsRenewing: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export const ReactivateAutoRenewal = ({ currentAccount, isRenewingState }: ReactivateAutoRenewalProps) => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const [deleteCancellationTrigger, deleteCancellationStatus] = useDeleteSubscriptionCancellationMutation();

  const handleReactivateClick = async () => {
    const [err, data] = await to(deleteCancellationTrigger().unwrap());
    if (!err && data.status === 'ok') {
      toast({
        title: tSettings('autoRenewalActivated'),
        description: tSettings('yourSubscriptionWillAutomaticallyRenewOn', {
          date: standardDateTimeFormat(currentAccount.subscriptionInfo.currentSubEndAt)
        }),
        kind: 'success'
      });
      // Set to true because we expect that the auto-renew is enabled
      isRenewingState.setIsRenewing(true);
    }
  };

  return (
    <div className="mt-6 space-y-6">
      <div className="space-y-2">
        <Text color="secondary">
          <Trans
            i18nKey="settings:yourCurrentSubscriptionWillEndOnAndWontAutomaticallyRenew"
            values={{
              date: standardDateTimeFormat(currentAccount.subscriptionInfo.currentSubEndAt)
            }}
            components={{ strong: <strong /> }}
          />
        </Text>
        <Text color="secondary">
          {tSettings('ifYouReactivateAutoRenewalYourSubscriptionWillContinueWithoutInterruptionThereafter', {})}
        </Text>
      </div>

      <Button onClick={handleReactivateClick} disabled={deleteCancellationStatus.isLoading}>
        {deleteCancellationStatus.isLoading ? <Spinner size="sm" /> : null}
        {tSettings('reactivateAutoRenewal', {})}
      </Button>
    </div>
  );
};
