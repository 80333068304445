import type { ChangeEvent, FunctionComponent } from 'react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { Checkbox } from '@/components/v1/Checkbox';
import { Modal } from '@/components/v1/Modal';
import type { TConfirmModalProps } from '@/components/v1/Modal/types';
import { Show } from '@/components/v1/Show';
import { useChangeLanguageStatusMutation, useGetBootstrapDataQuery } from '@/redux/api';
import { TranslationStatus } from '@/redux/api/constants';
import type { IConfirmTranslationModalProps } from '@/redux/slices/modalsSlice/types/props';
import { to } from '@/utils/awaitToJs';

export const ConfirmTranslationModal: FunctionComponent<
  TConfirmModalProps & { props: IConfirmTranslationModalProps }
> = ({ props: { documentId, languageId }, ...modalProps }) => {
  const { t } = useTranslation('modals');

  const { data: bootstrapData, isLoading: isBootstrapDataLoading } = useGetBootstrapDataQuery({
    documentId,
    languageId
  });
  const [changeLanguageStatus, { isLoading: isChangeLanguageStatusLoading, isSuccess: isChangeLanguageStatusSuccess }] =
    useChangeLanguageStatusMutation();
  const [doConfirmDraftTranslations, setDoConfirmDraftTranslations] = useState(false);

  const segmentsTotalCount = bootstrapData?.segmentsTotalCount ?? 0;
  const segmentsTranslatedCount = bootstrapData?.segmentsTranslatedCount ?? 0;
  const segmentsDraftCount = bootstrapData?.segmentsDraftCount ?? 0;
  const segmentsWithGlossaryWarnings = bootstrapData?.segmentsWithGlossaryWarnings ?? 0;
  const segmentsEmptyCount = segmentsTotalCount - segmentsTranslatedCount;
  const hasWarnings = !!segmentsEmptyCount || !!segmentsWithGlossaryWarnings || !!segmentsDraftCount;
  const okLoading = isChangeLanguageStatusLoading || isChangeLanguageStatusSuccess;

  const handleModalOk = async () => {
    await to(
      changeLanguageStatus({
        documentId,
        languageId,
        body: { status: TranslationStatus.CONFIRMED, doConfirmDraftTranslations }
      })
    );
    modalProps.onClose(false);
  };

  const handleDoConfirmDraftTranslationsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoConfirmDraftTranslations(e.target.checked);
  };

  return (
    <Modal.Confirm
      loading={isBootstrapDataLoading}
      cancelText={t('close')}
      okText={
        <Show when={hasWarnings} fallback={t('confirm')}>
          <Show when={doConfirmDraftTranslations} fallback={t('ignoreAndConfirm')}>
            {t('applyChangesAndConfirm')}
          </Show>
        </Show>
      }
      okLoading={okLoading}
      onOk={handleModalOk}
      {...modalProps}
    >
      {{
        header: t('confirmDocumentTranslation'),
        content: (
          <div className="space-y-4">
            <p>
              <Trans
                i18nKey="modals:afterConfirmationTranslationsWillNotBeEditable"
                components={{ 1: <b className="font-bold" /> }}
              />
            </p>

            <Show when={segmentsEmptyCount}>
              <Alert
                type="warning"
                message={
                  <Trans
                    i18nKey="modals:someTranslationBoxesAreEmptyTheOriginalPhraseWillBeDeleted"
                    components={{ 1: <b className="font-bold" /> }}
                    values={{ segmentsEmptyCount }}
                  />
                }
              />
            </Show>

            <Show when={segmentsWithGlossaryWarnings}>
              <Alert
                type="warning"
                message={
                  <Trans
                    i18nKey="modals:someTranslationsHaveGlossaryWarningsCloseAndUseFilter"
                    components={{ 1: <b className="font-bold" /> }}
                    values={{ segmentsWithGlossaryWarnings }}
                  />
                }
              />
            </Show>

            <Show when={segmentsDraftCount}>
              <Alert
                type="warning"
                message={
                  <div className="space-y-2">
                    <p>
                      <Trans
                        i18nKey="modals:someTranslationsInDraftModeDraftTranslationsNotAddedToTranslationMemories"
                        components={{ 1: <b className="font-bold" /> }}
                        values={{ segmentsDraftCount }}
                      />
                    </p>

                    <Checkbox checked={doConfirmDraftTranslations} onChange={handleDoConfirmDraftTranslationsChange}>
                      {t('checkThisOptionToChangeAllTranslationsFromDraftToConfirmed', { segmentsDraftCount })}
                    </Checkbox>
                  </div>
                }
              />
            </Show>
          </div>
        )
      }}
    </Modal.Confirm>
  );
};
