import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { ComponentPropsWithoutRef } from 'react';

import { stripe as stripePromise } from '../stripe';

const Elements = (props: ElementsProps) => {
  const { stripe = stripePromise, ...elementsProps } = props;

  return <StripeElements stripe={stripe} {...elementsProps} />;
};
Elements.displayName = 'Elements';

type StripeElementsProps = ComponentPropsWithoutRef<typeof StripeElements>;
type ElementsProps = Omit<StripeElementsProps, 'stripe'> & Partial<Pick<StripeElementsProps, 'stripe'>>;

export { Elements };
export type { ElementsProps };
