import { sumBy } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import CollapsibleInlineMessage from '@/components/v2/CollapsibleInlineMessage';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { JobInvitesDto } from '@/redux/api/types';

interface MultiplePendingJobInvitationProps {
  pendingJobInvites: JobInvitesDto[];
  isLoading?: boolean;
  onConfirm: (invitationId: number) => void;
}

/**
 * Collapsible inline message for multiple invitations
 * For the future: implement batch invitation logic
 */
const MultiplePendingJobInvitation = ({
  pendingJobInvites,
  isLoading,
  onConfirm
}: MultiplePendingJobInvitationProps) => {
  const { t: tDocumentList } = useTranslation('documentList');
  const [open, setOpen] = useState(false);
  const pendingInvitationCount = sumBy(pendingJobInvites, (invite) => (invite.jobs ? invite.jobs.length : 0));

  return (
    <CollapsibleInlineMessage.Root className="mt-6" open={open} onOpenChange={setOpen}>
      <Show
        when={!isLoading}
        fallback={
          <div className="flex h-14 justify-center">
            <Spinner size="lg" />
          </div>
        }
      >
        <CollapsibleInlineMessage.Header>
          <AccessibleIcon className="text-2xl text-primary-active" label="feedback-icon" icon="ri-feedback-fill" />
          <CollapsibleInlineMessage.Title>
            {tDocumentList('youHavePendingInvitations', { count: pendingInvitationCount })}
          </CollapsibleInlineMessage.Title>
          <CollapsibleInlineMessage.Trigger asChild>
            <Button variant="surface" color="secondary" size="sm" className="ml-auto">
              {tDocumentList(open ? 'hideAll' : 'showAll')}
            </Button>
          </CollapsibleInlineMessage.Trigger>
        </CollapsibleInlineMessage.Header>
        <CollapsibleInlineMessage.Content>
          {pendingJobInvites.map(({ id, inviter, jobs }) => {
            const areDocumentNamesEqual = jobs.every((job) => job.documentName === jobs[0].documentName);
            const areLanguageNamesEqual = jobs.every((job) => job.targetLanguageName === jobs[0].targetLanguageName);
            const areMixedDocumentsAndLanguages = !areDocumentNamesEqual && !areLanguageNamesEqual;
            const isBatchInvitation = jobs.length > 1;

            if (!isBatchInvitation) {
              return (
                <div key={id} className="flex flex-row justify-between">
                  <Text size="sm" weight="medium" className="text-blue-700">
                    <Trans
                      i18nKey="documentList:invitedYouToTranslateDocumentToLanguage"
                      values={{
                        user: inviter.fullName,
                        document: jobs[0].documentName,
                        language: jobs[0].targetLanguageName
                      }}
                      components={{ 1: <span className="font-bold"></span> }}
                    />
                  </Text>
                  <Button variant="text" color="primary" size="sm" onClick={() => onConfirm(id)}>
                    {tDocumentList('accept')}
                  </Button>
                </div>
              );
            }

            return (
              <div key={id} className="flex flex-row justify-between">
                <Text size="sm" weight="medium" className="text-blue-700">
                  <Show
                    when={!areMixedDocumentsAndLanguages}
                    fallback={
                      <Trans
                        i18nKey="documentList:invitedYouToTranslateCountDocuments"
                        values={{
                          user: inviter.fullName,
                          count: jobs.length
                        }}
                        components={{ 1: <span className="font-bold"></span> }}
                      />
                    }
                  >
                    <Show when={areLanguageNamesEqual}>
                      <Trans
                        i18nKey="documentList:invitedYouToTranslateCountDocumentToLanguage"
                        values={{
                          user: inviter.fullName,
                          count: jobs.length,
                          language: jobs[0].targetLanguageName
                        }}
                        components={{ 1: <span className="font-bold"></span> }}
                      />
                    </Show>
                    <Show when={areDocumentNamesEqual}>
                      <Trans
                        i18nKey="documentList:invitedYouToTranslateDocumentInCountLanguages"
                        values={{
                          user: inviter.fullName,
                          document: jobs[0].documentName,
                          count: jobs.length
                        }}
                        components={{ 1: <span className="font-bold"></span>, 2: <span className="font-bold"></span> }}
                      />
                    </Show>
                  </Show>
                  {/* </Show> */}
                </Text>
                <Button variant="text" color="primary" size="sm" onClick={() => onConfirm(id)}>
                  {tDocumentList('acceptAll')}
                </Button>
              </div>
            );
          })}
        </CollapsibleInlineMessage.Content>
      </Show>
    </CollapsibleInlineMessage.Root>
  );
};
MultiplePendingJobInvitation.displayName = 'MultiplePendingJobInvitation';

export { MultiplePendingJobInvitation };
export type { MultiplePendingJobInvitationProps };
