import { useTranslation } from 'react-i18next';

import { Text } from '@/components/v2/Text';
import { Box } from '@/components/v2/Box';
import { LanguageGroup, LanguageOption } from '@/routes/Document/types';
import { Show } from '@/components/v2/Show';
import { FlagIcon } from '@/components/v2/FlagIcon';
import { Button } from '@/components/v2/Button';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

import { FavouriteLanguagesSearchBox } from './FavouriteLanguagesSearchBox';

type FavouriteLanguagesProps = {
  languageGroups: LanguageGroup[];
  defaultValues?: LanguageOption[];
  handleAddLanguage: (selectedItems?: LanguageOption[]) => void;
  handleRemoveLanguage: (id: string) => void;
};

export const FavouriteLanguages = ({
  languageGroups,
  defaultValues,
  handleAddLanguage,
  handleRemoveLanguage
}: FavouriteLanguagesProps) => {
  const { t: tSettings } = useTranslation('settings');

  return (
    <Box>
      <div className="flex items-center space-x-4">
        <Text size="md" color="primary" className="font-bold">
          {tSettings('favoriteLanguages')}
        </Text>
      </div>
      <FavouriteLanguagesSearchBox
        languageGroups={languageGroups}
        defaultValues={defaultValues}
        onAddLanguage={handleAddLanguage}
      />
      <Show when={defaultValues?.length}>
        <div className="space-y-6">
          {defaultValues?.map((languageOption, key) => {
            const { id, name, flagCode } = languageOption;
            return (
              <div className="flex justify-between" key={key}>
                <div className="flex items-center space-x-2">
                  <FlagIcon label={name} code={flagCode || 'xx'} size="md" />
                  <p>{name}</p>
                </div>
                <div>
                  <Button variant="text" color="negative" onClick={() => handleRemoveLanguage(id)}>
                    <AccessibleIcon icon="ri-delete-bin-line" label={tSettings('delete')} className="text-xl" />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Show>
    </Box>
  );
};
