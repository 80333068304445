import { Middleware } from '@reduxjs/toolkit';
import { some as _some } from 'lodash';

import { hideSavedIndicator, showSavedIndicator } from '../../slices/documentLanguageSlice';
import { ENDPOINTS, TIMEOUT_MS } from './constants';

export const documentLanguageMiddleware: Middleware = ({ dispatch }) => {
  let timeout: NodeJS.Timeout | null = null;

  return (next) => (action) => {
    if (_some(ENDPOINTS, (endpoint) => endpoint.matchFulfilled(action))) {
      if (timeout) {
        clearTimeout(timeout);
      }

      dispatch(showSavedIndicator());

      timeout = setTimeout(() => {
        dispatch(hideSavedIndicator());
      }, TIMEOUT_MS);
    }

    return next(action);
  };
};
