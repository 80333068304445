import { findLast } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { Badge } from '@/components/v1/Badge';
import { Divider } from '@/components/v1/Divider';
import { Show } from '@/components/v1/Show';
import { Spinner } from '@/components/v1/Spinner';
import { useOffline } from '@/hooks/useOffline';
import { useUnmount } from '@/hooks/useUnmount';
import { InboxIcon } from '@/icons/solid';
import { useCommentsContext } from '@/providers/CommentsProvider';
import { useCurrentAccountContext } from '@/providers/CurrentAccountProvider';
import {
  useCreateParagraphCommentMutation,
  useGetBootstrapDataQuery,
  useGetParagraphActivityCommentsQuery,
  useMarkParagraphCommentReadMutation
} from '@/redux/api';
import { EBlockedReason, EListableType } from '@/redux/api/constants';
import { to } from '@/utils/awaitToJs';

import { CommentInput } from '../comments/CommentInput';
import { ParagraphActivityCommentsProvider } from './ParagraphActivityCommentsContext';
import { ParagraphActivityItem } from './ParagraphActivityItem';
import type { IParagraphActivityCommentsProps } from './types';
import { filterActivities } from './utils';

export const ParagraphActivityComments = ({ documentId, languageId, paragraphId }: IParagraphActivityCommentsProps) => {
  const { t } = useTranslation('documentLanguageComments');
  const offline = useOffline();

  const { currentAccount } = useCurrentAccountContext();
  const { suggestions } = useCommentsContext();

  // Bootstrap data query
  const { data: bootstrapData } = useGetBootstrapDataQuery({ documentId, languageId });

  // Paragraph comment query
  const {
    data: activityComments,
    isLoading: isActivityCommentsLoading,
    isSuccess: isActivityCommentsSuccess
  } = useGetParagraphActivityCommentsQuery({ documentId, languageId, paragraphId });
  const activities = useMemo(
    () => filterActivities(activityComments?.activities ?? []),
    [activityComments?.activities]
  );

  const unreadCommentsCount = activityComments?.unreadCommentsCount ?? 0;

  // Create paragraph comment mutation
  const [createComment, { isLoading: isCreateCommentLoading, isSuccess: isCreateCommentSuccess }] =
    useCreateParagraphCommentMutation();

  // Mark paragraph comment read mutation
  const [markCommentRead] = useMarkParagraphCommentReadMutation();

  const markRead = () => {
    const lastReadParagraphCommentId = findLast(
      activities,
      (activity) => activity.type === EListableType.COMMENT && !activity.isRead
    )?.id;

    if (unreadCommentsCount > 0 && lastReadParagraphCommentId) {
      markCommentRead({
        documentId,
        languageId,
        paragraphId,
        body: { lastReadParagraphCommentId }
      });
    }
  };

  useUnmount(() => {
    markRead();
  });

  const listRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (isActivityCommentsSuccess) {
      const list = listRef.current;
      list?.scrollTo({ top: list.scrollHeight });
    }
  }, [isActivityCommentsSuccess]);

  return (
    <ParagraphActivityCommentsProvider documentId={documentId} languageId={languageId} paragraphId={paragraphId}>
      <div className="bg-white">
        <Divider>
          <span>{t('commentsAndActivities')}</span>

          <Show when={unreadCommentsCount}>
            <Badge className="ml-1" shape="squared" value={unreadCommentsCount} />
          </Show>
        </Divider>

        <Show
          when={!isActivityCommentsLoading && activities}
          fallback={
            <div className="flex h-10 items-center justify-center">
              <Spinner />
            </div>
          }
        >
          {(activities) => (
            <Show
              when={activities.length}
              fallback={
                <div className="space-y-1 p-4">
                  <div className="flex items-center justify-center">
                    <InboxIcon className="text-blueGray-800 opacity-60" size="2x" />
                  </div>
                  <div>
                    <p className="text-center font-bold text-blueGray-800 opacity-60">{t('noCommentsOrEdits')}</p>
                    <p className="text-center text-blueGray-800 opacity-60">{t('onlyTheMostRecentWillBeShown')}</p>
                  </div>
                </div>
              }
            >
              <Show when={!bootstrapData?.weConfig.hasActivityFeed} fallback={null}>
                <div className={'p-2'}>
                  <Alert type="info" message={t('onlyTheMostRecentWillBeShown')} />
                </div>
              </Show>
              <ul ref={listRef} className="max-h-112 overflow-y-auto">
                {activities.map((activity) => (
                  <ParagraphActivityItem key={`${activity.type}-${activity.id}`} activity={activity} />
                ))}
              </ul>
            </Show>
          )}
        </Show>

        <div className="px-4 pb-3 pt-4">
          <CommentInput
            disabled={bootstrapData?.weConfig.blockedReason === EBlockedReason.EXPIRED_SUBSCRIPTION || offline}
            isCreating={isCreateCommentLoading}
            isCreated={isCreateCommentSuccess}
            suggestions={suggestions}
            onMarkRead={markRead}
            onCreate={async (content) => {
              const [error] = await to(
                createComment({ documentId, languageId, paragraphId, currentAccount, body: { content } }).unwrap()
              );
              if (!error) {
                const list = listRef.current;
                list?.scrollTo({ top: list.scrollHeight, behavior: 'smooth' });
              }
            }}
          />
        </div>
      </div>
    </ParagraphActivityCommentsProvider>
  );
};
