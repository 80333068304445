export const PAGINATION_LIMIT = 300;

// You need to update Tailwind classes if you change these values
// We could improve this by using runtime values (eg. useMeasure hook)
const HEADER_HEIGHT = 40; // h-10
const SEARCH_HEIGHT = 64; // h-16
const PAGE_NUMBER_HEIGHT = 32; // h-8
const PARAGRAPHS_SPACING = 2; // space-y-0.5
const TOTAL_HEIGHT = HEADER_HEIGHT + SEARCH_HEIGHT + PAGE_NUMBER_HEIGHT + PARAGRAPHS_SPACING;

export const ROOT_MARGIN = `-${TOTAL_HEIGHT}px 0px 0px 0px`;
export const SCROLL_MARGIN = `${TOTAL_HEIGHT}px 0px 0px 0px`;

export enum ENodeType {
  TEXT = 'TEXT',
  PLACEHOLDER = 'PLACEHOLDER'
}
