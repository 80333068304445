/**
 * WORKAROUND
 *
 * We should find a better way to handle race conditions
 * or improve this builder to cover all cases
 */

import { EndpointDefinitions } from '@reduxjs/toolkit/dist/query';
import { QueryKeys, RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { Recipe, UpdateQueryDataThunk } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { QueryArgFrom, ResultTypeFrom } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export const buildSafeUpdateQueryData =
  <Definitions extends EndpointDefinitions, ReducerPath extends string>(
    updateQueryData: UpdateQueryDataThunk<Definitions, RootState<Definitions, string, ReducerPath>>
  ) =>
  <EndpointName extends QueryKeys<Definitions>>(
    endpointName: EndpointName,
    args: QueryArgFrom<Definitions[EndpointName]>,
    updateRecipe: Recipe<ResultTypeFrom<Definitions[EndpointName]>>
  ) =>
    updateQueryData(endpointName, args, (data) => {
      if (data !== undefined) {
        return updateRecipe(data);
      }
    });
