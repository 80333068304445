import { useTranslation } from 'react-i18next';

import { ETranslationListFilter } from '@/redux/api/constants';

import { ALL_FILTER_VALUE } from './constants';
import type { IUseTranslationListFilters } from './types';

export const useTranslationListFilters = ({
  segmentsTotalCount,
  segmentsTranslatedCount,
  segmentsDraftCount,
  segmentsWithMessagesCount,
  segmentsWithGlossaryWarnings
}: IUseTranslationListFilters) => {
  const { t } = useTranslation('documentLanguageParagraphsHeader');

  return [
    { value: ALL_FILTER_VALUE, label: t('allSentences'), count: segmentsTotalCount },
    { value: ETranslationListFilter.ONLY_WITH_COMMENTS, label: t('withComments'), count: segmentsWithMessagesCount },
    {
      value: ETranslationListFilter.ONLY_EMPTY,
      label: t('emptyTranslations'),
      count: segmentsTotalCount - segmentsTranslatedCount
    },
    { value: ETranslationListFilter.ONLY_DRAFT, label: t('draftTranslations'), count: segmentsDraftCount },
    {
      value: ETranslationListFilter.ONLY_GLOSSARY_WARNINGS,
      label: t('withGlossaryWarnings'),
      count: segmentsWithGlossaryWarnings
    }
  ];
};
