import checkout from './checkout.json';
import components from './components.json';
import document from './document.json';
import documentLanguage from './documentLanguage.json';
import documentLanguageAlerts from './documentLanguageAlerts.json';
import documentLanguageComments from './documentLanguageComments.json';
import documentLanguageHeader from './documentLanguageHeader.json';
import documentLanguageParagraph from './documentLanguageParagraph.json';
import documentLanguageParagraphs from './documentLanguageParagraphs.json';
import documentLanguageParagraphsFallback from './documentLanguageParagraphsFallback.json';
import documentLanguageParagraphsHeader from './documentLanguageParagraphsHeader.json';
import documentLanguagePreview from './documentLanguagePreview.json';
import documentLanguageTMComparison from './documentLanguageTMComparison.json';
import documentLanguageTranslation from './documentLanguageTranslation.json';
import documentList from './documentList.json';
import documentRevision from './documentRevision.json';
import exportSegments from './exportSegments.json';
import general from './general.json';
import global from './global.json';
import glossary from './glossary.json';
import importSegments from './importSegments.json';
import layout from './layout.json';
import modals from './modals.json';
import notifications from './notifications.json';
import plans from './plans.json';
import pretranslate from './pretranslate.json';
import sentry from './sentry.json';
import settings from './settings.json';
import translationMemory from './translationMemory.json';
import uploadDocuments from './uploadDocuments.json';

export const en = {
  checkout,
  components,
  document,
  documentLanguage,
  documentLanguageAlerts,
  documentLanguageComments,
  documentLanguageHeader,
  documentLanguageParagraph,
  documentLanguageParagraphs,
  documentLanguageParagraphsFallback,
  documentLanguageParagraphsHeader,
  documentLanguagePreview,
  documentLanguageTMComparison,
  documentLanguageTranslation,
  documentList,
  documentRevision,
  exportSegments,
  general,
  global,
  glossary,
  importSegments,
  layout,
  modals,
  notifications,
  plans,
  pretranslate,
  sentry,
  settings,
  translationMemory,
  uploadDocuments
} as const;
