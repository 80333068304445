import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import Tooltip from '@/components/v2/Tooltip';
import { onboardingSteps } from '@/routes/Onboarding/Welcome/constants';
import { EOnboardingSteps } from '@/routes/Onboarding/types';

const VIDEO_URL =
  'https://player.vimeo.com/video/940920557?h=1a5a64a6f5&muted=1&autoplay=1&pip=1&texttrack=en-x-autogen';

export const WhatYouCanDo: React.FC = () => {
  const { t } = useTranslation('uploadDocuments');
  const history = useHistory();

  const nextStep = () => {
    const index = onboardingSteps.map((s) => s.id).indexOf(EOnboardingSteps.TRANSLATE_YOUR_FIRST_DOCUMENT);
    const step = index !== -1 ? index : 0;

    if (step !== undefined) {
      const route = onboardingSteps[Math.min(step, onboardingSteps.length - 1)].path;
      history.push(route);
    }
  };

  const prevStep = () => {
    const index = onboardingSteps.map((s) => s.id).indexOf(EOnboardingSteps.GETTING_TO_KNOW_YOU);
    const step = index !== -1 ? index : 0;

    if (step !== undefined) {
      const route = onboardingSteps[Math.max(step, 0)].path;

      history.push(route);
    }
  };

  return (
    <div className="flex flex-col items-center pb-8">
      <div className="mt-16 items-start">
        <Title size="md" weight="bold" className="mb-4 text-start leading-9">
          {t('withRedokunYouCanDo')}
        </Title>
        <ol type="1" className="list-inside list-decimal text-base">
          <li>
            <Text size="md" className="inline">
              <Trans
                i18nKey="uploadDocuments:streamlineDocumentTranslations"
                components={{ 1: <span className="font-semibold text-typography-primary" /> }}
              />
            </Text>
          </li>
          <li>
            <Text size="md" className="inline">
              <Trans
                i18nKey="uploadDocuments:useAiForSuggestions"
                components={{ 1: <span className="font-semibold text-typography-primary" /> }}
              />
            </Text>
          </li>
          <li>
            <Text size="md" className="inline">
              <Trans
                i18nKey="uploadDocuments:haveYourTranslatedDocumentsFormatted"
                components={{ 1: <span className="font-semibold text-typography-primary" /> }}
              />
            </Text>
          </li>
        </ol>
        <div>
          <div className="relative  pt-2">
            <iframe
              src={VIDEO_URL}
              width="640"
              height="434"
              allow="fullscreen"
              title="Redokun: start projects faster"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <div className="mt-6 flex justify-between">
          <Button color="primary" variant="text" size="md" onClick={prevStep}>
            <AccessibleIcon label="arrow-left" icon="ri-arrow-left-line" />
            {t('back')}
          </Button>
          <Tooltip.Root open={true}>
            <Tooltip.Trigger asChild>
              <Button color="primary" variant="solid" size="md" onClick={nextStep}>
                {t('next')}
                <AccessibleIcon label="arrow-right" icon="ri-arrow-right-line" />
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content side="bottom" className="flex max-w-xxs flex-col gap-1">
              <Text className="text-typography-inverse-primary" size="sm">
                {t('redokunIsEasyToUse')}
              </Text>
              <Text className="text-typography-inverse-secondary" size="xs">
                {t('uploadDocumentToTranslate')}
              </Text>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
        </div>
      </div>
    </div>
  );
};
