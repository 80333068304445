import { Transition } from '@headlessui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Progress } from '@/components/v1/Progress';
import { Select } from '@/components/v1/Select';
import { Tooltip } from '@/components/v1/Tooltip';
import { useOffline } from '@/hooks/useOffline';
import { CheckIcon } from '@/icons/regular';
import { StepForwardIcon } from '@/icons/solid';
import { ETranslationListFilter } from '@/redux/api/constants';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { setFilter } from '@/redux/slices/documentLanguageSlice';
import { selectFilter, selectSavedIndicator } from '@/redux/slices/documentLanguageSlice/selectors';
import { classNames } from '@/utils/classNames';

import { ALL_FILTER_VALUE } from './constants';
import { useTranslationListFilters } from './hooks';
import type { IParagraphsHeaderProps } from './types';

export const ParagraphsHeader = (props: IParagraphsHeaderProps) => {
  const {
    segmentsTotalCount,
    segmentsTranslatedCount,
    segmentsDraftCount,
    segmentsWithMessagesCount,
    segmentsWithGlossaryWarnings,
    onGoToFirstEmptyTranslation
  } = props;

  const { t } = useTranslation('documentLanguageParagraphsHeader');
  const offline = useOffline();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter) ?? ALL_FILTER_VALUE;
  const savedIndicator = useAppSelector(selectSavedIndicator) ?? false;

  const onFilterChange = useCallback(
    (filter: ETranslationListFilter | typeof ALL_FILTER_VALUE) => {
      dispatch(setFilter(filter !== ALL_FILTER_VALUE ? filter : null));
    },
    [dispatch]
  );

  const filters = useTranslationListFilters({
    segmentsDraftCount,
    segmentsTotalCount,
    segmentsTranslatedCount,
    segmentsWithMessagesCount,
    segmentsWithGlossaryWarnings
  });

  const segmentsConfirmedCount = segmentsTranslatedCount - segmentsDraftCount;
  const percent = (100 * segmentsTranslatedCount) / segmentsTotalCount;
  const successPercent = (100 * segmentsConfirmedCount) / segmentsTotalCount;

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className="w-44">
          <Progress percent={percent} successPercent={successPercent} />
        </div>

        <p className="ml-2 space-x-2 text-sm font-bold text-gray-500">
          {t('confirmedCount', { count: segmentsConfirmedCount })}
          &nbsp;&nbsp;
          {t('draftCount', { count: segmentsDraftCount })}
          &nbsp;&nbsp;
          {t('totalCount', { count: segmentsTotalCount })}
        </p>
      </div>

      <Transition
        className="ml-4 text-green-500 opacity-0 transition-opacity duration-200"
        show={savedIndicator}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <CheckIcon /> {t('saved')}
      </Transition>

      <div className="flex items-center">
        <span className="mr-1 text-sm font-bold text-gray-500">{t('show')}</span>

        <Select value={filter} onChange={onFilterChange} optionsPlacement="right" disabled={offline}>
          {filters.map(({ value, label, count }, i) => (
            <Select.Option key={`${i}-${value}`} value={value} trigger={`${label} (${count})`}>
              {label} {count >= 0 && <span className="text-gray-400">({count})</span>}
            </Select.Option>
          ))}
        </Select>

        <div className="flex w-12 flex-shrink-0 items-center justify-end">
          <Tooltip title={t('jumpToFirstEmptyTranslationBox')} placement="top">
            <button
              disabled={offline}
              className={classNames('px-2 text-blue-500', {
                'cursor-not-allowed opacity-50': offline,
                'hover:text-blue-600': !offline
              })}
              onClick={onGoToFirstEmptyTranslation}
            >
              <StepForwardIcon rotation={90} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
