import { Accept } from 'react-dropzone';

export const ACCEPT: Accept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/xliff+xml': ['.xliff'],
  'application/ods': ['.ods']
};

const POSSIBILITIES = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xliff: 'application/xliff+xml',
  ods: 'application/ods'
};

type KeyOfPossibilities = keyof typeof POSSIBILITIES;
export const KEYS_POSSIBILITIES = Object.keys(POSSIBILITIES);

export function acceptCreator(extension: string): Accept {
  if (!KEYS_POSSIBILITIES.includes(extension)) {
    return {};
  }

  const key = POSSIBILITIES[extension as KeyOfPossibilities];
  return { [key]: ACCEPT[key] };
}
