import { Transition } from '@headlessui/react';
import { first as _first } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/v1/Avatar';
import { Badge } from '@/components/v1/Badge';
import { Dropdown } from '@/components/v1/Dropdown';
import { Notifications } from '@/components/v1/Notifications/Notifications';
import { Popover } from '@/components/v1/Popover';
import { Tooltip } from '@/components/v1/Tooltip';
import { APP_URL, HELP_URL } from '@/config/urls';
import { useWindowScroll } from '@/hooks/useWindowScroll';
import { BellExclamationIcon, EnvelopeIcon, QuestionCircleIcon, StepForwardIcon } from '@/icons/solid';
import { useCurrentAccountContext } from '@/providers/CurrentAccountProvider';
import { useAppDispatch } from '@/redux/hooks';
import { openFeedbackModal } from '@/redux/slices/modalsSlice';
import { Beacon } from '@/utils/helpScout';
import { isNonNullable } from '@/utils/isNonNullable';

import { SCROLL_TO_TOP_THRESHOLD } from './constants';
import type { IDocumentLanguageLayoutProps } from './types';

export const DocumentLanguageLayout: React.FC<IDocumentLanguageLayoutProps> = ({ subHeader, bottomLeft, children }) => {
  const { t } = useTranslation('layout');
  const { currentAccount } = useCurrentAccountContext();
  const { scroll, scrollToTop } = useWindowScroll();
  const dispatch = useAppDispatch();

  return (
    <div className="flex min-h-screen flex-col text-sm text-gray-800">
      <header className="w-full border-b border-gray-300 px-8">
        <nav className="mx-auto flex h-16 max-w-6xl items-center justify-between">
          <a className="text-2xl font-extrabold" href={`${APP_URL}`}>
            redokun
          </a>

          <ul className="flex items-center space-x-6">
            <li>
              <button
                className="inline-flex items-center space-x-1 text-gray-500 hover:text-gray-600"
                onClick={() => {
                  dispatch(openFeedbackModal());
                }}
              >
                <EnvelopeIcon />
                <span>Send feedback or request feature</span>
              </button>
            </li>

            <li>
              <Dropdown
                placement="bottom-end"
                content={
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        Beacon('open');
                        Beacon('navigate', '/ask/message/');
                      }}
                    >
                      {t('sendSupportRequest')}
                    </Dropdown.Item>

                    <Dropdown.Item
                      as="a"
                      // @ts-expect-error: TS 5 migration error
                      href={HELP_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="flex"
                    >
                      {t('helpCenterUserGuides')}
                    </Dropdown.Item>
                  </>
                }
              >
                <Dropdown.Button className="inline-flex items-center space-x-1 text-gray-500 hover:text-gray-600">
                  <QuestionCircleIcon />
                  <span>{t('help')}</span>
                </Dropdown.Button>
              </Dropdown>
            </li>

            <li>
              <Popover
                placement="bottom-end"
                className="w-96"
                content={({ close }) => <Notifications account={currentAccount} onGoTo={close} />}
              >
                <Badge value={currentAccount.unreadNotificationCount || undefined}>
                  <Popover.Button>
                    <BellExclamationIcon size="lg" className="text-gray-500 hover:text-gray-600" />
                  </Popover.Button>
                </Badge>
              </Popover>
            </li>

            <li>
              <Avatar src={currentAccount.avatarUrl} alt={_first(currentAccount.fullName)} size="lg" />
            </li>
          </ul>
        </nav>
      </header>

      {isNonNullable(subHeader) && (
        <div className="px-8">
          <div className="mx-auto w-full max-w-6xl">{subHeader}</div>
        </div>
      )}

      <div className="flex-1 bg-gray-100 px-8">
        <div className="mx-auto w-full max-w-6xl">{children}</div>
      </div>

      <Transition
        className="fixed bottom-20 right-5 z-40 opacity-0 transition-opacity tall:bottom-28 tall:right-10"
        show={scroll.y > SCROLL_TO_TOP_THRESHOLD}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Tooltip title={t('goToTop')} placement="top" portal={false}>
          <button
            className="h-10 w-10 rounded-full bg-blue-200 text-blue-500 shadow-lg hover:bg-blue-300 hover:text-blue-600"
            onClick={() => {
              scrollToTop();
            }}
          >
            <StepForwardIcon rotation={270} />
          </button>
        </Tooltip>
      </Transition>

      {isNonNullable(bottomLeft) && (
        <div className="fixed bottom-3 left-5 z-40 tall:bottom-10 tall:left-10">{bottomLeft}</div>
      )}
    </div>
  );
};
