import { zodResolver } from '@hookform/resolvers/zod';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { Box } from '@/components/v2/Box';
import { Button } from '@/components/v2/Button';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { useToast } from '@/components/v2/Toast';
import { useLazyGetBillingQuery, usePutBillingMutation } from '@/redux/api/billingApi';
import { useGetCountryQuery } from '@/redux/api/countryApi';
import { to } from '@/utils/awaitToJs';

import { BillingDataForm } from './components/BillingDataForm';
import { defaultBillingsValues, recipientCodeRegEx } from './constants';

export const SettingsBillingData: React.FC = () => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const { data: countriesData, isLoading: countriesDataIsLoading } = useGetCountryQuery();
  const [putBillingsTrigger, { isLoading: putBillingsIsLoading }] = usePutBillingMutation();
  const [getBillingsTrigger, { isLoading: billingsDataIsLoading }] = useLazyGetBillingQuery();

  const formSchema = z.object({
    companyName: z.string().min(3).max(50),
    addressCountryId: z.number().positive(),
    addressStreet: z.string().min(3).max(75),
    addressCity: z.string().min(3).max(75),
    addressZipCode: z.string().min(3).max(10),
    vatNumber: z.string().optional().nullable(),
    billingEmail: z.string().email().optional().nullable(),
    recipientCode: z.string().regex(recipientCodeRegEx).min(7).max(7).optional().nullable()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: async () => {
      const [err, billingsData] = await to(getBillingsTrigger().unwrap());
      if (billingsData && !err) {
        const { companyName, countryId, address, city, zipCode, vatNumber, billingEmail, recipientCode } = billingsData;
        return {
          companyName,
          addressCountryId: countryId,
          addressStreet: address ?? '',
          addressCity: city ?? '',
          addressZipCode: zipCode ?? '',
          vatNumber,
          billingEmail,
          recipientCode
        };
      } else {
        return defaultBillingsValues;
      }
    }
  });
  const { handleSubmit: handleSubmitPutBillings } = form;

  const onSave = async (values: z.infer<typeof formSchema>) => {
    type Error = {
      data: { code: string; fieldsWithErrors: { [key: string]: string }; error: string };
    };

    const [err] = await to<unknown, Error>(
      putBillingsTrigger({ body: { ...defaultBillingsValues, ...values } }).unwrap()
    );

    if (!err) {
      toast({ title: tSettings('savedSuccessfully'), kind: 'success' });
    }

    if (err) {
      if (err.data.code === 'VALIDATION_ERROR') {
        if (err.data.fieldsWithErrors) {
          const fieldsWithErrors = Object.keys(err.data.fieldsWithErrors);
          for (let i = 0; i < fieldsWithErrors.length; i++) {
            const field = fieldsWithErrors[i] as keyof typeof values;
            form.setError(field, {
              type: 'server',
              message: err.data.fieldsWithErrors[field]
            });
          }
        }
      }
    }
  };

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      <Helmet>
        <title>{tSettings('settingsBillingData')}</title>
      </Helmet>
      <div className="grid grid-cols-1 gap-7">
        {/* Title */}
        <div className="flex items-center justify-between">
          <Text size="xl" weight="bold" color="primary">
            {tSettings('billingData')}
          </Text>

          <Button color="primary" disabled={putBillingsIsLoading} onClick={handleSubmitPutBillings(onSave)}>
            {putBillingsIsLoading ? <Spinner size="sm" /> : null}
            {tSettings('save')}
          </Button>
        </div>

        <div className="mt-6">
          <Show
            when={!billingsDataIsLoading && !countriesDataIsLoading}
            fallback={
              <Box className="border-none">
                <div className="flex h-56 justify-center rounded-lg">
                  <Spinner size="lg" />
                </div>
              </Box>
            }
          >
            <FormProvider {...form}>
              <BillingDataForm countriesData={countriesData} />
            </FormProvider>
          </Show>
        </div>
      </div>
    </div>
  );
};
