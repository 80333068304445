import { useTranslation } from 'react-i18next';

import { Tag } from '@/components/v2/Tag';
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from '@/components/v2/Tooltip';
import { Text } from '@/components/v2/Text';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

type StatusCellProps = {
  status:
    | 'CHANGED'
    | 'NOT_MATCHED'
    | 'DUPLICATE_ID'
    | 'SAME'
    | 'INVALID_STRING'
    | 'INVALID_ORIGINAL_PHRASE'
    | 'TAG_ERROR';
};

/*
 * Status cell for the "import segments" table.
 */
export const StatusCell = ({ status }: StatusCellProps) => {
  const { t } = useTranslation('importSegments');
  const color = {
    CHANGED: 'positive',
    NOT_MATCHED: 'negative',
    DUPLICATE_ID: 'negative',
    SAME: 'neutral',
    INVALID_STRING: 'negative',
    INVALID_ORIGINAL_PHRASE: 'negative',
    TAG_ERROR: 'negative'
  } as const;
  return (
    <div>
      <Tag color={color[status]}>
        {t(status)}
        {status === 'TAG_ERROR' ? (
          <Tooltip>
            <TooltipTrigger>
              <AccessibleIcon className="text-lg" label="ri-information-line" icon="ri-information-line" />
            </TooltipTrigger>
            <TooltipContent className="max-w-xxs" sideOffset={12}>
              <Text className="text-typography-inverse-primary" size="sm">
                {t('missingTagsInTranslatedPhrases')}
              </Text>
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        ) : null}
      </Tag>
      {/*  */}
    </div>
  );
};
