import classNames from 'classnames';
import { forwardRef } from 'react';

import { isNonNullable } from '@/utils/isNonNullable';

import type { TTextAreaProps } from './types';

export const TextArea = forwardRef<HTMLTextAreaElement, TTextAreaProps>(
  (
    {
      size = 'md',
      prefix,
      suffix,
      containerClassName,
      className,
      style,
      onChangeValue,
      onChange,
      ...htmlTextAreaProps
    },
    ref
  ) => {
    const textSizeClassName = classNames({
      'text-xs': size === 'sm',
      'text-sm': size === 'md'
    });

    return (
      <span
        className={classNames(
          'relative inline-flex items-center rounded border border-gray-100 bg-gray-100 px-2 py-1 text-gray-800 ring-blue-600 focus-within:ring-2',
          textSizeClassName,
          containerClassName
        )}
        style={style}
      >
        {isNonNullable(prefix) && <span className="mr-2">{prefix}</span>}

        <textarea
          className={classNames(
            'flex-1 border-none bg-transparent p-0 leading-none placeholder-gray-500 focus:ring-0',
            textSizeClassName,
            className
          )}
          onChange={
            onChange || onChangeValue
              ? (event) => {
                  onChange?.(event);
                  onChangeValue?.(event.target.value);
                }
              : undefined
          }
          {...htmlTextAreaProps}
          ref={ref}
        />

        {isNonNullable(suffix) && <span className="ml-2">{suffix}</span>}
      </span>
    );
  }
);
