/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { find as _find } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@/components/v1/Link';
import { Modal } from '@/components/v1/Modal';
import { Select } from '@/components/v1/Select';
import { HELP_URL } from '@/config/urls';
import { useOffline } from '@/hooks/useOffline';
import { EDocumentType, EThumbnailStatus } from '@/redux/api/constants';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { changePreviewPage } from '@/redux/slices/documentLanguageSlice';
import { selectPreviewPageNumber } from '@/redux/slices/documentLanguageSlice/selectors';

import { NO_PAGE_NUMBER } from './constants';
import { PreviewPlaceholder } from './PreviewPlaceholder';
import type { IPreviewProps } from './types';

export const Preview = ({ pages, documentType }: IPreviewProps) => {
  const { t } = useTranslation('documentLanguagePreview');
  const offline = useOffline();
  const dispatch = useAppDispatch();
  const previewPageNumber = useAppSelector(selectPreviewPageNumber);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const page = useMemo(() => _find(pages, { number: previewPageNumber }), [pages, previewPageNumber]);

  const visiblePageNumber = page?.number ?? NO_PAGE_NUMBER;
  const disabled = pages.length < 1 || offline;
  const pptx = documentType === EDocumentType.PPTX;

  const handleChange = useCallback(
    (pageNumber: number) => {
      const firstParagraph = _find(pages, { number: pageNumber })?.firstParagraph;
      if (firstParagraph) {
        dispatch(
          changePreviewPage({
            previewPageNumber: pageNumber,
            paragraphId: firstParagraph.id,
            paginationPage: firstParagraph.paginationPage
          })
        );
      }
    },
    [dispatch, pages]
  );

  return (
    <div>
      <div className="flex h-16 items-center justify-between">
        <div className="font-bold text-gray-500">{t(pptx ? 'slide' : 'page', { number: visiblePageNumber })}</div>

        <div className="flex items-center space-x-1">
          <span className="font-bold text-gray-500">{t(pptx ? 'goToSlide' : 'goToPage')}</span>

          <Select
            onChange={handleChange}
            value={previewPageNumber}
            triggerClassName="w-14"
            trigger={visiblePageNumber}
            disabled={disabled}
          >
            {pages.map((page) => {
              const { number, segmentCount } = page;

              return (
                <Select.Option key={number} value={number} size="sm" disabled={segmentCount < 1}>
                  {number}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>

      {page ? (
        <>
          {page.thumbnailStatus === EThumbnailStatus.OK && page.thumbnailUrl && (
            <>
              <img
                className="w-full hover:cursor-pointer"
                src={page.thumbnailUrl}
                alt=""
                loading="lazy"
                onClick={() => {
                  setOpenPreviewModal(true);
                }}
              />
              <Modal open={openPreviewModal} onClose={setOpenPreviewModal} size="unlimited">
                {{
                  header: t('originalPagePreview'),
                  content: <img src={page.thumbnailUrl} alt="" />
                }}
              </Modal>
            </>
          )}

          {page.thumbnailStatus === EThumbnailStatus.GENERATING && (
            <PreviewPlaceholder>
              {{ title: t('generatingPagePreview'), description: t('pleaseRefreshPageToSeePreview') }}
            </PreviewPlaceholder>
          )}

          {page.thumbnailStatus === EThumbnailStatus.CANNOT_GENERATE && (
            <PreviewPlaceholder>
              {{
                title: t('thumbnailGenerationFailed')
              }}
            </PreviewPlaceholder>
          )}

          {page.thumbnailStatus === EThumbnailStatus.UNAVAILABLE && <PreviewPlaceholder />}

          {page.thumbnailStatus === EThumbnailStatus.NOT_FOUND && (
            <PreviewPlaceholder>
              {{
                title: t('pagePreviewMissing'),
                description: <Link href={`${HELP_URL}/article/30-configure-indesign`}>{t('whyEmpty')}</Link>
              }}
            </PreviewPlaceholder>
          )}
        </>
      ) : (
        <PreviewPlaceholder />
      )}
    </div>
  );
};
