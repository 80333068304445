import { isTag, isText } from 'domhandler';
import { parseDocument } from 'htmlparser2';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createEditor, Descendant, Text } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { useForceRender } from '@/hooks/useForceRender';
import type { TPlaceholder } from '@/redux/api/types';
import { EElementType } from '@/slate/constants';
import { withPlaceholdersV1, withSingleLine, withTrim } from '@/slate/plugins';
import { editorIsValueChanged } from '@/slate/utils';
import { emptyText } from '@/slate/utils/emptyText';
import { getPlaceholderFromXmlAttribs } from '@/utils/placeholder';

export interface IUseTranslationEditorProps {
  initialTranslationContent: string;
  placeholders: TPlaceholder[];
}

export const useTranslationEditor = ({ initialTranslationContent, placeholders }: IUseTranslationEditorProps) => {
  const forceRender = useForceRender();

  const withPlaceholders = withPlaceholdersV1(placeholders);
  const [editor] = useState(() =>
    [withReact, withHistory, withSingleLine, withTrim, withPlaceholders].reduce(
      (editor, plugin) => plugin(editor),
      createEditor()
    )
  );

  const [initialValue] = useState(() => xliffToValue(initialTranslationContent));

  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_value: Descendant[]) => {
      const valueChanged = editorIsValueChanged(editor);
      if (valueChanged) {
        forceRender();
      }
    },
    [editor, forceRender]
  );

  const initialRenderRef = useRef(true);
  useLayoutEffect(() => {
    const initialRender = initialRenderRef.current;
    if (initialRender) {
      forceRender();
    } else {
      const value = xliffToValue(initialTranslationContent);
      editor.children = value;
      editor.onChange();
      forceRender();
    }
    initialRenderRef.current = false;
  }, [editor, forceRender, initialTranslationContent]);

  return { editor, initialValue, onChange };
};

const xliffToValue = (xliff: string): Descendant[] => {
  const children = parseDocument(xliff, { xmlMode: true }).children.reduce<Descendant[]>(
    (elements, node, index, array) => {
      if (isText(node)) {
        return [...elements, { text: node.data }];
      }

      if (isTag(node)) {
        // <x id="t1" ctype="TAG"/>
        const placeholder = getPlaceholderFromXmlAttribs(node.attribs);
        if (!placeholder) {
          return elements;
        }

        // Placeholder element must be wrapped between two text nodes
        const newElements: Descendant[] = [];
        if (!Text.isText(elements[elements.length - 1])) {
          newElements.push(emptyText());
        }
        newElements.push({
          type: EElementType.PLACEHOLDER_V1,
          placeholder,
          children: [emptyText()]
        });
        if (index >= array.length - 1) {
          newElements.push(emptyText());
        }

        return [...elements, ...newElements];
      }

      return elements;
    },
    []
  );

  if (children.length < 1) {
    children.push(emptyText());
  }

  return [{ type: EElementType.PARAGRAPH, children }];
};
