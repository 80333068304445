import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert } from '@/components/v1/Alert';
import { TConfirmAlertProps } from '@/components/v1/Alert/types';

export const SameTranslationsConfirmAlert: FunctionComponent<
  Pick<TConfirmAlertProps, 'onOk' | 'onCancel' | 'border'> & { isUpdate: boolean; count: number }
> = ({ isUpdate, count, ...props }) => {
  const { t } = useTranslation('documentLanguageAlerts');

  return (
    <Alert.Confirm
      type="warning"
      message={
        isUpdate ? (
          <Trans
            i18nKey={'documentLanguageAlerts:wantUpdateTranslationForAllIdenticalOccurrences'}
            count={count}
            components={{ 1: <span className="font-bold" />, 2: <span className="font-bold" /> }}
          />
        ) : (
          <Trans
            i18nKey={'documentLanguageAlerts:wantUseTranslationForAllIdenticalOccurrences'}
            count={count}
            components={{ 1: <span className="font-bold" /> }}
          />
        )
      }
      okText={t(isUpdate ? 'updateSegments' : 'useSameTranslation', { count })}
      cancelText={t('ignore')}
      {...props}
    />
  );
};
