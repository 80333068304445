import { FunctionComponent } from 'react';

import { classNames } from '@/utils/classNames';

import s from './Label.module.css';
import type { TLabelProps } from './types';

export const Label: FunctionComponent<TLabelProps> = ({ className, underline, children, ...htmlSpanProps }) => {
  const spanClassName = classNames(
    'relative inline-block',
    s.underline,
    {
      [s['underline-opacity-100']]: underline,
      [s['underline-opacity-0']]: !underline
    },
    className
  );

  return (
    <span className={spanClassName} {...htmlSpanProps}>
      {children}
    </span>
  );
};
