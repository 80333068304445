import {
  faArrowLeft,
  faArrowUp,
  faBellExclamation,
  faBolt,
  faBriefcase,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleExclamationCheck,
  faCog,
  faCommentAltLines,
  faCommentAltPlus,
  faCopy,
  faDatabase,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faFile,
  faInbox,
  faInfoCircle,
  faPaperPlane,
  faPen,
  faQuestion,
  faQuestionCircle,
  faRotateLeft,
  faSearch,
  faStepForward,
  faTimesCircle,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';

import { makeIcon } from './makeIcon';

export const ArrowLeftIcon = makeIcon(faArrowLeft);
export const ArrowUpIcon = makeIcon(faArrowUp);
export const BellExclamationIcon = makeIcon(faBellExclamation);
export const BoltIcon = makeIcon(faBolt);
export const BriefcaseIcon = makeIcon(faBriefcase);
export const ChevronDownIcon = makeIcon(faChevronDown);
export const ChevronLeftIcon = makeIcon(faChevronLeft);
export const ChevronRightIcon = makeIcon(faChevronRight);
export const CircleCheckIcon = makeIcon(faCircleCheck);
export const CircleExclamationCheckIcon = makeIcon(faCircleExclamationCheck);
export const CogIcon = makeIcon(faCog);
export const CommentAltLinesIcon = makeIcon(faCommentAltLines);
export const CommentAltPlusIcon = makeIcon(faCommentAltPlus);
export const CopyIcon = makeIcon(faCopy);
export const DatabaseIcon = makeIcon(faDatabase);
export const EllipsisVIcon = makeIcon(faEllipsisV);
export const EnvelopeIcon = makeIcon(faEnvelope);
export const ExclamationCircleIcon = makeIcon(faExclamationCircle);
export const ExclamationTriangleIcon = makeIcon(faExclamationTriangle);
export const FileIcon = makeIcon(faFile);
export const InboxIcon = makeIcon(faInbox);
export const InfoCircleIcon = makeIcon(faInfoCircle);
export const PaperPlaneIcon = makeIcon(faPaperPlane);
export const PenIcon = makeIcon(faPen);
export const QuestionIcon = makeIcon(faQuestion);
export const QuestionCircleIcon = makeIcon(faQuestionCircle);
export const RotateLeftIcon = makeIcon(faRotateLeft);
export const SearchIcon = makeIcon(faSearch);
export const StepForwardIcon = makeIcon(faStepForward);
export const TimesCircleIcon = makeIcon(faTimesCircle);
export const TrashIcon = makeIcon(faTrash);
