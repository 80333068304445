import { BillingFrequencyEnum, CurrencyEnum } from '@/redux/api/types';

interface CreateChangePlanUrlProps {
  planCode: string;
  currency: CurrencyEnum;
  billingFrequency: BillingFrequencyEnum;
}

export const createChangePlanUrl = (props: CreateChangePlanUrlProps) => {
  const { planCode, currency, billingFrequency } = props;
  return `/checkout?planCode=${planCode}&billingFrequency=${billingFrequency}&currency=${currency}`;
};
