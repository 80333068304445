import { languageApi, notificationApi, trackingApi } from '@/redux/api';

const { getMt, getTmSuggestions, markDocumentTranslatedCommentRead, markParagraphCommentRead, updateTranslation } =
  languageApi.endpoints;
const { markNotificationRead } = notificationApi.endpoints;
const { trackEvent } = trackingApi.endpoints;

/**
 * These endpoints are ignored or handled in different ways in the application.
 * If we create a new endpoint and it has to be ignored we should add it here.
 */
export const STANDARD_ERROR_IGNORED_ENDPOINTS = [
  getMt,
  getTmSuggestions,
  markDocumentTranslatedCommentRead,
  markNotificationRead,
  markParagraphCommentRead,
  updateTranslation
] as const;
export const OTHER_ERROR_IGNORED_ENDPOINTS = [getMt, getTmSuggestions, trackEvent] as const;
