import { useTranslation } from 'react-i18next';

import { Surface } from '@/components/v2/Surface';
import { Table, TableBody, TableCell, TableRow } from '@/components/v2/Table';
import { Text } from '@/components/v2/Text';
import { BillingFrequencyEnum, CurrencyEnum } from '@/redux/api/types';
import { useBillingFrequencyTranslation } from '@/utils/billingFrequency';
import { formatCurrency, formatPercent } from '@/utils/number';

const TransactionDetails = (props: TransactionDetailsProps) => {
  const { planName, billingFrequency, currency, amount, vatPercent, vat, total } = props;

  const { t: tCheckout } = useTranslation('checkout');
  const { t: tBillingFrequency } = useBillingFrequencyTranslation();

  return (
    <Surface className="space-y-7 px-7 py-8">
      <Text weight="bold" color="primary">
        {tCheckout('transactionDetails')}
      </Text>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell weight="regular">{tCheckout('plan')}</TableCell>
            <TableCell>{planName}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell weight="regular">{tCheckout('billingType')}</TableCell>
            <TableCell>{tBillingFrequency(billingFrequency)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell weight="regular">{tCheckout('amount')}</TableCell>
            <TableCell>{formatCurrency(amount, { currency })}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell weight="regular">
              {tCheckout('vat')} ({formatPercent(vatPercent / 100)})
            </TableCell>
            <TableCell>{formatCurrency(vat, { currency })}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell weight="regular">{tCheckout('total')}</TableCell>
            <TableCell className="text-green-400">{formatCurrency(total, { currency })}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Surface>
  );
};
TransactionDetails.displayName = 'TransactionDetails';

interface TransactionDetailsProps {
  planName: string;
  billingFrequency: BillingFrequencyEnum;
  currency: CurrencyEnum;
  amount: number;
  vatPercent: number;
  vat: number;
  total: number;
}

export { TransactionDetails };
export type { TransactionDetailsProps };
