import type { ChildNode } from 'domhandler';
import { isTag, isText } from 'domhandler';
import { parseDocument } from 'htmlparser2';
import { isArray, isNil, isNumber } from 'lodash';

import { highlight } from '@/utils/highlight';
import type { IGlossaryEntry, LanguageDto } from '@/redux/api/types';

import type { TNode } from './types';

export const parseContent = ({
  content,
  searchWord = [],
  glossaryEntries = [],
  targetLanguage
}: {
  content: string;
  searchWord?: string | string[];
  targetLanguage?: LanguageDto;
  glossaryEntries?: IGlossaryEntry[];
}) => {
  const { children } = parseDocument(content, { xmlMode: true });
  let nodes = mapChildNodesToNodes(children);

  if (glossaryEntries?.length && targetLanguage) {
    nodes = applyGlossaryEntries(nodes, { glossaryEntries, targetLanguage });
  }

  if (searchWord?.length) {
    const text = getNodesText(nodes);
    const chunksToHighlight = highlight({ text, searchWord });
    nodes = applyHighlight(nodes, chunksToHighlight);
  }

  return nodes;
};

const mapChildNodesToNodes = (childNodes: ChildNode[]): TNode[] =>
  childNodes.reduce((nodes, childNode) => {
    if (isText(childNode)) {
      return [...nodes, { type: 'TEXT' as const, text: childNode.data }];
    }

    if (isTag(childNode)) {
      const { name, attribs } = childNode;

      if (name === 'x') {
        const { id, ctype, 'equiv-text': equivText, 'x-desc': desc, 'x-content': content } = attribs;
        const placeholder = { id, ctype, equivText, desc, content };
        return [...nodes, { type: 'PLACEHOLDER' as const, placeholder }];
      }

      if (name === 'g') {
        const { id, ctype, 'equiv-text': equivText, 'x-desc': desc, 'x-content': content } = attribs;
        const placeholder = { id, ctype, equivText, desc, content };
        return [
          ...nodes,
          { type: 'PLACEHOLDER' as const, placeholder, children: mapChildNodesToNodes(childNode.children) }
        ];
      }

      return nodes;
    }

    return nodes;
  }, [] as TNode[]);

const applyGlossaryEntries = (
  nodes: TNode[],
  { glossaryEntries, targetLanguage }: { glossaryEntries: IGlossaryEntry[]; targetLanguage: LanguageDto }
) =>
  glossaryEntries.reduce((nodes, glossaryEntry) => {
    const { matchesOffsets, sourceContent } = glossaryEntry;

    return matchesOffsets.reduce((nodes, offset) => {
      const start = offset;
      const end = offset + sourceContent.length;
      const text = getNodesText(nodes).substring(start, end);
      return insertNode(
        nodes,
        { type: 'GLOSSARY_ENTRY', glossaryEntry, targetLanguage, children: [{ type: 'TEXT', text }] },
        { start, end }
      );
    }, nodes);
  }, nodes);

const insertNode = (nodes: TNode[], node: TNode, at?: number | { start: number; end: number }): TNode[] => {
  if (isNil(at)) {
    return [...nodes, node];
  }

  const { start, end } = isNumber(at) ? { start: at, end: at } : at;

  let index = 0;
  let offset = 0;
  while (index < nodes.length && offset + getNodeText(nodes[index]).length <= start) {
    offset += getNodeText(nodes[index]).length;
    index++;
  }

  if (index >= nodes.length) {
    return [...nodes, node];
  }

  const currentNode = nodes[index];
  const offsetStart = start - offset;
  const offsetEnd = end - offset;

  const beforeNodes = nodes.slice(0, index);
  const afterNodes = nodes.slice(index + 1);

  if (currentNode.type === 'TEXT') {
    const beforeText = currentNode.text.slice(0, offsetStart);
    const beforeNode = { type: 'TEXT' as const, text: beforeText };

    const afterText = currentNode.text.slice(offsetEnd);
    const afterNode = { type: 'TEXT' as const, text: afterText };

    return [...beforeNodes, beforeNode, node, afterNode, ...afterNodes];
  }

  if ('children' in currentNode && isArray(currentNode.children) && offsetStart > 0) {
    const children = insertNode(currentNode.children, node, { start: offsetStart, end: offsetEnd });
    const updatedNode = { ...currentNode, children };
    return [...beforeNodes, updatedNode, ...afterNodes];
  }

  return [...beforeNodes, node, currentNode, ...afterNodes];
};

const applyHighlight = (nodes: TNode[], chunks: { start: number; end: number }[]) => {
  let currentIndex = 0;
  const highlightedNodes: TNode[] = [];

  nodes.forEach((node) => {
    if (node.type === 'TEXT') {
      const nodeHighlightedChunks: { start: number; end: number }[] = [];

      chunks.forEach((chunk) => {
        const textLength = node.text.length;
        const nodeStart = currentIndex;
        const nodeEnd = currentIndex + textLength;

        if (nodeStart < chunk.end && nodeEnd > chunk.start) {
          const highlightStart = Math.max(chunk.start - nodeStart, 0);
          const highlightEnd = Math.min(chunk.end - nodeStart, textLength);

          nodeHighlightedChunks.push({
            start: highlightStart,
            end: highlightEnd
          });
        }
      });

      if (nodeHighlightedChunks.length > 0) {
        let lastHighlightEnd = 0;

        nodeHighlightedChunks.forEach((chunk) => {
          if (chunk.start > lastHighlightEnd) {
            highlightedNodes.push({
              type: 'TEXT',
              text: node.text.substring(lastHighlightEnd, chunk.start)
            });
          }

          highlightedNodes.push({
            type: 'TEXT',
            text: node.text.substring(chunk.start, chunk.end),
            highlight: true
          });

          lastHighlightEnd = chunk.end;
        });

        if (lastHighlightEnd < node.text.length) {
          highlightedNodes.push({
            type: 'TEXT',
            text: node.text.substring(lastHighlightEnd)
          });
        }
      } else {
        highlightedNodes.push(node);
      }

      currentIndex += node.text.length;
    } else if ('children' in node && isArray(node.children)) {
      highlightedNodes.push({
        ...node,
        children: applyHighlight(
          node.children,
          chunks.map((r) => ({ ...r, start: r.start - currentIndex, end: r.end - currentIndex }))
        )
      });

      currentIndex += getNodeText(node).length;
    } else {
      highlightedNodes.push(node);
    }
  });

  return highlightedNodes;
};

const getNodeText = (node: TNode): string => {
  if (node.type === 'TEXT') {
    return node.text;
  }

  if ('children' in node && isArray(node.children)) {
    return node.children.reduce((acc, child) => acc + getNodeText(child), '');
  }

  return '';
};

const getNodesText = (nodes: TNode[]): string => nodes.reduce((acc, node) => acc + getNodeText(node), '');
