import { cva } from 'cva';

import s from './Checkbox.module.css';

const checkbox = cva(
  [
    s['checkbox'],
    'oultine-none focus:outline-none focus-visible:outline-none flex items-center justify-center border-gray-400 bg-surface-elements ring-0 ring-primary-selected transition-all data-disabled:cursor-not-allowed data-disabled:border-gray-400 data-disabled:bg-gray-400'
  ],
  {
    variants: {
      size: {
        sm: 'h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] rounded border',
        md: 'h-6 min-h-[1.5rem] w-6 min-w-[1.5rem] rounded border-2',
        lg: 'h-8 min-h-[2rem] w-8 min-w-[2rem] rounded-lg border-2'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

const checkboxIndicator = cva([s['checkbox-indicator'], 'leading-none text-typography-inverse-primary'], {
  variants: {
    size: {
      sm: 'text-[0.75rem]',
      md: 'text-[1rem]',
      lg: 'text-[1.5rem]'
    }
  },
  defaultVariants: {
    size: 'md'
  }
});

export { checkbox, checkboxIndicator };
