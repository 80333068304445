import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@/components/v2/Button';
import { Input } from '@/components/v2/Input';
import { Label } from '@/components/v2/Label';
import { useToast } from '@/components/v2/Toast';
import { useInviteTranslatorMutation } from '@/redux/api';
import { to } from '@/utils/awaitToJs';
import { Spinner } from '@/components/v1/Spinner';

export const InviteTranslatorForm = () => {
  const { t: tSettings } = useTranslation('settings');
  const { toast } = useToast();

  const [inviteTranslator, { isLoading }] = useInviteTranslatorMutation();

  const formSchema = z.object({
    email: z.string().email()
  });

  const { handleSubmit, control, reset } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ''
    }
  });

  const onSubmitInvitation = async ({ email }: z.infer<typeof formSchema>) => {
    if (email) {
      const [err, res] = await to(inviteTranslator({ email }).unwrap());
      if (!err && res.translatorAccountId) {
        reset();
        toast({ title: tSettings('translatorInvitedWithSuccessToastTitle') });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitInvitation)}>
      <div className="space-y-4">
        <Label htmlFor="email" isRequired text={tSettings('email')}></Label>
        <div className="flex items-center space-x-3">
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  type="email"
                  onChange={field.onChange}
                  value={field.value}
                  placeholder={tSettings('typeEmailAddress')}
                />
              );
            }}
          />
          <Button disabled={isLoading} type="submit">
            {isLoading ? <Spinner size="md" /> : null}
            {tSettings('sendInvitation')}
          </Button>
        </div>
      </div>
    </form>
  );
};
