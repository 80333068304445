import { cva } from 'cva';

export const tooltipVariant = cva(['z-50 rounded-lg bg-alpha-900 text-white'], {
  variants: {
    size: {
      sm: ['px-3 py-2 text-base'],
      md: ['px-5 py-2.5 text-base']
    }
  },
  defaultVariants: {
    size: 'sm'
  }
});
