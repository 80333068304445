import type { ArgumentArray } from 'classnames';
import cn from 'classnames';
// @ts-expect-error: TS 5 migration error
import { extendTailwindMerge } from 'tailwind-merge';

const tw = extendTailwindMerge({
  classGroups: {
    fontSize: [
      'text-2xs',
      'text-xs',
      'text-sm',
      'text-base',
      'text-lg',
      'text-xl',
      'text-2xl',
      'text-3xl',
      'text-4xl',
      'text-5xl',
      'text-6xl',
      'text-7xl',
      'text-8xl',
      'text-9xl'
    ],
    textAlign: ['text-left', 'text-center', 'text-right', 'text-justify', 'text-start', 'text-end']
  }
});

/**
 * Wrapper for classnames and tailwind-merge.
 * Useful when using TailwindCSS and components based frameworks like React.
 *
 * GitHub:
 * classnames - https://github.com/JedWatson/classnames
 * tailwind-merge - https://github.com/dcastil/tailwind-merge
 */
export const classNames = (...args: ArgumentArray) => tw(cn(...args));
