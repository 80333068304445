import { useTranslation } from 'react-i18next';

import { Text } from '@/components/v2/Text';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';

export const TitleBlock = () => {
  const { t: tSettings } = useTranslation('settings');

  return (
    <div className="flex items-center justify-between">
      <Text size="xl" weight="bold" color="primary">
        {tSettings('deleteAccount')}
      </Text>
      <div>
        <ButtonLink variant="text" href="https://help.redokun.com/article/123-pause-subscription">
          <AccessibleIcon
            icon="ri-lightbulb-fill"
            label={tSettings('lookingToPauseYourSubscriptionLearnHow')}
            className="text-xl"
          />
          {tSettings('lookingToPauseYourSubscriptionLearnHow')}
        </ButtonLink>
      </div>
    </div>
  );
};
