import type { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArrowUpIcon,
  BoltIcon,
  CircleCheckIcon,
  CircleExclamationCheckIcon,
  CommentAltLinesIcon,
  PenIcon,
  QuestionIcon
} from '@/icons/solid';
import { classNames } from '@/utils/classNames';

export interface IActivityItemHeaderProps {
  action:
    | 'comment-added'
    | 'translation-edited'
    | 'translation-added'
    | 'translation-deleted'
    | 'translation-imported'
    | 'translation-pre-translated'
    | 'translation-marked-as-draft'
    | 'translation-marked-as-confirmed'
    | 'unknown';
  className?: string;
  style?: CSSProperties;
}

export const ActivityItemHeader = (props: IActivityItemHeaderProps) => {
  const { action, className, style } = props;
  const Icon = ICONS[action];
  const label = LABELS[action];

  const { t } = useTranslation('components');

  return (
    <div className={classNames('flex items-center gap-1 pl-9 text-blueGray-800 opacity-60', className)} style={style}>
      <Icon className="h-4 w-4" />

      <span className="text-sm">{t(label)}</span>
    </div>
  );
};
ActivityItemHeader.displayName = 'ActivityItemHeader';

const ICONS = {
  'comment-added': CommentAltLinesIcon,
  'translation-edited': PenIcon,
  'translation-added': PenIcon,
  'translation-deleted': PenIcon,
  'translation-imported': ArrowUpIcon,
  'translation-pre-translated': BoltIcon,
  'translation-marked-as-draft': CircleExclamationCheckIcon,
  'translation-marked-as-confirmed': CircleCheckIcon,
  unknown: QuestionIcon
} as const;

const LABELS = {
  'comment-added': 'activityItem.commentAdded',
  'translation-edited': 'activityItem.translationEdited',
  'translation-added': 'activityItem.translationAdded',
  'translation-deleted': 'activityItem.translationDeleted',
  'translation-imported': 'activityItem.translationImported',
  'translation-pre-translated': 'activityItem.translationPreTranslated',
  'translation-marked-as-draft': 'activityItem.translationMarkedAsDraft',
  'translation-marked-as-confirmed': 'activityItem.translationMarkedAsConfirmed',
  unknown: 'activityItem.unknown'
} as const;
