import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { to } from '@/utils/awaitToJs';
import { useDeleteTranslationMemoriesMutation } from '@/redux/api/translationMemoryApi';
import { Spinner } from '@/components/v1/Spinner';

export interface DeleteTranslationMemoryModalProps extends ModalProps {
  translationMemoryId: number;
  onDelete?: () => void;
}

export const DeleteTranslationMemoryModal = (props: DeleteTranslationMemoryModalProps) => {
  const { translationMemoryId, children, onDelete, ...modalProps } = props;

  const { t: tTM } = useTranslation('translationMemory');

  const [triggerDeleteTranslationMachine, { isLoading, isSuccess }] = useDeleteTranslationMemoriesMutation();

  const handleDeleteClick = async () => {
    const [error] = await to(triggerDeleteTranslationMachine({ itemId: translationMemoryId }).unwrap());

    if (!error) {
      onDelete?.();
    }
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="sm">
        <Modal.Header>
          <Modal.Title size="md">{tTM('deleteTranslationMemoryQuestion')}</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button color="negative" onClick={handleDeleteClick} disabled={isLoading || isSuccess}>
            {isLoading ? <Spinner size="sm" /> : null}
            {tTM('deleteTranslationMemory')}
          </Button>

          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tTM('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
