import { useCallback } from 'react';

import { useCopySameTranslationMutation } from '@/redux/api';

import type { IUseParagraphMutationsProps } from './types';

/**
 * Wrapper for useCopySameTranslationMutation hook used in DocumentLanguageParagraph component
 *
 * @param documentId Document ID
 * @param languageId Language ID
 * @param paragraphId Paragraph ID
 * @returns wrapped mutation hook with less parameters and renamed fields (more readable component)
 */
export const useCopySameParagraphTranslationMutation = ({
  documentId,
  languageId,
  paragraphId
}: IUseParagraphMutationsProps) => {
  const [mutationTrigger, mutationResult] = useCopySameTranslationMutation({
    selectFromResult: ({ data, isLoading, isSuccess, isError }) => ({
      copySameTranslationData: data,
      copySameTranslationIsLoading: isLoading,
      copySameTranslationIsSuccess: isSuccess,
      copySameTranslationIsError: isError
    })
  });

  return [
    useCallback(
      (isUpdate: boolean) => {
        mutationTrigger({ documentId, languageId, body: { paragraphId, isUpdate } });
      },
      [documentId, languageId, paragraphId, mutationTrigger]
    ),
    mutationResult
  ] as const;
};
