import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableColumnDef } from '@/components/v2/DataTable';
import { Text } from '@/components/v2/Text';
import { GlossaryDto } from '@/redux/api/types';
import { ActionsCell } from '@/routes/Glossary/components/GlossaryList/components/ActionCell.tsx';
import { SourceLanguageCell } from '@/routes/Glossary/components/GlossaryList/components/SourceLanguageCell';
import { TargetLanguageCell } from '@/routes/Glossary/components/GlossaryList/components/TargetLanguageCell';
import { standardDateTimeFormat } from '@/utils/date';
import { formatDecimal } from '@/utils/number';

const useColumns = () => {
  const { t: tGlossary } = useTranslation('glossary');

  const columns: DataTableColumnDef<GlossaryDto>[] = useMemo(
    () => [
      {
        id: 'source-language',
        accessorKey: 'sourceLanguage',
        header: () => tGlossary('sourceLanguage'),
        cell: () => <SourceLanguageCell />
      },
      {
        id: 'target-langugage',
        accessorKey: 'targetLanguage',
        header: () => tGlossary('targetLanguage'),
        cell: () => <TargetLanguageCell />
      },
      {
        id: 'entry-count',
        accessorKey: 'entryCount',
        header: () => tGlossary('entriesCount'),
        cell: (value) => <Text>{formatDecimal(value.cell.getValue<number>())}</Text>
      },
      {
        id: 'last-updated-at',
        accessorKey: 'lastUpdatedAt',
        header: () => tGlossary('lastUpdate'),
        cell: (value) => <Text>{standardDateTimeFormat(value.cell.getValue<string>())}</Text>
      },
      {
        id: 'actions',
        cell: () => <ActionsCell />,
        meta: {
          width: '10rem'
        }
      }
    ],
    [tGlossary]
  );

  return columns;
};

export { useColumns };
