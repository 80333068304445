import { createContext, useMemo } from 'react';

import type { IPusherContext, IPusherProviderProps } from './types';

export const PusherContext = createContext<IPusherContext | undefined>(undefined);
PusherContext.displayName = 'PusherContext';

export const PusherProvider = (props: IPusherProviderProps) => {
  const { pusher, children } = props;

  const value = useMemo(
    () => ({
      pusher,
      subscribe: pusher.subscribe,
      unsubscribe: pusher.unsubscribe
    }),
    [pusher]
  );

  return <PusherContext.Provider value={value}>{children}</PusherContext.Provider>;
};
PusherProvider.displayName = 'PusherProvider';
