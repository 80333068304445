import { createSelector } from '@reduxjs/toolkit';

import type { TAppState } from '../../store/types';

const selectDocumentLanguage = (state: TAppState) => state.documentLanguage;

export const selectSavedIndicator = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.savedIndicator
);

export const selectScrollToParagraphId = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.scrollToParagraphId
);

export const selectPreviewPageNumber = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.previewPageNumber
);

export const selectSearchSource = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.searchSource
);

export const selectDebouncedSearchSource = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.debouncedSearchSource
);

export const selectSearchTarget = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.searchTarget
);

export const selectDebouncedSearchTarget = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.debouncedSearchTarget
);

export const selectFilter = createSelector(selectDocumentLanguage, (documentLanguage) => documentLanguage.filter);

export const selectFocusedParagraphId = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.focusedParagraphId
);

export const selectSuggestionsParagraphId = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.suggestionsParagraphId
);

export const selectPaginationPage = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.paginationPage
);

export const selectOpenParagraphCommentsId = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.openParagraphCommentsId
);

export const selectOpenParagraphComments = createSelector(
  selectDocumentLanguage,
  (documentLanguage) => documentLanguage.openParagraphComments
);

export const selectHasFilters = createSelector(
  selectDocumentLanguage,
  (documentLanguage) =>
    !!documentLanguage.debouncedSearchSource || !!documentLanguage.debouncedSearchTarget || !!documentLanguage.filter
);
