import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppLayout from '@/components/layouts/AppLayout';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';

import { DocumentListContent } from './components/DocumentListContent';
import { DocumentListHeader } from './components/DocumentListHeader';
import { useDocumentListParams } from './hooks/useDocumentListParams';

export const DocumentList: React.FC = () => {
  const { t } = useTranslation('layout');
  const { folderId } = useDocumentListParams();

  const { currentAccount } = useCurrentAccount();
  const { subscriptionInfo } = currentAccount;

  useEffect(() => {
    if (subscriptionInfo.isActive && !subscriptionInfo.isTrial) {
      window.delighted?.survey({
        email: currentAccount.email,
        name: currentAccount.fullName
      });
    }
  }, [currentAccount.email, currentAccount.fullName, subscriptionInfo.isActive, subscriptionInfo.isTrial]);

  return (
    <AppLayout.Root title={t('documentList')}>
      <AppLayout.Navbar />

      <AppLayout.Content>
        <DocumentListHeader currentFolderId={folderId} />
        <DocumentListContent currentFolderId={folderId} />
      </AppLayout.Content>
    </AppLayout.Root>
  );
};
