import { StripeElementClasses, StripeElementStyle } from '@stripe/stripe-js';

const defaultStyle: StripeElementStyle = {
  base: {
    fontFamily:
      'ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#06162c',
    '::placeholder': {
      color: '#06162c80'
    }
  },
  invalid: {
    color: '#e4563a'
  }
};

const defaultClasses: StripeElementClasses = {
  base: 'rounded-md border border-gray-200 bg-white px-4 py-1',
  focus: 'ring-2 ring-primary-selected'
};

export { defaultClasses, defaultStyle };
