import { useCombobox, useMultipleSelection } from 'downshift';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v2/Button';
import { Checkbox } from '@/components/v2/Checkbox';
import Combobox from '@/components/v2/Combobox';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Show } from '@/components/v2/Show';
import { Tag } from '@/components/v2/Tag';
import { Text } from '@/components/v2/Text';
import { DocumentListStatus } from '@/redux/api/types';
import { useDocumentListSearchParams } from '@/routes/DocumentList/hooks/useDocumentListSearchParams';
import { isNotEmpty } from '@/utils/isNotEmpty';

export const StatusCombobox = () => {
  const { t: tDocumentList } = useTranslation('documentList');

  const status = {
    [DocumentListStatus.IN_PROGRESS]: 'primary',
    [DocumentListStatus.CONFIRMED]: 'positive',
    [DocumentListStatus.DOWNLOADED]: 'neutral'
  } as const;

  const label = {
    [DocumentListStatus.IN_PROGRESS]: tDocumentList('inProgress'),
    [DocumentListStatus.CONFIRMED]: tDocumentList('confirmedStatus'),
    [DocumentListStatus.DOWNLOADED]: tDocumentList('downloaded')
  } as const;

  const [{ filterByStatus: filterValueFromUrl }, { setFilterByStatus: setFilterValueFromUrl, setSavedView }] =
    useDocumentListSearchParams();

  const options = Object.values(DocumentListStatus);
  const [selectedItems, setSelectedItems] = useState<DocumentListStatus[]>();

  useEffect(() => {
    if (filterValueFromUrl && isNotEmpty(options)) {
      const options = Object.values(DocumentListStatus);
      const currentLanguagesSelected = options.filter((option) => filterValueFromUrl.includes(option));
      setSelectedItems(currentLanguagesSelected);
    }
    // needed to prevent infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValueFromUrl]);

  const { removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange(changes) {
      const { selectedItems: newSelectedItems, type } = changes;
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            setSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    }
  });

  const state = useCombobox({
    items: options,
    // clicking the same item twice does not toggle the selected item in combobox fix
    // https://github.com/downshift-js/downshift/pull/1571
    selectedItem: null,
    itemToString(item) {
      return item ? item : '';
    },
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputClick:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            // keep the menu open after selection. only if is already opened
            isOpen: state.isOpen
          };
        default:
          return changes;
      }
    },
    onStateChange(changes) {
      const { type, selectedItem: newSelectedItem } = changes;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          // added state.isOpen check as workaround to prevent side-effects
          // we want to trigger set or remove items only if dropdown is open
          if (!!newSelectedItem && state.isOpen) {
            if (selectedItems && selectedItems.some((s) => s === newSelectedItem)) {
              const item = selectedItems?.find((item) => item === newSelectedItem);
              if (item) {
                removeSelectedItem(item);
              }
            } else {
              setSelectedItems([...(selectedItems || []), newSelectedItem]);
            }
          }
          break;
        default:
          break;
      }
    }
  });

  const renderItems = () =>
    options.map((value) => (
      <Combobox.Item
        key={`key-${value}`}
        item={value}
        index={options.indexOf(value)}
        className="w-full items-center gap-3"
      >
        <Checkbox checked={!!selectedItems?.find((i) => i === value)} onClick={(e) => e.preventDefault()} />
        <Tag color={status[value]}>{label[value]}</Tag>
      </Combobox.Item>
    ));

  const handleClearFilter = () => {
    // clear document list
    setFilterValueFromUrl(undefined);
    setSavedView(null);
  };
  useEffect(() => {
    if (isEmpty(filterValueFromUrl)) setSelectedItems([]);
  }, [filterValueFromUrl]);

  const handleOnClick = () => {
    if (selectedItems) {
      setFilterValueFromUrl(selectedItems.flatMap((s) => s));
      setSavedView(null);
    }
  };

  const names = selectedItems?.flatMap((item) => label[item]).join(', ');

  return (
    <Combobox.Root state={state} className="max-w-[196px]">
      <Combobox.Trigger bordered className="h-9 place-content-start rounded-xl">
        <Text weight="medium" color="tertiary">
          {tDocumentList('status')}
        </Text>

        <OverflowTooltip label={names}>
          <Text size="md" color="primary" className="whitespace-nowrap">
            <Show when={isEmpty(selectedItems)} fallback={names}>
              {tDocumentList('all')}
            </Show>
          </Text>
        </OverflowTooltip>
        <Combobox.TriggerIcon className="ml-auto" />
      </Combobox.Trigger>

      <Combobox.Content>
        <Combobox.ItemsContainer className="max-h-56">{renderItems()}</Combobox.ItemsContainer>
        <Button
          variant="solid"
          color="primary"
          className="mt-1 w-full"
          size="sm"
          onClick={handleOnClick}
          type="button"
          disabled={!selectedItems}
        >
          {tDocumentList('filterStatus')}
        </Button>
        <Button
          variant="solid"
          color="secondary"
          className="mt-1 w-full"
          size="sm"
          onClick={handleClearFilter}
          type="button"
          disabled={!selectedItems}
        >
          {tDocumentList('clearFilter')}
        </Button>
      </Combobox.Content>
    </Combobox.Root>
  );
};
