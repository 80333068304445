import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v2/Box';
import { Text } from '@/components/v2/Text';
import { Toggle } from '@/components/v2/Toggle';

export const WebEditor = () => {
  const { t: tSettings } = useTranslation('settings');
  const { control: controlFromContext } = useFormContext();

  return (
    <Box>
      <div>
        <Text size="md" color="primary" className="font-bold">
          {tSettings('webEditor')}
        </Text>
      </div>
      <div className="space-y-6 pt-4">
        <Controller
          control={controlFromContext}
          name="autoloadMt"
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex gap-6">
              <Toggle checked={value} defaultChecked={value} onCheckedChange={onChange} {...field} />
              <Text weight="medium" color="primary">
                {tSettings('loadMachineTranslationSuggestions')}
                <Text size="sm" color="tertiary" className="pt-3">
                  {tSettings('loadMachineTranslationSuggestionsDescription')}
                </Text>
              </Text>
            </label>
          )}
        />
        <Controller
          control={controlFromContext}
          name="enableLiveCharacterCount"
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex gap-6">
              <Toggle checked={value} defaultChecked={value} onCheckedChange={onChange} {...field} />
              <Text weight="medium" color="primary">
                {tSettings('showTranslationCharacterCount')}
                <Text size="sm" color="tertiary" className="whitespace-pre-line pt-3">
                  {tSettings('showTranslationCharacterCountDescription')}
                </Text>
              </Text>
            </label>
          )}
        />
        <Controller
          control={controlFromContext}
          name="enableTmSourceIndicator"
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex gap-6">
              <Toggle checked={value} defaultChecked={value} onCheckedChange={onChange} {...field} />
              <Text weight="medium" color="primary">
                {tSettings('showTmSource')}
                <Text size="sm" color="tertiary" className="whitespace-pre-line pt-3">
                  {tSettings('showTheOriginOfTheTmBelowEachTmSuggestion')}
                </Text>
              </Text>
            </label>
          )}
        />
        <Controller
          control={controlFromContext}
          name="enableSearchSimilarity"
          render={({ field: { value, onChange, ...field } }) => (
            <label className="flex gap-6">
              <Toggle checked={value} defaultChecked={value} onCheckedChange={onChange} {...field} />
              <Text weight="medium" color="primary">
                {tSettings('enableFuzzyTranslationMemorySearch')}
                <Text size="sm" color="tertiary" className="whitespace-pre-line pt-3">
                  {tSettings('enableFuzzyTranslationMemorySearchDescription')}
                </Text>
              </Text>
            </label>
          )}
        />
      </div>
    </Box>
  );
};
