import type { TAppDispatch } from '@/redux/store/types';

import { languageApiUtil } from '../languageApi';
import type { IGetParagraphCommentQueryArg } from '../types';
import { mergePatchCollections } from '../utils/mergePatchCollections';

export const paragraphCommentDeleted =
  (args: IGetParagraphCommentQueryArg, payload: number) => (dispatch: TAppDispatch) => {
    const commentPatch = dispatch(
      languageApiUtil.safeUpdateQueryData('getParagraphComment', args, (data) => {
        data.comments = data.comments.filter((comment) => comment.id !== payload);
      })
    );

    const activityCommentsPatch = dispatch(
      languageApiUtil.safeUpdateQueryData('getParagraphActivityComments', args, (data) => {
        data.activities = data.activities.filter((activity) => activity.id !== payload);
      })
    );

    return mergePatchCollections(commentPatch, activityCommentsPatch);
  };
