import { Trans, useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import CollapsibleInlineMessage from '@/components/v2/CollapsibleInlineMessage';
import { Show } from '@/components/v2/Show';
import { JobInvitesDto } from '@/redux/api/types';

interface SinglePendingJobInvitationProps {
  invite: JobInvitesDto;
  isLoading?: boolean;
  onConfirm: (invitationId: number) => void;
}

/**
 * Collapsible inline message for single invitations
 */
const SinglePendingJobInvitation = ({ invite, isLoading, onConfirm }: SinglePendingJobInvitationProps) => {
  const { t: tDocumentList } = useTranslation('documentList');

  return (
    <CollapsibleInlineMessage.Root className="mt-6">
      <Show
        when={!isLoading}
        fallback={
          <div className="flex h-14 justify-center">
            <Spinner size="lg" />
          </div>
        }
      >
        <CollapsibleInlineMessage.Header>
          <AccessibleIcon className="text-2xl text-primary-active" label="feedback-icon" icon="ri-feedback-fill" />
          <CollapsibleInlineMessage.Title>
            <Trans
              i18nKey="documentList:invitedYouToTranslateDocumentToLanguage"
              values={{
                user: invite.inviter.fullName,
                document: invite.jobs[0].documentName,
                language: invite.jobs[0].targetLanguageName
              }}
              components={{ 1: <span className="font-bold"></span> }}
            />
          </CollapsibleInlineMessage.Title>
          <CollapsibleInlineMessage.Trigger asChild>
            <Button
              variant="surface"
              color="secondary"
              size="sm"
              className="ml-auto"
              onClick={() => onConfirm(invite.id)}
            >
              {tDocumentList('accept')}
            </Button>
          </CollapsibleInlineMessage.Trigger>
        </CollapsibleInlineMessage.Header>
      </Show>
    </CollapsibleInlineMessage.Root>
  );
};
SinglePendingJobInvitation.displayName = 'SinglePendingJobInvitation';

export { SinglePendingJobInvitation };
export type { SinglePendingJobInvitationProps };
