import { first } from 'lodash';
import type { CSSProperties } from 'react';

import { Avatar } from '@/components/v1/Avatar';
import { Badge } from '@/components/v1/Badge';
import { classNames } from '@/utils/classNames';
import { standardDateTimeFormat } from '@/utils/date';

export interface IActivityItemUserProps {
  avatarSrc?: string;
  badge?: boolean;
  name: string;
  date: string;
  className?: string;
  style?: CSSProperties;
}

export const ActivityItemUser = (props: IActivityItemUserProps) => {
  const { name, avatarSrc, badge, className, date, style } = props;

  return (
    <div className={classNames('flex items-center gap-2', className)} style={style}>
      <div className="flex items-center gap-1">
        <Badge size="sm" empty className={classNames(!badge && 'invisible')} />

        <Avatar size="sm" src={avatarSrc} alt={name} fallback={first(name)} />
      </div>

      <div className="flex-1 space-x-2">
        <div className="inline text-sm font-bold text-blueGray-800">{name}</div>

        <div className="inline text-xs text-blueGray-800 opacity-60">{standardDateTimeFormat(date)}</div>
      </div>
    </div>
  );
};
ActivityItemUser.displayName = 'ActivityItemUser';
