import type {
  CreateSavedViewQueryArgs,
  DeleteDocumentListItemsQueryArgs,
  GetDocumentListQueryArgs,
  GetDocumentListResponseDto,
  GetSavedViewsResponseDto,
  OkResponseDto,
  SavedViewCreatedResponseDto,
  SavedViewDto,
  UpdateDocumentListItemsQueryArgs
} from '@/redux/api/types';
import { generateArrayParams } from '@/utils/array';

import { redokunApi } from './redokunApi';
import { buildSafeUpdateQueryData } from './utils/buildSafeUpdateQueryData';

export const documentListApi = redokunApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentList: builder.query<GetDocumentListResponseDto, GetDocumentListQueryArgs>({
      query: (props) => {
        const {
          folderParentId,
          page,
          perPage,
          searchTerm,
          filterByOwnerAccountIds,
          filterByTranslatorAccountIds,
          filterByTargetLanguageIds,
          filterByStatus
        } = props;
        const baseParams: { [key: string]: unknown } = {
          folderParentId,
          page,
          perPage,
          searchTerm
        };
        // add each filter array as a separate query parameter
        // since the backend expects an array
        const arrayParams = generateArrayParams({
          filterByOwnerAccountIds,
          filterByTranslatorAccountIds,
          filterByTargetLanguageIds,
          filterByStatus
        });

        const params = { ...baseParams, ...arrayParams };

        return {
          url: 'document-list',
          // avoid redundant calls to the document list table when the user searches or filters with unchanged parameters
          keepUnusedDataFor: 60,
          params
        };
      },
      providesTags: ['documentList']
    }),
    /**
     * Update properties of a document
     */
    updateDocumentListItems: builder.mutation<OkResponseDto, UpdateDocumentListItemsQueryArgs>({
      query: ({ parentFolderId, items, ownerAccountId, parentFolderIsRoot }) => ({
        url: 'document-list',
        method: 'PATCH',
        body: {
          parentFolderId,
          items,
          ownerAccountId,
          parentFolderIsRoot
        }
      }),
      invalidatesTags: ['documentList']
    }),
    /**
     * Delete document list items
     */
    deleteDocumentListItems: builder.mutation<OkResponseDto, DeleteDocumentListItemsQueryArgs>({
      query: ({ items }) => ({
        url: 'document-list',
        body: {
          items
        },
        method: 'DELETE'
      }),
      invalidatesTags: ['documentList']
    }),
    /**
     * Save a view
     */
    saveView: builder.mutation<SavedViewCreatedResponseDto, CreateSavedViewQueryArgs>({
      query: (data) => ({ url: 'document-list/saved-views', method: 'POST', body: data }),
      invalidatesTags: ['saved-views']
    }),
    /**
     * Retrieve all saved views
     */
    getSavedViews: builder.query<GetSavedViewsResponseDto, void>({
      query: () => `document-list/saved-views`,
      providesTags: ['saved-views']
    }),
    /**
     * Delete a saved view
     */
    deleteSavedView: builder.mutation<OkResponseDto, number>({
      query: (savedViewId) => ({
        url: `document-list/saved-views/${savedViewId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['saved-views']
    }),
    /**
     * Update saved view properties
     */
    patchSavedView: builder.mutation<OkResponseDto, Pick<SavedViewDto, 'id'> & Partial<SavedViewDto>>({
      query: (savedView) => ({
        url: `document-list/saved-views/${savedView.id}`,
        body: savedView,
        method: 'PATCH'
      }),
      invalidatesTags: ['saved-views']
    })
  })
});

export const {
  useDeleteDocumentListItemsMutation,
  useDeleteSavedViewMutation,
  useGetDocumentListQuery,
  useGetSavedViewsQuery,
  useLazyGetDocumentListQuery,
  useLazyGetSavedViewsQuery,
  usePatchSavedViewMutation,
  useSaveViewMutation,
  useUpdateDocumentListItemsMutation
} = documentListApi;

export const documentListApiUtil = {
  ...documentListApi.util,
  safeUpdateQueryData: buildSafeUpdateQueryData(documentListApi.util.updateQueryData)
};
