import { PayloadAction } from '@reduxjs/toolkit';

import { EModalType } from './constants';
import type { IModalsState, TOpenModalPayload } from './types';

export const openModalReducer = (state: IModalsState, { payload }: PayloadAction<TOpenModalPayload>) => {
  const { type, props, version } = payload;
  return { ...state, [type]: { open: true, props, version } };
};

export const closeModalReducer = (state: IModalsState, { payload }: PayloadAction<EModalType>) => {
  const modal = state[payload];
  if (modal) {
    return { ...state, [payload]: { ...modal, open: false } };
  }
  return state;
};

export const destroyModalReducer = (state: IModalsState, { payload }: PayloadAction<EModalType>) => ({
  ...state,
  [payload]: undefined
});
