import { cva } from 'cva';

import s from './RadioGroup.module.css';

const radioGroupItem = cva(
  [s['radio-group-item'], 'relative z-0 rounded-full border ring-0 ring-primary-selected transition-all'],
  {
    variants: {
      size: {
        sm: 'h-5 min-h-[1.25rem] w-5 min-w-[1.25rem]',
        md: 'h-6 min-h-[1.5rem] w-6 min-w-[1.5rem]',
        lg: 'h-8 min-h-[2rem] w-8 min-w-[2rem]'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

export { radioGroupItem };
