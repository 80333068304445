import Avatar from '@/components/v2/Avatar';
import DataTable from '@/components/v2/DataTable';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Text } from '@/components/v2/Text';
import { DocumentListItemDto } from '@/redux/api/types';
import { getFallbackName } from '@/routes/Document/utils';

import { isFolderItem } from '../utils';

export const UploaderCell = () => {
  const { cell } = DataTable.useCellContext<DocumentListItemDto, DocumentListItemDto>('UploaderCell');
  const data = cell.row.original;

  if (isFolderItem(data)) {
    return <div />;
  }

  const owner = data.item.owner;

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center">
        <Avatar.Root size="xs">
          <Avatar.Image src={owner.avatarUrl} />
          {/* delayMs is used to prevent flickering between avatar image and fallback */}
          <Avatar.Fallback delayMs={1000}>{getFallbackName(owner.fullName)}</Avatar.Fallback>
        </Avatar.Root>
      </div>

      <OverflowTooltip label={owner.fullName}>
        <Text size="sm" weight="medium" color="primary" className="whitespace-nowrap">
          {owner.fullName}
        </Text>
      </OverflowTooltip>
    </div>
  );
};
