import type { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/v1/Button';
import { Divider } from '@/components/v1/Divider';
import { useDropdownContext } from '@/components/v1/Dropdown';
import { TrashIcon } from '@/icons/solid';
import { composeEventHandlers } from '@/utils/composeEventHandlers';

export interface IActivityItemCommentMenuProps {
  onDelete?: () => void;
  className?: string;
  style?: CSSProperties;
}

export const ActivityItemCommentMenu = (props: IActivityItemCommentMenuProps) => {
  const { className, style, onDelete } = props;

  const { t } = useTranslation('components');
  const { close } = useDropdownContext('ActivityItemCommentMenu');

  return (
    <div className={className} style={style}>
      <Divider>{t('activityItem.actions')}</Divider>

      <div className="px-4 pb-1 pt-2">
        <Button btnType="text" icon={<TrashIcon />} size="sm" onClick={composeEventHandlers(onDelete, close)}>
          {t('activityItem.deleteComment')}
        </Button>
      </div>
    </div>
  );
};
ActivityItemCommentMenu.displayName = 'ActivityItemCommentMenu';
