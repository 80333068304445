import * as RadixLabel from '@radix-ui/react-label';
import { VariantProps } from 'cva';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';

import { classNames } from '@/utils/classNames';

import { labelVariants } from './styles';

export type LabelVariantProps = VariantProps<typeof labelVariants>;
export type LabelProps = { text?: ReactNode; isRequired?: boolean } & LabelVariantProps &
  ComponentPropsWithoutRef<'label'>;

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ color, size, theme, weight, className, text, children, isRequired, ...props }, ref) => (
    <RadixLabel.Root
      {...props}
      className={classNames(labelVariants({ color, size, theme, weight }), className)}
      ref={ref}
    >
      {children ?? text} {isRequired ? <span className="text-red-500">*</span> : null}
    </RadixLabel.Root>
  )
);
Label.displayName = 'Label';
