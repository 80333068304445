import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';

import { Spinner } from '@/components/v1/Spinner';
import { NestedFolderStructureItem } from '@/components/v2/AccordionNested';
import { Button } from '@/components/v2/Button';
import { FolderBrowser } from '@/components/v2/FolderBrowser';
import { Input } from '@/components/v2/Input';
import Modal from '@/components/v2/Modal';
import { Show } from '@/components/v2/Show';
import { Text } from '@/components/v2/Text';
import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { useGetFoldersQuery, useUpdateFolderMutation } from '@/redux/api';
import { FolderHierarchyDto } from '@/redux/api/types';
import { to } from '@/utils/awaitToJs';
import { collectFolderAndChildrenIds, findFolderNameById, findParentFolderIdById } from '@/utils/folder';
import { isNotEmpty } from '@/utils/isNotEmpty';

interface FolderSettingsModalContentProps {
  folderId: number;
  onConfirm?: () => void;
}

export const FolderSettingsModalContent = ({ folderId, onConfirm }: FolderSettingsModalContentProps) => {
  const { t: tDocument } = useTranslation('document');

  const [updateFolder, { isLoading: isLoadingUpdate }] = useUpdateFolderMutation();
  const { data, isLoading: isLoadingFolders } = useGetFoldersQuery();

  const formSchema: ZodType = z.object({
    parentFolderId: z.string(),
    folderName: z.string().trim().min(1)
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  });

  // used to set default values
  // default owner and default parentId
  useEffect(() => {
    if (data && isNotEmpty(data)) {
      const currentFolderName = findFolderNameById(data, folderId);
      const currentParentFolderId = String(findParentFolderIdById(data, folderId) ?? DOCUMENT_LIST_MOCK_ID);

      reset({ folderName: currentFolderName || '', parentFolderId: currentParentFolderId });
    }
  }, [folderId, reset, data]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { folderName, parentFolderId } = data;
    const parsedParentFolderId = isNaN(Number(parentFolderId)) ? null : parseInt(parentFolderId);

    const [error] = await to(
      updateFolder({ parentFolderId: parsedParentFolderId, name: folderName, folderId }).unwrap()
    );
    if (!error) {
      onConfirm?.();
    }
  };

  const transformFolders = useCallback((folders: FolderHierarchyDto[]): NestedFolderStructureItem[] => {
    return folders.map((folder) => ({
      id: String(folder.id),
      name: folder.name,
      hasFolderIcon: true,
      children: folder.children ? transformFolders(folder.children) : []
    }));
  }, []);

  // disable current folder and its children to prevent unexpected behaviours such us moving the folder within itself
  const disabledIds = useMemo(
    () => (data !== undefined ? collectFolderAndChildrenIds(data, folderId) : []),
    [data, folderId]
  );

  const defaultNestedFolders: NestedFolderStructureItem[] = useMemo(
    () => [
      {
        id: DOCUMENT_LIST_MOCK_ID,
        name: tDocument('documentList'),
        hasFolderIcon: false,
        children: data?.children ? transformFolders(data.children) : []
      }
    ],
    [data?.children, tDocument, transformFolders]
  );

  const [nestedFolders, setNestedFolders] = useState(defaultNestedFolders);
  // list of accordion's folder ids
  const [openedAccordionIds, setOpenedAccordionIds] = useState([DOCUMENT_LIST_MOCK_ID]);

  // this useEffect is needed only if folders data changes
  useEffect(() => {
    if (data?.children) {
      setNestedFolders(defaultNestedFolders);
    }
  }, [data, defaultNestedFolders]);

  const disabled = !isValid || isLoadingUpdate;

  return (
    <Show
      when={!isLoadingFolders}
      fallback={
        <div className="flex h-[478px] justify-center">
          <Spinner size="lg" />
        </div>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6">
        <Modal.Header>
          <Modal.Title>{tDocument('folderSettings')}</Modal.Title>
        </Modal.Header>

        <div className="space-y-2">
          <Text>{tDocument('folderName')}</Text>
          <Controller
            control={control}
            name="folderName"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            render={({ field }) => <Input {...field} autoFocus id="folderName" />}
          />
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <Text>{tDocument('parentFolder')}</Text>
            <Show
              when={!isLoadingFolders}
              fallback={
                <div className="flex h-56 justify-center rounded-lg border border-alpha-200 bg-white">
                  <Spinner size="lg" />
                </div>
              }
            >
              <Controller
                control={control}
                name="parentFolderId"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FolderBrowser
                    defaultValue={[DOCUMENT_LIST_MOCK_ID]}
                    value={openedAccordionIds}
                    nestedFolderStructure={nestedFolders}
                    selected={value}
                    isCreatingNewFolder={false}
                    disabledIds={disabledIds}
                    onSelectItem={onChange}
                    onValueChange={setOpenedAccordionIds}
                  />
                )}
              />
            </Show>
          </div>
        </div>

        <Modal.Footer>
          <Button color="primary" type="submit" disabled={disabled}>
            {tDocument('saveChanges')}
          </Button>
          <Modal.Close>
            <Button color="secondary" variant="surface">
              {tDocument('cancel')}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </form>
    </Show>
  );
};
