import { forwardRef } from 'react';

import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { classNames } from '@/utils/classNames';

import s from './Table.module.css';
import type {
  TableBodyElement,
  TableBodyProps,
  TableCellElement,
  TableCellProps,
  TableElement,
  TableHeaderCellElement,
  TableHeaderCellProps,
  TableHeaderElement,
  TableHeaderProps,
  TableProps,
  TableRowElement,
  TableRowProps
} from './types';

const Table = forwardRef<TableElement, TableProps>(({ className, layout = 'auto', ...props }, ref) => (
  <table
    {...props}
    className={classNames(
      'w-full rounded-lg ring-1 ring-gray-200',
      {
        'table-auto': layout === 'auto',
        'table-fixed': layout === 'fixed'
      },
      className
    )}
    ref={ref}
  />
));
Table.displayName = 'Table';

const TableHeader = forwardRef<TableHeaderElement, TableHeaderProps>(({ className, ...props }, ref) => (
  <thead {...props} className={classNames(s['table-header'], className)} ref={ref} />
));
TableHeader.displayName = 'TableHeader';

const TableBody = forwardRef<TableBodyElement, TableBodyProps>(({ className, ...props }, ref) => (
  <tbody {...props} className={classNames(s['table-body'], className)} ref={ref} />
));
TableBody.displayName = 'TableBody';

const TableRow = forwardRef<TableRowElement, TableRowProps>(({ className, ...props }, ref) => (
  <tr {...props} className={classNames('group border-b border-gray-200', className)} ref={ref} />
));
TableRow.displayName = 'TableRow';

const TableHeaderCell = forwardRef<TableHeaderCellElement, TableHeaderCellProps>(
  ({ className, color, size = 'sm', theme, weight = 'medium', ...props }, ref) => (
    <Title asChild color={color} size={size} theme={theme} weight={weight}>
      <th {...props} className={classNames('p-5 text-start', className)} ref={ref} />
    </Title>
  )
);
TableHeaderCell.displayName = 'TableHeaderCell';

const TableCell = forwardRef<TableCellElement, TableCellProps>(
  ({ className, color, size = 'sm', theme, weight = 'medium', ...props }, ref) => (
    <Text asChild color={color} size={size} theme={theme} weight={weight}>
      <td {...props} className={classNames('px-5 py-2.5', className)} ref={ref} />
    </Text>
  )
);
TableCell.displayName = 'TableCell';

export { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow };
