import { RadioGroup, RadioGroupItem } from './RadioGroup';

const RadioGroupObject = {
  Root: RadioGroup,
  Item: RadioGroupItem
};

export type { RadioGroupItemProps, RadioGroupProps } from './types';
export { RadioGroup, RadioGroupItem };

export default RadioGroupObject;
