import { Listbox } from '@headlessui/react';
import classNames from 'classnames';

import { isFunction } from '@/utils/isFunction';

import type { IOptionProps, TValue } from './types';

export const Option = <T extends TValue>({ value, children, className, disabled, size = 'md' }: IOptionProps<T>) => (
  <Listbox.Option
    value={value}
    disabled={disabled}
    className={(props) =>
      classNames(
        {
          'px-2 py-0.5': size === 'sm',
          'px-4 py-1': size === 'md',
          'bg-gray-100': props.active,
          'opacity-50 hover:cursor-not-allowed': props.disabled,
          'hover:cursor-pointer hover:bg-gray-100': !props.disabled,
          'font-semibold': props.selected
        },
        isFunction(className) ? className(props) : className
      )
    }
  >
    {children}
  </Listbox.Option>
);
