import * as Accordion from '@radix-ui/react-accordion';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { classNames } from '@/utils/classNames';
import { OverflowTooltip } from '@/components/v2/OverflowTooltip';
import { Text } from '@/components/v2/Text';
import { AccordionNestedProps } from '@/components/v2/AccordionNested';
import { Show } from '@/components/v2/Show';
import { Input } from '@/components/v2/Input';
import { DOCUMENT_LIST_MOCK_ID } from '@/constants';
import { ChevronDownIcon } from '@/icons/solid';

import s from './AccordionNested.module.css';

export const AccordionNested = ({
  nestedFolderStructure,
  defaultValue,
  value,
  isCreatingNewFolder,
  selected,
  disabledIds = [],
  onSelectItem,
  onValueChange,
  onFolderCreated
}: AccordionNestedProps) => {
  // check if item is disabled
  const isDisabled = (id: string) => disabledIds.includes(id);

  return (
    <Accordion.Root type="multiple" defaultValue={defaultValue} value={value}>
      {nestedFolderStructure.map(({ id, name, hasFolderIcon = true, children = [] }) => {
        // check if accordion has children
        const hasChildren = children.length > 0;
        const isSelected = String(selected) === String(id);
        // if folder is selected and user clicked 'new folder' button
        const showCreateFolderInput = isSelected && isCreatingNewFolder;
        // check if item is disabled
        const isItemDisabled = isDisabled(id);

        // function to open or close an accordion item
        const toggleAccordion = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // prevent submitting the form
          e.preventDefault();
          // stop propagation otherwise the item will be selected
          e.stopPropagation();
          const updatedSet = new Set(value);
          if (updatedSet.has(String(id))) {
            updatedSet.delete(String(id));
          } else {
            updatedSet.add(String(id));
          }
          onValueChange?.(Array.from(updatedSet));
        };

        const handleFolderCreation = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            // if user selected document list root, pass null to endpoint otherwise the normal id
            const parentFolderId = id === DOCUMENT_LIST_MOCK_ID ? null : parseInt(String(id));
            const name = e.currentTarget.value;
            onFolderCreated?.(parentFolderId, name);
          }
        };

        const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // prevent submitting the form
          e.preventDefault();
          onSelectItem?.(String(id));
        };

        return (
          <Accordion.Item value={String(id)} key={id} disabled={isItemDisabled}>
            <Accordion.Header asChild>
              <button
                className={classNames(
                  'flex w-full select-none flex-row items-center gap-2 overflow-hidden rounded-md px-2 py-1',
                  { 'bg-surface-accent': isSelected },
                  { 'hover:bg-surface-background': !isSelected },
                  { 'cursor-not-allowed opacity-50': isItemDisabled }
                )}
                disabled={isItemDisabled}
                onClick={handleSelectItem}
              >
                <Accordion.Trigger
                  className={classNames(s['accordion-trigger'], { 'cursor-not-allowed': isItemDisabled })}
                  onClick={toggleAccordion}
                  disabled={isItemDisabled}
                >
                  <i className={classNames(s['accordion-chevron'], 'inline-block min-w-[18px]')}>
                    {/* show arrow icon if accordion has children or is creating a new folder item */}
                    {(hasChildren || showCreateFolderInput) && (
                      <ChevronDownIcon aria-hidden className={'text-alpha-400'} />
                    )}
                  </i>
                </Accordion.Trigger>
                <Show when={hasFolderIcon}>
                  <AccessibleIcon
                    icon="ri-folder-3-fill"
                    label="ri-folder-3-fill"
                    className="text-lg text-states-alert-active"
                  />
                </Show>
                <OverflowTooltip label={name}>
                  <Text
                    size="sm"
                    weight="regular"
                    color="primary"
                    className="overflow-hidden overflow-ellipsis whitespace-nowrap break-words"
                  >
                    {name}
                  </Text>
                </OverflowTooltip>
              </button>
            </Accordion.Header>
            <Accordion.Content className={(classNames(s['accordion-content']), 'pl-4')}>
              <Show when={showCreateFolderInput}>
                <div className="flex w-full select-none flex-row items-center gap-2 overflow-hidden rounded-md py-1 pl-8 pr-2">
                  <AccessibleIcon
                    icon="ri-folder-3-fill"
                    label="ri-folder-3-fill"
                    className="text-lg text-states-alert-active"
                  />
                  {/* when you click the new folder button, it shows an input and brings it into focus */}
                  <Input
                    placeholder="New folder"
                    size="sm"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={true}
                    onKeyDown={handleFolderCreation}
                    maxLength={100}
                  />
                </div>
              </Show>
              {hasChildren && (
                <AccordionNested
                  nestedFolderStructure={children}
                  selected={selected}
                  defaultValue={defaultValue}
                  value={value}
                  isCreatingNewFolder={isCreatingNewFolder}
                  disabledIds={disabledIds}
                  onSelectItem={onSelectItem}
                  onValueChange={onValueChange}
                  onFolderCreated={onFolderCreated}
                />
              )}
            </Accordion.Content>
          </Accordion.Item>
        );
      })}
    </Accordion.Root>
  );
};

AccordionNested.displayName = 'AccordionNested';
