import { zodResolver } from '@hookform/resolvers/zod';
import { useElements } from '@stripe/react-stripe-js';
import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js';
import { ReactNode } from 'react';

import { Form, useForm } from '@/components/v2/Form';

import { FORM_ID } from './constants';
import { defaultValues, PaymentMethodFormSchema, paymentMethodFormSchema } from './utils';

const PaymentMethodForm = (props: PaymentMethodFormProps) => {
  const { children, onSubmit } = props;

  const elements = useElements();

  const form = useForm({
    defaultValues,
    resolver: zodResolver(paymentMethodFormSchema)
  });

  const handleSubmit = (data: PaymentMethodFormSchema) => {
    const cardNumber = elements?.getElement('cardNumber');
    const cardExpiry = elements?.getElement('cardExpiry');
    const cardCvc = elements?.getElement('cardCvc');
    if (!cardNumber || !cardExpiry || !cardCvc) {
      return;
    }

    return onSubmit({ data, cardNumber, cardExpiry, cardCvc });
  };

  return (
    <Form {...form} id={FORM_ID} onSubmit={handleSubmit} devTool>
      {children}
    </Form>
  );
};
PaymentMethodForm.displayName = 'PaymentMethodForm';

interface PaymentMethodFormPayload {
  data: PaymentMethodFormSchema;
  cardNumber: StripeCardNumberElement;
  cardExpiry: StripeCardExpiryElement;
  cardCvc: StripeCardCvcElement;
}
interface PaymentMethodFormProps {
  children: ReactNode;
  onSubmit: (payload: PaymentMethodFormPayload) => void | Promise<void>;
}

export { PaymentMethodForm };
export type { PaymentMethodFormPayload, PaymentMethodFormProps };
