import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Button } from '@/components/v2/Button';
import InlineMessage from '@/components/v2/InlineMessage';
import Modal, { ModalProps } from '@/components/v2/Modal';
import { Show } from '@/components/v2/Show';
import { useChangeLanguageStatusMutation, useLazyGetBootstrapDataQuery } from '@/redux/api';
import { TranslationStatus } from '@/redux/api/constants';
import { to } from '@/utils/awaitToJs';

export interface LockDocumentModalProps extends ModalProps {
  documentId: number;
  languageId: number;
  onLock?: () => void;
}

export const LockDocumentModal = (props: LockDocumentModalProps) => {
  const { documentId, languageId, children, onLock, ...modalProps } = props;

  const { t } = useTranslation('document');

  const [changeStatus, { isLoading, isSuccess }] = useChangeLanguageStatusMutation();
  const [getBootstrapDataTrigger, { data: bootstrapData, isLoading: isBootstrapDataLoading }] =
    useLazyGetBootstrapDataQuery();

  useEffect(() => {
    if (modalProps.open === true)
      getBootstrapDataTrigger({
        documentId,
        languageId
      });
  }, [modalProps.open, getBootstrapDataTrigger, documentId, languageId]);

  const segmentsTotalCount = bootstrapData?.segmentsTotalCount ?? 0;
  const segmentsTranslatedCount = bootstrapData?.segmentsTranslatedCount ?? 0;
  const segmentsWithGlossaryWarnings = bootstrapData?.segmentsWithGlossaryWarnings ?? 0;
  const segmentsEmptyCount = segmentsTotalCount - segmentsTranslatedCount;

  const hasWarnings = Boolean(segmentsEmptyCount) || Boolean(segmentsWithGlossaryWarnings);
  const disableConfirmButton = isLoading || isBootstrapDataLoading || isSuccess;

  const handleOnLock = async () => {
    const [error] = await to(
      changeStatus({
        documentId,
        languageId,
        body: {
          status: TranslationStatus.CONFIRMED
        }
      }).unwrap()
    );

    if (!error) {
      onLock?.();
    }
  };

  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content size="lg">
        <Show
          when={!isBootstrapDataLoading}
          fallback={
            <div className="flex h-56 justify-center">
              <Spinner size="lg" />
            </div>
          }
        >
          <Modal.Header>
            <AccessibleIcon
              icon="ri-question-fill"
              label="ri-question-fill"
              className="justify-start text-4xl text-primary-active"
            />
            <Modal.Title size="md">{t('doYouWantToConfirmThisTranslation')}</Modal.Title>
            <Modal.Description size="md">{t('afterConfirmationyouWontBeAbleToEditTheTranslation')}</Modal.Description>
            <Show when={Boolean(segmentsEmptyCount)}>
              <InlineMessage.Root type="warning" orientation="horizontal" icon="ri-alert-fill" size="sm">
                <InlineMessage.Title size="sm" weight="semibold">
                  {t('yourFileContainsEmptyTranslations', { segmentsEmptyCount })}
                </InlineMessage.Title>
              </InlineMessage.Root>
            </Show>
            <Show when={Boolean(segmentsWithGlossaryWarnings)}>
              <InlineMessage.Root type="warning" orientation="horizontal" icon="ri-alert-fill" size="sm">
                <InlineMessage.Title size="sm" weight="semibold">
                  {t('someTranslationsHaveGlossaryWarnings')}
                </InlineMessage.Title>
              </InlineMessage.Root>
            </Show>
          </Modal.Header>

          <Modal.Footer className="flex flex-row gap-9">
            <Modal.Close>
              <Button color="secondary" variant="surface">
                {hasWarnings ? t('reviewTranslation') : t('cancel')}
              </Button>
            </Modal.Close>
            <Button color="primary" onClick={handleOnLock} disabled={disableConfirmButton}>
              {t('confirmAndLock')}
            </Button>
          </Modal.Footer>
        </Show>
      </Modal.Content>
    </Modal.Root>
  );
};
