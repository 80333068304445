import { lowerCase } from 'lodash';

import { PartialTranslatorDto } from '@/routes/Document/types';

export function getFilteredTranslators(inputValue?: string) {
  return function filterTranslators(translators: PartialTranslatorDto[]) {
    return translators.filter(
      (t) =>
        !inputValue ||
        lowerCase(t.fullName).includes(lowerCase(inputValue)) ||
        lowerCase(t.email).includes(lowerCase(inputValue))
    );
  };
}

export function removeDuplicateTranslators(translators: PartialTranslatorDto[]): PartialTranslatorDto[] {
  const uniqueTranslators: PartialTranslatorDto[] = [];
  const seenIds: Set<number> = new Set();

  for (const translator of translators) {
    if (!seenIds.has(translator.id)) {
      seenIds.add(translator.id);
      uniqueTranslators.push(translator);
    }
  }
  return uniqueTranslators;
}
