import { useTranslation } from 'react-i18next';

import Breadcrumb from '@/components/v2/Breadcrumb';
import { Button } from '@/components/v2/Button';
import { Skeleton } from '@/components/v2/Skeleton';
import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { Title } from '@/components/v2/Title';

export const GlossaryDetailHeaderSkeleton = () => {
  const { t: tGlossary } = useTranslation('glossary');

  return (
    <div className="flex flex-col">
      <Skeleton width="33%">
        <Breadcrumb.Root size="sm">
          <Breadcrumb.List>
            <Breadcrumb.Link>&nbsp;</Breadcrumb.Link>
          </Breadcrumb.List>
        </Breadcrumb.Root>
      </Skeleton>

      <div className="flex flex-row items-center justify-between">
        <Skeleton width="50%">
          <Title>&nbsp;</Title>
        </Skeleton>

        <div className="flex flex-row gap-4">
          <Skeleton>
            <Button variant="solid" color="secondary">
              <AccessibleIcon icon="ri-download-2-line" label="download-icon" />
              {tGlossary('download')}
            </Button>
          </Skeleton>

          <Skeleton>
            <Button variant="solid" color="secondary">
              <AccessibleIcon icon="ri-upload-2-line" label="upload-icon" />
              {tGlossary('uploadGlossary')}
            </Button>
          </Skeleton>

          <Skeleton>
            <Button variant="solid" color="primary">
              <AccessibleIcon icon="ri-add-fill" label="add-icon" />
              {tGlossary('createSingleEntry')}
            </Button>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
GlossaryDetailHeaderSkeleton.displayName = 'GlossaryDetailHeaderSkeleton';
