import { useTranslation } from 'react-i18next';

import { Spinner } from '@/components/v1/Spinner';
import { Box } from '@/components/v2/Box';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { Show } from '@/components/v2/Show';
import Table from '@/components/v2/Table';
import { Text } from '@/components/v2/Text';
import { APP_URL } from '@/config/urls';
import { useCurrentAccount } from '@/providers/CurrentAccountProvider';
import { GetBillingDetailResponseDto, PreferredPaymentMethodOptions } from '@/redux/api/types';
import { createBillingFrequencyLabels } from '@/routes/Settings/utils';

export const BillingDetails = ({
  isLoading,
  billingsData
}: {
  isLoading: boolean;
  billingsData?: GetBillingDetailResponseDto;
}) => {
  const { t: tSettings } = useTranslation('settings');

  const { currentAccount } = useCurrentAccount();

  const billingFrequencyLabels = createBillingFrequencyLabels(tSettings);

  const frequency = currentAccount.subscriptionInfo.billingFrequency
    ? `- ${billingFrequencyLabels[currentAccount.subscriptionInfo.billingFrequency]}`
    : '';
  const planCellContent = `${currentAccount.subscriptionInfo.planName} ${frequency}`;

  const paymentaMethodCellContent = () => {
    switch (billingsData?.preferredPaymentMethod) {
      case PreferredPaymentMethodOptions.stripe:
        return `${billingsData.cardBrand} ${billingsData.cardExpiration} ${tSettings('rpCardrp')}`;

      case PreferredPaymentMethodOptions.wire:
        return tSettings('bankTransfer');
      default:
        return '';
    }
  };

  return (
    <Show
      when={!isLoading}
      fallback={
        <Box className="border-none">
          <div className="flex h-56 justify-center rounded-lg">
            <Spinner size="lg" />
          </div>
        </Box>
      }
    >
      <Box className="border-none">
        <Text size="md" weight="bold" color="primary">
          {tSettings('billingDetails')}
        </Text>
        <Table.Root>
          <Table.Body>
            {/* Plans */}
            <Table.Row>
              <Table.Cell>{tSettings('plan')}</Table.Cell>
              <Table.Cell>{planCellContent}</Table.Cell>
              <Table.Cell align="right">
                {/* @todo: The "Plans" page is disabled, and we use the old one for now. Change when ready */}
                <ButtonLink variant="text" href={`${APP_URL}/settings/plans`}>
                  {tSettings('update')}
                </ButtonLink>
              </Table.Cell>
            </Table.Row>
            {/* Payment method */}
            <Table.Row>
              <Table.Cell>{tSettings('paymentMethod')}</Table.Cell>
              <Table.Cell className="capitalize">{paymentaMethodCellContent()}</Table.Cell>
              <Table.Cell align="right">
                <ButtonLink variant="text" href={`${APP_URL}/settings/billing/payment-method`}>
                  {tSettings('update')}
                </ButtonLink>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{tSettings('billingAddress')}</Table.Cell>
              <Table.Cell>
                <span className="block">{billingsData?.companyName}</span>
                <span className="block">{billingsData?.address}</span>
                <span className="block">
                  {billingsData?.city} - {billingsData?.zipCode}
                </span>
                <span className="block">{billingsData?.countryName}</span>
              </Table.Cell>
              <Table.Cell align="right">
                <ButtonLink variant="text" href="/settings/billing/change">
                  {tSettings('update')}
                </ButtonLink>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{tSettings('billingEmail')}</Table.Cell>
              <Table.Cell>{billingsData?.billingEmail || '—'}</Table.Cell>
              <Table.Cell align="right">
                <ButtonLink variant="text" href="/settings/billing/change">
                  {tSettings('update')}
                </ButtonLink>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{tSettings('vatId')}</Table.Cell>
              <Table.Cell>{billingsData?.vatNumber || '—'}</Table.Cell>
              <Table.Cell align="right">
                <ButtonLink variant="text" href="/settings/billing/change">
                  {tSettings('update')}
                </ButtonLink>
              </Table.Cell>
            </Table.Row>
            {currentAccount.subscriptionInfo.isActive && (
              <Table.Row>
                <Table.Cell>{tSettings('manageAutorenewal')}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell align="right">
                  <ButtonLink variant="text" href="/settings/billing/manage-auto-renewal">
                    {tSettings('manageAutoRenewal')}
                  </ButtonLink>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table.Root>
      </Box>
    </Show>
  );
};
