import { MachineTranslationVendor } from '../../../../redux/api/constants';

export const getProviderDescription = (provider: MachineTranslationVendor) => {
  switch (provider) {
    case MachineTranslationVendor.DEEPL:
      return 'DeepL';
    case MachineTranslationVendor.GOOGLE_TRANSLATE:
      return 'Google Translate';
    default:
      return provider;
  }
};
