import { some } from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AccessibleIcon } from '@/components/v2/AccessibleIcon';
import { ButtonLink } from '@/components/v2/ButtonLink';
import { For } from '@/components/v2/For';
import RadioGroup from '@/components/v2/RadioGroup';
import { Surface } from '@/components/v2/Surface';
import { Text } from '@/components/v2/Text';
import { Title } from '@/components/v2/Title';
import { Toggle } from '@/components/v2/Toggle';
import Tooltip from '@/components/v2/Tooltip';
import { LaunchPreTranslateFormValues, TmOption } from '@/routes/PreTranslate/components/types';

export const TranslationTmOption = (props: { languageId: number; disabled?: boolean }) => {
  const { t } = useTranslation('pretranslate');
  const { languageId, disabled } = props;

  const form = useFormContext<LaunchPreTranslateFormValues>();
  const handleCheckedChange = (value: boolean, callback: (value: boolean) => void) => {
    const newToggleState = !value;
    callback(newToggleState);
    if (!newToggleState) {
      form.setValue(`languages.${languageId}.mt`, false);
    }
  };

  return (
    <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
      <Controller
        control={form.control}
        name={`languages.${languageId}.tm`}
        disabled={disabled}
        render={({ field: { value, onChange, ...field } }) => (
          <label className="flex items-center gap-3">
            <Toggle
              size="sm"
              className="peer"
              checked={disabled ? false : value}
              defaultChecked={value}
              onCheckedChange={() => handleCheckedChange(value, onChange)}
              {...field}
            />
            <Text
              size="sm"
              weight="medium"
              color="primary"
              className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
            >
              {t('applyTranslationMemory')}
            </Text>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <AccessibleIcon
                  className="text-xl text-gray-600"
                  label="ri-information-fill"
                  icon="ri-information-line"
                />
              </Tooltip.Trigger>
              <Tooltip.Content className="max-w-xxs">
                <Text className="text-typography-inverse-primary" size="xs">
                  {t('useYourPreviouslyApprovedTranslationsToTranslateYourDocument')}{' '}
                  <a
                    href="https://help.redokun.com/article/24-pre-translate"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('learnMore')}
                  </a>
                </Text>
              </Tooltip.Content>
            </Tooltip.Root>
          </label>
        )}
      />
    </div>
  );
};

export const TmOptions = () => {
  const { t } = useTranslation('pretranslate');

  const form = useFormContext<LaunchPreTranslateFormValues>();
  const languages = useWatch({ control: form.control, name: 'languages' });

  const items = [
    { value: TmOption.MARK_AS_CONFIRMED, label: t('yesAndMarkAllAsConfirmed') },
    { value: TmOption.MARK_AS_DRAFT, label: t('yesAndMarkAllAsDraft') },
    { value: TmOption.DISABLE, label: t('noUseOnlyTranslationsWithSameSurroundingSegments') }
  ];

  return (
    <Surface className="space-y-6 p-7">
      <div className="flex flex-row justify-between">
        <Title size="sm">{t('alsoUseTranslationMemoryWithDifferentContext')}</Title>
        <ButtonLink
          variant="text"
          color="primary"
          size="sm"
          href="https://help.redokun.com/article/58-translation-memory"
        >
          <AccessibleIcon label="ri-lightbulb-fill" icon="ri-lightbulb-fill" />
          {t('seeHowItWorks')}
        </ButtonLink>
      </div>

      <Controller
        control={form.control}
        name="tm"
        disabled={!some(languages, { tm: true })}
        render={({ field: { value, onChange, ...field } }) => (
          <RadioGroup.Root className="space-y-4" value={value} onValueChange={onChange} {...field}>
            <For each={items}>
              {(item) => (
                <label key={item.value} className="flex items-center gap-3">
                  <RadioGroup.Item value={item.value} size="sm" className="peer" />

                  <Text
                    color="primary"
                    size="sm"
                    className="peer-disabled:cursor-not-allowed peer-disabled:text-typography-disabled"
                  >
                    {item.label}
                  </Text>
                </label>
              )}
            </For>
          </RadioGroup.Root>
        )}
      />
    </Surface>
  );
};
