import Modal, { ModalProps } from '@/components/v2/Modal';
import { FolderSettingsModalContent } from '@/routes/Document/components/FolderSettingsModal/components';

export interface FolderSettingsModalProps extends ModalProps {
  folderId: number;
  onConfirm?: () => void;
}

export const FolderSettingsModal = (props: FolderSettingsModalProps) => {
  const { folderId, onConfirm, children, ...modalProps } = props;
  return (
    <Modal.Root {...modalProps}>
      <Modal.Trigger>{children}</Modal.Trigger>

      <Modal.Content onOpenAutoFocus={(e) => e.preventDefault()} size="lg">
        <FolderSettingsModalContent folderId={folderId} onConfirm={onConfirm} />
      </Modal.Content>
    </Modal.Root>
  );
};
