import { TFunction } from 'i18next';
import { first, isEmpty, upperCase, words } from 'lodash';

import { LanguagesDto, LanguageSettingDto } from '@/redux/api/types';
import { LanguageGroup, LanguageOption } from '@/routes/Document/types';

export function getGroupFilter(inputValue?: string) {
  return function groupFilter(group: LanguageGroup): LanguageGroup {
    const filteredOptions = group.options.filter(
      (item) => !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return {
      ...group,
      options: filteredOptions
    };
  };
}

export const createTargetLanguageGroups = (data: LanguagesDto, t: TFunction): LanguageGroup[] => {
  const favoriteGroup: LanguageGroup = {
    name: t('favoriteLanguages'),
    options: data.active.map((language) => ({
      ...language,
      id: language.id.toString(),
      label: language.name
    }))
  };

  const otherGroup: LanguageGroup = {
    name: t('otherLanguages'),
    options: data.all
      .filter((language) => !data.active.some((l) => l.id === language.id))
      .map((language) => ({
        ...language,
        id: language.id.toString(),
        label: language.name
      }))
  };

  return [favoriteGroup, otherGroup];
};

export const createTargetLanguageInUseGroup = (data: LanguagesDto, t: TFunction): LanguageGroup[] => {
  const inUseGroup: LanguageGroup = {
    name: t('usedLanguages'),
    options: data.inUse.map((language) => ({
      ...language,
      id: language.id.toString(),
      label: language.name
    }))
  };

  return [inUseGroup];
};

export const createSourceLanguageGroups = (data: LanguagesDto, t: TFunction): LanguageGroup[] => {
  if (!isEmpty(data.inUse)) {
    const mostFrequentGroup: LanguageGroup = {
      name: t('mostFrequentlyUsed'),
      options: data.inUse.map((language) => ({
        ...language,
        id: language.id.toString(),
        label: language.name
      }))
    };

    const otherGroup: LanguageGroup = {
      name: t('otherLanguages'),
      options: data.all
        .filter((language) => !data.inUse.some((l) => l.id === language.id))
        .map((language) => ({
          ...language,
          id: language.id.toString(),
          label: language.name
        }))
    };

    return [mostFrequentGroup, otherGroup];
  } else {
    const mostFrequentGroup: LanguageGroup = {
      name: t('mostFrequentlyUsed'),
      options: data.all
        .filter((language) => language.isCommon)
        .map((language) => ({
          ...language,
          id: language.id.toString(),
          label: language.name
        }))
    };

    const otherGroup: LanguageGroup = {
      name: t('otherLanguages'),
      options: data.all
        .filter((language) => !language.isCommon)
        .map((language) => ({
          ...language,
          id: language.id.toString(),
          label: language.name
        }))
    };

    return [mostFrequentGroup, otherGroup];
  }
};

export const flattenGroupOptions = (options: LanguageGroup[]) =>
  options.reduce((prev: LanguageGroup['options'], curr: { options: LanguageGroup['options'] }) => {
    return [...prev, ...curr.options];
  }, []);

export const doesLanguageExist = (languageOptions: LanguageOption[], idToFind: string): boolean => {
  return languageOptions.some((language) => language.id === idToFind);
};

export const removeDuplicateLanguages = (arr: LanguageSettingDto[]) => {
  const languageMap = new Map<number, LanguageSettingDto>();
  // Iterate over the array to store the most recent occurrence of each languageId
  for (const item of arr) {
    languageMap.set(item.languageId, item);
  }
  // Return values from the map, which represent the most recent occurrences
  return Array.from(languageMap.values());
};

export const getFallbackName = (name: string) => {
  const [firstWord, secondWord] = words(name);
  return upperCase(`${first(firstWord) ?? ''}${first(secondWord) ?? ''}`);
};
