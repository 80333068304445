import { useCallback } from 'react';

import { useUpdateTranslationMutation } from '@/redux/api';
import { ETranslationUpdateSource } from '@/redux/api/constants';

import type { IUseParagraphMutationsProps } from './types';

/**
 * Wrapper for useUpdateTranslationMutation hook used in DocumentLanguageParagraph component
 *
 * @param documentId Document ID
 * @param languageId Language ID
 * @param paragraphId Paragraph ID
 * @returns wrapped mutation hook with less parameters and renamed fields (more readable component)
 */
export const useUpdateParagraphTranslationMutation = ({
  documentId,
  languageId,
  paragraphId
}: IUseParagraphMutationsProps) => {
  const [trigger, result] = useUpdateTranslationMutation({
    fixedCacheKey: `updateTranslation-${documentId}-${languageId}-${paragraphId}`
  });

  return [
    useCallback(
      (content: string, source: ETranslationUpdateSource) =>
        trigger({ documentId, languageId, body: { paragraphId, content, source } }),
      [documentId, languageId, paragraphId, trigger]
    ),
    result
  ] as const;
};
