import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@/components/v2/Box';
import { Text } from '@/components/v2/Text';
import RadioGroup from '@/components/v2/RadioGroup';
import { SettingsDTODeeplFormalityOptions } from '@/redux/api/types';

export const MachineTranslation = () => {
  const { t: tSettings } = useTranslation('settings');
  const { control: controlFromContext } = useFormContext();

  return (
    <Box>
      <div>
        <Text size="md" color="primary" className="font-bold">
          {tSettings('machineTranslation')}
        </Text>
      </div>
      <div>
        <Controller
          control={controlFromContext}
          name="deeplFormality"
          render={({ field: { value, onChange, ...field } }) => (
            <div className="space-y-6">
              <Text color="secondary">{tSettings('formalityDeepLOnly')}</Text>
              <RadioGroup.Root className="flex space-x-6" value={value} onValueChange={onChange} {...field}>
                <label className="flex items-center gap-3">
                  <RadioGroup.Item value={SettingsDTODeeplFormalityOptions.default} size="sm" className="peer" />
                  <Text color="primary">{tSettings('automatic')}</Text>
                </label>
                <label className="flex items-center gap-3">
                  <RadioGroup.Item value={SettingsDTODeeplFormalityOptions.more} size="sm" className="peer" />
                  <Text color="primary">{tSettings('formal')}</Text>
                </label>
                <label className="flex items-center gap-3">
                  <RadioGroup.Item value={SettingsDTODeeplFormalityOptions.less} size="sm" className="peer" />
                  <Text color="primary">{tSettings('informal')}</Text>
                </label>
              </RadioGroup.Root>
              <Text size="sm" color="tertiary" className="whitespace-pre-line">
                {tSettings('machineTranslationDescription')}
              </Text>
            </div>
          )}
        />
      </div>
    </Box>
  );
};
