import { last as _last } from 'lodash';
import { useMemo, useState } from 'react';

import type { IDocumentCommentDto, IParagraphComment } from '@/redux/api/types';

// Split comments into read and unread comments with counters
export const useComments = <Comment extends IDocumentCommentDto | IParagraphComment>(comments: Comment[] = []) => {
  const [savedFirstNewCommentIndex, setSavedFirstNewCommentIndex] = useState(-1);

  const firstUnreadCommentIndex = useMemo(() => findFirstUnreadCommentIndex(comments), [comments]);
  const unreadCommentsCount = useMemo(() => countUnreadComments(comments), [comments]);

  const splitIndex = savedFirstNewCommentIndex >= 0 ? savedFirstNewCommentIndex : firstUnreadCommentIndex;
  const { newComments, oldComments } = useMemo(() => splitComments(comments, splitIndex), [comments, splitIndex]);

  const commentsCount = comments.length;
  const lastCommentId = _last(comments)?.id;
  const firstUnreadCommentId = comments[firstUnreadCommentIndex]?.id;

  const saveSplit = () => {
    setSavedFirstNewCommentIndex(firstUnreadCommentIndex);
  };

  const resetSplit = () => {
    setSavedFirstNewCommentIndex(-1);
  };

  return {
    commentsCount,
    unreadCommentsCount,
    lastCommentId,
    firstUnreadCommentId,
    oldComments,
    newComments,
    saveSplit,
    resetSplit
  };
};

const findFirstUnreadCommentIndex = <Comment extends IDocumentCommentDto | IParagraphComment>(comments: Comment[]) =>
  comments.findIndex((comment) => !comment.isRead);

const countUnreadComments = <Comment extends IDocumentCommentDto | IParagraphComment>(comments: Comment[]) =>
  comments.reduce((count, comment) => (!comment.isRead ? count + 1 : count), 0);

const splitComments = <Comment extends IDocumentCommentDto | IParagraphComment>(comments: Comment[], index: number) => {
  if (index >= 0) {
    const oldComments = comments.slice(0, index);
    const newComments = comments.slice(index);
    return { oldComments, newComments };
  }

  return { oldComments: comments, newComments: [] };
};
